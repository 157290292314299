import ROUTES from "core/constants/routes/routes";

import PromoCustomizeComponent from "pages/promoCustomize/promoCustomize.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const PromoCustomizePageRoute = {
	path: `${ROUTES.PROMO_CUSTOMIZE}/:id`,
	element: PromoCustomizeComponent , 
    permission: { resource: PERMISSION_RESOURCE.PROJECT, action: PERMISSION_ACTION.VIEW }
};

export default PromoCustomizePageRoute;
