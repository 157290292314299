import { message } from 'antd';

import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

export const deleteAccountAvatar = ( id, onSuccess, isProfile) => {
    return () => {

        return axios({
            url: isProfile ? ApiUrls.DELETE_ACCOUNT_PROFILE_AVATAR :  ApiUrls.DELETE_ACCOUNT_AVATAR,
            method: Methods.DELETE,
            data: {id}
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)){
                message.success(data.message);
                onSuccess && onSuccess();
            }
        })
        .catch((ex) => {})
    }
}