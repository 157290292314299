import { message } from 'antd';

import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setProjectsActionBefore, setProjectsActionFinished, setProjectsSaveActionBefore, setProjectsSaveActionFinished } from './projects.action';

import { 
    SET_PROJECT_PAYOUT_SETTINGS
} from "../../../actionTypes";

import TokenManagerService from 'core/services/tokenManagerService';

const setProjectPayoutSettings = payoutSettings => ({
    type: SET_PROJECT_PAYOUT_SETTINGS,
    payload: { payoutSettings }
})

export const getProjectPayoutSettings = () => {
    return dispatch => {
        dispatch(setProjectsActionBefore());
        return axios({
            url: ApiUrls.GET_PROJECT_PAYOUT_SETTINGS,
            method: Methods.GET
        })
        .then(({data : {value: payoutSettings}}) => {
            dispatch(setProjectPayoutSettings(payoutSettings))
            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        })
    }
}

export const saveProjectPayoutSettings = payoutSettings => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_PROJECT_PAYOUT_SETTINGS,
            method: Methods.POST,
            data: {
                ...payoutSettings
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setProjectPayoutSettings(data.value));
                
                TokenManagerService.updateToken();
            } 
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}