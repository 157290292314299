import isBoolean from "core/helpers/typeChecks/isBoolean";
import isFunction from "core/helpers/typeChecks/isFunction";

const isMenuItemAvailable = (
	item,
	projectId,
	user,
    userInfo,
	permUtils,
) => {
	if (item.userRole && !item.userRole.includes(user?.role)) {
		return false;
	}

	if (item.permission) {
		if (Array.isArray(item.permission)) {
			if (!permUtils.some(...item.permission)) {
				return false;
			}
		} else {
			if (!permUtils.has(item.permission)) {
				return false;
			}
		}
	}

    if(isBoolean(item.disabled)){
        if (item.disabled) return false;
    }

    if(isFunction(item.disabled)){
        if (item.disabled(projectId, user, userInfo)) return false;
    }


	if (item.isProjectRequired && !projectId) {
		return false;
	}

	return true;
};

export default isMenuItemAvailable;