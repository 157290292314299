import React from 'react';

export const getTableColumns = additionalProps => {
    const { t } = additionalProps;

    return [
        {
            title: 'backoffice.platforms.name',
            dataIndex: 'name',
            mobileLevel: 1,
        },
        {
            title: 'backoffice.platforms.description',
            dataIndex: 'description',
            mobileLevel: 2,
        },
        {
            title: 'backoffice.platforms.message',
            dataIndex: 'messages',
            mobileFullWidth: true,
            mobileLevel: 3,
            render: messages => {
                if (!Boolean(messages)) {
                    return "-"
                }
    
                return (
                    <div className="rt--flex rt--flex-col rt--pb-8 rt--pt-8">
                        <span className="rt--title rt--font-regular rt--font-normal">
                            {messages[0]}
                        </span>
    
                        {messages.length > 1 && (
                            <>
                                <span className="rt--font-regular rt--font-normal rt--error-text rt--font-italic rt--pb-4 rt--pt-8">
                                    {t("backoffice.platforms.testCases")}:
                                </span>
    
                                <ul className="rt--pl-16 rt--mb-0">
                                    {messages.slice(1).map(message => (
                                        <li key={message}>
                                            <span className="rt--title-secondary rt--font-regular rt--font-normal">
                                                {message}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </div>
                )
            },
        },
        {
            title: 'backoffice.platforms.details',
            dataIndex: 'fullMessage',
            middle: true,
            mobileLevel: 4,
        },
    ]
}