import React from 'react';

import { Tooltip as AntTooltip } from 'antd';

import useDevice from 'core/hooks/useDevice';

const Tooltip = ({
    children,
    title,
    trigger=["click", "hover"],
    placement="bottomLeft",
    enableMobile=false,
    ...rest
}) => {

    const { isMobile } = useDevice();

    const showTooltip = enableMobile || !isMobile;

    const renderTooltipTitle = () => (
        <div
            onClick={e => e.stopPropagation()}
        >
            { title }
        </div>
    )

    return showTooltip ? (
            <AntTooltip
                title={renderTooltipTitle()}
                trigger={ isMobile ? ["click"] : trigger}
                placement={placement}
                { ...rest }
            >
                { children }
            </AntTooltip>
        ) : children
}

export default Tooltip;