import axios from "core/lib/axios";

import ApiUrls from "constants/api.constants";
import Methods from "constants/methods.constants";

import {
    SET_ALL_COMPANIES,
    SET_GLOBAL_COMPANY_ID,
    SET_GLOBAL_PROJECT_ID,
    SET_SYSTEM_AVAILABLE_LANGUAGES_ACTION_BEFORE,
    SET_SYSTEM_AVAILABLE_LANGUAGES_ACTION_FINISH,
    SET_SYSTEM_AVAILABLE_LANGUAGES,
    SET_PROJECT_AVAILABLE_LANGUAGES_BEFORE,
    SET_PROJECT_AVAILABLE_LANGUAGES_FINISH,
    SET_PROJECT_AVAILABLE_LANGUAGES
} from "../../../actionTypes";

export const setAllCompanies = companies => ({
    type: SET_ALL_COMPANIES,
    payload: { companies },
});

export const setGlobalCompanyId = id => ({
    type: SET_GLOBAL_COMPANY_ID,
    payload: { id },
});

export const setGlobalProjectId = id => ({
    type: SET_GLOBAL_PROJECT_ID,
    payload: { id },
});


/** System languages */

const setSystemAvalableLanguagesActionBefore = () => ({
    type: SET_SYSTEM_AVAILABLE_LANGUAGES_ACTION_BEFORE,
});

const setSystemAvalableLanguagesActionFinished = () => ({
    type: SET_SYSTEM_AVAILABLE_LANGUAGES_ACTION_FINISH,
});

const setSystemAvailableLanguages = languages => ({
    type: SET_SYSTEM_AVAILABLE_LANGUAGES,
    payload: { languages },
});

export const getSystemAvailableLanguages = () => {
    return (dispatch) => {
        dispatch(setSystemAvalableLanguagesActionBefore());
        return axios({
            url: ApiUrls.GET_SYSTEM_AVAILABLE_LANGUAGES,
            method: Methods.GET
        })
            .then(({ data: { value: languages } }) => {
                dispatch(setSystemAvailableLanguages(languages));
                dispatch(setSystemAvalableLanguagesActionFinished());
            })
            .catch(() => {
                dispatch(setSystemAvalableLanguagesActionFinished());
            });
    }
} 


/** Project Available Languages */
const setProjectAvailableLanguagesBefore = () => ({
    type: SET_PROJECT_AVAILABLE_LANGUAGES_BEFORE,
});

const setProjectAvailableLanguagesFinished = () => ({
    type: SET_PROJECT_AVAILABLE_LANGUAGES_FINISH,
});

const setProjectAvailableLanguages = languages => ({
    type: SET_PROJECT_AVAILABLE_LANGUAGES,
    payload: { languages }
})

export const getProjectAvailableLanguages = () => {
    return dispatch => {
        dispatch(setProjectAvailableLanguagesBefore());
        return axios({
            url: ApiUrls.GET_PROJECT_AVAILABLE_LANGUAGES,
            method: Methods.GET
        })
        .then(({data : {value: languages}}) => {
            dispatch(setProjectAvailableLanguages(languages))
            dispatch(setProjectAvailableLanguagesFinished());
        })
        .catch((ex) => {
            dispatch(setProjectAvailableLanguagesFinished());
        })
    }
}