import React, { Fragment, Suspense } from "react";

const withSuspenseHoc = (WrappedComponent, fallback = <Fragment />) => {
	return (props) => (
		<Suspense Suspense fallback={fallback}>
			<WrappedComponent {...props} />
		</Suspense>
	);
};

export default withSuspenseHoc;