import { useMemo } from "react";

import { globalProjectIdSelector, useAuthSelector } from "core/stores/authStore";
import { companiesSelector, useGlobalSelector } from "core/stores/globalStore";

import { LOGO_DEFAULTS } from "core/constants/common/logo";

const getProjectLogos = ( companies=[], projectId ) => {
    const logos = { ...LOGO_DEFAULTS };
    const paths = { };
    for(let company of companies) {
        for(let project of (company.projects ?? [])) {
            if (projectId === project.id) {
                project.logoPaths.forEach(logoData => {
                    if(logoData.path){
                        logos[logoData.type] = logoData.path;
                        paths[logoData.type] = logoData.path;
                    }
                });
                break;
            }
        }
    }

    return { logos, paths };
} 

const useLogo = () => {
    const companies = useGlobalSelector(companiesSelector);
    const projectId = useAuthSelector(globalProjectIdSelector);

    const { logos, paths } = useMemo(() => {
        return getProjectLogos(companies, projectId);
    }, [ companies, projectId])

    return { logos , paths}
}

export default useLogo;