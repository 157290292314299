import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

import DeviceContext from "core/contexts/device";

import DeviceDetectService from "core/services/deviceDetectService";

import { MEDIA_FOR_POINTER, MEDIA_FOR_MOBILE, DEVICE_TYPE } from "core/constants/device";

const detect = () => DeviceDetectService.detect()

const DeviceProvider = ({ children }) => {
    const [deviceVariables, setDeviceVariables] = useState(detect);

    useEffect(() => {
		const pointerMediaQueryList = window.matchMedia(MEDIA_FOR_POINTER);
		const widthMediaQueryListMobile = window.matchMedia(MEDIA_FOR_MOBILE);

		const pointerMediaQueryListEventHandler = () => setDeviceVariables(detect());
		const widthMediaQueryListEventHandler = () => setDeviceVariables(detect());

		pointerMediaQueryList.addEventListener("change", pointerMediaQueryListEventHandler);
		widthMediaQueryListMobile.addEventListener("change", widthMediaQueryListEventHandler);

		return () => {
			pointerMediaQueryList.removeEventListener("change", pointerMediaQueryListEventHandler);
			widthMediaQueryListMobile.removeEventListener("change", widthMediaQueryListEventHandler);
		};
	}, []);

    // useEffect(() => {
	// 	const dataDevice = deviceVariables.isMobile ? DEVICE_TYPE.MOBILE : DEVICE_TYPE.WEB;
	// 	document.body.setAttribute("data-device", dataDevice);
	// 	document.body.setAttribute("data-os", `${deviceVariables.osName}`.toLowerCase());
    //     document.body.setAttribute("data-os-version", `${deviceVariables.osVersion}`.toLowerCase());
	// }, [deviceVariables]);

    return <DeviceContext.Provider value={deviceVariables}>{children}</DeviceContext.Provider>;
}

DeviceProvider.propTypes = {
	children: PropTypes.node
};

export default DeviceProvider;