export const COMMISSION_STATE = {
    ACTIVE: 1,
    INACTIVE: 2
}

export const COMMISSION_STATE_COLORS = {
    [COMMISSION_STATE.ACTIVE]: "green",
    [COMMISSION_STATE.INACTIVE]: "grey"
}

export const COMMISSION_STATE_TRANSLATIONS = {
    [COMMISSION_STATE.ACTIVE]: "backoffice.common.active",
    [COMMISSION_STATE.INACTIVE]: "backoffice.common.inactive"
}