import BaseStore from "..";

import { getSettingsFromStorage } from "./helpers";
import { getStackTrace } from "./helpers/getStackTrace";

import { debugInitialState } from "./initialState";

export class DebugStore extends BaseStore {
    constructor() {
        super(debugInitialState, "DebugStore");

        window.__debug_mode__ = this.toggleDebugMode.bind(this);

        this._stores = {};
    }

    registerStore(store) {
        this.state.stores.push(store._name);
        this._stores[store._name] = store;

        store.subscribe((data) => {
            if (this.state.enabled) {
                this.state.changes.push({
                    id: `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
                    name: data.name,
                    state: data.state,
                    status: data.changes.status,
                    path: data.changes.path?.join(' > ') || 'INIT',
                    value: data.changes.value,
                    stackTrace: getStackTrace(),
                    timestamp: Date.now(),
                    changes: {
                        previous: data.changes.previousState[0]?.value,
                        current: data.changes.currentState[0]?.value,
                    }
                });

                this.setAllStates(store);
            }
        });

        if (this.state.enabled) {
            this.setAllStates(store);
        }
    }

    setAllStates(store) {
        const name = store.__proto__.constructor.name;
        this.state.allStates[name] = store.getState();
    }

    clearChanges() {
        this.state.changes = [];
        this.state.selectedChange = null;
    }

    selectChange(change) {
        this.state.selectedChange = change;
    }

    selectStore(storeName) {
        this.state.selectedStore = storeName;

        if (this.state.selectedChange && storeName && this.state.selectedChange.name !== storeName) {
            this.state.selectedChange = null;
        }

        this.setSettingsToStorage({ selectedStore: storeName });
    }

    toggleOpened() {
        this.state.opened = !this.state.opened;
        this.setSettingsToStorage();
    }

    toggleDebugMode() {
        this.state.enabled = !this.state.enabled;

        if (!this.state.enabled) {
            this.clearChanges();
            this.state.opened = false;
        }

        this.setSettingsToStorage({ enabled: this.state.enabled })

        return this.state.enabled;
    }

    changePlacement(placement) {
        this.state.placement = placement;
        this.setSettingsToStorage();
    }

    setSettingsToStorage(additionalSettings = {}) {
        const settings = getSettingsFromStorage();

        localStorage.setItem(
            '__DEBUG__',
            JSON.stringify({
                ...settings,
                opened: this.state.opened,
                placement: this.state.placement,
                ...additionalSettings,
            })
        );
    }

    setStoreProperty(storeName, propertyPath, value) {
        if (this._stores[storeName]) {
            const store = this._stores[storeName];

            let current = store.state;

            for (let i = 0; i < propertyPath.length - 1; i++) {
                current = current[propertyPath[i]];
            }

            current[propertyPath[propertyPath.length - 1]] = value;
        }
    }

    deleteStoreProperty(storeName, propertyPath) {
        if (this._stores[storeName]) {
            const store = this._stores[storeName];

            let current = store.state;

            for (let i = 0; i < propertyPath.length - 1; i++) {
                current = current[propertyPath[i]];
            }

            delete current[propertyPath[propertyPath.length - 1]];
        }
    }
}
