import React from 'react';

export const getTableColumns = additionalProps => {
    const { t } = additionalProps;

    return [
        {
            title: "backoffice.users.companyName",
            dataIndex: "name",
            showOnlyValue: true,
            mobileLevel: 1,
            render: (value, record) => (
                record.id === "*"
                    ? t("backoffice.common.all")
                    : value
            ),
            mobileRender: (value, record) => (
                <span className="rt--font-normal rt--font-extra-bold">
                    {
                        record.id === "*"
                            ? t("backoffice.common.all")
                            : value
                    }
                </span>
            ),
        },
        {
            title: "backoffice.users.id",
            dataIndex: "id",
            showOnlyValue: true,
            mobileLevel: 2,
            mobileRender: value => `${t("backoffice.users.id")}: ${value}`,
        }
    ];
}