import ROUTES from "core/constants/routes/routes";

import PermissionGroupsComponent from "pages/userManagement/permissionGroups/permissionGroups.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const PermissionGroupsPageRoute = {
	path: ROUTES.PERMISSION_GROUPS,
	element: PermissionGroupsComponent, 
    permission: { resource: PERMISSION_RESOURCE.PERMISSION_GROUP, action: PERMISSION_ACTION.VIEW }
};

export default PermissionGroupsPageRoute;
