import React, { Fragment } from "react";

import { useAuthSelector, userSelector } from "core/stores/authStore";

import Icon from "core/ui-kit/icon";

import LoggedinAsAffiliate from "./loginAsAffiliate";
import CompanyProjectDropdown from "./companyProjectDropdown";
import CurrenciesDropdown from "./currenciesDropdown";
import ProfileDropdown from "./profileDropdown";
import Notifications from "components/common/notifications";

import useDevice from "core/hooks/useDevice";

import { USER_ROLE } from "core/constants/auth";

const Header = () => {

    const { role, hasPrevious } = useAuthSelector(userSelector);

    const { isMobile } = useDevice();

    return (
        <Fragment>
            {
                (
                    isMobile && hasPrevious
                ) && <LoggedinAsAffiliate />
            }
            <div className='rt--header rt--flex rt--justify-between rt--align-center rt--pl-16 rt--pr-16'>

                {
                    isMobile && (
                        <div
                            className='rt--header-menu-btn rt--flex rt--align-center'
                            onClick={() => document.body.classList.add("rt--body-open-sidebar")}
                        >
                            <Icon name='menu' />
                        </div>
                    )
                }
                {
                    (
                        !isMobile && hasPrevious
                    ) && <LoggedinAsAffiliate />
                }

                <CompanyProjectDropdown />

                <div className='rt--header-right rt--flex rt--align-center rt--flex-equal rt--justify-end'>
                    {
                        role === USER_ROLE.AFFILIATE && (
                            <CurrenciesDropdown />
                        )
                    }

                    <Notifications />

                    <ProfileDropdown />
                </div>

            </div>
        </Fragment>
    )
}

export default Header;