import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useNavigate } from 'react-router-dom';

import { Form, Col, Row, Checkbox } from 'antd';

import Modal from "components/common/modal";
import Select from 'components/common/select';
import Input from 'components/common/input';

import { createCompany, getCompanies } from "store/actions/portal/companies/companies.action";

import { NAME_REGEX } from 'constants/regex.constants';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "core/constants/permission";
import ROUTES from "core/constants/routes/routes";

import countries from 'systemData/countries';

import { isMobile } from 'utils/common';
import usePermissions from 'core/hooks/usePermission';

/** Company Creating Popup Component */
const CompanyCreateComponent = ({
    isSaving,
    createCompany,
    getCompanies,
    onClose
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;
    const navigate = useNavigate();


    /** Fires when form submitted
       * @function
       * @memberOf CompanyCreateComponent
    */
    const handleForm = () => {
        validateFields()
            .then(({ name, countryCode, isTesting }) => {
                createCompany(
                    { name, countryCode, isTesting },
                    ({ id, name }) => {
                        if (permissionUtils.some(
                            { resource: PERMISSION_RESOURCE.COMPANY_SECURITY_SETTINGS, action: PERMISSION_ACTION.VIEW },
                            { resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.VIEW }
                        )) {
                            navigate(`${ROUTES.COMPANIES_EDIT}/${id}?name=${name}`);
                        } else {
                            getCompanies();
                            onClose();
                        }

                    });
            }).catch(() => { })
    }

    return (
        <Modal
            title={t('backoffice.companies.createCompany')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.create')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.companies.name')} *`}
                            name="name"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { max: 30, message: t('backoffice.validation.fieldInvalid') },
                                { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            validateFirst
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.companies.name')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.companies.name')}`}
                                maxLength={30}
                                autoFocus={(isMobile() ? false : true)}
                                autoComplete="off"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.companies.country')} *`}
                            name="countryCode"
                            rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                        >
                            <Select
                                options={
                                    countries.map(c => (
                                        { value: c.iso2, text: t(`backoffice.countries.${c.iso2}`) }
                                    ))
                                }
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.companies.country')}`}
                                search={true}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <div className="rt--flex-inline rt--align-center rt--form-item-checkbox">
                            <Form.Item
                                className='rt--form-item-inline rt--form-item-without-margin'
                                name="isTesting"
                                valuePropName='checked'
                            >
                                <Checkbox />
                            </Form.Item>
                            <span className='rt--title rt--font-normal rt--font-regular rt--pl-8'>{t('backoffice.companies.isTestCompany')}</span>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

/** CompanyCreateComponent propTypes
    * PropTypes
*/
CompanyCreateComponent.propTypes = {
    /** Redux state property, is true when creating company request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to create company */
    createCompany: PropTypes.func,
    /** Redux action to get companies */
    getCompanies: PropTypes.func,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        createCompany: (company, onSuccess) => {
            dispatch(createCompany(company, onSuccess));
        },

        getCompanies: () => {
            dispatch(getCompanies());
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.companies.isSaving
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyCreateComponent)