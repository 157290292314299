import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";

import { Form, Col, Input, Row, TimePicker } from 'antd';

import Modal from "components/common/modal";
import Icon from "components/common/icon";

import useDate from 'hooks/useDate';

import { isMobile } from 'utils/common';

import { EDITABLE_CELL_FIELD_TYPES } from "constants/table.constants";

/** Table Editable Cell Modal for mobile */
const EditableCellModal = ({
    title,
    label,
    onClose,
    onSave,
    initialValue,
    extraButtons,
    record,
    maxLength,
    regex,
    type = EDITABLE_CELL_FIELD_TYPES.INPUT,
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    const { dateService } = useDate();

    /** Fires when form submitted
       * @function
       * @memberOf EditableCellModal
    */
    const handleForm = () => {
        validateFields()
            .then(({ editableField }) => {
                onSave && onSave(editableField);
                onClose();
            }).catch(() => { })
    }

    return (
        <Modal
            title={title}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.save')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={false}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    editableField: type === EDITABLE_CELL_FIELD_TYPES.TIME_PICKER
                        ? initialValue.split(" - ").map(time => dateService.getDate(time, "HH:mm"))
                        : initialValue
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={label}
                            name="editableField"
                            className='rt--form-item-without-margin'
                            rules={regex ? [
                                { pattern: regex, message: t('backoffice.validation.fieldInvalid')}
                            ] : undefined}
                        >
                            {
                                type === EDITABLE_CELL_FIELD_TYPES.TIME_PICKER
                                ? (
                                    <TimePicker.RangePicker
                                        format='HH:mm'
                                        allowClear={false}
                                        inputReadOnly={true}
                                        getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                        className='rt--timepicker'
                                        popupClassName='rt--timepicker-dropdown'
                                        separator={<div className='rt--timepicker-separator' />}
                                        placement="bottomRight"
                                        showNow={false}
                                    />
                                ) : (
                                    <Input
                                        autoFocus={(isMobile() ? false : true)}
                                        autoComplete="off"
                                        maxLength={maxLength}
                                    />
                                )
                            }
                        </Form.Item>
                    </Col>
                    {
                        extraButtons && extraButtons.map(button => !button.disabled(record) ? (
                            <div
                                key={button.icon}
                                className="rt--flex rt--align-center rt--pl-8 rt--pr-8"
                                onClick={() => button.onClick(record)}
                            >
                                <Icon name={button.icon}/>
                                <span className='rt--title rt--pl-8 rt--font-regular rt--font-normal'>{button.title}</span>
                            </div>

                        ) : <Fragment key={button.icon} />)
                    }
                </Row>
            </Form>
        </Modal>
    )
}

/** EditableCellModal propTypes
    * PropTypes
*/
EditableCellModal.propTypes = {
    /** Fires on popup close */
    onClose: PropTypes.func,
    /** Modal title */
    title: PropTypes.string,
    /** Editable Field Label */
    label: PropTypes.string,
    /** Save handler */
    onSave: PropTypes.func,
    /** Initial Value */
    initialValue: PropTypes.string,
    /** The array of extra buttons */
    extraButtons: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string,
        disabled: PropTypes.func,
        onClick: PropTypes.func,
        title: PropTypes.string
    })),
    /** Editing record */
    record: PropTypes.object,
    /** Max char count of input */
    maxLength: PropTypes.number,
    /** Regex */
    regex: PropTypes.instanceOf(RegExp),
    /** Type of edit component */
    type: PropTypes.oneOf([EDITABLE_CELL_FIELD_TYPES.INPUT, EDITABLE_CELL_FIELD_TYPES.TIME_PICKER])
}


export default EditableCellModal;