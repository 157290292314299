import React, { Fragment, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import TabTableDashboardLayout from 'components/layouts/tab/table';

import { Col, Row, Carousel } from 'antd';

import PieChart from '../../../components/pieChart';
import OperationalLineChart from '../../../components/operationalLineChart';

import { isMobile } from 'utils/common';

import { OPERATIONAL_ENTITY_TYPE, TIME_PERIOD } from '../../../constants';
import { USER_ROLE } from 'constants/user.constants';

import useFirstRender from 'hooks/useFirstRender';

import { globalProjectIdSelector, useAuthSelector, userRoleSelector } from 'core/stores/authStore';

const getDefaultFilters = () => (
    {
        period: {
            period: TIME_PERIOD.TODAY,
            from: null,
            to: null
        }
    }
)

const General = ({
    updateLastUpdatedDate
}) => {

    const [ filters, setFilters ] = useState(getDefaultFilters())

    const globalProjectId = useAuthSelector(globalProjectIdSelector);
    const userRole = useAuthSelector(userRoleSelector);

    const updateFilters = useCallback((newFilters) => {
        setFilters(prevState => {
            return {
                ...prevState,
                ...newFilters
            }
        })
    }, [])


    useFirstRender({
        afterFirstRenderCB: () => {
            updateFilters(getDefaultFilters())
        },
        dependencies: [globalProjectId, updateFilters]
    })


    const [carouselActiveIndex, setCarouselActiveIndex] = useState(0);

    const handleCarouselChange = (_, nextIndex) => {
        setCarouselActiveIndex(nextIndex);
    }

    const availableEntityTypes = useMemo(() => {
        if(userRole === USER_ROLE.ADMIN || userRole === USER_ROLE.AFFILIATE_MANAGER){
            return [
                OPERATIONAL_ENTITY_TYPE.AFFILIATE,
                OPERATIONAL_ENTITY_TYPE.MEDIA,
                OPERATIONAL_ENTITY_TYPE.CAMPAIGN,
                OPERATIONAL_ENTITY_TYPE.PLAYER
            ]
        } else {
            return [
                OPERATIONAL_ENTITY_TYPE.MEDIA,
                OPERATIONAL_ENTITY_TYPE.PLAYER
            ]
        }
    }, [])

    return (
        <TabTableDashboardLayout>
            {
                !isMobile() ? (
                    <Fragment>
                        <Row gutter={[16, 16]}>
                            {
                                availableEntityTypes.map(entityType => (
                                    <Col xl={6} lg={12} xs={12} key={entityType}>
                                        <PieChart
                                            entityType={entityType}
                                            globalProjectId={globalProjectId}
                                            updateLastUpdatedDate={updateLastUpdatedDate}
                                        />
                                    </Col>
                                ))
                            }
                        </Row>
                        <div className='rt--mt-24'>
                            <Row gutter={[16, 24]}>
                                <Col span={24}>
                                    <OperationalLineChart
                                        updateLastUpdatedDate={updateLastUpdatedDate}
                                        filters={filters}
                                        updateFilters={updateFilters}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Fragment>
                ) : (
                    <div className='rt--chart-operational-pie-carousel'>
                        <Carousel 
                            infinite={false} 
                            beforeChange={handleCarouselChange}
                        >
                            {
                                availableEntityTypes.map( ( entityType, index) => (
                                    <PieChart
                                        entityType={entityType}
                                        globalProjectId={globalProjectId}
                                        isActive={carouselActiveIndex === index}
                                        updateLastUpdatedDate={updateLastUpdatedDate}
                                        key={entityType}
                                    />
                                ))
                            }
                            
                        </Carousel>
                    </div>
                )
            }
            
        </TabTableDashboardLayout>
    )
}

General.propTypes = {
    updateLastUpdatedDate: PropTypes.func,
}

export default General;