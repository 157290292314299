import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";

import Tabs from 'components/common/tabs';

import GroupsComponent from './groups';
import PermissionsComponent from './permissions';

import { USER_ROLE } from 'constants/user.constants';

import { useAuthSelector, userRoleSelector } from 'core/stores/authStore';

/** User Edit Page Permissions & Groups Tab Component */
const PermissionsAndGroupsComponent = ({
    permissionResource,
    onTabChange
}) => {
    const { t } = useTranslation();

    const userRole = useAuthSelector(userRoleSelector)


    const items = [
        {
            title: t("backoffice.users.groups"),
            component: <GroupsComponent permissionResource={permissionResource} />
        },
        {
            title: t("backoffice.users.permissions"),
            component: <PermissionsComponent permissionResource={permissionResource} />,
            disabled: userRole === USER_ROLE.ACCESS_MANAGER
        }
    ]

    return (
        <Tabs.SubTabs
            items={items}
            onTabChange={onTabChange}
        />
    )

    
}

/** PermissionsAndGroupsComponent propTypes
    * PropTypes
*/
PermissionsAndGroupsComponent.propTypes = {
    /** The permission resource */
    permissionResource: PropTypes.string,
    /** Fires when for any form in tab, saved/unsaved state is changed */
    onTabChange: PropTypes.func,
}


export default PermissionsAndGroupsComponent;
