import React from "react";
import PropTypes from 'prop-types';

import LeaderboardMobileView from "./mobileView";
import LeaderboardDesktopView from "./desktopView";

import { isMobile } from "utils/common";

import { LEADERBOARD_DATA_TYPE, LEADERBOARD_TYPE } from "./constants";

import leaderType from "types/dashboard/leader.type";

const Leaderboard = ({
    type = LEADERBOARD_TYPE.FINANCIAL,
    leaders = [],
    isLoading = false,
    currencyCode,
    dataType,
    countText
}) => {
    const isMobileDevice = isMobile();

    return isMobileDevice
        ? (
            <LeaderboardMobileView
                leaders={leaders}
                isLoading={isLoading}
                type={type}
                dataType={dataType}
                currencyCode={currencyCode}
                countText={countText}
            />
        ) : (
            <LeaderboardDesktopView
                leaders={leaders}
                isLoading={isLoading}
                type={type}
                dataType={dataType}
                currencyCode={currencyCode}
                countText={countText}
            />
        )
};

/** Leaderboard propTypes*/
Leaderboard.propTypes = {
    leaders: PropTypes.arrayOf(leaderType),
    isLoading: PropTypes.bool,
    currencyCode: PropTypes.string,
    type: PropTypes.oneOf([
        LEADERBOARD_TYPE.FINANCIAL,
        LEADERBOARD_TYPE.OPERATIONAL
    ]),
    dataType: PropTypes.oneOf(Object.values(LEADERBOARD_DATA_TYPE)),
    countText: PropTypes.string
};

export default Leaderboard;