import ROUTES from "core/constants/routes/routes";

import Financial from "pages/dashboard/financial";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const FinancialRoute = {
	path: ROUTES.DASHBOARD_FINANCIAL,
	element: Financial, 
    permission: { resource: PERMISSION_RESOURCE.DASHBOARD_FINANCIAL, action: PERMISSION_ACTION.VIEW }
};

export default FinancialRoute;
