//#region react
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "core/hooks/useTranslation";
//#endregion

//#region components
import Icon from "components/common/icon";
//#endregion

//#region utils
import { isMobile, classNames } from "utils/common";
//#endregion

//#region hooks
import useFormat from 'hooks/useFormat';
//#endregion

//#region constants
import { WALLET_TYPE_TRANSLATION_RESOURCES } from 'constants/wallet.constants';
//#endregion

const WalletBalanceChanges = ({
    balanceChanges = [],
    currencyCode,
    transactionTime
}) => {
    const { t } = useTranslation();

    const { formatAmount } = useFormat();

    const sortedBalanceChanges = [...balanceChanges].sort((c1, c2) => c1.type >= c2.type ? 1 : -1);

    const isMobileDevice = isMobile();

    return (
        <div
            className={classNames(
                "rt--flex rt--align-center",
                isMobileDevice ? "rt--justify-center rt--width-full rt--width-full-wallets rt--mb-16" : "rt--pr-8"
            )}
        >
            {!isMobileDevice && <Icon name="pointing-arrow" />}

            {sortedBalanceChanges.map((change, index) => (
                <div className="rt--flex rt--align-center rt--pl-8 rt--pr-8" key={index}>
                    <div className="rt--flex rt--align-center">
                        <span className="rt--title rt--font-normal rt--font-bold rt--pr-4">
                            {
                                t(WALLET_TYPE_TRANSLATION_RESOURCES[change.type]) + (isMobileDevice ? '' : ':')
                            }
                        </span>
                    </div>
                    <div className="rt--flex rt--align-center">
                        <span className="rt--title rt--font-normal rt--font-regular rt--pr-4">
                            {
                                formatAmount( change.before, currencyCode, transactionTime)
                            }
                        </span>
                        <Icon 
                            name={change.before < change.after ? "arrow-up-right" : "arrow-down-right"} 
                            size={14}
                            className={change.before < change.after ? "rt--success-text" : "rt--error-text"} 
                        />

                        <span className="rt--title rt--font-normal rt--font-regular rt--pl-4">
                            {
                                formatAmount( change.after, currencyCode, transactionTime)
                            }
                        </span>
                    </div>
                </div>
            ))}
        </div>
    )
}

/** WalletBalanceChanges propTypes
 * PropTypes
*/
WalletBalanceChanges.propTypes = {
    balanceChanges: PropTypes.array,
    currencyCode: PropTypes.string,
    transactionTime: PropTypes.string
}

export default WalletBalanceChanges