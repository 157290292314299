export const DEFAULT_TRANSLATIONS = {
    "common": {
        "save": "Save",
        "logout": "Logout",
        "profile": "Profile",
        "refresh": "Refresh",
        "tokenExpireText": "Your token will expire in %X% seconds",
        "tokenExpiration": "Token Expiration",
        "copy": "Copy",
        "copied": "Copied !",
        "noData": "No Data",
        "noDataFound": "No Data Found",
        "edit": "Edit",
        "cancel": "Cancel",
        "create": "Create",
        "enter": "Enter",
        "select": "Select",
        "add": "Add",
        "search": "Search",
        "never": "Never",
        "every_N_month": "Every %N% months",
        "yes": "Yes",
        "no": "No",
        "delete": "Delete",
        "remove": "Remove",
        "block": "Block",
        "unblock": "Unblock",
        "unlock": "Unlock",
        "actions": "Actions",
        "male": "Male",
        "female": "Female",
        "other": "Other",
        "none": "None",
        "fullView": "Full View",
        "filters": "Filters",
        "reset": "Reset",
        "all": "All",
        "blocked": "Blocked",
        "active": "Active",
        "inactive": "Inactive",
        "expired": "Expired",
        "deleted": "Deleted",
        "locked": "Locked",
        "locked2Fa": "Locked 2Fa",
        "lockedLogin": "Locked Login",
        "declined": "Declined",
        "dataType": "Data Type",
        "testing": "Testing",
        "notTesting": "Not Testing",
        "dateAndTime": "Date and Time",
        "creationDate": "Creation Date",
        "lastUpdateDate": "Last Update Date",
        "from": "From",
        "to": "To",
        "status": "Status",
        "selectDate": "Select Date",
        "deepView": "Deep View",
        "month": "Month",
        "year": "Year",
        "details": "Details",
        "pdf": "PDF",
        "csv": "CSV",
        "export": "Export",
        "selectAll": "Select All",
        "deselectAll": "Deselect All",
        "noPermission": "No Permission",
        "columns": "Columns",
        "apply": "Apply",
        "admin": "Admin",
        "affiliate": "Affiliate",
        "player": "Player",
        "reparent": "Reparent",
        "last24Hours": "Last 24 h",
        "lastWeek": "Last 7 days",
        "lastMonth": "Last Month",
        "last3Month": "Last 90 days",
        "upload": "Upload Picture",
        "uploadRemoveConfirmationMessage": "Are you sure you want to delete the image?",
        "maxSizeOf": "Max size of",
        "selectOrDragToUpload": "Select Or Drag To Upload",
        "selectToUpload": "Select To Upload",
        "dragHere": "Drag Here",
        "uploadingError": "Uploading Error",
        "uploadingFile": "Uploading File",
        "change": "Change",
        "balance": "Balance",
        "project": "Project",
        "choose": "Choose",
        "next": "Next",
        "notFound": "Not Found",
        "back": "Back",
        "close": "Close",
        "addRange": "Add Range",
        "percent": "Percent",
        "amount": "Amount",
        "invalidRanges": "Invalid Ranges",
        "minRangeCountIs": "Min Range Count is",
        "min": "Min",
        "max": "Max",
        "id": "ID",
        "longId": "Long ID",
        "on": "on",
        "setPassword": "Set Password",
        "newPassword": "New Password",
        "confirmPassword": "Confirm Password",
        "preMatch": "Pre Match",
        "live": "Live",
        "kombi": "Kombi",
        "confirm": "Confirm",
        "reject": "Reject",
        "items": "Items",
        "today": "Today",
        "yesterday": "Yesterday",
        "thisMonth": "This Month",
        "ytd": "YTD",
        "youAreloggedinAsAffiliate": "You're logged in as affiliate",
        "backToAdmin": "Back To Admin",
        "backToYourUser": "Back To Your User",
        "loginAsAffiliate": "Login As Affiliate",
        "date": "Date",
        "selected": "Selected",
        "deselect": "Deselect",
        "success": "Success",
        "failed": "Failed",
        "calculate": "Calculate",
        "uploadFile": "Upload File",
        "flushCache": "Flush Cache",
        "flushCacheConfirmationMessage": "Are you sure you want to flush cache ?",
        "cache": "Cache",
        "flush": "Flush",
        "default": "Default",
        "setDefault": "Set Default",
        "reparentResult": "Reparent Result",
        "approveConfirmationMessage": "Are you sure you want to approve ?",
        "rejectConfirmationMessage": "Are you sure you want to reject ?",
        "approve": "Approve",
        "translationsExport": "Export Translations",
        "translationsUpdate": "Update Translations",
        "resetToDefault": "Reset To Default",
        "reason": "Reason",
        "confirmAction": "Confirm Action",
        "withoutSavingMessage": "Are you sure you want to exit without saving the changes?",
        "discard": "Discard",
        "decline": "Decline",
        "comingSoon": "Coming Soon",
        "comingSoonInfo": "We're currently working on creating something fantastic.",
        "totalActiveConnections": "Total Active Connections",
        "totalIdleConnections": "Total Idle Connections",
        "username": "Username",
        "minutes": "Minutes",
        "idle": "Idle",
        "state": "State",
        "total": "Total",
        "pending": "Pending"
    },
    "authentication": {
        "signIn": "Sign In",
        "welcomeBack": "Welcome Back!",
        "username": "Username",
        "password": "Password",
        "confirmPassword": "Confirm Password",
        "setNewPassword": "Set New Password",
        "noTokenFound": "No token Found !",
        "passwordRules": "Password Rules",
        "mustBeBetween": "Must be between %X% and %Y% characters.",
        "mustContain": "Must contain at least 1 %X%",
        "mustNotContain": "Must not contain %X%",
        "mustMatchRegularExpression": "Must match the Regular expression sample %X%",
        "mustInclude": "Must include the following characters '%X%'",
        "twoFactorVerification": "Two Factor Verification",
        "scanQRCode": "Scan QR code",
        "twoFactorVerificationCode": "Enter the verification code generated by your mobile application",
        "verify": "Verify",
        "accountTemporaryLockedText": "Account locked due to reaching the maximum failed login attempts.",
        "accountLockedText": "The account is locked. Please contact to your Moderator.",
        "accountNotApprovedText": "Your account is currently under review and its status is Pending.",
        "accountDeclinedText": "Your Account is Declined",
        "accountBlockedText": "Your Account is Blocked",
        "becomeAffiliate": "Become an Affiliate",
        "alreadyHaveAccount": "Already have an account?",
        "register": "Register",
        "paymentDetails": "Payment Details",
        "readAndAccept": "I have read, understood and accept the above",
        "termsAndPrivacy": "Terms and conditions/Privacy policy",
        "yourEmailVarificationIsComplete": "Your email verification is complete",
        "forgotPassword": "Forgot Password?",
        "forgotPasswordInfoText": "Enter the email address you used when you joined and we'll send you instructions to reset your password. For security reasons, we do NOT store your password. So rest assured that we will never send your password via email.",
        "emailAddress": "Email Address",
        "sendResetInstructions": "Send Reset Instructions",
        "returnToLogin": "Return to login",
        "resetPassword": "Reset Password"
    },
    "menu": {
        "companies": "Companies",
        "currencies": "Currencies",
        "languages": "Languages",
        "settings": "Settings",
        "affiliatesManagement": "Affiliates Management",
        "affiliates": "Affiliates",
        "reports": "Reports",
        "marketing": "Marketing",
        "performance": "Performance",
        "payment": "Payment",
        "commissionCalculations": "Commission Calculations",
        "invoices": "Invoices",
        "balanceHistory": "Balance History",
        "betHistory": "Bets",
        "admins": "Admins",
        "userManagement": "User Management",
        "communications": "Communications",
        "systemNotifications": "System Notifications",
        "permissions": "Permissions",
        "permissionGroups": "Permission Groups",
        "projects": "Projects",
        "userLogs": "User Logs",
        "players": "Players",
        "errors": "Errors",
        "jobs": "Jobs",
        "serviceMonitoring": "Service Monitoring",
        "platformTest": "Platform Test",
        "developerSpace": "Developer Space",
        "promoTranslations": "Promo Translations",
        "boTranslations": "BO Translations",
        "cms": "CMS",
        "online": "Bet History",
        "payments": "Payments",
        "generations": "Generations",
        "realTimeSportBets": "Real Time Sport Bets",
        "postDeploymentActions": "Post Deployment Actions",
        "accessManagers": "Access Managers",
        "affiliateManagers": "Affiliate Managers",
        "pendingRequests": "Pending Requests",
        "requests": "Requests",
        "profile": "Profile",
        "marketingTools": "Marketings",
        "campaigns": "Campaigns",
        "mediaTools": "Medias",
        "aggregatedData": "Aggregated Data",
        "approveCommission": "Approve Commission",
        "newsletter": "Newsletter",
        "dashboard": "Dashboard",
        "financial": "Financial",
        "dbConnections": "DB Connections",
        "accounts": "Accounts",
        "affiliateGroups": "Affiliate Groups",
        "myAccount": "My Account",
        "affiliateAccess": "Affiliate Access",
        "operational": "Operational"
    },
    "profile": {
        "companies": "Companies",
        "personalInformation": "Personal Information",
        "noCompany": "No Company",
        "changePassword": "Change Password",
        "currentPassword": "Current Password",
        "newPassword": "New Password",
        "confirmPassword": "Confirm New Password",
        "dateFormat": "Date Format",
        "timeFormat": "Time Format",
        "numberFormat": "Number Format",
        "formatSettings": "Format Settings",
        "24HourTime": "24-Hour Time",
        "12HourTime": "12-Hour Time AM/PM"
    },
    "companies": {
        "general": "General",
        "securitySettings": "Security Settings",
        "passwordSettings": "Password Settings",
        "tokenAndmultidevice": "Token & Multidevice",
        "authenticationSettings": "Authentication Settings",
        "name": "Name",
        "id": "ID",
        "country": "Country",
        "status": "Status",
        "createdAt": "Created at",
        "lastUpdatedAt": "Last Updated at",
        "createCompany": "Create Company",
        "email": "Email",
        "tel": "Telephone",
        "postalCode": "Postal Code",
        "comments": "Comments",
        "fax": "Fax",
        "emergencyTel": "Emergency Tel.",
        "city": "City",
        "address": "Address",
        "contactName": "Contact Name",
        "company": "Company",
        "isTestCompany": "Is a Test Company",
        "companyNameOrId": "Company Name Or Id",
        "changeCompany": "Change Company",
        "must": "Must",
        "may": "May",
        "none": "None",
        "passwordMinLimit": "Password Min Limit",
        "passwordMaxLimit": "Password Max Limit",
        "forceChange": "Force Change",
        "addSpecificCharacters": "Add specific characters",
        "containType1": "Upper-case (A, B, C, ...)",
        "containType2": "Lower-case (a, b, c, ...)",
        "containType4": "Digits (0, 1, 2, ...)",
        "containType8": "Minus (-)",
        "containType16": "Underline(_)",
        "containType32": "Space ( )",
        "containType64": "Special (!, $, %, &, ...)",
        "containType128": "Brackets ([, ], {, }, <, >)",
        "mustRequired": "At least one Must selection is required.",
        "tokenLifetime": "Token Lifetime",
        "multideviceLogin": "Multidevice Login",
        "googleAuth": "Google Two Factor Authentication",
        "login": "Login",
        "2fa": "2FA",
        "failedLoginAttemptsCount": "Failed Login Attempts Count",
        "failedFAAttemptsCount": "Failed FA Attempts Count",
        "autoUnlockTime": "Auto-Unlock Time",
        "autoUnlockTotalCount": "Auto-Unlock Total Count",
        "min": "min"
    },
    "currencies": {
        "currency": "Currency",
        "addCurrency": "Add Currency",
        "editCurrency": "Edit Currency",
        "name": "Name",
        "code": "Code",
        "rate": "Rate",
        "rateToDefault": "Rate (1 EUR)",
        "symbol": "Symbol",
        "createdAt": "Created at",
        "lastUpdatedAt": "Last Updated at",
        "lastUpdatedBy": "Last Updated by",
        "selectCurrency": "Select Currency",
        "selectCurrencyPlaceholder": "Select currency name",
        "default": "Default",
        "rateOverridden": "Rate Overridden",
        "deleteCurrency": "Delete Currency",
        "doYouWanttoDeleteCurrency": "Do you want to delete currency ?",
        "resetToDefault": "Reset to default",
        "addedAt": "Added at",
        "addedBy": "Added by"
    },
    "languages": {
        "addLanguage": "Add Language",
        "name": "Name",
        "code": "Code",
        "createdAt": "Created at",
        "selectLanguage": "Select Language",
        "language": "Language",
        "published": "Published",
        "ar": "Arabic",
        "bg": "Bulgarian",
        "de": "German",
        "en": "English",
        "es": "Spanish",
        "fa": "Persian",
        "fr": "French",
        "he": "Hebrew",
        "hy": "Armenian",
        "ko": "Korean",
        "pt": "Portuguese",
        "ro": "Romanian",
        "ru": "Russian",
        "tr": "Turkish"
    },
    "registrationform": {
        "must": "Required",
        "may": "Optional",
        "none": "Hidden",
        "cannotBeSelected": "Cannot be selected",
        "mustTooltip": "If the selection is marked as REQUIRED, the field mandatory be filled out during registration",
        "mayTooltip": "If the selection is marked as Optional, the field optional be filled out during registration.",
        "noneTooltip": "If the selection is marked as Hidden, the fields is not seen in the registration form",
        "regexp": "Regular Expression",
        "mustRequired": "At least one of the fields (Username, Email, PhoneNumber) must be required.",
        "unique": "The field is unique",
        "login": "Login",
        "personalData": "Personal Data",
        "contactDetailFields": "Contact Detail Fields",
        "paymentDetailFields": "Payment Detail Fields",
        "additionalFields": "Additional Fields",
        "payment": "Payment",
        "UserName": "Username",
        "Email": "Email",
        "Password": "Password",
        "ConfirmPassword": "Confirm Password",
        "FirstName": "First Name",
        "LastName": "Last Name",
        "PhoneNumber": "Tel",
        "Country": "Country",
        "State": "State",
        "City": "City",
        "Address": "Address",
        "ZipCode": "Postal Code",
        "ContactChannel": "Contact Channel",
        "AdditionalInfo": "Additional Info",
        "TargetMarket": "Target Market",
        "TrafficSource": "Traffic Source",
        "CurrencyCode": "Currency",
        "PaymentMethod": "Payment Method",
        "ReceiveNewsletter": "Receive Newsletter",
        "ContactChannelName": "Contact Channel Details"
    },
    "projects": {
        "general": "General",
        "currencies": "Currencies",
        "languages": "Languages",
        "commissionPlans": "Commission Plans",
        "registrationForm": "Registration Form",
        "wallets": "Wallets",
        "platform": "Platform",
        "integration": "Integration",
        "name": "Name",
        "domains": "Domains",
        "promoDomain": "Promo Domain",
        "trackingDomain": "Tracking Domain",
        "portalDomain": "Portal Domain",
        "defaultDomain": "Default Domain",
        "sites": "Sites",
        "hosts": "Hosts",
        "addSite": "Add Site",
        "addCname": "Add CNAME",
        "cname": "CNAME",
        "site": "Site",
        "doYouWantToDeleteSite": "Do you want to delete site ?",
        "doYouWantToDeleteCname": "Do you want to delete CNAME ?",
        "nameServer": "Name Server",
        "removeSite": "Remove Site",
        "createProject": "Create Project",
        "gateway": "Gateway",
        "createdAt": "Created at",
        "lastUpdatedAt": "Last Updated at",
        "createdBy": "Created by",
        "lastUpdatedBy": "Last Updated by",
        "isTestProject": "Is a Test Project",
        "projectNameOrId": "Project Name Or Id",
        "description": "Description",
        "version": "Version",
        "mode": "Mode",
        "real": "Real",
        "test": "Test",
        "documents": "Documents",
        "chat": "Chat",
        "customization": "Customization",
        "backgroundColors": "Background Colors",
        "textAndIconColors": "Text And Icon Colors",
        "avatarBackgroundColors": "Avatar Background Colors",
        "lastPublishedAt": "Last Published At",
        "lastPublishedby": "Last Published By",
        "notActivatedYet": "Not Activated Yet",
        "notActivatedYetInfo": "If you want to activate the promo website then click the Activate promo website button",
        "activatePromoWebsite": "Activate Promo Website",
        "paymentMethod": "Payment Method",
        "totalCurrency": "Total Currency",
        "addPayment": "Add Payment Method",
        "doYouWantToDeletePayment": "Do you want to delete Project Payment",
        "supportedCurrency": "Supported Currency",
        "minThreshold": "Minthreshold",
        "commission": "Commission",
        "numberOfAffiliates": "Number Of Affiliates",
        "addCurrency": "Add Currency",
        "currency": "Currency",
        "promo": "Promo",
        "financial": "Financial",
        "calculationAndPayouts": "Calculation & Payouts",
        "fee": "Fees",
        "paymentMethods": "Payment Methods",
        "affiliateRegConfig": "Affiliate Registration Config",
        "selfSignUp": "Self Sign Up",
        "byAffiliateManager": "By Affiliate Manager",
        "defaultManager": "Default Manager",
        "affiliateManagerTextInfo": "If Default Manager is selected the affiliate will be assigned and visible to him automatically, otherwise only Admins will see registered affiliates",
        "brands": "Brands",
        "addBrand": "Add Brand",
        "mainDomain": "Main Domain",
        "urls": "URLs",
        "doYouWanttoDeleteBrand": "Are you sure you want to delete this brand?",
        "deleteBrand": "Delete Brand",
        "brandUrls": "Brand URLs",
        "url": "URL",
        "language": "Language",
        "product": "Product",
        "casino": "Casino",
        "sport": "Sport",
        "other": "Other",
        "addUrl": "Add URL",
        "editUrl": "Edit URL",
        "doYouWanttoDeleteBrandUrl": "Are you sure you want to delete this brand URL?",
        "deleteBrandUrl": "Delete Brand URL",
        "subDomain": "SubDomain",
        "referralProgram": "Referral Program",
        "referralProgramInfo": "Referral Program allows your Affiliates to bring new Affiliates and earn additional commission",
        "theCompany": "The Company",
        "subAffiliate": "Sub-Affiliate",
        "theCompanyTooltip": "Parent affiliate will be paid by the company",
        "subAffiliateTooltip": "Parent affiliate will be paid by the sub-affiliate",
        "percentage": "Percentage",
        "percentageTooltip": "Commission will be calculated based on sub affiliate's earning",
        "referralProgramPeriod": "Referral Program Period",
        "referralProgramPeriodInfo": "Period in which the Affiliate will benefit from the Referral Program after they are registered",
        "1_month": "1 Month",
        "3_months": "3 Months",
        "6_months": "6 Months",
        "1_year": "1 year",
        "2_years": "2 years",
        "always": "Always",
        "commissions": "Commissions",
        "main": "Main",
        "appearance": "Appearance",
        "logo": "Logo",
        "favicon": "Favicon",
        "collapsedMenuLogo": "Collapsed Menu Logo",
        "additionalParams": "Additional Params",
        "addAdditionalParam": "Add Additional Param",
        "removeAdditionalParam": "Remove Additional Param",
        "doYouWantToRemoveAdditionalParam": "Are you sure you want to remove additional param?",
        "key": "Key",
        "value": "Value",
        "configurations": "Configurations",
        "postback": "Postback",
        "postbackLogs": "Postback Logs",
        "postbackTooltip": "If this toggle switch is turned off, the Postback feature will be disabled and unavailable.",
        "postbackViewDescription": "Affiliates will have access to View the Postbacks",
        "postbackModifyDescription": "Affiliates will have access to Edit the Postbacks",
        "postbackCreateDescription": "Affiliates will have access to Create Postbacks",
        "postbackLogsDescription": "Affiliates will have access to the 'Postback Logs' Sub Menu of the 'Postback' Menu"
    },
    "affiliates": {
        "id": "ID",
        "userName": "UserName",
        "email": "Email",
        "status": "Status",
        "players": "Players",
        "registrationDate": "Registration Date",
        "lastLoginDate": "Last Login Date",
        "personalTab": "Personal",
        "additionalTab": "Additional",
        "trafficSourceTab": "Traffic Source",
        "firstName": "First Name",
        "lastName": "Last Name",
        "phoneNumber": "Phone Number",
        "dateOfBirth": "Date of Birth",
        "street": "Street",
        "postalCode": "Postal Code",
        "forcePasswordChange": "Force Password Change",
        "channel":" Channel",
        "contactNumberOrUsername": "Contact Number/Username",
        "channelLink": "Channel Link",
        "assignedManager": "Assigned Manager",
        "targetMarket": "Target Market",
        "referredBy": "Referred By",
        "additionalInformation": "Additional Information",
        "newsLetterConfiguration": "NewsLetter Configuration",
        "asia": "Asia",
        "europe": "Europe",
        "africa": "Africa",
        "northAmerica": "North America",
        "centralAndSouthAmerica": "Central & South America",
        "middleEastNorthAfrica": "Middle East North Africa",
        "trafficSourceList": "Traffic Source List",
        "usernameOrId": "Username Or Id",
        "createAffiliate": "Create Affiliate",
        "currency": "Currency",
        "country": "Country",
        "doYouWantToApprove": "Are you sure want to Approve?",
        "doYouWantToDecline": "Are you sure want to Decline?",
        "doYouWantToBlock": "Are you sure want to Block?",
        "doYouWantToUnblock": "Are you sure want to Unblock?",
        "doYouWantToUnlock": "Are you sure want to Unlock?",
        "reason": "Reason",
        "manager": "Manager",
        "subAffiliatesCount": "Sub Affiliates",
        "city": "City",
        "state": "State",
        "forceLogout": "Force Logout",
        "forceLogoutConfirmationMessage": "Users will be automatically logged out. This may cause termination of some actions which are being executed currently by affiliates. Do you want to continue?",
        "general": "General",
        "addTrafficSource": "Add Traffic Source",
        "contactChannel": "Contact Channel",
        "sessions": "Sessions",
        "pending": "Pending",
        "approved": "Approved",
        "paymentMethodsTab": "Payment Methods",
        "paymentMethod": "Payment Method",
        "paymentMethods": "Payment Methods",
        "minThreshold": "MinThreshold",
        "commissions": "Commissions",
        "documentNumber": "Document Number",
        "accountHolder": "Account Holder",
        "accountNumber": "Account Number",
        "address": "Address",
        "wallets": "Wallets",
        "createdBy": "Created By",
        "createdAt": "Created At",
        "finance": "Finance",
        "subAffiliates": "Sub-Affiliates",
        "referralLinkInfo": "Invite new affiliates and earn a %%PERCENT%%% commission on their monthly earnings",
        "personalReferralLink": "Personal Referral Link",
        "copySignUpUrl": "Copy Sign Up URL",
        "totalCommission": "Total Commission",
        "receivedReferralCommission": "Referral Commission",
        "name": "Name",
        "groupType": "Group Type",
        "groupTypeTooltip": "Static Group Type: Affiliates will be added & removed manually. Dynamic Group Type: Affiliates will be added automatically based on the filters criteria.",
        "dynamic": "Dynamic",
        "static": "Static",
        "lastModifiedAt": "Last Modified At",
        "lastModifiedBy": "Last Modified By",
        "createGroup": "Create Group",
        "groupNameOrId": "Group Name Or ID",
        "groupVisibleToAffiliates": "Group Visible to Affiliates",
        "groupVisibleToAffiliatesTooltip": "Affiliate will be able to see their Assigned Groups",
        "editableByOtherUsers": "Edible By Other Users",
        "editableByOtherUsersTooltip": "The Group members are editable by other users",
        "selectAffiliate": "Select Affiliate",
        "totalNumberOfAffiliates": "Total Number of Affiliates",
        "groupMembers": "Group Members",
        "modifyMembers": "Modify Members",
        "removeAffiliate": "Remove Affiliate",
        "doYouWantToRemoveAffiliateFromGroup": "Are you sure you want to remove this Affiliate from the group?",
        "removedMembers": "Removed Members",
        "addedMembers": "Added Members",
        "deleteGroup": "Delete Group",
        "doYouWantToDeleteGroup": "Are you sure you want to delete this Group?",
        "setAsAutoInvoiceDefault": "Set as Auto-Invoice Default",
        "autoInvoice": "Auto-Invoice",
        "clickSource": "Click Source",
        "depositCount": "Deposit Count",
        "depositAmount": "Deposit Amount",
        "betAmount": "Bet Amount",
        "betCount": "Bet Count",
        "withdrawCount": "Withdraw Count",
        "withdrawAmount": "Withdraw Amount",
        "netDepositAmount": "Net Deposit Amount",
        "ggr": "GGR",
        "ngr": "NGR",
        "wonAmount": "Won Amount",
        "bonusWonAmount": "Bonus Won Amount",
        "adminFee": "Admin Fee",
        "adjustments": "Adjustments",
        "playerId": "Player ID",
        "playerUsername": "Player Username",
        "ftdCount": "FTD Count",
        "ftdAmount": "FTD Amount",
        "affiliateAccessDescription": "Affiliate access allows admins to configure which data table columns affiliates can see. This ensures affiliates view only relevant information, enhancing security and usability by restricting access to sensitive data and streamlining their interface."
    },
    "wallet": {
        "earningBalance": "Earning Balance",
        "cpaBalance": "CPA Balance",
        "adjust": "Adjust",
        "adjustEarningBalance": "Adjust Earning Balance",
        "transactionType": "Transaction Type",
        "manualDeduction": "Manual Deduction",
        "manualBonus": "Manual Bonus",
        "username": "Username",
        "affiliateId": "Affiliate ID",
        "amount": "Amount",
        "notes": "Notes",
        "currency": "Currency",
        "walletType": "Wallet Type",
        "message": "Message",
        "success": "Success",
        "transferSuccessful": "Transfer Successful!",
        "transferFailed": "Transfer Failed!",
        "transactionId": "Transaction ID",
        "transactionTime": "Transaction Time",
        "usernameOrId": "Username Or Id",
        "earningNotApprovedBalance": "Pending Earning Balance",
        "cpaNotApprovedBalance": "Pending CPA Balance",
        "paidoutBalance": "Total Paid Balance",
        "frozenBalance": "Balance Due",
        "commissionCalculated": "Commission Calculated",
        "commissionRecalculated": "Commission Recalculated",
        "commissionApproved": "Commission Approved",
        "invoiceCreated": "Invoice Created",
        "invoicePaid": "Invoice Paid",
        "rollback": "Rollback",
        "manualBonused": "Manual Bonused",
        "manualDeducted": "Manual Deducted",
        "paidReferralCalculated": "Paid Referral Calculated",
        "referralCalculationApproved": "Referral Calculation Approved",
        "balanceType": "Balance Type",
        "createdBy": "Created By",
        "affiliateUserName": "Affiliate Username",
        "cpaBalanceDescription": "Approved CPA earnings available for affiliate withdrawal.",
        "cpaNotApprovedBalanceDescription": "Calculated Cost Per Acquisition (CPA) awaiting for approval.",
        "earningBalanceDescription": "Approved commission earnings available for affiliate withdrawal.",
        "earningNotApprovedBalanceDescription": "All calculated commissions and sub-affiliate commissions awaiting for approval.",
        "frozenBalanceDescription": "The total amount of created and unpaid invoices.",
        "paidoutBalanceDescription": "The overall amount that has been settled & paid to the affiliate.",
        "resetEarningBalance": "Reset Earning Balance"
    },
    "commissionplans": {
        "calculation": "Calculation",
        "affiliateWithdrawals": "Affiliate Withdrawals",
        "period": "Period",
        "nextCalculationTime": "Next Calculation Time",
        "calculationPeriod": "Calculation Period",
        "negativeCarryOver": "Negative Carry Over",
        "percent": "Percent",
        "negativeRevenueCommissionWillImpactCPACommission": "Negative revenue commission will impact CPA commission.",
        "approveCommissionManually": "Approve Commission Manually",
        "invoiceGeneration": "Invoice Generation",
        "byCompany": "By Company",
        "byAffiliate": "By Affiliate",
        "byCompanyTooltip": "Invoice will be generated by admin or by affiliate manager",
        "byAffiliateTooltip": "Affiliate can requests payment and generate invoice",
        "hour": "A hour",
        "day": "A day",
        "week": "A week",
        "2week": "2 week",
        "3week": "3 week",
        "4week": "4 week",
        "administrativeFee": "Administrative Fee",
        "administrativeFeeInfo": "Administrative fee will be deducted from only positive GGR before affiliate commission calculation.",
        "deposit": "Deposit",
        "depositFeeInfo": "Deposit Fee percentage will be calculated from the Total Deposit Amount & will be deducted from the GGR to calculate the NGR if the created NGR Commission has the Configuration checked to deduct.",
        "percentage": "Percentage",
        "casinoFee": "Casino Fee",
        "sportFee": "Sport Fee",
        "createCommission" : "Create Commission",
        "nameOrId": "Name or ID",
        "name": "Name",
        "currency": "Currency",
        "commissionType": "Commission Type",
        "revshare": "RevShare",
        "cpa": "CPA",
        "fix": "Fix",
        "source": "Source",
        "sourceType": "Source Type",
        "ngr": "NGR",
        "ggr": "GGR",
        "turnover": "Turnover",
        "structure": "Structure",
        "flat": "Flat",
        "playerTiered": "Player Tiered",
        "progressive": "Progressive",
        "sourceRanges": "Source Ranges",
        "playerRangesTooltip": "Number of players affiliate has on calculation date",
        "amountWillBeIncludedInMaxField": "The amount will be included in Max field",
        "amount": "Amount",
        "includeOtherCosts": "Include Other Costs",
        "setAsDefault": "Set As Default",
        "playerRanges": "Player Ranges",
        "minDepositAmount": "Min Deposit Amount",
        "minWagerAmount": "Min Wager Amount",
        "status": "Status",
        "assignedAffiliates": "Assigned Affiliates",
        "createdBy": "Created By",
        "createdAt": "Created At",
        "lastModifiedAt": "Last Modified At",
        "lastModifiedBy": "Last Modified By",
        "doYouWanttoDeleteCommissionPlan": "Do You Want to Delete Commission Plan?",
        "activate": "Activate",
        "deactivate": "Deactivate",
        "commissionPlanDeactivateInfo": "Do You Want to Deactivate Commission Plan?",
        "commissionPlanActivateInfo": "Do You Want to Activate Commission Plan?",
        "addCommission": "Add Commission",
        "doYouWanttoRemoveCommissionPlan": "Do You Want to Remove Commission Plan?",
        "general": "General",
        "month": "Month",
        "netDeposit": "Net Deposit",
        "manual": "Manual",
        "auto": "Auto",
        "generationPeriod": "Generation Period",
        "nextGenerationPeriod": "Next Generation Period",
        "feeInfo": "Select the fees that will be deducted from the GGR when calculating the NGR. Deposit Fee perncetage should be set in Project Financial Tab.",
        "playerBalanceAdjustment" : "Player Balance Adjustment",
        "depositFee" : "Deposit Fee",
        "recalculate": "Recalculate",
        "recalculation": "Recalculation",
        "recalculateInfo": "Please note that both pending and approved commission for the selected period will be recalculated",
        "dateDuration": "Date Duration",
        "recalculationIsInProgress": "Cannot perform the operation until the current recalculation is complete."
    },
    "commissioncalculations": {
        "affiliateUserNameOrId": "Affiliate Username Or ID",
        "affiliateId": "Affiliate ID",
        "affiliateUsername": "Affiliate Username",
        "calculationId": "Calculation ID",
        "from": "From",
        "to": "To",
        "currency": "Currency",
        "status": "Status",
        "sportSource": "Sport Source",
        "sportCommission": "Sport Commission",
        "casinoSource": "Casino Source",
        "casinoCommission": "Casino Commission",
        "depositFee": "Deposit Fee",
        "adjustmentFee": "Adjustment Fee",
        "sportAdminFee": "Sport Admin Fee",
        "casinoAdminFee": "Casino Admin Fee",
        "cpa": "CPA",
        "revshare": "RevShare",
        "totalSource": "Total Source",
        "fixed": "Fixed",
        "earning": "Earning",
        "paidReferralCommission": "Paid Referral Commission",
        "calculationDate": "Calculation Date",
        "approvedDate": "Approved Date",
        "pending": "Pending",
        "approved": "Approved",
        "commissionType": "Commission Type",
        "sourceType": "Source Type",
        "commissioncalculations": "Commission Calculations",
        "doYouWanttoApproveCalculation": "Are you sure you want to approve this Commission Calculation? After approval the transaction will be moved to the Report/Commission Calculation. Note that the operation cannot be rolled back."
    },
    "campaigns": {
        "createCampaign": "Create Campaign",
        "nameOrId": "Name or ID",
        "name": "Name",
        "brand": "Brand",
        "country": "Country",
        "url": "URL",
        "mediaTools": "Medias",
        "language": "Language",
        "availablility": "Availablility",
        "availablilityTooltip": "Public campaign will available for all affiliates. Private campaign will be available only for selected affiliates.",
        "private": "Private",
        "public": "Public",
        "creatives": "Creatives",
        "createdBy": "Created By",
        "createdAt": "Created At",
        "lastModifiedAt": "Last Modified At",
        "lastModifiedBy": "Last Modified By",
        "affiliates": "Affiliates",
        "general": "General",
        "description": "Description",
        "creativeNameOrId": "Creative Name or ID",
        "createCreative": "Create Creative",
        "creativeType": "Creative Type",
        "banner": "Banner",
        "bannerHTML": "HTML Banner",
        "dimension": "Dimension",
        "width": "Width",
        "height": "Height",
        "recommendedDimensions": "Recommended Dimensions",
        "web": "Web",
        "mobile": "Mobile",
        "size": "Size",
        "fileType": "File Type",
        "htmlFrameSize": "HTML Frame Size",
        "htmlCustomSize": "HTML Custom Size",
        "run": "Run",
        "uploadHTML": "Upload HTML",
        "editCreative": "Edit Creative",
        "deleteCreative": "Delete Creative",
        "doYouWanttoDeleteCreative": "Do You Want to Delete Creative?",
        "thumbnails": "Thumbnails",
        "mediaToolId": "Media Id",
        "mediaToolName": "Media Name",
        "affiliateId": "Affiliate Id",
        "affiliateUsername": "Affiliate Username",
        "mediaType": "Media Type",
        "brandName": "Brand Name",
        "brandId": "Brand Id",
        "campaignName": "Campaign Name",
        "campaignId": "Campaign Id",
        "activeMedias": "Active Medias",
        "modifiedAt": "Modified At",
        "modifiedBy": "Modified By",
        "createMedia": "Create Media",
        "trafficSource": "Traffic Source",
        "campaign": "Campaign",
        "directLink": "Direct Link",
        "creative": "Creative Code",
        "campaignLink": "Campaign Link",
        "selectCreative": "Select Creative",
        "mediaSuccessfullyCreated": "Media Successfully Created",
        "noCreativesFound": "No Creatives Found",
        "getCode": "Get Code",
        "mediaCode": "Media Code",
        "enable": "enable",
        "disable": "Disable",
        "enableMedia": "Enable Media",
        "disableMedia": "Disable Media",
        "doYouWanttoEnableMedia": "Are you sure you want to enable the Media Tool? Enabled Media Tool will collect all statistics of players.",
        "doYouWanttoDisableMedia": "Are you sure you want to disable the Media Tool? Disabled Media Tool will not collect statistics of players.",
        "editMedia": "Edit Media",
        "viewCount": "View Count",
        "clickCount": "Click Count",
        "activate": "Activate",
        "deactivate": "Deactivate",
        "campaignDeactivateInfo": "Do You Want to Deactivate Campaign?",
        "campaignActivateInfo": "Do You Want to Activate Campaign?",
    },
    "reports" : {
        "clicks": "Clicks",
        "mediaNameOrId": "Media Name Or ID",
        "brand": "Brand",
        "campaign": "Campaign",
        "country": "Country",
        "affiliateUsername": "Affiliate Username",
        "ipAddress": "IP Address",
        "status": "Status",
        "valid": "Valid",
        "invalid": "Invalid",
        "unknownAffiliate": "Unknown Affiliate",
        "affiliateHasNoAccessForCampaign": "Affiliate Has No Access For Campaign",
        "unknownMedia": "Unknown Media",
        "inactiveMedia": "Inactive Media",
        "unknownBrand": "Unknown Brand",
        "unknownCountry": "Unknown Country",
        "restrictedCountry": "Restricted Country",
        "unknownBtag": "Unknown Btag",
        "unknownCampaign": "Unknown Campaign",
        "inactiveCampaign": "Inactive Campaign",
        "clickId": "Click ID",
        "reason": "Reason",
        "clickDate": "Click Date",
        "mediaName": "Media Name",
        "deviceType": "Device Type",
        "deviceOS": "Device OS",
        "browser": "Browser",
        "referrer": "Referrer",
        "medias": "Medias",
        "mediaId": "Media ID",
        "mediaType": "Media Type",
        "product": "Product",
        "language": "Language",
        "affiliateId": "Affiliate ID",
        "trafficSource": "Traffic Source",
        "views": "Views",
        "validClicks": "Valid Clicks",
        "invalidClicks": "Invalid Clicks",
        "currency": "Currency",
        "playerRegistrations": "Player Registrations",
        "depositCount": "Deposit Count",
        "depositAmount": "Deposit Amount",
        "ftdCount": "FTD Count",
        "ftdAmount": "FTD Amount",
        "withdrawCount": "Withdraw Count",
        "withdrawAmount": "Withdraw Amount",
        "netDepositAmount": "Net Deposit Amount",
        "ggr": "GGR",
        "ngr": "NGR",
        "sportBetCount": "Sport Bet Count",
        "sportBetAmount": "Sport Bet Amount",
        "sportWonAmount": "Sport Win",
        "sportBonusWonAmount": "Sport Bonus Won",
        "sportGGR": "Sport GGR",
        "sportNGR": "Sport NGR",
        "casinoBetCount": "Casino Bet Count",
        "casinoBetAmount": "Casino Bet Amount",
        "casinoWonAmount": "Casino Win",
        "casinoBonusWonAmount": "Casino Bonus Won",
        "casinoGGR": "Casino GGR",
        "casinoNGR": "Casino NGR",
        "depositFee": "Deposit Fee",
        "totalRevShare": "Total RevShare",
        "affiliateUserNameOrId": "Affiliate Username Or ID",
        "perAffiliate": "Per Affiliate",
        "additional": "Additional",
        "affiliateStatus": "Affiliate Status",
        "deposit": "Deposit",
        "netDeposit": "Net Deposit",
        "sportBet": "Sport Bet",
        "casinoBet": "Casino Bet",
        "perPlayer": "Per Player",
        "playerUserNameOrId": "Player Username Or ID",
        "btag": "Btag",
        "playerId": "Player ID",
        "playerUsername": "Player Username",
        "registrationDate": "Registration Date",
        "adjustments": "Adjustments",
        "playerExternalId": "Player External ID",
        "generationId": "Generation ID",
        "sportTurnover": "Sport Turnover",
        "sportBonusTurnover": "Sport Bonus Turnover",
        "casinoTurnover": "Casino Turnover",
        "casinoBonusTurnover": "Casino Bonus Turnover",
        "crToReg": "CR To Reg",
        "revShareAmount": "Rev Share Amount",
        "cpaAmount": "CPA Amount",
        "sportAdminFee": "Sport Admin Fee",
        "casinoAdminFee": "Casino Admin Fee",
        "totalEarningTooltip": "The Total Earnings in this report do not include Referral Commission already paid by the affiliate to his parent affiliate. The final Total Earnings of the affiliate can be found in the Affiliate Performance Report.",
        "sportNGRTooltip": "Sport NGR = Sport GGR - Sport Bonus Won - Sport Admin Fee.",
        "casinoNGRTooltip": "Casino NGR = Casino GGR - Casino Bonus Won - Casino Admin Fee.",
        "ngrTooltip": "NGR = Sport NGR + Casino NGR - Deposit Fee - Player Balance Adjustment.",
        "sportRevShareTooltip": "Sport RevShare = Sport Source (GGR, NGR, Turnover) * Commision Percentage. In case the source is set to NGR, the Deduction of th Deposit Fee & Adjustment depends on specific Commission Configurations.",
        "casinoRevShareTooltip": "Casino RevShare = Casino Source (GGR, NGR, Turnover) * Commision Percentage. In case the source is set to NGR, the Deduction of th Deposit Fee & Adjustment depends on specific Commission Configurations.",
        "totalRevShareAmountTooltip": "1) If The Commissions for Sport & Casino are set separetly then: Total RevShare = Sport RevShare + Casino RevShare. 2) If there is one Commission set for Sport & Casino (Multi-Select) then: Total RevShare = (Sport Source + Casino Source) * Commission Percentage. In case the source is set to NGR, the Deduction of the Deposit Fee & Adjustments depends on specific Commissions Configurations."
    },
    "bets": {
        "settled": "Settled",
        "betId": "Bet ID",
        "betslipId": "Betslip ID",
        "provider": "Provider",
        "type": "Type",
        "single": "Single",
        "multi": "Multi",
        "system": "System",
        "date": "Date",
        "placedBy": "Placed By",
        "currency": "Currency",
        "odds": "Odds",
        "amount": "Amount",
        "possibleWin": "Possible Win",
        "win": "Winning",
        "calculatedAt": "Calculated At",
        "paidOutAt": "Paid out at",
        "status": "Status",
        "pending": "Open",
        "return": "Return",
        "won": "Won",
        "semiWon": "Semi-Won",
        "semiLost": "Semi-Lost",
        "lost": "Lost",
        "cancelled": "Cancelled",
        "belongsTo": "Belongs To",
        "showIn": "Show In",
        "originalCurrency": "Original Currency",
        "convertedCurrency": "Converted Currency",
        "ggr": "GGR",
        "bets": "Bets",
        "game": "Game",
        "market": "Market",
        "event": "Event",
        "eventId": "Event ID",
        "result": "Result",
        "bet": "Bet",
        "bonus": "Bonus",
        "lastUpdateDate": "Last Update Date",
        "playerUserName": "Player Username",
        "playerId": "Player ID",
        "betCount": "Bet Count",
        "winAmount": "Win Amount",
        "bonusWin": "Bonus Win",
        "bonusTurnover": "Bonus Turnover",
        "bonusGGR": "Bonus GGR",
        "ngr": "NGR",
        "aggregated": "Casino Bets (Aggregated)",
        "playerUsernameOrId": "Player Username or ID",
        "playerExternalId": "Player External ID",
        "rejectedByProvider": "Rejected By Provider",
        "sportBets": "Sport Bets",
        "bettingType": "Betting Type",
        "currencies": "Currencies",
        "totals": "Totals",
        "settledDate": "Settle Date",
        "dateType": "Date Type",
        "betDateTime": "Bet Date Time",
        "calculatedDateTime": "Calculated Date Time",
        "betType": "Bet Type",
        "betDate": "Bet Date",
        "factor": "Factor",
        "searchByBetslipId": "Search by Betslip ID"
    },
    "players": {
        "general": "General",
        "bets": "Casino Bets",
        "createPlayer": "Create Player",
        "id": "ID",
        "username": "Username",
        "firstName": "First Name",
        "lastName": "Last Name",
        "email": "Email",
        "status": "Status",
        "createdAt": "Created at",
        "createdBy": "Created by",
        "unknown": "Unknown",
        "partiallyBlocked": "Partially Blocked",
        "withdrawableBalance": "Withdrawable Balance",
        "lastUpdatedAt": "Last Updated at",
        "lastUpdateBy": "Last Updated by",
        "userNameOrId": "Username or Id",
        "externalId": "External ID",
        "currency": "Currency",
        "doYouWanttoBlockPlayer": "Are you sure you want to block this player?",
        "doYouWanttoUnblockPlayer": "Are you sure you want to unblock this player?",
        "findBy": "Find By",
        "playerId": "Player ID",
        "phoneNumber": "Phone Number",
        "find": "Find",
        "securityCode": "Security Code",
        "belongsTo": "Belongs To",
        "playerNotFound": "PlayerNotFound",
        "playerNotFoundInfo": "Please, make sure the player ID is correct",
        "playerReparentDone": "Reparent done successfully for %%X%% players",
        "playerReparentFailed": "Reparent failed for %%X%% out of %%Y%% players"
    },
    "users": {
        "general": "General",
        "companies": "Companies",
        "permissionsAndGroups": "Permissions And Groups",
        "permissions": "Permissions",
        "groups": "Groups",
        "projects": "Projects",
        "sessions": "Sessions",
        "createAdmin": "Create Admin",
        "id": "ID",
        "firstName": "First Name",
        "lastName": "Last Name",
        "username": "Username",
        "email": "Email",
        "mainCompany": "Main Company",
        "permissionGroups": "Permission Groups",
        "status": "Status",
        "createdAt": "Created at",
        "reason": "Reason",
        "lockedForFailed2FA": "Locked for failed 2FA",
        "lockedForFailedLogin": "Locked for failed login",
        "lastSuccessfulLogin": "Last successful login",
        "blockedDateTime": "Blocked Date & Time",
        "lastUpdatedAt": "Last Updated at",
        "lastLoginAt": "Last Login at",
        "lastPasswordChangeAt": "Last Password Change At",
        "lastBlockedAt": "Last Blocked at",
        "lastBlockedBy": "Last Blocked by",
        "forceLogout": "Force Logout",
        "forceLogoutConfirmationMessage": "Admins will be automatically logged out. This may cause termination of some actions which are being executed currently by admins. Do you want to continue?",
        "isTestAdmin": "Is a Test Admin",
        "userNameOrId": "Username or ID",
        "doYouWanttoUnlock": "Do you want to unlock this admin?",
        "doYouWanttoBlockAdmin": "Do you want to block this admin?",
        "doYouWanttoUnblockAdmin": "Do you want to unblock this admin?",
        "company": "Company",
        "password": "Password",
        "confirmPassword": "Confirm Password",
        "gender": "Gender",
        "address": "Address",
        "tel": "Tel",
        "allowEdit": "Allow editing personal info",
        "expiration": "User expiration",
        "expirationDate": "Expiration Date",
        "expirationConfirmMessage": "Do you want to remove expiration for this user? The status will be changed to active in case the user is already expired.",
        "forcePasswordChange": "Force Password Change",
        "permissionGroup": "Permission Group",
        "securityLevel": "Security Level",
        "uploadPhoto": "Upload Photo",
        "changePhoto": "Change Photo",
        "deletePhoto": "Delete Photo",
        "avatarUploadTooltip": "JPG, PNG, GIF, Max size of 1mb",
        "companyName": "Company Name",
        "addCompany": "Add Company",
        "deleteCompany": "Remove Company",
        "doYouWanttoDeleteCompany": "Do you want to remove this company?",
        "deleteGroup": "Remove Group",
        "doYouWanttoDeleteGroup": "Do you want to remove this group?",
        "addGroup": "Add Group",
        "projectId": "ID",
        "projectName": "Name",
        "addNewProject": "Add Project",
        "deleteProject": "Remove Project",
        "doYouWanttoDeleteProject": "Do you want to remove this Project?",
        "project": "Project",
        "allFutureProjects": "All future new projects will automatically be added to this user.",
        "createAccessManager": "Create Access Manager",
        "notes": "Notes",
        "requestId": "Request ID",
        "actionType": "Action Type",
        "date": "Date",
        "objectType": "Object Type",
        "permissionGroupModify": "Permission Group Modify",
        "addCompanyAccess": "Add Company Access",
        "deleteCompanyAccess": "Delete Company Access",
        "user": "User",
        "change": "Change",
        "addProjectAccess": "Add Project Access",
        "deleteProjectAccess": "Delete Project Access",
        "addPermissionGroup": "Add Permission Group",
        "deletePermissionGroup": "Delete Permission Group",
        "pendingRequestsInfoMessage": "There are %%count%% pending requests.",
        "rejected": "Rejected",
        "approved": "Approved",
        "modifiedAt": "Modified At",
        "modifiedBy": "Modified By",
        "history": "History",
        "searchByObjectId": "Search By Object ID",
        "isTestAccessManager": "Is a Test Access Manager",
        "changes": "Changes",
        "affiliate": "Affiliate",
        "affiliates": "Affiliates",
        "doYouWanttoDelete": "Do you want to delete this admin?",
        "createAffiliateManager": "Create Affiliate Manager",
        "isTestAffiliateManager": "Is a Test Affiliate Manager",
        "doYouWanttoUnlockAffiliateManager": "Do you want to unlock this affiliate manager?",
        "doYouWanttoBlockAffiliateManager": "Do you want to block this affiliate manager?",
        "doYouWanttoUnblockAffiliateManager": "Do you want to unblock this affiliate manager?",
        "affiliateManagersForceLogoutConfirmationMessage": "Affiliate managers will be automatically logged out. This may cause termination of some actions which are being executed currently by affiliate managers. Do you want to continue?",
        "removeAffiliate": "Remove Affiliate",
        "addAffiliate": "Add Affiliate",
        "doYouWanttoRemoveAffiliate": "Do you want to remove this affiliate?",
        "assignedAffiliates": "Assigned Affiliates"
    },
    "communication": {
        "triggerName": "Trigger Name",
        "registrationTrigger": "Registration",
        "forgotPasswordTrigger": "Forgot Password",
        "forceChangeTrigger": "Force Change",
        "declinedTrigger": "Declined",
        "approvedTrigger": "Approved",
        "blockedTrigger": "Blocked",
        "unblockedTrigger": "Unblocked",
        "modifiedAt": "Last Update Date",
        "modifiedBy": "Last Update By",
        "systemDefaultTemplate": "System Default Template",
        "emailSubject": "Email Subject",
        "email": "Email",
        "language": "Language",
        "registrationTriggerDescription": "Sent to affiliate after registration to verify the email.",
        "forgotPasswordTriggerDescription": "Sent to affiliate after account password was forgotten.",
        "approvedTriggerDescription": "Sent to affiliate after account was approved.",
        "declinedTriggerDescription": "Sent to affiliate after account was declined.",
        "blockedTriggerDescription": "Sent to affiliate after account was blocked.",
        "unblockedTriggerDescription": "Sent to affiliate after account was unblocked.",
        "mail": "Mail",
        "newsletters": "NewsLetters",
        "createdAt": "Created At",
        "createdBy": "Created By",
        "nameOrId": "Name or ID",
        "createNewsletter": "Create Newsletter",
        "newsletterName": "Newsletter Name",
        "chooseTemplate": "Choose Template",
        "backToTemplate": "Back To Template",
        "primaryColor": "Primary Color",
        "titleColor": "Title Color",
        "descriptionColor": "Description Color",
        "bgColor": "Background Color",
        "backgroundTopColor": "Background Top Color",
        "backgroundBottomColor": "Background Bottom Color",
        "banner": "Banner",
        "section1": "Section 1",
        "button": "Button",
        "section2": "Section 2",
        "title": "Title",
        "buttonText": "Button Text",
        "url": "URL",
        "renameNewsletter": "Rename Newsletter",
        "rename": "Rename",
        "duplicate": "Duplicate",
        "duplicateNewsletter": "Duplicate Newsletter",
        "doYouWantToDeleteNewsletter": "Are you sure you want to delete this Newsletter?",
        "preview": "Preview",
        "createEmail": "Create Email",
        "general": "General",
        "generalDescription": "Letter Subject",
        "affiliates": "Affiliates",
        "affiliateDescription": "Select an affiliate",
        "chooseNewsletter": "Choose Newsletter",
        "newsletter": "Newsletter",
        "subject": "Subject",
        "from": "From",
        "send": "Send",
        "sendTestEmail": "Send a Test Email",
        "filterAffiliates": "Filter Affiliates",
        "viewAffiliates": "View Affiliates",
        "addFilter": "Add Filter",
        "filter": "Filter",
        "status": "Status",
        "country": "Country",
        "registrationDate": "Registration Date",
        "assignedManager": "Assigned Manager",
        "targetMarket": "Target Market",
        "affiliatesPlayerCount": "Affiliates Player Count",
        "showAffiliates": "Show Affiliates",
        "sendDate": "Send Date",
        "sentBy": "Sent By",
        "received": "Received",
        "opened": "Opened",
        "clicked": "Clicked",
        "sent": "Sent",
        "scheduled": "Scheduled",
        "changeSchedule": "Change Schedule",
        "scheduleDate": "Schedule Date",
        "lastUpdatedAt": "Last Updated at",
        "lastUpdatedBy": "Last Updated by",
        "receivers": "Receivers"
    },
    "permissions": {
        "resources": "Resources",
        "selectAll": "Select All",
        "deSelectAll": "Deselect All",
        "View": "View",
        "Create": "Create",
        "Modify": "Modify",
        "Delete": "Delete",
        "Export": "Export",

		"Company": "Company",
		"Company_View": "Allows to view Companies list.",
		"Company_Create": "Allows to create new Companies.",
		"Company_GeneralInfo": "General Info",
		"Company_GeneralInfo_View": "Allows to view Companies General info.",
		"Company_GeneralInfo_Modify": "Allows to edit Companies General info.",
		"Company_SecuritySettings": "Security Settings",
		"Company_SecuritySettings_View": "Allows to view Companies Security settings (Password, Token settings, IP restrictions).",
		"Company_SecuritySettings_Modify": "Allows to edit Companies Security settings (Password, Token settings, IP restrictions).",

		"Project": "Project",
		"Project_View": "Allows to view Projecs List.",
		"Project_Create": "Allows to create new Projects.",
		"Project_Export": "Allows to export project's list.",
		"Project_GeneralInfo": "Project's General info",
		"Project_GeneralInfo_View": "Allows to view project's General info.",
		"Project_GeneralInfo_Modify": "Allows to change project's General info.",
		"Project_Domains": "Project's Domains",
		"Project_Domains_View": "Allows to view project's Domains.",
        "Project_Domains_Create": "Allows to create project's Domain.",
		"Project_Domains_Modify": "Allows to change project's Domain.",
        "Project_Domains_Delete": "Allows to delete project's Domain.",
		"Project_LanguageSettings": "Language Settings",
		"Project_LanguageSettings_View": "Allows to view Project's Language Settings.",
		"Project_LanguageSettings_Modify": "Allows to add Language to Project.",
		"Project_Currencies": "Currencies",
		"Project_Currencies_View": "Allows to view Project's Currencies.",
		"Project_Currencies_Create": "Allows to add new Currencies to the Project.",
		"Project_Currencies_Modify": "Allows to edit Project's Currency settings.",
		"Project_Currencies_Delete": "Allows to delete Projects Currencies.",
		"Project_CommissionPlan": "Commission Plans",
		"Project_CommissionPlan_View": "Allows to view Project's Commission Plans.",
		"Project_CommissionPlan_Create": "Allows to create new Commission Plans.",
		"Project_CommissionPlan_Modify": "Allows to modify Project's Commission Plans.",
		"Project_CommissionPlan_Delete": "Allows to delete Project's Commission Plans.",
		"Project_RegistrationConfiguration": "Project's Registration Configuration",
		"Project_RegistrationConfiguration_View": "Allows to view Project's Registration Form Configuration.",
		"Project_RegistrationConfiguration_Modify": "Allows to change project's Registration Form Configuration.",
		"Project_Integration": "Project's integration settings",
		"Project_Integration_View": "Allows to view Project's  integration settings.",
		"Project_Integration_Modify": "Allows to change Project's integration settings",
		"Project_PaymentMethods": "Project's payment methods",
		"Project_PaymentMethods_View": "Allows to view Project's payment methods",
		"Project_PaymentMethods_Create": "Allows to add new payment method to project",
		"Project_PaymentMethods_Modify": "Allows to change Project's payment method",
		"Project_PaymentMethods_Delete": "Allows to delete payment method from project",
		"Project_Promo": "Promo",
		"Project_Promo_View": "Allows to view promo site.",
		"Project_Promo_Create": "Allows to setup promo site.",
		"Project_Promo_Modify": "Allows to modify promo site.",
		"Project_Brands": "Brands",
		"Project_Brands_View": "Allows to view project brands list.",
		"Project_Brands_Create": "Allows to add brand to project.",
		"Project_Brands_Modify": "Allows to modify project brand.",
		"Project_Brands_Delete": "Allows to delete brand from project.",
		"Project_BrandUrls": "Brand Urls",
		"Project_BrandUrls_View": "Allows to view projects brand urls.",
		"Project_BrandUrls_Create": "Allows to add brand urls.",
		"Project_BrandUrls_Modify": "Allows to modify projects brand urls.",
		"Project_BrandUrls_Delete": "Allows to delete brand urls.",
		"Project_ReferralSettings": "Project's referral settings",
		"Project_ReferralSettings_View": "Allows to view Project's referral settings.",
		"Project_ReferralSettings_Modify": "Allows to change Project's referral settings",
		"Project_PayoutSettings": "Project's payout settings",
		"Project_PayoutSettings_View": "Allows to view Project's payout settings.",
		"Project_PayoutSettings_Modify": "Allows to change Project's payout settings",
        "Project_AffiliateAccessSettings": "Project's access settings",
		"Project_AffiliateAccessSettings_View": "Allows to view Project's access settings.",
		"Project_AffiliateAccessSettings_Modify": "Allows to change Project's access settings",
        "Project_CommissionRecalculation": "Commission Recalculation",
        "Project_CommissionRecalculation_Modify": "Allows to recalculate commission.",

		"SystemLanguages": "System Languages",
		"SystemLanguages_View": "Allows to view System Languages.",
		"SystemLanguages_Create": "Allows to add new Languages to the System.",
		"SystemLanguages_Export": "Allows to export System Languages.",

		"SystemCurrencies": "System Currencies",
		"SystemCurrencies_View": "Allows to view System Currencies.",
		"SystemCurrencies_Create": "Allows to add new Currencies to the System.",
		"SystemCurrencies_Modify": "Allows to edit System Currencies (rate, symbol).",
		"SystemCurrencies_Export": "Allows to export System Currencies.",
		
        "SystemPaymentMethods": "Payment Methods",
		"SystemPaymentMethods_View": "Allows to view Payment Methods.",
		"SystemPaymentMethods_Create": "Allows to create new Payment Method.",
		"SystemPaymentMethods_Modify": "Allows to edit Payment Method.",
		"SystemPaymentMethods_Delete": "Allows to delete Payment Method.",
		"SystemPaymentMethods_Export": "Allows to export Payment Methods.",
		
        "Permission": "Permissions",
		"Permission_View": "Allows to view available Permissions with descriptions.",
		"Permission_Group": "Permission groups",
		"Permission_Group_View": "Allows to view Permission Groups general data.",
		"Permission_Group_Create": "Allows to create new Permission Groups for Companies.",
		"Permission_Group_Modify": "Allows to edit Permission Groups.",
		"Permission_Group_Delete": "Allows to delete Permission Groups.",
		
        "Admin": "Admins",
		"Admin_View": "Allows to view Users List.",
		"Admin_Create": "Allows to create new Users.",
		"Admin_Modify": "Allows to change user's state and set their passwords.",
		"Admin_Delete": "Allows to delete Admin user.",
		"Admin_Export": "Allows to export Users List.",
		"Admin_GeneralInfo": "General Info",
		"Admin_GeneralInfo_View": "Allows to modify users general info.",
		"Admin_GeneralInfo_Modify": "Allows to modify users general info, updload and delete profile's avatars.",
		"Admin_ForceLogout": "Force Logout",
		"Admin_ForceLogout_Modify": "Allows to Force Logout all the users in the table.",
		"Admin_Sessions": "Admin's Sessions",
		"Admin_Sessions_View": "Allows to view users sessions.",
		"Admin_Sessions_Export": "Allows to export users sessions.",
		"Admin_CompanyAccess": "Admin's Company accesses",
		"Admin_CompanyAccess_View": "Allows to view users Companies access.",
		"Admin_CompanyAccess_Create": "Allows to add Company access.",
		"Admin_CompanyAccess_Delete": "Allows to remove Company access.",
		"Admin_ProjectAccess": "Admin's Project accesses",
		"Admin_ProjectAccess_View": "Allows to view users Projects access.",
		"Admin_ProjectAccess_Create": "Allows to add Project access.",
		"Admin_ProjectAccess_Delete": "Allows to remove Project access.",
		"Admin_Permissions": "Admin's permissions and groups",
		"Admin_Permissions_View": "Allows to view admins permissions and groups.",
		"Admin_Permissions_Create": "Allows to add permissions and groups to the admins.",
		"Admin_Permissions_Modify": "Allows to add permissions the the admins.",
		"Admin_Permissions_Delete": "Allows to delete permissions and groups from admins.",

		"Affiliate": "Affiliates",
		"Affiliate_View": "Allows to view Affiliate's list.",
		"Affiliate_Create": "Allows to create new Affiliates for Partner/s.",
		"Affiliate_Modify": "Allows to block affiliate's, set their passwords.",
		"Affiliate_Delete": "Allows to delete affiliate.",
		"Affiliate_Export": "Allows to export affiliates list.",
		"Affiliate_GeneralInfo": "Affiliate's General info.",
		"Affiliate_GeneralInfo_View": "Allows to view Affiliate's general info.",
		"Affiliate_GeneralInfo_Modify": "Allows to modify Affiliate's general info.",
		"Affiliate_CommissionPlan": "CommissionPlan",
		"Affiliate_CommissionPlan_View": "Allows to view Affiliate's Commission Plans.",
		"Affiliate_CommissionPlan_Create": "Allows to add new Commission plan  for Affiliate.",
		"Affiliate_CommissionPlan_Modify": "Allows to edit Affiliate's Commission plan.",
		"Affiliate_CommissionPlan_Delete": "Allows to remove Affiliate's Commission plan.",
		"Affiliate_Sessions": "Affiliate's Sessions",
		"Affiliate_Sessions_View": "Allows to view affiliate's sessions.",
		"Affiliate_Sessions_Export": "Allows to export affiliate's sessions.",
		"Affiliate_Wallets": "Affiliate's Wallets",
		"Affiliate_Wallets_View": "Allows to view affiliate's wallets history.",
		"Affiliate_Wallets_Export": "Allows to export affiliate's wallets history.",
		"Affiliate_Wallets_Modify": "Allows to change affiliate's wallets.",
		"Affiliate_PaymentMethods": "Affiliate's payment methods",
		"Affiliate_PaymentMethods_View": "Allows to view Affiliate's payment methods",
		"Affiliate_PaymentMethods_Create": "Allows to add new payment method to affiliate",
		"Affiliate_PaymentMethods_Modify": "Allows to change Affiliate's payment method",
		"Affiliate_PaymentMethods_Delete": "Allows to delete payment method from affiliate",
		"Affiliate_Groups": "Affiliate Groups",
		"Affiliate_Groups_View": "Allows to view affiliate groups",
		"Affiliate_Groups_Create": "Allows to add new affiliate group",
		"Affiliate_Groups_Modify": "Allows to change affiliate group",
		"Affiliate_Groups_Delete": "Allows to delete affiliate groups",

		"Player": "Players",
		"Player_View": "Allows to view player list.",
		"Player_Create": "Allows to create new players.",
		"Player_Modify": "Allows to set players password, change state and reparent players.",
		"Player_Export": "Allows to export players.",
		"Player_GeneralInfo": "Players General info",
		"Player_GeneralInfo_View": "Allows to view  players general info.",

		"Translation": "Translations",
		"Translation_View": "Allows to view translations.",
		"Translation_Create": "Allows to create new translation.",
		"Translation_Modify": "Allows to edit translation.",

		"Developer": "Developer space",
		"Developer_View": "Allows to view Developer space.",
		"Developer_Modify": "Allows to modify Developer space.",

		"UserLogs": "User Logs",
		"UserLogs_View": "Allows to view User Logs.",
		"UserLogs_Export": "Allows to export User Logs.",

		"AffiliateManager": "Affiliate Manager",
		"AffiliateManager_View": "Allows to view Affiliate managers List.",
		"AffiliateManager_Create": "Allows to create affiliate managers.",
		"AffiliateManager_Modify": "Allows to modify affiliate manager.",
		"AffiliateManager_Export": "Allows to export affiliate managers List.",
		"AffiliateManager_GeneralInfo": "Affiliate Managers General Info",
		"AffiliateManager_GeneralInfo_View": "Allows to view affiliate managers general info.",
		"AffiliateManager_GeneralInfo_Modify": "Allows to modify affiliate managers general info.",
		"AffiliateManager_Sessions": "Affiliate Managers Sessions",
		"AffiliateManager_Sessions_View": "Allows to view affiliate managers sessions.",
		"AffiliateManager_Affiliates": "Affiliate Managers Affiliates List",
		"AffiliateManager_Affiliates_View": "Allows to view affiliate managers affiliates list.",
		"AffiliateManager_Affiliates_Modify": "Allows to assign / remove affiliates to / from affiliate manager.",

		"Communication": "Communication",
		"Communication_View": "Allows to view communications section.",
		"Communication_SystemTemplates": "System email templates",
		"Communication_SystemTemplates_View": "Allows to view system email templates.",
		"Communication_SystemTemplates_Modify": "Allows to modify system email templates.",
		"Communication_ProjectTemplates": "Project email templates",
		"Communication_ProjectTemplates_View": "Allows to view project email templates.",
		"Communication_ProjectTemplates_Modify": "Allows to modify project email templates.",
		"Communication_Newsletters": "Project newsletter templates",
		"Communication_Newsletters_View": "Allows to view project newsletter templates.",
		"Communication_Newsletters_Modify": "Allows to modify project newsletter templates.",
		"Communication_Newsletters_Create": "Allows to create newsletter templates.",
		"Communication_Newsletters_Delete": "Allows to delete newsletter templates.",

		"AccessManager": "Access Managers",
		"AccessManager_View": "Allows to view access manager list.",
		"AccessManager_Create": "Allows to create new access manager.",
		"AccessManager_Modify": "Allows to change access manager's state and set their passwords.",
		"AccessManager_Export": "Allows to export access managers list.",
		"AccessManager_GeneralInfo": "General Info",
		"AccessManager_Sessions": "Access Manager's Sessions",
		"AccessManager_GeneralInfo_View": "Allows to modify access manager general info.",
		"AccessManager_GeneralInfo_Modify": "Allows to modify access manager general info, updload and delete profile's avatars.",
		"AccessManager_Sessions_View": "Allows to view access managers sessions.",
		"AccessManager_Sessions_Export": "Allows to export access managers sessions.",

		"Permission_Requests": "Permission Requests",
		"Permission_Requests_View": "Allows to view permission requests data.",
		"Permission_Requests_Modify": "Allows to approve/reject permission request.",

		"MarketingTools": "Marketing",
		"MarketingTools_View": "Allows to view marketing section.",
		"MarketingTools_Campaign": "Campaign",
		"MarketingTools_Campaign_View": "Allows to view campaigns",
		"MarketingTools_Campaign_Create": "Allows to create campaign",
		"MarketingTools_Campaign_Modify": "Allows to modify campaign",
		"MarketingTools_Campaign_Delete": "Allows to delete campaign",
		"MarketingTools_Creative": "Creative",
		"MarketingTools_Creative_View": "Allows to view creatives",
		"MarketingTools_Creative_Create": "Allows to create creative",
		"MarketingTools_Creative_Modify": "Allows to modify creative",
		"MarketingTools_Creative_Delete": "Allows to delete creative",
		"MarketingTools_Media": "Media",
		"MarketingTools_Media_View": "Allows to view medias",
		"MarketingTools_Media_Create": "Allows to create media",

		"Payments": "Payments",
		"Payments_View": "Allows to view payments section.",
		"Payments_Invoices": "Invoices",
		"Payments_Invoices_View": "Allows to view invoice history.",
		"Payments_Invoices_Create": "Allows to create invoices.",
		"Payments_Invoices_Modify": "Allows to reject / approve invoices.",
		"Payments_Invoices_Delete": "Allows to delete invoices.",
		"Payments_Invoices_Export": "Allows to export invoices.",
		"Payments_WalletsHistory": "Wallets History",
		"Payments_WalletsHistory_View": "Allows to view wallets history.",
		"Payments_WalletsHistory_Modify": "Allows to roll back transactions.",
		"Payments_WalletsHistory_Export": "Allows to export wallets history.",
		"Payments_CommissionCalculations": "Commission Calculations History",
		"Payments_CommissionCalculations_View": "Allows to view Commission Calculations History.",
		"Payments_CommissionCalculations_Export": "Allows to export Commission Calculations History.",

		"Report": "Report",
		"Report_View": "Allows to view reports section.",
		"Report_Clicks": "Clicks",
		"Report_Clicks_View": "Allows to view clicks.",
		"Report_Clicks_Export": "Allows to export clicks.",
		"Report_MediaPerformance": "Media Performance",
		"Report_MediaPerformance_View": "Allows to view media performance report.",
		"Report_MediaPerformance_Export": "Allows to export media performance report.",
		"Report_AffiliatePerformance": "Affiliate Performance",
		"Report_AffiliatePerformance_View": "Allows to view Affiliate performance report.",
		"Report_AffiliatePerformance_Export": "Allows to export Affiliate performance report.",
		"Report_AffiliatePerformanceTotals": "Affiliate Performance Totals",
		"Report_AffiliatePerformanceTotals_View": "Allows to view Affiliate performance totals report.",
		"Report_PlayerPerformance": "Player Performance",
		"Report_PlayerPerformance_View": "Allows to view player performance report.",
		"Report_PlayerPerformance_Export": "Allows to export player performance report.",
		"Report_PlayerPerformanceTotals": "Player Performance Totals",
		"Report_PlayerPerformanceTotals_View": "Allows to view player performance totals report.",
		"Report_AggregationHistory": "Aggregation History",
		"Report_AggregationHistory_View": "Allows to view aggregation history report.",
		"Report_AggregationHistory_Export": "Allows to export aggregation history report.",

		"Dashboard": "Dashboard",
		"Dashboard_View": "Allows to view Dashboard.",
		"Dashboard_Financial": "Financial Dashboard",
		"Dashboard_Financial_View": "Allows to view Financial Dashboard.",
        "Dashboard_Operational": "Operational Dashboard",
		"Dashboard_Operational_View": "Allows to view Operational Dashboard."
	},
    "dashboard": {
		"today": "Today",
		"yesterday": "Yesterday",
		"lastWeek": "Last Week",
		"thisWeek": "This Week",
		"lastMonth": "Last Month",
		"thisMonth": "This Month",
		"lastQuarter": "Last Quarter",
        "last90Days": "Last 90 Days",
		"ytd": "YTD",
		"registrations": "Registrations",
		"byPlayer": "By Player",
		"playersCount": "Players Count",
		"totalCountForAllTime": "Total count for all time",
		"total": "Total",
		"usersCount": "Users Count",
		"lastUpdatedAt": "Last updated at",
		"week": "Week",
		"ggr": "GGR",
		"ngr": "NGR",
		"turnover": "Turnover",
		"commissions": "Commissions",
		"netDeposit": "Net Deposit",
		"player": "Player",
		"id": "ID",
		"username": "Username",
		"amount": "Amount",
		"count": "Count",
		"depositAmount": "Deposit Amount",
		"withdrawAmount": "Withdraw Amount",
		"topPlayers": "Top Players",
		"deposit": "Deposit",
		"withdrawal": "Withdrawal",
		"withdraw": "Withdraw",
		"filterDirectAffiliates": "Income Type",
		"currency": "Currency",
		"timePeriod": "Period",
        "general": "General",
        "totalAffiliateCount": "Total Affiliate Count",
        "totalMediaCount": "Total Media Count",
        "totalCampaignCount": "Total Campaign Count",
        "totalPlayerCount": "Total Player Count",
        "affiliate": "Affiliate",
        "campaign": "Campaign",
        "media": "Media",
        "custom": "Custom",
        "registration": "Registration",
        "depositCount": "Deposit Count",
        "topAffiliates": "Top Affiliates",
        "topPlayers": "Top Players",
        "perPlayer": "Per Player",
        "perMedia": "Per Media",
        "perSubAffiliates": "Per Sub-Affiliates",
        "dataType": "Data Type",
        "country": "Country",
        "targetMarket": "Target Market",
        "liveBalances": "Live Balances",
        "gamingRevenue": "Game Revenue",
        "qualifiedDepositors": "Qualified Depositors",
        "ftds": "FTDs",
        "earning": "Earning",
        "playerDeposit": "Player Deposit",
        "ftdAmount": "FTD Amount",
        "cpa": "CPA",
        "affiliateEarning": "Affiliate Earning",
        "approvedCpa": "Approved CPA",
        "top10Country": "Top 10 Country",
        "top10Campaign": "Top 10 Campaign",
        "view": "View",
        "validClick": "Valid Click",
        "invalidClick": "Invalid Click",
        "views": "Views",
        "validClicks": "Valid Clicks",
        "invalidClicks": "Invalid Clicks",
        "crToReg": "CR to Reg",
        "marketingPerformance": "Marketing Performance"
	},
	"permissiongroups": {
		"general": "General",
		"admins": "Admins",
		"createPermissionGroup": "Create Permission Group",
		"name": "Name",
		"description": "Description",
		"userCount": "User Count",
		"createdAt": "Created at",
		"lastUpdatedAt": "Last Updated at",
		"lastUpdatedBy": "Last Updated by",
		"deletePermissionGroup": "Delete Permission Group",
		"doYouWanttoDeletePermissionGroup": "Do you want to delete this group?",
		"securityLevel": "Security Level",
		"userid": "User Id",
		"username": "Username",
		"addedAt": "Added at",
		"addedBy": "Added by",
		"addAdmin": "Add Admin",
		"deleteAdmin": "Delete Admin",
		"doYouWanttoDeleteAdmin": "Do you want to remove admin from this group?",
		"usernameOrId": "Username Or ID",
	},
	"sessions": {
		"sessions": "sessions",
		"sessionId": "Session Id",
		"sessionStart": "Session Start",
		"sessionEnd": "Session End",
		"sessionDuration": "Session Duration",
		"loginIp": "Login IP",
		"country": "Country",
		"userName": "Username",
		"printed": "Printed",
		"previousUserId": "Previous User ID",
		"previousUserName": "Previous Username",
	},
	"translations": {
		"resource": "Resource",
		"publish": "Publish",
		"unpublish": "Unpublish",
		"unpublishMessage": "Are you sure you want to unpublish the translation?",
		"createTranslation": "Create Translation",
		"translation": "Translation",
		"translationKey": "Translation Key",
		"addNewTranslation": "Add New Translation",
		"language": "Language",
		"systemDefaultTranslations": "System Default Translations",
		"systemDefault": "System Default",
		"keys": "Keys",
		"resetToDefault": "Reset to default",
	},
	"notifications": {
		"noNotifications": "No Notifications",
		"notifications": "Notifications",
	},
	"userlogs": {
		"date": "Date",
		"user": "User",
		"resource": "Resource",
		"resourceId": "Resource ID",
		"resourceName": "Resource Name",
		"action": "Action",
		"actionType": "Action Type",
		"browser": "Browser",
		"deviceType": "Device type",
		"ip": "IP",
		"os": "OS",
		"changes": "Changes",
		"name": "Name",
		"previousValue": "Previous Value",
		"currentValue": "Current Value",
		"actionType1": "Create",
		"actionType2": "Delete",
		"actionType3": "Add",
		"actionType4": "Remove",
		"actionType5": "Edit",
		"userNameOrId": "Username or ID",
		"logHistory": "Log History",
		"actionType6": "Export",
		"actionType7": "Login",
		"previousUser": "Previous User",
	},
	errorlogs: {
		controller: "Controller",
		action: "Action",
		ip: "IP",
		time: "Time",
		message: "Message",
		id: "ID",
		userNameOrId: "User Name Or Id",
		stackTrace: "StackTrace",
		apiType: "API Type",
		backoffice: "Backoffice",
		chat: "Chat",
		job: "Job",
		integration: "Integration",
		"controller/action": "Controller/Action",
		platform: "Platform",
		status: "Status",
		notFound: "Not Found",
		unauthorized: "Unauthorized",
		badRequest: "Bad Request",
		forbidden: "Forbidden",
		headers: "Headers",
		request: "Request",
		response: "Response",
		project: "Project",
		method: "Method",
		direction: "Direction",
		out: "Out",
		in: "In",
		url: "URL",
		content: "Content",
		body: "Body",
		queryStrings: "Query Strings",
	},
	jobs: {
		state: "State",
		state0: "Running",
		state1: "Success",
		state2: "Failed",
		startTime: "Start Time",
		nextFireTime: "Next Fire Time",
		serverName: "Server Name",
		elapsedMilliseconds: "Elapsed Milliseconds",
		unlock: "Unlock",
		editJob: "Edit Job",
		cronExpression: "Cron Expression",
		cronDescription: "Cron Description",
		allowedServerNames: "Allowed Server Names",
		name: "Name",
	},
	monitoring: {
		lastHour: "Last Hour",
		last24Hour: "Last 24 Hours",
		warning: "Warning",
		warningMsg: "had %COUNT% failure cases during the last 24 hours",
		count: "Count",
	},
	platforms: {
		platform: "Platform",
		test: "Test",
		name: "Name",
		message: "Message",
		details: "Details",
		description: "Description",
		testCases: "Test Cases",
	},
	validation: {
		fieldRequired: "The field is required",
		fieldInvalid: "The field is invalid",
		passwordRequired: "Please input your password",
		emailFormat: "Valid Format: test@example.com",
		telFormat: "+1234567890",
		mustBeMore: "Must be more than (or equal to) %X%",
		mustBeLess: "Must be less than (or equal to) %X%",
		mustBeBetween: "Must be between %X% and %Y%",
		passwordsDoNotMatch: "Passwords do not match.",
		passwordRulesMustBeBetween: "Must be between %X% and %Y% characters.",
		passwordRulesMustContain: "Must contain at least 1 %X%",
		passwordRulesMustNotContain: "Must not contain %X%",
		passwordRulesMustMatchRegularExpression:
			"Must match the Regular expression sample %X%",
		passwordRulesMustInclude: "Must include the following characters '%X%'",
		shouldUploadImg: "Should Upload Image",
	},
	resources: {
		Company: "Company",
		Company_GeneralInfo: "Company General Info",
		Company_SecuritySettings: "Company Security Settings",
		Company_Language: "Company Language",
		SystemCurrency: "System Currency",
		SystemLanguage: "System Language",
		SystemCountry: "System Country",
		SystemCounter: "System Counter",
		SystemLog: "System Log",
		Account: "Account",
		XmppAccount: "Xmpp Account",
		Project: "Project",
		Project_Currency: "Project_Currency",
		Project_GeneralInfo: "Project General Info",
		Country: "Country",
		Currency: "Currency",
		Language: "Language",
		CommissionPlan: "Commission Plan",
		Captcha: "Captcha",
		Permission: "Permission",
		Permission_Group: "Permission Group",
		SecurityLevel: "Security Level",
		User_Permissions: "User Permissions",
		File: "File",
		Translation: "Translation",
		Translation_Group: "Translation Group",
		Translation_SubGroup: "Translation SubGroup",
		UserLogs: "User Logs",
		Player: "Player",
		Bet: "Bet",
		BetSlip: "BetSlip",
		Session: "Session",
		Platform: "Platform",
		Notification: "Notification",
		Transaction: "Transaction",
		Job: "Job",
	},
	errors: {
		Exist: "%resource% exists",
		NotFound: "%resource% was not found",
		NotCreated: "%resource% was not created",
		NotSaved: "%resource% was not saved",
		InvalidParameters: "Invalid Parameters for %resource%",
		DontHavePermission: "%resource% does not have permission.",
		ImpermissibleType: "Impermissible type for %resource%.",
		DuplicateType: "Duplicate type for %resource%.",
		Unauthorized: "Unauthorized.",
		NotAllowed: "Action is not allowed.",
		FileNotFound: "File was not found.",
		InvalidFileSize: "File size is invalid.",
		InvalidFile: "File format is invalid.",
		WasBlocked: "%resource% is blocked.",
		WrongToken: "Wrong token.",
		WrongLoginParameters: "%resource% Wrong Login Parameters.",
		InternalServerError: "Internal Server Error",
		AttempsLimitReached: "Attempts Limit Reached",
		UsedPassword:
			"You cannot use your previous passwords as your new password.",
		InvalidCaptcha: "Captcha Alert! Wrong Login Parameters!",
		NotEnoughBalance: "Not Enough Balance",
		CannotBeDeleted: "%resource% cannot be deleted.",
		LimitExceeded: "%resource% Limit Exceeded",
		NotAvailable: "%resource% is not available",
		AccountSuspended: "Your account has been suspended",
	},
	weekdays: {
		monday: "Monday",
		tuesday: "Tuesday",
		wednesday: "Wednesday",
		thursday: "Thursday",
		friday: "Friday",
		saturday: "Saturday",
		sunday: "Sunday",
		mon: "Mon",
		tue: "Tue",
		wed: "Wed",
		thu: "Thu",
		fri: "Fri",
		sat: "Sat",
		sun: "Sun",
	},
	months: {
		month1: "January",
		month2: "February",
		month3: "March",
		month4: "April",
		month5: "May",
		month6: "June",
		month7: "July",
		month8: "August",
		month9: "September",
		month10: "October",
		month11: "November",
		month12: "December",
	},
	payments: {
		addPaymentMethod: "Add Payment Method",
		editPaymentMethod: "Edit Payment Method",
		name: "Name",
		projects: "Projects",
		project: "Project",
		labels: "Labels",
		createdAt: "Created at",
		createdBy: "Created by",
		logo: "Logo",
		general: "General",
		doYouWantToDeletePayment: "Are you sure you want to delete Payment ?",
		createSystemPayment: "Add Payment Method",
		method: "Payment Method",
		requestFields: "Request Fields",
		headerInfo: "JPG, PNG, GIF. Max size of 1mb",
		Email: "Email",
		PhoneNumber: "Phone Number",
		DocumentNumber: "Document Number",
		FirstName: "FirstName",
		LastName: "LastName",
		AccountHolder: "Account Holder",
		AccountNumber: "Account Number",
		Address: "Address",
		translations: "Translations",
		fieldName: "Field Name",
		value: "Value",
	},
	invoices: {
		pending: "Pending",
		history: "History",
		affiliateUserNameOrId: "Affiliate Username or ID",
		searchByInvoiceId: "Search By Invoice ID",
		paymentMethod: "Payment Method",
		invoiceId: "Invoice ID",
		affiliateId: "Affiliate ID",
		username: "Username",
		amount: "Amount",
		createdBy: "Created By",
		createdDate: "Created Date",
		modifiedDate: "Modified Date",
		modifiedBy: "Modified By",
		paymentDate: "Payment Date",
		status: "Status",
		createInvoice: "Create Invoice",
		affiliate: "Affiliate",
		currency: "Currency",
		note: "Note",
		screenshot: "Screenshot",
		editInvoice: "Edit Invoice",
		cancelInvoice: "Cancel Invoice",
		doYouWanttoCancelInvoice: "Are you sure you want to cancel this invoice?",
		pay: "Pay",
		payInvoice: "Pay Invoice",
		payInvoiceNote:
			"Please note that once the Invoice is paid it cannot be cancelled",
		paid: "Paid",
		canceled: "Canceled",
		deleted: "Deleted",
		downloadPDF: "Download PDF",
		pendingInvoice: "Pending Invoice",
		paidInvoice: "Paid Invoice",
		deleteInvoice: "Delete Invoice",
		doYouWanttoDeleteInvoice: "Are you sure you want to delete this invoice?",
		transactionCommission: "Transaction Commission",
		commissionAmount: "Commission Amount",
		requisiteDetails: "Requisite Details",
	},
	generations: {
		generation: "Generation",
		chunks: "Chunks",
		count: "Count",
		time: "Time",
		aggregatedBets: "Aggregated Bets",
		sportBets: "Sport Bets",
		sportOtherCosts: "Sport Other Costs",
		type: "Type",
	},
	countries: {
		AFG: "Afghanistan",
		PHL: "Philippines",
		ALA: "Åland Islands",
		POL: "Poland",
		DZA: "Algeria",
		PCN: "Pitcairn",
		PER: "Peru",
		ASM: "American Samoa",
		ALB: "Albania",
		PRT: "Portugal",
		AND: "Andorra",
		ETH: "Ethiopia",
		SWZ: "Eswatini",
		FJI: "Fiji",
		GNQ: "Equatorial Guinea",
		SLV: "El Salvador",
		FRO: "Faroe Islands",
		EST: "Estonia",
		ERI: "Eritrea",
		FIN: "Finland",
		FLK: "Falkland Islands (Malvinas)",
		AGO: "Angola",
		PRI: "Puerto Rico",
		ATA: "Antarctica",
		QAT: "Qatar",
		RUS: "Russian Federation",
		BLM: "Saint Barthélemy",
		REU: "Réunion",
		ARG: "Argentina",
		ATG: "Antigua and Barbuda",
		RWA: "Rwanda",
		ROU: "Romania",
		AIA: "Anguilla",
		AUT: "Austria",
		ABW: "Aruba",
		SHN: "Saint Helena, Ascension and Tristan da Cunha",
		BHR: "Bahrain",
		ARM: "Armenia",
		LCA: "Saint Lucia",
		VCT: "Saint Vincent and the Grenadines",
		BHS: "Bahamas",
		BEL: "Belgium",
		BGD: "Bangladesh",
		BLR: "Belarus",
		FRA: "France",
		GUF: "French Guiana",
		PYF: "French Polynesia",
		GAB: "Gabon",
		KNA: "Saint Kitts and Nevis",
		DEU: "Germany",
		AUS: "Australia",
		LKA: "Sri Lanka",
		WSM: "Samoa",
		ATF: "French Southern Territories",
		SAU: "Saudi Arabia",
		SPM: "Saint Pierre and Miquelon",
		ESP: "Spain",
		SEN: "Senegal",
		GHA: "Ghana",
		MAF: "Saint Martin (French part)",
		SRB: "Serbia",
		GMB: "Gambia",
		GRC: "Greece",
		GEO: "Georgia",
		BRB: "Barbados",
		STP: "Sao Tome and Principe",
		GIB: "Gibraltar",
		BMU: "Bermuda",
		SSD: "South Sudan",
		GRL: "Greenland",
		GUM: "Guam",
		GLP: "Guadeloupe",
		GRD: "Grenada",
		SYC: "Seychelles",
		SLE: "Sierra Leone",
		GTM: "Guatemala",
		BTN: "Bhutan",
		SMR: "San Marino",
		BEN: "Benin",
		BOL: "Bolivia (Plurinational State of)",
		GGY: "Guernsey",
		BRA: "Brazil",
		BIH: "Bosnia and Herzegovina",
		GIN: "Guinea",
		GNB: "Guinea-Bissau",
		HTI: "Haiti",
		HMD: "Heard Island and McDonald Islands",
		SXM: "Sint Maarten (Dutch part)",
		SGP: "Singapore",
		VAT: "Holy See",
		HND: "Honduras",
		SLB: "Solomon Islands",
		BFA: "Burkina Faso",
		HKG: "Hong Kong",
		HUN: "Hungary",
		BES: "Bonaire, Sint Eustatius and Saba",
		ISL: "Iceland",
		BLZ: "Belize",
		GUY: "Guyana",
		IND: "India",
		IDN: "Indonesia",
		IRN: "Iran (Islamic Republic of)",
		BDI: "Burundi",
		IRQ: "Iraq",
		BRN: "Brunei Darussalam",
		IRL: "Ireland",
		SVN: "Slovenia",
		ZAF: "South Africa",
		TCD: "Chad",
		CYM: "Cayman Islands",
		IMN: "Isle of Man",
		KHM: "Cambodia",
		BWA: "Botswana",
		BGR: "Bulgaria",
		BVT: "Bouvet Island",
		ISR: "Israel",
		CHL: "Chile",
		SVK: "Slovakia",
		CCK: "Cocos (Keeling) Islands",
		CAN: "Canada",
		CMR: "Cameroon",
		IOT: "British Indian Ocean Territory",
		COL: "Colombia",
		SOM: "Somalia",
		CRI: "Costa Rica",
		COK: "Cook Islands",
		CAF: "Central African Republic",
		ITA: "Italy",
		CHN: "China",
		COD: "Congo  Democratic Republic of the",
		DJI: "Djibouti",
		CPV: "Cabo Verde",
		HRV: "Croatia",
		JAM: "Jamaica",
		CUB: "Cuba",
		CIV: "Côte d'Ivoire",
		CUW: "Curaçao",
		EGY: "Egypt",
		JPN: "Japan",
		CYP: "Cyprus",
		CXR: "Christmas Island",
		SUR: "Suriname",
		SJM: "Svalbard and Jan Mayen",
		DNK: "Denmark",
		SDN: "Sudan",
		SYR: "Syrian Arab Republic",
		CZE: "Czechia",
		DOM: "Dominican Republic",
		ECU: "Ecuador",
		COM: "Comoros",
		DMA: "Dominica",
		TWN: "Taiwan, Province of China",
		TJK: "Tajikistan",
		COG: "Congo",
		TZA: "Tanzania, United Republic of",
		SWE: "Sweden",
		THA: "Thailand",
		NOR: "Norway",
		CHE: "Switzerland",
		PAK: "Pakistan",
		TLS: "Timor-Leste",
		TGO: "Togo",
		PNG: "Papua New Guinea",
		TKL: "Tokelau",
		TTO: "Trinidad and Tobago",
		TUR: "Turkey",
		TUN: "Tunisia",
		TON: "Tonga",
		TKM: "Turkmenistan",
		TCA: "Turks and Caicos Islands",
		TUV: "Tuvalu",
		UKR: "Ukraine",
		UGA: "Uganda",
		ARE: "United Arab Emirates",
		GBR: "United Kingdom of Great Britain and Northern Ireland",
		USA: "United States of America",
		UMI: "United States Minor Outlying Islands",
		URY: "Uruguay",
		VEN: "Venezuela (Bolivarian Republic of)",
		VUT: "Vanuatu",
		UZB: "Uzbekistan",
		VNM: "Viet Nam",
		VIR: "Virgin Islands (U.S.)",
		WLF: "Wallis and Futuna",
		YEM: "Yemen",
		ESH: "Western Sahara",
		ZWE: "Zimbabwe",
		VGB: "Virgin Islands (British)",
		ZMB: "Zambia",
		JEY: "Jersey",
		KAZ: "Kazakhstan",
		JOR: "Jordan",
		KEN: "Kenya",
		KIR: "Kiribati",
		PRK: "Korea (Democratic People's Republic of)",
		KOR: "Korea, Republic of",
		KGZ: "Kyrgyzstan",
		KWT: "Kuwait",
		LAO: "Lao People's Democratic Republic",
		LVA: "Latvia",
		LBN: "Lebanon",
		LBY: "Libya",
		LIE: "Liechtenstein",
		LTU: "Lithuania",
		LBR: "Liberia",
		LUX: "Luxembourg",
		LSO: "Lesotho",
		MDG: "Madagascar",
		MYS: "Malaysia",
		MWI: "Malawi",
		MAC: "Macao",
		MDV: "Maldives",
		MLI: "Mali",
		MLT: "Malta",
		MHL: "Marshall Islands",
		MTQ: "Martinique",
		MRT: "Mauritania",
		MUS: "Mauritius",
		MYT: "Mayotte",
		MEX: "Mexico",
		FSM: "Micronesia (Federated States of)",
		MDA: "Moldova, Republic of",
		MCO: "Monaco",
		MNG: "Mongolia",
		MNE: "Montenegro",
		MSR: "Montserrat",
		MAR: "Morocco",
		MMR: "Myanmar",
		MOZ: "Mozambique",
		NRU: "Nauru",
		NLD: "Netherlands",
		NAM: "Namibia",
		NPL: "Nepal",
		NCL: "New Caledonia",
		NZL: "New Zealand",
		NER: "Niger",
		NIC: "Nicaragua",
		NGA: "Nigeria",
		NIU: "Niue",
		NFK: "Norfolk Island",
		MKD: "North Macedonia",
		MNP: "Northern Mariana Islands",
		OMN: "Oman",
		PSE: "Palestine, State of",
		PLW: "Palau",
		PAN: "Panama",
		PRY: "Paraguay",
		SGS: "South Georgia and the South Sandwich Islands",
	},
	promo: {
		backToProject: "Back to project",
		preview: "Preview",
		publish: "Publish",
		colors: "Colors",
		fonts: "Fonts",
		logo: "Logo",
		brandColor: "Brand Color",
		secondaryColor: "Secondary Color",
		backgroundColor: "Background Color",
		textAndIconColor: "Text and Icon Color",
		fontFamily: "Font Family",
		uploadLogo: "Upload a logo",
		uploadFavicon: "Upload a favicon",
		generalInfo: "General Info",
		banner: "Banner",
		background: "Background",
		title: "Title",
		description: "Description",
		subDescription: "Sub Description",
		buttonText: "Button Text",
		languageConfig: "Language Config",
		availableLanguage: "Available Language",
		brands: "Brands",
		addBrand: "Add Brand",
		url: "URL",
		brandName: "Brand Name",
		editBrands: "Edit Brands",
		deleteBrand: "Delete Brand",
		editBrand: "Edit Brand",
		statistics: "Statistics",
		stories: "Stories",
		commission: "Commission",
		testimonials: "Testimonials",
		FAQ: "FAQ",
		SEO: "SEO",
		footer: "Footer",
		keywords: "Keywords",
		earnProcess: "Earn Process",
		marketingTools: "Marketing",

		addBlock: "Add Block",
		blockName: "Block Name",
		image: "Image",
		name: "Name",
		editBlock: "Edit Block",
		addStory: "Add Story",
		addStories: "Add Stories",
		editStoryBlock: "Edit Story Block",
		deleteStoryBlock: "Delete Story Block",
		deleteStory: "Delete Story",

		contacts: "Contacts",
		privacyPolicy: "Privacy Policy",
		termsAndConditions: "Terms And Conditions",
		profileImage: "Profile Image",

		editTestimonials: "Edit Testimonials",
		editMarketing: "Edit Marketing",
	},
};

export const DEFAULT_GROUPS = [
	{
		id: "5b88b3f670ee4d15bc9d713d2c7ad30c",
		name: "common",
	},
	{
		id: "8dcb76fca1bb45088ed295d90ead5ac6",
		name: "authentication",
	},
	{
		id: "7ba399ecf4b24893879acc87e306cc93",
		name: "menu",
	},
	{
		id: "5be451d4251d404396210238f0702e27",
		name: "profile",
	},
	{
		id: "e320f736c36d4f148781f69b7eeacc27",
		name: "companies",
	},
	{
		id: "f2718bae2c704cd9ab19a2a7283495e7",
		name: "currencies",
	},
	{
		id: "bf3fb33a46464176a4682f25a41e1dc6",
		name: "languages",
	},
	{
		id: "33d56f64688a418788663dc8bfeec5b9",
		name: "registrationform",
	},
	{
		id: "d1f1bafec08647e8b063586f66b759df",
		name: "projects",
	},
	{
		id: "edfb8fc9abb94ac9ac0a32516bfc8a86",
		name: "wallet",
	},
	{
		id: "acd06135fac2495d9f16fb4a48ba10ec",
		name: "commissionplans",
	},
	{
		id: "e277505ffeeb4fafbec5e8cf787e5798",
		name: "reports",
	},
	{
		id: "2c23da536a354f05ba6f70b6c32641b8",
		name: "commissioncalculations",
	},
	{
		id: "358f602dfa18432083b0afecfaaaf53a",
		name: "bets",
	},
	{
		id: "6a64f9af92414d42be9678320d1ef4b4",
		name: "players",
	},
	{
		id: "4da53667dd004a6fae639f124be80038",
		name: "users",
	},
	{
		id: "66da86194ef04164b5408ccf235737d0",
		name: "permissions",
	},
	{
		id: "9fea1d82bd2944c992dcba2412a2999f",
		name: "permissiongroups",
	},
	{
		id: "d77b2fd6b0b54dd889745dfc63722127",
		name: "sessions",
	},
	{
		id: "2f472a4981704a8f817c2030a1cc7caa",
		name: "translations",
	},
	{
		id: "d5474ee4cc1549e1a253893edfc71777",
		name: "notifications",
	},
	{
		id: "4ec91e11495d442ba4d08e22b31a7313",
		name: "userlogs",
	},
	{
		id: "8c5c33895ed24653aec803568adf3432",
		name: "errorlogs",
	},
	{
		id: "27c3e6c93ee24ee0b663a63f127e9562",
		name: "jobs",
	},
	{
		id: "191d7fb66e76440981e998b488f10fd2",
		name: "platforms",
	},
	{
		id: "09bee68c5bb449b7a111576f754aa82e",
		name: "validation",
	},
	{
		id: "c2db09ea4f474b32a403eef5c89fde1f",
		name: "resources",
	},
	{
		id: "bf25de051d5b4ad39964d37674682c5b",
		name: "errors",
	},
	{
		id: "426aeb95047b49d3afa966665b04058a",
		name: "weekdays",
	},
	{
		id: "85a8190496704fcf988a4b11074bf246",
		name: "months",
	},
	{
		id: "322470ceb2e54b66ba0cd03ef80e6f35",
		name: "affiliates",
	},
	{
		id: "6826b38497c84dc099baf3134de599b7",
		name: "generations",
	},
	{
		id: "fb6ea0dfca9042dbbbf038bb2019e68e",
		name: "performancereport",
	},
	{
		id: "2aa500311cd145d9897a5ddecd625b83",
		name: "countries",
	},
	{
		id: "8a7de3f6fb6943f4b394063ed0210ce7",
		name: "dashboard",
	},
	{
		id: "f208347907684d27bd84833917350628",
		name: "promo",
	},
	{
		id: "415751e51b364791820daf591ce779b5",
		name: "payments",
	},
	{
		id: "d92dde74ea1b44568929b4e198491a2a",
		name: "communication",
	},
	{
		id: "ec3d8bb1cd0f4601a977af37f3ee5398",
		name: "campaigns",
	},

	{
		id: "bf8ba129f5eb4a3cb74097b17539c1a6",
		name: "invoices",
	},
];
