import React, { Fragment } from "react";

import useDate from "core/hooks/useDate";

const DateTime = ({
    value
}) => {

    const { dateService } = useDate();

    if(!value){
        return "-"
    }

    return (
        <Fragment>
            <span className="rt--title rt--font-normal rt--font-regular rt--table-col-date">
                {dateService.format(value, false) + " "}
            </span>
            <span className="rt--title rt--font-normal rt--font-regular rt--table-col-time">
                {dateService.format(value, true, false)}
            </span>
        </Fragment>
    );
}

export default DateTime;