import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "core/hooks/useTranslation";

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import Select from 'components/common/select';
import DropdownRangePicker from '../../components/dropdownRangePicker';

import currencyType from 'types/profile/currency.type';

const MobileFilters = ({
    updateFilters,
    filters,
    showDataType=false,
    showCurrency=false,
    currencies=[],
    dataTypeFields
}) => {
    const { t } = useTranslation();

    const currencyFieldOptions = useMemo(() => {
        return currencies.map(currency => ({
            value: currency.currencyCode,
            text: currency.currencyCode
        }))
    }, [currencies])

    return (
        <FiltersWrapper
            loadFn={() => { }}
            setFiltersFn={updateFilters}
            updateProps={[]}
            filters={filters}
        >
            <Row gutter={[16, 0]}>
                {
                    showCurrency && (
                        <Col span={24}>
                            <Form.Item
                                label={t('backoffice.dashboard.currency')}
                                name="currencyCode"
                            >
                                <Select
                                    options={currencyFieldOptions}
                                    getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                />
                            </Form.Item>
                        </Col>
                    )
                }
                {
                    showDataType && (
                        <Col span={24}>
                            <Form.Item
                                label={t('backoffice.dashboard.dataType')}
                                name="dataType"
                            >
                                <Select
                                    options={dataTypeFields}
                                    getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                />
                            </Form.Item>
                        </Col>
                    )
                }
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.common.date')}
                        name="period"
                    >
                        <DropdownRangePicker/>
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    );
}

MobileFilters.propTypes = {
    filters: PropTypes.object,
    updateFilters: PropTypes.func,
    showDataType: PropTypes.bool,
    showCurrency: PropTypes.bool,
    currencies: PropTypes.arrayOf(currencyType),
    dataTypeFields: PropTypes.array
}

export default MobileFilters;