
import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import {
    SET_NEWSLETTER_CUSTOMIZE_ACTION_BEFORE,
    SET_NEWSLETTER_CUSTOMIZE_ACTION_FINISH,
    SET_NEWSLETTER_CUSTOMIZE_SAVE_ACTION_BEFORE,
    SET_NEWSLETTER_CUSTOMIZE_SAVE_ACTION_FINISH,
    SET_NEWSLETTER_TEMPLATE_VARIABLES
} from "../../../actionTypes";

import { makeNewslaterHTML } from "utils/newsletter";

export const setNewsletterCustomizeActionBefore = () => ({
    type: SET_NEWSLETTER_CUSTOMIZE_ACTION_BEFORE,
});

export const setNewsletterCustomizeActionFinished = () => ({
    type: SET_NEWSLETTER_CUSTOMIZE_ACTION_FINISH,
});

export const setNewsletterCustomizeSaveActionBefore = () => ({
    type: SET_NEWSLETTER_CUSTOMIZE_SAVE_ACTION_BEFORE,
});

export const setNewsletterCustomizeSaveActionFinished = () => ({
    type: SET_NEWSLETTER_CUSTOMIZE_SAVE_ACTION_FINISH,
});

const setNewsletterTemplateVariables = (variables) => ({
    type: SET_NEWSLETTER_TEMPLATE_VARIABLES,
    payload: { variables },
})

export const getNewsletterTemplateVariables = () => {
    return (dispatch) => {

        return axios({
            url: ApiUrls.GET_NEWSLETTER_TEMPLATE_VARIABLES,
            method: Methods.GET
        })
            .then(({ data: { value: variables } }) => {
                dispatch(setNewsletterTemplateVariables(Object.values(variables)));
            })
            .catch(Function.prototype);
    }
}

export const saveNewsletterTemplateContent = (id, configuration) => {
    return () => {
        const content = makeNewslaterHTML(configuration.templateType, configuration);
        
        return axios({
            url: ApiUrls.SAVE_NEWSLETTER_TEMPLATE_CONTENT,
            method: Methods.POST,
            data: {
                id, content
            },
        })
            .then(Function.prototype)
            .catch(Function.prototype);
    };
}

export const getNewsletterTemplateContent = (id, onSuccess) => {
    return dispatch => {    
        dispatch(setNewsletterCustomizeActionBefore());    
        return axios({
            url: ApiUrls.GET_NEWSLETTER_TEMPLATE_CONTENT,
            method: Methods.GET,
            params: {
                id
            },
        })
        .then(({ data: { value: content } }) => {
            dispatch(setNewsletterCustomizeActionFinished());
            onSuccess && onSuccess(content);
        })
        .catch(() => {
            dispatch(setNewsletterCustomizeActionFinished());
        })
    };
}
