import ROUTES from "core/constants/routes/routes";

import SystemLanguagesComponent from "pages/settings/systemLanguages/systemLanguages.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const SystemLanguagesPageRoute = {
	path: ROUTES.SETTINGS_LANGUAGES,
	element: SystemLanguagesComponent, 
    permission: { resource: PERMISSION_RESOURCE.SYSTEM_LANGUAGES, action: PERMISSION_ACTION.VIEW }
};

export default SystemLanguagesPageRoute;
