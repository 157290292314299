import React, { Fragment } from "react";

import { Outlet } from "react-router-dom";

import { Layout, Content as LayoutContent, Header as LayoutHeader } from 'core/ui-kit/layout';
import Spin from "core/ui-kit/spin";

import Header from "./header";
import Sidebar from "./sidebar";

import { loaderSelector, useGlobalSelector } from "core/stores/globalStore";
import { useAuthSelector, userSelector } from "core/stores/authStore";

import classNames from "core/helpers/common/classNames";

const PortalLayout = () => {

    const isLoading = useGlobalSelector(loaderSelector)
    const { hasPrevious } = useAuthSelector(userSelector)


    const headerClassName = classNames(
        'rt--header-wrapper',
        hasPrevious && "rt--header-wrapper-asAffiliate" 
    )

    return (
        <Fragment>
            <Sidebar />
            <Layout className="rt--flex rt--flex-col">
                <Spin spinning={isLoading}>
                    <LayoutHeader className={headerClassName}>
                        <Header />
                    </LayoutHeader>
                    <LayoutContent className='rt--flex-equal'>
                        <Outlet />
                    </LayoutContent>
                </Spin>
            </Layout>
        </Fragment>
    )
}

export default PortalLayout;