import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Form, Steps, Button } from 'antd';

import Modal from "components/common/modal";

import GeneralComponent from "./general";
import AffiliateComponent from "./affiliate";
import PreviewComponent from "./preview";

import { createNewsletter } from 'store/actions/portal/communications/newsletter/newsletters.action';

import { POPUP_SIZE } from 'constants/common.constants';

import { isMobile } from 'utils/common';

import useDate from 'hooks/useDate';

import affiliateType from 'types/affiliate/generalInfo.type';

const STEPS = {
    GENERAL: 0,
    AFFILIATES: 1,
    PREVIEW: 2
}

/** Newsletter Creating Popup Component */
const NewsletterCreateComponent = ({
    isSaving,
    createNewsletter,
    availableAffiliates,
    isAvailableAffiliatesLoading,
    templateId,
    onClose,
}) => {
    const { t } = useTranslation();

    const { dateService } = useDate();

    const [generalFormInstance] = Form.useForm();
    const [affiliatesFormInstance] = Form.useForm();
    const [scheduleFormInstance] = Form.useForm();

    const [selectedFilters, setSelectedFilters] = useState({});
    const [affiliateFilters, setAffiliateFilters] = useState({});

    const [currentStep, setCurrentStep] = useState(STEPS.GENERAL);

    /** Fires when form submitted
       * @function
       * @memberOf NewsletterCreateComponent
    */
    const onOk = () => {
        if (currentStep === STEPS.GENERAL) {
            generalFormInstance.validateFields()
                .then(data => {
                    setCurrentStep(STEPS.AFFILIATES)
                }).catch(() => { })
        } else if (currentStep === STEPS.AFFILIATES) {
            affiliatesFormInstance.validateFields()
                .then(data => {
                    const filters = {...data};
                    if(data.createdAt){
                        let fromDate = dateService.getDate(data.createdAt[0]);
                        fromDate = dateService.setUnit(fromDate, "hour", 0);
                        fromDate = dateService.setUnit(fromDate, "minute", 0);
                        fromDate = dateService.setUnit(fromDate, "second", 0);
                        fromDate = dateService.setUnit(fromDate, "millisecond", 0);

                        let toDate = dateService.getDate(data.createdAt[1]);
                        toDate = dateService.setUnit(toDate, "hour", 23);
                        toDate = dateService.setUnit(toDate, "minute", 59);
                        toDate = dateService.setUnit(toDate, "second", 59);
                        toDate = dateService.setUnit(toDate, "millisecond", 0);

                        filters.from = dateService.toISOString(fromDate);
                        filters.to = dateService.toISOString(toDate)

                        delete filters.createdAt;
                    }
                    setAffiliateFilters(filters);
                    setTimeout(() => {
                        setCurrentStep(STEPS.PREVIEW)
                    }, 0)
                }).catch(() => { })
        }
    }

    const handleBack = () => {
        setCurrentStep(currentStep === STEPS.AFFILIATES ? STEPS.GENERAL : STEPS.AFFILIATES);
    }

    const handleSendNowClick = () => {
        const generalFormData = generalFormInstance.getFieldsValue(true);
        const scheduleFormData = scheduleFormInstance.getFieldsValue(true);
        createNewsletter({
            ...generalFormData,
            ...scheduleFormData,
            targetAffiliates: availableAffiliates.map(u => u.id)
        }, onClose)
    }


    const renderActions = () => {
        return (
            <div className='rt--flex rt--justify-between rt--align-center'>
                {
                    currentStep !== STEPS.GENERAL && (
                        <Button
                            type="secondary"
                            onClick={handleBack}
                            className={"rt--button" + (!isMobile() ? " rt--button-secondary" : "" )}
                        >
                            {t('backoffice.common.back')}
                        </Button>
                    )
                }

                <div className='rt--flex rt--flex-equal rt--justify-end rt--align-center'>
                    {
                        !isMobile() && (
                            <Button
                                type="secondary"
                                onClick={onClose}
                                className="rt--button"
                            >
                                {t('backoffice.common.cancel')}
                            </Button>
                        )
                    }

                    {
                        currentStep === STEPS.PREVIEW ? (
                            <Fragment>
                                <Button
                                    type="primary"
                                    onClick={handleSendNowClick}
                                    className="rt--button rt--button-primary"
                                    disabled={availableAffiliates.length === 0}
                                >
                                    {t('backoffice.communication.send')}
                                </Button>
                            </Fragment>
                        ) : (
                            <Button
                                type="primary"
                                onClick={onOk}
                                className="rt--button rt--button-primary"
                                loading={isSaving}
                                disabled={currentStep === STEPS.PREVIEW && (availableAffiliates.length === 0 || isAvailableAffiliatesLoading)}
                            >
                                {t('backoffice.common.next')}
                            </Button>
                        )
                    }


                </div>
            </div>
        )
    }

    return (
        <Modal
            title={t('backoffice.communication.createEmail')}
            cancelText={t('backoffice.common.cancel')}
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.BIGEST}
            footer={renderActions()}
        >
            <div className='rt--flex rt--justify-center rt--mb-24'>
                <Steps
                    className="rt--steps"
                    onChange={step => setCurrentStep(step)}
                    current={currentStep}
                    responsive={false}
                >
                    <Steps.Step
                        title={t('backoffice.communication.general')}
                        description={t('backoffice.communication.generalDescription')}
                    />
                    <Steps.Step
                        title={t('backoffice.communication.affiliates')}
                        description={t('backoffice.communication.affiliateDescription')}
                        disabled={currentStep === STEPS.GENERAL}
                    />
                    <Steps.Step
                        title={t('backoffice.communication.preview')}
                        description={t('backoffice.communication.showAffiliates')}
                        disabled={currentStep === STEPS.GENERAL || currentStep === STEPS.AFFILIATES}
                    />
                </Steps>
            </div>
            {
                currentStep === STEPS.GENERAL ? (
                    <GeneralComponent
                        formInstance={generalFormInstance}
                        templateId={templateId}
                    />
                ) : currentStep === STEPS.AFFILIATES ? (
                    <AffiliateComponent
                        formInstance={affiliatesFormInstance}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                    />
                ) : <PreviewComponent
                    formInstance={scheduleFormInstance}
                    filters={affiliateFilters}
                />
            }

        </Modal>
    )
}

/** NewsletterCreateComponent propTypes
    * PropTypes
*/
NewsletterCreateComponent.propTypes = {
    /** Redux action to create newsletter */
    createNewsletter: PropTypes.func,
    /** Redux state property, is true when creating newsletter request is in process */
    isSaving: PropTypes.bool,
    /** Redux state property, available affiliates */
    availableAffiliates: PropTypes.arrayOf(affiliateType),
    /** Redux state property, is true when loading available affiliates */
    isAvailableAffiliatesLoading: PropTypes.bool,
    /** Selected template Id */
    templateId: PropTypes.string,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        createNewsletter: (data, onSuccess) => {
            dispatch(createNewsletter(data, onSuccess))
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.newsletter.isSaving,
        availableAffiliates: state.newsletter.affiliates.availableAffiliates,
        isAvailableAffiliatesLoading: state.newsletter.affiliates.isLoading,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterCreateComponent)