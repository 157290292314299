import React from 'react';

import { useDebugSelector } from '../../..';

const DebugStackTraceTab = () => {
    const { selectedChange } = useDebugSelector();

    const trace = selectedChange?.stackTrace ?? [];

    return (
        <div className="rt--debug-stack-tace">
            {trace.map((item, index) => (
                <React.Fragment key={index}>
                    <div>{item.func || '-'}</div>
                    <div className="rt--debug-stack-tace-file">{item.file}</div>
                    <div>{item.line}:{item.column}</div>
                </React.Fragment>
            ))}
        </div>
    );
};

export default DebugStackTraceTab;
