import React, { useState } from "react";

import { Table as AntTable, ConfigProvider } from "antd";

import Summary from "./summary";
import EmptyView from "../empty";

import classNames from "core/helpers/common/classNames";
import toUpperCaseFirstCharacter from "core/helpers/common/toUpperCaseFirstCharacter";

import { 
    getRowClassName,
    getPaginationData, 
    mapTableColumnsForDesktop,
    renderExpandedIcon
} from "../helpers";

import { ORDER_DIRECTION } from "core/constants/table";

const TableDesktop = ({
    loading=false,
    columns=[],
    data=[],
    summary,
    onRow: onRowProps,
    rowSelection,
    rowClassName,
    expandable,
    onSort,
    onPagination=Function.prototype,
    pagination,
    defaultSorting,
    rowKey="id",
    renderEmpty=() => <EmptyView />,
    actions
}) => {

    const [orderDirection, setOrderDirection] = useState(defaultSorting?.orderDirection);
    
    const onRow = Object.assign(
        { 
            click : {
                disabled: () => true, 
                handler: () => null
            }
        }, onRowProps
    );

    const showSummary = Boolean(summary) && data.length > 0;

    const tableClassName = classNames(
        "rt--table",
        showSummary && "rt--table-with-totals"
    )

    const onRowHandler = (record, rowIndex) => {

        const onClick = e => {
            e.preventDefault();
            e.stopPropagation();
            if(onRow.click.disabled(record, rowIndex)){
                return;
            }
            onRow.click.handler(record, rowIndex)
        }

        return {
            onClick: onClick, 
        };
    }

    const handleChange = (pagination, _, sorter, extra) => {
        const { action } = extra;
        const data = {}
        if(action === "sort"){
            if (sorter.field) {
                data.orderBy = toUpperCaseFirstCharacter(sorter.field);
            }
            if (sorter.order) {
                data.orderDirection = sorter.order === "ascend" ? ORDER_DIRECTION.ASC : ORDER_DIRECTION.DESC
            } else {
                data.orderDirection = defaultSorting.orderDirection;
                data.orderBy = defaultSorting.orderBy;
            }
            setOrderDirection(data.orderDirection)
            onSort(data);
        } else if (action === "paginate"){
            if (pagination.current) {
                data.page = pagination.current;
            }
    
            if (pagination.pageSize) {
                data.limit = pagination.pageSize;
            }
            onPagination(data)
        }
    }

    const mappedColumns = mapTableColumnsForDesktop({ columns, orderDirection, actions })

    return (
        <div className="rt--table-wrapper">
            <ConfigProvider
                getPopupContainer={() => document.body}
                renderEmpty={renderEmpty}
            >
                <AntTable
                    className={tableClassName} 
                    loading={loading}
                    columns={mappedColumns}
                    dataSource={data}
                    pagination={pagination ? getPaginationData(pagination) : false}
                    onChange={handleChange}
                    scroll={{ x: true }}
                    showSorterTooltip={false}
                    rowKey={rowKey}
                    rowClassName={
                        (record, rowIndex) => getRowClassName({
                            rowIndex,
                            clickable: !onRow.click.disabled(record, rowIndex),
                            expanded : false,
                            isAlwaysExpended : false,
                            rowClassName
                        })
                    }
                    onRow={onRowHandler}
                    rowSelection={rowSelection && {
                        columnWidth: 58,
                        ...rowSelection
                    }}
                    summary={ () => showSummary && (
                        <Summary summary={summary} />
                    ) }
                    expandable={Boolean(expandable) && (
                        {
                            expandIcon: params => renderExpandedIcon(params, expandable.rowExpandable),
                            ...expandable
                        }
                    )}
                />
            </ConfigProvider>
        </div>
    )
}

export default TableDesktop;