import isArray from "core/helpers/typeChecks/isArray";
import isFunction from "core/helpers/typeChecks/isFunction";

const checkRoleAccess = (userRole, routeRole) => {
    if (!routeRole) {
        return true;
    }

    if (isArray(routeRole)) {
        return routeRole.includes(userRole);
    }

    return routeRole === userRole;
}

const checkPermissionAccess = (permissionUtils, routePermission) => {
    if(!routePermission) return true;
    if(isArray(routePermission)){
        return permissionUtils.some(...routePermission)
    }
    return permissionUtils.has(routePermission);
}

const checkProject = (projectId, isProjectRequired = false) => {
    if(!isProjectRequired) return true;

    return Boolean(projectId)
}

const checkDisabled = (projectId, user, userInfo, isDisabled = null) => {
    if(!isFunction(isDisabled)) return true;

    return !isDisabled(projectId, user, userInfo)
}

export const hasPageAccess = ({ 
    user, 
    permissionUtils, 
    projectId, 
    userInfo,
    route
}) => {
    return (
        checkRoleAccess(user?.role, route.role) &&
        checkPermissionAccess(permissionUtils, route.permission) &&
        checkProject(projectId, route.isProjectRequired) &&
        checkDisabled(projectId, user, userInfo, route.isDisabled)
    )
}