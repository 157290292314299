import { message } from 'antd';

import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { 
    setAffiliatesActionBefore, 
    setAffiliatesActionFinished, 
    setAffiliatesSaveActionBefore, 
    setAffiliatesSaveActionFinished 
} from './affiliates.action';

import { SET_AFFILIATE_GENERAL_INFO, SET_AFFILIATE_ADDITIONAL_INFO, SET_AFFILIATE_TRAFFIC_SOURCES } from "../../../../actionTypes";

const setAffiliatePersonalInfo = info => ({
    type: SET_AFFILIATE_GENERAL_INFO,
    payload: { info }
})

const setAffiliateAdditionalInfo = info => ({
    type: SET_AFFILIATE_ADDITIONAL_INFO,
    payload: { info }
})

const setAffiliateTrafficSources = info => ({
    type: SET_AFFILIATE_TRAFFIC_SOURCES,
    payload: { info }
})

export const getAffiliateGeneralInfo = userNameOrId => {
    return dispatch => {
        dispatch(setAffiliatesActionBefore());

        return axios({
            url: ApiUrls.GET_AFFILIATE_GENERAL_INFO,
            method: Methods.GET,
            params: {
                userNameOrId
            }
        })
        .then(({data : {value: info}}) => {
            dispatch(setAffiliatePersonalInfo(info))
            dispatch(setAffiliatesActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliatesActionFinished());
        })
    }
}

export const saveAffiliateGeneralInfo = info => {
    return dispatch => {
        dispatch(setAffiliatesSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_AFFILIATE_GENERAL_INFO,
            method: Methods.POST,
            data: {
                ...info
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setAffiliatePersonalInfo(data.value));
            } 
            dispatch(setAffiliatesSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliatesSaveActionFinished());
        })
    }
}

export const getAffiliateAdditionalInfo = (id) => {
    return dispatch => {
        dispatch(setAffiliatesActionBefore());

        return axios({
            url: ApiUrls.GET_AFFILIATE_ADDITIONAL_INFO,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({data : {value: info}}) => {
            dispatch(setAffiliateAdditionalInfo(info))
            dispatch(setAffiliatesActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliatesActionFinished());
        })
    }
}

export const saveAffiliateAdditionalInfo = info => {
    return dispatch => {
        dispatch(setAffiliatesSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_AFFILIATE_ADDITIONAL_INFO,
            method: Methods.POST,
            data: {
                ...info
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setAffiliateAdditionalInfo(data.value));
            } 
            dispatch(setAffiliatesSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliatesSaveActionFinished());
        })
    }
}

export const getAffiliateTrafficSources = (id) => {
    return dispatch => {
        dispatch(setAffiliatesActionBefore());

        return axios({
            url: ApiUrls.GET_AFFILIATE_TRAFFIC_SOURCES,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({data : { value }}) => {
            dispatch(setAffiliateTrafficSources(value.sources))
            dispatch(setAffiliatesActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliatesActionFinished());
        })
    }
}

export const saveAffiliateTrafficSources = (info, onSuccess) => {
    return dispatch => {
        dispatch(setAffiliatesSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_AFFILIATE_TRAFFIC_SOURCES,
            method: Methods.POST,
            data: {
                ...info
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                onSuccess();
                message.success(data.message);
                dispatch(setAffiliateTrafficSources(data.value?.sources || []));
            } 
            dispatch(setAffiliatesSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliatesSaveActionFinished());
        })
    }
}

export const addAffiliateTrafficSource = (requestBody, onSuccess) => {
    return (dispatch) => {
        dispatch(setAffiliatesSaveActionBefore());
        return axios({
            url: ApiUrls.ADD_AFFILIATE_TRAFFIC_SOURCE,
            method: Methods.POST,
            data: requestBody
        })
            .then(({ data }) => {
                dispatch(setAffiliatesSaveActionFinished());
                if (isResponseSuccess(data)) {
                    onSuccess && onSuccess()
                }
            })
            .catch(() => {
                dispatch(setAffiliatesSaveActionFinished());
            });
    };
};

export const deleteAffiliateTrafficSource = (requestBody, onSuccess) => {
    return (dispatch) => {
        dispatch(setAffiliatesSaveActionBefore());
        return axios({
            url: ApiUrls.DELETE_AFFILIATE_TRAFFIC_SOURCE,
            method: Methods.DELETE,
            data: requestBody
        })
            .then(({ data }) => {
                dispatch(setAffiliatesSaveActionFinished());
                if (isResponseSuccess(data)) {
                    onSuccess && onSuccess()
                }
            })
            .catch(() => {
                dispatch(setAffiliatesSaveActionFinished());
            });
    };
};