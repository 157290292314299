//#region react
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";

import { Button, Form, Spin } from 'antd';

import { getPromoFontFamily, savePromoFontFamily } from "store/actions/portal/promoCustomize/styles.action";

import Select from "components/common/select";

import { isFormChanged } from "utils/form";
import { sendToPromoIframe } from "utils/promo";

import { PROMO_UPDATE_TYPE, SECTION_NAMES } from "constants/promo.constants";
import { CUSTOMIZE_FONT_FAMILIES } from "constants/customize.constants";
import { UNSAVED_FORM_PAGE_TYPE } from "constants/common.constants";

/** Portal Customize Page Sections Fonts Editor Component */
const FontsEditor = ({
    fontFamily,
    getPromoFontFamily,
    savePromoFontFamily,
    isSaving,
    isLoading,
    onTabChange,
}) => {
    const { t } = useTranslation();

    const [isFormTouched, setIsFormTouched] = useState(false);

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    /** Load font family */
    useEffect(() => {
        getPromoFontFamily();
    }, [])

    useEffect(() => {
        setFieldsValue({
            fontFamily
        });
    }, [fontFamily])

    /** Fires when form submitted
       * @function
       * @memberOf TwoFactorComponent
   */
    const handleForm = () => {
        validateFields()
            .then(data => {
                savePromoFontFamily(data.fontFamily);
                setIsFormTouched(false);
            }).catch(() => { })
    }

    useEffect(() => {
        onTabChange(isFormTouched, SECTION_NAMES.FONTS, UNSAVED_FORM_PAGE_TYPE.CUSTOMIZE_STYLES);
    }, [isFormTouched])

    return (
        <Spin spinning={isLoading}>
            <div>
                <Form
                    className="rt--form"
                    form={formInstance}
                    colon={false}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={(_, formValues) => {
                        sendToPromoIframe(PROMO_UPDATE_TYPE.FONT_FAMILY, { fontFamily: formValues['fontFamily'] });
                        setIsFormTouched(isFormChanged({ fontFamily: fontFamily }, { ...formValues }))
                    }}
                >
                    <Form.Item
                        label={t("backoffice.promo.fontFamily")}
                        name="fontFamily"
                        rules={[
                            { required: true, message: t('backoffice.validation.fieldRequired') },
                        ]}
                        className="rt--customize-widget-main-content-form-item"
                    >
                        <Select
                            options={
                                CUSTOMIZE_FONT_FAMILIES.map(f => ({ value: f, text: f }))
                            }
                            placeholder={`${t("backoffice.common.select")} ${t("backoffice.promo.fontFamily")}`}
                        />
                    </Form.Item>
                </Form>
                <div className="rt--flex rt--justify-end rt--align-center rt--pt-24 rt--mt-16">
                    <Button
                        type="primary"
                        className="rt--button"
                        onClick={handleForm}
                        loading={isSaving}
                        disabled={!isFormTouched}
                    >
                        <span>{t("backoffice.common.save")}</span>
                    </Button>
                </div>
            </div>
        </Spin>
    );
};

/** FontsEditor propTypes
 * PropTypes
*/
FontsEditor.propTypes = {
    /** Redux state promerty, promo font family */
    fontFamily: PropTypes.string,
    /** Redux action to get promo font family */
    getPromoFontFamily: PropTypes.func,
    /** Redux action to save promo font family */
    savePromoFontFamily: PropTypes.func,
    /** Redux state property, is true when promo saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when loading portal customization data */
    isLoading: PropTypes.bool,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    getPromoFontFamily: () => {
        dispatch(getPromoFontFamily())
    },
    savePromoFontFamily: fontFamily => {
        dispatch(savePromoFontFamily(fontFamily))
    }
});

const mapStateToProps = (state) => {
    return {
        isSaving: state.promoCustomize.isSaving,
        fontFamily: state.promoCustomize.fontFamily,
        isLoading: state.promoCustomize.isLoading,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FontsEditor);
