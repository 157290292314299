import useFormat from "hooks/useFormat";
import isUndefined from "core/helpers/typeChecks/isUndefined";
import isNull from "core/helpers/typeChecks/isNull";

const Numeric = ({
    value,
    allowNull=false
}) => {

    const { formatNumber } = useFormat();

    if(isUndefined(value) || isNull(value)){
        return allowNull ? "-" : "0"
    }

    if(isNaN(value)){
        return "-";
    }

    return formatNumber(value);
}

export default Numeric;