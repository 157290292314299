import React from "react"
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";

import { Button } from "antd";

import Icon from "components/common/icon";

import { copyToClipboard, isMobile } from "utils/common";

/** Sub Affiliates Referral Url */
const SubAffiliatesReferralUrl = ({
    referralUrl,
    referralCommissionPercent
}) => {

    const { t } = useTranslation();

    return (
        <div className="rt--affiliates-referral rt--pb-40 rt--pt-40 rt--pl-32 rt--pr-32">
            <div className="rt--affiliates-referral-inner">
                <b 
                    className={"rt--mb-16 " + (isMobile() ? "rt--font-big rt--font-normal": "rt--font-bigest rt--font-bold") }
                >
                    { t("backoffice.affiliates.referralLinkInfo").replace("%%PERCENT%%", referralCommissionPercent) }
                </b>
                <span 
                    className={"rt--font-small rt--font-bold " + ( isMobile() ? "rt--mb-8": "rt--mb-16" )}
                >
                    {t("backoffice.affiliates.personalReferralLink")}
                </span>
                {
                    !isMobile() ? (
                        <Button
                            className="rt--button"
                            type="secondary"
                            onClick={() => copyToClipboard(referralUrl, t)}
                        >
                            <span className="rt--title rt--font-normal rt--flex-equal">{referralUrl}</span>
                            <Icon name="copy" />
                        </Button>
                    ) : (
                        <Button
                            className="rt--button"
                            type="secondary"
                            onClick={() => copyToClipboard(referralUrl, t)}
                        >
                            <span className="rt--font-bold">{t("backoffice.affiliates.copySignUpUrl")}</span>
                        </Button>
                    )
                }
                {
                    !isMobile() && <img src={`${import.meta.env.SYSTEM_CDN_URL}/system/static/images/gift.png`} alt="gift" />
                }
                
            </div>
        </div>
    )
}

/** SubAffiliatesReferralUrl propTypes
    * PropTypes
*/
SubAffiliatesReferralUrl.propTypes = {
    /** Referral Url of current editing affiliate */
    referralUrl: PropTypes.string,
    /** Referral Commission Percent of current editing affiliate */
    referralCommissionPercent: PropTypes.number
}

export default SubAffiliatesReferralUrl;
