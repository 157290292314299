import React from "react";

import Icon from "core/ui-kit/icon";
import Button from "core/ui-kit/button";

import { authStore, useAuthSelector, userSelector } from "core/stores/authStore";

import { useTranslation } from "core/hooks/useTranslation";
import useDevice from "core/hooks/useDevice";

const LoginAsAffiliate = () => {

    const { t } = useTranslation();

    const { isMobile } = useDevice();

    const { userName } = useAuthSelector(userSelector)

    const handleSwitchBackClick = () => {
        authStore.switchBackAccount()
    }

    return (
        <div className={'rt--header-backToAdmin rt--flex rt--align-center rt--pl-12 rt--pr-12' + (isMobile ? " rt--justify-between" : "")}>
            <div className='rt--flex rt--flex-col'>
                <span className='rt--header-backToAdmin-title rt--title rt--font-bold rt--font-smallest'>{t("backoffice.common.youAreloggedinAsAffiliate")}</span>
                <span className='rt--title rt--font-bold rt--font-normal'>{userName}</span>
            </div>
            {
                isMobile ?
                    (
                        <div
                            className='rt--flex rt--align-center rt--cursor-pointer rt--pl-12'
                            onClick={handleSwitchBackClick}
                        >
                            <b className='rt--title rt--font-bold rt--font-normal rt--pr-2'>
                                {
                                    t("backoffice.common.backToYourUser")
                                }
                            </b>
                            <Icon name='arrow-up-right' size={20} />
                        </div>
                    ) : (
                        <Button
                            className='rt--header-backToAdmin-button rt--button rt--button-primary rt--flex rt--align-center rt--cursor-pointer rt--ml-24'
                            onClick={handleSwitchBackClick}
                        >
                            <b className='rt--title rt--font-bold rt--font-normal rt--pr-2'>
                                {
                                    t("backoffice.common.backToYourUser")
                                }
                            </b>
                        </Button>
                    )

                    
            }
        </div>
    )
}

export default LoginAsAffiliate;