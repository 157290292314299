//#region react
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";
//#endregion

//#region actions
import {
    getBalanceHistory,
    setBalanceHistorySorting,
    setBalanceHistoryFilters
} from "store/actions/portal/payment/balanceHistory/balanceHistory.action";
//#endregion

//#region components
import Table from "components/common/table";
import MainDashboardLayout from "components/layouts/main";
import WalletBalanceChanges from "components/common/walletBalanceChanges";
import Filters from "./filters.component";
//#endregion

//#region utils
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region hooks
import useIncludedColumns from "hooks/useIncludedColumns";
import useFormat from "hooks/useFormat";
//#endregion

//#region constants
import {
    PERMISSION_ACTION,
    PERMISSION_RESOURCE
} from 'core/constants/permission';

import { WALLET_HISTORY } from "constants/pageName.constants";
import { EXPAND_TABLE_TYPES } from "constants/common.constants";
import ApiUrls from 'constants/api.constants';
import { getTableColumns } from "./columns";
import { SEARCH_TYPE } from "components/common/search/constants";
//#endregion

//#region types
import sortingType from "types/common/sorting.type";
import walletTransferType from "types/wallet/transfer.type";
//#endregion

import { globalProjectIdSelector, useAuthSelector } from "core/stores/authStore";

import usePermissions from "core/hooks/usePermission";

const ROW_UNIQUE_KEY_PROP = "transactionId";
const EXPANDED_ROW_UNIQUE_KEY_PROP = "type";

/** Balance History Tab Component */
const BalanceHistoryComponent = ({
    getBalanceHistory,
    setBalanceHistorySorting,
    setBalanceHistoryFilters,
    isLoading,
    history,
    total,
    sorting,
    filters
}) => {
    const { t } = useTranslation();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);
    const permissionUtils = usePermissions();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: WALLET_HISTORY });

    const { formatAmount } = useFormat();

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const permissionResource = PERMISSION_RESOURCE.PAYMENTS_WALLETS_HISTORY;

    const hasDataExportPermission = permissionUtils.has( permissionResource, PERMISSION_ACTION.EXPORT );

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setBalanceHistoryFilters({
            ...filters,
            transactionId: value
        })
    }

    const handleDateSearchChange = value => {
        setBalanceHistoryFilters({
            ...filters,
            from: value[0],
            to: value[1]
        })
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { 
        mainTableColumns, 
        columnsForExport,
        columnsThatCanBeIncluded 
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            includedColumns,
            constructForInclude: true,
            constructForExport: true,
            additionalProps: {
                formatAmount,
                t
            },
            t
        });
    }, [includedColumns, t])

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters  />,
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        search: {
            type: SEARCH_TYPE.NUMERIC,
            placeholder: t("backoffice.wallet.transactionId"),
            onSearch: handleSearchChange,
            loadFn: getBalanceHistory,
            numeric: true,
            maxLength: 12,
        },
        date: {
            onSearch: handleDateSearchChange,
            placeholder: t('backoffice.common.dateAndTime'),
            showTime: true,
            loadFn: getBalanceHistory,
            allowClear: false,
            value: [filters.from, filters.to]
        },
        breadcrumbs: {
            items: [
                { title: t('backoffice.menu.payment') },
                { title: t('backoffice.menu.balanceHistory') }
            ]
        },
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            columns: columnsForExport,
            tableName: t("backoffice.menu.balanceHistory"),
            url: ApiUrls.EXPORT_WALLET_HISTORY,
            filters: filters
        }
    }

    //#endregion

    return (
        <MainDashboardLayout
            header={headerPartsData}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={history}
                loadFn={getBalanceHistory}
                uniqueKey={ROW_UNIQUE_KEY_PROP}
                updateProps={[globalProjectId]}
                sorting={sorting}
                setSortingFn={setBalanceHistorySorting}
                total={total}
                expandable={{
                    title: t("backoffice.wallet.transaction"),
                    type: EXPAND_TABLE_TYPES.CONTENT,
                    expandAll: true,
                    details: record => Boolean(record.changes) && ({
                        data: record.changes,
                        uniqueKey: EXPANDED_ROW_UNIQUE_KEY_PROP,
                        content: (
                            <WalletBalanceChanges
                                balanceChanges={record.changes}
                                transactionTime={record.transactionTime}
                                currencyCode={record.currencyCode}
                            />
                        ),
                    }),
                }}
            />

        </MainDashboardLayout>
    );
};

/** BalanceHistoryComponent propTypes
 * PropTypes
*/
BalanceHistoryComponent.propTypes = {
    /** Redux action to get balance history */
    getBalanceHistory: PropTypes.func,
    /** Redux state property, represents the array of balance history  */
    history: PropTypes.arrayOf(walletTransferType),
    /** Redux state property, balance history total count */
    total: PropTypes.number,
    /** Redux state property, is true when loading balance history */
    isLoading: PropTypes.bool,
    /** Redux action to set balance history sorting details */
    setBalanceHistorySorting: PropTypes.func,
    /** Redux action to set balance history filters */
    setBalanceHistoryFilters: PropTypes.func,
    /** Redux state property, balance history sorting details */
    sorting: sortingType,
    /** Redux state property, balance history filters */
    filters: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
    getBalanceHistory: nextPage => {
        dispatch(getBalanceHistory(nextPage))
    },
    setBalanceHistorySorting: sorting => {
        dispatch(setBalanceHistorySorting(sorting));
    },
    setBalanceHistoryFilters: filters => {
        dispatch(setBalanceHistoryFilters(filters));
    }
});

const mapStateToProps = state => {
    return {
        isLoading: state.balanceHistory.isLoading,
        history: state.balanceHistory.history,
        total: state.balanceHistory.total,
        sorting: state.balanceHistory.sorting,
        filters: state.balanceHistory.filters
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BalanceHistoryComponent);
