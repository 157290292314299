export const PASSWORD_PATTERN = {
    CONTAINS: 1,
    PATTERN: 2
}

export const PASSWORD_PATTERN_RULE = {
    MAY: 1,
    MUST: 2,
    NONE: 4
}

export const PASSWORD_PATTERN_CONTAINS = {
    UPPERCASE: 1,
    LOWERCASE: 2,
    DIGITS: 4,
    DASH: 8,
    UNDERSCORE: 16,
    SPACE: 32,
    SPECIAL: 64,
    BRACKETS: 128
}