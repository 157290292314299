import React from "react";

export const getTableColumns = additionalProps => {

    const { t } = additionalProps;

    return [
        {
            title: "backoffice.currencies.code",
            dataIndex: "code",
            mobileLevel: 1,
            render: (value, record) => (
                <div className="rt--flex rt--align-center">
                    <span className="rt--title rt--font-regular rt--font-normal">{value}</span>
                    {
                        record.isDefault && (
                            <div className='rt--tag rt--table-tag rt--flex rt--justify-between rt--align-center rt--pl-8 rt--pr-8 rt--ml-8 rt--mr-8'>
                                <span className='rt--title rt--font-normal rt--font-bold'>{t("backoffice.common.default")}</span>
                            </div>
                        )
                    }
                </div>
            )
        },
        {
            title: "backoffice.currencies.name",
            dataIndex: "name",
            alwaysVisible: true,
            mobileLevel: 2
        },
        {
            title: "backoffice.currencies.symbol",
            dataIndex: "symbol",
            mobileLevel: 3
        },
        {
            title: "backoffice.currencies.rateToDefault",
            dataIndex: "rate",
            mobileLevel: 4,
        },
        {
            title: "backoffice.currencies.rateOverridden",
            dataIndex: "isOverridden",
            render: value => value ? t("backoffice.common.yes") : t("backoffice.common.no"),
            mobileLevel: 5
        },
    ];
}
