import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import {
    SET_MEDIA_TOOLS,
    SET_MEDIA_TOOLS_ACTION_BEFORE,
    SET_MEDIA_TOOLS_ACTION_FINISH,
    SET_MEDIA_TOOLS_CREATION_RESULT,
    SET_MEDIA_TOOLS_FILTERS,
    SET_MEDIA_TOOLS_SAVE_ACTION_BEFORE,
    SET_MEDIA_TOOLS_SAVE_ACTION_FINISH,
    SET_MEDIA_TOOLS_SORTING,
} from "store/actionTypes";

import { isResponseSuccess } from "utils/request";
import { isMobile } from "utils/common";
import { message } from "antd";

export const setMediaToolsActionBefore = () => ({
    type: SET_MEDIA_TOOLS_ACTION_BEFORE,
});

export const setMediaToolsActionFinished = () => ({
    type: SET_MEDIA_TOOLS_ACTION_FINISH,
});

export const setMediaToolsSaveActionBefore = () => ({
    type: SET_MEDIA_TOOLS_SAVE_ACTION_BEFORE,
});

export const setMediaToolsSaveActionFinished = () => ({
    type: SET_MEDIA_TOOLS_SAVE_ACTION_FINISH,
});

const setMediaTools = (mediaTools, add) => ({
    type: SET_MEDIA_TOOLS,
    payload: { mediaTools, add },
});

export const setMediaToolsSorting = sorting => ({
    type: SET_MEDIA_TOOLS_SORTING,
    payload: { sorting },
});

export const setMediaToolsFilters = filters => ({
    type: SET_MEDIA_TOOLS_FILTERS,
    payload: { filters },
});

export const setMediaCreationResult = result => ({
    type: SET_MEDIA_TOOLS_CREATION_RESULT,
    payload: { result },
});

export const getMediaTools = nextPage => {
    return (dispatch, getState) => {

        const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().mediaTools.sorting.page + 1 : getState().mediaTools.sorting.page;
        let params = {
            ...getState().mediaTools.sorting,
            ...getState().mediaTools.filters,
            page: page
        };

        if (page > 1 && getState().mediaTools.total <= (page - 1) * getState().mediaTools.sorting.limit) {
            return Promise.resolve();
        }

        if (nextPage === "next" && getState().mediaTools.total === 0) {
            return Promise.resolve();
        }

        let campaignIdsStr = "";
        let campaignIds = getState().mediaTools.filters.campaignIds;

        if (campaignIds?.length > 0) {
            campaignIds.forEach(id => {
                campaignIdsStr += `${campaignIdsStr !== "" ? "&" : ""}campaignIds=${id}`
            })
        }
        let brandIdsStr = "";
        let brandIds = getState().mediaTools.filters.brandIds;

        if (brandIds?.length > 0) {
            brandIds.forEach(id => {
                brandIdsStr += `${brandIdsStr !== "" ? "&" : ""}brandIds=${id}`
            })
        }

        delete params.campaignIds;
        delete params.brandIds;

        dispatch(setMediaToolsActionBefore());

        return axios({
            url: `${ApiUrls.GET_MEDIAS}?${campaignIdsStr}${Boolean(brandIdsStr) ? `&${brandIdsStr}` : ""}`,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value } }) => {
                dispatch(setMediaTools(value, isMobile() && page !== 1));
                if (nextPage) {
                    dispatch(setMediaToolsSorting({ ...getState().mediaTools.sorting, page: page }));
                }

                dispatch(setMediaToolsActionFinished());
            })
            .catch((ex) => {
                dispatch(setMediaToolsActionFinished());
            });
    }
}

export const createMedia = (requestBody, onSuccess) => {
    return dispatch => {
        dispatch(setMediaToolsSaveActionBefore());

        axios({
            url: ApiUrls.CREATE_MEDIA,
            method: Methods.POST,
            data: requestBody,
        })
            .then(({ data }) => {
                if(isResponseSuccess(data)){
                    dispatch(setMediaCreationResult(data.value));
                    onSuccess && onSuccess();
                    dispatch(getMediaTools());
                }

                dispatch(setMediaToolsSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setMediaToolsSaveActionFinished())
            })
    }
}

export const getMediaCode = (id, onSuccess) => {
    return (dispatch) => {

        dispatch(setMediaToolsActionBefore());

        return axios({
            url: ApiUrls.GET_MEDIA_CODE,
            method: Methods.GET,
            params: { id },
        })
            .then(({ data: { value } }) => {
                onSuccess && onSuccess(value);
                dispatch(setMediaToolsActionFinished());
            })
            .catch((ex) => {
                dispatch(setMediaToolsActionFinished());
            });
    }
}

export const activateMedia = id => {
    return (dispatch) => {

        dispatch(setMediaToolsSaveActionBefore());

        return axios({
            url: ApiUrls.ACTIVATE_MEDIA,
            method: Methods.POST,
            data: { id },
        })
            .then(({ data }) => {
                if(isResponseSuccess(data)){
                    dispatch(getMediaTools());
                    message.success(data.message);
                }
                dispatch(setMediaToolsSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setMediaToolsSaveActionFinished());
            });
    }
}

export const deactivateMedia = id => {
    return (dispatch) => {

        dispatch(setMediaToolsSaveActionBefore());

        return axios({
            url: ApiUrls.DEACTIVATE_MEDIA,
            method: Methods.POST,
            data: { id },
        })
            .then(({ data }) => {
                if(isResponseSuccess(data)){
                    dispatch(getMediaTools());
                    message.success(data.message);
                }
                dispatch(setMediaToolsSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setMediaToolsSaveActionFinished());
            });
    }
}

export const saveMedia = (data, onSuccess) => {
    return (dispatch) => {

        dispatch(setMediaToolsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_MEDIA,
            method: Methods.POST,
            data: { ...data },
        })
            .then(({ data }) => {
                if(isResponseSuccess(data)){
                    message.success(data.message);
                    onSuccess && onSuccess();
                    dispatch(getMediaTools());
                }
                dispatch(setMediaToolsSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setMediaToolsSaveActionFinished());
            });
    }
}