import { getSettingsFromStorage } from "./helpers";

export const debugInitialState = {
    enabled: getSettingsFromStorage().enabled || false,
    opened: getSettingsFromStorage().opened || false,
    placement: getSettingsFromStorage().placement || "popup",
    initTimestamp: Date.now(),
    allStates: {},
    changes: [],
    selectedChange: null,
    stores: [],
    selectedStore: '',
};
