const processDefaultOpenedKeys = (items, parentItem, path) => {
	if (!parentItem) {
		return items.reduce((result, item) => {
			return [
				...result,
				...(item.items ? processDefaultOpenedKeys(item.items, item, path) : []),
			];
		}, []);
	}

	return items.reduce((result, item) => {
		if (item.match && item.match.some((p) => path.includes(p))) {
			result.push(parentItem.key);
		}

		if (item.items) {
			result = [...result, ...processDefaultOpenedKeys(item.items, item, path)];
		}

		return result;
	}, []);
};

export default processDefaultOpenedKeys;