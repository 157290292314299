import React from 'react';

import { useTranslation } from "core/hooks/useTranslation";

import Tabs from 'components/common/tabs';

import GeneralInfoComponent from '../edit/sections/general';
import CreativesComponent from '../edit/sections/creatives';

import ROUTES from "core/constants/routes/routes";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "core/constants/permission";

/** Campaign Edit Page Component */
const CampaignEditComponent = () => {
    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.campaigns.general"),
            permissions: [{ resource: PERMISSION_RESOURCE.MARKETING_TOOLS_CAMPAIGN, action: PERMISSION_ACTION.VIEW }],
            component: <GeneralInfoComponent />
        },
        {
            title: t("backoffice.campaigns.creatives"),
            permissions: [{ resource: PERMISSION_RESOURCE.MARKETING_TOOLS_CREATIVE, action: PERMISSION_ACTION.VIEW }],
            component: <CreativesComponent />
        },
    ]

    return (
        <Tabs
            items={items}
            mainPage={
                {
                    title: t('backoffice.menu.campaigns'),
                    path: ROUTES.CAMPAIGNS
                }
            }
        />
    )
}

export default CampaignEditComponent;