export const COMMISSION_TYPE = {
    REVSHARE: 1,
    CPA: 2,
    FIX: 4
}

export const COMMISSION_TYPE_TRANSLATIONS = {
    [COMMISSION_TYPE.REVSHARE]: "backoffice.commissionplans.revshare",
    [COMMISSION_TYPE.CPA]: "backoffice.commissionplans.cpa",
    [COMMISSION_TYPE.FIX]: "backoffice.commissionplans.fix",
}