import React from 'react';

import Status from 'components/common/status';

import { STATUS_TYPES } from 'constants/status.constants';
import { MEDIA_CLICK_STATE, MEDIA_CLICK_STATE_TRANSLATION_KEYS } from 'constants/reports.constants';
import { AFFILIATE_ACCESS_TYPE } from 'constants/affiliate.constants';

import { binaryToFlags } from 'utils/common';

export const getTableColumns = additionalProps => {

    const {
        hasAccess,
        t
    } = additionalProps;

    return [
        {
            title: "backoffice.reports.clickId",
            dataIndex: "id",
            alwaysVisible: true,
            copy: true,
        },
        {
            title: "backoffice.reports.status",
            dataIndex: "isValid",
            render: (_, record) => <Status status={record.status} type={STATUS_TYPES.MEDIA_CLICK} />,
            mobileLevel: 1
        },
        {
            title: "backoffice.reports.reason",
            dataIndex: "status",
            multi: true,
            multiMapper: value => value ? binaryToFlags(
                Object.values(MEDIA_CLICK_STATE), value).map(key => t(MEDIA_CLICK_STATE_TRANSLATION_KEYS[key])
                ) : []
        },
        {
            title:"backoffice.reports.clickDate",
            dataIndex: "clickDate",
            isDateTime: true,
            mobileLevel: 2,
            sorter: true
        },
        {
            title:"backoffice.reports.brand",
            dataIndex: "brandName",
            mobileLevel: 3,
            sorter: true
        },
        {
            title:"backoffice.reports.campaign",
            dataIndex: "campaignName",
            mobileLevel: 4,
            sorter: true
        },
        {
            title:"backoffice.reports.mediaName",
            dataIndex: "mediaName",
            alwaysVisible: true,
            sorter: true,
            mobileLevel: 5,
        },
        {
            title:"backoffice.reports.affiliateUsername",
            dataIndex: "affiliateUserName",
            sorter: true
        },
        {
            title: "backoffice.reports.country",
            dataIndex: "countryCode",
            render: value => value ? (
                <div className='rt--flex rt--align-center'>
                    <div className={`rt--flag rt--mr-4 rt--flag-${value.toLowerCase()}`} />
                    {t(`backoffice.countries.${value}`)}
                </div>
            ) : "-",
            mobileLevel: 6,
            sorter: true
        },
       
        hasAccess(AFFILIATE_ACCESS_TYPE.CLICK_SOURCE) && {
            title: "backoffice.reports.referrer",
            dataIndex: "referrer",
            render: value => {
                if (value) {
                    return (
                        <a
                            className='rt--media-campaign-url-link'
                            href={"https://" + value} target='_blank' rel="noreferrer"
                        >
                            {value}
                        </a>
                    )
                }
    
                return "-";
            }
        },
        {
            title: 'backoffice.reports.ipAddress',
            dataIndex: 'ipAddress',
            sorter: true
        },
        {
            title: 'backoffice.reports.browser',
            dataIndex: 'browserName',
            render: (value, record) => value + " " + (record.browserVersion !== "Unknown" ? record.browserVersion : ""),
            sorter: true
        },
        {
            title: 'backoffice.reports.deviceType',
            dataIndex: 'deviceType',
            sorter: true
        },
        {
            title: 'backoffice.reports.deviceOS',
            dataIndex: 'os',
            sorter: true
        }
    ];
}