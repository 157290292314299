import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { isMobile } from "utils/common";

import {
    SET_PROJECTS_ACTION_BEFORE,
    SET_PROJECTS_ACTION_FINISH,
    SET_PROJECTS,
    SET_PROJECTS_SORTING,
    SET_PROJECTS_FILTERS,
    SET_PROJECTS_SAVE_ACTION_BEFORE,
    SET_PROJECTS_SAVE_ACTION_FINISH,
    SET_PROJECT_AVAILABLE_CURRENCIES_BEFORE,
    SET_PROJECT_AVAILABLE_CURRENCIES_FINISH,
    SET_PROJECT_AVAILABLE_CURRENCIES,
    SET_GAME_PROVIDERS
} from "../../../actionTypes";

import TokenManagerService from "core/services/tokenManagerService";

import { globalStore } from "core/stores/globalStore";
import { authStore } from "core/stores/authStore";

export const setProjectsActionBefore = () => ({
    type: SET_PROJECTS_ACTION_BEFORE,
});

export const setProjectsActionFinished = () => ({
    type: SET_PROJECTS_ACTION_FINISH,
});

export const setProjectsSaveActionBefore = () => ({
    type: SET_PROJECTS_SAVE_ACTION_BEFORE,
});

export const setProjectsSaveActionFinished = () => ({
    type: SET_PROJECTS_SAVE_ACTION_FINISH,
});

const setProjects = (projects, add) => ({
    type: SET_PROJECTS,
    payload: { projects, add },
});

export const setProjectsSorting = sorting => ({
    type: SET_PROJECTS_SORTING,
    payload: { sorting },
});

export const setProjectsFilters = filters => ({
    type: SET_PROJECTS_FILTERS,
    payload: { filters },
});


export const getProjects = nextPage => {
    return (dispatch, getState) => {
        
        const page= nextPage === "first" ? 1 : nextPage === "next" ? getState().projects.sorting.page + 1 : getState().projects.sorting.page;
        let params = {
            ...getState().projects.sorting,
            ...getState().projects.filters,
            page: page
        };

        if(page > 1 && getState().projects.total <= (page - 1 ) * getState().projects.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && getState().projects.total === 0){
            return Promise.resolve();
        }
        
        dispatch(setProjectsActionBefore());

        return axios({
            url: ApiUrls.GET_PROJECTS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: projects } }) => {
                dispatch(setProjects(projects, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setProjectsSorting({ ...getState().projects.sorting, page: page }));
                dispatch(setProjectsActionFinished());
            })
            .catch((ex) => {
                dispatch(setProjectsActionFinished());
            });
    }
}

export const createProject = (project, onSuccess, companyId) => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.CREATE_PROJECT,
            method: Methods.POST,
            data: {
                ...project
            },
        })
            .then(({ data }) => {
                dispatch(setProjectsSaveActionFinished());
                if (isResponseSuccess(data)) {

                    TokenManagerService.updateToken()
                        .then(() => {
                            return globalStore.getUserInfo()
                        })
                        .then(() => {
                            return authStore.changeProject({ companyId: companyId, projectId: data.value.id });
                        })
                        .then(() => {
                            onSuccess && onSuccess(data.value);
                        });
                }
            })
            .catch(() => {
                dispatch(setProjectsSaveActionFinished());
            });
    };
};


/** Available Currencies */

const setProjectAvailableCurrenciesBefore = () => ({
    type: SET_PROJECT_AVAILABLE_CURRENCIES_BEFORE,
});

const setProjectAvailableCurrenciesFinished = () => ({
    type: SET_PROJECT_AVAILABLE_CURRENCIES_FINISH,
});

const setProjectAvailableCurrencies = currencies => ({
    type: SET_PROJECT_AVAILABLE_CURRENCIES,
    payload: { currencies }
})

export const getProjectAvailableCurrencies = () => {
    return dispatch => {

        dispatch(setProjectAvailableCurrenciesBefore());
        return axios({
            url: ApiUrls.GET_PROJECT_AVAILABLE_CURRENCIES,
            method: Methods.GET
        })
        .then(({data : {value: currencies}}) => {
            dispatch(setProjectAvailableCurrencies(currencies))
            dispatch(setProjectAvailableCurrenciesFinished());
        })
        .catch((ex) => {
            dispatch(setProjectAvailableCurrenciesFinished());
        })
    }
}

/** Project game providers */

const setGameProviders = providers => ({
    type: SET_GAME_PROVIDERS,
    payload: { providers }
})

export const getGameProviders = ()  => {
    return ( dispatch, getState ) => {
        const gameProviders = getState().projects.gameProviders;
        if(gameProviders.length > 0) return;
        
        return axios({
            url: ApiUrls.GET_GAME_PROVIDERS,
            method: Methods.GET
        })
        .then(({data : {value: providers}}) => {
            dispatch(setGameProviders(providers))
        })
        .catch((ex) => {
            console.log(ex)
        })
    }
}


