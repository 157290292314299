import { message } from 'antd';

import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setUsersActionBefore, setUsersActionFinished, setUsersSaveActionBefore, setUsersSaveActionFinished } from './users.action';

import { SET_USER_GENERAL_INFO } from "../../../../actionTypes";

const setUserGeneralInfo = info => ({
    type: SET_USER_GENERAL_INFO,
    payload: { info }
})

export const getUserGeneralInfo = id => {
    return dispatch => {
        dispatch(setUsersActionBefore());

        return axios({
            url: ApiUrls.GET_USER_GENERAL_INFO,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({data : {value: info}}) => {
            dispatch(setUserGeneralInfo(info))
            dispatch(setUsersActionFinished());
        })
        .catch((ex) => {
            dispatch(setUsersActionFinished());
        })
    }
}

export const saveUserGeneralInfo = user => {
    return dispatch => {
        dispatch(setUsersSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_USER_GENERAL_INFO,
            method: Methods.POST,
            data: {
                ...user
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setUserGeneralInfo(data.value));
            } 
            dispatch(setUsersSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setUsersSaveActionFinished());
        })
    }
}
