import React from "react";

import { Outlet } from "react-router-dom";
import Header from "./header";

const GuideLayout = () => {
    return (
        <div className='rt--guide-layout rt--flex rt--flex-col'>
            <Header />
            <div className='rt--guide-layout-content'>
                <Outlet />
            </div>
        </div>
    )
}

export default GuideLayout;