import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import {
    setPromoCustomizeActionBefore,
    setPromoCustomizeActionFinished,
    setPromoCustomizeSaveActionBefore,
    setPromoCustomizeSaveActionFinished
} from "./common.action";

import { 
    SET_PROMO_CUSTOMIZE_COLORS,
    SET_PROMO_CUSTOMIZE_FONT_FAMILY 
} from "../../../actionTypes";

const setPromoColors = colors => ({
    type: SET_PROMO_CUSTOMIZE_COLORS,
    payload: { colors }
})

export const getPromoColors = () => {
    return dispatch => {
        dispatch(setPromoCustomizeActionBefore());

        return axios({
            url: ApiUrls.GET_PROMO_COLORS,
            method: Methods.GET
        })
        .then(({data : {value: colors}}) => {
            dispatch(setPromoColors(colors))
            dispatch(setPromoCustomizeActionFinished());
        })
        .catch((ex) => {
            dispatch(setPromoCustomizeActionFinished());
        })
    }
}

export const savePromoColors = colors => {
    return dispatch => {
        dispatch(setPromoCustomizeSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_PROMO_COLORS,
            method: Methods.POST,
            data: {
                ...colors
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                dispatch(setPromoColors(data.value));
            } 
            dispatch(setPromoCustomizeSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setPromoCustomizeSaveActionFinished());
        })
    }
}

const setPromoFontFamily = fontFamily => ({
    type: SET_PROMO_CUSTOMIZE_FONT_FAMILY,
    payload: { fontFamily }
})

export const getPromoFontFamily = () => {
    return dispatch => {
        dispatch(setPromoCustomizeActionBefore());

        return axios({
            url: ApiUrls.GET_PROMO_FONT_FAMILY,
            method: Methods.GET
        })
        .then(({data : {value: fontFamily}}) => {
            dispatch(setPromoFontFamily(fontFamily))
            dispatch(setPromoCustomizeActionFinished());
        })
        .catch((ex) => {
            dispatch(setPromoCustomizeActionFinished());
        })
    }
}

export const savePromoFontFamily = fontFamily => {
    return dispatch => {
        dispatch(setPromoCustomizeSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_PROMO_FONT_FAMILY,
            method: Methods.POST,
            data: {
                name: fontFamily
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                dispatch(setPromoFontFamily(fontFamily));
            } 
            dispatch(setPromoCustomizeSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setPromoCustomizeSaveActionFinished());
        })
    }
}