import { PASSWORD_PATTERN, PASSWORD_PATTERN_CONTAINS, PASSWORD_PATTERN_RULE } from "core/constants/password";

const validatePassword = (value, settings, translate) => {

    if (value.length < settings.passwordMinLimit || value.length > settings.passwordMaxLimit) {
        return Promise.reject(
            translate('backoffice.validation.passwordRulesMustBeBetween')
            .replace("%X%", settings.passwordMinLimit)
            .replace("%Y%", settings.passwordMaxLimit)
        )
    }

    const pattern = settings.pattern ?? {};
    const patternContain = pattern.contain ?? [];
    const type = pattern.type;

    if (type === PASSWORD_PATTERN.CONTAINS) {
        for (let i = 0; i < patternContain.length; i++) {
            let result;
            switch (patternContain[i]?.type) {
                case PASSWORD_PATTERN_CONTAINS.UPPERCASE:
                    result = (/[A-Z]/.test(value));
                    break;
                case PASSWORD_PATTERN_CONTAINS.LOWERCASE:
                    result = (/[a-z]/.test(value));
                    break;
                case PASSWORD_PATTERN_CONTAINS.DIGITS:
                    result = (/[0-9]/.test(value));
                    break;
                case PASSWORD_PATTERN_CONTAINS.DASH:
                    result = (/-/.test(value));
                    break;
                case PASSWORD_PATTERN_CONTAINS.UNDERSCORE:
                    result = (/_/.test(value));
                    break;
                case PASSWORD_PATTERN_CONTAINS.SPACE:
                    result = (/\s/.test(value));
                    break;
                case PASSWORD_PATTERN_CONTAINS.SPECIAL:
                    result = (/[!#$%&*+,./:;=?@\\^`|~'"]/.test(value));
                    break;
                case PASSWORD_PATTERN_CONTAINS.BRACKETS:
                    result = (/[{([<>\])}]/.test(value));
                    break;
            }

            if (result && patternContain[i]?.value === PASSWORD_PATTERN_RULE.NONE) {
                return Promise.reject(
                    translate('backoffice.validation.passwordRulesMustNotContain')
                    .replace("%X%", translate(`backoffice.companies.containType${patternContain[i]?.type}`))
                );
            } else if (!result && patternContain[i].value === PASSWORD_PATTERN_RULE.MUST) {
                return Promise.reject(
                    translate('backoffice.validation.passwordRulesMustContain')
                    .replace("%X%", translate(`backoffice.companies.containType${patternContain[i]?.type}`))
                );
            }
        }

        if (pattern?.alsoIncludeCharacters) {
            if (!pattern.alsoIncludeCharacters.split('')
                .every(char => value.includes(char))) {
                return Promise.reject(
                    translate('backoffice.validation.passwordRulesMustInclude')
                    .replace("%X%", pattern?.alsoIncludeCharacters)
                )
            }
        }

    } else if (type === PASSWORD_PATTERN.PATTERN && pattern.regularExpression) {
        const reg = new RegExp(pattern.regularExpression, 'g');
        
        if (!reg.test(value)) {
            return Promise.reject(
                translate('backoffice.validation.passwordRulesMustMatchRegularExpression')
                .replace("%X%", pattern.regularExpression)
            );
        }
    }

    return Promise.resolve();
}

export default validatePassword;