import Observable from "core/lib/observable";

import { SESSION_EXPIRATION, SESSION_REFRESH_MODAL_DELAY } from "core/constants/common/session";

class SessionManagerService {

    constructor() {
	
		this._warningTimer = null;
		this._endTimer = null;

		this._triggerWarningAfter = (SESSION_EXPIRATION - SESSION_REFRESH_MODAL_DELAY) * 1000;

		this._isStarted = false;

		this.sessionEnd = new Observable();
		this.sessionWarning = new Observable();
	}

	startSession() {
		this._isStarted = true;
		this._startSessionWarningCountdown();
	}

	refresh() {
		this._clear();
		if (this._isStarted) {
			this._startSessionWarningCountdown();
		}
	}

	stopSession() {
		this._isStarted = false;
		this._clear();
	}

	_clear() {
		clearTimeout(this._endTimer);
		clearTimeout(this._warningTimer);
	}

	_startSessionWarningCountdown() {
		clearTimeout(this._warningTimer);
		this._warningTimer = setTimeout(() => {
			this.sessionWarning.notify();
			this._startSessionEndCountdown();
		}, this._triggerWarningAfter);
	}

	_startSessionEndCountdown() {
		this._endTimer = setTimeout(() => {
			this.sessionEnd.notify();
		}, SESSION_REFRESH_MODAL_DELAY * 1000);
	}
}

const sessionManagerServiceInstance = new SessionManagerService();

export default sessionManagerServiceInstance;
