import AuthStore from "./store";

import { createSelector } from "core/lib/baseStore";
import { debugStore } from "core/lib/baseStore/debugStore";

export const authStore = new AuthStore();

debugStore.registerStore(authStore);

export const useAuthSelector = createSelector(authStore);

export const isAuthenticatedSelector = state => Boolean(state.user);

export const userSelector = state => state.user;

export const globalProjectIdSelector = state => state.user?.projectId;

export const globalCompanyIdSelector = state => state.user?.companyId;

export const wsTokenSelector = state => state.user?.wsToken;

export const userStateSelector = state => state.user?.userState;

export const userRoleSelector = state => state.user?.role;
