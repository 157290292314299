import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { BO_GROUP_ID } from "constants/translation.constants";

import { DEFAULT_TRANSLATIONS, DEFAULT_GROUPS } from "translations/defaultTranslations"

const createTranslation = (key, subGroupId, text, onSuccess) => {
    return dispatch => {
        return axios({
            url: ApiUrls.CREATE_TRANSLATION,
            method: Methods.POST,
            data: {
                key: key,
                groupId: BO_GROUP_ID,
                subGroupId: subGroupId,
                text: text
            }
        })
            .then(({ data }) => {
                onSuccess && onSuccess(data.value);
            })
            .catch(ex => {
                console.log(ex)
            });
    };
};

const createGroup = (id, name) => {
    let obj = {...DEFAULT_TRANSLATIONS[name]};
    let keys = Object.keys(obj);

    const c = dispatch => {
        const k = keys[0];
        if(k){
            dispatch(createTranslation(k, id, obj[k], () => {
                delete obj[keys[0]];
                keys.shift();
                if(keys.length > 0){
                    c(dispatch)
                }
            }))
        }
    }

    return dispatch => {
        c(dispatch)
    };
};

/** Translations Create Script */
export const createAllBOTranslations = () => {
    return dispatch => {
        for(let i = 0; i < DEFAULT_GROUPS.length; i++){
            const id = DEFAULT_GROUPS[i].id;
            const name = DEFAULT_GROUPS[i].name;
            dispatch(createGroup(id, name))
        }
    };
}