import { lazy } from "react";

import Spin from "core/ui-kit/spin";

import withSuspenseHoc from "core/HOCs/withSuspense";

import ROUTES from "core/constants/routes/routes";

const AffiliatesContainer = withSuspenseHoc(lazy(() => import("./affiliatesContainer")), <Spin />);

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

import { USER_ROLE } from "constants/user.constants";

const AffiliatesPageRoute = {
	path: ROUTES.AFFILIATES,
	element: AffiliatesContainer, 
    permission: { resource: PERMISSION_RESOURCE.AFFILIATE, action: PERMISSION_ACTION.VIEW },
    role: [USER_ROLE.ADMIN, USER_ROLE.AFFILIATE_MANAGER]
};

export default AffiliatesPageRoute;
