import React from 'react';
import PropTypes from 'prop-types';

import { Col, Row, Form } from 'antd';

import { useTranslation } from "core/hooks/useTranslation";

import NumericInput from 'components/common/numericInput';

import { numberTransform } from 'utils/common';

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'core/constants/permission';

import usePermissions from 'core/hooks/usePermission';

/** Commission Plan Creating Popup Fix Component */
const Fix = ({
    editMode
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const hasEditPermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT_COMMISSION_PLAN, editMode ? PERMISSION_ACTION.MODIFY : PERMISSION_ACTION.CREATE);

    const canEdit = hasEditPermission && (!editMode || !editMode.isAffiliate);

    return (
        <Row gutter={[16, 0]}>
            <Col xs={24} sm={12}>
                <Form.Item
                    label={`${t('backoffice.commissionplans.amount')} *`}
                    name="amount"
                    rules={[
                        { type: "number", min: 0, message: t('backoffice.validation.fieldInvalid'), transform: numberTransform },

                        () => ({
                            validator(rule, value) {
                                if (value === "" || value === null || value === undefined) {
                                    return Promise.reject(t('backoffice.validation.fieldRequired')) 
                                }
                                if (Number(value) < 0.000001) {
                                    return Promise.reject(t('backoffice.validation.mustBeMore').replace("%X%", 0.000001))
                                }
                                return Promise.resolve();
                            }
                        })
                    ]}
                    validateFirst
                    className={'rt--general-form-item rt--form-item-without-margin' + (!canEdit ? " rt--form-item-disabled" : "")}
                    data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.amount')}`}
                >
                    <NumericInput
                        placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.amount')}`}
                        disabled={!canEdit}
                        maxLength={8}
                    />
                </Form.Item>
            </Col>
        </Row>
    )
}

/** Fix propTypes
    * PropTypes
*/
Fix.propTypes = {
    editMode: PropTypes.shape({
        isAffiliate: PropTypes.bool
    })
}

export default Fix;