import React, { useState } from "react";
import { Menu as AntMenu } from "antd";
import { LOGO_TYPE } from "core/constants/common/logo";
import useDevice from "core/hooks/useDevice";
import Icon from "core/ui-kit/icon";
import useLogo from "core/hooks/useLogo";

const LeftBar = ({ items, handleMenuClick, selectedPostId }) => {
  const [openedKeys, setOpenedKeys] = useState([]);
  const { isMobile } = useDevice();
  const { logos } = useLogo();

  // Group IDs for different categories
  const menuGroups = {
    Reports: ["452589", "452631", "452795"],
  };

  // Desired order of menu items
  const desiredOrder = [
    "Dashboard",
    "My Account",
    "Marketings",
    "Payment",
    "Reports",
  ];

  // Function to map and sort items
  const getMappedMenuItems = () => {
    // Sort items based on desiredOrder
    const sortedItems = items.slice().sort((a, b) => {
      const indexA = desiredOrder.indexOf(a.title);
      const indexB = desiredOrder.indexOf(b.title);

      // Items not in desiredOrder go to the end
      return (
        (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB)
      );
    });

    let mappedItems = [];

    // Handle Reports group
    const reportGroup = {
      key: "Reports",
      label: "Reports",
      children: sortedItems
        .filter((item) => menuGroups.Reports.includes(item.id))
        .map((item) => ({
          key: item.id,
          label: item.title,
        })),
    };

    if (reportGroup.children.length > 0) {
      mappedItems.push(reportGroup);
    }

    // Other items that are not in the Reports group
    const otherItems = sortedItems
      .filter((item) => !menuGroups.Reports.includes(item.id))
      .map((item) => ({
        key: item.id,
        label: item.title,
      }));

    return [...otherItems, ...mappedItems];
  };

  const mappedMenuItems = getMappedMenuItems();

  const onMenuClick = ({ key }) => {
    const parentMenuKey = mappedMenuItems.find((item) =>
      item.children?.some((child) => child.key === key)
    )?.key;

    setOpenedKeys(parentMenuKey ? [parentMenuKey] : []);
    document.body.classList.remove("rt--body-open-sidebar");
    handleMenuClick(key);
  };

  const onOpenChange = (keys) => {
    setOpenedKeys(keys.slice(-1));
  };

  return (
    <div className="rt--guide-layout-left-bar">
      <div className="rt--guide-layout-left-bar-content">
        {isMobile && (
          <div className="lu rt--flex rt--align-center rt--justify-between rt--pl-10 rt--pt-10 rt--pr-10 rt--pb-10 rt--mb-24">
            <div className="rt--guide-layout-header-logo">
              <img
                alt="logo"
                src={`${import.meta.env.SYSTEM_CDN_URL}/${
                  logos[LOGO_TYPE.LOGO]
                }`}
              />
            </div>

            <div className="rt--guide-layout-header-logo">
              <Icon
                name="close"
                size={30}
                color="white"
                onClick={() =>
                  document.body.classList.remove("rt--body-open-sidebar")
                }
              />
            </div>
          </div>
        )}

        <div className="rt--guide-layout-left-bar-content-menu">
          <AntMenu
            mode="inline"
            expandIcon={
              <Icon
                size={16}
                name="down"
                className="rt--sidebar-content-menu-down"
              />
            }
            openKeys={openedKeys}
            onOpenChange={onOpenChange}
            onClick={onMenuClick}
            selectedKeys={[selectedPostId]}
          >
            {mappedMenuItems.map((item) =>
              item.children ? (
                <AntMenu.SubMenu key={item.key} title={item.label}>
                  {item.children.map((child) => (
                    <AntMenu.Item key={child.key} title={child.label}>
                      {child.label}
                    </AntMenu.Item>
                  ))}
                </AntMenu.SubMenu>
              ) : (
                <AntMenu.Item key={item.key} title={item.label}>
                  {item.label}
                </AntMenu.Item>
              )
            )}
          </AntMenu>
        </div>
      </div>
    </div>
  );
};

export default LeftBar;
