import { message } from 'antd';

import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setCampaignsActionBefore, setCampaignsActionFinished, setCampaignsSaveActionBefore, setCampaignsSaveActionFinished } from './campaigns.action';

import { SET_CAMPAIGN_GENERAL_INFO, UPDATE_CAMPAIGN_STATE } from "../../../../actionTypes";

const setCampaignGeneralInfo = info => ({
    type: SET_CAMPAIGN_GENERAL_INFO,
    payload: { info }
})

export const updateCampaignState = status => ({
    type: UPDATE_CAMPAIGN_STATE,
    payload: { status }
})

export const getCampaignGeneralInfo = id => {
    return dispatch => {
        dispatch(setCampaignsActionBefore());

        return axios({
            url: ApiUrls.GET_CAMPAIGN_GENERAL_INFO,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({data : {value: info}}) => {
            dispatch(setCampaignGeneralInfo(info))
            dispatch(setCampaignsActionFinished());
        })
        .catch((ex) => {
            dispatch(setCampaignsActionFinished());
        })
    }
}

export const saveCampaignGeneralInfo = campaign => {
    return dispatch => {
        dispatch(setCampaignsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_CAMPAIGN_GENERAL_INFO,
            method: Methods.POST,
            data: {
                ...campaign
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setCampaignGeneralInfo(data.value));
            } 
            dispatch(setCampaignsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setCampaignsSaveActionFinished());
        })
    }
}
