import isNull from "core/helpers/typeChecks/isNull"
import isArray from "core/helpers/typeChecks/isArray"

const isObjectLiteral = value=> {
	if (isNull(value)) {
		return false;
	}

	if (isArray(value)) {
		return false;
	}

	return typeof value === "object";
};

export default isObjectLiteral;
