import { message } from 'antd';

import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setAccountsSaveActionBefore, setAccountsSaveActionFinished } from './accounts.action';

export const setAccountPassword = ( data, userId, onSuccess) => {
    return dispatch => {
        dispatch(setAccountsSaveActionBefore());
        return axios({
            url: ApiUrls.CHANGE_ACCOUNT_PASSWORD,
            method: Methods.POST,
            data: { 
                ...data,
                userId 
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)){
                message.success(data.message);
                onSuccess && onSuccess();
            }
            dispatch(setAccountsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAccountsSaveActionFinished());
        })
    }
}