import ROUTES from "core/constants/routes/routes";

import PermissionsComponent from "pages/userManagement/permissions/permissions.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const PermissionsPageRoute = {
	path: ROUTES.PERMISSIONS,
	element: PermissionsComponent , 
    permission: { resource: PERMISSION_RESOURCE.PERMISSION, action: PERMISSION_ACTION.VIEW }
};

export default PermissionsPageRoute;
