class BrowserStorage {
	constructor(storage) {
		this.storage = storage;
	}

	set(key, value) {
		this.storage.setItem(key, JSON.stringify(value));
	}

	clear() {
		this.storage.clear();
	}

	remove(key) {
		this.storage.removeItem(key);
	}

	get(key, defaultValue) {
		const data = this.storage.getItem(key);

		if (data === "undefined") {
			return defaultValue ?? undefined;
		}

		if (data === "null") {
			return defaultValue ?? null;
		}

		return JSON.parse(data);
	}
}

export default BrowserStorage;