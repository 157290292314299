import ROUTES from "core/constants/routes/routes";

import RequestsComponent from "pages/developer/requests/requests.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const RequestsPageRoute = {
	path: ROUTES.DEVELOPER_REQUESTS,
	element: RequestsComponent, 
    permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW }
};

export default RequestsPageRoute;
