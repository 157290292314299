import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useNavigate } from 'react-router-dom';

import { Form, Row, Col, Divider } from 'antd';

import Modal from "components/common/modal";
import Input from 'components/common/input';
import Select from 'components/common/select';
import Tooltip from 'components/common/tooltip';
import Icon from 'components/common/icon';

import { createCampaign, getCampaigns } from "store/actions/portal/marketingTools/campaigns/campaigns.action";

import { hasCampaignEditPageAccess } from 'utils/pageAccess';
import { flagsToBinary, makeUrl } from 'utils/common';

import { POPUP_SIZE } from 'constants/common.constants';
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { NAME_REGEX } from 'constants/regex.constants';
import { PROJECT_BRAND_PRODUCT_TYPE } from 'constants/project.constants';
import ROUTES from "core/constants/routes/routes";
import { USER_STATE } from 'constants/user.constants';

import useAutosuggestion from 'hooks/useAutosuggestion';

import countries from 'systemData/countries';
import usePermissions from 'core/hooks/usePermission';

/** Campaign Creating Popup Component */
const CampaignCreateComponent = ({
    isSaving,
    createCampaign,
    getCampaigns,
    onClose
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const navigate = useNavigate();

    const [isPrivate, setIsPrivate] = useState(false);
    const [selectedBrandId, setSelectedBrandId] = useState(null);
    const [selectedBrandUrlId, setSelectedBrandUrlId] = useState(null);

    const [ affiliateNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AFFILIATES, 
        actionParams: { state: flagsToBinary([USER_STATE.ACTIVE, USER_STATE.LOCKED_LOGIN, USER_STATE.LOCKED_2_FA])}
    });
    const [ brands ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.BRAND, 
    });

    const selectedBrand = selectedBrandId ? brands.find(brand => brand.id === selectedBrandId) : null;

    const urls = (selectedBrand?.urls ?? []).map(url => (
        {
            id: url.id,
            product: url.product,
            languageCode: url.languageCode,
            name: url.name ?? makeUrl(selectedBrand?.domain, url.subDomain, url.relative),
            url: makeUrl(selectedBrand?.domain, url.subDomain, url.relative)
        }
    ));

    const selectedUrl = urls.find(url => url.id === selectedBrandUrlId);

    useEffect(() => {
        setFieldsValue({
            "brandUrlId": null
        })
        setSelectedBrandUrlId(null)
    }, [selectedBrandId]);

    const selectedProduct = selectedUrl?.product ?? null;
    const selectedProductName = selectedProduct === PROJECT_BRAND_PRODUCT_TYPE.CASINO ?
        t(`backoffice.projects.casino`) : selectedProduct === PROJECT_BRAND_PRODUCT_TYPE.SPORT ?
            t(`backoffice.projects.sport`) : selectedProduct === PROJECT_BRAND_PRODUCT_TYPE.OTHER ?
                t(`backoffice.projects.other`) : "-";
    const selectedLanguage = selectedUrl?.languageCode ?? null;
    const selectedUrlUrl = selectedUrl?.url ?? null;

    /** Fires when form submitted
       * @function
       * @memberOf CampaignCreateComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {

                createCampaign(
                    {
                        ...data
                    },
                    record => {
                        if (hasCampaignEditPageAccess(permissionUtils)) {
                            navigate(`${ROUTES.CAMPAIGNS_EDIT}/${record.id}?name=${record.name}`);
                        } else {
                            getCampaigns();
                            onClose();
                        }
                    });
            }).catch(ex => {
                console.log(ex)
            })
    }

    return (
        <Modal
            title={t('backoffice.campaigns.createCampaign')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.create')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.MIDDLE}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    name: "",
                    isPrivate: false,
                    affiliateIds: [],
                    countryCodes: [],
                    brandUrlId: null
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label={`${t('backoffice.campaigns.name')} *`}
                            name="name"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { max: 30, message: t('backoffice.validation.fieldInvalid') },
                                { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.campaigns.name')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.campaigns.name')}`}
                                maxLength={30}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={
                                <div className="rt--flex rt--align-center">
                                    {t('backoffice.campaigns.availablility')}
                                    <Tooltip
                                        title={t(`backoffice.campaigns.availablilityTooltip`)}
                                        trigger={["hover", "click"]}
                                        placement="bottomLeft"
                                        enableMobile={true}
                                    >
                                        <Icon name='info' size={14} className="rt--ml-6" />
                                    </Tooltip>
                                </div>
                            }
                            name="isPrivate"
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') },
                            ]}
                        >
                            <Select
                                options={[
                                    { value: true, text: t('backoffice.campaigns.private') },
                                    { value: false, text: t('backoffice.campaigns.public') }
                                ]}
                                onChange={value => setIsPrivate(value)}
                                placeholder={`${t("backoffice.common.select")} ${t('backoffice.campaigns.availablility')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={`${t('backoffice.campaigns.country')} *`}
                            name="countryCodes"
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') },
                            ]}
                        >
                            <Select
                                options={
                                    countries.map(c => (
                                        { value: c.iso2, text: t(`backoffice.countries.${c.iso2}`) }
                                    ))
                                }
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.campaigns.country')}`}
                                search={true}
                                isMultiple={true}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            />
                        </Form.Item>
                    </Col>
                    {
                        isPrivate === true && (
                            <Col span={12}>
                                <Form.Item
                                    label={t('backoffice.campaigns.affiliates')}
                                    name="affiliateIds"
                                >
                                    <Select
                                        options={
                                            affiliateNames
                                                .map(item => (
                                                    { value: item.id, text: item.name }
                                                ))
                                        }
                                        placeholder={`${t("backoffice.common.select")} ${t('backoffice.campaigns.affiliates')}`}
                                        getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                        search={true}
                                        isMultiple={true}
                                    />
                                </Form.Item>
                            </Col>
                        )
                    }

                </Row>
                <Divider className='rt--form-section-divider' />
                <Row gutter={[16, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label={`${t('backoffice.campaigns.brand')} *`}
                            name="brandId"
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') },
                            ]}
                        >
                            <Select
                                options={
                                    brands
                                        .map(item => (
                                            { value: item.id, text: item.name }
                                        ))
                                }
                                onChange={value => setSelectedBrandId(value)}
                                placeholder={`${t("backoffice.common.select")} ${t('backoffice.campaigns.brand')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                search={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={`${t('backoffice.campaigns.url')} *`}
                            name="brandUrlId"
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') },
                            ]}
                            className={!Boolean(selectedBrandId) ? " rt--form-item-disabled" : ""}
                        >
                            <Select
                                options={
                                    urls
                                        .map(item => (
                                            { value: item.id, text: item.name }
                                        ))
                                }
                                placeholder={`${t("backoffice.common.select")} ${t('backoffice.campaigns.url')}`}
                                onChange={value => setSelectedBrandUrlId(value)}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                search={true}
                                disabled={!Boolean(selectedBrandId)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div>
                            <span className='rt--text-light rt--font-regular rt--font-small'>
                                {
                                    `${t('backoffice.projects.product')}: ${selectedProductName}`
                                }
                            </span>
                        </div>
                        <div>
                            <span className='rt--text-light rt--font-regular rt--font-small'>
                                {
                                    `${t('backoffice.campaigns.language')}: ${selectedLanguage ? t(`backoffice.languages.${selectedLanguage.toLowerCase()}`) : "-"}`
                                }
                            </span>
                        </div>
                        <div>
                            <span className='rt--text-light rt--font-regular rt--font-small'>
                                {
                                    `${t('backoffice.campaigns.url')}: `
                                }
                                {
                                    selectedUrlUrl ? (
                                        <span
                                            className="rt--title rt--font-small rt--font-regular rt--link"
                                            onClick={() => window.open(`https://${selectedUrlUrl}`, "_blank")}
                                        >
                                            {
                                                selectedUrlUrl
                                            }
                                        </span>
                                    ) : "-"
                                }

                            </span>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

/** CampaignCreateComponent propTypes
    * PropTypes
*/
CampaignCreateComponent.propTypes = {
    /** Redux state property, is true when creating campaign request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to get campaigns */
    getCampaigns: PropTypes.func,
    /** Redux action to create campaign */
    createCampaign: PropTypes.func,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        createCampaign: (campaign, onSuccess) => {
            dispatch(createCampaign(campaign, onSuccess));
        },

        getCampaigns: () => {
            dispatch(getCampaigns());
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.campaigns.isSaving,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignCreateComponent)