import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";

import { Spin, DatePicker } from 'antd';

import MainDashboardLayout from "components/layouts/main";

import Tooltip from "components/common/tooltip";
import Icon from "components/common/icon";

import { getGenerations } from 'store/actions/portal/developer/generations/generations.action';

import { GENERATION_TYPE } from "constants/common.constants";

import { isMobile } from "utils/common"

import useDate from 'hooks/useDate';

import generationType from "types/generation/generation.type";

import { globalProjectIdSelector, useAuthSelector } from "core/stores/authStore";

/** Generations Page Component */
const GenerationsComponent = ({
    generations,
    isLoading,
    getGenerations
}) => {

    const { t } = useTranslation();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);

    const { dateService } = useDate();

    const type = GENERATION_TYPE.AGGREGATED;

    const [date, setDate] = useState(dateService.getNow(true));

    const loadData = (t, d) => {
        const start = dateService.toISOString(dateService.startOfDay(d));
        const end = dateService.toISOString(dateService.startOfNextDay(d))

        getGenerations(t, start, end)
    }

    const handleDateChange = v => {
        setDate(v);
        loadData(type, v);
    }

    const renderDatePicker = () => (
        <DatePicker
            value={date}
            onChange={v => {
                handleDateChange(v);
            }}
            className="rt--datepicker"
            dropdownClassName='rt--datepicker-popup'
            format={dateService.getFormat(false)}
            showToday={false}
            showNow={false}
            allowClear={false}
            placeholder={t('backoffice.common.selectDate')}
            suffixIcon={<Icon name="reset" />}
            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
            inputReadOnly={isMobile()}
            disabledDate={d => dateService.isAfter(d, dateService.endOfDay()) }
        />
    )

    const mapData = generations => {
        const result = [];
        let intervals = [];

        const sortedGenerations = [...generations];
        sortedGenerations.reverse();
        intervals.push(dateService.format(dateService.startOfDay(), true, false));

        const generationsData = sortedGenerations.map(g => ({
            ...g,
            from: dateService.format(g.from, true, false),
            to: dateService.format(g.to, true, false)
        }))

        if(generationsData.length > 0){
            generationsData.forEach(gen => {
                if(!intervals.includes(gen.from)){
                    intervals.push(gen.from)
                }

                if(!intervals.includes(gen.to) && gen.to !== "00:00"){
                    intervals.push(gen.to)
                }
            })
        } 

        intervals.forEach(interval => {
            let intervalGeneration = generationsData.find(g => g.from === interval);
            result.push({
                interval: interval,
                generations: intervalGeneration?.generations ?? []
            })
        })

        return result;
    }

    /** Load Data */
    useEffect(() => {
        loadData(type, date)
    }, [globalProjectId])

    const generationsData = useMemo(() => mapData(generations), [generations]);

    return (
        <MainDashboardLayout
            header={
                {
                    breadcrumbs: {
                        items: [
                            { title: t('backoffice.menu.generations') }
                        ]
                    },

                    extra: !isMobile() ? renderDatePicker() : undefined
                }
            }

            extraContent={
                isMobile() ? {
                    content: renderDatePicker(),
                } : undefined
            }
        >
            <div className="rt--generations-container rt--flex rt--align-center">
                <Spin spinning={isLoading}>
                    <div className="rt--generations-wrapper rt--flex rt--align-center">
                        <div className="rt--generations rt--flex rt--pl-24 rt--pr-24">
                            {
                                generationsData.map((d, i) => (
                                    <div 
                                        className={
                                            "rt--generations-item rt--flex rt--align-end" +
                                            (d.generations.length === 0 ? " rt--generations-item-empty" : "")
                                        }
                                        key={d.interval}
                                    >
                                        <div 
                                            className="rt--generations-item-generations rt--flex rt--flex-col-reverse rt--justify-start"
                                        >
                                            {
                                                d.generations.map((g, index) => (
                                                    <Tooltip
                                                        key={d.interval}
                                                        title={(
                                                            <div className="rt--tooltip-generation">
                                                                <div className="rt--flex rt--align-center rt--pb-4">
                                                                    <span className="rt--title rt--font-normal rt--font-regular rt--pr-4">{t("backoffice.generations.chunks")}:</span>
                                                                    <b className="rt--title rt--font-normal rt--font-regular">
                                                                        {
                                                                            g.chunkCount
                                                                        }
                                                                    </b>
                                                                </div>
                                                                <div className="rt--flex rt--align-center rt--pb-4">
                                                                    <span className="rt--title rt--font-normal rt--font-regular rt--pr-4">{t("backoffice.generations.count")}:</span>
                                                                    <b className="rt--title rt--font-normal rt--font-regular">
                                                                        {
                                                                            g.betCount
                                                                        }
                                                                    </b>
                                                                </div>
                                                                <div className="rt--flex rt--align-center rt--pb-4">
                                                                    <span className="rt--title rt--font-normal rt--font-regular rt--pr-4">{t("backoffice.generations.time")}:</span>
                                                                    <b className="rt--title rt--font-normal rt--font-regular">
                                                                        {
                                                                            dateService.format(g.lastUpdatedAt, true, false)
                                                                        }
                                                                    </b>
                                                                </div>
                                                            </div>
                                                        )}
                                                        trigger={["hover", "click"]}
                                                        placement="topLeft"
                                                        enableMobile={true}
                                                    >
                                                        <div className="rt--generations-item-generation rt--flex rt--align-center rt--justify-center rt--mt-8" >
                                                            <span className="rt--title rt--font-bold rt--font-small">{`${t("backoffice.generations.generation")} ${index + 1}`}</span>
                                                        </div>
                                                    </Tooltip>

                                                ))
                                            }
                                        </div>

                                        <span className="rt--generations-item-time rt--title rt--font-bold rt--font-normal">{d.interval}</span>

                                        {
                                            i === generationsData.length - 1 && (
                                                <span className="rt--generations-item-time rt--generations-item-time-last rt--title rt--font-bold rt--font-normal">24:00</span>
                                            )
                                        }
                                    </div>
                                ))
                            }
                        </div>

                    </div>
                </Spin>
            </div>
        </MainDashboardLayout>

    );
};

/** GenerationsComponent propTypes
 * PropTypes
*/
GenerationsComponent.propTypes = {
    /** Redux action to get generations data */
    getGenerations: PropTypes.func,
    /** Redux state property, generations data */
    generations: PropTypes.arrayOf(generationType),
    /** Redux state property, is true when loading generations data */
    isLoading: PropTypes.bool
};

const mapDispatchToProps = dispatch => ({
    getGenerations: (type, from, to) => {
        dispatch(getGenerations(type, from, to))
    },
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.generations.isLoading,
        generations: state.generations.generations,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GenerationsComponent);
