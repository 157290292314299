import BaseStore from "core/lib/baseStore";
import setAuthData from "core/helpers/auth/setAuthData";

import accountService from "core/services/apiServices/accountService";

import authInitialState from "./initialState";
import { globalStore } from "../globalStore";

import browserSessionStorage from "core/services/storageService/sessionStorage";
import { USER_ROLE } from "core/constants/auth";

class AuthStore extends BaseStore {
    constructor() {
		super(authInitialState);
	}

    logout() {
        this.state.user = null;
        browserSessionStorage.clear()
    }

    loginUser(user) {
		setAuthData(user);
        this.state.user = user;
	}

    refreshToken() {
		return accountService
			.refreshToken(this.state.user.refreshToken)
			.then( value => {
				this.loginUser(value);
				return value;
			});
	}

    changeProject(data){
        globalStore.setLoading(true);
        return accountService
                .changeProject(data)
                .then( value => {
                    this.loginUser(value);
                    if(value?.role !== USER_ROLE.ACCESS_MANAGER && value?.projectId !== null){
                        globalStore.getCurrencies()
                    }
                })
                .finally(() => {
                    globalStore.setLoading(false);
                });
    }

    verifyAuthenticationCode(token, code) {
		return accountService
			.authenticateToken(token, code)
			.then( value => {
				this.loginUser(value);
                globalStore.setMainInfoLoading(true);
				return value;
			});
	}

    switchAccount(id) {
		globalStore.setLoading(true);
		return accountService
            .switchAccount(id)
            .then(value => {
                globalStore.setLoading(false);

                browserSessionStorage.clear();
                this.loginUser(value);

                globalStore.setMainInfoLoading(true);

                Promise.all([
                    globalStore.getUserInfo(),
                    globalStore.getCurrencies()
                ]).finally(() => {
                    globalStore.setMainInfoLoading(false);
                });

			    return value;
		    });
	}

	switchBackAccount() {
		globalStore.setLoading(true);
		return accountService
            .switchAccountBack().then(
                value => {
                    globalStore.setLoading(false);

                    browserSessionStorage.clear();
                    this.loginUser(value);

                    globalStore.setMainInfoLoading(true)

                    Promise.all([
                        globalStore.getUserInfo(),
                        value.projectId !== null && globalStore.getCurrencies()
                    ]).finally(() => {
                        globalStore.setMainInfoLoading(false);
                    });
    
                    return value;
                }
            );
	}
}

export default AuthStore;