import React from "react";

import Status from "components/common/status";

import { PROJECT_BRAND_PRODUCT_TYPE } from "constants/project.constants";
import { STATUS_TYPES } from "constants/status.constants";

export const getTableColumns = additionalProps => {

    const { t } = additionalProps;

    return [
        {
            title: "backoffice.common.id",
            dataIndex: "longId",
            sorter: true,
            copy: true,
            alwaysVisible: true,
            mobileLevel: 1,
        },
        {
            title: "backoffice.campaigns.name",
            dataIndex: "name",
            sorter: true,
            alwaysVisible: true,
            mobileLevel: 2,
        },
        {
            title: "backoffice.commissionplans.status",
            dataIndex: "status",
            render: value => <Status type={STATUS_TYPES.CAMPAIGN} status={value} />,
            mobileLevel: 3
        },
        {
            title: "backoffice.campaigns.brand",
            dataIndex: "brandName",
            sorter: true,
        },
        {
            title: "backoffice.campaigns.url",
            dataIndex: "url",
            mobileLevel: 4,
            render: value => {
                return (
                    <span
                        className="rt--title rt--font-normal rt--font-regular rt--table-col-link"
                        onClick={() => window.open(`https://${value}`, "_blank")}
                    >
                        <b>{value}</b>
                    </span>
                )
            }
        },
        {
            title: "backoffice.projects.product",
            dataIndex: "product",
            render: value => value === PROJECT_BRAND_PRODUCT_TYPE.CASINO ?
                t(`backoffice.projects.casino`) : value === PROJECT_BRAND_PRODUCT_TYPE.SPORT ?
                    t(`backoffice.projects.sport`) : value === PROJECT_BRAND_PRODUCT_TYPE.OTHER ?
                        t(`backoffice.projects.other`) : "",
            mobileLevel: 5
        },
        {
            title: "backoffice.campaigns.creatives",
            dataIndex: "creativeCount",
            isNumeric: true
        },
        {
            title: "backoffice.campaigns.medias",
            dataIndex: "mediaCount",
            isNumeric: true,
        },
        {
            title: "backoffice.campaigns.language",
            dataIndex: "languageCode",
            mobileLevel: 6,
            render: value => value ? t(`backoffice.languages.${value.toLowerCase()}`) : "-"
        },
        {
            title: "backoffice.campaigns.availablility",
            dataIndex: "isPrivate",
            render: value => value ? t(`backoffice.campaigns.private`) : t(`backoffice.campaigns.public`),
        },
        {
            title: "backoffice.campaigns.country",
            dataIndex: "countryCodes",
            multi: true,
            multiMapper: (value) => value ? value.map(v => (
                <div className="rt--flex rt--align-center" key={v}>
                    <div className={`rt--flag rt--mr-4 rt--flag-${v.toLowerCase()}`}></div>
                    {t(`backoffice.countries.${v}`)}
                </div>
            )) : []
        },
    
        {
            title: "backoffice.campaigns.createdAt",
            dataIndex: "createdAt",
            isDateTime: true,
            sorter: true,
        },
        {
            title: "backoffice.campaigns.createdBy",
            dataIndex: "createdBy",
            sorter: true,
        },
        {
            title: "backoffice.campaigns.lastModifiedAt",
            dataIndex: "modifiedAt",
            isDateTime: true,
            sorter: true,
        },
        {
            title: "backoffice.campaigns.lastModifiedBy",
            dataIndex: "modifiedBy",
            sorter: true,
        }
    ];
}
