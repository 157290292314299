import React from "react";

import { useTranslation } from "core/hooks/useTranslation";

import classNames from "core/helpers/common/classNames";

const Flag = ({
    value
}) => {

    const { t } = useTranslation();

    const className = classNames(
        "rt--flag",
        "rt--mr-4",
        `rt--flag-${value?.toLowerCase()}`
    )

    if(!value){
        return "-";
    }

    return (
        <div className='rt--flex rt--align-center'>
            <div className={className} />
            { t(`backoffice.countries.${value}`) }
        </div>
    );
}

export default Flag;