import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";
import { connect } from 'react-redux';

import { Row, Col, Form, Button, Divider } from "antd";

import Select from "components/common/select";
import Input from "components/common/input";

import { getNewsletterTemplateContent } from 'store/actions/portal/newsletterCustomize/common.action';
import { sendTestNewsletter } from 'store/actions/portal/communications/newsletter/newsletters.action';

import useAutosuggestion from 'hooks/useAutosuggestion';

import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { EMAIL_REGEX } from 'constants/regex.constants';

import { openBase64HtmlInNewTab } from 'utils/newsletter';
import { isMobile } from 'utils/common';

/** Newsletter Creating Popup General Section Component */
const NewsletterCreateGeneralComponent = ({
    formInstance,
    getNewsletterTemplateContent,
    isTestSending,
    sendTestNewsletter,
    templateId
}) => {

    const { t } = useTranslation();

    const [selectedTemplate, setSelectedTemplate] = useState(templateId ? templateId : formInstance.getFieldValue("templateId") || null);
    const [testEmail, setTestEmail] = useState("");

    const [ templates ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.NEWSLETTER
    });

    const handlePreviewClick = () => {
        getNewsletterTemplateContent(selectedTemplate, content => {
            openBase64HtmlInNewTab(content)
        })
    }

    const handleSendTestClick = () => {
        formInstance.validateFields()
                .then(data => {
                    sendTestNewsletter({
                        ...data,
                        testEmail: testEmail
                    })
                }).catch(() => { })
    }

    return (
        <Form
            className="rt--form"
            form={formInstance}
            colon={false}
            requiredMark={false}
            layout="vertical"
            initialValues={{
                templateId: templateId
            }}
        >
            {
                isMobile() && (
                    <h4
                        className='rt--form-section-title rt--title rt--mb-16 rt--mt-0 rt--font-bold rt--font-biger'
                    >
                        {
                            t('backoffice.communication.general')
                        }
                    </h4>
                )
            }

            <Row gutter={[16, 0]}>
                <Col span={24} lg={8}>
                    <Form.Item
                        label={`${t('backoffice.communication.chooseNewsletter')} *`}
                        name="templateId"
                        rules={[
                            { required: true, message: t('backoffice.validation.fieldRequired') },
                        ]}
                        className={Boolean(templateId) ? "rt--form-item-disabled" : ""}
                    >
                        <Select
                            options={templates.map(template => ({
                                value: template.id,
                                text: template.name
                            }))}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.communication.newsletter')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            onChange={template => setSelectedTemplate(template)}
                            disabled={Boolean(templateId)}
                        />
                    </Form.Item>
                </Col>
                <Col span={24} lg={8}>
                    <Form.Item
                        label={isMobile() ? "" : " "}
                    >
                        <Button
                            type="primary"
                            onClick={handlePreviewClick}
                            disabled={!selectedTemplate}
                            className="rt--button rt--button-main rt--button-main-without-icon"
                        >
                            {t('backoffice.communication.preview')}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
            <Divider className='rt--form-section-divider' />
            <Row gutter={[16, 0]}>
                <Col span={24} lg={8}>
                    <Form.Item
                        label={`${t('backoffice.communication.subject')} *`}
                        name="subject"
                        rules={[
                            { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                            { max: 100, message: t('backoffice.validation.fieldInvalid') },
                        ]}
                        className='rt--general-form-item'
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.communication.subject')}`}
                        validateFirst
                    >
                        <Input
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.communication.subject')}`}
                            maxLength={100}
                        />
                    </Form.Item>
                </Col>
                <Col span={24} lg={8}>
                    <Form.Item
                        label={`${t('backoffice.communication.from')} *`}
                        name="from"
                        rules={[
                            { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                            { max: 30, message: t('backoffice.validation.fieldInvalid') },
                        ]}
                        validateFirst
                        className='rt--general-form-item'
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.communication.from')}`}
                    >
                        <Input
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.communication.from')}`}
                            maxLength={30}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 0]}>
                <Col span={24} lg={8}>
                    <Form.Item
                        label={t('backoffice.communication.sendTestEmail')}
                        name="email"
                        rules={[
                            { pattern: EMAIL_REGEX, message: t('backoffice.validation.emailFormat') },
                            { max: 50, message: t('backoffice.validation.fieldInvalid') }
                        ]}
                        className={'rt--general-form-item' + (!isMobile() ? " rt--form-item-without-margin" : "")}
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.communication.sendTestEmail')}`}
                    >
                        <Input
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.communication.sendTestEmail')}`}
                            maxLength={50}
                            onChange={e => setTestEmail(e.target.value)}
                        />
                    </Form.Item>
                </Col>
                <Col span={24} lg={8}>
                    <Form.Item
                        label={isMobile() ? "" : " "}
                        className='rt--form-item-without-margin'
                    >
                        <Button
                            type="primary"
                            onClick={handleSendTestClick}
                            disabled={!testEmail || !EMAIL_REGEX.test(testEmail)}
                            className="rt--button rt--button-main rt--button-main-without-icon"
                            loading={isTestSending}
                        >
                            {t('backoffice.communication.send')}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

/** NewsletterCreateGeneralComponent propTypes
    * PropTypes
*/
NewsletterCreateGeneralComponent.propTypes = {
    /** Form Instance */
    formInstance: PropTypes.object,
    /** Redux action to get newsletter template content */
    getNewsletterTemplateContent: PropTypes.func,
    /** Redux state property, is true when sending test newsletter */
    isTestSending: PropTypes.bool,
    /** Redux action to send test newsletter */
    sendTestNewsletter: PropTypes.func,
    /** Selected template Id */
    templateId: PropTypes.string,
}

const mapDispatchToProps = dispatch => (
    {
        getNewsletterTemplateContent: (id, onSuccess) => {
            dispatch(getNewsletterTemplateContent(id, onSuccess))
        },

        sendTestNewsletter: newsletterData => {
            dispatch(sendTestNewsletter(newsletterData))
        }
    }
)

const mapStateToProps = state => {
    return {
        isTestSending: state.newsletter.newsletters.isTestSending
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterCreateGeneralComponent);