import React, { useState, useEffect, useMemo } from "react";
import PropTypes from 'prop-types';

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Menu as AntMenu } from "antd";

import { useTranslation } from "core/hooks/useTranslation";
import usePermissions from "core/hooks/usePermission";

import { globalProjectIdSelector, useAuthSelector, userSelector } from "core/stores/authStore";
import { useGlobalSelector, userInfoSelector } from "core/stores/globalStore";

import Icon from "core/ui-kit/icon";

import mapMenuItems from "./helpers/mapMenuItems";
import getPathForMenuProcess from "./helpers/getPathForMenuProcess";
import processDefaultOpenedKeys from "./helpers/processOpenedKeys";
import processDefaultSelectedKeys from "./helpers/processSelectedKeys";

import menuItems from "./items";

import { UNSAVED_FORM_PAGE_TYPE } from "constants/common.constants";
import useUnsavedChangesConfirmation from 'hooks/useUnsavedChangesConfirmation';

const Menu = ({
    isCollapsed
}) => {

    const { t } = useTranslation()

    const user = useAuthSelector(userSelector);
    const userInfo = useGlobalSelector(userInfoSelector);
    const globalProjectId = useAuthSelector(globalProjectIdSelector);

    const permissionsUtils = usePermissions();

    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const urlParams = useParams();

    const [selectedKeys, setSelectedKeys] = useState([]);
	const [openedKeys, setOpenedKeys] = useState([]);

    const mappedMenuItems = useMemo(() => mapMenuItems(
		menuItems,
		user,
        userInfo, 
        globalProjectId,
        permissionsUtils,
        t
	), [menuItems, user, userInfo, globalProjectId, permissionsUtils, t]);

    const handleMenuItemClick = item => {
        if(item.url){
            navigate(item.url)
        }
		document.body.classList.remove("rt--body-open-sidebar");
	};

    const handleMenuItemClickWithConfirmation = useUnsavedChangesConfirmation({
        cb: handleMenuItemClick,
        subscribe: [UNSAVED_FORM_PAGE_TYPE.TAB, UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

     /** Update active menu items, when path changes */
     useEffect(() => {
        if (!isCollapsed) {
            setDefaultOpenedKeys()
        }
        setDefaultSelectedKeys();
    }, [pathname + search])

    const pathForMenuProcess = getPathForMenuProcess(pathname, urlParams, search);

    const setDefaultOpenedKeys = () => {
		setOpenedKeys(
			processDefaultOpenedKeys(
				menuItems,
				null,
				pathForMenuProcess
			),
		);
	};

	const setDefaultSelectedKeys = () => {
		setSelectedKeys(
			processDefaultSelectedKeys(
				menuItems,
				pathForMenuProcess
			),
		);
	};

    return (
        <div className='rt--sidebar-content-menu rt--flex-equal'>
            <AntMenu
                mode="inline"
                expandIcon={<Icon name='down' className='rt--sidebar-content-menu-down' />}
                selectedKeys={selectedKeys}
                openKeys={openedKeys}
                onOpenChange={keys => {
                    setOpenedKeys(
                        keys.filter(k => k.length === 1 || keys.includes(k[0]) ),
                    );
                }}
            >
                {
                    mappedMenuItems.map(item => item.children ? (
                        <AntMenu.SubMenu
                            popupClassName="rt--sidebar-content-menu-sub"
                            key={item.key}
                            icon={item.icon}
                            title={item.label}
                            expandIcon={<Icon name='down' className='rt--sidebar-content-menu-down' />}
                        >
                            {
                                item.children.map(item => (
                                    <AntMenu.Item
                                        key={item.key}
                                        icon={item.icon}
                                        onClick={() => handleMenuItemClickWithConfirmation(item)}
                                    >
                                        { item.label }
                                    </AntMenu.Item>
                                ))
                            }
                        </AntMenu.SubMenu>
                    ) : (
                        <AntMenu.Item
                            key={item.key}
                            icon={item.icon}
                            onClick={() => handleMenuItemClickWithConfirmation(item)}
                        >
                           { item.label }
                        </AntMenu.Item>
                    )
                )
                }
            </AntMenu>
        </div>
    )
}

Menu.propTypes = {
    isCollapsed: PropTypes.bool
}

export default Menu;