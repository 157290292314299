import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "core/hooks/useTranslation";

import { Spin } from "antd";

import NoData from './noData';

import { LEADERBOARD_DATA_TYPE, LEADERBOARD_TYPE } from '../constants';
import { TARGET_MARKET, TARGET_MARKETS_TT_KEYS } from 'constants/affiliate.constants';

import useFormat from 'hooks/useFormat';

import { binaryToFlags } from 'utils/common';

import leaderType from "types/dashboard/leader.type";


const LeaderboardMobileView = ({
    leaders,
    type,
    isLoading,
    currencyCode,
    dataType,
    countText
}) => {
    const { t } = useTranslation();

    const { formatAmount, formatNumber } = useFormat();

    if (isLoading) {
        return (
            <div className="rt--chart-section rt--chart-top-section">
                <Spin spinning={isLoading} />
            </div>
        )
    }

    return (
        <div className='rt--chart-section rt--chart-top-section rt--pl-12 rt--pr-12 rt--pb-16 rt--pt-16'>
            {
                leaders.length === 0 ? (
                    <NoData />
                ) : (
                    <div className="rt--chart-top-section-items">
                        {
                            leaders.map((leader, index) => (
                                <div className="rt--chart-top-section-item rt--flex rt--justify-between" key={leader.id}>
                                    <div 
                                        className={
                                            'rt--chart-top-num rt--chart-top-num' + '-' + (index + 1)
                                        }
                                    >
                                        <span className={'rt--font-normal rt--font-regular'}>
                                            {index + 1}
                                        </span>
                                    </div>
                                    <div className='rt--chart-top-section-item-info rt--flex-equal rt--flex'>
                                        <div className='rt--chart-top-section-item-info-content rt--flex-equal rt--pl-8 rt--pr-8'>
                                            <div 
                                                className={
                                                    'rt--flex rt--align-center rt--chart-top-section-item-info-row rt--chart-top-section-item-info-row-head rt--justify-between' + (dataType === LEADERBOARD_DATA_TYPE.COUNTRY ? " rt--height-full" : "")
                                                }
                                            >
                                                <b>
                                                    { 
                                                        dataType === LEADERBOARD_DATA_TYPE.COUNTRY ? `${t("backoffice.dashboard.country") } : ${leader.name}` :
                                                        dataType === LEADERBOARD_DATA_TYPE.CAMPAIGN ? leader.name : leader.userName 
                                                    }
                                                </b>
                                                <span>
                                                    {
                                                        type === LEADERBOARD_TYPE.OPERATIONAL ?
                                                            formatNumber(leader.count) : 
                                                            formatAmount(leader.amount, currencyCode)
                                                    }
                                                </span>
                                            </div>
                                            {
                                                dataType !== LEADERBOARD_DATA_TYPE.COUNTRY && (
                                                    <div className='rt--flex rt--align-center rt--chart-top-section-item-info-row'>
                                                        <b>
                                                            { t("backoffice.dashboard.id") }:
                                                        </b>
                                                        <span>
                                                            {
                                                                leader.id
                                                            }
                                                        </span>
                                                    </div>
                                                )
                                            }
                                            
                                            {
                                                dataType === LEADERBOARD_DATA_TYPE.AFFILIATE && (
                                                    <div className='rt--flex rt--align-center rt--chart-top-section-item-info-row'>
                                                        <b>
                                                            { t("backoffice.dashboard.country") }:
                                                        </b>
                                                        <span>
                                                            {
                                                                leader.countryCode ? t(`backoffice.countries.${leader.countryCode}`) : "-"
                                                            }
                                                        </span>
                                                    </div>
                                                )
                                            }
                                            {
                                                dataType === LEADERBOARD_DATA_TYPE.AFFILIATE && (
                                                    <div className='rt--flex rt--align-center rt--chart-top-section-item-info-row'>
                                                        <b>
                                                            { t("backoffice.dashboard.targetMarket") }:
                                                        </b>
                                                        <span>
                                                            {
                                                                leader.targetMarket ? 
                                                                    binaryToFlags(Object.values(TARGET_MARKET), leader.targetMarket)
                                                                    .map(
                                                                        key => t(`backoffice.affiliates.${TARGET_MARKETS_TT_KEYS[key]}`)
                                                                    ).join(", ")
                                                                 : "-"
                                                            }
                                                        </span>
                                                    </div>
                                                )
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                )
            }
        </div>
    );
}

/** LeaderboardMobileView propTypes*/
LeaderboardMobileView.propTypes = {
    leaders: PropTypes.arrayOf(leaderType),
    isLoading: PropTypes.bool,
    currencyCode: PropTypes.string,
    /**
     * Leaderboard type (this adds/removes some display information)
     */
    type: PropTypes.oneOf([
        LEADERBOARD_TYPE.FINANCIAL,
        LEADERBOARD_TYPE.OPERATIONAL,
    ]),
     /** Data Type */
     dataType: PropTypes.oneOf(Object.values(LEADERBOARD_DATA_TYPE)),
    /** Count Column Title */
    countText: PropTypes.string
};

export default LeaderboardMobileView;