//#region react
import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";
//#endregion

//#region actions
import {
    getSessions,
    setSessionsSorting,
    setSessionsFilters,
    resetSessions
} from "store/actions/portal/sessions/sessions.action";
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import Filters from "./filters.component";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
import useDate from "hooks/useDate";
//#endregion

//#region utils
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import ApiUrls from 'constants/api.constants';
import { PERMISSION_ACTION } from 'core/constants/permission';
import { SESSIONS } from "constants/pageName.constants";
import { USER_TYPE } from "constants/user.constants";
import { getTableColumns } from "./columns";
//#endregion

//#region types
import sortingType from "types/common/sorting.type";
import sessionType from 'types/session/session.type';
//#endregion

import usePermissions from "core/hooks/usePermission";

const makeExportUrl = ({ userRole, userId }) => {
    let url = ApiUrls.EXPORT_SESSIONS;

    if (userRole) {
        url += `?userRole=${userRole}`
    }

    if (userId) {
        url += `&userNameOrId=${userId}`
    }

    return url;
}

/** Sessions Tab Component */
const SessionsComponent = ({
    getSessions,
    resetSessions,
    setSessionsSorting,
    setSessionsFilters,
    isLoading,
    sessions,
    total,
    sorting,
    filters,
    userRole,
    userId,
    permissionResource
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const { dateService } = useDate();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: SESSIONS });

    const exportURL = makeExportUrl({ userRole, userId });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = permissionUtils.has( permissionResource, PERMISSION_ACTION.EXPORT );

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded
    } = useMemo(() => {
        const excludedColumns = (
            ["userName"]
        )

        if (userRole === USER_TYPE.ADMIN) {
            excludedColumns.push("previousUserName");
        }

        return tableColumnsCreator({
            mainColumns: getTableColumns,
            constructForExport: true,
            constructForInclude: true,
            includedColumns,
            additionalProps: {
                excludedColumns,
                dateService,
                t
            },
            t
        });
    }, [userRole, includedColumns, t])

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        filters: (
            <Filters
                userRole={userRole}
                userId={userId}
            />
        )
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.sessions.sessions"),
            columns: columnsForExport,
            url: exportURL,
            filters: filters,
        }
    }

    //#endregion

    // Reset session on component unmounth
    useEffect(() => {
        return () => {
            resetSessions();
        }
    }, [])

    return (
        <TabTableDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={sessions}
                total={total}
                loadFn={nextPage => getSessions(userRole, userId, nextPage)}
                sorting={sorting}
                setSortingFn={setSessionsSorting}
                filters={filters}
                setFiltersFn={setSessionsFilters}
                enableReload={true}
            />
        </TabTableDashboardLayout>
    );
};

/** SessionsComponent propTypes
 * PropTypes
*/
SessionsComponent.propTypes = {
    /** Redux action to get sessions */
    getSessions: PropTypes.func,
    /** Redux action to reset sessions */
    resetSessions: PropTypes.func,
    /** Redux state property, represents the array of sessions  */
    sessions: PropTypes.arrayOf(sessionType),
    /** Redux state property, sessions total count */
    total: PropTypes.number,
    /** Redux state property, is true when loading sessions */
    isLoading: PropTypes.bool,
    /** Redux action to set sessions sorting details */
    setSessionsSorting: PropTypes.func,
    /** Redux action to set sessions filters details */
    setSessionsFilters: PropTypes.func,
    /** Redux state property,sessions sorting details */
    sorting: sortingType,
    /** Redux state property, sessions filters */
    filters: PropTypes.object,
    /** Current user type */
    userRole: PropTypes.oneOf(Object.values(USER_TYPE)),
    /**Current user Id */
    userId: PropTypes.string,
    /** The permission resource */
    permissionResource: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
    getSessions: (userRole, userId, nextPage) => {
        dispatch(getSessions(userRole, userId, nextPage))
    },
    resetSessions: () => {
        dispatch(resetSessions())
    },
    setSessionsSorting: sorting => {
        dispatch(setSessionsSorting(sorting));
    },
    setSessionsFilters: (filters, keepPage) => {
        dispatch(setSessionsFilters(filters, keepPage));
    }
});

const mapStateToProps = state => {
    return {
        isLoading: state.sessions.isLoading,
        sessions: state.sessions.sessions,
        total: state.sessions.total,
        sorting: state.sessions.sorting,
        filters: state.sessions.filters
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SessionsComponent);
