import React, { useLayoutEffect, useState } from 'react';
import { createPortal } from "react-dom";

import styles from '../../../debug.css?inline';

import { debugStore, useDebugSelector } from '../../..';

import { getSettingsFromStorage } from '../../../helpers';

const createPopup = () => {
    const { popupWidth = 600, popupHeight = 400 } = getSettingsFromStorage();
    const popup = window.open('', '_blank', `width=${popupWidth},height=${popupHeight}`);

    popup.document.body.style.margin = 0;

    const container = popup.document.createElement('div');

    const style = popup.document.createElement('style');
    style.innerHTML = styles;

    const title = popup.document.createElement('title');
    title.textContent = "Debug Panel";

    popup.document.body.appendChild(container);
    popup.document.head.appendChild(title);
    popup.document.head.appendChild(style);

    popup.addEventListener('beforeunload', () => {
        debugStore.changePlacement("bottom");
    });

    popup.addEventListener('resize', () => {
        debugStore.setSettingsToStorage({
            popupWidth: popup.innerWidth,
            popupHeight: popup.innerHeight,
        });
    });

    window.addEventListener('beforeunload', () => {
        popup.close();
    });

    return {popup, container};
}

export const withPopup = (WrappedComponent) => (upperProps) => {
    const [popupRef, setPopupRef] = useState(null);

    const rootDiv = document.getElementById('root');

    const { opened, enabled, placement } = useDebugSelector();

    useLayoutEffect(() => {
        if (!enabled || !opened || placement !== "popup") {
            popupRef?.popup.close();
            setPopupRef(null);
            return;
        }

        if (placement === "popup") {
            setPopupRef(createPopup());
        }
    }, [opened, enabled, placement]);

    if (!popupRef) {
        return createPortal(<WrappedComponent {...upperProps} />, rootDiv);
    }

    return createPortal(<WrappedComponent {...upperProps} />, popupRef.container);
}
