import { useEffect, useState } from "react";

import { getMarketingPieCharts } from "../api";

import { makeTimePeriodCustomRangeOutputValue } from "../helpers";

import useDate from "hooks/useDate";

const useMarketingPieCharts = ( filters, globalProjectId, updateLastUpdatedDate) => {

    const { dateService } = useDate();

    const [data, setData] = useState({
        isLoading: false,
        data: {},
    })

    useEffect(() => {
        setData((prevState) => {
            return {
                ...prevState,
                isLoading: true
            }
        })

        const { request, cancelRequest } = getMarketingPieCharts({
            timePeriod: filters?.period?.period,
            ...makeTimePeriodCustomRangeOutputValue(filters?.period?.from, filters?.period?.to, dateService)
        })

        request
            .then((response) => {
                const { data: { value: payload } } = response;

                updateLastUpdatedDate(payload.lastUpdatedAt);
                setData((prevState) => {
                    return {
                        ...prevState,
                        isLoading: false,
                        data: payload.data
                    }
                })
            })
            .catch(() => {
                setData((prevState) => {
                    return {
                        ...prevState,
                        isLoading: false
                    }
                })
            })

        return () => {
            cancelRequest();
        }
    }, [ globalProjectId, updateLastUpdatedDate, filters?.period])

    return data;

}

export default useMarketingPieCharts;