import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useNavigate } from 'react-router-dom';

import { Form, Col, Row } from 'antd';

import Modal from "components/common/modal";
import Select from 'components/common/select';
import Input from 'components/common/input';
import TemplateChooser from 'components/common/templateChooser';

import { createNewsletterTemplate } from 'store/actions/portal/communications/newsletter/templates.action';

import { isMobile } from 'utils/common';

import { NAME_REGEX } from 'constants/regex.constants';
import { POPUP_SIZE } from 'constants/common.constants';
import { NEWSLETTER_TEMPLATE } from 'constants/newsletter.constants';
import ROUTES from "core/constants/routes/routes";

import useLanguages from 'hooks/useLanguages';

/** Template Creating Popup Component */
const TemplateCreateComponent = ({
    isSaving,
    createNewsletterTemplate,
    onClose
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;
    const navigate = useNavigate();

    const [ languages ] = useLanguages();

    /** Fires when form submitted
       * @function
       * @memberOf TemplateCreateComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                createNewsletterTemplate(
                    {
                        ...data
                    },
                    record => {
                        navigate(`${ROUTES.NEWSLETTER_CUSTOMIZE}/${record.id}`)
                    });
            }).catch(ex => {
                console.log(ex)
            })
    }

    return (
        <Modal
            title={t('backoffice.communication.createNewsletter')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.create')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.BIG}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    templateType: NEWSLETTER_TEMPLATE.DEFAULT
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24} lg={12}>
                        <Form.Item
                            label={`${t('backoffice.communication.newsletterName')} *`}
                            name="name"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { max: 30, message: t('backoffice.validation.fieldInvalid') },
                                { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.communication.newsletterName')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.communication.newsletterName')}`}
                                maxLength={30}
                                autoFocus={!isMobile()}
                                autoComplete="off"
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24} lg={12}>
                        <Form.Item
                            label={`${t('backoffice.communication.language')} *`}
                            name="languageCode"
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') },
                            ]}
                        >
                            <Select
                                options={
                                    Object.keys(languages)
                                        .map(item => (
                                            { value: item.toUpperCase(), text: t(`backoffice.languages.${item}`) }
                                        ))
                                }
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.communication.language')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <h4 className='rt--form-section-title rt--font-bold rt--font-biger rt--mb-16 rt--mt-8'>
                            { t('backoffice.communication.chooseTemplate') }
                        </h4>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="templateType"
                            className='rt--form-item-without-margin'
                        >
                            <TemplateChooser 
                                items={
                                    [
                                        { img: `${import.meta.env.SYSTEM_CDN_URL}/system/static/images/emailTemplateDefault.png`, name: "Default", value: NEWSLETTER_TEMPLATE.DEFAULT},
                                        //{ img: `${import.meta.env.SYSTEM_CDN_URL}/system/static/images/PurpleTemplateImg.png`, name: "Purple", value: NEWSLETTER_TEMPLATE.PURPLE, disabled: true}
                                    ]
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

/** TemplateCreateComponent propTypes
    * PropTypes
*/
TemplateCreateComponent.propTypes = {
    /** Redux state property, is true when creating template request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to create newsletter template */
    createNewsletterTemplate: PropTypes.func,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        createNewsletterTemplate: (data, onSuccess) => {
            dispatch(createNewsletterTemplate(data, onSuccess));
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.newsletter.isSaving
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateCreateComponent)