import RESPONSE_CODES from "core/constants/api/responseCodes";

import showError from "core/helpers/common/showError";

const ERROR_CODES_TO_NOT_SHOW_ERROR_MESSAGE = [
    RESPONSE_CODES.AccountLoginLocked,
    RESPONSE_CODES.AccountLoginTemporaryLocked,
    RESPONSE_CODES.NotApproved,
    RESPONSE_CODES.Declined,
    RESPONSE_CODES.Blocked
]

const processError = error => {
    if(!error.response){
        showError("CORS ERROR");
        return Promise.reject(error);
    }
    const errorData = error?.response?.data;
    if(!ERROR_CODES_TO_NOT_SHOW_ERROR_MESSAGE.includes(errorData?.resultCode) ){
        showError(errorData?.message ?? "", errorData?.resultCode ?? 0, errorData?.resource ?? "");
    }

    return Promise.reject(error);
}

export default processError;