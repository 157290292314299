//#region react
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";

import { Form, Row, Col, Spin } from 'antd';

import CustomizeImgUploader from "../../customizeImgUploader";

import {
    PROMO_FILE_NAMES,
    PROMO_FILE_TYPE,
    PROMO_SECTION_TYPE
} from "constants/promo.constants";

/** Portal Customize Page Sections Logo Editor Component */
const LogoEditor = ({
    isLoading,
    files,
}) => {
    const { t } = useTranslation();

    return (
        <Spin spinning={isLoading}>
            <div className="rt--customize-widget-main-content-form">
                <Form
                    className="rt--form"
                    colon={false}
                    requiredMark={false}
                    layout="vertical"
                >
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label={t("backoffice.promo.uploadLogo")}
                            >
                                <CustomizeImgUploader
                                    sectionType={PROMO_SECTION_TYPE.LOGO}
                                    fileType={PROMO_FILE_TYPE.LOGO}
                                    imgPath={files[PROMO_FILE_NAMES[PROMO_FILE_TYPE.LOGO]]}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label={t("backoffice.promo.uploadFavicon")}
                            >
                                <CustomizeImgUploader
                                    sectionType={PROMO_SECTION_TYPE.LOGO}
                                    fileType={PROMO_FILE_TYPE.FAVICON}
                                    imgPath={files[PROMO_FILE_NAMES[PROMO_FILE_TYPE.FAVICON]]}
                                />

                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </div>
        </Spin>
    );
};

/** LogoEditor propTypes
 * PropTypes
*/
LogoEditor.propTypes = {
    /** Redux state property, is true when loading portal customization data */
    isLoading: PropTypes.bool,
    /** Images Paths */
    files: PropTypes.objectOf(PropTypes.string)
};


const mapStateToProps = (state) => {
    return {
        isLoading: state.promoCustomize.isLoading,
        files: state.promoCustomize.configuration.logoSection.files
    };
};

export default connect(mapStateToProps, null)(LogoEditor);
