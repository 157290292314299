
import axios from "core/lib/axios";

import {
    setNewsletterCustomizeActionBefore,
    setNewsletterCustomizeActionFinished
} from "./common.action";

import {
    SET_NEWSLETTER_CUSTOMIZE_CONFIGURATION,
} from "../../../actionTypes";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

const setNewsletterConfiguration = configuration => ({
    type: SET_NEWSLETTER_CUSTOMIZE_CONFIGURATION,
    payload: { configuration }
})

export const getNewsletterConfiguration = id => {
    return dispatch => {
        dispatch(setNewsletterCustomizeActionBefore());

        return axios({
            url: ApiUrls.GET_NEWSLETTER_CONFIGURATION,
            method: Methods.GET,
            params: { id }
        })
            .then(({ data: { value: configuration } }) => {
                dispatch(setNewsletterConfiguration(configuration));
                dispatch(setNewsletterCustomizeActionFinished());
            })
            .catch((ex) => {
                dispatch(setNewsletterCustomizeActionFinished());
            })
    }
}