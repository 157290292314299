const getPathForMenuProcess = (pathname, urlParams, search) => {
	let path = pathname;

	if (urlParams) {
		Object.keys(urlParams).forEach((key) => {
			path = path.replace(urlParams[key], ":" + key);
		});
	}

	if (search) {
		path = path + search;
	}

	return path;
};

export default getPathForMenuProcess;