import { useEffect, useState } from "react";

import useDate from "hooks/useDate";

import { getMarketingTopCampaigns, getMarketingTopCountries } from "../api";

import { makeTimePeriodCustomRangeOutputValue } from "../helpers";

const useMarketingTopCountries = (filters, updateLastUpdatedDate) => {

    const { dateService } = useDate();

    const [leaderboardState, setLeaderboardState] = useState({
        leaderBoardData: [],
        isLoading: false,
    })

    useEffect(() => {
        setLeaderboardState((prevState) => {
            return {
                ...prevState,
                isLoading: true
            }
        })

        const { request, cancelRequest } = getMarketingTopCountries({
            dataType: filters?.dataType,
            timePeriod: filters?.period?.period,
            ...makeTimePeriodCustomRangeOutputValue(filters?.period?.from, filters?.period?.to, dateService)
        })

        request
            .then((response) => {
                const { data: { value: payload } } = response;
                updateLastUpdatedDate(payload.lastUpdatedAt)
                setLeaderboardState((prevState) => {
                    return {
                        ...prevState,
                        leaderBoardData: payload.data,
                        isLoading: false,
                    }
                })
            })
            .catch(() => {
                setLeaderboardState((prevState) => {
                    return {
                        ...prevState,
                        isLoading: false,
                    }
                })
            })

        return () => {
            cancelRequest()
        }
    }, [filters, updateLastUpdatedDate])

    return leaderboardState;
}

export default useMarketingTopCountries;