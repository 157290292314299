import React, { useCallback, useEffect, useRef } from "react";

import useDevice from "core/hooks/useDevice";

const IOSSpecificFunctional = () => {

    const _scrollBlockElementRef = useRef(null);

    const { isIOS, osVersion } = useDevice()
	
    const iosScrollHandler = useCallback(event => {
        const target = event.target;

		window.onscroll = function (ev) {
			if ( document.body.offsetHeight + window.pageYOffset > window.innerHeight) {
				_scrollBlockElementRef.current.classList.add("rt--block-scroll-active");
			} else {
				_scrollBlockElementRef.current.classList.remove("rt--block-scroll-active");
			}
		};

		if (target.classList.contains("ant-input")) {
			if (!_scrollBlockElementRef.current.classList.contains("rt--block-scroll-active")) {
				_scrollBlockElementRef.current.classList.add("rt--block-scroll-active");
			}

			/** Is in modal */
			if (target.closest(".ant-drawer-wrapper-body") && !target.closest(".rt--select-dropdown")) {
				const overlayModal = target.closest(".ant-drawer-wrapper-body");
				overlayModal.classList.add("rt--modal-overlay-active");
			}
		} else {

			_scrollBlockElementRef.current.classList.remove("rt--block-scroll-active");

			const overlayModal = document.getElementsByClassName("rt--modal-overlay-active")[0];
			if (overlayModal) {
				overlayModal.classList.remove("rt--modal-overlay-active");
			}

			const searchFocused = document.getElementsByClassName("ant-input-affix-wrapper-focused")[0];
			if (searchFocused) {
				_scrollBlockElementRef.current.classList.add("rt--block-scroll-active");
			} else {
				_scrollBlockElementRef.current.classList.remove("rt--block-scroll-active");
			}
		}
    }, [])

    const iosKeyPressHandler = useCallback(event => {
		if (event.which === 0) {
			_scrollBlockElementRef.current.classList.remove("rt--block-scroll-active");

			if ((document.body.offsetHeight + window.pageYOffset) > window.innerHeight) {
				_scrollBlockElementRef.current.classList.add("rt--block-scroll-active");
			} else {
				_scrollBlockElementRef.current.classList.remove("rt--block-scroll-active");
			}

			const overlayModal = document.getElementsByClassName("rt--modal-overlay-active")[0];
			if (overlayModal) {
				overlayModal.classList.remove("rt--modal-overlay-active");
			}
		}
    }, [])

    useEffect(() => {
        const appElement = document.getElementsByClassName("App")[0];
		if (isIOS) {
			if (osVersion < 16) {
				appElement.classList.add("app-ios-low");
			} else {
				appElement.classList.add("app-ios");
			}
		}

		document.addEventListener("click", iosScrollHandler);
		document.addEventListener("focusout", iosKeyPressHandler);
		
        return () => {
			document.removeEventListener("click", iosScrollHandler);
			document.removeEventListener("focusout", iosKeyPressHandler);
		}

	}, [])

	return (
        <div className='rt--block-scroll' ref={_scrollBlockElementRef}></div>
    )
};

export default IOSSpecificFunctional;