import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useLocation, useNavigate } from "react-router-dom";

import {
    getProjectBrands,
    saveProjectBrand,
} from "store/actions/portal/projects/brands.action";

import { Col, Form, Row, Spin } from 'antd';

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";
import Input from 'components/common/input';
import Icon from "components/common/icon";

import useUnsavedChangesConfirmation from 'hooks/useUnsavedChangesConfirmation';

import { isFormChanged } from "utils/form";
import { deleteQueryStringParamsToUrl } from 'utils/queryString';

import { NAME_REGEX, DOMAIN_REGEX, LICENSE_REGEX } from 'constants/regex.constants';
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'core/constants/permission';
import { UNSAVED_FORM_PAGE_TYPE } from 'constants/common.constants';

import brandType from "types/project/brand.type";

import usePermissions from 'core/hooks/usePermission';

const GeneralInfoComponent = ({
    getProjectBrands,
    brands,
    saveProjectBrand,
    isLoading,
    isSaving,
    onTabChange,
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const { search, hash, pathname } = useLocation();
    const navigate = useNavigate();

    const [isFormTouched, setIsFormTouched] = useState(false);
    
    const navigateWithConfirmation = useUnsavedChangesConfirmation({
        cb: navigate,
        subscribe: [UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    const currentBrandId = (new URLSearchParams(search)).get("brandId");

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const hasModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT_BRANDS, PERMISSION_ACTION.MODIFY );

    /** Load brands */
    useEffect(() => {
        if(brands.length === 0){
            getProjectBrands();
        }
    }, [brands.length])

    const currentBrand = brands.find(brand => brand.id === currentBrandId);

    // Set form field values when data is loaded
    useEffect(() => {
        const currentBrand = brands.find(brand => brand.id === currentBrandId);

        if (currentBrand) {
            setFieldsValue({
                name: currentBrand.name,
                domain: currentBrand.domain,
                licenseId: currentBrand.licenseId
            })
        }

        setIsFormTouched(false);
    }, [brands])

    const handleForm = () => {
        validateFields()
            .then((fieldValuesObj) => {
                saveProjectBrand({
                    ...fieldValuesObj,
                    id: currentBrandId,
                })
            })
            .catch(Function.prototype)
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <SubTabFormDashboardLayout
            buttons={[
                {
                    type: "primary",
                    onClick: handleForm,
                    text: t("backoffice.common.save"),
                    enabled: hasModifyPermission,
                    loading: isSaving,
                    disabled: !isFormTouched
                },
                {
                    type: "secondary",
                    onClick: () => {
                        const url = deleteQueryStringParamsToUrl(pathname, search, hash, ["brandId", "brandName"] );
                        navigateWithConfirmation(url, { replace: true } )
                    },
                    text: (
                        <span className='rt--back-button'>
                            <Icon name="left" size={16} />
                            <span>
                                {t("backoffice.common.back")}
                            </span>
                        </span>
                    ),
                    enabled: true,
                    className: "rt--button-secondary",
                    placement: "left",
                },
            ]}
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({
                        name: formValues.name,
                        domain: formValues.domain,
                        licenseId: formValues.licenseId,
                    }, {
                        name: currentBrand?.name ?? "",
                        domain: currentBrand?.domain ?? "",
                        licenseId: currentBrand.licenseId ?? "",
                    }))}
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12} lg={6}>
                            <Form.Item
                                label={`${t('backoffice.projects.name')} *`}
                                name="name"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 30, message: t('backoffice.validation.fieldInvalid') },
                                    { min: 2, message: t('backoffice.validation.fieldInvalid') },
                                    { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                className={"rt--general-form-item" + (!hasModifyPermission ? " rt--form-item-disabled" : "" )}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.name')}`}
                                validateFirst
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.name')}`}
                                    maxLength={30}
                                    disabled={!hasModifyPermission}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} lg={6}>
                            <Form.Item
                                label={`${t('backoffice.projects.mainDomain')} *`}
                                name="domain"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { pattern: DOMAIN_REGEX, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                className={"rt--general-form-item" + (!hasModifyPermission ? " rt--form-item-disabled" : "" )}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.mainDomain')}`}
                                validateFirst
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.mainDomain')}`}
                                    disabled={!hasModifyPermission}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={t('backoffice.projects.licenseId')}
                                name="licenseId"
                                rules={[
                                    { max: 11, message: t('backoffice.validation.fieldInvalid') },
                                    { pattern: LICENSE_REGEX, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                validateFirst
                                className='rt--general-form-item'
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.licenseId')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.licenseId')}`}
                                    maxLength={11}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </SubTabFormDashboardLayout>
    );
}

/** GeneralInfoComponent propTypes
    * PropTypes
*/
GeneralInfoComponent.propTypes = {
    /** Redux action to get project brands */
    getProjectBrands: PropTypes.func,
    /** Redux action to save project brand */
    saveProjectBrand: PropTypes.func,
    /** Redux state property, is true when project brands are loading */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when project brand is saving */
    isSaving: PropTypes.bool,
    /** Redux state, represents the currencies of current editing project  */
    brands: PropTypes.arrayOf(brandType),
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        getProjectBrands: () => {
            dispatch(getProjectBrands());
        },

        saveProjectBrand: brand => {
            dispatch(saveProjectBrand(brand));
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.projects.isSaving,
        isLoading: state.projects.isLoading,
        brands: state.projects.edit.brands
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent);