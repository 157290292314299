import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import Spin from "core/ui-kit/spin";

import initLocale from "core/helpers/language/initLocale";

import translationService from "core/services/translationService";

import { globalStore } from "core/stores/globalStore";

import { useTranslation } from "core/hooks/useTranslation";
import useDate from "core/hooks/useDate";

import { mapLocaleToDateServiceLocale } from "./constants";

const AppWrapper = () => {
    const [isInitialized, setIsInitialized] = useState(false);

    const { locale} = useTranslation()

    const { dateService } = useDate();
    
    useEffect(() => {
        initLocale();
    }, [])

    useEffect(() => {
        dateService.setLocale(mapLocaleToDateServiceLocale[locale])
    }, [locale])

    useEffect(() => {
		Promise.all([
            globalStore.getSystemLanguages(), 
            translationService.init()
        ]).finally(() => {
            setIsInitialized(true);
        });
	}, []);

    if (!isInitialized) {
		return <Spin />;
	}

	return <Outlet />;
};

export default AppWrapper;