import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";

import { setMarketingReportsActionBefore, setMarketingReportsActionFinished } from "./marketing.action";

import {
    SET_MEDIA_REPORT,
    SET_MEDIA_REPORT_SORTING,
    SET_MEDIA_REPORT_FILTERS,
    SET_MEDIA_REPORT_TOTALS
} from "../../../../actionTypes";

const setMediaReport = (report, add) => ({
    type: SET_MEDIA_REPORT,
    payload: { report, add },
});

export const setMediaReportSorting = sorting => ({
    type: SET_MEDIA_REPORT_SORTING,
    payload: { sorting },
});

export const setMediaReportFilters = (filters, keepPage) => ({
    type: SET_MEDIA_REPORT_FILTERS,
    payload: { filters, keepPage },
});


export const getMediaReport = nextPage => {
    return (dispatch, getState) => {

        const mediaReport = getState().marketingReports.mediaReport;
        
        const page = nextPage === "first" ? 1 : nextPage === "next" ? mediaReport.sorting.page + 1 : mediaReport.sorting.page;
        
        let params = {
            ...mediaReport.sorting,
            ...mediaReport.filters,
            page: page
        };

        if(page > 1 && mediaReport.total <= (page - 1 ) * mediaReport.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && mediaReport.total === 0){
            return Promise.resolve();
        }

        dispatch(setMarketingReportsActionBefore());

        return axios({
            url: ApiUrls.GET_MEDIA_REPORT,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: report } }) => {
                dispatch(setMediaReport(report, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setMediaReportSorting({ ...mediaReport.sorting, page: page }));
                dispatch(setMarketingReportsActionFinished());
            })
            .catch(() => {
                dispatch(setMarketingReportsActionFinished());
            });
    }
}

const setMediaReportTotals = totals => ({
    type: SET_MEDIA_REPORT_TOTALS,
    payload: { totals },
});

export const getMediaReportTotals = () => {
    return (dispatch, getState) => {

        const mediaReport = getState().marketingReports.mediaReport;

        const filters = { ... mediaReport.filters };
        
        let params = {
            ...filters
        };
        
        return axios({
            url: ApiUrls.GET_MEDIA_REPORT_TOTALS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: totals } }) => {
                dispatch(setMediaReportTotals(totals));
            })
            .catch(ex => {
                console.log(ex)
            });
    }
}