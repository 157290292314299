import React, { lazy } from "react";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import Spin from "core/ui-kit/spin";

import ROUTES from "core/constants/routes/routes";

import withSuspenseHoc from "core/HOCs/withSuspense";

const RegisterContainer = withSuspenseHoc(lazy(() => import("./registerContainer")), <Spin />);

const RegisterPageRoute = {
	path: ROUTES.REGISTER,
	element: (
		<GoogleReCaptchaProvider reCaptchaKey={import.meta.env.SYSTEM_CAPTCHA_KEY}>
			<RegisterContainer />
		</GoogleReCaptchaProvider>
	),
};

export default RegisterPageRoute
