import { message } from 'antd';

import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import {
    SET_PROMO_CUSTOMIZE_LANGUAGE,
    SET_PROMO_CUSTOMIZE_ACTION_BEFORE,
    SET_PROMO_CUSTOMIZE_ACTION_FINISH,
    SET_PROMO_CUSTOMIZE_SAVE_ACTION_BEFORE,
    SET_PROMO_CUSTOMIZE_SAVE_ACTION_FINISH,
    SET_PROMO_CUSTOMIZE_PUBLISH_ACTION_BEFORE,
    SET_PROMO_CUSTOMIZE_PUBLISH_ACTION_FINISH
} from "../../../actionTypes";

export const setPromoCustomizeLanguage = language => ({
    type: SET_PROMO_CUSTOMIZE_LANGUAGE,
    payload: { language }
});

export const setPromoCustomizeActionBefore = () => ({
    type: SET_PROMO_CUSTOMIZE_ACTION_BEFORE,
});

export const setPromoCustomizeActionFinished = () => ({
    type: SET_PROMO_CUSTOMIZE_ACTION_FINISH,
});

export const setPromoCustomizeSaveActionBefore = () => ({
    type: SET_PROMO_CUSTOMIZE_SAVE_ACTION_BEFORE,
});

export const setPromoCustomizeSaveActionFinished = () => ({
    type: SET_PROMO_CUSTOMIZE_SAVE_ACTION_FINISH,
});

export const setPromoCustomizePublishActionBefore = () => ({
    type: SET_PROMO_CUSTOMIZE_PUBLISH_ACTION_BEFORE,
});

export const setPromoCustomizePublishActionFinished = () => ({
    type: SET_PROMO_CUSTOMIZE_PUBLISH_ACTION_FINISH,
});

export const publishPromo = () => {
    return dispatch => {
        dispatch(setPromoCustomizePublishActionBefore());
        return axios({
            url: ApiUrls.PUBLISH_PROMO,
            method: Methods.POST,
            data: {}
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
            } 
            dispatch(setPromoCustomizePublishActionFinished());
        })
        .catch((ex) => {
            dispatch(setPromoCustomizePublishActionFinished());
        })
    }
}
