import React, { memo, useMemo } from "react";
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";
import translationService from "core/services/translationService";
import useDevice from "core/hooks/useDevice";

import Icon from "core/ui-kit/icon";
import Dropdown from "core/ui-kit/dropdown";
import Flag from "core/ui-kit/flag";

import setLocale from "core/helpers/language/setLocale";

import languageCodeToISO from "core/constants/language/languageCodeToISO";

const LanguagesDropdown = ({
    languages
}) => {

    const { t, locale } = useTranslation();

    const { isMobile } = useDevice();

    const handleClick = ({ key }) => {
        translationService.changeLocale(key).then(changedLocal => {
            setLocale(changedLocal)
        })
    }

    const supportMultipleLanguages = Object.keys(languages).length > 1;

    const menuItems = useMemo(() => {
        return Object.keys(languages).map(lang => ({
            key: lang,
            label: (
                <div 
                    className={ 
                        'rt--flex rt--align-center rt--justify-between rt--languages-list-item' + 
                        ( lang === locale ? " rt--languages-list-item-selected" : "") 
                    }
                >
                    <div className={
                        "rt--flex rt--align-center rt--justify-between" + 
                        (isMobile ? " rt--flex-row-reverse" : " rt--flex-equal")
                    }>
                        <span className={'rt--title rt--font-normal rt--font-regular ' + (isMobile ? "rt--pl-8" : "rt--pr-8")}>
                            {t(`backoffice.languages.${lang}`)}
                        </span>
                        <Flag code={languageCodeToISO[lang.toLowerCase()]} />
                    </div>
                    {
                        lang === locale && isMobile && <Icon name="ok" />
                    }    
                </div>
            )
        }))
    }, [languages])

    return (
        <Dropdown
            className={ !isMobile ? "rt--languages" : ""}
            disabled={!supportMultipleLanguages}
            title={t("backoffice.languages.language")}
            menu={{
                className: "rt--languages-list",
                items: menuItems,
                onClick: handleClick
            }}
        >
            <div className={'rt--languages-element rt--flex rt--align-center rt--pl-12 rt--pr-12' + (!supportMultipleLanguages ? " rt--languages-element-disabled" : "")}>
                <Flag code={languageCodeToISO[locale.toLowerCase()]} />
            </div>
        </Dropdown>
    )
};


LanguagesDropdown.propTypes = {
    languages: PropTypes.object
};

export default memo(LanguagesDropdown);
