import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom"

import { LOGO_TYPE } from "core/constants/common/logo";

import useLogo from "core/hooks/useLogo";

import useDevice from "core/hooks/useDevice";

import Icon from "core/ui-kit/icon";
import Button from "core/ui-kit/button";


const Header = () => {

    const { logos } = useLogo()

    const { isMobile } = useDevice();

    const navigate = useNavigate();

    return (
        <div className='rt--guide-layout-header rt--flex rt--align-center rt--justify-between'>
            <div className="rt--flex rt--align-center">
                {
                        isMobile && (
                            <div
                                className='rt--header-menu-btn rt--flex rt--align-center rt--mr-8'
                                onClick={() => {
                                    document.body.classList.add("rt--body-open-sidebar")
                                }}
                            >
                                <Icon name='menu' />
                            </div>
                        )
                    }
                <div className='rt--guide-layout-header-logo'>
                    <img alt="logo" src={`${import.meta.env.SYSTEM_CDN_URL}/${logos[LOGO_TYPE.LOGO]}`} />
                </div>
            </div>

            <div className='rt--guide-layout-header-back'>
               <Button
                    className='rt--guide-layout-header-back-button'
                    onClick={() => navigate(-1)}
                >
                    Back to Admin
                </Button>
            </div>
        </div>
    )
}

export default Header;