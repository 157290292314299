import ROUTES from "core/constants/routes/routes";

import AffiliateGroupsComponent from "pages/affiliates/groups/groups.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const AffiliateGroupsPageRoute = {
	path: ROUTES.AFFILIATE_GROUPS,
	element: AffiliateGroupsComponent, 
    permission: { resource: PERMISSION_RESOURCE.AFFILIATE_GROUPS, action: PERMISSION_ACTION.VIEW }
};

export default AffiliateGroupsPageRoute;
