import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";

import Tabs from 'components/common/tabs';

import Personal from "./personal";
import Additional from "./additional";
import TrafficSource from "./trafficSource";


/** Affiliate Edit Page General Info Tab Component */
const GeneralInfoComponent = ({
    onTabChange
}) => {
    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.affiliates.personalTab"),
            component: <Personal/>
        },
        {
            title: t("backoffice.affiliates.additionalTab"),
            component: <Additional />,
        },
        {
            title: t("backoffice.affiliates.trafficSourceTab"),
            component: <TrafficSource />
        }
    ]

    return (
        <Tabs.SubTabs 
            items={items}
            onTabChange={onTabChange}
        />
    )
}

/** GeneralInfoComponent propTypes
    * PropTypes
*/
GeneralInfoComponent.propTypes = {
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}


export default GeneralInfoComponent;