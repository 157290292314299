import isArray from "core/helpers/typeChecks/isArray";

import { AUTHORIZED_LAYOUT_TYPE } from "./constants";

import isFunction from "core/helpers/typeChecks/isFunction";

const checkRoleAccess = (userRole, routeRole) => {
    if (!routeRole) {
        return true;
    }

    if (isArray(routeRole)) {
        return routeRole.includes(userRole);
    }

    return routeRole === userRole;
}

const checkPermissionAccess = (permissionUtils, routePermission) => {
    if(!routePermission) return true;
    if(isArray(routePermission)){
        return permissionUtils.some(...routePermission)
    }
    return permissionUtils.has(routePermission);
}

const checkProject = (projectId, isProjectRequired = false) => {
    if(!isProjectRequired) return true;

    return Boolean(projectId)
}

const checkDisabled = (isDisabled = null, projectId, user, userInfo) => {
    if(!isFunction(isDisabled)) return true;
    return !isDisabled(projectId, user, userInfo)
}

export const getAvailableRoutes = ({ 
    user, 
    permissionUtils, 
    projectId, 
    routes,
    userInfo
}) => {
    return Object.keys(routes).reduce((result, item) => {
        result[item] = routes[item].filter(route => {
            return (
                checkRoleAccess(user?.role, route.role) &&
                checkPermissionAccess(permissionUtils, route.permission) &&
                checkProject(projectId, route.isProjectRequired) &&
                checkDisabled(route.isDisabled, projectId, user, userInfo)
            );
        });
        
        return result;
    }, {})
}

export const findFirstAvailableRoute = routes => {
    return routes[AUTHORIZED_LAYOUT_TYPE.PORTAL].find(route => !route.path.includes("*") && !route.path.includes(":"))?.path ?? '';
}