import React from 'react';

import { debugStore, useDebugSelector } from '../../..';

import AttachToBottomIcon from '../../icons/attachToBottom';
import AttachToLeftIcon from '../../icons/attachToLeft';
import AttachToRightIcon from '../../icons/attachToRight';
import PopupIcon from '../../icons/popup';
import DebugAction from '../debugAction';

const DebugHeader = () => {
    const { selectedChange, placement } = useDebugSelector();

    const showPlacementActions = placement !== "popup";

    const handleClear = () => {
        debugStore.clearChanges();
    }

    const handleClose = () => {
        debugStore.toggleOpened();
    }

    const handleChangePlacement = (placement) => () => {
        debugStore.changePlacement(placement)
    }

    return (
        <div className="rt--debug-header">
            <div className="rt--debug-header-actions">
                <DebugAction onClick={handleClose}>
                    X
                </DebugAction>
                <div className="rt--debug-divider-vertical"></div>
                <DebugAction onClick={handleClear}>
                    Clear
                </DebugAction>
                {showPlacementActions && (
                    <>
                        <div className="rt--debug-divider-vertical"></div>
                        <DebugAction onClick={handleChangePlacement("left")}>
                            <AttachToLeftIcon size={24}/>
                        </DebugAction>
                        <DebugAction onClick={handleChangePlacement("bottom")}>
                            <AttachToBottomIcon size={24}/>
                        </DebugAction>
                        <DebugAction onClick={handleChangePlacement("right")}>
                            <AttachToRightIcon size={24}/>
                        </DebugAction>
                        <DebugAction onClick={handleChangePlacement("popup")}>
                            <PopupIcon size={24}/>
                        </DebugAction>
                    </>
                )}
            </div>

            {selectedChange && (
                <span>{selectedChange.name} {'>'} {selectedChange.path}</span>
            )}
        </div>
    );
};

export default DebugHeader;
