import ROUTES from "core/constants/routes/routes";

import GenerationsComponent from "pages/developer/generations/generations.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const GenerationsPageRoute = {
	path: ROUTES.DEVELOPER_GENERATIONS,
	element: GenerationsComponent, 
    permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW }
};

export default GenerationsPageRoute;
