import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import {
    SET_BALANCE_HISTORY_ACTION_BEFORE,
    SET_BALANCE_HISTORY_ACTION_FINISH,
    SET_BALANCE_HISTORY,
    SET_BALANCE_HISTORY_FILTERS,
    SET_BALANCE_HISTORY_SORTING
} from "../../../../actionTypes";
import { isMobile } from "utils/common";

export const setBalanceHistoryActionBefore = () => ({
    type: SET_BALANCE_HISTORY_ACTION_BEFORE,
});

export const setBalanceHistoryActionFinished = () => ({
    type: SET_BALANCE_HISTORY_ACTION_FINISH
});

export const setBalanceHistorySorting = sorting => ({
    type: SET_BALANCE_HISTORY_SORTING,
    payload: { sorting },
});

export const setBalanceHistoryFilters = filters => ({
    type: SET_BALANCE_HISTORY_FILTERS,
    payload: { filters },
});

const setBalanceHistory = (history, add) => ({
    type: SET_BALANCE_HISTORY,
    payload: { history, add },
});


export const getBalanceHistory = nextPage => {
    return (dispatch, getState) => {

        const balanceHistory = getState().balanceHistory;

        const filters = { ... balanceHistory.filters };
        const sorting = { ... balanceHistory.sorting };
        const total = balanceHistory.total;
        const page = nextPage === "first" ? 1 : nextPage === "next" ? sorting.page + 1 : sorting.page;
        
        let params = {
            ...sorting,
            ...filters,
            page: page
        };

        if(page > 1 && total <= (page - 1 ) * sorting.limit){
            return Promise.resolve();
        }
        
        dispatch(setBalanceHistoryActionBefore());
        return axios({
            url: ApiUrls.GET_WALLET_HISTORY,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: history } }) => {
                dispatch(setBalanceHistory(history, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setBalanceHistorySorting({ ...sorting, page: page }));
                dispatch(setBalanceHistoryActionFinished());
            })
            .catch((ex) => {
                dispatch(setBalanceHistoryActionFinished());
            });
    }
}
