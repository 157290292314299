import React from "react";

import Tooltip from "components/common/tooltip";

const Status = ({
    color,
    text,
    icon,
    tooltip
}) => {

    const renderContent = () => (
        <div
            className="rt--status"
            data-highlight={ color }
        >
            <div className="rt--flex rt--align-center">

                <span className="rt--status-content rt--title rt--font-small">
                    {
                        text
                    }
                </span>

                {
                    icon ?
                        tooltip ? (
                            <Tooltip
                                title={tooltip}
                                trigger={["hover", "click"]}
                                placement="top"
                                enableMobile={true}
                            >
                                { icon }
                            </Tooltip>
                        ) : icon
                        : null
                }
            </div>
        </div>
    )

    return (tooltip && !icon) ? (
        <Tooltip
            title={tooltip}
            trigger={["hover", "click"]}
            placement="top"
            enableMobile={true}
        >
            { renderContent() }
        </Tooltip>
    ) : renderContent()
};


export default Status;