//#region React
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";
import { useNavigate } from 'react-router-dom';
//#endregion

//#region Actions
import { getCommunicationTemplates } from "store/actions/portal/communications/systemNotifications/systemNotifications.action";
//#endregion

//#region Components
import Table from "components/common/table";
import MainDashboardLayout from "components/layouts/main";
//#endregion

//#region hooks
import useIncludedColumns from "hooks/useIncludedColumns";
//#endregion

//#region utils
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region Constants
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'core/constants/permission';
import { TEMPLATE_TRIGGER_NAMES_TT_KEYS } from "constants/systemNotifications.constants";
import { SYSTEM_NOTIFICATIONS } from "constants/pageName.constants";
import ROUTES from "core/constants/routes/routes";
import { getTableColumns } from "./columns";
//#endregion

//#region Types
import systemNotificationType from "types/systemNotifications/systemNotification.type";
//#endregion

import { globalProjectIdSelector, useAuthSelector } from "core/stores/authStore";
import usePermissions from "core/hooks/usePermission";

/** System Notifications Page Component */
const SystemNotifications = ({
	getCommunicationTemplates,
	templates,
	isLoading
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

    const permissionUtils = usePermissions();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: SYSTEM_NOTIFICATIONS });

	//#region ------------------------------------- PERMISSIONS ---------------------------------------//

	const hasViewPermission = permissionUtils.some(
		{ resource: PERMISSION_RESOURCE.COMMUNICATION_SYSTEM_TEMPLATES, action: PERMISSION_ACTION.VIEW },
		{ resource: PERMISSION_RESOURCE.COMMUNICATION_PROJECT_TEMPLATES, action: PERMISSION_ACTION.VIEW }
	);

	//#endregion

	//#region --------------------------------------- HANDLERS ----------------------------------------//

	const handleRightArrowIconClick = (template) => {
		navigate(`${ROUTES.SYSTEM_NOTIFICATIONS_EDIT}/${template.id}?triggerType=${template.triggerType}&name=${t(`backoffice.communication.${TEMPLATE_TRIGGER_NAMES_TT_KEYS[template.triggerType]}`)}`);
	};

	//#endregion

	//#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

	const { mainTableColumns, columnsThatCanBeIncluded } = useMemo(() => {
		return tableColumnsCreator({
			mainColumns: getTableColumns,
			constructForInclude: true,
			includedColumns,
            additionalProps: {
                t
            },
            t
		})
	}, [includedColumns, t]);

	//#endregion

	//#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

	const tableRowActions = [
		{
			title: t('backoffice.common.deepView'),
			icon: "right",
			disabled: () => !hasViewPermission,
			onClick: handleRightArrowIconClick
		}
	];

	//#endregion

	//#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

	const headerPartsData = {
		columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
		breadcrumbs: {
			items: [
				{
					title: t('backoffice.menu.systemNotifications')
				}
			],
		}
	};

	//#endregion

	return (
		<MainDashboardLayout
			header={headerPartsData}
		>
			<Table
				loading={isLoading}
				disableFullView={true}
				columns={mainTableColumns}
				data={templates}
				loadFn={getCommunicationTemplates}
				noPagination={true}
				updateProps={[globalProjectId]}
				actions={tableRowActions}
			/>
		</MainDashboardLayout>
	);
};

SystemNotifications.propTypes = {
	/** Redux action to get System Payments */
	getCommunicationTemplates: PropTypes.func,
	/** Redux state property, represents the array of System Payments  */
	templates: PropTypes.arrayOf(systemNotificationType),
	/** Redux state property, is true when loading System Payments */
	isLoading: PropTypes.bool,
	/** Redux state property, represents global company id */
	globalCompanyId: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
	getCommunicationTemplates: () => {
		dispatch(getCommunicationTemplates());
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.systemNotifications.isLoading,
		templates: state.systemNotifications.templates
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SystemNotifications);