export const getStackTrace = () => {
    const trace = new Error().stack;
    const segments = (
        trace
            .replaceAll(" at ", "")
            .split("\n")
            .slice(2)
            .map(segment => segment.trim())
            .reduce((acc, segment) => {
                const match = segment.match(/(.*\s)\((.*):(\d+):(\d+)\)/);

                if (!match) {
                    return acc;
                }

                acc.push({
                    func: match[1],
                    file: match[2],
                    line: match[3],
                    column: match[4]
                });

                return acc;
            }, [])
    );

    return segments;
}
