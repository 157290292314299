import ROUTES from "core/constants/routes/routes";

import CampaignsComponent from "pages/marketingTools/campaigns/campaigns.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const CampaignsPageRoute = {
	path: ROUTES.CAMPAIGNS,
	element: CampaignsComponent, 
    permission: { resource: PERMISSION_RESOURCE.MARKETING_TOOLS_CAMPAIGN, action: PERMISSION_ACTION.VIEW }
};

export default CampaignsPageRoute;
