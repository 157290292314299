export const COMMISSION_STRUCTURE = {
    FLAT: 1,
    PLAYER_TIERED: 2,
    PROGRESSIVE: 4,
    PLAYER_LIFETIME: 8
}

export const COMMISSION_STRUCTURE_TRANSLATIONS = {
    [COMMISSION_STRUCTURE.FLAT]: 'backoffice.commissionplans.flat',
    [COMMISSION_STRUCTURE.PLAYER_TIERED]: 'backoffice.commissionplans.playerTiered',
    [COMMISSION_STRUCTURE.PROGRESSIVE]: 'backoffice.commissionplans.progressive',
    [COMMISSION_STRUCTURE.PLAYER_LIFETIME]: 'backoffice.commissionplans.playerLifetime'
};