import React from "react";

import { Switch as AntSwitch } from "antd";

const Switch = props => {
    return (
        <AntSwitch 
            { ...props }
        />
    ) 
}

export default Switch;