import React, { useState, forwardRef } from 'react';

import { Input as AntInput } from 'antd';

const PREFIX = "https://";

const InputUrl = forwardRef(({
    ...rest
}, ref) => {

    const [internalValue, setInternalValue] = useState("");

    const resultValue = Object.hasOwn(rest, "value") ? rest.value ?? "" : internalValue;
    const resultChange = Object.hasOwn(rest, "onChange") ? rest.onChange : setInternalValue;

    const inputValue = resultValue.replace(PREFIX, "")

    const handleInternalChange = e => {
        const newValue = e.target.value;
        const wholeValue = (
            Boolean(newValue)
                ? `${PREFIX}${newValue}`
                : ""
        );
        resultChange?.(wholeValue)
    }

    return (
        <AntInput
            ref={ref}
            {...rest}
            value={inputValue}
            onChange={handleInternalChange}
            addonBefore={PREFIX}
        />
    )
})

export default InputUrl;

