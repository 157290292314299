import React, { Fragment } from "react";

import PropTypes from "prop-types";

import Icon from "core/ui-kit/icon";
import Dropdown from "core/ui-kit/dropdown";
import Input from "core/ui-kit/input";

import { useTranslation } from "core/hooks/useTranslation";
import useDevice from "core/hooks/useDevice";

import { companiesDropdownMenu } from "../../helpers";

import { INPUT_TYPE } from "core/constants/component/input";

const CompaniesDropdownMobile = ({
	opened,
	setOpened,
	companySearch,
	onSearchChange,
	filteredCompanies,
	preselectedCompany,
	onChange,
	onChangeToProject,
	selectedProject,
	onMenuChange,
	onOpenChange,
    disabled
}) => {
	const { t } = useTranslation();

    const { isMobile } = useDevice();

	const menuItems = companiesDropdownMenu({
		companies: filteredCompanies,
		companyId: preselectedCompany,
		t,
        isMobile
	});

	const footer = {
		okText: t("backoffice.common.next"),
		onOk: () => {
			onChange({ key: preselectedCompany });
			onChangeToProject();
		},
		cancelText: t("backoffice.common.cancel"),
		onCancel: () => {
			onChangeToProject();
			setOpened(false);
		},
	};

	return (
		<Dropdown
			popupOpened={opened}
			forceRender
			menu={{
                className: "rt--companies-dropdown-menu",
				items: menuItems,
				onClick: onMenuChange,
                selectedKeys: [preselectedCompany]
			}}
			footer={footer}
			onOpenChange={onOpenChange}
			dropdownRender={(menu) => (
				<Fragment>
					<div className="rt--companies-dropdown-search rt--pl-8 rt--pr-8 rt--pt-8 rt--pb-8">
						<Input 
                            type={INPUT_TYPE.SEARCH}
                            placeholder={t("backoffice.common.search")}
                            value={companySearch} 
                            onChange={onSearchChange} 
                        />
					</div>
					{menu}
				</Fragment>
			)}
			title={`${t("backoffice.common.choose")} ${t("backoffice.companies.company")}`}
            disabled={disabled}
		>
			<div className="rt--companies-dropdown-content rt--flex rt--align-center rt--justify-between rt--pl-12 rt--pr-4">
				<span className="rt--title rt--font-normal rt--font-bold rt--pr-4">
					{selectedProject?.name ?? ""}
				</span>
                {
                    !disabled && <Icon name="down-small" className="rt--font-bigest" />
                }
				
			</div>
		</Dropdown>
	);
};

CompaniesDropdownMobile.propTypes = {
	filteredCompanies: PropTypes.array,
	preselectedCompany: PropTypes.string,
	onChange: PropTypes.func,
	onChangeToProject: PropTypes.func,
	selectedProject: PropTypes.object,
	companySearch: PropTypes.string,
	onSearchChange: PropTypes.func,
	setOpened: PropTypes.func,
	opened: PropTypes.bool,
	onMenuChange: PropTypes.func,
	onOpenChange: PropTypes.func,
};

export default CompaniesDropdownMobile;
