import ROUTES from "core/constants/routes/routes";

import BalanceHistoryComponent from "pages/payment/balanceHistory/balanceHistory-component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const BalanceHistoryPageRoute = {
	path: ROUTES.BALANCE_HISTORY,
	element: BalanceHistoryComponent, 
    permission: { resource: PERMISSION_RESOURCE.PAYMENTS_WALLETS_HISTORY, action: PERMISSION_ACTION.VIEW }
};

export default BalanceHistoryPageRoute;
