import React, { useRef, useCallback, useState } from 'react';

import { useTranslation } from "core/hooks/useTranslation";

import { Tabs as AntTabs } from 'antd';

import TabDashboardLayout from "components/layouts/tab";

import General from "./tabs/general";
import Earning from './tabs/earning';
import TopAffiliates from "./tabs/topAffiliates";
import TopPlayers from './tabs/topPlayers';
import LastUpdated from '../components/lastUpdated';

import { getHashValue, isMobile, updateLocationHash } from 'utils/common';

import { FINANCIAL_TAB_NAMES } from '../constants';
import { USER_ROLE } from 'constants/user.constants';
import { AFFILIATE_ACCESS_TYPE } from 'constants/affiliate.constants';

import useDate from 'hooks/useDate';
import useAccess from 'hooks/useAccess';

import { useAuthSelector, userRoleSelector } from 'core/stores/authStore';

const getActiveTab = () => {
    const tabKey = getHashValue("tab");

    return tabKey ? tabKey : FINANCIAL_TAB_NAMES.GENERAL
}

const FinancialDashboard = () => {
    const { t } = useTranslation();

    const userRole = useAuthSelector(userRoleSelector);

    const { hasAccess } = useAccess();

    const { dateService } = useDate();

    const [lastUpdatedDate, setLastUpdatedDate] = useState(null);

    const activeTabRef = useRef(getActiveTab());

    const handleTabChange = (tab) => {
        updateLocationHash("tab=" + tab);
    }

    const updateLastUpdatedDate = useCallback((newDate) => {
        setLastUpdatedDate((prevState) => {
            if (lastUpdatedDate === null || dateService.isAfter(newDate, lastUpdatedDate)) {
                return newDate;
            }

            return prevState;
        })
    }, [])


    return (
        <TabDashboardLayout
            header={
                isMobile() ? { extra: <LastUpdated lastUpdatedDate={lastUpdatedDate} hasFilter={true} /> } : undefined
            }
            breadcrumbs={{
                items: [{ title: t('backoffice.menu.financial') }]
            }}
        >
            <AntTabs
                animated={false}
                defaultActiveKey={activeTabRef.current}
                destroyInactiveTabPane={true}
                onChange={ handleTabChange }
                className='rt--tabs rt--chart-tab'
                tabBarExtraContent={
                    !isMobile() ? <LastUpdated lastUpdatedDate={lastUpdatedDate} /> : undefined
                }
            >
                <AntTabs.TabPane
                    tab={<span>{t("backoffice.dashboard.general")}</span>}
                    key={FINANCIAL_TAB_NAMES.GENERAL}
                >
                    <General updateLastUpdatedDate={updateLastUpdatedDate} />
                </AntTabs.TabPane>
                <AntTabs.TabPane
                    tab={<span>{t("backoffice.dashboard.earning")}</span>}
                    key={FINANCIAL_TAB_NAMES.EARNING}
                >
                    <Earning updateLastUpdatedDate={updateLastUpdatedDate} />
                </AntTabs.TabPane>
                {
                    userRole !== USER_ROLE.AFFILIATE && (
                        <AntTabs.TabPane
                            tab={<span>{t("backoffice.dashboard.topAffiliates")}</span>}
                            key={FINANCIAL_TAB_NAMES.TOP_AFFILIATES}
                        >
                            <TopAffiliates updateLastUpdatedDate={updateLastUpdatedDate} />
                        </AntTabs.TabPane>
                    )
                }
                {
                    [
                        AFFILIATE_ACCESS_TYPE.NGR,
                        AFFILIATE_ACCESS_TYPE.GGR,
                        AFFILIATE_ACCESS_TYPE.BET_AMOUNT,
                        AFFILIATE_ACCESS_TYPE.DEPOSIT_AMOUNT
                    ].some(access => hasAccess(access)) && (
                        <AntTabs.TabPane
                            tab={<span>{t("backoffice.dashboard.topPlayers")}</span>}
                            key={FINANCIAL_TAB_NAMES.TOP_PLAYERS}
                        >
                            <TopPlayers updateLastUpdatedDate={updateLastUpdatedDate} />
                        </AntTabs.TabPane>
                    )
                }
            </AntTabs>
        </TabDashboardLayout>
    )
}

export default FinancialDashboard;