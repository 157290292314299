import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";

import { ORDER_DIRECTION } from "constants/common.constants";

import {
    SET_SESSIONS_ACTION_BEFORE,
    SET_SESSIONS_ACTION_FINISH,
    SET_SESSIONS,
    SET_SESSIONS_SORTING,
    SET_SESSIONS_FILTERS,
    SET_SESSIONS_SAVE_ACTION_BEFORE,
    SET_SESSIONS_SAVE_ACTION_FINISH
} from "../../../actionTypes";

export const setSessionsActionBefore = () => ({
    type: SET_SESSIONS_ACTION_BEFORE,
});

export const setSessionsActionFinished = () => ({
    type: SET_SESSIONS_ACTION_FINISH,
});

export const setSessionsSaveActionBefore = () => ({
    type: SET_SESSIONS_SAVE_ACTION_BEFORE,
});

export const setSessionsSaveActionFinished = () => ({
    type: SET_SESSIONS_SAVE_ACTION_FINISH,
});

const setSessions = (sessions, add) => ({
    type: SET_SESSIONS,
    payload: { sessions, add },
});

export const setSessionsSorting = sorting => ({
    type: SET_SESSIONS_SORTING,
    payload: { sorting },
});

export const setSessionsFilters = (filters, keepPage) => ({
    type: SET_SESSIONS_FILTERS,
    payload: { filters, keepPage },
});


export const getSessions = (userRole, userId, nextPage) => {
    return (dispatch, getState) => {
        
        const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().sessions.sorting.page + 1 : getState().sessions.sorting.page;
        
        let params = {
            ...getState().sessions.sorting,
            ...getState().sessions.filters,
            page: page
        };

        if(page > 1 && getState().sessions.total <= (page - 1 ) * getState().sessions.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && getState().sessions.total === 0){
            return Promise.resolve();
        }

        params.userRole = userRole;
        if(userId){
            params.userNameOrId = userId;
        }
        
        dispatch(setSessionsActionBefore());

        return axios({
            url: ApiUrls.GET_SESSIONS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: sessions } }) => {
                dispatch(setSessions(sessions, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setSessionsSorting({ ...getState().sessions.sorting, page: page }));
                dispatch(setSessionsActionFinished());
            })
            .catch((ex) => {
                dispatch(setSessionsActionFinished());
            });
    }
}

export const resetSessions = () => {
    return dispatch => {
        dispatch(setSessions({ item1: 0, item2: [] }, false));
        dispatch(setSessionsSorting({ 
            page: 1,
			limit: 10,
			orderBy: "startTime",
			orderDirection: ORDER_DIRECTION.DESC
        }));
        dispatch(setSessionsFilters({ 
            userNameOrId: "",
            from: "",
			to: ""
        }));
    }
}
