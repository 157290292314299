import ROUTES from "core/constants/routes/routes";

import CompaniesComponent from "pages/companies/companies.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const CompaniesPageRoute = {
	path: ROUTES.COMPANIES,
	element: CompaniesComponent, 
    permission: { resource: PERMISSION_RESOURCE.COMPANY, action: PERMISSION_ACTION.VIEW }
};

export default CompaniesPageRoute;
