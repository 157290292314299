export const isValidValue = (value) => {
	if (value === "") {
		return true;
	}

	if (value === "undefined") {
		return true;
	}

	try {
		JSON.parse(value);
		return true;
	} catch (e) {
		return false
	}
}
