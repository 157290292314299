import ROUTES from "core/constants/routes/routes";

import Operational from "pages/dashboard/operational";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const OperationalRoute = {
	path: ROUTES.DASHBOARD_OPERATIONAL,
	element: Operational, 
    permission: { resource: PERMISSION_RESOURCE.DASHBOARD_OPERATIONAL, action: PERMISSION_ACTION.VIEW }
};

export default OperationalRoute;
