import axios from "core/lib/axios";
import { message } from "antd";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";
import { isResponseSuccess } from "utils/request";

import { 
    setNewsletterActionBefore, 
    setNewsletterActionFinished,
    setNewsletterSaveActionBefore,
    setNewsletterSaveActionFinished 
} from "./newsletter.action";

import {
    SET_NEWSLETTERS,
    SET_NEWSLETTERS_SORTING,
    SET_NEWSLETTERS_FILTERS,
    SET_NEWSLETTER_SEND_TEST_ACTION_FINISH,
    SET_NEWSLETTER_SEND_TEST_ACTION_BEFORE
} from "../../../../actionTypes";

const setNewsletters = (newsletters, add) => ({
    type: SET_NEWSLETTERS,
    payload: { newsletters, add },
});

export const setNewslettersSorting = sorting => ({
    type: SET_NEWSLETTERS_SORTING,
    payload: { sorting },
});

export const setNewslettersFilters = filters => ({
    type: SET_NEWSLETTERS_FILTERS,
    payload: { filters },
});



export const getNewsletters = nextPage => {
    return (dispatch, getState) => {

        const newsletters = getState().newsletter.newsletters;

        const filters = { ... newsletters.filters };
        const sorting = { ... newsletters.sorting };
        const total = newsletters.total;
        const page = nextPage === "first" ? 1 : nextPage === "next" ? sorting.page + 1 : sorting.page;
        
        let params = {
            ...sorting,
            ...filters,
            page: page
        };

        if(page > 1 && total <= (page - 1 ) * sorting.limit){
            return Promise.resolve();
        }
        
        dispatch(setNewsletterActionBefore());
        return axios({
            url: ApiUrls.GET_NEWSLETTERS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: newsletters } }) => {
                dispatch(setNewsletters(newsletters, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setNewslettersSorting({ ...sorting, page: page }));
                dispatch(setNewsletterActionFinished());
            })
            .catch((ex) => {
                dispatch(setNewsletterActionFinished());
            });
    }
}

export const createNewsletter = (newsletter, onSuccess) => {
    return (dispatch) => {
        dispatch(setNewsletterSaveActionBefore());
        return axios({
            url: ApiUrls.CREATE_NEWSLETTER,
            method: Methods.POST,
            data: {
                ...newsletter
            },
        })
            .then(({ data }) => {
                dispatch(setNewsletterSaveActionFinished());
                if (isResponseSuccess(data)) {
                    dispatch(getNewsletters());
                    onSuccess && onSuccess(data.value);
                }
            })
            .catch(() => {
                dispatch(setNewsletterSaveActionFinished());
            });
    };
};

export const deleteNewsletter = id => {
    return (dispatch) => {
        dispatch(setNewsletterSaveActionBefore());
        return axios({
            url: ApiUrls.REMOVE_NEWSLETTER,
            method: Methods.DELETE,
            data: {
                id
            },
        })
            .then(({ data }) => {
                dispatch(setNewsletterSaveActionFinished());
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    dispatch(getNewsletters());
                }
            })
            .catch(() => {
                dispatch(setNewsletterSaveActionFinished());
            });
    };
};


/** Send Test */

const setNewsletterSendTestActionBefore = () => ({
    type: SET_NEWSLETTER_SEND_TEST_ACTION_BEFORE,
});

const setNewsletterSendTestActionFinished = () => ({
    type: SET_NEWSLETTER_SEND_TEST_ACTION_FINISH
});

export const sendTestNewsletter = newsletterData => {
    return (dispatch) => {
        dispatch(setNewsletterSendTestActionBefore());
        return axios({
            url: ApiUrls.SEND_TEST_NEWSLETTER,
            method: Methods.POST,
            data: {
                ...newsletterData
            },
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                }
                dispatch(setNewsletterSendTestActionFinished());
            })
            .catch(() => {
                dispatch(setNewsletterSendTestActionFinished());
            });
    };
};
