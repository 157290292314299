import React from 'react';

const DebugAction = ({children, className, ...props}) => {
    return (
        <button className={["rt--debug-action", className].join(" ")} {...props}>
            {children}
        </button>
    );
};

export default DebugAction;
