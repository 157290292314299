import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";

import { Form, Col, Row } from "antd";

import Modal from "components/common/modal";
import Input from "components/common/input";

import { addAdditionalParam } from "store/actions/portal/projects/additionalParams.action";

import { POPUP_SIZE } from "constants/common.constants";
import { NAME_REGEX } from "constants/regex.constants";

/** Add Additional Param Popup Component */
const AddAdditionalParamComponent = ({
    addAdditionalParam,
    isSaving,
    onClose,
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    const [isButtonDisabled, setIsButtonDisabled] = useState(true)

    const handleForm = () => {
        validateFields()
            .then((data) => {
                addAdditionalParam( data.key, data.value, onClose);
            })
            .catch(() => {});
    };

    return (
        <Modal
            title={t("backoffice.projects.addAdditionalParam")}
            cancelText={t("backoffice.common.cancel")}
            okText={t("backoffice.common.add")}
            onOk={handleForm}
            disableOkButton={isSaving || isButtonDisabled}
            onCancel={onClose}
            size={POPUP_SIZE.SMALL}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                onValuesChange={(_, formValues) => setIsButtonDisabled(!formValues["key"] || !formValues["value"])}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t("backoffice.projects.key")} *`}
                            name="key"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            validateFirst
                            className="rt--general-form-item"
                            data-placeholder={`${t("backoffice.common.enter")} ${t("backoffice.projects.key")}`}
                        >
                            <Input
                                placeholder={`${t("backoffice.common.enter")} ${t("backoffice.projects.key")}`}
                                maxLength={30}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={`${t("backoffice.projects.value")} *`}
                            name="value"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            validateFirst
                            className="rt--general-form-item rt--form-item-without-margin"
                            data-placeholder={`${t("backoffice.common.enter")} ${t("backoffice.projects.value")}`}
                        >
                            <Input
                                placeholder={`${t("backoffice.common.enter")} ${t("backoffice.projects.value")}`}
                                maxLength={30}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

AddAdditionalParamComponent.propTypes = {
    /** Redux action to add additional param to the Project */
    addAdditionalParam: PropTypes.func,
    /** Redux ste property, is true when save request is in process */
    isSaving: PropTypes.bool,
    /** Fires on popup close */
    onClose: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    addAdditionalParam: (key, value, onSuccess) => {
        dispatch(addAdditionalParam(key, value, onSuccess));
    },
});

const mapStateToProps = (state) => {
    return {
        isSaving: state.projects.isSaving,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddAdditionalParamComponent);
