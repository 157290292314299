import React from 'react';

import Checkbox from 'core/ui-kit/checkbox';

const AccessCheckbox = ({
    title="",
    description="",
    value,
    disabled=false
}) => {

    return (
        <div className="rt--access-checkbox rt--pl-24 rt--pr-24 rt--pt-16 rt--pb-16 rt--mb-16">
            <div className="rt--flex">
                <div className="rt--flex-equal rt--flex rt--flex-col rt--access-checkbox-content">
                    <b className="rt--title rt--pb-6 rt--font-bold rt--font-big">{title}</b>
                    <span className="rt--title rt--pb-6 rt--font-regular rt--font-small" title={description}>{description}</span>
                </div>
                <Checkbox
                    value={value}
                    disabled={disabled}
                />
            </div>
        </div>
    )
}

export default AccessCheckbox;