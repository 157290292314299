import ROUTES from "core/constants/routes/routes";

import CommissionCalculationsComponent from "pages/reports/commissionCalculations/commissionCalculations.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const CommissionCalculationsPageRoute = {
	path: ROUTES.COMMISSION_CALCULATIONS,
	element: CommissionCalculationsComponent, 
    permission: { resource: PERMISSION_RESOURCE.PAYMENTS_COMMISSION_CALCULATIONS, action: PERMISSION_ACTION.VIEW }
};

export default CommissionCalculationsPageRoute;
