import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";

import { Collapse, Form, Checkbox } from "antd";

import Table from 'components/common/table';
import Tooltip from 'components/common/tooltip';
import Icon from 'core/ui-kit/icon';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "core/constants/permission";

import permissionType from 'types/permission/permission.type';

import { toLowerCaseFirstLetter, isMobile } from "utils/common";

const mapping = {
    [PERMISSION_RESOURCE.DASHBOARD]: {
        icon: "dashboard",
        order: 0
    },
    [PERMISSION_RESOURCE.COMPANY]: {
        icon: "companies",
        order: 1
    },
    [PERMISSION_RESOURCE.PROJECT]: {
        icon: "globe",
        order: 2
    },
    [PERMISSION_RESOURCE.AFFILIATE]: {
        icon: "affiliate",
        order: 3
    },
    [PERMISSION_RESOURCE.REPORT]: {
        icon: "report",
        order: 4
    },
    [PERMISSION_RESOURCE.PLAYER]: {
        icon: "player",
        order: 5
    },
    [PERMISSION_RESOURCE.MARKETING_TOOLS]: {
        icon: "mediatools",
        order: 6
    },
    [PERMISSION_RESOURCE.PAYMENTS]: {
        icon: "payments",
        order: 7
    },
    [PERMISSION_RESOURCE.ADMIN]: {
        icon: "users",
        order: 8
    },
    [PERMISSION_RESOURCE.AFFILIATE_MANAGER]: {
        icon: "users",
        order: 9
    },
    [PERMISSION_RESOURCE.ACCESS_MANAGER]: {
        icon: "users",
        order: 10
    },
    [PERMISSION_RESOURCE.PERMISSION]: {
        icon: "permission",
        order: 11
    },
    [PERMISSION_RESOURCE.COMMUNICATION]: {
        icon: "communication",
        order: 12
    },
    [PERMISSION_RESOURCE.USER_LOGS]: {
        icon: "userlogs",
        order: 13
    },
    [PERMISSION_RESOURCE.TRANSLATION]: {
        icon: "translations",
        order: 14
    },
    [PERMISSION_RESOURCE.SYSTEM_CURRENCIES]: {
        icon: "currency",
        order: 15
    },
    [PERMISSION_RESOURCE.SYSTEM_LANGUAGES]: {
        icon: "languages",
        order: 16
    },
    [PERMISSION_RESOURCE.SYSTEM_PAYMENT_METHODS]: {
        icon: "settings",
        order: 17
    },
    [PERMISSION_RESOURCE.DEVELOPER]: {
        icon: "code",
        order: 18
    },

    [PERMISSION_RESOURCE.POSTBACK]: {
        icon: "postback",
        order: 19
    },
}

/** Permissions List Component */

const PermissionsComponent = forwardRef(({
    permissions,
    isLoading,
    formInstance,
    initialFormValues,
    editable,
    disabled,
    onCheckAll,
    onChange,
    fieldName
}, ref) => {

    const { t } = useTranslation();

    const [formValues, setFormValues] = useState(initialFormValues);

    useEffect(() => {
        onChange  && onChange(isCheckedAll())
    }, [formValues])

    /** Open all by default */
    useEffect(() => {
        setFormValues(initialFormValues);
    }, [initialFormValues])

    /** Function to render table cell for permission
       * @param {object} permission
       * @param {string} action
       * @param {string} parent - parent resource
       * @function
       * @memberOf PermissionsComponent
   */
    const renderCell = (permission, action, parent) => {

        const actionData = permission.actions.find(a => a.action === action);

        return (
            <div className='rt--flex rt--align-center'>
                <div className="rt--permissions-table-content rt--flex rt--align-center">
                    {
                        (actionData && editable) && (
                            <div className="rt--flex-inline rt--align-center rt--form-item-checkbox rt--mr-8">
                                <Form.Item
                                    name={permission.isParent ? (fieldName ? [fieldName] : []).concat([permission.resource, "parent", action]) : (fieldName ? [fieldName] : []).concat([parent?.resource ?? null, permission.resource, action])}
                                    valuePropName="checked"
                                    className="rt--form-item-without-margin rt--permissions-table-checkbox"
                                >
                                    <Checkbox
                                        onChange={e => handleChange(permission.resource, !permission.isParent ? parent?.resource ?? null : null, action, e.target.checked)}
                                        disabled={
                                            !actionData.canEdit ||
                                            isFieldReadonly(permission.resource, !permission.isParent ? parent?.resource ?? null : null, action)
                                        }
                                    />
                                </Form.Item>
                            </div>
                        )
                    }

                    <Tooltip
                        title={actionData ? makeDescription(permission.resource, action) : ""}
                        enableMobile={true}
                    >
                        <span className="rt--permissions-table-description">{actionData ? makeDescription(permission.resource, action) : ""}</span>
                    </Tooltip>
                </div>
                {
                    renderPermissionLevel(actionData)
                }

            </div>
        )
    }

    /** Checks if the permissions contains any subpermission which supports the action
       * @param {string} resource
       * @param {string} action
       * @function
       * @returns {boolean}
       * @memberOf PermissionsComponent
   */
    const isResourceHasAction = (resource, action) => {
        const permission = permissions.find(p => p.resource === resource);
        if (!permission) return false;
        return permission.actions.some(a => a.action === action && a.canEdit) || permission.subPermissions.some(p => p.actions.some(a => a.action === action && a.canEdit));
    }
    

    /** Function to render table cell for permission
       * @param {string} resource
       * @param {string} action
       * @function
       * @memberOf PermissionsComponent
   */
    const renderHeaderCell = (resource, action) => {
        return (
            <div className='rt--flex rt--align-center rt--justify-between'>
                <div className="rt--permissions-table-content rt--flex rt--align-center rt--justify-between">
                    {
                        (editable && !disabled && isResourceHasAction(resource, action)) &&
                        (
                            <div className="rt--flex-inline rt--align-center rt--form-item-checkbox rt--mr-8">
                                <Form.Item
                                    className="rt--form-item-without-margin rt--permissions-table-checkbox"
                                >
                                    <Tooltip
                                        title={isCheckedAll(resource, action) ? t("backoffice.permissions.deSelectAll") : t("backoffice.permissions.selectAll")}
                                    >
                                        <Checkbox
                                            checked={isCheckedAll(resource, action)}
                                            indeterminate={!isUnCheckedAll(resource, action) && !isCheckedAll(resource, action)}
                                            onClick={e => !isCheckedAll(resource, action) ? checkAll(resource, action, e) : unCheckAll(resource, action, e)}
                                        />
                                    </Tooltip>
                                </Form.Item>
                            </div>
                        )
                    }
                    <span>{t(`backoffice.permissions.${action}`)}</span>
                </div>
            </div>
        )
    }

    /** Function to render securit level
       * @param {object} permission
       * @function
       * @memberOf PermissionsComponent
   */
    const renderPermissionLevel = permission => {
        const securityLevel = permission?.securityLevel ?? null

        return securityLevel ? (
            <div className="rt--permissions-table-level rt--ml-4 rt--flex rt--align-center rt--justify-center">
                <img
                    src={`${import.meta.env.SYSTEM_CDN_URL}/system/static/images/level_${securityLevel}.svg`}
                    alt="Level"
                />
            </div>
        ) : <div className="rt--permissions-table-level rt--ml-4 rt--flex rt--align-center rt--justify-center" />;
    }

    /** Function to make name text
       * @param {string} resource
       * @returns {string}
       * @function
       * @memberOf PermissionsComponent
   */
    const makeName = resource => {
        let res = resource;

        return t(`backoffice.permissions.${res}`)
    }

    /** Function to make description text
       * @param {string} resource
       * @param {string} action
       * @returns {string}
       * @function
       * @memberOf PermissionsComponent
   */
    const makeDescription = (resource, action) => {
        let res = resource;

        return t(`backoffice.permissions.${res}_${action}`)
    }

    /** Function to render mobile row
       * @param {object} permission
       * @param {string} action
       * @param {string} parent - parent resource
       * @function
       * @memberOf PermissionsComponent
   */
    const renderMobileRow = (permission, action, parent) => {
        const actionData = permission.actions.find(a => a.action === action);

        return (
            <div className="rt--permissions-table-content rt--flex rt--align-center">
                <div className='rt--flex rt--flex-col rt--justify-center rt--align-start'>
                    <div className='rt--flex rt--align-center rt--justify-start'>
                        <span className='rt--title rt--font-normal rt--font-regular rt--permissions-table-content-action-title rt--mr-2'>{t(`backoffice.permissions.${action}`)}</span>
                        {
                            renderPermissionLevel(actionData)
                        }
                    </div>
                    <Tooltip
                        title={actionData ? makeDescription(permission.resource, action) : ""}
                        enableMobile={true}
                    >
                        <span className="rt--title rt--font-normal rt--font-regular rt--permissions-table-description rt--pt-2">{actionData ? makeDescription(permission.resource, action) : ""}</span>
                    </Tooltip>
                </div>
                {
                    (actionData && editable) && (
                        <div className="rt--flex rt--align-center rt--form-item-checkbox rt--flex-equal rt--justify-end">
                            <Form.Item
                                name={permission.isParent ? (fieldName ? [fieldName] : []).concat([permission.resource, "parent", action]) : (fieldName ? [fieldName] : []).concat([parent?.resource ?? null, permission.resource, action])}
                                valuePropName="checked"
                                className="rt--form-item-without-margin rt--permissions-table-checkbox"
                            >
                                <Checkbox
                                    onChange={e => handleChange(permission.resource, !permission.isParent ? (parent?.resource ?? null) : null, action, e.target.checked)}
                                    disabled={
                                        !actionData.canEdit ||
                                        isFieldReadonly(permission.resource, !permission.isParent ? (parent?.resource ?? null) : null, action)
                                    }
                                />
                            </Form.Item>
                        </div>
                    )
                }

            </div>
        )

    }

    /** Function to render table for permissions
       * @param {object} permission
       * @function
       * @memberOf PermissionsComponent
   */
    const renderTable = permission => {
        let tableData = [];
        tableData.push({ ...permission, isParent: true })
        tableData = tableData.concat(permission.subPermissions)

        return (
            <div className="rt--permissions-table" key={permission.resource}>
                <Table
                    loading={isLoading}
                    columns={[
                        {
                            title: "",
                            dataIndex: 'name',
                            render: (value, record) =>
                                <Tooltip
                                    title={makeName(record.resource)}
                                >
                                    <span className="rt--permissions-table-description">{makeName(record.resource)}</span>
                                </Tooltip>,
                            mobileLevel: 1,
                            showOnlyValue: true
                        },
                        {
                            title: renderHeaderCell(permission.resource, PERMISSION_ACTION.VIEW),
                            dataIndex: 'view',
                            render: (value, record) => renderCell(record, PERMISSION_ACTION.VIEW, tableData[0]),
                            mobileLevel: 2,
                            showOnlyValue: true,
                            mobileRender: (value, record) => renderMobileRow(record, PERMISSION_ACTION.VIEW, tableData[0]),
                            isHiddenForMobile: (record) => !record.actions.some(a => a.action === PERMISSION_ACTION.VIEW),
                        },
                        {
                            title: renderHeaderCell(permission.resource, PERMISSION_ACTION.CREATE),
                            dataIndex: 'create',
                            render: (value, record) => renderCell(record, PERMISSION_ACTION.CREATE, tableData[0]),
                            mobileLevel: 3,
                            showOnlyValue: true,
                            mobileRender: (value, record) => renderMobileRow(record, PERMISSION_ACTION.CREATE, tableData[0]),
                            isHiddenForMobile: (record) => !record.actions.some(a => a.action === PERMISSION_ACTION.CREATE),
                        },
                        {
                            title: renderHeaderCell(permission.resource, PERMISSION_ACTION.MODIFY),
                            dataIndex: 'modify',
                            render: (value, record) => renderCell(record, PERMISSION_ACTION.MODIFY, tableData[0]),
                            mobileLevel: 4,
                            showOnlyValue: true,
                            mobileRender: (value, record) => renderMobileRow(record, PERMISSION_ACTION.MODIFY, tableData[0]),
                            isHiddenForMobile: (record) => !record.actions.some(a => a.action === PERMISSION_ACTION.MODIFY),
                        },
                        {
                            title: renderHeaderCell(permission.resource, PERMISSION_ACTION.DELETE),
                            dataIndex: 'delete',
                            render: (value, record) => renderCell(record, PERMISSION_ACTION.DELETE, tableData[0]),
                            mobileLevel: 5,
                            showOnlyValue: true,
                            mobileRender: (value, record) => renderMobileRow(record, PERMISSION_ACTION.DELETE, tableData[0]),
                            isHiddenForMobile: (record) => !record.actions.some(a => a.action === PERMISSION_ACTION.DELETE),
                        },
                        {
                            title: renderHeaderCell(permission.resource, PERMISSION_ACTION.EXPORT),
                            dataIndex: 'export',
                            render: (value, record) => renderCell(record, PERMISSION_ACTION.EXPORT, tableData[0]),
                            mobileLevel: 6,
                            showOnlyValue: true,
                            mobileRender: (value, record) => renderMobileRow(record, PERMISSION_ACTION.EXPORT, tableData[0]),
                            isHiddenForMobile: (record) => !record.actions.some(a => a.action === PERMISSION_ACTION.EXPORT),
                        },
                    ]}
                    data={tableData}
                    total={tableData.length}
                    actions={[]}
                    isDisabled={() => false}
                    noPagination={true}
                    uniqueKey="resource"
                    noAction={true}
                    mobileListView={true}
                    disableFullView={true}
                    fullViewClassName="rt--permissions-card-view"
                />

            </div>
        )
    }

    /** Fires on checkbox change
       * @function
       * @param {string} resource
       * @param {string} parent 
       * @param {string} action
       * @param {boolean} checked
       * @memberOf PermissionsComponent
   */
    const handleChange = (resource, parent, action, checked) => {
        const { setFieldsValue, getFieldsValue, getFieldValue } = formInstance;
        let changed = false;
        const values = fieldName ? { ...getFieldValue(fieldName) } : { ...getFieldsValue() };
        if (checked) {
            if (!parent) {
                if (action === PERMISSION_ACTION.DELETE || action === PERMISSION_ACTION.MODIFY || action === PERMISSION_ACTION.CREATE || action === PERMISSION_ACTION.EXPORT) {
                    changed = true;
                    values[resource]['parent'][PERMISSION_ACTION.VIEW] = true;
                    if (values[resource][resource + "_GeneralInfo"] && (action === PERMISSION_ACTION.MODIFY || action === PERMISSION_ACTION.DELETE)) {
                        values[resource][resource + "_GeneralInfo"][PERMISSION_ACTION.VIEW] = true;
                    }
                }
            } else {
                changed = true;
                values[parent]['parent'][PERMISSION_ACTION.VIEW] = true;
                if (action === PERMISSION_ACTION.DELETE || action === PERMISSION_ACTION.MODIFY || action === PERMISSION_ACTION.CREATE || action === PERMISSION_ACTION.EXPORT) {
                    values[parent][resource][PERMISSION_ACTION.VIEW] = true;
                }
            }
        }
        if (changed) {
            setFieldsValue(fieldName ? { [fieldName]: values } : values)
        }
        setFormValues(values);
    }

    /** Function, checkes should the field be readOnly
       * @function
       * @param {string} resource
       * @param {string} parent 
       * @param {string} action
       * @memberOf PermissionsComponent
   */
    const isFieldReadonly = (resource, parent, action) => {
        if (disabled) return true;
        const values = { ...formValues };
        if ((!parent && !values[resource]) || (parent && (!values[parent] || !values[parent][resource]))) return false;
        let isReadonly = false;
        if (!parent) {
            if (action === PERMISSION_ACTION.VIEW) {
                Object.keys(values[resource]).forEach(res => {
                    if (isReadonly) return false;
                    Object.keys(values[resource][res]).forEach(a => {
                        if ((a !== PERMISSION_ACTION.VIEW || res !== "parent") && values[resource][res][a] === true) {
                            isReadonly = true;
                            return false;
                        }
                    })
                })
            }
        } else {
            if (action === PERMISSION_ACTION.VIEW) {
                if (values[parent][resource][PERMISSION_ACTION.MODIFY] === true || values[parent][resource][PERMISSION_ACTION.DELETE] === true || values[parent][resource][PERMISSION_ACTION.CREATE] === true || values[parent][resource][PERMISSION_ACTION.EXPORT] === true) {
                    isReadonly = true;
                }
                if (resource.includes("_GeneralInfo") && (values[parent]["parent"][PERMISSION_ACTION.MODIFY] === true || values[parent]["parent"][PERMISSION_ACTION.DELETE] === true)) {
                    isReadonly = true;
                }
            }
        }
        return isReadonly;
    }

    /** Function, checkes if all the permissions of resource are checked
       * @function
       * @param {string} resource
       * @param {string} action - if null then all actions
       * @memberOf PermissionsComponent
   */
    const isCheckedAll = (resource, action) => {
        const values = { ...formValues };
        let foundChecked = false;
        let foundUnChecked = false;

        if(resource && !values[resource]) return false;
        const resources = resource ? [resource] : Object.keys(values);

        resources.forEach(r => {
            if (foundUnChecked) {
                return;
            }
            for (let i = 0; i < Object.keys(values[r]).length; i++) {
                let actions = values[r][Object.keys(values[r])[i]];
    
                if (action) {
                    if (actions[action] === false) {
                        foundUnChecked = true;
                    } else if (actions[action] === true) {
                        foundChecked = true;
                    }
                } else {
                    Object.keys(actions).forEach(a => {
                        if (actions[a] === false) {
                            foundUnChecked = true;
                        } else if (actions[a] === true) {
                            foundChecked = true;
                        }
                    })
                }
    
                if (foundUnChecked) {
                    break;
                }
            }
        })

        return !foundUnChecked && foundChecked;
    }

    /** Function, checkes if all the permissions of resource are unchecked
       * @function
       * @param {string} resource
       * @param {string} action - if null then all actions
       * @memberOf PermissionsComponent
   */
    const isUnCheckedAll = (resource, action) => {
        const values = { ...formValues };
        let isChecked = false;
        if(resource && !values[resource]) return false;
        const resources = resource ? [resource] : Object.keys(values);
        resources.forEach(r => {
            if (isChecked) {
                return;
            }
            for (let i = 0; i < Object.keys(values[r]).length; i++) {
                let actions = values[r][Object.keys(values[r])[i]];
                if (action) {
                    if (actions[action] === true) {
                        isChecked = true;
                    }
                } else {
                    Object.keys(actions).forEach(a => {
                        if (actions[a] === true) {
                            isChecked = true;
                        }
                    })
                }
                if (isChecked) break;
            }
        })
        
        return !isChecked
    }

    /** Fires on select all click
       * @function
       * @param {string} resource
       * @param {string} action - if null then all actions
       * @param {object} e - click event object
       * @memberOf PermissionsComponent
   */
    const checkAll = (resource, action, e) => {
        e && e.stopPropagation();
        if (isCheckedAll(resource, action)) return;
        const { setFieldsValue, getFieldsValue, getFieldValue } = formInstance;
        const values = fieldName ? { ...getFieldValue(fieldName) } : { ...getFieldsValue() };

        const resources = resource ? [resource] : Object.keys(values);

        resources.forEach(r => {
            Object.keys(values[r]).forEach(res => {
                if (action && values[r][res][action] !== undefined) {
                    values[r][res][action] = true;
                    if (action === PERMISSION_ACTION.DELETE || action === PERMISSION_ACTION.CREATE || action === PERMISSION_ACTION.MODIFY) {
                        values[r][res][PERMISSION_ACTION.VIEW] = true;
                    }
                    if (res !== "parent") {
                        values[r]["parent"][PERMISSION_ACTION.VIEW] = true;
                    }
                    if (res === "parent" && action === PERMISSION_ACTION.MODIFY && values[r][r + "_GeneralInfo"]) {
                        values[r][r + "_GeneralInfo"][PERMISSION_ACTION.VIEW] = true;
                    }
                } else if (!action) {
                    Object.keys(values[r][res]).forEach(a => {
                        values[r][res][a] = true;
                    })
                }
            })
        })

        
        setFieldsValue(fieldName ? { [fieldName]: values } : values)
        setFormValues(values);
        onCheckAll && onCheckAll();
    }

    /** Fires on deselect all click
       * @function
       * @param {string} resource
       * @param {string} action - if null then all actions
       * @param {object} e - click event object
       * @memberOf PermissionsComponent
   */
    const unCheckAll = (resource, action, e) => {
        e && e.stopPropagation();
        if (isUnCheckedAll(resource, action)) return;
        const { setFieldsValue, getFieldsValue, getFieldValue } = formInstance;
        const values = fieldName ? { ...getFieldValue(fieldName) } : { ...getFieldsValue() };

        const resources = resource ? [resource] : Object.keys(values);

        resources.forEach(r => {
            Object.keys(values[r]).forEach(res => {
                if (action) {
                    if (!isFieldReadonly(res === "parent" ? r : res, res === "parent" ? null : r, action)) {
                        values[r][res][action] = false;
                    }
                } else {
                    Object.keys(values[r][res]).forEach(a => {
                        values[r][res][a] = false;
                    })
                }
            })
        })

        setFieldsValue(fieldName ? { [fieldName]: values } : values);
        setFormValues(values);
        onCheckAll && onCheckAll();
    }

    /** CHeck if resource has any action to edit
       * @function
       * @param {string} resource
       * @returns {boolean} 
       * @memberOf PermissionsComponent
   */
    const hasEditablePermission = resource => {
        const permission = permissions.find(p => p.resource === resource);
        if (!permission) return false;
        return permission.actions.some(a => a.canEdit) || permission.subPermissions.some(p => p.actions.some(a => a.canEdit));
    }

    /** Sort Permissions */
    const sortedPermissions = [...permissions].sort(
        (perm1, perm2) => {
            const perm1Order = mapping[toLowerCaseFirstLetter(perm1.resource)]?.order ?? 10000;
            const perm2Order = mapping[toLowerCaseFirstLetter(perm2.resource)]?.order ?? 10000;
            return perm1Order < perm2Order ? -1 : 1;
        }
    )

    useImperativeHandle(ref, () => ({
        toggleAll: isAllChecked => {
            if(isAllChecked){
                unCheckAll()
            } else {
                checkAll()
            }
        },

        isDisabled: !sortedPermissions.some(perm => hasEditablePermission(perm.resource))
    }))

    return (
        <Collapse
            bordered={false}
            className="rt--permissions-collapse"
            expandIcon={() => <Icon name="down" />}
            accordion={isMobile()}
        >
            {
                sortedPermissions.map(perm => (
                    <Collapse.Panel
                        header={(
                            <div className="rt--permissions-collapse-panel-header rt--flex rt--align-center rt--justify-between">
                                <div className="rt--permissions-collapse-panel-header-inner rt--flex rt--align-center">
                                    <Icon name={mapping[toLowerCaseFirstLetter(perm.resource)]?.icon} />
                                    <span className='rt--title rt--font-normal rt--font-bold rt--pl-4'>{makeName(perm.resource)}</span>
                                </div>
                                {
                                    editable && !disabled &&
                                    (
                                        <div className="rt--flex-inline rt--align-center rt--form-item-checkbox">
                                            <Form.Item
                                                className="rt--form-item-without-margin rt--permissions-table-checkbox"
                                            >
                                                <Tooltip
                                                    title={isCheckedAll(perm.resource) ? t("backoffice.permissions.deSelectAll") : t("backoffice.permissions.selectAll")}
                                                >
                                                    <Checkbox
                                                        checked={isCheckedAll(perm.resource)}
                                                        indeterminate={!isUnCheckedAll(perm.resource) && !isCheckedAll(perm.resource)}
                                                        onClick={e => !isCheckedAll(perm.resource) ? checkAll(perm.resource, null, e) : unCheckAll(perm.resource, null, e)}
                                                        disabled={!hasEditablePermission(perm.resource)}
                                                    />
                                                </Tooltip>
                                            </Form.Item>
                                        </div>
                                    )
                                }
                            </div>
                        )}
                        key={perm.resource}
                        forceRender={true}
                    >
                        {
                            renderTable(perm)
                        }
                    </Collapse.Panel>
                ))
            }
        </Collapse>
    )
})

/** PermissionsComponent propTypes
    * PropTypes
*/
PermissionsComponent.propTypes = {
    /** permissions array to show */
    permissions: PropTypes.arrayOf(permissionType),
    /** Is true when loading permissions */
    isLoading: PropTypes.bool,
    /** Form instance object */
    formInstance: PropTypes.object,
    /** Form initial values */
    initialFormValues: PropTypes.object,
    /** Are permission editable */
    editable: PropTypes.bool,
    /** Are checkboxes disabled */
    disabled: PropTypes.bool,
    /** Function, which will fire on checking all */
    onCheckAll: PropTypes.func,
    /** Fires on change */
    onChange: PropTypes.func,
    /** The fild name in form */
    fieldName: PropTypes.string
}

export default PermissionsComponent;
