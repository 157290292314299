import React from 'react';
import PropTypes from "prop-types";

import { useTranslation } from "core/hooks/useTranslation";

import Icon from "components/common/icon";

const DefaultEmptyView = ({
    isExpandedView=false
}) => {
    const { t } = useTranslation();
    
    return (
        <div className="rt--table-empty rt--flex rt--flex-col rt--justify-center rt--align-center">
            <Icon name="empty" size={isExpandedView ? 40 : 80}/>
            <h5 className="rt--font-bold rt--font-bigest rt--mb-6 rt--mt-16">
                {t('backoffice.common.noData')}
            </h5>
            <span className="rt--title rt--font-bold rt--font-normal">
                {t('backoffice.common.noDataFound')}
            </span>
        </div>
    );
}

DefaultEmptyView.propTypes = {
    isExpandedView: PropTypes.bool
}
 
export default DefaultEmptyView;