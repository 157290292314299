import isString from "core/helpers/typeChecks/isString";
import isNumber from "core/helpers/typeChecks/isNumber";

const classNames = (...classNames) => {
	let classes = "";

	for (let className of classNames) {
		if (!className) {
			continue;
		}

		if (isString(className) || isNumber(className)) {
			classes = classes !== "" ? `${classes} ${className}` : `${className}`;
		}
	}

	return classes;
};

export default classNames;