import React, { Fragment, useEffect } from "react";
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";
import { connect } from "react-redux";

import Tooltip from "components/common/tooltip";
import Icon from "components/common/icon";

import { getPaymentTranslations } from "store/actions/portal/payment/invoices/invoices.action";

import { isMobile, makeImagePath } from "utils/common";

import translationType from 'types/translation/translation.type';

const constructDataFromDefinedFields = details => {
    const definedFields = [];
    const pair = [];

    details.forEach(d => {
        if (pair.length === 2) {
            definedFields.push([...pair]);
            pair.length = 0;
        }

        pair.push(d);
    })

    if (pair.length !== 0) {
        definedFields.push([...pair])
    }

    return definedFields;
}

/** Invoice Details Component */
const InvoiceDetails = ({
    details,
    getPaymentTranslations,
    paymentTranslations,
    paymentId
}) => {

    const { t, locale } = useTranslation();

    const languageCode = locale.toUpperCase();

    const definedValues = constructDataFromDefinedFields(details);

    /** Load Payment Fields translations */
    useEffect(() => {
        if (!paymentTranslations[paymentId]) {
            getPaymentTranslations(paymentId)
        }
    }, [])

    /** Render dield
       * @function
       * @returns {object}
       * @memberOf TransactionDetails
   */
    const renderField = key => {
        const translations = paymentTranslations[paymentId] ?? [];
        const translation = translations.find(tr => tr.key === key.toLowerCase());

        let value = details.find(d => d.key === key)?.value;
        let title = key;
        if(translation && key !== "attachmentPath"){
            title = translation.translations?.find(t => t.languageCode === languageCode)?.text ?? translation.text;
        }

        let fileName = "";
        switch (key) {
            case "attachmentPath":
                if(value){
                    fileName = value.split("/").at(-1);
                    fileName = fileName.replace(fileName.split("_")[0] + "_", "") ;
                }
                
                return {
                    title: t(`backoffice.invoices.screenshot`),
                    content: value ? (
                        <a className="rt--flex rt--align-center" href={makeImagePath(value)} target="_blank" rel="noreferrer">
                            <Icon name="picture" size={16} className="rt--mr-4" />
                            <span>{fileName}</span>
                        </a>
                    ) : "-",
                    tooltip: fileName,
                    mobileTooltip: null
                }
            default:
                return {
                    title: title,
                    content: value,
                    tooltip: value,
                    mobileTooltip: value
                }
        }
    }

    /** Render for desktop
      * @function
      * @memberOf TransactionDetails
  */
    const renderForDesktop = () => {
        return (
            <div className="rt--table-details-list rt--flex">

                {
                    definedValues.map((fieldPair, index) => {
                        return (
                            <div className="rt--pt-8 rt--pb-8 rt--flex" key={index}>
                                <div className="rt--table-details-list-item-col rt--flex-equal">
                                    {
                                        fieldPair.map(f => {
                                            const field = renderField(f.key);
                                            return (
                                                <div className="rt--flex rt--align-center rt--mb-8" key={f.key}>
                                                    <b className="rt--title rt--font-normal rt--font-regular">{field.title}:</b>
                                                    <span
                                                        className="rt--title rt--font-normal rt--font-bold rt--pl-8 rt--pr-8"
                                                        title={field.tooltip}
                                                    >
                                                        {
                                                            field.content
                                                        }
                                                    </span>

                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    /** Render for mobile
       * @function
       * @memberOf TransactionDetails
   */
    const renderForMobile = () => {
        return (
            <div className="rt--table-details-list">
                {
                    definedValues.map((fieldPair, i) => (
                        <Fragment key={i}>
                            {
                                fieldPair.map(f => {
                                    const field = renderField(f.key);
                                    return (
                                        <div className="rt--table-details-list-item rt--flex rt--mb-16" key={f.key}>
                                            <div className="rt--flex">
                                                <b className="rt--title rt--font-big rt--font-regular">{field.title}:</b>
                                                {
                                                    field.mobileTooltip ? (
                                                        <Tooltip
                                                            title={field.mobileTooltip}
                                                            trigger={["click"]}
                                                            placement="bottomLeft"
                                                            enableMobile={true}
                                                        >
                                                            <span className="rt--title rt--font-big rt--font-medium">
                                                                {
                                                                    field.content
                                                                }
                                                            </span>
                                                        </Tooltip>
                                                    ) : (
                                                        <span className="rt--title rt--font-big rt--font-medium">
                                                            {
                                                                field.content
                                                            }
                                                        </span>
                                                    )
                                                }


                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Fragment>
                    ))
                }
            </div>
        )
    }

    return (
        <div className="rt--table-details">
            {
                !isMobile() && (
                    <b className="rt--title rt--font-bold rt--font-normal rt--table-details-title">
                        {
                            t("backoffice.invoices.requisiteDetails")
                        }
                    </b>
                )
            }
            {
                isMobile() ? renderForMobile() : renderForDesktop()
            }

        </div>

    );
};

/** InvoiceDetails propTypes
 * PropTypes
*/
InvoiceDetails.propTypes = {
    /** Details */
    details: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string
    })),
    /** Payment Id */
    paymentId: PropTypes.string,
    /** Redux state property, payment Translations */
    paymentTranslations: PropTypes.objectOf(PropTypes.arrayOf(translationType)),
    /** Redux action to get payment translations */
    getPaymentTranslations: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
    getPaymentTranslations: paymentId => {
        dispatch(getPaymentTranslations(paymentId));
    },
});

const mapStateToProps = (state) => {
    return {
        paymentTranslations: state.invoices.paymentTranslations,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoiceDetails);
