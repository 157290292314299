import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Form, Row, Col, Spin, Divider } from "antd";

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";
import NumericInput from "components/common/numericInput";

import { getProjectFeeSettings, saveProjectFeeSettings } from "store/actions/portal/projects/feeSettings.action";

import { isMobile, numberTransform } from "utils/common";
import { isFormChanged } from 'utils/form';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'core/constants/permission';

import projectFeeSettingsType from "types/project/feeSettings.type";
import usePermissions from 'core/hooks/usePermission';

/** Project Edit Page Financial Tab Fee SubTab Component */
const FeeComponent = ({
    isLoading,
    isSaving,
    getProjectFeeSettings,
    saveProjectFeeSettings,
    feeSettings,
    onTabChange
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const [isFormTouched, setIsFormTouched] = useState(false);

    const hasModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT_PAYOUT_SETTINGS, PERMISSION_ACTION.MODIFY );

    /** Get Payout Settings */
    useEffect(() => {
        getProjectFeeSettings()
    }, [])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            casinoFee: feeSettings.casinoFee,
            sportFee: feeSettings.sportFee,
            depositFee: feeSettings.depositFee
        });
    }, [feeSettings])

    /** Fires when form submitted
       * @function
       * @memberOf FeeComponent
    */
    const handleForm = () => {
        validateFields()
            .then((data) => {
                saveProjectFeeSettings({
                    casinoFee: data.casinoFee,
                    sportFee: data.sportFee,
                    depositFee: data.depositFee
                })

                setIsFormTouched(false);
            }).catch(() => { })
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <SubTabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: hasModifyPermission,
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={
                        (changed, formValues) => {
                            setIsFormTouched(
                                isFormChanged(
                                    {
                                        casinoFee: formValues.casinoFee,
                                        sportFee: formValues.sportFee,
                                        depositFee: formValues.depositFee,
                                    },
                                    {
                                        casinoFee: feeSettings.casinoFee,
                                        sportFee: feeSettings.sportFee,
                                        depositFee: feeSettings.depositFee,
                                    }
                                )
                            )
                        }
                    }
                >
                    <Row gutter={[40, 0]}>
                        <Col xs={24} sm={12} xl={6}>
                            <Row gutter={[16, 0]}>
                                <Col span={24}>
                                    <h4
                                        className={'rt--form-section-title rt--title rt--mb-16 rt--font-bold rt--font-biger' + (isMobile() ? " rt--mt-8" : "")}>
                                        {
                                            t("backoffice.commissionplans.administrativeFee")
                                        }
                                    </h4>
                                    <div className='rt--mb-16'>
                                        <span className='rt--text-light rt--font-small rt--font-regular'>{t("backoffice.commissionplans.administrativeFeeInfo")}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={[16, 0]}>
                                <Col span={24}>
                                    <Form.Item
                                        label={`${t('backoffice.commissionplans.casinoFee')} *`}
                                        name="casinoFee"
                                        rules={[
                                            { type: "number", min: 0, message: t('backoffice.validation.fieldInvalid'), transform: numberTransform },

                                            () => ({
                                                validator(rule, value) {
                                                    if (value === "" || value === null || value === undefined) {
                                                        return Promise.reject(t('backoffice.validation.fieldRequired'))
                                                    }
                                                    if (Number(value) < 0 || Number(value) > 100) {
                                                        return Promise.reject(t('backoffice.validation.mustBeBetween').replace("%X%", 0).replace("%Y%", 100))
                                                    }
                                                    return Promise.resolve();
                                                }
                                            })
                                        ]}
                                        validateFirst
                                        className={'rt--form-item-with-suffix rt--general-form-item ' + (!hasModifyPermission ? 'rt--form-item-disabled' : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.casinoFee')}`}
                                    >
                                        <NumericInput
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.casinoFee')}`}
                                            disabled={!hasModifyPermission}
                                            suffix={<span className='rt--text-light rt--font-smallest'>%</span>}
                                            maxLength={8}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 0]}>
                                <Col span={24}>
                                    <Form.Item
                                        label={`${t('backoffice.commissionplans.sportFee')} *`}
                                        name="sportFee"
                                        rules={[
                                            { type: "number", min: 0, message: t('backoffice.validation.fieldInvalid'), transform: numberTransform },

                                            () => ({
                                                validator(rule, value) {
                                                    if (value === "" || value === null || value === undefined) {
                                                        return Promise.reject(t('backoffice.validation.fieldRequired'))
                                                    }
                                                    if (Number(value) < 0 || Number(value) > 100) {
                                                        return Promise.reject(t('backoffice.validation.mustBeBetween').replace("%X%", 0).replace("%Y%", 100))
                                                    }
                                                    return Promise.resolve();
                                                }
                                            })
                                        ]}
                                        validateFirst
                                        className={'rt--form-item-with-suffix rt--general-form-item ' + (!hasModifyPermission ? 'rt--form-item-disabled' : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.sportFee')}`}
                                    >
                                        <NumericInput
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.sportFee')}`}
                                            disabled={!hasModifyPermission}
                                            suffix={<span className='rt--text-light rt--font-smallest'>%</span>}
                                            maxLength={8}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        {
                            isMobile() && (
                                <Col span={24}>
                                    <Divider className='rt--form-section-divider'/>
                                </Col>
                            )
                        }

                        <Col xs={24} sm={12} xl={6}>
                            <Row gutter={[16, 0]}>
                                <Col span={24}>
                                    <h4
                                        className={'rt--form-section-title rt--title rt--mb-16 rt--font-bold rt--font-biger' + (isMobile() ? " rt--mt-8" : "")}>
                                        {
                                            t("backoffice.commissionplans.depositFee")
                                        }
                                    </h4>
                                    <div className='rt--mb-16'>
                                        <span className='rt--text-light rt--font-small rt--font-regular'>{t("backoffice.commissionplans.depositFeeInfo")}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={[16, 0]}>
                                <Col span={24}>
                                    <Form.Item
                                        label={`${t('backoffice.commissionplans.percentage')} *`}
                                        name="depositFee"
                                        rules={[
                                            { type: "number", min: 0, message: t('backoffice.validation.fieldInvalid'), transform: numberTransform },

                                            () => ({
                                                validator(rule, value) {
                                                    if (value === "" || value === null || value === undefined) {
                                                        return Promise.reject(t('backoffice.validation.fieldRequired'))
                                                    }
                                                    if (Number(value) < 0 || Number(value) > 100) {
                                                        return Promise.reject(t('backoffice.validation.mustBeBetween').replace("%X%", 0).replace("%Y%", 100))
                                                    }
                                                    return Promise.resolve();
                                                }
                                            })
                                        ]}
                                        validateFirst
                                        className={'rt--form-item-with-suffix rt--general-form-item ' + (!hasModifyPermission ? 'rt--form-item-disabled' : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.percentage')}`}
                                    >
                                        <NumericInput
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.percentage')}`}
                                            disabled={!hasModifyPermission}
                                            suffix={<span className='rt--text-light rt--font-smallest'>%</span>}
                                            maxLength={8}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    
                    
                </Form>

            </Spin>
        </SubTabFormDashboardLayout>
    )
}

/** FeeComponent propTypes
 * PropTypes
*/
FeeComponent.propTypes = {
    /** Redux state property, is true when loading project fee data */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when saving project fee data */
    isSaving: PropTypes.bool,
    /** Redux state property, current editing project fee settings */
    feeSettings: projectFeeSettingsType,
    /** Redux action to get project fee settings */
    getProjectFeeSettings: PropTypes.func,
    /** Redux action to save project fee settings */
    saveProjectFeeSettings: PropTypes.func,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
};

const mapDispatchToProps = dispatch => (
    {
        getProjectFeeSettings: () => {
            dispatch(getProjectFeeSettings());
        },

        saveProjectFeeSettings: data => {
            dispatch(saveProjectFeeSettings(data));
        }
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.projects.isLoading,
        isSaving: state.projects.isSaving,
        feeSettings: state.projects.edit.feeSettings,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeeComponent)