import React, { useMemo } from "react";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import AppWrapper from "core/global/appWrapper";
import MainLayout from "core/layouts/main";
import AuthLayout from "core/layouts/auth";
import PortalLayout from "core/layouts/main/portal";
import CustomizeLayout from "core/layouts/main/customize";
import GuideLayout from "core/layouts/main/guide";
import UnknownRoute from "./unknownRoute";

import withAnonymousHoc from "core/HOCs/withAnonymous";
import withAuthorizationHoc from "core/HOCs/withAuthorization";
import withAccessHoc from "core/HOCs/withAccess";

const UnAuthorizedUserLayout = withAnonymousHoc(AuthLayout);
const AuthorizedUserLayout = withAuthorizationHoc(MainLayout);

import { anonymousRoutes, authorizedRoutes } from "./routes";

import { AUTHORIZED_LAYOUT_TYPE } from "./constants";

const getAppRoutes = () => [
    {
        key: "root",
        element: <AppWrapper />,
        children: [
            {
                element: <AuthorizedUserLayout />,
                children: [
                    {
                        element: <PortalLayout />,
                        children: authorizedRoutes[AUTHORIZED_LAYOUT_TYPE.PORTAL].map(route => {
                            const ElementWrapper = withAccessHoc(route.element, route);
                            const routeProps = route.props ?? {};
                            return ({
                                path: route.path,
                                element: <ElementWrapper {...routeProps} />
                            })
                        })
                    },
                    {
                        element: <CustomizeLayout />,
                        children: authorizedRoutes[AUTHORIZED_LAYOUT_TYPE.CUSTOMIZE].map(route => {
                            const ElementWrapper = withAccessHoc(route.element, route);
                            const routeProps = route.props ?? {};
                            return ({
                                path: route.path,
                                element: <ElementWrapper {...routeProps} />
                            })
                        })
                    },

                    {
                        element: <GuideLayout />,
                        children: authorizedRoutes[AUTHORIZED_LAYOUT_TYPE.GUIDE].map(route => {
                            const ElementWrapper = withAccessHoc(route.element, route);
                            const routeProps = route.props ?? {};
                            return ({
                                path: route.path,
                                element: <ElementWrapper {...routeProps} />
                            })
                        })
                    },
                    
                    {
                        path: "*",
                        element: <UnknownRoute />,
                    }
                ]
            },
            ...anonymousRoutes.map( route => ({
                path: route.path,
                element: <UnAuthorizedUserLayout />,
                children: [
                    route
                ],
            })),
        ]
    }
]

const BrowserRouter = () => {
    const router = useMemo(() => {
        return createBrowserRouter(getAppRoutes());
    }, []);

    return <RouterProvider router={router} />;
}

export default BrowserRouter;