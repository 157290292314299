import isNull from "core/helpers/typeChecks/isNull";
import isUndefined from "core/helpers/typeChecks/isUndefined";
import isString from "core/helpers/typeChecks/isString";
import isObjectLiteral from "core/helpers/typeChecks/isObjectLiteral";
import isFunction from "core/helpers/typeChecks/isFunction";
import isArray from "core/helpers/typeChecks/isArray";
import isObject from "core/helpers/typeChecks/isObject";

const isNumericString = str => {
    return !isNaN(str) && !isNaN(parseFloat(str))
}

const deepMap = (f, obj) => {
    return Object.keys(obj).reduce((acc, k) => {
        if (isObjectLiteral(obj[k])) {
            acc[k] = deepMap(f, obj[k])
        } else {
            acc[k] = f(obj[k], k)
        }
        return acc
    }, {})
}

const areObjectsEquals = ( obj1, obj2 ) => {
    if (isUndefined(obj1) || isNull(obj1) || isUndefined(obj2) || isNull(obj2)) { return obj1 === obj2 }
    
    if (obj1.constructor !== obj2.constructor) { return false; }
    
    if (isFunction(obj1) || isFunction(obj2)) { return obj1 === obj2; }
    
    if (obj1 instanceof RegExp || obj2 instanceof RegExp) { return obj1 === obj2; }
    
    if (obj1 === obj2 || obj1.valueOf() === obj2.valueOf()) { return true; }
    
    if ((isArray(obj1) || isArray(obj2)) && obj1.length !== obj2.length) { return false; }

    // if they are dates, they must had equal valueOf
    if (obj1 instanceof Date || obj2 instanceof Date) { return false; }

    // if they are strictly equal, they both need to be object at least
    if (!(isObject(obj1))) { return false; }
    if (!(isObject(obj2))) { return false; }

    // recursive object equality check
    const keys = Object.keys(obj1);
    return Object.keys(obj2).every(i => keys.indexOf(i) !== -1) && keys.every(i => areObjectsEquals(obj1[i], obj2[i]));
}

const isFormChanged = ( values, initial ) => {
    if (Object.keys(values).length === 0) return false; 

    let normalizedValues = {};
    let normalizedInitials = {};

    Object.keys(values).forEach(key => {
        normalizedValues[key] = isUndefined(values[key]) || isNull(values[key]) ? "" : values[key];
        normalizedInitials[key] = isUndefined(initial[key]) || isNull(initial[key]) ? "" : initial[key];
    });

    const mapper = v => 
        isString(v) ? (
            isNumericString(v.trim()) ? Number(v.trim()) : v.trim()
        ) : isUndefined(v) || isNull(v) ? "" : v

    normalizedValues = deepMap(mapper, normalizedValues);
    normalizedInitials = deepMap(mapper, normalizedInitials);
    return !areObjectsEquals(normalizedValues, normalizedInitials);
}

export default isFormChanged;