import useFormat from "hooks/useFormat";

const Amount = ({
    value,
    currencyCode
}) => {

    const { formatAmount } = useFormat();

    return formatAmount( value , currencyCode);
}

export default Amount;