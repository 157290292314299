import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";

import { Form, Row, Col } from 'antd';

import RichEditor from "components/common/richEditor";

import {
    resetPromoSectionTranslations,
    savePromoSectionTranslations,
} from 'store/actions/portal/promoCustomize/sections.action';

import { hasValue } from 'utils/common';

import { PROMO_SECTION_TYPE, SECTION_NAMES } from 'constants/promo.constants';


const FORM_FIELD_NAMES = {
    TERMS_AND_CONDITIONS: "description3",
}

const TermsAndConditions = ({
    translations,
    savePromoSectionTranslations,
    resetPromoSectionTranslations,
}) => {
    const { t } = useTranslation();

    const [formInstance] = Form.useForm();
    const { setFieldsValue } = formInstance;

    const initialValue = useMemo(() => {
        const value = translations[FORM_FIELD_NAMES.TERMS_AND_CONDITIONS]?.translation ?? "";
        return value.startsWith("<p>") ? value : `<p>${value}</p>`; // Fixed tinymce bug which adds <p> at start of string
    }, [translations])

    const handleReset = () => {
        resetPromoSectionTranslations(
            SECTION_NAMES.FOOTER,
            PROMO_SECTION_TYPE.FOOTER,
            FORM_FIELD_NAMES.TERMS_AND_CONDITIONS,
        )
    }


    // Set Fields Value When Translations is Loaded
    useEffect(() => {
        if (!hasValue(translations)) {
            return;
        }

        setFieldsValue({
            [FORM_FIELD_NAMES.TERMS_AND_CONDITIONS]: initialValue
        })
        
    }, [translations]);

    return (

        <div className='rt--customize-widget-editor'>
            <Form
                className="rt--form"
                colon={false}
                form={formInstance}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    [FORM_FIELD_NAMES.TERMS_AND_CONDITIONS]: initialValue
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            name={FORM_FIELD_NAMES.TERMS_AND_CONDITIONS}
                            className="rt--general-form-item"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                            ]}
                        >
                            <RichEditor.WithAdditionalActions
                                height={325}
                                initialValue={initialValue}
                                showReset={translations[FORM_FIELD_NAMES.TERMS_AND_CONDITIONS]?.isOverwritten}
                                onConfirm={value => {
                                    savePromoSectionTranslations(
                                        SECTION_NAMES.FOOTER,
                                        PROMO_SECTION_TYPE.FOOTER,
                                        FORM_FIELD_NAMES.TERMS_AND_CONDITIONS,
                                        value
                                    )
                                }}
                                onReset={handleReset}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

        </div>
    )
}

/** TermsAndConditions propTypes
 * PropTypes
*/
TermsAndConditions.propTypes = {
    translations: PropTypes.objectOf(PropTypes.shape({
        isOverwritten: PropTypes.bool,
        key: PropTypes.string,
        translation: PropTypes.string,
    })),
    /** Redux action to save promo section translations */
    savePromoSectionTranslations: PropTypes.func,
    /** Redux action to reset promo section translations */
    resetPromoSectionTranslations: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
    savePromoSectionTranslations: (sectionName, sectionType, key, text) => {
        dispatch(savePromoSectionTranslations(sectionName, sectionType, key, text))
    },
    resetPromoSectionTranslations: (sectionName, sectionType, key, text) => {
        dispatch(resetPromoSectionTranslations(sectionName, sectionType, key, text))
    },
});

const mapStateToProps = (state) => {
    return {
        translations: state.promoCustomize.sections[PROMO_SECTION_TYPE.FOOTER].translations,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);