import React, { useContext } from "react";

import TableContext from "../../context";

import { splitActions } from "../../helpers";

import VisibleActions from "./visibleActions";
import DropdownActions from "./dropdownActions";

const TableActions = ({
    actions=[],
    record,
    expandable,
    showFullViewData,
    showDetailsData
}) => {

    const activeActions = actions.filter(action => !action.disabled?.(record));

    const isExpandable = Boolean(expandable) && ( expandable.rowExpandable?.(record) ?? true )

    if(showFullViewData || isExpandable){
        activeActions.unshift({
            icon: "eye",
            onClick: record => showDetailsData(record),
            alwaysVisible: true
        })
    }

    const { showConfirmationModal } = useContext(TableContext);

    if (activeActions.length === 0) {
        return null;
    }

    const { visibleActions, dropdownActions } = splitActions(activeActions);

    const onActionClick = action => {
        if(Boolean(action.confirmation)){
            showConfirmationModal({
                record,
                title: action.confirmation.title,
                message: action.confirmation.message,
                onOk: action.onClick
            })
            return;
        }

        action.onClick(record);
    }

    return (
        <div className="rt--table-item-row-actions rt--flex rt--flex-col rt--align-center">
            <div className='rt--flex rt--flex-col'>
                <VisibleActions actions={visibleActions} onActionClick={onActionClick} />
                <DropdownActions actions={dropdownActions} record={record} onActionClick={onActionClick} />
            </div>
        </div>
    )
}

export default TableActions;