import React from 'react';

const DebugTabs = ({ items }) => {
    const [selectedTab, setSelectedTab] = React.useState(0);

    const availableItems = items.filter(item => !item.disabled);

    const handleTabSelect = (tabIndex) => {
        setSelectedTab(tabIndex);
    }

    return (
        <div className="rt--debug-tabs">
            <div className="rt--debug-tabs-header">
                {availableItems.map((item, index) => (
                    <button
                        key={item.key}
                        className={
                            [
                                "rt--debug-tabs-header-button",
                                index === selectedTab ? "rt--debug-tabs-header-button__active" : ""
                            ].join(' ')
                        }
                        onClick={() => handleTabSelect(index)}
                    >
                        {item.label}
                    </button>
                ))}
            </div>

            <div className="rt--debug-tabs-content">
                {availableItems.map((item, index) => (
                    <div
                        key={item.key}
                        className={
                            [
                                "rt--debug-tabs-content-item",
                                index === selectedTab ? "rt--debug-tabs-content-item__active" : ""
                            ].join(' ')
                        }
                    >
                        {item.children}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DebugTabs;
