import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "core/hooks/useTranslation";

import { getExpandTableColumns } from './columns';

import useFormat from 'hooks/useFormat';
import useAccess from 'hooks/useAccess';

import affiliateReportType from 'types/reports/affiliateReport.type';

const orders = [
    ["depositCount", "depositAmount", "netDepositAmount"],
    ["ftdCount", "ftdAmount", "cpaCount"],
    ["ngr", "adjustmentsAmount"],
    ["cpaAmount", "fixedCommissionAmount", "totalCommissionAmount"],
    ["sportRevShareAmount", "casinoRevShareAmount"],
    ["sportBetCount", "casinoBetCount"],
    ["sportBetAmount", "casinoBetAmount"],
    ["sportWonAmount", "casinoWonAmount"],
    ["sportBonusWonAmount", "casinoBonusWonAmount"],
    ["sportGGR", "casinoGGR"],
    ["sportNGR", "casinoNGR"],
    ["sportFeeAmount", "casinoFeeAmount"],
]

/** Affiliate Report Details Component */
const ReportDetails = ({
    data
}) => {
    const { t } = useTranslation();

    const { formatAmount, formatNumber } = useFormat();

    const { hasAccess } = useAccess();

    const columns = getExpandTableColumns({
        formatAmount,
        hasAccess
    });

    const getColumn = index => columns.find(c => c.dataIndex === index);

    return (
        <div className='rt--reports-details'>
            {
                data.map(d => (
                    <div className='rt--reports-details-item rt--flex rt--flex-col rt--pb-16' key={d.currencyCode}>
                        <h4 className='rt--title rt--font-bold rt--font-biger rt--pt-24 rt--pb-8 rt--mb-0'>{d.currencyCode}</h4>
                        <div className='rt--reports-details-item-container rt--flex'>
                            {
                                orders.map((order, index) => (
                                    <div className='rt--reports-details-item-col rt--pr-24' key={index}>
                                        {
                                            order.map(o => {
                                                const column = getColumn(o); 
                                                if(!column){
                                                    return null;
                                                }
                                                return (
                                                    (
                                                        <div className='rt--reports-details-item-row rt--flex rt--justify-between rt--mb-8' key={o}>
                                                            <span className='rt--text-light rt--font-normal rt-font-regular rt--pr-8'>
                                                                {
                                                                    `${t(column.title)}:`
                                                                }
                                                            </span>
                                                            <b className='rt--title rt--font-normal rt-font-bold'>
                                                                {
                                                                    column.render ? column.render(d[o], d) : 
                                                                    column.isNumeric ? formatNumber(d[o]) : d[0]
                                                                }
                                                            </b>
                                                        </div>
                                                    )
                                                )
                                            })
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

/** ReportDetails propTypes
    * PropTypes
*/
ReportDetails.propTypes = {
    /** Data */
    data: PropTypes.arrayOf(affiliateReportType)
}

export default ReportDetails;