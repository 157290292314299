import React, { lazy } from "react";

import Spin from "core/ui-kit/spin";

import ROUTES from "core/constants/routes/routes";

import withSuspenseHoc from "core/HOCs/withSuspense";

const SetPasswordContainer = withSuspenseHoc(lazy(() => import("./setPasswordContainer")), <Spin />);

const SetPasswordPageRoute = {
	path: ROUTES.SET_PASSWORD,
	element: <SetPasswordContainer />,
};

export default SetPasswordPageRoute