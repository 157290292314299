export const WALLET_TYPE = {
    CPA_NOT_APPROVED_BALANCE: 1 << 0,
    EARNING_NOT_APPROVED_BALANCE: 1 << 1,
    CPA_BALANCE: 1 << 2,
    EARNING_BALANCE: 1 << 3,
    FROZEN_BALANCE: 1 << 4,
    PAIDOUT_BALANCE: 1 << 5
}

export const WALLET_TYPE_TRANSLATIONS = {
    [WALLET_TYPE.EARNING_BALANCE]: "backoffice.wallet.earningBalance",
    [WALLET_TYPE.CPA_BALANCE]: "backoffice.wallet.cpaBalance",
    [WALLET_TYPE.EARNING_NOT_APPROVED_BALANCE]: "backoffice.wallet.earningNotApprovedBalance",
    [WALLET_TYPE.CPA_NOT_APPROVED_BALANCE]: "backoffice.wallet.cpaNotApprovedBalance",
    [WALLET_TYPE.PAIDOUT_BALANCE]: "backoffice.wallet.paidoutBalance",
    [WALLET_TYPE.FROZEN_BALANCE]: "backoffice.wallet.frozenBalance",
}