import axios from "core/lib/axios";
import { message } from "antd";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { isMobile } from "utils/common";

import {
    SET_SYSTEM_CURRENCIES_ACTION_BEFORE,
    SET_SYSTEM_CURRENCIES_ACTION_FINISH,
    SET_SYSTEM_CURRENCIES,
    SET_SYSTEM_CURRENCIES_SORTING,
    SET_SYSTEM_CURRENCIES_SAVE_ACTION_BEFORE,
    SET_SYSTEM_CURRENCIES_SAVE_ACTION_FINISH,
    SET_SYSTEM_AVAILABLE_CURRENCIES_ACTION_BEFORE,
    SET_SYSTEM_AVAILABLE_CURRENCIES_ACTION_FINISH,
    SET_SYSTEM_AVAILABLE_CURRENCIES,
    SET_AVAILABLE_CURRENCIES
} from "../../../../actionTypes";

const setSystemCurrenciesActionBefore = () => ({
    type: SET_SYSTEM_CURRENCIES_ACTION_BEFORE,
});

const setSystemCurrenciesActionFinished = () => ({
    type: SET_SYSTEM_CURRENCIES_ACTION_FINISH,
});

const setSystemCurrenciesSaveActionBefore = () => ({
    type: SET_SYSTEM_CURRENCIES_SAVE_ACTION_BEFORE,
});

const setSystemCurrenciesSaveActionFinished = () => ({
    type: SET_SYSTEM_CURRENCIES_SAVE_ACTION_FINISH,
});

const setSystemCurrencies = (currencies, add) => ({
    type: SET_SYSTEM_CURRENCIES,
    payload: { currencies, add },
});

export const setSystemCurrenciesSorting = sorting => ({
    type: SET_SYSTEM_CURRENCIES_SORTING,
    payload: { sorting },
});


export const getSystemCurrencies = nextPage => {
    return (dispatch, getState) => {
        
        const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().systemCurrencies.sorting.page + 1 : getState().systemCurrencies.sorting.page;
        let params = {
            ...getState().systemCurrencies.sorting,
            page: page
        };

        if(page > 1 && getState().systemCurrencies.total <= (page - 1 ) * getState().systemCurrencies.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && getState().systemCurrencies.total === 0){
            return Promise.resolve();
        }
        
        dispatch(setSystemCurrenciesActionBefore());

        return axios({
            url: ApiUrls.GET_SYSTEM_CURRENCIES,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: currencies } }) => {
                dispatch(setSystemCurrencies(currencies, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setSystemCurrenciesSorting({ ...getState().systemCurrencies.sorting, page: page }));
                dispatch(setSystemCurrenciesActionFinished());
            })
            .catch((ex) => {
                dispatch(setSystemCurrenciesActionFinished());
            });
    }
}

export const createSystemCurrency = (currency, onSuccess) => {
    return (dispatch) => {
        dispatch(setSystemCurrenciesSaveActionBefore());
        return axios({
            url: ApiUrls.CREATE_SYSTEM_CURRENCY,
            method: Methods.POST,
            data: {
                ...currency
            },
        })
            .then(({ data }) => {
                dispatch(setSystemCurrenciesSaveActionFinished());
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    onSuccess && onSuccess(data.value);
                    dispatch(getSystemCurrencies())
                }
            })
            .catch(() => {
                dispatch(setSystemCurrenciesSaveActionFinished());
            });
    };
};

export const saveSystemCurrency = (currency, onSuccess) => {
    return (dispatch) => {
        dispatch(setSystemCurrenciesSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_SYSTEM_CURRENCY,
            method: Methods.POST,
            data: {
                ...currency
            },
        })
            .then(({ data }) => {
                dispatch(setSystemCurrenciesSaveActionFinished());
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    onSuccess && onSuccess(data.value);
                    dispatch(getSystemCurrencies())
                }
            })
            .catch(() => {
                dispatch(setSystemCurrenciesSaveActionFinished());
            });
    };
};

/** Avalable Currencies */

const setSystemAvalableCurrenciesActionBefore = () => ({
    type: SET_SYSTEM_AVAILABLE_CURRENCIES_ACTION_BEFORE,
});

const setSystemAvalableCurrenciesActionFinished = () => ({
    type: SET_SYSTEM_AVAILABLE_CURRENCIES_ACTION_FINISH,
});

const setSystemAvailableCurrencies = currencies => ({
    type: SET_SYSTEM_AVAILABLE_CURRENCIES,
    payload: { currencies },
});

const setAvailableCurrencies = currencies => ({
    type: SET_AVAILABLE_CURRENCIES,
    payload: { currencies },
});

export const getSystemAvailableCurrencies = () => {
    return (dispatch) => {
        dispatch(setSystemAvalableCurrenciesActionBefore());
        return axios({
            url: ApiUrls.GET_SYSTEM_AVAILABLE_CURRENCIES,
            method: Methods.GET
        })
            .then(({ data: { value: currencies } }) => {
                dispatch(setSystemAvailableCurrencies(currencies));
                dispatch(setSystemAvalableCurrenciesActionFinished());
            })
            .catch(() => {
                dispatch(setSystemAvalableCurrenciesActionFinished());
            });
    }
}

export const getAvailableCurrencies = () => {
    return (dispatch) => {
        dispatch(setSystemAvalableCurrenciesActionBefore());
        return axios({
            url: ApiUrls.GET_AVAILABLE_CURRENCIES,
            method: Methods.GET
        })
            .then(({ data: { value: currencies } }) => {
                dispatch(setAvailableCurrencies(currencies));
                dispatch(setSystemAvalableCurrenciesActionFinished());
            })
            .catch(() => {
                dispatch(setSystemAvalableCurrenciesActionFinished());
            });
    }
}