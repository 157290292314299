import React, { useState } from "react";

import Icon from "core/ui-kit/icon";
import Dropdown from "core/ui-kit/dropdown";

import { useTranslation } from "core/hooks/useTranslation";

import isFunction from "core/helpers/typeChecks/isFunction";

const DropdownActions = ({
    actions,
    record,
    onActionClick=Function.prototype
}) => {

    const { t } = useTranslation();

    const [ dropdownOpened, setDropdownOpened ] = useState(false);

    const handleClick = ({ key }) => {
        setDropdownOpened(false);
        const action = actions[Number(key)];
        onActionClick(action);
    }

    const menuItems = actions.map((action, index) => ({
        key: index,
        label: (
            <div className="rt--flex rt--align-center">
                <Icon name={action.icon} />
                <span className="rt--title rt--font-regular rt--font-normal rt--pl-4 rt--pr-4">
                    { isFunction(action.title) ? action.title(record) : action.title }
                </span>
            </div>
        )
    }))

    if(actions.length === 0){
        return null;
    }

    return (
        <div className="rt--table-col-actions rt--flex rt--align-center rt--justify-end">
            <Dropdown
                title={t("backoffice.common.actions")} 
                menu={{ 
                    items: menuItems, 
                    onClick: handleClick,
                    className: "rt--table-actions-menu" 
                }}
                popupOpened={dropdownOpened}
                onOpenChange={setDropdownOpened}
            >
                <div className="rt--table-col-actions-item rt--ml-8">
                    <Icon name="more" />
                </div>
            </Dropdown>
        </div>
    )
}

export default DropdownActions;