import ROUTES from "core/constants/routes/routes";

import DBConnectionsComponent from "pages/developer/dbConnections/dbConnections.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const DBConnectionsPageRoute = {
	path: ROUTES.DEVELOPER_DB_CONNECTIONS,
	element: DBConnectionsComponent, 
    permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW }
};

export default DBConnectionsPageRoute;
