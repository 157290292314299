import { useCallback, useEffect } from "react";

import useDevice from "core/hooks/useDevice";

const CssDataAttributes = () => {

    const { isMobile } = useDevice();

	useEffect(() => {
		if (isMobile) {
			document.documentElement.setAttribute("data-ismobile", 1);
		} else {
			document.documentElement.setAttribute("data-ismobile", 0);
		}
	}, [isMobile]);

    const appHeight = useCallback(() => {
		document.documentElement.style.setProperty('--app-height', window.innerHeight + "px")
	}, [])


	useEffect(() => {
		appHeight();
		window.addEventListener("resize", appHeight);

        return () => {
            window.removeEventListener("resize", appHeight)
        }
	}, [])

	return null;
};

export default CssDataAttributes;