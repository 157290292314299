import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "core/hooks/useTranslation";

import { Form, message } from 'antd';

import Modal from 'components/common/modal';
import Input from 'components/common/input';
import ImageUploader from "components/common/imageUploader";

import { isFunction } from 'utils/common';

const FORM_FIELD_NAMES = {
    NAME: "name",
    FILE: "file",
}

const StoryAddEditComponent = ({
    name,
    file,
    onClose,
    type,
    onOk,
    isSaving,
}) => {
    const { t } = useTranslation();

    const fileRef = useRef(file || null);

    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    const handleForm = () => {
        validateFields()
            .then(fieldsValue => {
                if (!fileRef.current) {
                    return message.error(t('backoffice.validation.shouldUploadImg'))
                }

                if (isFunction(onOk)) {
                    onOk({
                        ...fieldsValue,
                        [FORM_FIELD_NAMES.FILE]: fileRef.current
                    });
                    onClose();
                }

            }).catch(ex => {
                console.log(ex)
            })
    }

    return (
        <Modal
            title={type === "edit" ? t('backoffice.promo.editBlock') : type === "addStory" ? t('backoffice.promo.addStory') : t('backoffice.promo.addBlock')}
            cancelText={t('backoffice.common.cancel')}
            okText={type === "edit" ? t('backoffice.common.save') : t('backoffice.common.add')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    [FORM_FIELD_NAMES.NAME]: name,
                }}
            >

                {type !== "addStory" && (
                    <Form.Item
                        label={`${t('backoffice.promo.blockName')} *`}
                        name={FORM_FIELD_NAMES.NAME}
                        className='rt--general-form-item'
                        validateFirst
                        rules={[
                            { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                            { max: 30, message: t('backoffice.validation.mustBeLess').replace("%X%", 30) },
                            { min: 3, message: t('backoffice.validation.mustBeMore').replace("%X%", 3) }
                        ]}
                    >
                        <Input
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.promo.blockName')}`}
                            maxLength={30}
                        />
                    </Form.Item>
                )}

                <Form.Item
                    label=""
                    name={FORM_FIELD_NAMES.FILE}
                    className='rt--general-form-item rt--form-item-without-margin rt--mt-4'
                    validateFirst
                >
                    <ImageUploader
                        size={1024 * 1024}
                        remove={{ handler: () => fileRef.current = null }}
                        onFileReady={(file) => fileRef.current = file}
                        defaultFile={
                            file ? {
                                url: `${import.meta.env.SYSTEM_CDN_URL}/${file.toLowerCase()}`,
                                status: "done",
                                percent: 100
                            } : null
                        }
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}

/** StoryAddEditComponent propTypes
    * PropTypes
*/
StoryAddEditComponent.propTypes = {
    type: PropTypes.oneOf([
        "addStory",
        "addBlock",
        "edit",
    ]),
    /** Fires on popup close */
    onClose: PropTypes.func,
    /** Fires on popup add/save button clicked */
    onOk: PropTypes.func,
    /** title initial value on add/edit mode */
    name: PropTypes.string,
    /** url initial value on add/edit mode */
    file: PropTypes.string,
    /** true when saving data */
    isSaving: PropTypes.bool,

}

export default StoryAddEditComponent;