import React, {useEffect} from 'react';

import { debugStore, useDebugSelector } from '..';

import DebugChangeList from './components/debugChangeList';
import DebugHeader from './components/debugHeader';
import DebugNextTab from './components/debugNextTab';
import DebugPrevTab from './components/debugPrevTab';
import DebugStackTraceTab from './components/debugStackTraceTab';
import DebugStateTab from './components/debugStateTab';
import DebugTabs from './components/debugTabs';
import DebugValueTab from './components/debugValueTab';

import { withEnabled } from './hocs/withEnabled';
import { withPopup } from './hocs/withPopup';

const DebugComponent = () => {
    const { enabled, opened, placement, selectedChange } = useDebugSelector();

    const tabItems = [
        {
            key: "1",
            label: "Value",
            children: <DebugValueTab />,
            disabled: !selectedChange
        },
        {
            key: "2",
            label: "Prev",
            children: <DebugPrevTab />,
            disabled: !selectedChange
        },
        {
            key: "3",
            label: "Next",
            children: <DebugNextTab />,
            disabled: !selectedChange
        },
        {
            key: "4",
            label: "State",
            children: <DebugStateTab />
        },
        {
            key: "5",
            label: "Stack Trace",
            children: <DebugStackTraceTab />,
            disabled: !selectedChange
        }
    ];

    const handleKeyCombination = (event) => {
        if (event.altKey && event.ctrlKey && event.key === "d") {
            debugStore.toggleOpened();
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", handleKeyCombination);

        return () => {
            window.removeEventListener("keydown", handleKeyCombination);
        }
    }, []);

    if (!enabled || !opened) {
        return null;
    }

    return (
        <div className={
            [
                "rt--debug",
                `rt--debug__${placement}`
            ].join(' ')
        }>
            <DebugHeader />

            <div className="rt--debug-content">
                <DebugChangeList />
                <DebugTabs items={tabItems} />
            </div>
        </div>
    );
};

export default withEnabled(withPopup(DebugComponent));
