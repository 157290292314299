import { message } from 'antd';

import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import {
    setProjectsActionBefore,
    setProjectsActionFinished,
    setProjectsSaveActionBefore,
    setProjectsSaveActionFinished
} from './projects.action';

import { 
    SET_PROJECT_AFFILIATE_REGISTRATION_FORM 
} from '../../../actionTypes';

import { globalStore } from 'core/stores/globalStore';

const setAffiliateRegistrationConfig = registrationConfig => ({
    type: SET_PROJECT_AFFILIATE_REGISTRATION_FORM,
    payload: { registrationConfig }
})

export const getAffiliateRegistrationConfig = () => {
    return dispatch => {
        dispatch(setProjectsActionBefore());

        return axios({
            url: ApiUrls.GET_PROJECT_AFFILIATE_REGISTRATION_CONFIGURATION,
            method: Methods.GET
        })
        .then(({data : {value: registrationConfig }}) => {
            dispatch(setAffiliateRegistrationConfig(registrationConfig))
            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        })
    }
}

export const saveAffiliateRegistrationConfig = data => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_PROJECT_AFFILIATE_REGISTRATION_CONFIGURATION,
            method: Methods.POST,
            data: {
                ...data
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setAffiliateRegistrationConfig(data.value));

                globalStore.getUserInfo()
            } 
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}