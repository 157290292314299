import { Row as AntRow, Col as AntCol, Layout as AntLayout } from "antd";

const { Content: AntContent, Header: AntHeader, Sider: AntSider } = AntLayout;

export const Row = AntRow;
export const Col = AntCol;
export const Layout = AntLayout;
export const Content = AntContent;
export const Header = AntHeader;
export const Sider = AntSider;
