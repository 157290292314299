import ROUTES from "core/constants/routes/routes";

import SystemPaymentEditComponent from "pages/settings/systemPayments/edit/systemPayments-edit.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const SystemPaymentEditPageRoute = {
	path: `${ROUTES.SETTINGS_PAYMENTS_EDIT}/:id`,
	element: SystemPaymentEditComponent, 
    permission: { resource: PERMISSION_RESOURCE.SYSTEM_PAYMENT_METHODS, action: PERMISSION_ACTION.VIEW }
};

export default SystemPaymentEditPageRoute;
