import React from "react";
import Icon from "core/ui-kit/icon";

const VisibleActions = ({
    actions,
    onActionClick=Function.prototype
}) => {

    const handleClick = (action, e) => {
        e.preventDefault();
        e.stopPropagation();
        onActionClick(action);
    }

    if(actions.length === 0){
        return null;
    }

    return (
        <div className="rt--table-col-actions rt--flex rt--align-center rt--justify-end rt--flex-col">
            {
                actions.map((action, index) => (
                    <div
                        onClick={e => handleClick(action, e)}
                        key={index}
                        className="rt--table-col-actions-item rt--mb-8"
                    >
                        <Icon 
                            name={action.icon} 
                        />
                    </div>
                ))
            }
        </div>
    )
}

export default VisibleActions;