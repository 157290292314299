//#region react
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useParams } from "react-router-dom";
//#endregion

//#region actions
import {
    getUserPermissionGroups,
    deleteUserPermissionGroup
} from "store/actions/portal/userManagement/users/permissionGroups.action";
//#endregion

//#region components
import SubTabTableDashboardLayout from "components/layouts/tab/subtab/table";
import Table from "components/common/table";
import Notes from 'components/common/notes';
import GroupAddComponent from "./group-add.component";
import PendingRequestsNote from 'components/common/pendingRequestsNote';
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import { PERMISSION_ACTION } from 'core/constants/permission';
import { PERMISSION_REQUEST_OBJECT_TYPE, PERMISSION_REQUEST_TYPE } from 'constants/permissionRequest.constants';
import { ADMIN_PERMISSION_GROUP } from 'constants/pageName.constants';
import { USER_ROLE } from 'constants/user.constants';
import { tableColumns } from './columns';
//#endregion

//#region types
import userPermissionGroupType from 'types/user/permissionGroup.type';
//#endregion

import { useGlobalSelector, userInfoSelector } from 'core/stores/globalStore';
import { useAuthSelector, userRoleSelector } from 'core/stores/authStore';

import usePermissions from 'core/hooks/usePermission';

const ROW_UNIQUE_KEY_PROP = "name";

/** User Edit Page Permissions and Groups Tab Groups Sub Tab Component */
const GroupsComponent = ({
    getUserPermissionGroups,
    deleteUserPermissionGroup,
    isLoading,
    groups,
    permissionResource
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const permissionUtils = usePermissions();

    const { id: userId } = useGlobalSelector(userInfoSelector);
    const userRole = useAuthSelector(userRoleSelector);

    /** Notes */
    const [notes, setNotes] = useState(null);

    // State to show/hide user add/edit popup
    const [isAddPopupVisible, setIsAddPopupVisible] = useState(false);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: ADMIN_PERMISSION_GROUP });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasCreatePermission = permissionUtils.has(permissionResource, PERMISSION_ACTION.CREATE);
    const hasDeletePermission = permissionUtils.has(permissionResource, PERMISSION_ACTION.DELETE);

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const showAddPopup = () => {
        setIsAddPopupVisible(true);
    }

    const hideAddPopup = () => {
        setIsAddPopupVisible(false);
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns, columnsThatCanBeIncluded } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: tableColumns,
            constructForInclude: true,
            includedColumns,
            t
        });
    }, [includedColumns, t])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasDeletePermission && searchParams.id !== userId) {
        tableRowActions.push({
            icon: "trash",
            title: t('backoffice.common.delete'),
            confirmationTitle: t("backoffice.users.deleteGroup"),
            confirmationMessage: t("backoffice.users.doYouWanttoDeleteGroup"),
            showConfirmation: true,
            onClick: record => handleDelete(record.id),
        })
    }

    /** Actions */
    const handleDelete = id => {
        if (userRole !== USER_ROLE.ACCESS_MANAGER) {
            deleteUserPermissionGroup(
                searchParams.id,
                id
            )
        } else {
            setNotes({
                id
            })
        }
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        button: {
            icon: "plus",
            type: "primary",
            onClick: showAddPopup,
            text: t("backoffice.users.addGroup"),
            enabled: hasCreatePermission && userId !== searchParams.id
        }
    }

    //#endregion

    // Close user add/edit popup, after save
    useEffect(() => {
        hideAddPopup();
    }, [groups])
    // TODO: check unique key warning
    return (
        <SubTabTableDashboardLayout 
            header={headerPartsData}
            extraContent={(
                <PendingRequestsNote
                    objectId={searchParams.id}
                    actionTypes={[PERMISSION_REQUEST_TYPE.ADD_PERMISSION_GROUP, PERMISSION_REQUEST_TYPE.DELETE_PERMISSION_GROUP]}
                    objectType={PERMISSION_REQUEST_OBJECT_TYPE.USER}
                    dependencies={[groups]}
                />
            )}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={groups}
                loadFn={() => getUserPermissionGroups(searchParams.id)}
                uniqueKey={ROW_UNIQUE_KEY_PROP}
                noPagination={true}
                disableFullView={true}
                smallInMobile={true}
                mobileLayoutVertical={true}
                actions={tableRowActions}
            />

            {isAddPopupVisible && <GroupAddComponent onClose={hideAddPopup} />}

            {
                notes && (
                    <Notes
                        onClose={() => setNotes(null)}
                        onSuccess={value => {
                            deleteUserPermissionGroup(
                                searchParams.id,
                                notes.id,
                                value
                            );
                        }}
                    />
                )
            }

        </SubTabTableDashboardLayout>
    )
}

/** GroupsComponent propTypes
    * PropTypes
*/
GroupsComponent.propTypes = {
    /** Redux action to get user permission groups */
    getUserPermissionGroups: PropTypes.func,
    /** Redux action to delete user permission group */
    deleteUserPermissionGroup: PropTypes.func,
    /** Redux state property, is true when loading user permission group */
    isLoading: PropTypes.bool,
    /** Redux state, represents the permission groups of current editing user */
    groups: PropTypes.arrayOf(userPermissionGroupType),
    /** The permission resource */
    permissionResource: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getUserPermissionGroups: id => {
            dispatch(getUserPermissionGroups(id));
        },

        deleteUserPermissionGroup: (id, groupId, note) => {
            dispatch(deleteUserPermissionGroup(id, groupId, note));
        }
    }
)

const mapStateToProps = state => {
    return {
        groups: state.users.edit.permissionGroups.groups,
        isLoading: state.users.isLoading,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupsComponent)