import ROUTES from "core/constants/routes/routes";

import AccessManagersComponent from "pages/userManagement/accessManagers/accessManagers.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const AccessManagersPageRoute = {
	path: ROUTES.ACCESS_MANAGERS,
	element: AccessManagersComponent, 
    permission: { resource: PERMISSION_RESOURCE.ACCESS_MANAGER, action: PERMISSION_ACTION.VIEW }
};

export default AccessManagersPageRoute;
