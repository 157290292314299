import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import Select from 'components/common/select';
import DateRangePicker from "components/common/dateRangePicker";

import {
    getPermissionRequestHistory,
    setPermissionRequestHistoryFilters
} from "store/actions/portal/userManagement/requests/history.action";

import { PERMISSION_REQUEST_OBJECT_TYPE, PERMISSION_REQUEST_STATUS, PERMISSION_REQUEST_TYPE } from 'constants/permissionRequest.constants';

import { globalCompanyIdSelector, useAuthSelector } from 'core/stores/authStore';

/** Permission Requests History Page Filters Component */

const Filters = ({
    setPermissionRequestHistoryFilters,
    getPermissionRequestHistory,
    filters
}) => {
    const { t } = useTranslation();

    const globalCompanyId = useAuthSelector(globalCompanyIdSelector);

    return (
        <FiltersWrapper
            loadFn={getPermissionRequestHistory}
            setFiltersFn={setPermissionRequestHistoryFilters}
            filters={filters}
            updateProps={[globalCompanyId]}
            datePickerFields={[
                { name: "date", time: true },
                { name: "modified", time: true },
            ]}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.users.status')}
                        name="status"
                    >
                        <Select
                            options={[
                                { value: "", text: t('backoffice.common.all') },
                                { value: PERMISSION_REQUEST_STATUS.APPROVED, text: t('backoffice.users.approved') },
                                { value: PERMISSION_REQUEST_STATUS.REJECTED, text: t('backoffice.users.rejected') }
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.users.status')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.users.actionType')}
                        name="actionTypes"
                    >
                        <Select
                            options={[
                                { value: null, text: t('backoffice.common.all') },
                                { value: PERMISSION_REQUEST_TYPE.GROUP_MODIFY, text: t('backoffice.users.permissionGroupModify') },
                                { value: PERMISSION_REQUEST_TYPE.ADD_COMPANY_ACCESS, text: t('backoffice.users.addCompanyAccess') },
                                { value: PERMISSION_REQUEST_TYPE.DELETE_COMPANY_ACCESS, text: t('backoffice.users.deleteCompanyAccess') },
                                { value: PERMISSION_REQUEST_TYPE.ADD_PROJECT_ACCESS, text: t('backoffice.users.addProjectAccess') },
                                { value: PERMISSION_REQUEST_TYPE.DELETE_PROJECT_ACCESS, text: t('backoffice.users.deleteProjectAccess') },
                                { value: PERMISSION_REQUEST_TYPE.ADD_PERMISSION_GROUP, text: t('backoffice.users.addPermissionGroup') },
                                { value: PERMISSION_REQUEST_TYPE.DELETE_PERMISSION_GROUP, text: t('backoffice.users.deletePermissionGroup') }
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.users.status')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.users.objectType')}
                        name="objectType"
                    >
                        <Select
                            options={[
                                { value: "", text: t('backoffice.common.all') },
                                { value: PERMISSION_REQUEST_OBJECT_TYPE.USER, text: t('backoffice.users.user') },
                                { value: PERMISSION_REQUEST_OBJECT_TYPE.PERMISSION_GROUP, text: t('backoffice.users.permissionGroup') },
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.users.objectType')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.creationDate')}
                        name="date"
                    >
                        <DateRangePicker
                            showTime={true}
                            allowClear={false}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.lastUpdateDate')}
                        name="modified"
                        className='rt--form-item-without-margin'
                    >
                        <DateRangePicker
                            showTime={true}
                            allowClear={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get permission request history */
    getPermissionRequestHistory: PropTypes.func,
    /** Redux action to set permission request history filters */
    setPermissionRequestHistoryFilters: PropTypes.func,
    /** Redux state property, permission request history filters */
    filters: PropTypes.object
}

const mapDispatchToProps = dispatch => (
    {
        getPermissionRequestHistory: () => {
            dispatch(getPermissionRequestHistory())
        },
        setPermissionRequestHistoryFilters: filters => {
            dispatch(setPermissionRequestHistoryFilters(filters))
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.permissionRequests.history.filters
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);