import React, { useEffect, memo } from "react";

import { useTranslation } from "core/hooks/useTranslation";

import isRTL from "core/helpers/language/isRTL";

const RTL = React.lazy(() => import("core/global/rtlSpecificStyles/rtl"));
const LTR = React.lazy(() => import("core/global/rtlSpecificStyles/ltr"));

const RTLSpecificStyles = () => {
	const { locale } = useTranslation();

    const canLoadRTL = isRTL(locale);

    useEffect(() => {
		if (canLoadRTL) {
			document.body.classList.add("rt--rtl");
		} else {
			document.body.classList.remove("rt--rtl");
		}
	}, [locale]);

    return canLoadRTL ? <RTL /> : <LTR />;
};

export default memo(RTLSpecificStyles);