import PropTypes from "prop-types";

import useDevice from 'core/hooks/useDevice';


const Responsive = ({ mobile, desktop }) => {

    const { isMobile } = useDevice()

	if (isMobile) {
		return mobile;
	}

	return desktop;
};

Responsive.propTypes = {
	mobile: PropTypes.node.isRequired,
	desktop: PropTypes.node.isRequired,
};

export default Responsive;