import ROUTES from "core/constants/routes/routes";

import UsersComponent from "pages/userManagement/users/users.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const UsersPageRoute = {
	path: ROUTES.USERS,
	element: UsersComponent, 
    permission: { resource: PERMISSION_RESOURCE.ADMIN, action: PERMISSION_ACTION.VIEW }
};

export default UsersPageRoute;