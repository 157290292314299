import React from 'react';

import { useDebugSelector } from '../../..';

import ObjectViewer from '../ObjectViewer';

const DebugStateTab = () => {
    const { selectedChange, allStates } = useDebugSelector();

    if (!selectedChange) {
        return <ObjectViewer value={allStates} canEdit />
    }

    return (
        <ObjectViewer value={selectedChange.state} />
    );
};

export default DebugStateTab;
