import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";

import { Row, Col, Form, Divider, Button } from "antd";

import Select from "components/common/select";
import Icon from "components/common/icon";
import DateRangePicker from 'components/common/dateRangePicker';
import NumericInput from 'components/common/numericInput';

import { TARGET_MARKET } from 'constants/affiliate.constants';
import { USER_STATE } from 'constants/user.constants';
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

import useAutosuggestion from 'hooks/useAutosuggestion';

import { isMobile } from 'utils/common';

import countries from "systemData/countries";

const FILTER_NAMES = {
    STATE: {
        key: "state",
        name: "state",
        title: "backoffice.communication.status",
        type: "select"
    },
    CREATED_AT: {
        key: "createdAt",
        name: "createdAt",
        title: "backoffice.communication.registrationDate",
        type: "rangepicker"
    },
    COUNTRY: {
        key: "country",
        name: "country",
        title: "backoffice.communication.country",
        type: "select"
    },
    MANAGER_ID: {
        key: "managerId",
        name: "managerId",
        title: "backoffice.communication.assignedManager",
        type: "select"
    },
    TARGET_MARKET: {
        key: "targetMarket",
        name: "targetMarket",
        title: "backoffice.communication.targetMarket",
        type: "select"
    },
    PLAYER_COUNT: {
        key: "playerCount",
        name: ["playerCountFrom", "playerCountTo"],
        title: "backoffice.communication.affiliatesPlayerCount",
        type: "range"
    }
}

/** Newsletter Creating Popup Affilaites Section Component */
const NewsletterCreateAffiliatesComponent = ({
    formInstance,
    selectedFilters,
    setSelectedFilters
}) => {

    const { t } = useTranslation();

    const { setFieldsValue } = formInstance;

    const [ affiliateManagerNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AFFILIATE_MANAGERS
    });

    const maxFiltersCount = Object.keys(FILTER_NAMES).length;
    const filtersCount = Object.keys(selectedFilters).length;

    const handleFilterChange = (filter, key) => {
        setSelectedFilters(prevState => ({
            ...prevState,
            [key]: filter
        }))
    }

    const handleAddFilter = () => {
        setSelectedFilters(prevState => ({
            ...prevState,
            [String(Object.keys(prevState).length)]: null
        }))
    }

    const handleFilterRemove = key => {

        const removingField = selectedFilters[key];
        const removingFieldName = FILTER_NAMES[removingField]?.name;
        let values = {};
        if (Array.isArray(removingFieldName)) {
            removingFieldName.forEach(n => {
                values[n] = undefined
            })
        } else {
            values[removingFieldName] = undefined
        }
        setFieldsValue(values)
        setSelectedFilters(prevState => {
            const newState = {};
            Object.keys(prevState).forEach(k => {
                if (Number(k) < Number(key)) {
                    newState[k] = prevState[k];
                } else if (Number(k) > Number(key)) {
                    newState[k - 1] = prevState[k]
                }
            })
            return newState;
        })
    }

    const getSelectData = type => {
        if (type === FILTER_NAMES.TARGET_MARKET["key"]) {
            return [
                { value: TARGET_MARKET.EUROPE, text: t(`backoffice.affiliates.europe`) },
                { value: TARGET_MARKET.ASIA, text: t(`backoffice.affiliates.asia`) },
                { value: TARGET_MARKET.AFRICA, text: t(`backoffice.affiliates.africa`) },
                { value: TARGET_MARKET.NORTH_AMERICA, text: t(`backoffice.affiliates.northAmerica`) },
                { value: TARGET_MARKET.CENTRAL_AND_SOUTH_AMERICA, text: t(`backoffice.affiliates.centralAndSouthAmerica`) },
                { value: TARGET_MARKET.MIDDLE_EAST_NORTH_AFRICA, text: t(`backoffice.affiliates.middleEastNorthAfrica`) }
            ]
        } else if (type === FILTER_NAMES.STATE["key"]) {
            return [
                { value: USER_STATE.IN_PROGRESS, text: t('backoffice.affiliates.pending') },
                { value: USER_STATE.ACTIVE, text: t('backoffice.affiliates.approved') },
                { value: USER_STATE.BLOCKED, text: t('backoffice.common.blocked') },
                { value: USER_STATE.DECLINED, text: t('backoffice.common.declined') }
            ]
        } else if (type === FILTER_NAMES.COUNTRY["key"]) {
            return countries.map(c => ({
                value: c.iso2,
                text: t(`backoffice.countries.${c.iso2}`)
            }))
        } else if (type === FILTER_NAMES.MANAGER_ID["key"]) {
            return [
                ...affiliateManagerNames.map(({ name, id }) => ({ text: name, value: id }))
            ];
        }
        return [];
    }

    return (
        <Form
            className="rt--form"
            form={formInstance}
            colon={false}
            requiredMark={false}
            layout="vertical"
        >
            <h4
                className='rt--form-section-title rt--title rt--mb-16 rt--mt-0 rt--font-bold rt--font-biger'
            >
                {
                    isMobile() ? t('backoffice.communication.affiliates') : t('backoffice.communication.filterAffiliates')
                }
            </h4>
            <Divider className='rt--form-section-divider' />
            {
                Object.keys(selectedFilters).map(key => (
                    <Fragment key={key}>
                        <Row gutter={[16, 0]}>
                            <Col span={22} lg={8}>
                                <Form.Item
                                    label={key === "0" ? t('backoffice.communication.filter') : ""}
                                    data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.communication.filter')}`}
                                    validateFirst
                                >
                                    <Select
                                        options={
                                            Object.keys(FILTER_NAMES)
                                                .filter(f => !Object.values(selectedFilters).includes(f) || selectedFilters[key] === f)
                                                .map(f => (
                                                    { value: f, text: t(FILTER_NAMES[f].title) }
                                                )
                                                )
                                        }
                                        placeholder={`${t('backoffice.common.select')} ${t('backoffice.communication.filter')}`}
                                        onChange={e => handleFilterChange(e, key)}
                                        getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                        value={selectedFilters[key]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={22} lg={8}>
                                {
                                    FILTER_NAMES[selectedFilters[key]]?.type === "select" ? (
                                        <Form.Item
                                            label={key === "0" && !isMobile() ? " " : ""}
                                            name={FILTER_NAMES[selectedFilters[key]]?.name}
                                            rules={[
                                                { required: true, message: t('backoffice.validation.fieldRequired') },
                                            ]}
                                            data-placeholder={`${t('backoffice.common.enter')} ${t(FILTER_NAMES[selectedFilters[key]]?.title)}`}
                                            validateFirst
                                        >
                                            <Select
                                                options={getSelectData(FILTER_NAMES[selectedFilters[key]]?.key)}
                                                placeholder={`${t('backoffice.common.select')} ${t(FILTER_NAMES[selectedFilters[key]]?.title)}`}
                                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                            />
                                        </Form.Item>
                                    ) : FILTER_NAMES[selectedFilters[key]]?.type === "rangepicker" ? (
                                        <Form.Item
                                            label={key === "0" && !isMobile() ? " " : ""}
                                            name={FILTER_NAMES[selectedFilters[key]]?.name}
                                            rules={[
                                                { required: true, message: t('backoffice.validation.fieldRequired') },
                                            ]}
                                        >
                                            <DateRangePicker
                                                showTime={false}
                                                allowClear={false}
                                            />
                                        </Form.Item>
                                    ) : FILTER_NAMES[selectedFilters[key]]?.type === "range" ? (
                                        <div className='rt--flex rt--align-end rt--justify-between rt--form-item-inputs'>
                                            <Form.Item
                                                label={key === "0" && !isMobile() ? " " : ""}
                                                name={FILTER_NAMES[selectedFilters[key]]?.name[0]}
                                                className='rt--flex-equal rt--form-item-inputs-left'
                                            >
                                                <NumericInput
                                                    placeholder={t("backoffice.common.from")}
                                                />
                                            </Form.Item>
                                            <Form.Item>
                                                <div className='rt--form-item-inputs-separator'>
                                                    <div className='rt--datepicker-separator' />
                                                </div>
                                            </Form.Item>
                                            <Form.Item
                                                label={key === "0" && !isMobile() ? " " : ""}
                                                name={FILTER_NAMES[selectedFilters[key]]?.name[1]}
                                                className='rt--flex-equal rt--form-item-inputs-right'
                                            >
                                                <NumericInput
                                                    placeholder={t("backoffice.common.to")}
                                                />
                                            </Form.Item>
                                        </div>
                                    ) : null
                                }

                            </Col>
                            <Col span={2} lg={8}>
                                {
                                    selectedFilters[key] && (
                                        <Form.Item
                                            label={key === "0" && !isMobile() ? " " : ""}
                                        >
                                            <div
                                                className='rt--cursor-pointer rt--flex-inline rt--align-center'
                                                onClick={() => handleFilterRemove(key)}
                                                style={isMobile() ? { top: "-24px", position: "relative" } : undefined}
                                            >
                                                <Icon name="trash" />
                                            </div>
                                        </Form.Item>
                                    )
                                }
                            </Col>
                        </Row>
                        {isMobile() && <Divider className='rt--form-section-divider' />}
                    </Fragment>
                ))
            }
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <div className='rt--flex rt--align-center'>
                        <Button
                            type="primary"
                            icon={<Icon name="plus" />}
                            onClick={handleAddFilter}
                            className="rt--button rt--button-main"
                            disabled={filtersCount === maxFiltersCount}
                        >
                            {t('backoffice.communication.addFilter')}
                        </Button>
                    </div>
                </Col>
            </Row>

        </Form>
    )
}

/** NewsletterCreateAffiliatesComponent propTypes
    * PropTypes
*/
NewsletterCreateAffiliatesComponent.propTypes = {
    /** Form Instance */
    formInstance: PropTypes.object,
    /** Selected Filters Object */
    selectedFilters: PropTypes.object,
    /** Function to Set Selected Filters Object */
    setSelectedFilters: PropTypes.func
}

export default NewsletterCreateAffiliatesComponent;