export const COMMISSION_SOURCE = {
    GGR: 1,
    NGR: 2,
    TURNOVER: 4,
    NET_DEPOSIT: 8
}

export const COMMISSION_SOURCE_TRANSLATIONS = {
    [COMMISSION_SOURCE.GGR] : 'backoffice.commissionplans.ggr',
    [COMMISSION_SOURCE.NGR] : 'backoffice.commissionplans.ngr',
    [COMMISSION_SOURCE.TURNOVER] : 'backoffice.commissionplans.turnover',
    [COMMISSION_SOURCE.NET_DEPOSIT]: 'backoffice.commissionplans.netDeposit'
}