import {
    SET_PROFILE_ACTION_BEFORE,
    SET_PROFILE_ACTION_FINISH,
    SET_PROFILE_SAVE_ACTION_BEFORE,
    SET_PROFILE_SAVE_ACTION_FINISH,
    CHANGE_PASSWORD_ACTION_BEFORE,
    CHANGE_PASSWORD_ACTION_FINISH,
    SET_PERSONAL_INFORMATION,
    SET_FORMAT_SETTINGS
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_PROFILE_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case SET_PROFILE_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_PERSONAL_INFORMATION:
            return {
                ...state,
                personalInformation: payload.personalInformation
            }
        case SET_FORMAT_SETTINGS:
            return {
                ...state,
                formatSettings: payload.formatSettings
            }
        case SET_PROFILE_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true
            }
        case SET_PROFILE_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false
            }
        case CHANGE_PASSWORD_ACTION_BEFORE:
            return {
                ...state,
                isPasswordChanging: true
            }
        case CHANGE_PASSWORD_ACTION_FINISH:
            return {
                ...state,
                isPasswordChanging: false
            }
        default:
            return state;
    }
};
