import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { ConfigProvider, message } from "antd";

import enLang from 'antd/es/locale/en_US';
import frLang from 'antd/es/locale/fr_FR';
import esLang from 'antd/es/locale/es_ES';
import ruLang from 'antd/es/locale/ru_RU';
import faLang from 'antd/es/locale/fa_IR';
import zhLang from 'antd/es/locale/zh_CN';

import { useTranslation } from "core/hooks/useTranslation";

const languagesMapping = {
    "en": enLang,
    "fr": frLang,
    "es": esLang,
    "ru": ruLang,
    "fa": faLang,
    "zh": zhLang
}

const AntdProvider = ({ children }) => {

    const { locale } = useTranslation()

    const localeObject = languagesMapping[locale] || enLang;

    useEffect(() => {
        message.config({
            maxCount: 3
        })
    }, [])

	return (
		<ConfigProvider
            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0] ?? document.body}
            form={{
                requiredMark: false
            }}
            locale={localeObject}
		>
			{children}
		</ConfigProvider>
	);
};

AntdProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AntdProvider;
