import { WALLET_OPERATION_TYPE } from "constants/wallet.constants";

export const makeTransactionText = ( transactionType, t ) => {
    switch (transactionType) {
        case WALLET_OPERATION_TYPE.COMMISSION_CALCULATION:
            return t("backoffice.wallet.commissionCalculated");
        case WALLET_OPERATION_TYPE.COMMISSION_RECALCULATION:
            return t("backoffice.wallet.commissionRecalculated");
        case WALLET_OPERATION_TYPE.COMMISSION_APPROVE:
            return t("backoffice.wallet.commissionApproved");
        case WALLET_OPERATION_TYPE.INVOICE_CREATE:
            return t("backoffice.wallet.invoiceCreated");
        case WALLET_OPERATION_TYPE.INVOICE_APPROVE:
            return t("backoffice.wallet.invoicePaid");
        case WALLET_OPERATION_TYPE.ROLLBACK:
            return t("backoffice.wallet.rollback");
        case WALLET_OPERATION_TYPE.DEPOSIT:
            return t("backoffice.wallet.manualBonused");
        case WALLET_OPERATION_TYPE.WITHDRAW:
            return t("backoffice.wallet.manualDeducted");
        case WALLET_OPERATION_TYPE.REFERRAL_CALCULATION:
            return t("backoffice.wallet.paidReferralCalculated");
        case WALLET_OPERATION_TYPE.REFERRAL_CALCULATION_APPROVE:
            return t("backoffice.wallet.referralCalculationApproved");
        case WALLET_OPERATION_TYPE.RESET_EARNING_BALANCE:
            return t("backoffice.wallet.resetEarningBalance");
        default:
            return ""
    }
}