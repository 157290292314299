import ROUTES from "core/constants/routes/routes";

import MediaToolsComponent from "pages/marketingTools/mediaTools/mediaTools.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const MediasPageRoute = {
	path: ROUTES.MEDIA_TOOLS,
	element: MediaToolsComponent, 
    permission: { resource: PERMISSION_RESOURCE.MARKETING_TOOLS_MEDIA, action: PERMISSION_ACTION.VIEW }
};

export default MediasPageRoute;
