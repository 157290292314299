import { useEffect, useCallback, useState } from "react";

import dateService from "core/services/dateService";

const useDate = () => {
    const [dateSettings, setDateSettings] = useState(dateService.getSettings());

    const handler = useCallback(settings => setDateSettings(settings), []);

    useEffect(() => {
		dateService.subscribe(handler);

		return () => {
			dateService.unsubscribe(handler);
		};
	}, [handler]);

    return { dateService, dateSettings };
}

export default useDate;