import React from "react";

import PropTypes from "prop-types";

import { Table as AntTable } from "antd";

const Summary = ({
    summary
}) => {

    return <span>Summary</span>
}

Summary.propTypes = {
    summary: PropTypes.object,
}

export default Summary;