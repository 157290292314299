import React, {useState} from 'react';

import isObject from 'core/helpers/typeChecks/isObject';
import isArray from 'core/helpers/typeChecks/isArray';

import DebugValue from '../debugValue';

const DebugValueRow = ({name, path, value, deepness = 0, canEdit}) => {
    const [opened, setOpened] = useState(false);

    const isPrimitive = !isObject(value);
    let properties = [];

    if (!isPrimitive) {
        properties = Object.keys(value);
    }

    const handleOpenToggle = () => {
        setOpened(!opened);
    };

    return (
        <>
            <div
                style={{
                    paddingLeft: `${deepness * 20}px`,
                    marginLeft: !isPrimitive ? "-16px" : "0px",
                }}
                className="rt--debug-object-viewer-row"
            >
                {!isPrimitive && (
                    <button
                        className={
                            [
                                "rt--debug-object-viewer-row-toggle",
                                opened ? "rt--debug-object-viewer-row-toggle__opened" : ""
                            ].join(" ")
                        }
                        onClick={handleOpenToggle}
                    >
                        ▶
                    </button>
                )}

                {name && (
                    <span
                        className={
                            [
                                "rt--debug-object-viewer-row-name",
                                !isPrimitive ? "rt--debug-object-viewer-row-name__nested" : ""
                            ].join(" ")
                        }
                        onClick={!isPrimitive ? handleOpenToggle : undefined}
                    >
                        {name} :
                        {!isPrimitive && opened && <span style={{color: '#f1cb21'}}>{isArray(value) ? ' [' : ' {'}</span>}
                    </span>
                )}

                <span>
                    {!opened && <DebugValue path={path} value={value} canEdit={canEdit} />}
                </span>
            </div>

            {!isPrimitive && opened && properties.map((property, index) => (
                <DebugValueRow
                    key={index + property}
                    name={property}
                    path={[...path, property]}
                    value={value[property]}
                    deepness={deepness + 1}
                    canEdit={canEdit}
                />
            ))}

            {!isPrimitive && opened && (
                <div style={{paddingLeft: `${deepness * 20}px`, color: '#f1cb21'}}>
                    {isArray(value) ? ']' : '}'}
                </div>
            )}
        </>
    );
};

export default DebugValueRow;
