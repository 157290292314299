import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useLocation, useNavigate } from "react-router-dom";

import { Tabs as AntTabs } from 'antd';

import TabDashboardLayout from "components/layouts/tab";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'core/constants/permission';
import { UNSAVED_FORM_PAGE_TYPE } from 'constants/common.constants';

import { getHashValue, updateLocationHash } from 'utils/common';
import { deleteQueryStringParamsToUrl } from 'utils/queryString';

import useUnsavedChanges from 'hooks/useUnsavedChanges';
import useUnsavedChangesConfirmation from 'hooks/useUnsavedChangesConfirmation';
import usePermissions from 'core/hooks/usePermission';

/** Tabs */
const Tabs = ({
    items,
    mainPage,
    destroyInactiveTabPane
}) => {

    const { pathname, search, hash } = useLocation();
    const navigate = useNavigate();

    const permissionUtils = usePermissions();

    const entityName = (new URLSearchParams(search)).get("name");

    const [unsavedTabs, setUnsavedTabs] = useState([]);

    const [activeKey, setActiveKey] = useState(null);

    const { setToStore } = useUnsavedChanges(UNSAVED_FORM_PAGE_TYPE.TAB);

    /** Function fires on tab change
       * @function
       * @param {string} key - tab key
       * @memberOf Tabs
   */
    const handleTabsChange = (key) => {
        setActiveKey(key);
        updateLocationHash("tab=" + key);
    }

    const handleTabsChangeWithConfirmation = useUnsavedChangesConfirmation({
        cb: handleTabsChange,
        subscribe: [UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    /** Function to check if tab should be visible
       * @function
       * @param {object} tab - tab
       * @returns {boolean}
       * @memberOf Tabs
   */
    const isTabVisible = tab => {
        if (tab.permissions) {
            if (!permissionUtils.some(...tab.permissions)) {
                return false;
            }
        }

        if (tab.disabled) {
            return false;
        }

        return true;
    }

    /** Function to get first tab key
       * @function
       * @returns {number}
       * @memberOf Tabs
   */
    const getInitialActiveKey = () => items.findIndex(item => isTabVisible(item))?.toString();

    /** Function to make tab class name
       * @function
       * @description addes class "unsaved-tab" if tab has unsaved changes
       * @param {string} key - tab key
       * @returns {string}
       * @memberOf Tabs
   */
    const tabClassName = key => unsavedTabs.indexOf(key) > -1 ? "rt--tab-unsaved" : "";

    /** Fires when tab saved status changed
       * @function
       * @param {boolean} status - does tab have unsaved change
       * @param {string} key - tab key
       * @memberOf Tabs
   */
    const changeTabSavedStatus = (status, key) => {
        if (status && unsavedTabs.indexOf(key) === -1) {
            setUnsavedTabs([...unsavedTabs, key]);
        } else if (!status) {
            setUnsavedTabs(unsavedTabs.filter(t => t !== key));
        }
    }

    const tabWithSubTabsExist = items.some(item => isTabVisible(item) && item.pathParams);

    const pathParamsValues = items.reduce((acc, current) => {
        const obj = {...acc}
        if(isTabVisible(current) && current.pathParams){
            obj[current.pathParams.name] = (new URLSearchParams(search)).get(current.pathParams.name);
            obj[current.pathParams.id] = (new URLSearchParams(search)).get(current.pathParams.id);
        }
        return obj;
    }, {})

    /** Set default tab */
    useEffect(() => {
        const tabKey = getHashValue("tab");
        const key = tabKey ? tabKey : getInitialActiveKey()?.toString()
        setActiveKey(key);

        /** Tabs indexes which has additional params */
        const indexes = []

        items.forEach((item, index) => {
            if(isTabVisible(item) && item.pathParams){
                indexes.push(index)
            }
        })
        let shouldNavigate = false;
        let pathToNavigate = pathname + search + hash;
        for(let i = 0; i < indexes.length; i++){
            const current = indexes[i];
            if (key !== current.toString()) {
                shouldNavigate = true;
                const pathParams = items[current].pathParams;
                if(search.includes(pathParams.id + "=") && search.includes(pathParams.name + "=")){
                    pathToNavigate = deleteQueryStringParamsToUrl(pathname, search, hash, [pathParams.id, pathParams.name]);
                }
            } 
        }

        if(shouldNavigate){
            navigate(pathToNavigate, { replace: true })
        }
    }, [hash])

    const generateBreadCrumbs = () => {
        const breadcrumbs = Array.isArray(mainPage) ? [...mainPage] : [ mainPage ];
        let breadcrumb = null;

        if(entityName){
            breadcrumb = {};
            breadcrumb.title = entityName;
            breadcrumb.path = undefined;
        }
        

        let newBreadCrumb = null

        items.forEach(item => {
            if(isTabVisible(item) && item.pathParams){
                const id = item.pathParams.id;
                const name = item.pathParams.name;
                if(pathParamsValues[id]){
                    if(!breadcrumb){
                        breadcrumb = {};
                    }
                    breadcrumb.path = deleteQueryStringParamsToUrl(pathname, search, hash, [id, name])
                    breadcrumb.replace = true;
                    
                    newBreadCrumb = { title: pathParamsValues[name] ?? "Unknown" }
                }
            }
        });
        if(breadcrumb){
            breadcrumbs.push(breadcrumb);
        }
        if(newBreadCrumb){
            breadcrumbs.push(newBreadCrumb);
        }
        return breadcrumbs;
    }

    useEffect(() => {
        const isThereUnsavedChanges = unsavedTabs.length > 0;

        setToStore(isThereUnsavedChanges);
    }, [unsavedTabs]);

    return ( 
        <TabDashboardLayout
            breadcrumbs={
                {
                    items: generateBreadCrumbs()
                }
            }
        >
            <AntTabs
                animated={false}
                activeKey={activeKey}
                destroyInactiveTabPane={destroyInactiveTabPane}
                onChange={ tabWithSubTabsExist ? handleTabsChangeWithConfirmation : handleTabsChange }
                className='rt--tabs'
            >
                {
                    items.map((tab, index) => isTabVisible(tab) && (
                        <AntTabs.TabPane
                            tab={
                                (
                                    <span
                                        className={tabClassName(index.toString())}
                                    >
                                        {
                                            tab.title
                                        }
                                    </span>
                                )
                            }
                            key={index.toString()}
                        >
                            {
                                React.cloneElement(
                                    tab.component,
                                    { onTabChange: status => changeTabSavedStatus(status, index.toString()) }
                                )
                            }
                        </AntTabs.TabPane>
                    ))
                }
            </AntTabs>
        </TabDashboardLayout>
    )
}

/** Tabs propTypes
    * PropTypes
*/

Tabs.propTypes = {
    /** Items */
    items: PropTypes.arrayOf(PropTypes.shape({
        /** Tab title */
        title: PropTypes.string,
        /** Permissions */
        permissions: PropTypes.arrayOf(PropTypes.shape({
            /** Permission resource */
            resource: PropTypes.oneOf(Object.values(PERMISSION_RESOURCE)),
            /** Permission action */
            action: PropTypes.oneOf(Object.values(PERMISSION_ACTION))
        })),
        /** Component */
        component: PropTypes.node,
        /** Is disabled */
        disabled: PropTypes.bool,
        /** Additional pathParams */
        pathParams: PropTypes.shape({
            /** Name */
            name: PropTypes.string,
            /** id */
            id: PropTypes.string
        })
    })),
    /** Main page data */
    mainPage: PropTypes.oneOfType([
        PropTypes.shape({
            /** Main page title */
            title: PropTypes.string, 
            /** Main page path */
            path: PropTypes.string
        }),
        PropTypes.arrayOf(PropTypes.shape({
            /** Main page title */
            title: PropTypes.string, 
            /** Main page path */
            path: PropTypes.string
        }))
    ]),
    /** Destroy Inactive Tabs */
    destroyInactiveTabPane: PropTypes.bool
}

export default Tabs;