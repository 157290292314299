import React, { Fragment, memo, useState } from "react";

import Responsive from "core/ui-kit/responsive";

import TableMobile from "./mobile";
import TableDesktop from "./desktop";

import Cell from "./columns/cell";

import TableContext from "./context";
import Confirmation from "components/common/confirmation";

const Table = memo(
    props => {
    
        const [confirmationModal, setConfirmationModal] = useState({
            isVisible: false,
            onOk: Function.prototype
        });
    
        const showConfirmationModal = confirmationModalData => {
            setConfirmationModal({
                isVisible: true,
                ...confirmationModalData
            })
        }
    
        const hideConfirmationModal = () => {
            setConfirmationModal({
                isVisible: false,
                onOk: Function.prototype
            })
        }
    
        return (
            <Fragment>
                <TableContext.Provider value={{ showConfirmationModal }}>
                    <Responsive 
                        mobile={
                            <TableMobile
                                {...props}
                            />
                        }
                        desktop={
                            <TableDesktop
                                {...props}
                            />
                        }
                    />
                </TableContext.Provider>
                
                <Confirmation
                    title={confirmationModal?.title ?? ""}
                    message={confirmationModal.message ?? ""}
                    onOk={() => confirmationModal.onOk(confirmationModal.record)}
                    onCancel={hideConfirmationModal}
                    isVisible={confirmationModal.isVisible}
                />
    
            </Fragment>
        )
    }
)

Table.Cell = Cell;

export default Table;