import { useEffect, useState } from "react";

import useDate from "hooks/useDate";

import { getMarketingLineChartData } from "../api";

import { makeTimePeriodCustomRangeOutputValue } from "../helpers";

const useMarketingLineChart = ( period, updateLastUpdatedDate, isActive) => {

    const { dateService } = useDate();

    const [chartState, setChartState] = useState({
        isLoading: false,
        chartData: [],
    })

    useEffect(() => {
        if(isActive === false){
            return
        }

        setChartState((prevState) => {
            return {
                ...prevState,
                isLoading: true
            }
        })

        const { request, cancelRequest } = getMarketingLineChartData({
            timePeriod: period?.period,
            ...makeTimePeriodCustomRangeOutputValue(period?.from, period?.to, dateService)
        })

        request
            .then((response) => {
                const { data: { value: payload } } = response;
                updateLastUpdatedDate(payload.lastUpdatedAt);
                setChartState((prevState) => {
                    return {
                        ...prevState,
                        isLoading: false,
                        chartData: payload.data,
                        // chartData: payload.data.map(d => ({
                        //     ...d,
                        //     viewsCount: Math.floor(Math.random() * (1000 - 100 + 1) + 100), 
                        //     invalidClicksCount: Math.floor(Math.random() * (3000 - 500 + 1) + 500), 
                        //     validClicksCount: Math.floor(Math.random() * (1500 - 250 + 1) + 250), 
                        // }))
                    }
                })
            })
            .catch(() => {
                setChartState((prevState) => {
                    return {
                        ...prevState,
                        isLoading: false
                    }
                })
            })

        return () => {
            cancelRequest();
        }
    }, [period, updateLastUpdatedDate, isActive])

    return chartState;

}

export default useMarketingLineChart;