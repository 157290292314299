import { AUTHORIZATION, API_URL } from "core/constants/guide";

const guideService = {
    getPostList() {
        return fetch(API_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": AUTHORIZATION,
            },
            body: JSON.stringify({post_list: true}),
        }).then(response => response.json());
    },

    getPostData(post_id) {
        return fetch(API_URL, {
            method: "POST",
            headers: {
                "Content-Type": "text/plain",
                "Authorization": AUTHORIZATION,
            },
            body: JSON.stringify({ post_id }),
        }).then(response => response.text());
    },
};

export default guideService;