import React from "react";

import Icon from "core/ui-kit/icon";

import { ORDER_DIRECTION } from "core/constants/table";

import useDevice from "core/hooks/useDevice";

const ColumnHeaderTitle = ({
    title,
    isSortable,
    orderDirection
}) => {

    const { isMobile } = useDevice();

    const showSortingIcon = !isMobile && isSortable;

    return (
        <div className="rt--table-col-title rt--flex rt--align-center">
            {
                title
            }
            {
                showSortingIcon && (
                    <Icon name={orderDirection === ORDER_DIRECTION.DESC ? "sort-down" : "sort-up"} className="rt--ml-4" />
                )
            }
        </div>
    ); 
}

export default ColumnHeaderTitle;