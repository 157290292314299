import React from "react";

import { useTranslation } from "core/hooks/useTranslation";
import useDevice from "core/hooks/useDevice";
import usePermissions from "core/hooks/usePermission";

import Form from "core/ui-kit/form";
import Button from "core/ui-kit/button";
import Icon from "core/ui-kit/icon";

import classNames from "core/helpers/common/classNames";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const Actions = ({
    negativeCarryOver,
    onNegativeCarryOverChange,
    onAddButtonClick
}) => {

    const { t } = useTranslation();

    const { isMobile } = useDevice();

    const permissionUtils = usePermissions();

    const className = classNames(
        "rt--commissions-actions rt--flex rt--justify-between ",
        isMobile ? "rt--mb-24" : "rt--mb-16"
    )

    const canModify = permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE_COMMISSION_PLAN, PERMISSION_ACTION.MODIFY )
    const canAdd = permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE_COMMISSION_PLAN, PERMISSION_ACTION.CREATE );

    return (
        <div className={className}>
            <Form.Switch
                checked={negativeCarryOver}
                onChange={onNegativeCarryOverChange} 
                label={t('backoffice.commissionplans.negativeCarryOver')}
                disabled={!canModify}
            />

            <Button
                icon={<Icon name="plus" />}
                type="primary"
                className="rt--button rt--button-main"
                onClick={onAddButtonClick}
                disabled={!canAdd}
            >
                { t("backoffice.commissionplans.addCommission") }
            </Button>
        </div>
    )
}

export default Actions;