import XRegExp from "xregexp";

import EMAIL_REGEXP from "core/constants/regexp/emailRegexp";

import validatePassword from "core/helpers/common/validatePassword";

class FormRulesBuilder {
    constructor( translate = v => v) {
        this.translate = translate;
		this.rules = [];
	}

    required(options = {}) {
        const validator = {
			required: true,
			message: this.translate("backoffice.validation.fieldRequired"),
		}
        if(!options.hasOwnProperty("whitespace") || options.whitespace === true){ // This checking must be becaouse of antd strange bug
            validator.whitespace = true;
        }
        
		this.rules.push(validator);

		return this;
	}

	maxCharactersCount(count = 0) {
		this.rules.push({
			max: count,
			message: this.translate("backoffice.validation.fieldInvalid"),
		});

		return this;
	}

	minCharactersCount(count = 0) {
		this.rules.push({
			min: count,
			message: this.translate("backoffice.validation.fieldInvalid"),
		});

		return this;
	}

    email(){
        this.rules.push({
			pattern: EMAIL_REGEXP,
			message: this.translate("backoffice.validation.emailFormat"),
		});

		return this;
    }

    password(settings){
        this.rules.push( 
            ({ getFieldValue }) => ({
                validator: (_, value) => {
                    return validatePassword(value, settings, this.translate);
                }
            })
        );

        return this;
    }

    regexp(regExp){
        this.rules.push( 
            ({ getFieldValue }) => ({
                validator: (_, value) => {
                    if(value){
                        try {
                            const regex = new XRegExp(regExp);
                            if(!regex.test(value.trim())){
                                return Promise.reject(this.translate('backoffice.validation.fieldInvalid'))
                            } 
                        } catch (ex) {
                            return Promise.reject(this.translate('backoffice.validation.fieldInvalid'))
                        }
                    }
                    return Promise.resolve();
                }
            })
        );

        return this;
    }

    validator(validateFn){
        this.rules.push( 
            ({ getFieldValue }) => ({
                validator: (_, value) => {
                    return validateFn(value, getFieldValue);
                }
            })
        );
        return this;
    }

	build() {
		return this.rules;
	}
}

export default FormRulesBuilder