import isObjectLiteral from "../typeChecks/isObjectLiteral";
import isArray from "../typeChecks/isArray";

import { PERMISSION_ACTION } from "core/constants/permission";

import isUserBlocked from "../user/isUserBlocked";

const blockedUserRestrictedPermissions = [
	PERMISSION_ACTION.CREATE,
	PERMISSION_ACTION.MODIFY,
	PERMISSION_ACTION.DELETE,
];

const Permissions = (userPermissions = {}, userState) => {
	const utils = {
		has(resource, action) {
			if (isObjectLiteral(resource)) {
				action = resource.action;
				resource = resource.resource;
			}

			if (isUserBlocked(userState) && blockedUserRestrictedPermissions.includes(action)) {
				return false;
			}

			return !!userPermissions[resource]?.includes(action);
		},

		some(...permissions) {
			return permissions.some((permission) => {
				let resource;
				let action;

				if (isObjectLiteral(permission)) {
					resource = permission.resource;
					action = permission.action;
				} else {
					[resource, action] = permission;
				}

				if (isArray(action)) {
					return action.some((a) => utils.has(resource, a));
				}

				return utils.has(resource, action);
			});
		},

		every(...permissions) {
			return permissions.every((permission) => {
				let resource;
				let action;

				if (isObjectLiteral(permission)) {
					resource = permission.resource;
					action = permission.action;
				} else {
					[resource, action] = permission;
				}

				if (isArray(action)) {
					return action.every((a) => utils.has(resource, a));
				}

				return utils.has(resource, action);
			});
		}
	};

	return utils;
};

export default Permissions;
