export const PROJECT_PRODUCT_TYPE = {
	CASINO: 1 << 0,
	SPORT: 1 << 1,
	OTHER: 1 << 2,
};

export const PROJECT_PRODUCT_TYPE_TRANSLATIONS = {
    [PROJECT_PRODUCT_TYPE.CASINO] : "backoffice.projects.casino",
    [PROJECT_PRODUCT_TYPE.SPORT] : "backoffice.projects.sport",
    [PROJECT_PRODUCT_TYPE.OTHER] : "backoffice.projects.other"
}

export default PROJECT_PRODUCT_TYPE;