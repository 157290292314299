import React, { useEffect, useRef, useState, memo } from "react";

import { useTranslation } from "core/hooks/useTranslation";
import useDevice from "core/hooks/useDevice";

import Modal from "core/ui-kit/modal";
import Button from "core/ui-kit/button";
import Icon from "core/ui-kit/icon";

import CommissionsPopupView from "./commissionsPopupView";
import CommissionsPopupAdd from "./commissionsPopupAdd";

import classNames from "core/helpers/common/classNames";

import { POPUP_SIZE } from "core/constants/common";

import { COMMISSION_POPUP_MODE } from "./constants";


const CommissionsPopup = ({
    isVisible,
    affiliateId,
    affiliateLongId,
    editable=false,
    onClose
}) => {

    const { t } = useTranslation();

    const _commissionsPopupAddRef = useRef(null);

    const { isMobile } = useDevice()

    const [mode, setMode] = useState(COMMISSION_POPUP_MODE.VIEW)

    const [selectedCommission, setSelectedCommission] = useState(null)

    useEffect(() => {
        if(isVisible){
            setMode(COMMISSION_POPUP_MODE.VIEW)
        }
    }, [isVisible])

    const okButtonProps = {
        hidden: true
    }

    const cancelButtonProps = {
        hidden: mode === COMMISSION_POPUP_MODE.ADD
    }

    const onAssignButtonClick = () => {
        _commissionsPopupAddRef.current.onAssignButtonClick()
    }

    return (
        <Modal
            open={isVisible}
            title={
                mode === COMMISSION_POPUP_MODE.VIEW ? t('backoffice.commissionplans.assignedCommissions') : t('backoffice.commissionplans.addCommission')
            }
            onCancel={onClose}
            cancelText={t("backoffice.common.close")}
            okText={t("backoffice.commissionplans.assign")}
            okButtonProps={okButtonProps}
            cancelButtonProps={cancelButtonProps}
            size={POPUP_SIZE.BIGEST}
            footer={mode === COMMISSION_POPUP_MODE.ADD ? (
                <div className={
                    classNames(
                        "rt--commissions-footer rt--flex rt--align-center rt--justify-between",
                        !isMobile && "rt--mt-24"
                    )
                }>
                    <Button
                        icon={<Icon name="left" />}
                        className="rt--button rt--button-main"
                        onClick={() => setMode(COMMISSION_POPUP_MODE.VIEW)}
                    >
                        { t("backoffice.common.back") }
                    </Button>
                    <div className="rt--flex rt--flex-equal rt--align-center rt--justify-end">
                        <Button
                            className="rt--button rt--button-ghost"
                            onClick={onClose}
                        >
                            { t("backoffice.common.close") }
                        </Button>
                        {
                            Boolean(selectedCommission) && (
                                <Button
                                    type="primary"
                                    className="rt--button rt--button-primary rt--ml-16"
                                    onClick={onAssignButtonClick}
                                >
                                    { t("backoffice.commissionplans.assign") }
                                </Button>
                            )
                        }
                    </div>
                </div>
            ) : null}
        >
            <div className="rt--commissions">
                {
                    mode === COMMISSION_POPUP_MODE.VIEW ? (
                        <CommissionsPopupView 
                            affiliateId={affiliateId}
                            affiliateLongId={affiliateLongId}
                            editable={editable}
                            setMode={setMode}
                        />
                    ) : (
                        <CommissionsPopupAdd
                            affiliateId={affiliateId}
                            setMode={setMode}
                            ref={_commissionsPopupAddRef}
                            onCommissionSelect={setSelectedCommission}
                        />
                    )
                }
            </div>
        </Modal>
    );    
}

export default memo(CommissionsPopup);