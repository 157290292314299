import React from "react";

import PropTypes from "prop-types";

import { useTranslation } from "core/hooks/useTranslation";
import useDevice from "core/hooks/useDevice";

import Input from "core/ui-kit/input";
import Icon from "core/ui-kit/icon";
import Dropdown from "core/ui-kit/dropdown";

import { projectsDropdownMenu } from "../../helpers";

import { INPUT_TYPE } from "core/constants/component/input";

const ProjectsDropdownMobile = ({
	opened,
	setOpened,
	projectSearch,
	onSearchChange,
	filteredProjects,
	preselectedProject,
	selectedCompany,
	onChange,
	onChangeToCompanies,
	selectedProject,
	onMenuChange,
	onOpenChange,
    disabled
}) => {
	const { t } = useTranslation();

    const { isMobile } = useDevice();

	const projectsMenuItems = projectsDropdownMenu({
		projects: filteredProjects,
		projectId: preselectedProject,
		t,
        isMobile
	});

	let menuItems = [
		{
			key: "title",
			disabled: true,
			label: (
                <div className="rt--companies-dropdown-menu-title">
                    <span className="rt--companies-dropdown-menu-title rt--title rt--font-big rt--font-bold">
                        {selectedCompany.name}
                    </span>
                </div>
			),
		},
		...projectsMenuItems,
	];
	let footer = {
		okText: t("backoffice.common.save"),
		onOk: () => {
			onChange({ key: preselectedProject });
			setOpened(false);
		},
		cancelText: t("backoffice.companies.changeCompany"),
		onCancel: onChangeToCompanies,
	};

	return (
		<Dropdown
			popupOpened={opened}
			forceRender
			menu={{
				items: menuItems,
				onClick: onMenuChange,
				style: {
					maxHeight: "200px",
				},
                selectedKeys: [preselectedProject]
			}}
			footer={footer}
			onOpenChange={onOpenChange}
			dropdownRender={(menu) => (
				<>
					<div className="rt--companies-dropdown-search rt--pl-8 rt--pr-8 rt--pt-8 rt--pb-8">
                        <Input 
                            type={INPUT_TYPE.SEARCH}
                            placeholder={t("backoffice.common.search")}
                            value={projectSearch} 
                            onChange={onSearchChange} 
                        />
					</div>
					{menu}
				</>
			)}
			title={`${t("backoffice.common.choose")} ${t("backoffice.common.project")}`}
            disabled={disabled}
		>
			<div className="rt--companies-dropdown-content rt--flex rt--align-center rt--justify-between rt--pl-12 rt--pr-4">
				<span className="rt--title rt--font-normal rt--font-bold rt--pr-4">
					{selectedProject?.name ?? ""}
				</span>
				{
                    !disabled && <Icon name="down-small" className="rt--font-bigest" />
                }
			</div>
		</Dropdown>
	);
};

ProjectsDropdownMobile.propTypes = {
	opened: PropTypes.bool,
	setOpened: PropTypes.func,
	projectSearch: PropTypes.string,
	onSearchChange: PropTypes.func,
	filteredProjects: PropTypes.array,
	preselectedProject: PropTypes.string,
	selectedCompany: PropTypes.object,
	onChange: PropTypes.func,
	onChangeToCompanies: PropTypes.func,
	selectedProject: PropTypes.object,
	onMenuChange: PropTypes.func,
	onOpenChange: PropTypes.func,
};

export default ProjectsDropdownMobile;
