import React from "react";

import Status from "components/common/status";

import { STATUS_TYPES } from "constants/status.constants";

export const getTableColumns = additionalProps => {
    const { t } = additionalProps;

    return [
        {
            title: "backoffice.jobs.name",
            dataIndex: "name",
            alwaysVisible: true,
            mobileLevel: 1,
            width: 300,
            tooltip: (_, record) => (
                <span>
                    <b>{t("backoffice.jobs.cronExpression") + ": "}</b>
                    <span>{record.cronExpression}</span>
                    <br />
                    <b>{t("backoffice.jobs.cronDescription") + ": "}</b>
                    <span>{record.cronDescription}</span>
                </span>
            ),
        },
        {
            title: "backoffice.jobs.state",
            dataIndex: "state",
            mobileLevel: 6,
            render: (state, record) => (
                <Status
                    type={STATUS_TYPES.JOB}
                    status={state}
                    tooltip={record.message}
                />
            ),
        },
        {
            title: "backoffice.jobs.elapsedMilliseconds",
            dataIndex: "elapsedMilliseconds",
            mobileLevel: 2,
        },
        {
            title: "backoffice.jobs.startTime",
            dataIndex: "startTime",
            isDateTime: true,
            mobileLevel: 3,
        },
        {
            title: "backoffice.jobs.nextFireTime",
            dataIndex: "nextFireTime",
            isDateTime: true,
            mobileLevel: 4,
        },
        {
            title: "backoffice.jobs.serverName",
            dataIndex: "serverName",
            mobileLevel: 5,
        },
    ]
};

export const expandColumns = [
    {
        title: "backoffice.jobs.state",
        dataIndex: "state",
        mobileLevel: 1,
        render: (state, record) => (
            <Status
                type={STATUS_TYPES.JOB}
                status={state}
                tooltip={record.message}
            />
        ),
    },
    {
        title: "backoffice.jobs.elapsedMilliseconds",
        dataIndex: "elapsedMilliseconds",
        mobileLevel: 2,
    },
    {
        title: "backoffice.jobs.startTime",
        dataIndex: "startTime",
        isDateTime: true,
        mobileLevel: 3,
    },
    {
        title: "backoffice.jobs.nextFireTime",
        dataIndex: "nextFireTime",
        isDateTime: true,
        mobileLevel: 4,
    },
    {
        title: "backoffice.jobs.serverName",
        dataIndex: "serverName",
        mobileLevel: 5,
    },
];
