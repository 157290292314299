import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Form, Row, Col, Spin, Switch, Button } from 'antd';

import TabFormDashboardLayout from "components/layouts/tab/form";
import Select from 'components/common/select';
import Input from 'components/common/input';
import Icon from 'components/common/icon';
import RichEditor from "components/common/richEditor";

import {
    getCommunicationTemplate,
    saveCommunicationTemplateTranslation,
    resetCommunicationTemplateTranslation,
    setTemplateSystemDefault
} from "store/actions/portal/communications/systemNotifications/email.action";
import { getSystemAvailableLanguages } from "store/actions/portal/common/common.action";
import { getProjectAvailableLanguages } from 'store/actions/portal/common/common.action';

import { isFormChanged } from 'utils/form';
import { isMobile } from 'utils/common';

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'core/constants/permission';
import { TRANSLATION_SOURCE_TYPE } from 'constants/translation.constants';

import usePermissions from 'core/hooks/usePermission';

const FIELDS_NAMES_PRESERVED_KEYS = {
    SUBJECT: "subject",
    CONTENT: "content"
}

/** System Notifications Page Email Info Tab Component */
const SystemNotificationEmail = ({
    isSaving,
    isLoading,
    templateInfo,
    systemDefaultTemplate,
    systemAvailableLanguages,
    projectAvailableLanguages,
    getCommunicationTemplate,
    getSystemAvailableLanguages,
    getProjectAvailableLanguages,
    saveCommunicationTemplateTranslation,
    resetCommunicationTemplateTranslation,
    setTemplateSystemDefault,
    onTabChange
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const [searchParams] = useSearchParams();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const [isFormTouched, setIsFormTouched] = useState(false);

    const [selectedLanguage, setSelectedLanguage] = useState(null);


    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasSystemTemplateViewPermission = permissionUtils.has( PERMISSION_RESOURCE.COMMUNICATION_SYSTEM_TEMPLATES, PERMISSION_ACTION.VIEW );
    const hasSystemTemplateModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.COMMUNICATION_SYSTEM_TEMPLATES, PERMISSION_ACTION.MODIFY );
    const hasProjectTemplateViewPermission = permissionUtils.has( PERMISSION_RESOURCE.COMMUNICATION_PROJECT_TEMPLATES, PERMISSION_ACTION.VIEW );
    const hasProjectTemplateModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.COMMUNICATION_PROJECT_TEMPLATES, PERMISSION_ACTION.MODIFY );

    //#region ------------------------------------- Languages ---------------------------------------//

    /** Load project available languages */
    useEffect(() => {
        if(!systemDefaultTemplate && Object.keys(projectAvailableLanguages).length === 0){
            getProjectAvailableLanguages()
        }
    }, [projectAvailableLanguages])

    /** Load System available languages */
    useEffect(() => {
        if(systemDefaultTemplate && Object.keys(systemAvailableLanguages).length === 0){
            getSystemAvailableLanguages()
        }
    }, [])

    /** Languages list */
    const { languageCodes = [], languagesOption = [] } = useMemo(() => {
        const languageCodes = Object.keys(systemDefaultTemplate ? systemAvailableLanguages : projectAvailableLanguages);

        return {
            languageCodes,
            languagesOption: languageCodes.map(language => ({
                text: t("backoffice.languages." + language),
                value: language
            }))
        }
    }, [systemDefaultTemplate, systemAvailableLanguages, projectAvailableLanguages]);

    /** Set default language code */
    useEffect(() => {
        if (languageCodes.length > 0) {
            setSelectedLanguage((prevLanguageCode) => {
                if (prevLanguageCode && languageCodes.find((languageCode) => languageCode === prevLanguageCode)) {
                    return prevLanguageCode;
                }

                return languageCodes[0];
            })
        }
    }, [languageCodes])

    //#endregion

    //#region ------------------------------------- Form Fields ---------------------------------------//
      
    const formInitialValues = useMemo(() => {
        return templateInfo.reduce((acc, section) => {
            section?.translations.forEach((translation) => {
                if (translation.languageCode.toLowerCase() === selectedLanguage?.toLowerCase()) {
                    acc[section.key] = translation.text;
                }
            })

            return acc;
        }, {});
    }, [templateInfo, selectedLanguage]);

    const formDynamicFieldsNames = useMemo(() => {
        return templateInfo.reduce((acc, section) => {
            Object.values(FIELDS_NAMES_PRESERVED_KEYS).forEach((preservedKey) => {
                if (section.key.endsWith(preservedKey)) {
                    acc[preservedKey] = section.key;
                }
            });

            return acc;
        }, { [FIELDS_NAMES_PRESERVED_KEYS.SUBJECT]: "", [FIELDS_NAMES_PRESERVED_KEYS.CONTENT]: "" });

    }, [templateInfo]);

    //#endregion

    //#region ------------------------------------- Handlers ---------------------------------------//

    const handleForm = () => {
        validateFields()
            .then(data => {
                saveCommunicationTemplateTranslation(
                    templateInfo.reduce(
                        (acc, section) => {
                            acc["keyValues"][section.key] =
                            data[
                                formDynamicFieldsNames[
                                    section.key.endsWith(FIELDS_NAMES_PRESERVED_KEYS.SUBJECT) ? FIELDS_NAMES_PRESERVED_KEYS.SUBJECT : FIELDS_NAMES_PRESERVED_KEYS.CONTENT
                                ]
                            ];

                    return acc;
                }, {
                    keyValues: {},
                    languageCode: selectedLanguage,
                    isSystemDefault: systemDefaultTemplate,
                    triggerType: searchParams.get("triggerType")
                }));

                setIsFormTouched(false);
            })
    };

    const handleReset = () => {
        resetCommunicationTemplateTranslation(
            {
                keys: templateInfo.map(section => section.key),
                languageCode: selectedLanguage,
                triggerType: searchParams.get("triggerType")
            }
        );
    }

    const handleSystemDefaultChange = isChecked => {
        if (isChecked && Object.keys(systemAvailableLanguages).length === 0) {
            getSystemAvailableLanguages()
        } else if (!isChecked && Object.keys(projectAvailableLanguages).length === 0) {
            getProjectAvailableLanguages();
        }
        setTemplateSystemDefault(isChecked);
    };

    //#endregion

    //#region ------------------------------------- Helpers ---------------------------------------//

    const canEdit = () => {
        if (systemDefaultTemplate && hasSystemTemplateModifyPermission) {
            return true;
        }
        else if (!systemDefaultTemplate && hasProjectTemplateModifyPermission) {
            return true;
        }
        
        return false;
    };

    const isSystemDefaultOverridden = useMemo(() => {
        const subjectKey = formDynamicFieldsNames.subject;
        const contentKey = formDynamicFieldsNames.content;
        if(!subjectKey || !contentKey) return false;
        const subjectTranslations = templateInfo.find(section => section.key === subjectKey);
        const contentTranslations = templateInfo.find(section => section.key === contentKey );
        const subjectTranslationSource = (subjectTranslations?.translations ?? []).find(translation => translation.languageCode.toLowerCase() === selectedLanguage?.toLowerCase())?.source;
        const contentTranslationSource = (contentTranslations?.translations ?? []).find(translation => translation.languageCode.toLowerCase() === selectedLanguage?.toLowerCase())?.source;
        return subjectTranslationSource === TRANSLATION_SOURCE_TYPE.OVERRIDEN || contentTranslationSource === TRANSLATION_SOURCE_TYPE.OVERRIDEN;
    }, [formDynamicFieldsNames, templateInfo])

    
    const isSystemDefaultSwitcherDisabled = () => {
        if (systemDefaultTemplate && hasProjectTemplateViewPermission) {
            return false;
        }
        else if (!systemDefaultTemplate && hasSystemTemplateViewPermission) {
            return false;
        }
        return true;
    };

    const getSectionTranslation = key => {
        const section = templateInfo.find((section) => section.key === key);

        const translations = section?.translations ?? [];

        return translations.find(
            (translation) => translation.languageCode.toLowerCase() === selectedLanguage?.toLowerCase()
        )?.text;
    };

    //#endregion

    /** Load System Communication Template info */
    useEffect(() => {
        if (languageCodes.length !== 0) {
            getCommunicationTemplate({
                isSystemDefault: systemDefaultTemplate,
                triggerType: searchParams.get("triggerType"),
                languageCodes
            });
        }
    }, [languageCodes]);
    
    /** Set form fields values, when data is loaded */
    useEffect(() => {
        if (templateInfo.length !== 0 && selectedLanguage) {
            setFieldsValue({
                [formDynamicFieldsNames.subject]: getSectionTranslation(formDynamicFieldsNames.subject) || "",
                [formDynamicFieldsNames.content]: getSectionTranslation(formDynamicFieldsNames.content) || ""
            });
        }
    }, [templateInfo, selectedLanguage, formDynamicFieldsNames]);


    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <TabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: true,
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={(_, formValues) => {
                        setIsFormTouched(
                            isFormChanged(
                                { ...formValues, registration_content: formValues.registration_content?.replace(/&nbsp;/g, " ")}, 
                                {...formInitialValues, registration_content: formInitialValues.registration_content?.replace(/&nbsp;/g, " ") }
                            )
                        )
                    }}
                >
                    <div className='rt--flex rt-align-center rt--justify-between'>
                        <Form.Item
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') }
                            ]}
                            style={{ "minWidth": isMobile() ? "50%" : "15%" }}
                            validateFirst
                        >
                            <Select
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.communication.language')}`}
                                options={languagesOption}
                                search={true}
                                value={selectedLanguage}
                                onChange={setSelectedLanguage}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            />
                        </Form.Item>
                        {
                            (
                                !systemDefaultTemplate && isSystemDefaultOverridden
                            ) && (
                                <Form.Item>
                                    <Button
                                        icon={<Icon name="reset" />}
                                        type="primary"
                                        htmlType="submit"
                                        className={'rt--button rt--button-main ' + (isMobile() ? 'rt--ml-8' : "rt--ml-16")}
                                        disabled={!canEdit()}
                                        onClick={handleReset}
                                    >
                                        {
                                            !isMobile() ? <span className='rt--font-normal'>{t('backoffice.common.resetToDefault')}</span> : null
                                        }
                                    </Button>
                                </Form.Item>
                            )
                        }
                    </div>

                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <div className={"rt--switcher rt--flex-inline rt--align-center rt--justify-between " + (isMobile() ? "rt--mb-12" : "rt--mb-16")}>
                                <Form.Item
                                    className={'rt--form-item-without-margin' + (isSystemDefaultSwitcherDisabled() ? " rt--form-item-disabled" : "")}
                                >
                                    <Switch
                                        disabled={isSystemDefaultSwitcherDisabled()}
                                        checked={systemDefaultTemplate}
                                        onChange={handleSystemDefaultChange}
                                    />
                                </Form.Item>
                                <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.communication.systemDefaultTemplate')}</label>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={t('backoffice.communication.emailSubject')}
                                name={formDynamicFieldsNames.subject}
                                rules={[
                                    { required: true, message: t('backoffice.validation.fieldRequired') }
                                ]}
                                className={'rt--general-form-item' + (!canEdit() ? ' rt--form-item-disabled' : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.communication.emailSubject')}`}
                                validateFirst
                            >
                                <Input
                                    disabled={!canEdit()}
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.communication.emailSubject')}`}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                className={'rt--general-form-item' + (!canEdit() ? ' rt--form-item-disabled' : "")}
                                name={formDynamicFieldsNames.content}
                            >
                                <RichEditor
                                    disabled={!canEdit()}
                                    enforceCharacterLimit={true}
                                    initialContent={getSectionTranslation(formDynamicFieldsNames.content) || ""}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </TabFormDashboardLayout>
    )
}

SystemNotificationEmail.propTypes = {
    /** Redux state property, is true when Email info is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when Email info is loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the Communication Template info of current editing System Notification */
    templateInfo: PropTypes.array,
    /** Redux state property, is true when system default template is enabled */
    systemDefaultTemplate: PropTypes.bool,
    /** Redux state property, represents the object of available system languages */
    systemAvailableLanguages: PropTypes.object,
    /** Redux state property, represents the object of project available  languages */
    projectAvailableLanguages: PropTypes.object,
    /** Redux action to get System Communication Template info */
    getCommunicationTemplate: PropTypes.func,
    /** Redux action to get system available languages */
    getSystemAvailableLanguages: PropTypes.func,
    /** Redux action to get project available languages */
    getProjectAvailableLanguages: PropTypes.func,
    /** Redux action to save System Notifications Email info */
    saveCommunicationTemplateTranslation: PropTypes.func,
    /** Redux action to reset System Notifications Email info */
    resetCommunicationTemplateTranslation: PropTypes.func,
    /** Redux action to set System Notifications Email Template system default */
    setTemplateSystemDefault: PropTypes.func,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getCommunicationTemplate: params => {
            dispatch(getCommunicationTemplate(params));
        },
        getSystemAvailableLanguages: () => {
            dispatch(getSystemAvailableLanguages())
        },
        saveCommunicationTemplateTranslation: params => {
            dispatch(saveCommunicationTemplateTranslation(params));
        },
        resetCommunicationTemplateTranslation: params => {
            dispatch(resetCommunicationTemplateTranslation(params));
        },
        setTemplateSystemDefault: systemDefault => {
            dispatch(setTemplateSystemDefault(systemDefault));
        },
        getProjectAvailableLanguages: () => {
            dispatch(getProjectAvailableLanguages())
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.systemNotifications.isSaving,
        isLoading: state.systemNotifications.isLoading,
        templateInfo: state.systemNotifications.edit.email.template,
        systemDefaultTemplate: state.systemNotifications.edit.email.systemDefault,
        systemAvailableLanguages: state.common.systemAvailableLanguages,
        projectAvailableLanguages: state.common.projectAvailableLanguages
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SystemNotificationEmail);