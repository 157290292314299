import React, { Fragment, useMemo, useState  } from "react";
import PropTypes from 'prop-types';

import { Select as AntSelect } from "antd";

import Icon from "core/ui-kit/icon";
import Checkbox from "core/ui-kit/checkbox";

import NotFound from "../notFound";
import Tag from "../tag";

import { useTranslation } from "core/hooks/useTranslation";

import { ALL_VALUE } from "../constants";

import { findOptionTextByValue, isOptionDisabled, isOptionSelected, filterAndSortOptions, getSelectValue, getNewSelectedValue } from "../helpers";
import isUndefined from "core/helpers/typeChecks/isUndefined";

const SelectDesktop = ({
    options=[],
    icon,
    isMultiple,
    disabled,
    maxMultipleSelectCount,
    search,
    value, 
    onChange,
    showSelectAllButton,
    ...rest
}) => {

    const { t } = useTranslation(); 

    const [searchValue, setSearchValue] = useState("");    

    const filteredOptions = useMemo(() => {
        return filterAndSortOptions(options, searchValue)
    }, [options, searchValue])

    const isSelectAllOptionVisible = (
        isMultiple &&
        showSelectAllButton &&
        filteredOptions.length > 1 &&
        searchValue === "" &&
        isUndefined(maxMultipleSelectCount) 
    );

    const internalValue = getSelectValue({
        value, isMultiple, options, isSelectAllOptionVisible
    });

    const multipleModeProps = isMultiple ? {
        showArrow: true,
        maxTagCount: "responsive",
        maxTagTextLength: 18,
        mode: "multiple",
        menuItemSelectedIcon: <Fragment />,
        tagRender: ({ value, onClose }) => {
            if (value === ALL_VALUE) {
                return;
            }

            return (
                <Tag
                    label={findOptionTextByValue(options, value)}
                    onClose={onClose}
                    disabled={disabled}
                />
            )
        }
    } : {};

    const searchModeProps = search ? {
        filterOption: () => true,
        onSearch: setSearchValue,
        onSelect: () => setSearchValue(""),
        onDeselect: () => setSearchValue(""),
        searchValue: searchValue
    } : {}

    const handleInternalChange = value => {
        const updatedValue = getNewSelectedValue({
            options,
            isSelectAllOptionVisible: isSelectAllOptionVisible,
            prevValue: internalValue,
            newValue: value
        })

        onChange(updatedValue);
    }

    return (
        <AntSelect
            suffixIcon={<Icon name="down" />}
            notFoundContent={<NotFound />}
            disabled={disabled}
            onChange={handleInternalChange}
            value={internalValue}
            showSearch={search}
            { ...multipleModeProps }
            { ...searchModeProps }
            { ...rest }
        >
            {
                isSelectAllOptionVisible && (
                    <AntSelect.Option
                        value={ALL_VALUE}
                        className="rt--select-all rt--mb-2"
                    >
                        <span className="rt--flex rt--align-center">
                            <Checkbox
                                className="rt--select-checkbox"
                                checked={value?.length === options.length}
                            />
                            <span className="rt--title rt--font-normal rt--font-regular">
                                {
                                    value?.length === options.length ? t("backoffice.common.deselectAll") : t("backoffice.common.selectAll")
                                }
                            </span>
                        </span>
                    </AntSelect.Option> 
                )
            }
            {
                filteredOptions.map(option => option.subs ? (
                    <AntSelect.OptGroup 
                        key={option.value || option.text} 
                        label={option.text}
                    >
                        {
                            option.subs.map(sub => (
                                <AntSelect.Option 
                                    key={sub.value || sub.text} 
                                    value={sub.value}
                                    disabled={isOptionDisabled(sub, value, maxMultipleSelectCount)}
                                >
                                    { sub.text }
                                </AntSelect.Option>
                            ))
                        }
                    </AntSelect.OptGroup>
                ) : (
                    <AntSelect.Option 
                        key={option.value || option.text}
                        value={option.value} 
                        disabled={isOptionDisabled(option, value, maxMultipleSelectCount)}
                    >
                        <span className="rt--flex rt--align-center">
                            {
                                isMultiple && (
                                    <Checkbox
                                        className="rt--select-checkbox"
                                        checked={isOptionSelected(option, internalValue, isMultiple)}
                                    />
                                )
                            }
                            <span className="rt--title rt--font-normal rt--font-regular">
                                {option.text}
                            </span>
                        </span>
                    </AntSelect.Option>
                ))
            }
        </AntSelect>
    )
} 

SelectDesktop.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        subs: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        }))
    })),
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.bool]),
    disabled: PropTypes.bool,
    search: PropTypes.bool,
    isMultiple: PropTypes.bool,
    showSelectAllButton: PropTypes.bool,
    icon: PropTypes.string,
    maxMultipleSelectCount: PropTypes.number
}

export default SelectDesktop;