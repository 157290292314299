export const getTableColumns = additionalProps => {

    const { t } = additionalProps;

    return [
        {
            title: "backoffice.communication.subject",
            dataIndex: "subject",
            sorter: true,
            alwaysVisible: true,
            mobileLevel: 1,
            short: true
        },
        {
            title: "backoffice.communication.newsletterName",
            dataIndex: "name",
            sorter: true,
            alwaysVisible: true,
            mobileLevel: 2
        },
        {
            title: "backoffice.communication.language",
            dataIndex: "languageCode",
            mobileLevel: 3,
            render: value => value ? t(`backoffice.languages.${value.toLowerCase()}`) : "-",
            sorter: true,
        },
        
        {
            title: "backoffice.communication.scheduleDate",
            dataIndex: "scheduledDate",
            isDateTime: true,
            sorter: true,
            mobileLevel: 4
        },
        {
            title: "backoffice.communication.createdAt",
            dataIndex: "createdAt",
            isDateTime: true,
            sorter: true,
            mobileLevel: 5
        },
        {
            title: "backoffice.communication.createdBy",
            dataIndex: "createdBy",
            sorter: true,
            mobileLevel: 6
        },
        {
            title: "backoffice.communication.lastUpdatedAt",
            dataIndex: "modifiedAt",
            isDateTime: true,
            sorter: true,
        },
        {
            title: "backoffice.communication.lastUpdatedBy",
            dataIndex: "modifiedBy",
            sorter: true,
            render: value => value || "-",
        },
    ]
};