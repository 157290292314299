import React from 'react';

import { useTranslation } from "core/hooks/useTranslation";

import Tabs from 'components/common/tabs';

import GeneralInfoComponent from '../edit/sections/general';
import UsersComponent from '../edit/sections/users';

import ROUTES from "core/constants/routes/routes";


import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "core/constants/permission";

/** Permission Group Edit Page Component */
const PermissionGroupEditComponent = () => {
    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.permissiongroups.general"),
            permissions: [{ resource: PERMISSION_RESOURCE.PERMISSION_GROUP, action: PERMISSION_ACTION.VIEW }],
            component: <GeneralInfoComponent/>
        },
        {
            title: t("backoffice.permissiongroups.admins"),
            permissions: [{ resource: PERMISSION_RESOURCE.PERMISSION_GROUP, action: PERMISSION_ACTION.VIEW }],
            component: <UsersComponent />
        }
    ]

    return (
        <Tabs 
            items={items}
            mainPage={{ title: t('backoffice.menu.permissionGroups'), path: `${ROUTES.PERMISSION_GROUPS}` }}
        />
    )
}


export default PermissionGroupEditComponent;