import ROUTES from "core/constants/routes/routes";

import MarketingReportsComponent from "pages/reports/marketing";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const MarketingReportsPageRoute = {
	path: ROUTES.MARKETING_REPORTS,
	element: MarketingReportsComponent, 
    permission: [
        { resource: PERMISSION_RESOURCE.REPORT_CLICKS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.REPORT_MEDIA_PERFORMANCE, action: PERMISSION_ACTION.VIEW }
    ]
};

export default MarketingReportsPageRoute;
