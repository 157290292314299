import ROUTES from "core/constants/routes/routes";

import UsersLogsComponent from "pages/userLogs/userLogs.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const UserLogsPageRoute = {
	path: ROUTES.USER_LOGS,
	element: UsersLogsComponent, 
    permission: { resource: PERMISSION_RESOURCE.USER_LOGS, action: PERMISSION_ACTION.VIEW }
};

export default UserLogsPageRoute;
