import React, { useState, Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useParams } from "react-router-dom";
import usePermissions from 'core/hooks/usePermission';

import EntityActions from "components/common/entityActions"
import SetPassword from "components/common/setPassword";
import CommissionsPopup from 'core/global/commissionsPopup';

import { 
    changeAffiliateInactiveState,
    changeAffiliateState,
    unlockAffiliate
} from "store/actions/portal/affiliates/affiliates/affiliates.action";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'core/constants/permission';
import { USER_STATE, USER_ROLE } from 'constants/user.constants';

import { personalInfoType } from "types/affiliate/generalInfo.type";

import { useGlobalSelector, userInfoSelector } from 'core/stores/globalStore';
import { useAuthSelector, userRoleSelector } from 'core/stores/authStore';

/** Affiliate Actions Button Component */
const AffiliateActions = ({
    personalInfo,
    changeAffiliateInactiveState,
    changeAffiliateState,
    unlockAffiliate
}) => {

    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const routeParams = useParams();

    const userInfo = useGlobalSelector(userInfoSelector);
    const role = useAuthSelector(userRoleSelector);

    const [showSetPasswordPopup, setShowSetPasswordPopup] = useState(false);

    const affiliateId = role === USER_ROLE.AFFILIATE ? userInfo.id : routeParams.id;

    const [ commissionsPopup, setCommissionsPopup ] = useState({
        isVisible: false
    });

    console.log({personalInfo})

    /** Actions list */
    const actions = [
        /** Set Password */
        {
            key: "setPassword",
            buttonType: "secondary",
            buttonClassName: "rt--button-secondary",
            title: t('backoffice.common.setPassword'),
            icon: "password",
            onClick: () => setShowSetPasswordPopup(true),
            disabled: (
                !permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE, PERMISSION_ACTION.MODIFY ) ||
                affiliateId === userInfo.id ||
                (personalInfo.state === USER_STATE.BLOCKED || personalInfo.state === USER_STATE.DECLINED)
            )
        },
        /** Approve */
        {
            key: "approve",
            buttonType: "secondary",
            title: t('backoffice.common.approve'),
            noteTitle: t('backoffice.common.reason'),
            okText: t('backoffice.common.approve'),
            icon: "success",
            buttonClassName: "rt--button-secondary",
            isStatusChange: true,
            onClick: (reason) => {
                changeAffiliateInactiveState({
                    id: affiliateId,
                    enabled: personalInfo.state === USER_STATE.IN_PROGRESS,
                    reason,
                    type: USER_STATE.ACTIVE
                })
            },
            disabled: (
                !permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE, PERMISSION_ACTION.MODIFY ) ||
                affiliateId === userInfo.id ||
                personalInfo.state !== USER_STATE.IN_PROGRESS
            )
        },
        /** Decline */
        {
            key: "decline",
            buttonType: "danger",
            title: t('backoffice.common.decline'),
            noteTitle: t('backoffice.common.reason'),
            okText: t('backoffice.common.decline'),
            icon: "decline",
            buttonClassName: "rt--button-danger",
            isStatusChange: true,
            onClick: (reason) => {
                changeAffiliateInactiveState({
                    id: affiliateId,
                    enabled: personalInfo.state === USER_STATE.IN_PROGRESS,
                    reason,
                    type: USER_STATE.DECLINED
                })
            },
            disabled: (
                !permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE, PERMISSION_ACTION.MODIFY ) ||
                affiliateId === userInfo.id ||
                personalInfo.state !== USER_STATE.IN_PROGRESS
            )
        },
        /** Block */
        {
            key: "block",
            buttonType: "secondary",
            buttonClassName: personalInfo.state === USER_STATE.BLOCKED ? "rt--button-secondary" : "rt--button-danger",
            title: personalInfo.state === USER_STATE.BLOCKED ? t('backoffice.common.unblock') : t('backoffice.common.block'),
            noteTitle: t('backoffice.common.reason'),
            okText: personalInfo.state === USER_STATE.BLOCKED ? t('backoffice.common.unblock') : t('backoffice.common.block'),
            isStatusChange: true,
            onClick: (reason) => {
                changeAffiliateState({
                    id: affiliateId,
                    enabled: false,
                    reason
                })
            },
            icon: "block",
            disabled: (
                !permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE, PERMISSION_ACTION.MODIFY ) ||
                affiliateId === userInfo.id ||
                personalInfo.state !== USER_STATE.ACTIVE
            )
        },
        /** Unblock */
        {
            key: "unblock",
            buttonClassName: "rt--button-secondary",
            buttonType: "secondary",
            title: t('backoffice.common.unblock'),
            icon: "block",
            onClick: () => changeAffiliateState({
                id: affiliateId,
                enabled: true,
                reason: "Unblock"
            }),
            isConfirmation: true,
            confirmationMessage: t('backoffice.affiliates.doYouWantToUnblock'),
            disabled: (
                !permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE, PERMISSION_ACTION.MODIFY ) ||
                affiliateId === userInfo.id ||
                personalInfo.state !== USER_STATE.BLOCKED
            )
        },
        /** Unlock */
        {
            key: "unlock",
            buttonClassName: "rt--button-secondary",
            buttonType: "secondary",
            title: t('backoffice.common.unlock'),
            icon: "lock",
            onClick: () => {
                unlockAffiliate(affiliateId)
            },
            isConfirmation: true,
            confirmationMessage: t('backoffice.affiliates.doYouWantToUnlock'),
            disabled: (
                !permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE, PERMISSION_ACTION.MODIFY ) ||
                affiliateId === userInfo.id ||
                (personalInfo.state !== USER_STATE.LOCKED_2_FA && personalInfo.state !== USER_STATE.LOCKED_LOGIN)
            )
        },

        /** Commissions Popup */
        {
            key: "commission",
            buttonClassName: "rt--button-main",
            buttonType: "primary",
            title: t('backoffice.affiliates.commissionsDetails'),
            icon: "commission",
            onClick: () => {
                setCommissionsPopup({
                    isVisible: true,
                })
            },
            disabled: !permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE_COMMISSION_PLAN, PERMISSION_ACTION.VIEW )
        },
    ]

    const hideCommissionsPopup = useCallback(() => {
        setCommissionsPopup({
            isVisible: false,
        })
    }, [setCommissionsPopup])

    return (
        <Fragment>
            <EntityActions
                actions={actions}
            />

            {
                showSetPasswordPopup && (
                    <SetPassword
                        onClose={() => setShowSetPasswordPopup(null)}
                    />
                )
            }

            {
                <CommissionsPopup 
                    isVisible={commissionsPopup.isVisible}
                    affiliateId={affiliateId}
                    affiliateLongId={personalInfo.longId}
                    editable={true}
                    onClose={hideCommissionsPopup}
                />
            }
        </Fragment>

    )
}

/** AffiliateActions propTypes
    * PropTypes
*/
AffiliateActions.propTypes = {
    /** Redux state, represents the general info of current editing Affiliate  */
    personalInfo: personalInfoType,
    /** Redux action to approve Affiliate */
    changeAffiliateInactiveState: PropTypes.func,
    /** Redux action to change Affiliate State */
    changeAffiliateState: PropTypes.func,
    /** Redux action to unlock affiliate */
    unlockAffiliate: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        changeAffiliateInactiveState: (data, onSuccess) => {
            dispatch(changeAffiliateInactiveState(data, onSuccess));
        },
        changeAffiliateState: (data, onSuccess) => {
            dispatch(changeAffiliateState(data, onSuccess));
        },
        unlockAffiliate: id => {
            dispatch(unlockAffiliate(id));
        }
    }
)

const mapStateToProps = state => {
    return {
        personalInfo: state.affiliates.edit.general.personal,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AffiliateActions)