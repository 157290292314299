import React, { Fragment } from "react"

import Icon from "core/ui-kit/icon";

import isUndefined from "core/helpers/typeChecks/isUndefined";
import isNull from "core/helpers/typeChecks/isNull";

const SelectTag = ({ 
    label, 
    onClose, 
    disabled 
}) => {

    const handleCloseIconClick = e => {
        if (disabled) {
            return;
        }
        onClose(e);
    }

    if(isUndefined(label) || isNull(label)){
        return <Fragment />
    }

    return (
        <div className="rt--tag rt--flex rt--justify-between rt--align-center rt--pl-8 rt--pr-2 rt--mr-4">
            <span className="rt--title rt--font-normal rt--font-regular rt--pr-4">
                { label }
            </span>
            <Icon name="close" size={16} onClick={handleCloseIconClick} />
        </div>
    );
}

export default SelectTag;