import React, { useState } from "react";
import PropTypes from "prop-types";

import Dropdown from "core/ui-kit/dropdown";
import Icon from "core/ui-kit/icon";

import { currenciesDropdownMenu, getAmount } from "../helpers";

import { WALLET_TYPE } from "core/constants/wallet";

import useFormat from "hooks/useFormat";

const DesktopCurrenciesDropdown = ({
    wallets,
    currencies,
    allowImpactOnCPA,
    selectedCurrency
}) => {

    const { formatAmount } = useFormat();

    const [dropdownOpened, setDropdownOpened] = useState(false);

    const menuItems = currenciesDropdownMenu(currencies, wallets, formatAmount, allowImpactOnCPA);

    return (
        <div className="rt--header-actions rt--header-item rt--header-actions-currencies rt--mr-16 rt--flex rt--align-center">
            <Dropdown 
                overlayClassName="rt--header-actions-currencies-dropdown"
                popupOpened={dropdownOpened}
                onOpenChange={setDropdownOpened}
                menu={{
                    items: menuItems,
                }}
            >
                <div className='rt--header-actions-content rt--flex rt--align-center rt--pl-8 rt--pr-8'>
                    <div className='rt--header-actions-content-icon rt--flex rt--align-center rt--justify-center rt--mr-12'>
                        <Icon name='wallet' />
                    </div>

                    {
                        wallets.map(wallet => (
                            <div className='rt--flex rt--flex-col rt--justify-center rt--align-start rt--mr-12 rt--header-actions-wallet' key={wallet.type}>
                                <span className='rt--title rt--font-small rt--font-regular'>{wallet.title}:</span>
                                <b className='rt--title rt--font-small'>
                                    {
                                        formatAmount(
                                            getAmount(wallet.type, selectedCurrency, currencies, allowImpactOnCPA), 
                                            selectedCurrency
                                        )
                                    }
                                </b>
                            </div>
                        ))
                    }


                    <b className='rt--title rt--font-normal'>
                        {selectedCurrency ?? ""}
                    </b>
                    <Icon name='down-small' className="rt--header-actions-content-arrow" />
                </div>
            </Dropdown>
        </div>
    )
}

DesktopCurrenciesDropdown.propTypes = {
	selectedCurrency: PropTypes.string,
	currencies: PropTypes.arrayOf(PropTypes.object),
	allowImpactOnCPA: PropTypes.bool,
	wallets: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			type: PropTypes.oneOf(Object.values(WALLET_TYPE)),
		}),
	)
};

export default DesktopCurrenciesDropdown;
