import React from 'react';

import DebugTimestamp from '../debugTimestamp';

const DebugChangeItem = ({storeName, propPath, timestamp, isSelected, status, onClick}) => {
    return (
        <div
            className={
                [
                    "rt--debug-content-change-list-content-item",
                    isSelected ? "rt--debug-content-change-list-content-item__selected" : "",
                    status ? `rt--debug-content-change-list-content-item__${status}` : ""
                ].join(' ')
            }
            onClick={onClick}
        >
            <span>{storeName} {status !== 'init' ?` > ${propPath}` : null}</span>
            <DebugTimestamp timestamp={timestamp} />
        </div>
    );
};

export default DebugChangeItem;
