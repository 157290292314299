import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useParams } from "react-router-dom";
import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Form, Row, Col } from 'antd';

import Modal from "components/common/modal";
import Input from 'components/common/input';
import NumericInput from 'components/common/numericInput';
import Select from 'components/common/select';
import Tooltip from 'components/common/tooltip';
import ImageUploader from 'components/common/imageUploader';
import HTMLEditor from 'components/common/htmlEditor';
import Icon from 'components/common/icon';

import {
    createBannerCreative,
    createHTMLCreative,
    updateBannerCreative,
    updateHTMLCreative,
    getCreativeHTML
} from "store/actions/portal/marketingTools/campaigns/creatives.action";

import { POPUP_SIZE } from 'constants/common.constants';
import { NAME_REGEX } from 'constants/regex.constants';
import { CREATIVE_HTML_SIZES, CREATIVE_IMAGE_FORMATS, CREATIVE_TYPE } from 'constants/campaign.constants';

import { humanFileSize, isMobile, makeImagePath } from 'utils/common';
import { isFormChanged } from "utils/form";

import creativeType from 'types/campaign/creative.type';

/** Creative Creating/Editing Popup Component */
const CreativeCreateEditComponent = ({
    isSaving,
    createBannerCreative,
    createHTMLCreative,
    updateBannerCreative,
    updateHTMLCreative,
    editingCreative,
    getCreativeHTML,
    htmls,
    onClose
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const searchParams = useParams();

    const [creativeType, setCreativeType] = useState(null);

    const [htmlFrameSize, setHtmlFrameSize] = useState(null);

    const [fileDetails, setFileDetails] = useState(null);

    const [isFormTouched, setIsFormTouched] = useState(!Boolean(editingCreative));

    /** Set Default Values */
    useEffect(() => {
        if (editingCreative) {
            setCreativeType(editingCreative.type);
            const values = {
                type: editingCreative.type,
                name: editingCreative.name
            }
            const width = Number(editingCreative.dimension.split("x")[0]);
            const height = Number(editingCreative.dimension.split("x")[1])

            if (editingCreative.type === CREATIVE_TYPE.IMAGE) {
                setFileDetails({
                    size: editingCreative.size,
                    type: CREATIVE_IMAGE_FORMATS[editingCreative.imageType],
                    width: width,
                    height: height
                });
                values.file = new File(["rt--old"], "rt--old")
            } else {

                const preDefinedDimension = CREATIVE_HTML_SIZES.find(size => size.width === width && size.height === height);
                if (preDefinedDimension) {
                    values.htmlFrameSize = editingCreative.dimension;
                } else {
                    values.htmlFrameSize = "0x0";
                    values.width = width;
                    values.height = height;
                }
                setHtmlFrameSize(values.htmlFrameSize);

                if (htmls[editingCreative.id]) {
                    values.HTML = htmls[editingCreative.id];
                } else {
                    getCreativeHTML(editingCreative.id, data => {
                        setFieldsValue({ "HTML": data })
                    });
                }
            }
            setTimeout(() => {
                setFieldsValue(values)
            }, 0)
        }
    }, [])

    /** Fires when form submitted
       * @function
       * @memberOf CreativeCreateComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                const d = {};
                d.name = data.name;
                d.campaignId = searchParams.id;
                if (editingCreative) {
                    d.id = editingCreative.id;
                }

                if (creativeType === CREATIVE_TYPE.IMAGE) {
                    if (editingCreative) {
                        if (data.file.name !== "rt--old") {
                            d.file = data.file;
                        }
                    } else {
                        d.file = data.file;
                    }
                    if (editingCreative) {
                        updateBannerCreative(d, onClose);
                    } else {
                        createBannerCreative(d, onClose);
                    }
                } else {
                    d.HTML = data.HTML;
                    if (data.htmlFrameSize) {
                        if (data.htmlFrameSize === "0x0") {
                            d.width = Number(data.width);
                            d.height = Number(data.height);
                        } else {
                            d.width = Number(data.htmlFrameSize.split("x")[0]);
                            d.height = Number(data.htmlFrameSize.split("x")[1]);
                        }
                    }
                    if (editingCreative) {
                        updateHTMLCreative(d, onClose);
                    } else {
                        createHTMLCreative(d, onClose);
                    }

                }
            }).catch(ex => {
                console.log(ex)
            })
    }


    const handleValuesChange = values => {
        if (!editingCreative) return;

        if (editingCreative.type === CREATIVE_TYPE.IMAGE) {
            if (values.file?.name !== "rt--old") {
                setIsFormTouched(true)
            } else {
                setIsFormTouched(values.name !== editingCreative.name);
            }
        } else {
            const initialValues = {
                width: Number(editingCreative.dimension.split("x")[0]),
                height: Number(editingCreative.dimension.split("x")[1]),
                name: editingCreative.name,
                HTML: htmls[editingCreative.id]
            }

            const formValues = {
                width: Number(values.width),
                height: Number(values.height),
                name: values.name,
                HTML: values.HTML
            }
            setIsFormTouched(isFormChanged(initialValues, formValues));
        }
    }

    const webInfo = "300x200, 728x90, 160x600, 300x600, 970x250, 125x125, 468x60, 336x280, 120x600, 234x60, 970x90";
    const mobileInfo = "250x250, 300x100, 300x50, 320x320, 320x100, 320x50, 320x480, 360x640";

    const onFileReady = file => {
        const img = new Image();
        const _URL = window.URL || window.webkitURL;
        const objectUrl = _URL.createObjectURL(file);
        img.onload = function () {
            setFileDetails({
                size: file.size,
                type: file.type,
                width: this.width,
                height: this.height
            });
            _URL.revokeObjectURL(objectUrl);
        };
        img.src = objectUrl;
    }

    return (
        <Modal
            title={editingCreative ? t('backoffice.campaigns.editCreative') : t('backoffice.campaigns.createCreative')}
            cancelText={t('backoffice.common.cancel')}
            okText={editingCreative ? t('backoffice.common.save') : t('backoffice.common.create')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            disableOkButton={!isFormTouched}
            size={POPUP_SIZE.BIGER}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                onValuesChange={(_, values) => handleValuesChange(values)}
            >
                <Row gutter={[16, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label={`${t('backoffice.campaigns.name')} *`}
                            name="name"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { max: 30, message: t('backoffice.validation.fieldInvalid') },
                                { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.campaigns.name')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.campaigns.name')}`}
                                maxLength={30}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={`${t('backoffice.campaigns.creativeType')} *`}
                            name="type"
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') },
                            ]}
                            className={editingCreative ? "rt--form-item-disabled" : ""}
                        >
                            <Select
                                options={[
                                    { value: CREATIVE_TYPE.IMAGE, text: t('backoffice.campaigns.banner') },
                                    { value: CREATIVE_TYPE.HTML, text: t('backoffice.campaigns.bannerHTML') }
                                ]}
                                onChange={value => setCreativeType(value)}
                                placeholder={`${t("backoffice.common.select")} ${t('backoffice.campaigns.creativeType')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                disabled={Boolean(editingCreative)}
                            />
                        </Form.Item>
                    </Col>

                    {
                        creativeType === CREATIVE_TYPE.IMAGE ? (
                            <Fragment>
                                <Col span={24}>
                                    <div className='rt--info rt--pl-16 rt--pr-16 rt--pt-12 rt--pb-12'>
                                        <span className='rt--text-secondary rt--font-small rt--font-bold'>
                                            {t("backoffice.campaigns.recommendedDimensions")}
                                        </span>
                                        <div className='rt--flex rt--align-center rt--mt-12'>
                                            <div className='rt--flex rt--align-center rt--mr-16'>
                                                <Icon name='desktop'/>
                                                <span className='rt--text-secondary rt--font-normal rt--font-bold rt--pl-8 rt--pr-4'>{t("backoffice.campaigns.web")}</span>
                                                <Tooltip
                                                    title={webInfo}
                                                    trigger={["hover", "click"]}
                                                    placement="bottomLeft"
                                                    enableMobile={true}
                                                >
                                                    <Icon name='info' size={20} />
                                                </Tooltip>
                                            </div>
                                            <div className='rt--flex rt--align-center rt--mr-16'>
                                                <Icon name='mobile' />
                                                <span className='rt--text-secondary rt--font-normal rt--font-bold rt--pl-8 rt--pr-4'>{t("backoffice.campaigns.mobile")}</span>
                                                <Tooltip
                                                    title={mobileInfo}
                                                    trigger={["hover", "click"]}
                                                    placement="bottomLeft"
                                                    enableMobile={true}
                                                >
                                                    <Icon name='info' size={20} />
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label=""
                                        name="file"
                                        rules={[
                                            { required: true, message: t('backoffice.validation.fieldRequired') },
                                        ]}
                                    >
                                        <ImageUploader
                                            size={3 * 1024 * 1024}
                                            onFileReady={onFileReady}
                                            defaultFile={editingCreative?.filePath ? {
                                                url: makeImagePath(editingCreative.filePath),
                                                status: "done",
                                                percent: 100
                                            } : null}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <div className={'rt--flex' + (isMobile() ? " rt--flex-col" : " rt--align-center")}>
                                        <div className='rt--flex rt--align-center'>
                                            <span className='rt--text-secondary rt--font-normal rt--font-regular'>
                                                {`${t("backoffice.campaigns.dimension")}:`}
                                            </span>
                                            <span className='rt--text-secondary rt--font-normal rt--font-bold rt--pl-4'>
                                                {fileDetails ? `${fileDetails.width}px x ${fileDetails.height}px` : " - "}
                                            </span>
                                        </div>
                                        <div className={'rt--flex rt--align-center ' + (!isMobile() ? "rt--ml-16" : "rt--mt-4")}>
                                            <span className='rt--text-secondary rt--font-normal rt--font-regular'>
                                                {`${t("backoffice.campaigns.size")}:`}
                                            </span>
                                            <span className='rt--text-secondary rt--font-normal rt--font-bold rt--pl-4'>
                                                {fileDetails?.size ? humanFileSize(fileDetails?.size) : " - "}
                                            </span>
                                        </div>
                                        <div className={'rt--flex rt--align-center ' + (!isMobile() ? "rt--ml-16" : "rt--mt-4")}>
                                            <span className='rt--text-secondary rt--font-normal rt--font-regular'>
                                                {`${t("backoffice.campaigns.fileType")}:`}
                                            </span>
                                            <span className='rt--text-secondary rt--font-normal rt--font-bold rt--pl-4'>
                                                {fileDetails?.type ?? " - "}
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                            </Fragment>
                        ) : creativeType === CREATIVE_TYPE.HTML ? (
                            <Fragment>
                                <Col xs={24} sm={12} >
                                    <Form.Item
                                        label={`${t('backoffice.campaigns.htmlFrameSize')} *`}
                                        name="htmlFrameSize"
                                        rules={[
                                            { required: true, message: t('backoffice.validation.fieldRequired') },
                                        ]}
                                    >
                                        <Select
                                            options={
                                                [
                                                    { value: "0x0", text: t('backoffice.campaigns.htmlCustomSize') },
                                                    ...CREATIVE_HTML_SIZES.map(size => (
                                                        { value: `${size.width}x${size.height}`, text: `${size.width}x${size.height}` }
                                                    ))
                                                ]

                                            }
                                            onChange={value => setHtmlFrameSize(value)}
                                            placeholder={`${t("backoffice.common.select")} ${t('backoffice.campaigns.htmlFrameSize')}`}
                                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                        />
                                    </Form.Item>
                                </Col>
                                {
                                    htmlFrameSize === "0x0" && (
                                        <Fragment>
                                            <Col xs={12} sm={6}>
                                                <Form.Item
                                                    label={`${t('backoffice.campaigns.htmlCustomSize')} *`}
                                                    name="width"
                                                    rules={[
                                                        { required: true, message: t('backoffice.validation.fieldRequired') },
                                                    ]}
                                                    className='rt--general-form-item'
                                                    data-placeholder={t('backoffice.campaigns.width')}
                                                >
                                                    <NumericInput
                                                        placeholder={t('backoffice.campaigns.width')}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={12} sm={6}>
                                                <Form.Item
                                                    label=" "
                                                    name="height"
                                                    rules={[
                                                        { required: true, message: t('backoffice.validation.fieldRequired') },
                                                    ]}
                                                    className='rt--general-form-item'
                                                    data-placeholder={t('backoffice.campaigns.height')}
                                                >
                                                    <NumericInput
                                                        placeholder={t('backoffice.campaigns.height')}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Fragment>
                                    )
                                }
                                <Col span={24}>
                                    <Form.Item shouldUpdate noStyle>
                                        {({ getFieldValue }) => {
                                            return (
                                                <Form.Item
                                                    label=""
                                                    name="HTML"
                                                    rules={[
                                                        { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                                        { max: 100000, message: t('backoffice.validation.fieldInvalid') },
                                                        { min: 10, message: t('backoffice.validation.fieldInvalid') },
                                                    ]}
                                                    validateFirst
                                                    className="rt--form-item-without-margin"
                                                >
                                                    <HTMLEditor
                                                        name={getFieldValue("name")}
                                                        size={
                                                            htmlFrameSize ? htmlFrameSize === "0x0" ? `${getFieldValue("width")}x${getFieldValue("height")}` : htmlFrameSize : undefined
                                                        }
                                                    />
                                                </Form.Item>
                                            )
                                        }}
                                    </Form.Item>
                                </Col>
                            </Fragment>
                        ) : null
                    }
                </Row>
            </Form>
        </Modal >
    )
}

/** CreativeCreateEditComponent propTypes
    * PropTypes
*/
CreativeCreateEditComponent.propTypes = {
    /** Redux state property, is true when creating campaign request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to create banner creative */
    createBannerCreative: PropTypes.func,
    /** Redux action to create html creative */
    createHTMLCreative: PropTypes.func,
    /** Redux action to update banner creative */
    updateBannerCreative: PropTypes.func,
    /** Redux action to update html creative */
    updateHTMLCreative: PropTypes.func,
    /** Fires on popup close */
    onClose: PropTypes.func,
    /** Current editing creative */
    editingCreative: creativeType,
    /** Redux state property, Creative htmls */
    htmls: PropTypes.objectOf(PropTypes.string),
    /** Redux action to get creative html */
    getCreativeHTML: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        createBannerCreative: (creative, onSuccess) => {
            dispatch(createBannerCreative(creative, onSuccess));
        },

        createHTMLCreative: (creative, onSuccess) => {
            dispatch(createHTMLCreative(creative, onSuccess));
        },

        updateBannerCreative: (creative, onSuccess) => {
            dispatch(updateBannerCreative(creative, onSuccess));
        },

        updateHTMLCreative: (creative, onSuccess) => {
            dispatch(updateHTMLCreative(creative, onSuccess));
        },

        getCreativeHTML: (id, onSuccess) => {
            dispatch(getCreativeHTML(id, onSuccess))
        },
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.campaigns.isSaving,
        htmls: state.campaigns.edit.creatives.htmls,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreativeCreateEditComponent)