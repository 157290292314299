import ROUTES from "core/constants/routes/routes";

import CompanyEditComponent from "pages/companies/edit/company-edit.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const CompanyEditPageRoute = {
	path: `${ROUTES.COMPANIES_EDIT}/:id`,
	element: CompanyEditComponent, 
    permission: [
        { resource: PERMISSION_RESOURCE.COMPANY_SECURITY_SETTINGS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.VIEW }
    ]
};

export default CompanyEditPageRoute;
