import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";

import { Input, Button, Form, Row, Col } from 'antd';

import NumericInput from "components/common/numericInput";
import Tooltip from "components/common/tooltip";
import Icon from "components/common/icon";

import { INFINITY, MINUS_INFINITY } from "constants/commission.constants";


/** Numeric Ranges Component */
const NumericRanges = ({
    formInstance,
    isPercent,
    fieldName,
    editable,
    maxPercent,
    disabled,
    hideHeader,
    minRangesCount = 0,
    label,
    isInteger
}) => {
    const { t } = useTranslation();

    const invalidFieldsNameRef = useRef([]);

    const { validateFields, getFieldValue, setFieldsValue } = formInstance;

    /** Function, to validate ranges
       * @function
       * @param {array} ranges
       * @returns {bool} value
       * @memberOf NumericRanges
   */
    const validateRanges = ranges => {
        if (ranges.length < minRangesCount) {
            return Promise.reject(t('backoffice.common.minRangeCountIs') + " " + minRangesCount);
        }

        for (let i = 0; i < ranges.length; i++) {
            if (
                ([null, ""].includes(ranges[i].from) && i !== 0) ||
                ([null, ""].includes(ranges[i].to) && i !== ranges.length - 1) ||
                ([null, ""].includes(ranges[i].value)) ||
                (ranges[i].from >= ranges[i].to && ranges[i].to !== INFINITY)
            ) {
                invalidFieldsNameRef.current = [`${i + 1} from`, `${i} to`];

                if (Number(ranges[i].value) > Number(ranges[i + 1]?.value)) {
                    invalidFieldsNameRef.current = [`${i + 1} value`];
                }

                if (Number(ranges[i].value) > maxPercent || ranges[i].value === "") {
                    invalidFieldsNameRef.current = [`${i} value`];
                }

                return Promise.reject(t('backoffice.common.invalidRanges'));
            }

            if ((!isPercent && ranges[i].to !== INFINITY && Number(ranges[i].value) > Number(ranges[i].to) * maxPercent / 100)) {
                invalidFieldsNameRef.current = [`${i} value`];
                return Promise.reject(`${t('backoffice.common.amount')} ${t('backoffice.validation.mustBeLess').replace("%X%", maxPercent + "%")}`)
            }
        }

        invalidFieldsNameRef.current = [];
        return Promise.resolve();
    }

    /** Function, to validate percent
       * @function
       * @param {array} rangesArr
       * @returns {bool} value
       * @memberOf NumericRanges
   */
    const validatePercent = ranges => {
        if (!isPercent) {
            return Promise.resolve();
        }

        const max = maxPercent ? maxPercent : 100;

        for (let i = 0; i < ranges.length; i++) {
            if (
                ranges[i].value === "" ||
                Number(ranges[i].value) < 0 ||
                Number(ranges[i].value) > max
            ) {
                invalidFieldsNameRef.current = [`${i} value`];
                return Promise.reject(
                    `${t('backoffice.common.percent')} ${t('backoffice.validation.mustBeBetween').replace("%X%", 0).replace("%Y%", max)}`
                )
            }
        }

        invalidFieldsNameRef.current = [];
        return Promise.resolve();
    }

    /** Function, fires on Range change
       * @function
       * @param {string} field
       * @param {number} index
       * @param {string} value
       * @memberOf NumericRanges
   */
    const onRangeChange = (field, index, value) => {
        setTimeout(() => {
            const ranges = getFieldValue(fieldName);
            if (field === "from") {
                if (index !== 0) {
                    if (value !== "" && value !== null && value !== MINUS_INFINITY) {
                        ranges[index - 1].to = Number(value);
                    } else {
                        ranges[index - 1].to = "";
                    }
                }
            } else if (field === "to") {
                if (index !== ranges.length - 1) {
                    if (value !== "" && value !== null && value !== INFINITY) {
                        ranges[index + 1].from = Number(value);
                    } else {
                        ranges[index + 1].from = "";
                    }
                }
            }

            setFieldsValue({ fieldName: ranges })

            setTimeout(() => validateFields([fieldName]), 20)

        }, 20)
    }

    /** Function, fires on Range change
       * @function
       * @memberOf NumericRanges
   */
    const onInputBlur = () => {
        const ranges = getFieldValue(fieldName);
        for (let i = 0; i < ranges.length; i++) {
            ranges[i].value = ranges[i].value || 0
        }
        setFieldsValue({ fieldName: ranges })
        setTimeout(() => validateFields([fieldName]), 20)
    }

    /** Function, fires on Add
       * @function
       * @memberOf NumericRanges
   */
    const onAdd = () => {
        setTimeout(() => {
            const ranges = getFieldValue(fieldName);
            ranges[ranges.length - 2].to = "";

            setFieldsValue({ fieldName: ranges })

            setTimeout(() => validateFields([fieldName]), 20)
        }, 20)
    }

    /** Function, fires on remove
      * @function
      * @param {number} index
      * @memberOf NumericRanges
  */
    const onRemove = index => {
        setTimeout(() => {
            const ranges = getFieldValue(fieldName);
            const oldRangesLength = ranges.length + 1;
            if (oldRangesLength - 1 === index) {
                ranges[ranges.length - 1].to = INFINITY
            } else if (index === 0) {
                ranges[0].from = MINUS_INFINITY
            } else {
                if (ranges[index - 1].to !== "" && ranges[index - 1].to !== null && ranges[index - 1].to !== INFINITY) {
                    ranges[index].from = Number(ranges[index - 1].to);
                } else if (ranges[index].from !== "" && ranges[index].from !== null && ranges[index].from !== MINUS_INFINITY) {
                    ranges[index - 1].to = Number(ranges[index].from);
                } else {
                    ranges[index].from = "";
                    ranges[index - 1].to = "";
                }

            }
            setFieldsValue({ fieldName: ranges })

            setTimeout(() => validateFields([fieldName]), 20)
        }, 20)
    }

    const getNewRangeFrom = () => {
        const ranges = getFieldValue(fieldName);
        if (ranges.length == 0) {
            return 0
        }
        const lastTo = ranges[ranges.length - 1].to;

        if (lastTo === "" || lastTo === null || lastTo === INFINITY) {
            return ""
        }

        return Number(lastTo) + 1
    }

    return (
        <div className='rt--numeric-ranges-wrapper'>
            {
                label && <h4 className='rt--text-secondary rt--numeric-ranges-label rt--font-small rt--font-bold'>{label}</h4>
            }
            <Form.List
                name={fieldName}
                rules={[
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            return validatePercent(value);
                        }
                    }),
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            return validateRanges(value);
                        }
                    })
                ]}
                validateFirst
            >
                {(fields, { add, remove }, { errors }) => {
                    return (
                        <div className='rt--numeric-ranges'>
                            {
                                !hideHeader && (
                                    <div className='rt--flex rt--align-center rt--mb-8'>
                                        <div className={'rt--numeric-ranges-header rt--flex rt--align-centers' + (!editable ? " rt--numeric-ranges-header-editable" : "")}>
                                            <div
                                                className='rt--numeric-ranges-header-min rt--flex rt--align-center'
                                            >
                                                <span className='rt--title rt--font-small rt--font-bold'>{t("backoffice.common.min")}</span>
                                            </div>
                                            <div
                                                className='rt--numeric-ranges-header-max rt--flex rt--align-center'
                                            >
                                                <span className='rt--title rt--font-small rt--font-bold'>{t("backoffice.common.max")}</span>
                                                <Tooltip
                                                    title={t("backoffice.commissionplans.amountWillBeIncludedInMaxField")}
                                                    trigger={["hover", "click"]}
                                                    placement="bottomLeft"
                                                    enableMobile={true}
                                                >
                                                    <Icon name="info" size={16} className="rt--ml-2" />
                                                </Tooltip>
                                            </div>
                                            <div
                                                className='rt--numeric-ranges-header-value rt--flex rt--align-center'
                                            >
                                                <span className='rt--title rt--font-small rt--font-bold'>{
                                                    isPercent ? t("backoffice.common.percent") : t("backoffice.common.amount")
                                                }</span>
                                            </div>
                                        </div>
                                        {
                                            editable && (
                                                <div className='rt--numeric-ranges-header-delete rt--ml-16 rt--flex rt--align-center' />
                                            )
                                        }
                                    </div>
                                )
                            }

                            {
                                fields.map((field, index) => (
                                    <Row gutter={[16, 0]} key={index}>
                                        <Col span={24}>
                                            <Input.Group
                                                compact
                                                className='rt--numeric-ranges-group rt--flex rt--align-center'
                                            >
                                                <div className='rt--numeric-ranges-group-ranges rt--flex rt--align-center rt--flex-equal'>
                                                    <Form.Item
                                                        name={[index, "from"]}
                                                        className={'rt--form-item-without-margin rt--numeric-ranges-left rt--mr-8 rt--general-form-item' + (!editable || index === 0 ? " rt--form-item-disabled" : "")}
                                                        data-placeholder={`${t('backoffice.common.from')}`}
                                                        validateStatus={invalidFieldsNameRef.current.includes(`${index} from`) ? "error" : undefined}
                                                    >

                                                        <NumericInput
                                                            placeholder={`${t('backoffice.common.from')}`}
                                                            disabled={!editable || index === 0}
                                                            onChange={e => onRangeChange("from", index, e)}
                                                            maxLength={9}
                                                            isInteger={isInteger}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name={[index, "to"]}
                                                        className={'rt--form-item-without-margin rt--numeric-ranges-right rt--mr-8 rt--general-form-item' + (!editable || index === fields.length - 1 ? " rt--form-item-disabled" : "")}
                                                        data-placeholder={`${t('backoffice.common.to')}`}
                                                        validateStatus={invalidFieldsNameRef.current.includes(`${index} to`) ? "error" : undefined}
                                                    >

                                                        <NumericInput
                                                            placeholder={`${t('backoffice.common.to')}`}
                                                            disabled={ !editable || index === fields.length - 1}
                                                            onChange={e => onRangeChange("to", index, e)}
                                                            maxLength={9}
                                                            isInteger={isInteger}
                                                        />

                                                    </Form.Item>
                                                    <Form.Item
                                                        name={[index, "value"]}
                                                        className={'rt--form-item-without-margin rt--numeric-ranges-value rt--general-form-item' + ( disabled ? " rt--form-item-disabled" : "")}
                                                        validateStatus={invalidFieldsNameRef.current.includes(`${index} value`) ? "error" : undefined}
                                                    >

                                                        <NumericInput
                                                            placeholder={isPercent ? t('backoffice.common.percent') : t('backoffice.common.amount')}
                                                            disabled={disabled}
                                                            onBlur={onInputBlur}
                                                            onChange={() => setTimeout(() => validateFields([fieldName]), 20)}
                                                            maxLength={8}
                                                        />

                                                    </Form.Item>
                                                </div>

                                                {
                                                    editable && (
                                                        <div
                                                            className={
                                                                'rt--numeric-ranges-delete rt--ml-16 rt--flex rt--align-center' + (fields.length <= 1 ? " rt--numeric-ranges-delete-hidden" : "")
                                                            }
                                                            onClick={() => {
                                                                if (fields.length > 1) {
                                                                    remove(index);
                                                                    onRemove(index)
                                                                }
                                                            }}
                                                        >
                                                            <Icon name="close"/>
                                                        </div>
                                                    )
                                                }

                                            </Input.Group>

                                        </Col>
                                    </Row>
                                ))
                            }
                            {
                                errors && errors.length > 0 && (
                                    <span className="rt--flex rt--error-text rt--title rt--font-normal rt--font-regular rt--pb-8" >{errors[0]}</span>
                                )
                            }
                            {
                                editable && (
                                    <Button
                                        icon={<Icon name="plus" />}
                                        type="secondary"
                                        htmlType="button"
                                        className="rt--button rt--button-secondary rt--numeric-ranges-add"
                                        onClick={() => {
                                            add({
                                                from: getNewRangeFrom(),
                                                to: INFINITY,
                                                value: 0
                                            });
                                            onAdd()
                                        }}
                                    >
                                        {t('backoffice.common.addRange')}
                                    </Button>
                                )
                            }

                        </div>
                    )
                }}
            </Form.List>
        </div>
    )
}

/** NumericRanges propTypes
    * PropTypes
*/
NumericRanges.propTypes = {
    /** Form instance object */
    formInstance: PropTypes.object,
    /** Is the field percent */
    isPercent: PropTypes.bool,
    /** The field Name */
    fieldName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    /** Are the ranges editable */
    editable: PropTypes.bool,
    /** Maximum possible percent */
    maxPercent: PropTypes.number,
    /** Are the ranges value fields disabled */
    disabled: PropTypes.bool,
    /** Hide the header */
    hideHeader: PropTypes.bool,
    /** Minimum count of ranges */
    minRangesCount: PropTypes.number,
    /** The label of component */
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    /** Should the ranges be integer */
    isInteger: PropTypes.bool,
}

export default NumericRanges;