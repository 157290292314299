import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "core/hooks/useTranslation";

import Dropdown from "core/ui-kit/dropdown";
import Icon from "core/ui-kit/icon";

import translationService from "core/services/translationService";
import useDevice from "core/hooks/useDevice";

import { currenciesSelector, currencySelector, globalStore, languagesSelector, useGlobalSelector, userInfoSelector } from "core/stores/globalStore";
import { authStore, useAuthSelector, userSelector } from "core/stores/authStore";

import ROUTES from "core/constants/routes/routes";

import setLocale from "core/helpers/language/setLocale";

import { PROFILE_DROPDOWN_TYPE } from "./constants";
import { buildAvatarPath, profileDropdownMenus } from "./helpers";

import useUnsavedChangesConfirmation from "hooks/useUnsavedChangesConfirmation";
import { UNSAVED_FORM_PAGE_TYPE } from "constants/common.constants";

const ProfileDropdown = () => {

    const { locale, t } = useTranslation();

    const navigate = useNavigate();

    const { isMobile } = useDevice()

    const currentUser = useAuthSelector(userSelector)

    const currencies = useGlobalSelector(currenciesSelector);
    const currency = useGlobalSelector(currencySelector)
    const languages = useGlobalSelector(languagesSelector);
    const userInfo = useGlobalSelector(userInfoSelector);

    const [activeDropdown, setActiveDropdown] = useState(PROFILE_DROPDOWN_TYPE.DEFAULT);
    const [dropdownOpened, setDropdownOpened] = useState(false);

    const mainMenus = Object.values(PROFILE_DROPDOWN_TYPE);
    const activeMenuItems = profileDropdownMenus({
		languages,
        currencies,
        currency,
        currentUser,
        locale,
		t,
        isMobile
	})[activeDropdown];

    const avatarPath = buildAvatarPath({
		userRole: currentUser.role,
		userLongId: userInfo?.longId,
		avatarId: userInfo?.avatarId,
		projectLongId: currentUser.projectLongId,
		companyLongId: userInfo?.mainCompanyLongId
	});

    const navigateWithConfirmation = useUnsavedChangesConfirmation({
        cb: navigate,
        subscribe: [UNSAVED_FORM_PAGE_TYPE.TAB, UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    const logoutWithConfirmation = useUnsavedChangesConfirmation({
        cb: authStore.logout.bind(authStore),
        subscribe: [UNSAVED_FORM_PAGE_TYPE.TAB, UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    const handleMenuClick = ({ key }) => {
		if (mainMenus.includes(key)) {
			return setActiveDropdown(key);
		}

        if(key === "divider"){
            return;
        }

		if (key !== "back") {
			if (key === "logout") {
				logoutWithConfirmation();
			}

			if (key === "profile") {
				navigateWithConfirmation(ROUTES.PROFILE);
			}

			let [menuType, menuKey] = key.split(".");

			if (menuType === "currency") {
				globalStore.changeCurrency(menuKey);
			}

			if (menuType === "language") {
                translationService.changeLocale(menuKey).then(changedLocal => {
                    setLocale(changedLocal)
                })
			}

			setDropdownOpened(false);
		}

		setActiveDropdown(PROFILE_DROPDOWN_TYPE.DEFAULT);
	};

    const handleDropdownOpenChange = open => {
        setDropdownOpened(open);
        if(open){
            setActiveDropdown(PROFILE_DROPDOWN_TYPE.DEFAULT);
        }
	};


    return (
        <div className='rt--header-actions'>
            <Dropdown 
                trigger={["click"]}
				className="rt--dropdown-menu-with-back"
                popupOpened={dropdownOpened}
                onOpenChange={handleDropdownOpenChange}
                menu={{ 
                    items: activeMenuItems, 
                    onClick: handleMenuClick 
                }}
            >
                {
                    isMobile ? (
                        <div className='rt--header-avatar rt--flex rt--align-center rt--justify-center'>
                            <span className='rt--title rt--font-normal rt--font-bold'>{currentUser.userName?.substring(0, 2)}</span>
                        </div>
				    ) : (
                        <div className="rt--header-actions-content rt--flex rt--align-center">
                            <div className="rt--header-avatar rt--flex rt--align-center rt--justify-center rt--mr-8">
                                {
                                    userInfo?.avatarId ? (
                                        <img alt="avatar" src={avatarPath} />
                                    ) : (
                                        <Icon name="user-avatar" size={20} />
                                    )
                                }
                            </div>
                            <span className='rt--header-actions-name rt--title rt--font-normal rt--font-bold rt--pr-4'>
                                {
                                    userInfo?.firstName || userInfo?.lastName ?
                                        `${userInfo?.firstName ?? ""} ${userInfo?.lastName ?? ""}` :
                                        currentUser.userName
                                }
                            </span>
                            <Icon
                                name="down-small"
                                className="rt--font-bigest rt--header-actions-content-arrow"
                            />
                        </div>
				    )
                }
            </Dropdown>
        </div>
    )
}

export default ProfileDropdown;