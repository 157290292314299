import ROUTES from "core/constants/routes/routes";

import TranslationsComponent from "pages/cms/translations/translations.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

import { TRANSLATION_TYPE } from "constants/translation.constants";

const BOTranslationsPageRoute = {
	path: ROUTES.TRANSLATIONS_BO,
	element: TranslationsComponent, 
    permission: { resource: PERMISSION_RESOURCE.TRANSLATION, action: PERMISSION_ACTION.VIEW },
    props: {
        translationType: TRANSLATION_TYPE.BO
    }
};

export default BOTranslationsPageRoute;
