import { message } from 'antd';

import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setPermissionGroupsActionBefore, setPermissionGroupsActionFinished, setPermissionGroupsSaveActionBefore, setPermissionGroupsSaveActionFinished } from './permissionGroups.action';

import { 
    SET_PERMISSION_GROUP_USERS,
    SET_PERMISSION_GROUP_USERS_SORTING,
    SET_PERMISSION_GROUP_USERS_FILTERS
} from "../../../../actionTypes";

const setPermissionGroupUsers = users => ({
    type: SET_PERMISSION_GROUP_USERS,
    payload: { users }
})

export const setPermissionGroupUsersSorting = sorting => ({
    type: SET_PERMISSION_GROUP_USERS_SORTING,
    payload: { sorting },
});

export const setPermissionGroupUsersFilters = filters => ({
    type: SET_PERMISSION_GROUP_USERS_FILTERS,
    payload: { filters },
});

export const getPermissionGroupUsers = (id, nextPage ) => {
    return (dispatch, getState) => {
        const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().permissionGroups.edit.users.sorting.page + 1 : getState().permissionGroups.edit.users.sorting.page;
        
        let params = {
            ...getState().permissionGroups.edit.users.sorting,
            ...getState().permissionGroups.edit.users.filters,
            id: id
        };

        if(page > 1 && getState().permissionGroups.edit.users.total <= (page - 1 ) * getState().permissionGroups.edit.users.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && getState().permissionGroups.edit.users.total === 0){
            return Promise.resolve();
        }

        dispatch(setPermissionGroupsActionBefore());
        return axios({
            url: ApiUrls.GET_PERMISSION_GROUP_USERS,
            method: Methods.GET,
            params: params
        })
        .then(({data : {value: users}}) => {
            dispatch(setPermissionGroupUsers(users))
            dispatch(setPermissionGroupsActionFinished());
        })
        .catch((ex) => {
            dispatch(setPermissionGroupsActionFinished());
        })
    }
}

export const addPermissionGroupUsers = (ids, groupId, note, onSuccess) => {
    return dispatch => {
        dispatch(setPermissionGroupsSaveActionBefore());
        return axios({
            url: ApiUrls.ADD_PERMISSION_GROUP_USERS,
            method: Methods.POST,
            data: {
                ids,
                groupId,
                note
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getPermissionGroupUsers(groupId));
                onSuccess && onSuccess();
            } 
            dispatch(setPermissionGroupsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setPermissionGroupsSaveActionFinished());
        })
    }
}

export const deletePermissionGroupUser = (id, groupId, note) => {
    return dispatch => {
        dispatch(setPermissionGroupsActionBefore());
        return axios({
            url: ApiUrls.DELETE_PERMISSION_GROUP_USER,
            method: Methods.DELETE,
            data: {
                id, 
                groupId,
                note
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getPermissionGroupUsers(groupId));
            } 
            dispatch(setPermissionGroupsActionFinished());
        })
        .catch((ex) => {
            dispatch(setPermissionGroupsActionFinished());
        })
    }
}
