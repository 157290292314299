import { message } from 'antd';

import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { 
    SET_PERMISSION_REQUESTS
} from "../../../../actionTypes";

import { 
    setPermissionRequestsActionBefore, 
    setPermissionRequestsActionFinished, 
    setPermissionRequestsSaveActionBefore,
    setPermissionRequestsSaveActionFinished
} from "./requests.action";

const setPermissionRequests = requests => ({
    type: SET_PERMISSION_REQUESTS,
    payload: { requests }
})

export const getPermissionRequests = (objectId, actionTypes, objectType) => {
    return dispatch => {
        dispatch(setPermissionRequestsActionBefore());

        let actionTypesStr = "";

        if (actionTypes?.length > 0) {
            actionTypes.forEach(type => {
                actionTypesStr += `${actionTypes !== "" ? "&" : ""}actionTypes=${type}`
            })
        }

        return axios({
            url: `${ApiUrls.GET_PERMISSION_REQUESTS}?${actionTypesStr}`,
            method: Methods.GET,
            params: {
                objectId, objectType
            }
        })
        .then(({data : {value: requests}}) => {
            dispatch(setPermissionRequests(requests))
            dispatch(setPermissionRequestsActionFinished());
        })
        .catch((ex) => {
            dispatch(setPermissionRequestsActionFinished());
        })
    }
}

export const approvePermissionRequest = id => {
    return dispatch => {
        dispatch(setPermissionRequestsSaveActionBefore());
        return axios({
            url: ApiUrls.APPROVE_PERMISSION_REQUEST,
            method: Methods.POST,
            data: {
                id
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setPermissionRequests(data.value));
            } 
            dispatch(setPermissionRequestsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setPermissionRequestsSaveActionFinished());
        })
    }
}

export const rejectPermissionRequest = (id, note) => {
    return dispatch => {
        dispatch(setPermissionRequestsSaveActionBefore());
        return axios({
            url: ApiUrls.REJECT_PERMISSION_REQUEST,
            method: Methods.POST,
            data: {
                id, note
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setPermissionRequests(data.value));
            } 
            dispatch(setPermissionRequestsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setPermissionRequestsSaveActionFinished());
        })
    }
}