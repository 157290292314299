import getLocale from "core/helpers/language/getLocale"
import isString from "core/helpers/typeChecks/isString";

import DEFAULT_LOCAL from "core/constants/language/defaultLocale"

class TranslationService{
    constructor(locale){
        this.locale = locale || DEFAULT_LOCAL;
        this._handlers = [];
		this._translations = {};
    }

    init() {
		return this.fetchLocale(this.locale).then(() => this.triggerHandlers());
	}

    async changeLocale(locale){
        if(!this._translations[locale]){
            await this.fetchLocale(locale)
        }

        this.locale = locale;
        this.triggerHandlers();

		return this.locale;
    }

    async fetchLocale(locale) {
		let translations = {};

		try {
			const response = await fetch(
				`${import.meta.env.SYSTEM_CDN_URL}/system/translations/backoffice_${locale?.toLowerCase()}.json`,
			);

			translations = await response.json();
		} catch (e) {
			console.error(`Failed to fetch translations for locale "${locale}":`, e);
			translations = null;
		}

        if(translations){
            this._translations[locale] = translations;
        }
	}

    triggerHandlers() {
		this._handlers.forEach((handler) => handler(this.locale));
	}

    subscribe(handler) {
		this._handlers.push(handler);
	}

	unsubscribe(handler) {
		this._handlers = this._handlers.filter((h) => h !== handler);
	}

    translate(key){
        if(!key) return "";
        
        const segments = key.split(".");

		let current = this._translations[this.locale];

        if (!current) {
			return key;
		}

        for (let segment of segments) {
			if (current[segment]) {
				current = current[segment];
			} else {
				return key;
			}
		}

        if (isString(current)) {
			return current;
		}

        return key;
    }
}

const locale = getLocale()

export default new TranslationService(locale)