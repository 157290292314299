import React from 'react';

import { useTranslation } from "core/hooks/useTranslation";

import Icon from "components/common/icon";

const TableEmptyView = () => {
    const { t } = useTranslation();
    
    return (
        <div className="rt--empty-table">
            <Icon name="empty" size={60} />
            <h5 className="rt--font-bold rt--font-bigest">
                {t('backoffice.common.noData')}
            </h5>
            <span className="rt--title rt--font-bold rt--font-normal">
                {t('backoffice.common.noDataFound')}
            </span>
        </div>
    );
}
 
export default TableEmptyView;