import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";

import {
    SET_USER_LOGS_ACTION_BEFORE,
    SET_USER_LOGS_ACTION_FINISH,
    SET_USER_LOGS,
    SET_USER_LOGS_SORTING,
    SET_USER_LOGS_FILTERS,
    SET_USER_LOG_DETAILS,
    SET_USER_LOG_RESOURCES
} from "../../../actionTypes";

export const setUserLogsActionBefore = () => ({
    type: SET_USER_LOGS_ACTION_BEFORE,
});

export const setUserLogsActionFinished = () => ({
    type: SET_USER_LOGS_ACTION_FINISH,
});


const setUserLogs = (userLogs, add) => ({
    type: SET_USER_LOGS,
    payload: { userLogs, add },
});

export const setUserLogsSorting = sorting => ({
    type: SET_USER_LOGS_SORTING,
    payload: { sorting },
});

export const setUserLogsFilters = filters => ({
    type: SET_USER_LOGS_FILTERS,
    payload: { filters },
});

const setUserLogDetails = (id, details) => ({
    type: SET_USER_LOG_DETAILS,
    payload: { id, details }
})

const setUserLogResources = resources => ({
    type: SET_USER_LOG_RESOURCES,
    payload: { resources }
})


export const getUserLogs = nextPage => {
    return (dispatch, getState) => {
        
        const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().userLogs.sorting.page + 1 : getState().userLogs.sorting.page;
        let params = {
            ...getState().userLogs.sorting,
            ...getState().userLogs.filters,
            page: page
        };

        if(page > 1 && getState().userLogs.total <= (page - 1 ) * getState().userLogs.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && getState().userLogs.total === 0){
            return Promise.resolve();
        }
        
        dispatch(setUserLogsActionBefore());

        return axios({
            url: ApiUrls.GET_USER_LOGS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: logs } }) => {
                dispatch(setUserLogs(logs, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setUserLogsSorting({ ...getState().userLogs.sorting, page: page }));
                dispatch(setUserLogsActionFinished());
            })
            .catch((ex) => {
                dispatch(setUserLogsActionFinished());
            });
    }
}

export const getUserLogDetails = id => {
    return (dispatch, getState) => {

        return axios({
            url: ApiUrls.GET_USER_LOG_DETAILS,
            method: Methods.GET,
            params: { 
                id, 
                year: getState().userLogs.filters.year, 
                month: getState().userLogs.filters.month 
            }
        })
        .then(({data : {value: data}}) => {
            dispatch(setUserLogDetails(id, data));
        })
    }
}

export const getUserLogResources = () => {
    return dispatch => {

        return axios({
            url: ApiUrls.GET_USER_LOG_RESOURCES,
            method: Methods.GET
        })
        .then(({data : {value: resources}}) => {
            dispatch(setUserLogResources(resources));
        })
    }
}
