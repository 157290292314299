import browserLocalStorage from "core/services/storageService/localStorage";

const setLocale = locale => {

    browserLocalStorage.set("locale", locale);

    const urlSearch = new URLSearchParams(window.location.search);
    urlSearch.set("language", locale);

    const url = window.location.origin + window.location.pathname + "?" + urlSearch.toString();

    window.history.pushState({ path: url }, "", url);
}

export default setLocale;