import React, { useMemo, memo } from "react";

import { useTranslation } from "core/hooks/useTranslation";
import useDevice from "core/hooks/useDevice";

import Table from "core/ui-kit/table";

import { COMMISSION_TYPE } from "core/constants/commission/type";
import { COMMISSION_STRUCTURE } from "core/constants/commission/structure";

const Ranges = ({
    commission
}) => {

    const { t } = useTranslation();

    const { isMobile } = useDevice()

    const columns = useMemo(() => {
        return [
            {
                title: t("backoffice.common.min"),
                dataIndex: "from",
                render: value => <Table.Cell.Numeric value={value} />,
                mobileConfigs: {
                    order: 1
                },
            },

            {
                title: t("backoffice.common.max"),
                dataIndex: "to",
                render: value => value ? <Table.Cell.Numeric value={value} /> : "∞",
                mobileConfigs: {
                    order: 2
                },
            },

            {
                title: commission?.type === COMMISSION_TYPE.REVSHARE ? t("backoffice.common.percent") : t("backoffice.common.amount"),
                dataIndex: "value",
                render: value => commission?.type === COMMISSION_TYPE.REVSHARE ? `${value}%` : value,
                mobileConfigs: {
                    order: 3
                },
            }
        ]
    }, [t, commission])

    const ranges = commission.type === COMMISSION_TYPE.REVSHARE ? (
        commission.revShareConfiguration?.range ?? []
    ) : commission.type === COMMISSION_TYPE.CPA ? (
        commission.cpaConfiguration?.range ?? []
    ) : [];

    const showRanges = ranges.length > 0 && ( commission.type === COMMISSION_TYPE.CPA || ( commission.type === COMMISSION_TYPE.REVSHARE && commission.structure !== COMMISSION_STRUCTURE.FLAT ))

    if(!showRanges){
        return null;
    } 

    return (
        <div className="rt--mb-16">
            <div className="rt--commissions-details-title rt--pt-16 rt--pb-16">
                <span className="rt--font-small rt--font-regular">
                    {
                        commission.type === COMMISSION_TYPE.CPA || commission.revShareConfiguration?.structure === COMMISSION_STRUCTURE.PLAYER_TIERED ? t('backoffice.commissionplans.playerRanges') : 
                        commission.revShareConfiguration?.structure === COMMISSION_STRUCTURE.PROGRESSIVE ? t('backoffice.commissionplans.sourceRanges') : 
                        commission.revShareConfiguration?.structure === COMMISSION_STRUCTURE.PLAYER_LIFETIME ? t('backoffice.commissionplans.playerLifetimeMonth') : ""
                    }
                </span>
            </div>
            {
                !isMobile ? (
                    <Table
                        data={ranges}
                        columns={columns}
                    />
                ) : (
                    <div className="rt--commissions-details-ranges">
                        <div className="rt--commissions-details-ranges-header rt--commissions-details-ranges-row rt--flex rt--align-center">
                            {
                                columns.map(column => (
                                    <div className="rt--commissions-details-ranges-col rt--flex-equal rt--pl-16 rt--pr-16 rt--pt-12 rt--pb-12" key={column.dataIndex}>
                                        <span className="rt--font-small">{ column.title }</span>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="rt--commissions-details-ranges-content">
                            {
                                ranges.map(range => (
                                    <div className="rt--commissions-details-ranges-row rt--flex rt--align-center" key={range.from}>
                                        {
                                            columns.map(column => (
                                                <div className="rt--commissions-details-ranges-col rt--flex-equal rt--pl-16 rt--pr-16 rt--pt-14 rt--pb-14" key={column.dataIndex}>
                                                    <span className="rt--font-small">
                                                        {
                                                            column.render(range[column.dataIndex])
                                                        }
                                                    </span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )
            }
            
        </div>
    )

}

export default memo(Ranges);