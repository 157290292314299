export const yAxisTextDefaultFormatter = (value) => {
    return value + ' - '
}

export const customizeIndicationLine = ({ element, color }) => {
    const chart = element.series.chart;
    const x = element.plotX + chart.plotLeft;
    const y1 = element.plotY + chart.plotTop;
    const y2 = chart.plotHeight + chart.plotTop;

    if (chart.customcrosshair) {
        chart.customcrosshair.destroy();
    }

    chart.customcrosshair = chart.renderer
        .path()
        .attr({
            "stroke-width": 1.5,
            "stroke-dasharray": 7.5,
            stroke: color,
        })
        .add();

    chart.customcrosshair.attr({
        d: ["M", x, y1, "L", x, y2],
    });
};


export const changeRGBAOpacityToGivenNumber = ({ rgba, opacity }) => {
    const arrOfRGBAnumbers = rgba.match(/(\d+)/g);

    if (!arrOfRGBAnumbers) {
        return null;
    }

    const colorBytes = arrOfRGBAnumbers.slice(0, 3);
    colorBytes.push(opacity)

    const strOfChangedRGBANumbers = colorBytes.join(", ");

    return `rgba(${strOfChangedRGBANumbers})`;
}

export const validateRGBA = (inputString) => {
    const regexPattern = /^rgba\(\s*((1?\d?\d|25[0-5]|2[0-4]\d)(\s*,\s*(1?\d?\d|25[0-5]|2[0-4]\d)){2})\s*,\s*(0?\.\d+|1(\.0+)?|\.\d+|0(\.0+)?)\s*\)$/;

    return regexPattern.test(inputString);
}
