import { lazy } from "react";

import Spin from "core/ui-kit/spin";

import withSuspenseHoc from "core/HOCs/withSuspense";

import ROUTES from "core/constants/routes/routes";

const PostbackLogsContainer = withSuspenseHoc(lazy(() => import("./postbackLogsContainer")), <Spin />);

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

import isPostbackLogsEnabled from "core/helpers/postback/isPostbackLogsEnabled";

const PostbackLogsPageRoute = {
	path: ROUTES.POSTBACK_LOGS,
	element: PostbackLogsContainer, 
    permission: { resource: PERMISSION_RESOURCE.POSTBACK_LOGS, action: PERMISSION_ACTION.VIEW },
    isProjectRequired: true,
    isDisabled: ( projectId, user, userInfo ) => !isPostbackLogsEnabled(projectId, user, userInfo)
};

export default PostbackLogsPageRoute;
