import React from "react";

import { Drawer as AntDrawer } from "antd";

const Drawer = ({ 
    children, 
    ...rest 
}) => {
	return (
        <AntDrawer 
            { ...rest }
        >
            {
                children
            }
        </AntDrawer>
    );
};

export default Drawer;
