import { message } from 'antd';

import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { isMobile } from "utils/common";

import {
    SET_AFFILIATE_GROUPS_ACTION_BEFORE,
    SET_AFFILIATE_GROUPS_ACTION_FINISH,
    SET_AFFILIATE_GROUPS_SAVE_ACTION_BEFORE,
    SET_AFFILIATE_GROUPS_SAVE_ACTION_FINISH,
    SET_AFFILIATE_GROUPS,
    SET_AFFILIATE_GROUPS_SORTING,
    SET_AFFILIATE_GROUPS_FILTERS,
    SET_AFFILIATE_GROUP_AVAILABLE_AFFILIATES
} from "../../../../actionTypes";

export const setAffiliateGroupsActionBefore = () => ({
    type: SET_AFFILIATE_GROUPS_ACTION_BEFORE,
});

export const setAffiliateGroupsActionFinished = () => ({
    type: SET_AFFILIATE_GROUPS_ACTION_FINISH,
});

export const setAffiliateGroupsSaveActionBefore = () => ({
    type: SET_AFFILIATE_GROUPS_SAVE_ACTION_BEFORE,
});

export const setAffiliateGroupsSaveActionFinished = () => ({
    type: SET_AFFILIATE_GROUPS_SAVE_ACTION_FINISH,
});

const setAffiliateGroups = (groups, add) => ({
    type: SET_AFFILIATE_GROUPS,
    payload: { groups, add },
});

export const setAffiliateGroupsSorting = sorting => ({
    type: SET_AFFILIATE_GROUPS_SORTING,
    payload: { sorting },
});

export const setAffiliateGroupsFilters = filters => ({
    type: SET_AFFILIATE_GROUPS_FILTERS,
    payload: { filters },
});

const setAvailableAffiliates = affiliates => ({
    type: SET_AFFILIATE_GROUP_AVAILABLE_AFFILIATES,
    payload: { affiliates },
});

export const getAffiliateGroups = nextPage => {
    return (dispatch, getState) => {
        
        const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().affiliateGroups.sorting.page + 1 : getState().affiliateGroups.sorting.page;
        let params = {
            ...getState().affiliateGroups.sorting,
            ...getState().affiliateGroups.filters,
            page: page
        };

        if(page > 1 && getState().affiliateGroups.total <= (page - 1 ) * getState().affiliateGroups.sorting.limit){
            return Promise.resolve();
        }
        
        dispatch(setAffiliateGroupsActionBefore());

        return axios({
            url: ApiUrls.GET_AFFILIATE_GROUPS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: affiliateGroups } }) => {

                dispatch(setAffiliateGroups(affiliateGroups, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setAffiliateGroupsSorting({ ...getState().affiliateGroups.sorting, page: page }));
                dispatch(setAffiliateGroupsActionFinished());
            })
            .catch((ex) => {
                dispatch(setAffiliateGroupsActionFinished());
            });
    }
}

export const createAffiliateGroup = (data, onSuccess) => {
    return (dispatch) => {
        dispatch(setAffiliateGroupsSaveActionBefore());

        return axios({
            url: ApiUrls.CREATE_AFFILIATE_GROUP,
            method: Methods.POST,
            data: { 
                ... data,
            }
        })
            .then(({ data }) => {
                dispatch(setAffiliateGroupsSaveActionFinished());
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    onSuccess && onSuccess(data.value);
                }
            })
            .catch(() => {
                dispatch(setAffiliateGroupsSaveActionFinished());
            });
    };
};

export const getAvailableAffiliates = filters => {
    return dispatch => {

        return axios({
            url: ApiUrls.GET_AFFILIATE_GROUP_AVAILABLE_MEMBERS,
            method: Methods.GET,
            params: filters,
        })
            .then(({ data: { value: affiliates } }) => {
                dispatch(setAvailableAffiliates(affiliates));
            })
            .catch(Function.prototype);
    }
}

export const resetAvailableAffiliates = () => {
    return dispatch => {
        dispatch(setAvailableAffiliates([]))
    }
}

export const deleteAffiliateGroup = id => {
    return dispatch => {
        dispatch(setAffiliateGroupsSaveActionBefore());
        return axios({
            url: ApiUrls.REMOVE_AFFILIATE_GROUP,
            method: Methods.DELETE,
            data: {
                id
            },
        })
            .then(({ data }) => {
                dispatch(setAffiliateGroupsSaveActionFinished());
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    dispatch(getAffiliateGroups());
                }
            })
            .catch(() => {
                dispatch(setAffiliateGroupsSaveActionFinished());
            });
    };
}