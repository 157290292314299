import ROUTES from "core/constants/routes/routes";

import AffiliatesEdit from "pages/affiliates/affiliates/edit/affiliate-edit.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const AffiliateEditPageRoute = {
	path: `${ROUTES.AFFILIATES_EDIT}/:id`,
	element: AffiliatesEdit, 
    permission: [
        { resource: PERMISSION_RESOURCE.AFFILIATE, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.AFFILIATE_GENERALINFO, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.AFFILIATE_SESSIONS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.AFFILIATE_PAYMENT_METHODS, action: PERMISSION_ACTION.VIEW }
    ]
};

export default AffiliateEditPageRoute;
