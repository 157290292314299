import isNull from "core/helpers/typeChecks/isNull";

const isObject = (value) => {
	if (isNull(value)) {
		return false;
	}

	return typeof value === "object";
};

export default isObject;
