import React, { Fragment, useCallback, useEffect, useState } from "react";

import { useTranslation } from "core/hooks/useTranslation";

import Spin from "core/ui-kit/spin";

import Actions from "../actions";
import Details from "../details";
import Navigation from "../navigation";
import Empty from "../empty";

import Confirmation from "components/common/confirmation";

import commissionService from "core/services/apiServices/commissionService";

import { COMMISSION_POPUP_MODE } from "../constants";

import showSuccess from "core/helpers/common/showSuccess";

const CommissionsPopupView = ({
    affiliateId,
    affiliateLongId,
    editable,
    setMode
}) => {

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [commissions, setCommissions] = useState([]);

    const [confirmationModal, setConfirmationModal] = useState({
        isVisible: false,
        onOk: Function.prototype
    });

    const [selectedCommission, setSelectedCommission] = useState(null);

    const [negativeCarryOver, setNegativeCarryOver] = useState(false);

    useEffect(() => {
        commissionService.getAffiliateCommissions(affiliateLongId).
            then(({ data }) => {
                setCommissions(data);
                if(data.length > 0){
                    setSelectedCommission(data[0])
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    useEffect(() => {
        commissionService.getNegativeCarryOver(affiliateId).
            then( value => {
                setNegativeCarryOver(value.isNegativeCarryOverEnabled);
            })
    }, [])

    const handleNegativeCarryOverChange = value => {
        commissionService.saveNegativeCarryOver(affiliateId, value)
            .then(({ value, message}) => {
                setNegativeCarryOver(value.isNegativeCarryOverEnabled);
                showSuccess(message)
            })
    }

    const removeCommission = useCallback(() => {
        setIsLoading(true);
        commissionService.removeCommission(selectedCommission.id, affiliateId)
            .then(({message}) => {
                showSuccess(message);
                const updated = commissions.filter(c => c.id !== selectedCommission.id)
                setCommissions(updated);
                if(updated.length > 0){
                    setSelectedCommission(updated[0])
                }
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, [selectedCommission, affiliateId, setCommissions, commissions, setIsLoading])

    const handleDeleteButtonClick = useCallback(() => {
        setConfirmationModal({
            isVisible: true,
            title: t('backoffice.common.remove'),
            message: t("backoffice.commissionplans.doYouWanttoRemoveCommissionPlan"),
            onOk: removeCommission
        })
    }, [setConfirmationModal, removeCommission])

    const hideConfirmationModal = () => {
        setConfirmationModal({
            isVisible: false,
            onOk: Function.prototype
        })
    }

    const handleAddButtonClick = () => {
        setMode(COMMISSION_POPUP_MODE.ADD)
    }

    return (
        <Spin spinning={isLoading}>
            <div>
                { 
                    editable && (
                        <Actions 
                            negativeCarryOver={negativeCarryOver}
                            onNegativeCarryOverChange={handleNegativeCarryOverChange}
                            onAddButtonClick={handleAddButtonClick}
                        />
                    ) 
                }
                {
                    commissions.length > 0 ? (
                        <Fragment>
                            <Navigation 
                                items={ commissions }
                                selected={ selectedCommission }
                                onClick={ setSelectedCommission }
                                mode={ COMMISSION_POPUP_MODE.VIEW }
                            />
                            <Details 
                                commission={selectedCommission}
                                mode={COMMISSION_POPUP_MODE.VIEW}
                                editable={editable}
                                onDeleteButtonClick={handleDeleteButtonClick}
                            />
                        </Fragment>
                    ) : (
                        <Empty mode={COMMISSION_POPUP_MODE.VIEW} />
                    )
                }
                
            </div>

            
            <Confirmation
                title={confirmationModal?.title ?? ""}
                message={confirmationModal.message ?? ""}
                onOk={() => confirmationModal.onOk()}
                onCancel={hideConfirmationModal}
                isVisible={confirmationModal.isVisible}
            />
        </Spin>
    )
}

export default CommissionsPopupView;