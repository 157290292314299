import { useSyncExternalStore } from "react";

import ImmutableState from "core/lib/immutableState";
import Observable from "core/lib/observable";

class BaseStore {
	#state;

	constructor(initialState, name) {
		this.observer = new Observable();
		this._initialState = initialState;
        this._name = name || this.__proto__.constructor.name;

		this.#state = new ImmutableState(initialState, this.#handleStateUpdate.bind(this));

        this.getSnapshot = this.getSnapshot.bind(this);
		this.subscribe = this.subscribe.bind(this);
	}

	get _pureState() {
		return this.#state.pureState;
	}

	get state() {
		return this.#state.proxyState;
	}

	set state(newState) {
		const prevState = this.#state.pureState;
		this.#state = new ImmutableState(newState, this.#handleStateUpdate.bind(this));
		const nextState = this.#state.pureState;

		this.#handleStateUpdate({
			status: "INIT",
			value: newState,
			previousState: [
				{
					op: "init",
					path: [],
					value: prevState
				}
			],
			currentState: [
				{
					op: "init",
					path: [],
					value: nextState
				}
			]
		});
	}

    getState() {
		return this._pureState;
	}

	reset() {
		this.state = this._initialState;
	}

	#handleStateUpdate(changes) {
		this.observer.notify({ name: this._name, state: {...this.#state.pureState}, changes });
	}

	subscribe(callback) {
		this.observer.subscribe(callback);

		return () => {
			this.observer.unsubscribe(callback);
		};
	}

	getSnapshot() {
		return this._pureState;
	}
}

export const createSelector =
	(stateInstance) =>
	(selectorFn = null) => {
		return useSyncExternalStore(
			stateInstance.subscribe,
			selectorFn
				? () => selectorFn.call(null, stateInstance.getSnapshot())
				: stateInstance.getSnapshot,
		);
	};

export default BaseStore;