import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Input as AntInput } from 'antd';

import { NUMBER_INPUT_FORMAT } from 'core/constants/component/input';

const InputNumber = forwardRef(({
    format,
    ...rest
}, ref) => {

    const [internalValue, setInternalValue] = useState("");

    const inputValue = Object.hasOwn(rest, "value") ? rest.value ?? "" : internalValue;
    const resultChange = Object.hasOwn(rest, "onChange") ? rest.onChange : setInternalValue;

    const handleInternalChange = e => {
        const { value } = e.target;
        let regexp = null;
        switch(format){
            case NUMBER_INPUT_FORMAT.WHOLE:
                regexp = /^-?[0-9]*([0-9]*)?$/;
                break;
            case NUMBER_INPUT_FORMAT.MOBILE:
                regexp = /^\+?([0-9]*)?$/;
                break;
            case NUMBER_INPUT_FORMAT.IP_ADDRESS:
                regexp = /^[0-9]+(?:\.[0-9]+)*\.?$/;
                break;
            default:
                regexp = /^-?[0-9]*(\.[0-9]*)?$/;
                break
        }

        if(value !== ""){
            if(isNaN(value)){
                return;
            }
    
            if(!regexp.test(value)){
                return;
            }
        }

        resultChange?.(value);
    }

    const handleInternalBlur = () => {
        const v = inputValue ?? "";

        let tempValue = String(v);

        if(tempValue.charAt(tempValue.length - 1) === '.'){
            tempValue = tempValue.slice(0, -1);
        }

        if(format !== NUMBER_INPUT_FORMAT.IP_ADDRESS && format !== NUMBER_INPUT_FORMAT.MOBILE){
            if(tempValue !== tempValue.replace(/0*(\d+)/, '$1')){
                resultChange?.(tempValue.replace(/0*(\d+)/, '$1'));
            }
        }

        rest.onBlur?.()
    }

    return (
        <AntInput
            ref={ref}
            {...rest}
            autoComplete="off"
            value={inputValue}
            onChange={handleInternalChange}
            onBlur={handleInternalBlur}
        />
    )
})

InputNumber.propTypes = {
	format: PropTypes.oneOf(Object.values(NUMBER_INPUT_FORMAT)),
}

export default InputNumber;

