import React, { useContext } from "react";

import { splitActions } from "../../helpers";

import DropdownActions from "./dropdownActions";
import VisibleActions from "./visibleActions";

import TableContext from "../../context";

const TableActions = ({
    actions=[],
    record
}) => {

    const activeActions = actions.filter(action => !action.disabled?.(record));

    const { showConfirmationModal } = useContext(TableContext);

    if (activeActions.length === 0) {
        return null;
    }

    const { visibleActions, dropdownActions } = splitActions(activeActions);

    const onActionClick = action => {
        if(Boolean(action.confirmation)){
            showConfirmationModal({
                record,
                title: action.confirmation.title,
                message: action.confirmation.message,
                onOk: action.onClick
            })
            return;
        }

        action.onClick(record);
    }

    return (
        <div className='rt--flex rt--align-center rt--justify-end'>
            <DropdownActions actions={dropdownActions} record={record} onActionClick={onActionClick} />
            <VisibleActions actions={visibleActions} record={record} onActionClick={onActionClick} />
        </div>
    )
}

export default TableActions;