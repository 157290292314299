//#region react
import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";
import { useNavigate } from 'react-router-dom';
//#endregion

//#region actions
import {
    getCompanies,
    setCompaniesSorting,
    setCompaniesFilters
} from "store/actions/portal/companies/companies.action";
//#endregion

//#region hooks
import useIncludedColumns from "hooks/useIncludedColumns";
//#endregion

//#region components
import Table from "components/common/table";
import MainDashboardLayout from "components/layouts/main";
import CompanyCreateComponent from "./company-create.component";
//#endregion

//#region utils
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from "core/constants/permission";
import ROUTES from "core/constants/routes/routes";
import { COMPANIES } from "constants/pageName.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";
import { getTableColumns } from "./columns";
//#endregion

//#region types
import companyType from "types/company/company.type";
import sortingType from "types/common/sorting.type";
import usePermissions from "core/hooks/usePermission";
//#endregion

const COMPANIES_EDIT_PAGE_PERMISSION_RESOURCES = [
    PERMISSION_RESOURCE.COMPANY_SECURITY_SETTINGS,
    PERMISSION_RESOURCE.COMPANY_GENERALINFO,
]

/** Companies Page Component */
const CompaniesComponent = ({
    getCompanies,
    companies,
    isLoading,
    total,
    setCompaniesSorting,
    setCompaniesFilters,
    sorting,
    filters
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isCreatePopupVisible, setIsCreatePopupVisible] = useState(false);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: COMPANIES });

    const permissionUtils = usePermissions();

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasCompanyCreatePermission = permissionUtils.has( PERMISSION_RESOURCE.COMPANY, PERMISSION_ACTION.CREATE );

    const canNavigateToCompaniesEditPage = permissionUtils.some(
        ...COMPANIES_EDIT_PAGE_PERMISSION_RESOURCES.map(resource => ({
            resource,
            action: PERMISSION_ACTION.VIEW
        }))
    )

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setCompaniesFilters({
            ...filters,
            nameOrId: value
        })
    }

    const handleRightArrowIconClick = (record) => {
        navigate(
            `${ROUTES.COMPANIES_EDIT}/${record.id}` +
            `?name=${record.name}`
        )
    }

    const showCreatePopup = () => {
        setIsCreatePopupVisible(true)
    }

    const hideCreatePopup = () => {
        setIsCreatePopupVisible(false)
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns, columnsThatCanBeIncluded } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            constructForInclude: true,
            includedColumns,
            additionalProps: {
                t
            },
            t
        });
    }, [includedColumns, t])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (canNavigateToCompaniesEditPage) {
        tableRowActions.push({
            title: t('backoffice.common.deepView'),
            icon: "right",
            onClick: handleRightArrowIconClick,
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        search: {
            placeholder: t("backoffice.companies.companyNameOrId"),
            loadFn: getCompanies,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.COMPANY
            },
            onSearch: handleSearchChange,
        },
        buttons: [{
            text: t("backoffice.companies.createCompany"),
            icon: "plus",
            type: "primary",
            enabled: hasCompanyCreatePermission,
            onClick: showCreatePopup,
        }],
        breadcrumbs: {
            items: [{ title: t('backoffice.menu.companies') }]
        }
    }

    //#endregion

    return (
        <MainDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={companies}
                loadFn={getCompanies}
                sorting={sorting}
                setSortingFn={setCompaniesSorting}
                total={total}
                actions={tableRowActions}
                disableFullView={true}
            />

            {isCreatePopupVisible && <CompanyCreateComponent onClose={hideCreatePopup} />}
        </MainDashboardLayout>

    );
};

/** CompaniesComponent propTypes
 * PropTypes
*/
CompaniesComponent.propTypes = {
    /** Redux action to get companies */
    getCompanies: PropTypes.func,
    /** Redux state property, represents the array of companies  */
    companies: PropTypes.arrayOf(companyType),
    /** Redux state property, is true when loading companies */
    isLoading: PropTypes.bool,
    /** Redux state property, companies total count */
    total: PropTypes.number,
    /** Redux action to set companies sorting details */
    setCompaniesSorting: PropTypes.func,
    /** Redux action to set companies sorting details */
    setCompaniesFilters: PropTypes.func,
    /** Redux state property, companies sorting details */
    sorting: sortingType,
    /** Redux state property, companies filters */
    filters: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
    getCompanies: nextPage => {
        dispatch(getCompanies(nextPage));
    },
    setCompaniesSorting: sorting => {
        dispatch(setCompaniesSorting(sorting));
    },
    setCompaniesFilters: filters => {
        dispatch(setCompaniesFilters(filters));
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.companies.isLoading,
        companies: state.companies.companies,
        total: state.companies.total,
        sorting: state.companies.sorting,
        filters: state.companies.filters
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompaniesComponent);
