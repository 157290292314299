import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "core/hooks/useTranslation";

import { Form } from 'antd';

import Modal from 'components/common/modal';
import CustomizeImgUploader from '../../customizeImgUploader';

import { PROMO_SECTION_TYPE, SECTION_NAMES } from 'constants/promo.constants';

const TestimonialEditComponent = ({
    imgPath,
    fileType,
    onClose,
    customizeLanguage,
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            title={t('backoffice.promo.editTestimonials')}
            closable={true}
            onCancel={onClose}
        >
            <Form
                className="rt--form"
                colon={false}
                requiredMark={false}
                layout="vertical"
            >
                <Form.Item className='rt--general-form-item rt--form-item-without-margin'>
                    <CustomizeImgUploader
                        sectionName={SECTION_NAMES.TESTIMONIAL}
                        sectionType={PROMO_SECTION_TYPE.TESTIMONIAL}
                        fileType={fileType}
                        languageCode={customizeLanguage}
                        imgPath={imgPath}
                        onSuccess={() => onClose()}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}

/** TestimonialEditComponent propTypes
    * PropTypes
*/
TestimonialEditComponent.propTypes = {
    /** Fires on popup close */
    onClose: PropTypes.func,
    /** Redux state property, customize page language */
    customizeLanguage: PropTypes.string,
    /** Image URL */
    imgPath: PropTypes.string,
    /** File Type */
    fileType: PropTypes.number,
}

export default TestimonialEditComponent;