import { message } from 'antd';

import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import {
    SET_AFFILIATE_ACCESS_SETTINGS_ACTION_BEFORE,
    SET_AFFILIATE_ACCESS_SETTINGS_ACTION_FINISH,
    SET_AFFILIATE_ACCESS_SETTINGS_SAVE_ACTION_BEFORE,
    SET_AFFILIATE_ACCESS_SETTINGS_SAVE_ACTION_FINISH,
    SET_AFFILIATE_ACCESS_SETTINGS
} from "../../../../actionTypes";

const setAffiliateAccessSettingsActionBefore = () => ({
    type: SET_AFFILIATE_ACCESS_SETTINGS_ACTION_BEFORE,
});

const setAffiliateAccessSettingsActionFinished = () => ({
    type: SET_AFFILIATE_ACCESS_SETTINGS_ACTION_FINISH,
});

const setAffiliateAccessSettingsSaveActionBefore = () => ({
    type: SET_AFFILIATE_ACCESS_SETTINGS_SAVE_ACTION_BEFORE,
});

const setAffiliateAccessSettingsSaveActionFinished = () => ({
    type: SET_AFFILIATE_ACCESS_SETTINGS_SAVE_ACTION_FINISH,
});

const setAffiliateAccessSettings = accessSettings => ({
    type: SET_AFFILIATE_ACCESS_SETTINGS,
    payload: { accessSettings },
});

export const getAffiliateAccessSettings = () => {
    return dispatch => {
                
        dispatch(setAffiliateAccessSettingsActionBefore());

        return axios({
            url: ApiUrls.GET_PROJECT_ACCESS_SETTINGS,
            method: Methods.GET,
            params: {},
        })
            .then(({ data: { value: accessSettings } }) => {

                dispatch(setAffiliateAccessSettings(accessSettings));
                dispatch(setAffiliateAccessSettingsActionFinished());
            })
            .catch((ex) => {
                dispatch(setAffiliateAccessSettingsActionFinished());
            });
    }
}

export const saveAffiliateAccessSettings = data => {
    return (dispatch) => {
        dispatch(setAffiliateAccessSettingsSaveActionBefore());

        return axios({
            url: ApiUrls.SAVE_PROJECT_ACCESS_SETTINGS,
            method: Methods.POST,
            data: { 
                ... data,
            }
        })
            .then(({ data }) => {
                dispatch(setAffiliateAccessSettingsSaveActionFinished());
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    dispatch(setAffiliateAccessSettings(data.value));
                }
            })
            .catch(() => {
                dispatch(setAffiliateAccessSettingsSaveActionFinished());
            });
    };
};

