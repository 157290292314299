import { message } from 'antd';

import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { isMobile } from "utils/common";

import { updateCommissionPlanState } from './general.action';

import {
    SET_COMMISSION_PLANS_ACTION_BEFORE,
    SET_COMMISSION_PLANS_ACTION_FINISH,
    SET_COMMISSION_PLANS,
    SET_COMMISSION_PLANS_SORTING,
    SET_COMMISSION_PLANS_FILTERS,
    SET_COMMISSION_PLANS_SAVE_ACTION_BEFORE,
    SET_COMMISSION_PLANS_SAVE_ACTION_FINISH,
    SET_AVAILABLE_COMMISSION_PLANS_ACTION_BEFORE,
    SET_AVAILABLE_COMMISSION_PLANS_ACTION_FINISH,
    SET_AVAILABLE_COMMISSION_PLANS
} from "../../../actionTypes";

export const setCommissionPlansActionBefore = () => ({
    type: SET_COMMISSION_PLANS_ACTION_BEFORE,
});

export const setCommissionPlansActionFinished = () => ({
    type: SET_COMMISSION_PLANS_ACTION_FINISH,
});

export const setCommissionPlansSaveActionBefore = () => ({
    type: SET_COMMISSION_PLANS_SAVE_ACTION_BEFORE,
});

export const setCommissionPlansSaveActionFinished = () => ({
    type: SET_COMMISSION_PLANS_SAVE_ACTION_FINISH,
});

const setCommissionPlans = (commissionPlans, add) => ({
    type: SET_COMMISSION_PLANS,
    payload: { commissionPlans, add },
});

export const setCommissionPlansSorting = sorting => ({
    type: SET_COMMISSION_PLANS_SORTING,
    payload: { sorting },
});

export const setCommissionPlansFilters = filters => ({
    type: SET_COMMISSION_PLANS_FILTERS,
    payload: { filters },
});

export const getCommissionPlans = nextPage => {
    return (dispatch, getState) => {
        
        const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().commissionPlans.sorting.page + 1 : getState().commissionPlans.sorting.page;
        
        let params = {
            ...getState().commissionPlans.sorting,
            ...getState().commissionPlans.filters,
            page: page
        };

        if(page > 1 && getState().commissionPlans.total <= (page - 1 ) * getState().commissionPlans.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && getState().commissionPlans.total === 0){
            return Promise.resolve();
        }
        
        dispatch(setCommissionPlansActionBefore());

        return axios({
            url: ApiUrls.GET_COMMISSION_PLANS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: commissionPlans } }) => {
                dispatch(setCommissionPlans(commissionPlans, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setCommissionPlansSorting({ ...getState().commissionPlans.sorting, page: page }));
                dispatch(setCommissionPlansActionFinished());
            })
            .catch((ex) => {
                dispatch(setCommissionPlansActionFinished());
            });
    }
}

export const createCommissionPlan = (commissionPlan, onSuccess) => {
    return dispatch => {
        dispatch(setCommissionPlansSaveActionBefore());
        return axios({
            url: ApiUrls.CREATE_COMMISSION_PLAN,
            method: Methods.POST,
            data: {
                ...commissionPlan
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                onSuccess && onSuccess(data.value);
            } 
            dispatch(setCommissionPlansSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setCommissionPlansSaveActionFinished());
        })
    }
}

export const changeCommissionPlanState = (id, status) => {
    return dispatch => {
        dispatch(setCommissionPlansSaveActionBefore());
        return axios({
            url: ApiUrls.CHANGE_COMMISSION_PLAN_STATE,
            method: Methods.POST,
            data: {
                id, 
                status
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(updateCommissionPlanState(status));
            } 
            dispatch(setCommissionPlansSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setCommissionPlansSaveActionFinished());
        })
    }
}

export const deleteCommissionPlan = (id, onSuccess) => {
    return dispatch => {
        dispatch(setCommissionPlansSaveActionBefore());
        return axios({
            url: ApiUrls.DELETE_COMMISSION_PLAN,
            method: Methods.DELETE,
            data: { id }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                onSuccess && onSuccess();
            } 
            dispatch(setCommissionPlansSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setCommissionPlansSaveActionFinished());
        })
    }
}

/*** Available Commission Plans */

export const setAvailableCommissionPlansActionBefore = () => ({
    type: SET_AVAILABLE_COMMISSION_PLANS_ACTION_BEFORE,
});

export const setAvailableCommissionPlansActionFinished = () => ({
    type: SET_AVAILABLE_COMMISSION_PLANS_ACTION_FINISH,
});

const setAvailableCommissionPlans = commissionPlans => ({
    type: SET_AVAILABLE_COMMISSION_PLANS,
    payload: { commissionPlans },
});

export const getAvailableCommissionPlans = id => {
    return (dispatch) => {
        dispatch(setAvailableCommissionPlansActionBefore());
        return axios({
            url: ApiUrls.GET_AVAILABLE_COMMISSION_PLANS,
            method: Methods.GET,
            params: { id }
        })
            .then(({ data: { value: commissionPlans } }) => {
                dispatch(setAvailableCommissionPlans(commissionPlans));
                dispatch(setAvailableCommissionPlansActionFinished());
            })
            .catch(() => {
                dispatch(setAvailableCommissionPlansActionFinished());
            });
    }
}

/** Affiliate Commission Plans */

export const getAffiliateCommissionPlans = (affiliateId, nextPage) => {
    return (dispatch, getState) => {
        
        const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().commissionPlans.sorting.page + 1 : getState().commissionPlans.sorting.page;
        
        let params = {
            ...getState().commissionPlans.sorting,
            affiliateId: affiliateId,
            page: page
        };

        if(page > 1 && getState().commissionPlans.total <= (page - 1 ) * getState().commissionPlans.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && getState().commissionPlans.total === 0){
            return Promise.resolve();
        }
        
        dispatch(setCommissionPlansActionBefore());

        return axios({
            url: ApiUrls.GET_COMMISSION_PLANS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: commissionPlans } }) => {
                dispatch(setCommissionPlans({item1: commissionPlans.item1, item2: commissionPlans.item2 ?? []}, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setCommissionPlansSorting({ ...getState().commissionPlans.sorting, page: page }));
                dispatch(setCommissionPlansActionFinished());
            })
            .catch((ex) => {
                dispatch(setCommissionPlansActionFinished());
            });
    }
}

export const addCommissionPlan = (id, affiliateId, startDate, affiliateLongId, onSuccess) => {
    return dispatch => {
        dispatch(setCommissionPlansSaveActionBefore());
        return axios({
            url: ApiUrls.ADD_COMMISSION_PLAN,
            method: Methods.POST,
            data: {
                id, affiliateId, startDate
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getAffiliateCommissionPlans(affiliateLongId));
                onSuccess && onSuccess();
            } 
            dispatch(setCommissionPlansSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setCommissionPlansSaveActionFinished());
        })
    }
}

export const removeCommissionPlan = (id, affiliateId, onSuccess) => {
    return dispatch => {
        dispatch(setCommissionPlansSaveActionBefore());
        return axios({
            url: ApiUrls.REMOVE_COMMISSION_PLAN,
            method: Methods.DELETE,
            data: { id, affiliateId }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                onSuccess && onSuccess();
            } 
            dispatch(setCommissionPlansSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setCommissionPlansSaveActionFinished());
        })
    }
}