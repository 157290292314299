import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useParams } from 'react-router-dom';

import { Form, Col, Row } from 'antd';

import Modal from "components/common/modal";
import PasswordRules from "components/common/passwordRules";
import TextAreaInput from 'components/common/textAreaInput';
import Input from 'components/common/input';

import { getCompanyPasswordSettings } from "store/actions/portal/companies/passwordSettings.action";

import { setAccountPassword } from "store/actions/portal/account/password.action";

import { validatePassword } from "utils/password";

import { USER_ROLE } from 'constants/user.constants';

import companyPasswordSettingsType from "types/company/passwordSettings.type";

import { useAuthSelector, userRoleSelector } from 'core/stores/authStore';


/** Set Password Popup Component */
const SetPasswordComponent = ({
    companyId,
    isAccountSaving,
    getCompanyPasswordSettings,
    passwordSettings,
    setAccountPassword,
    onClose
}) => {
    const { t } = useTranslation();

    const userRole = useAuthSelector(userRoleSelector);

    const [formInstance] = Form.useForm();
    const { validateFields, getFieldValue } = formInstance;

    const searchParams = useParams();

    useEffect(() => {
        getCompanyPasswordSettings(companyId);
    }, [])


    /** Fires when form submitted
       * @function
       * @memberOf SetPasswordComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                setAccountPassword({
                    newPassword: data.newPassword,
                    confirmPassword: data.confirmPassword,
                    note: data.note
                }, searchParams.id, onClose)

            }).catch(() => { })
    }

    return (
        <Modal
            title={t('backoffice.common.setPassword')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.save')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isAccountSaving}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    newPassword: "",
                    confirmPassword: ""
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.common.newPassword')} *`}
                            name="newPassword"
                            validateFirst
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        return validatePassword(value, passwordSettings, t);
                                    }
                                })
                            ]}
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.common.newPassword')}`}
                        >
                            <Input 
                                type='Password'
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.common.newPassword')}`}
                                maxLength={passwordSettings?.passwordMaxLimit ?? 24}
                                onChange={() => {
                                    setTimeout(() => {
                                        if (getFieldValue('confirmPassword') !== "")
                                            validateFields(['confirmPassword'])
                                    }, 0)
                                }} 
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.common.confirmPassword')} *`}
                            name="confirmPassword"
                            validateFirst
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (value !== getFieldValue("newPassword")) {
                                            return Promise.reject(t('backoffice.validation.passwordsDoNotMatch'))
                                        }
                                        return Promise.resolve();
                                    }
                                })
                            ]}
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.confirmPassword')}`}
                        >
                            <Input 
                                type='Password'
                                placeholder={`${t('backoffice.common.confirmPassword')}`}
                                maxLength={passwordSettings?.passwordMaxLimit ?? 24}
                                onPaste={e => e.preventDefault()}
                                onContextMenu={e => e.preventDefault()}
                                onCopy={e => e.preventDefault()} 
                            />
                        </Form.Item>
                    </Col>

                    {userRole === USER_ROLE.ACCESS_MANAGER && (
                        <Col span={24} >
                            <Form.Item
                                label={`${t('backoffice.users.notes')} *`}
                                name="note"
                                className="rt--general-form-item"
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.notes')}`}
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 200, message: t('backoffice.validation.fieldInvalid') },
                                    { min: 5, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                validateFirst
                            >
                                <TextAreaInput
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.notes')}`}
                                    maxLength={200}
                                    rows={6}
                                />
                            </Form.Item>
                        </Col>
                    )}

                    {
                        Object.keys(passwordSettings).length > 0 ?
                            (
                                <Col span={24}>
                                    <Form.Item className='rt--form-item-without-margin'>
                                        <PasswordRules passwordSettings={passwordSettings} />
                                    </Form.Item>
                                </Col>
                            ) : null
                    }
                </Row>
            </Form>
        </Modal>
    )
}

/** SetPasswordComponent propTypes
    * PropTypes
*/
SetPasswordComponent.propTypes = {
    /** Redux state property, is true when account set password request is in process */
    isAccountSaving: PropTypes.bool,
    /** Redux action to get password settings */
    getCompanyPasswordSettings: PropTypes.func,
    /** Redux state property, represents compan password settings */
    passwordSettings: companyPasswordSettingsType,
    /** Redux action to set account password */
    setAccountPassword: PropTypes.func,
    /** The company id */
    companyId: PropTypes.string,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getCompanyPasswordSettings: companyId => {
            dispatch(getCompanyPasswordSettings(companyId));
        },

        setAccountPassword: (data, id, onSuccess) => {
            dispatch(setAccountPassword(data, id, onSuccess))
        }
    }
)

const mapStateToProps = state => {
    return {
        isAccountSaving: state.account.isSaving,
        passwordSettings: state.companies.edit.passwordSettings
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SetPasswordComponent)