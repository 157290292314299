import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import Select from "components/common/select";
import DateRangePicker from "components/common/dateRangePicker";

import { getCommissionPlans, setCommissionPlansFilters } from "store/actions/portal/commissionPlans/commissionPlans.action";

import useCurrencies from 'hooks/useCurrencies';

import { PROJECT_BRAND_PRODUCT_TYPE } from 'constants/project.constants';
import { COMMISSION_PLAN_STATE, COMMISSION_PLAN_TYPE } from 'constants/commission.constants';

/** Campaigns Page Filters Component */

const Filters = ({
    setCommissionPlansFilters,
    getCommissionPlans,
    filters
}) => {
    const { t } = useTranslation();

    const [ currencies, getCurrencies ] = useCurrencies(false);

    return (
        <FiltersWrapper
            loadFn={getCommissionPlans}
            setFiltersFn={setCommissionPlansFilters}
            filters={filters}
            datePickerFields={[
                { name: "date", time: false },
                { name: "modified", time: false }
            ]}
            searchFieldName="nameOrId"
            onInit={() => {
                getCurrencies();
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.commissionplans.status')}
                        name="status"
                    >
                        <Select
                            options={[
                                { value: "", text: t('backoffice.common.all') },
                                { value: COMMISSION_PLAN_STATE.ACTIVE, text: t('backoffice.common.active') },
                                { value: COMMISSION_PLAN_STATE.INACTIVE, text: t('backoffice.common.inactive') }
                            ]}
                            placeholder={`${t("backoffice.common.select")} ${t('backoffice.commissionplans.status')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.commissionplans.commissionType')}
                        name="type"
                    >
                        <Select
                            options={[
                                { value: "", text: t('backoffice.common.all') },
                                { value: COMMISSION_PLAN_TYPE.REVSHARE, text: t('backoffice.commissionplans.revshare') },
                                { value: COMMISSION_PLAN_TYPE.CPA, text: t('backoffice.commissionplans.cpa') },
                                { value: COMMISSION_PLAN_TYPE.FIX, text: t('backoffice.commissionplans.fix') }
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissionplans.commissionType')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.commissionplans.currency')}
                        name="currencyCode"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') }
                                ].concat(
                                    currencies.map(item => (
                                        { value: item.code, text: item.code }
                                    ))
                                )
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissionplans.currency')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.projects.product')}
                        name="productType"
                    >
                        <Select
                            options={[
                                { value: "", text: t('backoffice.common.all') },
                                { value: PROJECT_BRAND_PRODUCT_TYPE.CASINO, text: t('backoffice.projects.casino') },
                                { value: PROJECT_BRAND_PRODUCT_TYPE.SPORT, text: t('backoffice.projects.sport') }
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.projects.product')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.creationDate')}
                        name="date"
                    >
                        <DateRangePicker
                            showTime={false}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.lastUpdateDate')}
                        name="modified"
                        className='rt--form-item-without-margin'
                    >
                        <DateRangePicker
                            showTime={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get commission plans */
    getCommissionPlans: PropTypes.func,
    /** Redux action to set commission plans filters */
    setCommissionPlansFilters: PropTypes.func,
    /** Redux state property, commission plans filters */
    filters: PropTypes.object
}

const mapDispatchToProps = dispatch => (
    {
        getCommissionPlans: () => {
            dispatch(getCommissionPlans());
        },

        setCommissionPlansFilters: filters => {
            dispatch(setCommissionPlansFilters(filters))
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.commissionPlans.filters
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);