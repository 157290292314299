import ROUTES from "core/constants/routes/routes";

import SystemNotificationEditComponent from "pages/communications/systemNotifications/edit/systemNotification-edit.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const SystemNotificationEditPageRoute = {
	path: `${ROUTES.SYSTEM_NOTIFICATIONS_EDIT}/:id`,
	element: SystemNotificationEditComponent, 
    permission: [
        { resource: PERMISSION_RESOURCE.COMMUNICATION_SYSTEM_TEMPLATES, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.COMMUNICATION_PROJECT_TEMPLATES, action: PERMISSION_ACTION.VIEW }
    ]
};

export default SystemNotificationEditPageRoute;
