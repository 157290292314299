import React from "react";
import PropTypes from "prop-types";

import { Button as AntButton } from "antd";

const Button = ({ 
    children, 
    ...rest 
}) => {
	return (
        <AntButton 
            { ...rest }
        >
            {
                children
            }
        </AntButton>
    );
};

Button.propTypes = {
	children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

export default Button;
