import React, { useMemo } from "react";

import ListView from "core/ui-kit/listView";
import isFunction from "core/helpers/typeChecks/isFunction";

const FullView = ({
    record, 
    columns
}) => {

    const fullViewData = useMemo(() => {
        return columns.map(column => ({
            key: column.dataIndex,
            disableTooltip: column.mobileConfigs?.fullView?.disableTooltip ?? false,
            title: isFunction(column.title) ? column.title() : column.title,
            value: (
                isFunction(column.mobileConfigs?.fullView?.render) ? column.mobileConfigs.fullView.render(record[column.dataIndex], record) : 
                isFunction(column.render) ? column.render(record[column.dataIndex], record) : record[column.dataIndex]
            )
        }))
    }, [record, columns])

    return (
        <ListView 
            data={fullViewData}
        />
    )
}

export default FullView;