import React, { forwardRef, useEffect, useImperativeHandle, useState, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";

import { Radio, Form } from "antd";

import Input from 'components/common/input';
import Tooltip from 'components/common/tooltip';
import Icon from 'components/common/icon';

import { binaryToFlags, isMobile, isFunction, classNames } from 'utils/common';
import { validateRegex } from 'utils/validate';
import { isFormChanged } from "utils/form";

import { COMPANY_CONTROL_RULE, COMPANY_PREVALIDATION_RULE } from "constants/company.constants";

import registrationFormType from "types/project/registrationForm.type";

const FormBuilder = forwardRef(({
    title,
    sections,
    formData = [],
    onTabChange,
    userCanModify,
    onFormFinish,
    validateFormValues,
    onFormChange,
    fieldNameRender
}, ref) => {
    const { t } = useTranslation();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const [isFormTouched, setIsFormTouched] = useState(false);
    const [isFormInvalid, setIsFormInvalid] = useState(false);

    const [regExpOpened, setRegExpOpened] = useState([]);

    const groupedFormControls = useMemo(() => {
        let result = [];
        if(sections){
            sections.forEach(section => {
                result.push({
                    items: [],
                    title: section.title
                }) 
            })

            formData.forEach(control => {
                const controlIndex = sections.findIndex(s => s.controls.includes(control.name));
                if(controlIndex !== -1){
                    result[controlIndex].items.push({...control})
                }
            })
        } else {
            result = [
                { items : [], title: title },
                { items: [], title: " " }
            ];

            [0, 1].forEach(index => {
                result[index].items = formData.slice(Math.ceil(formData.length / 2) * index, Math.ceil(formData.length / 2) * (index + 1))
            })
        }

        return result;
    }, [sections, formData]);

    const isMobileDevice = isMobile();

    /** Fires when form submitted
       * @function
       * @memberOf FormBuilder
    */
    const handleForm = () => {
        return validateFields()
            .then((data) => {
                const values = {
                    formControls: Object.keys(data).filter(k => !k.endsWith("_regexp")).map(k => ({
                        name: k,
                        selectedRule: data[k],
                        regExp: formData.find(c => c.name === k).preValidationRule === COMPANY_PREVALIDATION_RULE.REGEXP ? data[k + "_regexp"] : null
                    }))
                }
                if (!isFormInvalid) {
                    if (isFunction(onFormFinish)) {
                        onFormFinish(values)
                    }

                    setIsFormTouched(false);
                    return values;
                }
            }).catch((ex) => {
                console.log(ex)
            })
    }

    useImperativeHandle(ref, () => {
        return {
            handleForm,
            isFormTouched
        }
    })


    useEffect(() => {
        const formFields = {};

        formData.forEach(control => {
            formFields[control.name] = binaryToFlags(Object.values(COMPANY_CONTROL_RULE), control.selectedRule)[0]
            if (control.preValidationRule === COMPANY_PREVALIDATION_RULE.REGEXP) {
                formFields[control.name + "_regexp"] = control.regExp
            }
        })

        setFieldsValue(formFields)
    }, [formData])

    /** Render Radio Button
       * @function
       * @param {object} control
       * @param {number} value
       * @returns {JSX}
       * @memberOf FormBuilder
    */
    const renderRadioButton = (control, value) => {
        const isDisabled = !binaryToFlags(Object.values(COMPANY_CONTROL_RULE), control.rule).includes(value);
        if (isDisabled) {
            return (
                <Tooltip
                    title={t("backoffice.registrationform.cannotBeSelected")}
                    trigger={["hover", "click"]}
                    placement="bottomLeft"
                    enableMobile={true}
                >
                    <Radio
                        value={value}
                        disabled={true}
                    />
                </Tooltip>
            )
        }
        return (
            <Radio
                value={value}
                disabled={!userCanModify}
            />
        )
    }

    /** Get form initial values
       * @function
       * @returns {Object}
       * @memberOf FormBuilder
    */
    const getInitialValues = () => {
        const values = {};
        formData.forEach(control => {
            values[control.name] = control.selectedRule;
            if (control.preValidationRule === COMPANY_PREVALIDATION_RULE.REGEXP) {
                values[control.name + "_regexp"] = control.regExp;
            }
        })
        return values;
    }

    /** Functon to render tooltip
       * @function
       * @returns {JSX}
       * @memberOf FormBuilder
    */
    const renderInfoTooltipContent = () => (
        <div className='rt--registration-form-controls-info rt--pt-8 rt--pb-8'>
            <b className='rt--title rt--font-bold rt--font-normal rt--font-uppercase rt--pb-4'>*{t("backoffice.registrationform.must")}</b>
            <b className='rt--title rt--font-regular rt--font-normal'>{t("backoffice.registrationform.mustTooltip")}</b>
            <b className='rt--title rt--font-bold rt--font-normal rt--font-uppercase rt--pb-4 rt--pt-16'>*{t("backoffice.registrationform.may")}</b>
            <b className='rt--title rt--font-regular rt--font-normal'>{t("backoffice.registrationform.mayTooltip")}</b>
            <b className='rt--title rt--font-bold rt--font-normal rt--font-uppercase rt--pb-4 rt--pt-16'>*{t("backoffice.registrationform.none")}</b>
            <b className='rt--title rt--font-regular rt--font-normal'>{t("backoffice.registrationform.noneTooltip")}</b>
        </div>
    )

    const renderInfoTooltipContentTitleMust = () => (
        <div className='rt--registration-form-controls-info rt--pt-8 rt--pb-8'>
            <b className='rt--title rt--font-bold rt--font-uppercase rt--pb-4'>{t("backoffice.registrationform.must")}</b>
        </div>
    )

    const renderInfoTooltipContentTitleMay = () => (
        <div className='rt--registration-form-controls-info rt--pt-8 rt--pb-8'>
            <b className='rt--title rt--font-bold rt--font-uppercase rt--pb-4'>{t("backoffice.registrationform.may")}</b>
        </div>
    )

    const renderInfoTooltipContentTitleNone = () => (
        <div className='rt--registration-form-controls-info rt--pt-8 rt--pb-8'>
            <b className='rt--title rt--font-bold rt--font-uppercase rt--pb-4'>{t("backoffice.registrationform.none")}</b>
        </div>
    )

    useEffect(() => {
        if (isFunction(onTabChange)) {
            onTabChange(isFormTouched);
        }
    }, [isFormTouched])

    /** Functon to validate form
       * @function
       * @param {object} values
       * @memberOf FormBuilder
    */
    const validateForm = values => {
        if (isFunction(validateFormValues)) {
            setIsFormInvalid(validateFormValues(values));
        }
    }

    /** Functon to fire on form hange
       * @function
       * @param {object} values
       * @memberOf FormBuilder
    */
    const handleChange = values => {
        if (!isFunction(onFormChange)) {
            return;
        }
        let controls = [...formData]

        controls = controls.map(control => ({
            ...control,
            selectedRule: values[control.name],
            regExp: values[control.name + "_regexp"] || null
        })).filter(control => control.selectedRule !== COMPANY_CONTROL_RULE.HIDDEN)

        onFormChange(controls)
    }

    /** Functon to get sliced registration form
       * @function
       * @param {i} number
       * @memberOf RegistrationFormComponent
    */
    const getSlicedRegistrationForm = i => formData.slice(Math.ceil(formData.length / 2) * i, Math.ceil(formData.length / 2) * (i + 1));

    return (
        <Form
            form={formInstance}
            requiredMark={false}
            layout="vertical"
            onValuesChange={
                (changed, formValues) => {
                    setIsFormTouched(isFormChanged({ ...formValues }, { ...getInitialValues() }));
                    validateForm(formValues)
                    handleChange(formValues)
                }
            }
        >

            <div className='rt--flex rt--registration-form-controls-section-wrapper'>
                {
                    groupedFormControls.map((item, i) => (
                        <Fragment key={i}>
                            {
                                item.items.length > 0 && (
                                    <div className={classNames('rt--registration-form-part', !isMobileDevice && i > 0 && 'rt--registration-form-part-splitted')} key={i}>
                                        <h4
                                            className='rt--form-section-title rt--title rt--mb-16 rt--mt-16 rt--font-bold rt--font-biger'>
                                            {item.title}
                                        </h4>
                                        <div className='rt--registration-form-controls-section'>
                                            <div className='rt--flex rt--registration-form-controls'>
                                                <div className='rt--flex rt--registration-form-controls-row rt--registration-form-controls-head rt--align-center rt--mb-16'>
                                                    <div className='rt--registration-form-controls-name rt--flex rt--align-center rt--justify-start'>
                                                        {i === 0 && (
                                                            <Tooltip
                                                                title={renderInfoTooltipContent()}
                                                                trigger={["hover", "click"]}
                                                                placement="bottomLeft"
                                                                enableMobile={true}
                                                            >
                                                                <Icon name='info' size={20} />
                                                            </Tooltip>
                                                        )}
                                                    </div>
                                                    {
                                                        (isMobileDevice ?
                                                            <Tooltip
                                                                title={renderInfoTooltipContentTitleMust()}
                                                                trigger={["hover", "click"]}
                                                                placement="bottomLeft"
                                                                enableMobile={true}
                                                            >
                                                                <div className='rt--registration-form-controls-control rt--flex rt--align-center rt--justify-center rt--mr-8'>
                                                                    <span className='rt--title rt--font-extra-bold rt--font-normal'>{t("backoffice.registrationform.must")}</span>
                                                                </div>
                                                            </Tooltip>
                                                            :
                                                            <div className='rt--registration-form-controls-control rt--flex rt--align-center rt--justify-center rt--mr-8'>
                                                                <span className='rt--title rt--font-extra-bold rt--font-normal'>{t("backoffice.registrationform.must")}</span>
                                                            </div>
                                                        )
                                                    }

                                                    {
                                                        (isMobileDevice ?
                                                            <Tooltip
                                                                title={renderInfoTooltipContentTitleMay()}
                                                                trigger={["hover", "click"]}
                                                                placement="bottomLeft"
                                                                enableMobile={true}
                                                            >
                                                                <div className='rt--registration-form-controls-control rt--flex rt--align-center rt--justify-center rt--mr-8'>
                                                                    <span className='rt--title rt--font-extra-bold rt--font-normal'>{t("backoffice.registrationform.may")}</span>
                                                                </div>
                                                            </Tooltip>
                                                            :
                                                            <div className='rt--registration-form-controls-control rt--flex rt--align-center rt--justify-center rt--mr-8'>
                                                                <span className='rt--title rt--font-extra-bold rt--font-normal'>{t("backoffice.registrationform.may")}</span>
                                                            </div>
                                                        )
                                                    }

                                                    {
                                                        (isMobileDevice ?
                                                            <Tooltip
                                                                title={renderInfoTooltipContentTitleNone()}
                                                                trigger={["hover", "click"]}
                                                                placement="bottomLeft"
                                                                enableMobile={true}
                                                            >
                                                                <div className='rt--registration-form-controls-control rt--flex rt--align-center rt--justify-center'>
                                                                    <span className='rt--title rt--font-extra-bold rt--font-normal'>{t("backoffice.registrationform.none")}</span>
                                                                </div>
                                                            </Tooltip>
                                                            :
                                                            <div className='rt--registration-form-controls-control rt--flex rt--align-center rt--justify-center'>
                                                                <span className='rt--title rt--font-extra-bold rt--font-normal'>{t("backoffice.registrationform.none")}</span>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            {
                                                item.items
                                                    .map((control, index) => (
                                                        <div
                                                            key={control.name}
                                                        >
                                                            <Form.Item
                                                                label=""
                                                                name={control.name}
                                                                className={i === groupedFormControls.length - 1 && index === item.items.length - 1 ? "rt--form-item-without-margin" : ""}
                                                            >
                                                                <Radio.Group
                                                                    key={control.name}
                                                                    className='rt--flex rt--registration-form-controls-row rt--align-center'
                                                                >
                                                                    <div className='rt--registration-form-controls-name rt--flex rt--align-center'>
                                                                        <span className='rt--title rt--font-regular rt--font-normal'>{fieldNameRender(control.name)}</span>
                                                                        {
                                                                            control.preValidationRule === COMPANY_PREVALIDATION_RULE.REGEXP && (
                                                                                <Tooltip
                                                                                    title={t("backoffice.registrationform.regexp")}
                                                                                    trigger={["hover", "click"]}
                                                                                    placement="bottomLeft"
                                                                                    enableMobile={true}
                                                                                >
                                                                                    <div 
                                                                                        className='rt--flex rt--align-center rt--ml-4 rt--cursor-pointer'
                                                                                        onClick={() => {
                                                                                            setRegExpOpened(prev => prev.includes(control.name) ? prev.filter(c => c !== control.name) : prev.concat([control.name]))
                                                                                        }}
                                                                                    >
                                                                                        <Icon name="regex" size={20} />
                                                                                    </div>
                                                                                </Tooltip>
                                                                            )
                                                                        }

                                                                        {
                                                                            control.isUnique && (
                                                                                <Tooltip
                                                                                    title={t("backoffice.registrationform.unique")}
                                                                                    trigger={["hover", "click"]}
                                                                                    placement="bottomLeft"
                                                                                    enableMobile={true}
                                                                                >
                                                                                    <Icon name="info" size={20} className='rt--ml-4 rt--cursor-pointer'/>
                                                                                </Tooltip>
                                                                            )
                                                                        }


                                                                    </div>
                                                                    <div className='rt--registration-form-controls-control rt--flex rt--align-center rt--justify-center rt--mr-8'>
                                                                        {
                                                                            renderRadioButton(control, COMPANY_CONTROL_RULE.REQUIRED)
                                                                        }
                                                                    </div>
                                                                    <div className='rt--registration-form-controls-control rt--flex rt--align-center rt--justify-center rt--mr-8'>
                                                                        {
                                                                            renderRadioButton(control, COMPANY_CONTROL_RULE.OPTIONAL)
                                                                        }
                                                                    </div>
                                                                    <div className='rt--registration-form-controls-control rt--flex rt--align-center rt--justify-center'>
                                                                        {
                                                                            renderRadioButton(control, COMPANY_CONTROL_RULE.HIDDEN)
                                                                        }
                                                                    </div>

                                                                </Radio.Group>
                                                            </Form.Item>
                                                            {
                                                                control.preValidationRule === COMPANY_PREVALIDATION_RULE.REGEXP && (
                                                                    <Form.Item
                                                                        className={!regExpOpened.includes(control.name) ? "rt--general-form-item rt--hidden" : "rt--general-form-item" + (!userCanModify ? " rt--form-item-disabled" : "")}
                                                                        label=""
                                                                        name={control.name + "_regexp"}
                                                                        rules={[
                                                                            ({ getFieldValue }) => ({
                                                                                validator(rule, value) {
                                                                                    return validateRegex(value, t);
                                                                                }
                                                                            })
                                                                        ]}
                                                                        data-placeholder={`${t('backoffice.common.enter')} ${t(`backoffice.registrationform.regexp`)}`}
                                                                    >
                                                                        <Input
                                                                            placeholder={`${t('backoffice.common.enter')} ${t(`backoffice.registrationform.regexp`)}`}
                                                                            disabled={!userCanModify}
                                                                        />
                                                                    </Form.Item>
                                                                )
                                                            }
                                                        </div>
                                                    ))
                                            }
                                        </div>
                                    </div>
                                )}
                        </Fragment>

                    ))
                }

            </div>
            {
                isFormInvalid && <span className="rt--error-text rt--title rt--font-normal rt--font-regular rt--pt-8" >{t(`backoffice.registrationform.mustRequired`)}</span>
            }
        </Form>
    )
})

/** FormBuilder propTypes
 * PropTypes
*/
FormBuilder.propTypes = {
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func,
    /** Sections Form Controls Fields by sections */
    sections: PropTypes.array,
    /** Form Controls data */
    formData: PropTypes.arrayOf(registrationFormType),
    /** Show user have permission to change fields value or not */
    userCanModify: PropTypes.bool,
    /** Fires on form submit */
    onFormFinish: PropTypes.func,
    /** On form values change validates form values  */
    validateFormValues: PropTypes.func,
    /** Function fires on form values change  */
    onFormChange: PropTypes.func,
    /** Field name renderer function */
    fieldNameRender: PropTypes.func,
    /** Tille */
    title: PropTypes.string
};

export default FormBuilder;