import React from 'react';
import { Provider } from 'react-redux';

import store from 'store/configureStore';

import AntdProvider from 'core/providers/antdProvider';
import DeviceProvider from 'core/providers/deviceProvider';

import CssDataAttributes from "core/global/cssDataAttributes";
import RTLSpecificStyles from "core/global/rtlSpecificStyles";
import IOSSpecificFunctional from "core/global/iosSpecificFunctional";
import DebugComponent from 'core/lib/baseStore/debugStore/debugComponent';
import BrowserRouter from 'core/providers/routerProvider';

const App = () => {

    return (
        <DeviceProvider>
            <Provider store={store}>
                <AntdProvider>
                    <div className="App">
                        <CssDataAttributes />
                        <RTLSpecificStyles />
                        <IOSSpecificFunctional />
                        <DebugComponent />
                        <BrowserRouter />
                    </div>
                </AntdProvider>
            </Provider>
        </DeviceProvider>
    )
}

export default App;