import React from 'react';

import { useDebugSelector } from '../../..';

import ObjectViewer from '../ObjectViewer';

const DebugValueTab = () => {
    const { selectedChange } = useDebugSelector();

    if (!selectedChange) {
        return <span className="rt--debug-message-big">Select a change first!</span>
    }

    return (
        <ObjectViewer value={selectedChange.value} name={selectedChange.path} />
    );
};

export default DebugValueTab;
