import ROUTES from "core/constants/routes/routes";

import AffiliateManagersComponent from "pages/userManagement/affiliateManagers/affiliateManagers.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const AffiliateManagersPageRoute = {
	path: ROUTES.AFFILIATE_MANAGERS,
	element: AffiliateManagersComponent ,
    permission: { resource: PERMISSION_RESOURCE.AFFILIATE_MANAGER, action: PERMISSION_ACTION.VIEW }
};

export default AffiliateManagersPageRoute;
