import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import EntityActions from "components/common/entityActions";
import SetPasswordComponent from "components/common/setPassword";

import { changeUserState, unlockUser, deleteUser } from "store/actions/portal/userManagement/users/users.action";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'core/constants/permission';

import { USER_STATE } from 'constants/user.constants';
import ROUTES from "core/constants/routes/routes";

import userGeneralInfoType from "types/user/generalInfo.type";

import { useGlobalSelector, userInfoSelector } from 'core/stores/globalStore';
import usePermissions from 'core/hooks/usePermission';

/** User Actions Button Component */
const UserActionsComponent = ({
    generalInfo,
    unlockUser,
    deleteUser,
    changeUserState
}) => {

    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const userInfo = useGlobalSelector(userInfoSelector);

    const searchParams = useParams();
    const { search } = useLocation();
    const navigate = useNavigate();

    const mainCompany = (new URLSearchParams(search)).get("mainCompany");
    const mainCompanyId = (new URLSearchParams(search)).get("mainCompanyId");

    /** Flag to show/hide set password popup */
    const [showSetPasswordPopup, setShowSetPasswordPopup] = useState(false);

    const canEdit = (
        permissionUtils.has( PERMISSION_RESOURCE.ADMIN, PERMISSION_ACTION.MODIFY ) &&
        searchParams.id !== userInfo.id &&
        mainCompany
    )



    /** Actions list */
    const actions = [
        /** Set Password */
        {
            key: "setPassword",
            buttonType: "secondary",
            buttonClassName: "rt--button-secondary",
            title: t('backoffice.common.setPassword'),
            icon: "password",
            onClick: () => setShowSetPasswordPopup(true),
            disabled: !canEdit || generalInfo.state === USER_STATE.BLOCKED
        },
        /** Block/Unblock */
        {
            key: "block",
            buttonType: "secondary",
            title: generalInfo.state === USER_STATE.BLOCKED ? t('backoffice.common.unblock') : t('backoffice.common.block'),
            buttonClassName: generalInfo.state === USER_STATE.BLOCKED ? "rt--button-secondary" : "rt--button-danger",
            onClick: reason => {
                changeUserState({
                    id: searchParams.id,
                    enabled: generalInfo.state === USER_STATE.BLOCKED,
                    reason: reason
                })
            },
            icon: "block",
            isStatusChange: true,
            disabled: !canEdit
        },
        /** Unlock */
        {
            key: "unlock",
            buttonType: "secondary",
            buttonClassName: "rt--button-secondary",
            title: t('backoffice.common.unlock'),
            icon: "lock",
            onClick: () => {
                unlockUser(searchParams.id)
            },
            isConfirmation: true,
            confirmationMessage: t('backoffice.users.doYouWanttoUnlock'),
            disabled: !canEdit || ( generalInfo.state !== USER_STATE.LOCKED_2_FA && generalInfo.state !== USER_STATE.LOCKED_LOGIN )
        },
        /** Delete */
        {
            key: "delete",
            buttonType: "danger",
            title: t('backoffice.common.delete'),
            icon: "delete",
            buttonClassName: "rt--button-danger",
            onClick: () => {
                deleteUser(searchParams.id, () => {
                    navigate(ROUTES.USERS, { replace: true })
                })
            },
            isConfirmation: true,
            confirmationMessage: t('backoffice.users.doYouWanttoDelete'),
            disabled: (
                !permissionUtils.has( PERMISSION_RESOURCE.ADMIN, PERMISSION_ACTION.DELETE) ||
                searchParams.id === userInfo.id 
            )
        },
    ]

    return (
        <Fragment>
            <EntityActions 
                actions={actions}
            />

            {
                showSetPasswordPopup && (
                    <SetPasswordComponent
                        onClose={() => setShowSetPasswordPopup(false)}
                        companyId={mainCompanyId}
                    />
                )
            }
        </Fragment>
        
    )
}

/** UserActionsComponent propTypes
    * PropTypes
*/
UserActionsComponent.propTypes = {
    /** Redux state, represents the general info of current editing admin  */
    generalInfo: userGeneralInfoType,
    /** Redux action to unlock user */
    unlockUser: PropTypes.func,
    /** Redux action to delete user */
    deleteUser: PropTypes.func,
    /** Redux action to block/unblock users */
    changeUserState: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        unlockUser: id => {
            dispatch(unlockUser(id));
        },

        changeUserState: data => {
            dispatch(changeUserState(data));
        },

        deleteUser: (id, onSuccess) => {
            dispatch(deleteUser(id, onSuccess));
        },
    }
)

const mapStateToProps = state => {
    return {
        generalInfo: state.users.edit.general
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserActionsComponent)