import React, { useState, useMemo, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";

import { DatePicker, Divider, Menu, Button } from 'antd';

import Dropdown from 'components/common/dropdown';
import Icon from 'components/common/icon';

import { TIME_PERIOD, TIME_PERIOD_TRANSLATION_SOURCE } from '../../constants';
import useDate from 'hooks/useDate';

const periods = Object.values(TIME_PERIOD).filter(r => r !== TIME_PERIOD.CUSTOM).map(period => ({
    value: period,
    text: TIME_PERIOD_TRANSLATION_SOURCE[period]
}))

/** Dropdown Range Picker Component for Dashboard */
const DropdownRangePicker = ({
    onChange,
    value,
}) => {

    const { t } = useTranslation();

    const { dateService } = useDate();

    const [isDatePickerOpened, setIsDatePickerOpened] = useState(false);

    const [popupVisible, setPopupVisible] = useState(false);

    const [internalValue, setInternalValue] = useState(value?.period ?? periods[0].value);

    const [pickerValue, setPickerValue] = useState([value?.from ?? null, value?.to ?? null]);

    const handleMenuItemClick = key => {
        if(key === TIME_PERIOD.CUSTOM){
            setIsDatePickerOpened(!isDatePickerOpened);
            return;
        } else {
            setInternalValue(key);
            setPopupVisible(false);
            setPickerValue([null, null]);
            onChange?.({
                period: key,
                from: null,
                to: null
            })
        }
    }

    const disabledDate = currentDate => {
        if (!currentDate) return false;
        if (dateService.isAfter( currentDate, dateService.endOfTomorrow() ) ) {
            return true
        }
        return false;
    }

    const handlePickerChange = (...args) => {
        const [startDate, endDate] = args[0] ? args[0] : [null, null];

        if (startDate && endDate) {
            if(dateService.isSame(startDate, endDate, "day")){
                return;
            }
        }

        setPickerValue(...args);
    }

    const seletedText = useMemo(() => {
        if(internalValue === TIME_PERIOD.CUSTOM){
            return (
                <Fragment>
                    <span>{dateService.format(pickerValue[0], false)}</span>
                    <div className='rt--datepicker-separator' />
                    <span>{dateService.format(pickerValue[1], false)}</span>
                </Fragment>
            )
        }
        const period = periods.find(p => p.value === internalValue);
        if(!period) return "";
        return t(period.text);
    }, [internalValue, pickerValue])

    useEffect(() => {
        setInternalValue(value?.period);
        setPickerValue([value?.from, value?.to])
    }, [value])

    useEffect(() => {
        if(!popupVisible){
            setIsDatePickerOpened(false)
        }
    }, [popupVisible])

    return (
        <div className='rt--dropdown-rangepicker'>
            <Dropdown
                overlayClassName="rt--dropdown-rangepicker-dropdown"
                disabled={false}
                popupVisible={popupVisible}
                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                onVisibleChange={e => setPopupVisible(e)}
                placement="bottomRight"
                overlay={
                    <Menu
                        selectedKeys={[]} // Must to be empty every time so that every item can't stay selected
                        className="rt--dropdown-rangepicker-menu"
                    >
                        {
                            periods.map((period, index) => (
                                <Menu.Item key={index} onClick={() => handleMenuItemClick(period.value)}>
                                    <div
                                        className="rt--flex rt--align-center"
                                    >
                                        <span className="rt--title rt--font-regular rt--font-normal rt--pl-4 rt--pr-4">
                                            {
                                                t(period.text)
                                            }
                                        </span>

                                    </div>
                                </Menu.Item>
                            ))
                        }
                        <Menu.Item key="divider" className='rt--dropdown-rangepicker-divider'>
                            <Divider className='rt--divider-dropdown' />
                        </Menu.Item>
                        <Menu.Item 
                            className='rt--dropdown-rangepicker-menu-custom'
                            key={TIME_PERIOD.CUSTOM}
                        >
                            <DatePicker.RangePicker
                                open={isDatePickerOpened}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                className="rt--datepicker"
                                dropdownClassName="rt--datepicker-popup rt--dropdown-rangepicker-popup"
                                disabledDate={disabledDate}
                                format={dateService.getFormat(false)}
                                onChange={handlePickerChange}
                                value={pickerValue}
                                renderExtraFooter={() => (
                                    <div className='rt--flex rt--justify-end rt--align-center rt--pt-16 rt--pb-16'>
                                        <Button
                                            htmlType="button"
                                            type="text"
                                            className="rt--button rt--button-ghost rt--dropdown-rangepicker-menu-datepicker-button rt--ml-16"
                                            onClick={() => setPopupVisible(false)}
                                        >
                                            <span>{t("backoffice.common.cancel")}</span>
                                        </Button>
                                        <Button
                                            htmlType="button"
                                            type="primary"
                                            className="rt--button rt--button-main rt--dropdown-rangepicker-menu-datepicker-button rt--ml-16"
                                            onClick={() => {
                                                setInternalValue(TIME_PERIOD.CUSTOM);
                                                setPopupVisible(false);
                                                onChange?.({
                                                    period: TIME_PERIOD.CUSTOM,
                                                    from: pickerValue[0],
                                                    to: pickerValue[1]
                                                })
                                            }}
                                            disabled={!pickerValue[0] || !pickerValue[1]}
                                        >
                                            <span>{t("backoffice.common.apply")}</span>
                                        </Button>
                                    </div>
                                )}
                            />
                            <div
                                className="rt--dropdown-rangepicker-menu-custom-inner rt--flex rt--align-center rt--justify-between"
                                onClick={() => handleMenuItemClick(TIME_PERIOD.CUSTOM)}
                            >
                                <span className="rt--title rt--font-regular rt--font-normal rt--pl-4 rt--pr-4">
                                    {
                                        t("backoffice.dashboard.custom")
                                    }
                                </span>
                                <Icon name="right" />
                            </div>
                        </Menu.Item>
                    </Menu>
                }
            >
                <div className="rt--dropdown-rangepicker-selected rt--flex rt--justify-between rt--align-center">
                    <span className='rt--pr-8 rt--flex rt--align-center'>{seletedText}</span>
                    <Icon name="date" />
                </div>
            </Dropdown>
        </div>
    )
}

DropdownRangePicker.propTypes = {
    /** On Change function */
    onChange: PropTypes.func,
    /** Value */
    value: PropTypes.shape({
        period: PropTypes.oneOf(Object.values(TIME_PERIOD)),
        from: PropTypes.object,
        to: PropTypes.object
    }),
}


export default DropdownRangePicker;