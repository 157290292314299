import React from 'react';

import Tooltip from 'components/common/tooltip';
import Icon from 'components/common/icon';

import { TEMPLATE_TRIGGER_NAMES_TT_KEYS } from "constants/systemNotifications.constants";

export const getTableColumns = additionalProps => {
    const { t } = additionalProps;

    return [
        {
            title: "backoffice.communication.triggerName",
            dataIndex: "triggerType",
            alwaysVisible: true,
            mobileLevel: 1,
            render: (triggerType) => (
                <div className='rt--flex rt--align-center'>
                    <span className='rt--mr-6'>{t(`backoffice.communication.${TEMPLATE_TRIGGER_NAMES_TT_KEYS[triggerType]}`)}</span>
                    <Tooltip
                        title={<span>{t(`backoffice.communication.${TEMPLATE_TRIGGER_NAMES_TT_KEYS[triggerType]}Description`)}</span>}
                        trigger={["hover", "click"]}
                        placement="rightTop"
                        enableMobile={true}
                    >   
                        <div className='rt--flex rt--align-center'>
                            <Icon name='info' size={18} />
                        </div>
                    </Tooltip>
                </div>
            )
        },
        {
            title: "backoffice.communication.modifiedAt",
            dataIndex: "modifiedAt",
            isDateTime: true,
            mobileLevel: 2,
        },
        {
            title: "backoffice.communication.modifiedBy",
            dataIndex: "modifiedBy",
            mobileLevel: 3,
        }
    ];
}