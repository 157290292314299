import axios from "core/lib/axios";
import Methods from 'constants/methods.constants';
import ApiUrls from 'constants/api.constants';

import {
    SET_PASSWORD_SETTINGS_ACTION_BEFORE,
    SET_PASSWORD_SETTINGS_ACTION_FINISH,
    SET_PASSWORD_SETTINGS
} from '../../actionTypes';

const setPasswordSettingsBefore = () => ({
    type: SET_PASSWORD_SETTINGS_ACTION_BEFORE
})

const setPasswordSettingsFinished = () => ({
    type: SET_PASSWORD_SETTINGS_ACTION_FINISH
})

const setPasswordSettings = passwordSettings => ({
    type: SET_PASSWORD_SETTINGS,
    payload: { passwordSettings }
})

export const getPasswordSettings = token => {
    return dispatch => {
        dispatch(setPasswordSettingsBefore());

        const headers = token ? {
            'Authorization' : 'Bearer ' + token
        } : undefined;

        return axios({
            url: ApiUrls.GET_ACCOUNT_PASSWORD_SETTINGS,
            method: Methods.GET,
            params: {},
            headers: headers
        })
        .then(({data : {value: passwordSettings}}) => {
            dispatch(setPasswordSettings(passwordSettings));
            dispatch(setPasswordSettingsFinished());
        })
        .catch(() => {
            dispatch(setPasswordSettingsFinished());
        })
    }
}

export const getResetPasswordSettings = token => {
    return dispatch => {
        dispatch(setPasswordSettingsBefore());

        return axios({
            url: ApiUrls.GET_ACCOUNT_RESET_PASSWORD_SETTINGS,
            method: Methods.GET,
            params: {
                token
            }
        })
        .then(({data : {value: passwordSettings}}) => {
            dispatch(setPasswordSettings(passwordSettings));
            dispatch(setPasswordSettingsFinished());
        })
        .catch(() => {
            dispatch(setPasswordSettingsFinished());
        })
    }
}

