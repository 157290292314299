import React, { memo, useState, useMemo } from "react";
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";
import { Tabs as AntTabs } from 'antd';

import Filters from "../../components/filters";
import LineChart from "components/common/lineChart";

import { OPERATIONAL_ENTITY_TYPE, LINE_CHART_CONFIGS_BY_ENTITY, LINE_CHART_ENTITY_TYPE } from "../../constants";
import { USER_ROLE } from "constants/user.constants";

import { isMobile } from "utils/common";
import {
    getLineChartData,
    getOperationsLineChartTooltip
} from "../../helpers";
import { constructLineChartConfigs } from "utils/lineChart";
import {
    getCheckPointsTooltipConfigs,
    getMainLineConfigs,
    getXAxisConfigs
} from "utils/lineChart/configCreators";

import useOperationalLineChart from "../../hooks/useOperationalLineChart";
import useDate from "hooks/useDate";

import { useAuthSelector, userRoleSelector } from "core/stores/authStore";

const OperationalLineChart = ({
    updateLastUpdatedDate,
    filters,
    updateFilters
}) => {
    const { t } = useTranslation();

    const userRole = useAuthSelector(userRoleSelector);

    const { dateService } = useDate();

    const [entityType, setEntityType] = useState(OPERATIONAL_ENTITY_TYPE.PLAYER)

    const { chartData, isLoading } = useOperationalLineChart(
        entityType,
        filters.period,
        updateLastUpdatedDate
    );

     /** Chart Data */
     const configs = useMemo(() => {
        const valuePropNames = entityType === OPERATIONAL_ENTITY_TYPE.PLAYER ? [
            LINE_CHART_ENTITY_TYPE.REGISTRATIONS_COUNT
        ]: [
            LINE_CHART_ENTITY_TYPE.CPA_COUNT,
            LINE_CHART_ENTITY_TYPE.FTD_COUNT
        ];

        const charts = valuePropNames.map(valuePropName => (
            getLineChartData(chartData || [], filters.period, valuePropName, dateService, t)
        ))

        // Define names based on entityType
        const lineNames = entityType === OPERATIONAL_ENTITY_TYPE.PLAYER ? [
            `<span class='rt--highchart-legend-title'>${t("backoffice.dashboard.registration")}</span>`
        ] : [
            `<span class='rt--highchart-legend-title'>${t("backoffice.dashboard.qualifiedDepositors")}</span>`,
            `<span class='rt--highchart-legend-title'>${t("backoffice.dashboard.ftds")}</span>`
        ];

        const categories = charts[0].categories;

        return constructLineChartConfigs({
            xAxisConfigs: getXAxisConfigs({
                checkPoints: categories,
            }),
            checkPointsTooltipConfigs: getCheckPointsTooltipConfigs({
                tooltipHTMLGenerator: (element) => (
                    getOperationsLineChartTooltip({ 
                        element, 
                        chartData: charts, 
                        period: filters.period, 
                        entityType, 
                        dateService,
                        t 
                    })
                )
            }),
            lines: charts.map((chart, index) => (
                getMainLineConfigs({
                    ...LINE_CHART_CONFIGS_BY_ENTITY[valuePropNames[index]],
                    data: chart.values,
                    name: lineNames[index]
                }) 
            )) 
        })
    }, [chartData, filters.period, entityType, dateService, t])

    return (
        <div className={"rt--chart-section rt--pb-24 rt--pt-24 " + (isMobile() ? 'rt--pl-6 rt--pr-6' : 'rt--pl-24 rt--pr-24')}>
            <AntTabs
                animated={false}
                defaultActiveKey={OPERATIONAL_ENTITY_TYPE.PLAYER}
                onChange={value => setEntityType(Number(value))}
                destroyInactiveTabPane={true}
                className='rt--tabs rt--chart-tab'
                tabBarExtraContent={
                    !isMobile() ? (
                        <Filters filters={filters} updateFilters={updateFilters} />
                    ) : undefined
                }
            >
                <AntTabs.TabPane
                    tab={<span>{t("backoffice.dashboard.registration")}</span>}
                    key={OPERATIONAL_ENTITY_TYPE.PLAYER}
                >
                    <LineChart
                        configs={configs}
                        hideChart={chartData.length === 0}
                        isLoading={isLoading}
                    />
                </AntTabs.TabPane>

                {
                    userRole !== USER_ROLE.AFFILIATE && (
                        <AntTabs.TabPane
                            tab={<span>{t("backoffice.dashboard.depositCount")}</span>}
                            key={OPERATIONAL_ENTITY_TYPE.DEPOSIT_COUNT}
                        >
                            <LineChart
                                configs={configs}
                                hideChart={chartData.length === 0}
                                isLoading={isLoading}
                            />
                        </AntTabs.TabPane>
                    )
                }
                
            </AntTabs>
        </div>
    )
}

OperationalLineChart.propTypes = {
    updateLastUpdatedDate: PropTypes.func,
    filters: PropTypes.object,
    updateFilters: PropTypes.func
};

export default memo(OperationalLineChart);