import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Form, Row, Col } from 'antd';

import Modal from "components/common/modal";
import TextAreaInput from 'components/common/textAreaInput';
import ImageUploader from 'components/common/imageUploader';

import { editInvoice } from "store/actions/portal/payment/invoices/invoices.action";

import { POPUP_SIZE } from 'constants/common.constants';

import { makeImagePath } from 'utils/common';

import invoiceType from 'types/payment/invoice.type';

/** Invoice Editing Popup Component */
const InvoiceEditComponent = ({
    isSaving,
    editInvoice,
    invoice,
    onClose
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const [isFormTouched, setIsFormTouched] = useState(false);

    /** Fires when form submitted
       * @function
       * @memberOf InvoiceEditComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                const values = {
                    id: invoice.id,
                    createdAt: invoice.createdAt,
                    note: data.note
                }
                if (data.file.name !== "rt--old") {
                    values.file = data.file;
                }

                editInvoice(
                    values,
                    () => {
                        onClose();
                    });
            }).catch(ex => {
                console.log(ex)
            })
    }

    useEffect(() => {
        setFieldsValue({
            note: invoice.note,
            file: new File(["rt--old"], "rt--old")
        })
    }, [invoice])

    const handleValuesChange = values => {

        if (values.file?.name !== "rt--old") {
            setIsFormTouched(true)
        } else {
            setIsFormTouched(values.note !== invoice.note);
        }
    }

    return (
        <Modal
            title={t('backoffice.invoices.editInvoice')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.save')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            disableOkButton={!isFormTouched}
            size={POPUP_SIZE.SMALL}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                onValuesChange={(_, values) => handleValuesChange(values)}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.invoices.note')}`}
                            name="note"
                            rules={[
                                { max: 150, message: t('backoffice.validation.fieldInvalid') },
                                { min: 2, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            validateFirst
                            className="rt--general-form-item"
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.invoices.note')}`}
                        >
                            <TextAreaInput
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.invoices.note')}`}
                                maxLength={150}
                                rows={4}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.invoices.screenshot')}`}
                            name="file"
                            className="rt--form-item-without-margin"
                        >
                            <ImageUploader
                                size={1024 * 1024 * 3}
                                defaultFile={invoice?.attachmentPath ? {
                                    url: makeImagePath(invoice.attachmentPath),
                                    status: "done",
                                    percent: 100
                                } : null}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

/** InvoiceEditComponent propTypes
    * PropTypes
*/
InvoiceEditComponent.propTypes = {
    /** Redux state property, is true when editing invoice request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to edit invoice */
    editInvoice: PropTypes.func,
    /** Current editin invoice */
    invoice: invoiceType,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        editInvoice: (invoice, onSuccess) => {
            dispatch(editInvoice(invoice, onSuccess));
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.invoices.isSaving
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceEditComponent)