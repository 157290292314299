//#region react
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useNavigate } from 'react-router-dom';
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import Filters from "./filters.component";
import TemplateCreateComponent from "./template-create.component";
import TemplateRenameComponent from "./template-rename.component";
import TemplateDuplicateComponent from "./template-duplicate.component";
import NewsletterCreateComponent from "../newsletters/createNewsletter";

//#endregion

//#region actions
import {
    getNewsletterTemplates,
    setNewsletterTemplatesSorting,
    setNewsletterTemplatesFilters,
    deleteNewsletterTemplate
} from 'store/actions/portal/communications/newsletter/templates.action';
import { getNewsletterTemplateContent } from 'store/actions/portal/newsletterCustomize/common.action';
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { openBase64HtmlInNewTab } from 'utils/newsletter';
import { isMobile } from 'utils/common';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'core/constants/permission';
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { getTableCloumns } from './columns';
import { NEWSLETTER_TEMPLATES } from 'constants/pageName.constants';
import ROUTES from "core/constants/routes/routes";
//#endregion

//#region types
import templateType from 'types/newsletter/template.type';
import sortingType from 'types/common/sorting.type';
//#endregion

import { globalProjectIdSelector, useAuthSelector } from 'core/stores/authStore';

import usePermissions from 'core/hooks/usePermission';

/** Notifications Page Templates Tab Component */
const TemplatesComponent = ({
    templates,
    sorting,
    filters,
    total,
    isLoading,
    getNewsletterTemplates,
    getNewsletterTemplateContent,
    setNewsletterTemplatesSorting,
    setNewsletterTemplatesFilters,
    deleteNewsletterTemplate
}) => {
    const { t } = useTranslation();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);
    const permissionUtils = usePermissions();

    const navigate = useNavigate();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: NEWSLETTER_TEMPLATES });

    const [createPopup, setCreatePopup] = useState(false);
    const [renamePopupData, setRenamePopupData] = useState(null);
    const [duplicatePopupData, setDuplicatePopupData] = useState(null);

    const [newsletterCreatePopup, setNewsletterCreatePopup] = useState(null);

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasCreatePermission = permissionUtils.has( PERMISSION_RESOURCE.COMMUNICATION_NEWSLETTER, PERMISSION_ACTION.CREATE )

    const hasModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.COMMUNICATION_NEWSLETTER, PERMISSION_ACTION.MODIFY )

    const hasDeletePermission = permissionUtils.has( PERMISSION_RESOURCE.COMMUNICATION_NEWSLETTER, PERMISSION_ACTION.DELETE )

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setNewsletterTemplatesFilters({
            ...filters,
            nameOrId: value
        })
    }

    const handleEditIconClick = template => {
        navigate(`${ROUTES.NEWSLETTER_CUSTOMIZE}/${template.id}`);
    };

    const handleRenameIconClick = template => {
        setRenamePopupData(template)
    };

    const handleDuplicateIconClick = template => {
        setDuplicatePopupData(template)
    };

    const handleSendIconClick = template => {
        setNewsletterCreatePopup(template.id)
    };

    const handlePreviewIconClick = template => {
        getNewsletterTemplateContent(template.id, content => {
            openBase64HtmlInNewTab(content)
        })
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded and columnsForExport are only needed in the desktop version.
    const {
        mainTableColumns,
        columnsThatCanBeIncluded
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableCloumns,
            constructForInclude: true,
            includedColumns,
            additionalProps: {
                t
            },
            t
        })
    }, [includedColumns, t]);

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasModifyPermission) {
        if(!isMobile()){
            tableRowActions.push(
                {
                    title: t('backoffice.common.edit'),
                    icon: "edit",
                    onClick: handleEditIconClick
                }
            )
        }
        

        tableRowActions.push(
            {
                title: t('backoffice.communication.rename'),
                icon: "rename",
                onClick: handleRenameIconClick
            }
        )
    }

    if (hasCreatePermission) {
        tableRowActions.push(
            {
                title: t('backoffice.communication.duplicate'),
                icon: "copy",
                onClick: handleDuplicateIconClick
            }
        )
    }

    tableRowActions.push(
        {
            title: t('backoffice.communication.preview'),
            icon: "eye",
            onClick: handlePreviewIconClick
        }
    )

    if (hasCreatePermission) {
        tableRowActions.push(
            {
                title: t('backoffice.communication.send'),
                icon: "send",
                onClick: handleSendIconClick
            }
        )
    }

    if(hasDeletePermission){
        tableRowActions.push(
            {
                title: t('backoffice.common.delete'),
                confirmationMessage: t("backoffice.communication.doYouWantToDeleteNewsletter"),
                confirmationTitle: t("backoffice.common.delete"),
                icon: "trash",
                showConfirmation: true,
                className: "rt--button-danger",
                onClick: template => deleteNewsletterTemplate(template.id)
            },
        )
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        search: {
            onSearch: handleSearchChange,
            loadFn: getNewsletterTemplates,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.NEWSLETTER,
                dependencies: [globalProjectId]
            },
            placeholder: t("backoffice.communication.nameOrId"),
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        button: {
            icon: "plus",
            type: "primary",
            onClick: () => setCreatePopup(true),
            text: t("backoffice.communication.createNewsletter"),
            enabled: hasCreatePermission
        }
    }

     //#endregion

    return (
        <TabTableDashboardLayout
            header={headerPartsData}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={templates}
                loadFn={getNewsletterTemplates}
                sorting={sorting}
                setSortingFn={setNewsletterTemplatesSorting}
                total={total}
                updateProps={[globalProjectId]}
                actions={tableRowActions}
                disableFullView={true}
            />

            {
                createPopup && (
                    <TemplateCreateComponent
                        onClose={() => setCreatePopup(false)}
                    />
                )
            }

            {
                newsletterCreatePopup && (
                    <NewsletterCreateComponent
                        templateId={newsletterCreatePopup}
                        onClose={() => setNewsletterCreatePopup(null)}
                    />
                )
            }

            {
                renamePopupData && (
                    <TemplateRenameComponent
                        template={renamePopupData}
                        onClose={() => setRenamePopupData(null)}
                    />
                )
            }

            {
                duplicatePopupData && (
                    <TemplateDuplicateComponent
                        template={duplicatePopupData}
                        onClose={() => setDuplicatePopupData(null)}
                    />
                )
            }
        </TabTableDashboardLayout>
    )
}

/** TemplatesComponent propTypes
    * PropTypes
*/
TemplatesComponent.propTypes = {
    /** Redux state property, represents the array of newsletter templates */
    templates: PropTypes.arrayOf(templateType),
    /** Redux state property, newsletter templates sorting details */
    sorting: sortingType,
    /** Redux state property, newsletter templates filters */
    filters: PropTypes.object,
    /** Redux state property, newsletter templates total count */
    total: PropTypes.number,
    /** Redux state property, is true when loading newsletter templates */
    isLoading: PropTypes.bool,
    /** Redux action to get newsletter templates */
    getNewsletterTemplates: PropTypes.func,
    /** Redux action to get newsletter template content */
    getNewsletterTemplateContent: PropTypes.func,
    /** Redux action to set newsletter templates sorting details */
    setNewsletterTemplatesSorting: PropTypes.func,
    /** Redux action to set newsletter templates filters */
    setNewsletterTemplatesFilters: PropTypes.func,
    /** Redux action to delete newsletter template */
    deleteNewsletterTemplate: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getNewsletterTemplates: nextPage => {
            dispatch(getNewsletterTemplates(nextPage))
        },
        setNewsletterTemplatesSorting: sorting => {
            dispatch(setNewsletterTemplatesSorting(sorting));
        },
        setNewsletterTemplatesFilters: filters => {
            dispatch(setNewsletterTemplatesFilters(filters));
        },
        deleteNewsletterTemplate: id => {
            dispatch(deleteNewsletterTemplate(id))
        },
        getNewsletterTemplateContent: (id, onSuccess) => {
            dispatch(getNewsletterTemplateContent(id, onSuccess))
        }
    }
)

const mapStateToProps = state => {
    return {
        templates: state.newsletter.templates.templates,
        total: state.newsletter.templates.total,
        sorting: state.newsletter.templates.sorting,
        filters: state.newsletter.templates.filters,
        isLoading: state.newsletter.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesComponent)