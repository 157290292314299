import isArray from "core/helpers/typeChecks/isArray";
import isNull from "core/helpers/typeChecks/isNull";
import isObjectLiteral from "core/helpers/typeChecks/isObjectLiteral";
import isString from "core/helpers/typeChecks/isString";
import isUndefined from "core/helpers/typeChecks/isUndefined";

export const toString = (value) => {
	if (isObjectLiteral(value) || isArray(value)) {
		return JSON.stringify(value);
	}

	if (isUndefined(value)) {
		return "undefined";
	}

	if (isNull(value)) {
		return "null";
	}

	if (isString(value)) {
		return `"${value}"`;
	}

	return String(value);
}
