import React from 'react';

import { binaryToFlags } from 'utils/common';

import { TRANSLATION_TYPE } from "constants/translation.constants";

const TRANSLATION_TYPE_NAMES = {
    [TRANSLATION_TYPE.PROMO]: "promo",
    [TRANSLATION_TYPE.BO]: "bo",
}

export const getTableColumns = additionalProps => {
    const { t } = additionalProps;

    return [
        {
            title: "backoffice.languages.name",
            dataIndex: "name",
            alwaysVisible: true,
            mobileLevel: 1,
            showOnlyValue: true,
            mobileRender: (_, record) => (
                <span className="rt--font-normal rt--font-extra-bold">
                    {`${record.name} - ${record.code}`}
                </span>
            ),
        },
        {
            title: "backoffice.languages.code",
            dataIndex: "code",
        },
        {
            title: "backoffice.languages.published",
            dataIndex: "publishType",
            mobileLevel: 2,
            showOnlyValue: true,
            render: (value) => {
                const translationTypes = binaryToFlags(Object.values(TRANSLATION_TYPE), value);
                
                if (translationTypes.length === 0) {
                    return "-"
                }
    
                const translationNames = translationTypes.map((type) => (
                    TRANSLATION_TYPE_NAMES[type] ? t(`backoffice.common.${TRANSLATION_TYPE_NAMES[type]}`) : ""
                ));
    
                return translationNames.join(", ");
            },
        },
    ];
    
}