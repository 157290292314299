import { message } from 'antd';

import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setProjectsActionBefore, setProjectsActionFinished, setProjectsSaveActionBefore, setProjectsSaveActionFinished } from './projects.action';

import { 
    SET_PROJECT_BRANDS,
    ADD_PROJECT_BRAND,
    UPDATE_PROJECT_BRAND,
    DELETE_PROJECT_BRAND
} from "../../../actionTypes";

const setProjectBrands = brands => ({
    type: SET_PROJECT_BRANDS,
    payload: { brands }
})

const setAddProjectBrand = brand => ({
    type: ADD_PROJECT_BRAND,
    payload: { brand }
})

const updateProjectBrand = brand => ({
    type: UPDATE_PROJECT_BRAND,
    payload: { brand }
})

const setDeleteProjectBrand = id => ({
    type: DELETE_PROJECT_BRAND,
    payload: { id }
})


export const getProjectBrands = () => {
    return dispatch => {
        dispatch(setProjectsActionBefore());
        return axios({
            url: ApiUrls.GET_PROJECT_BRANDS,
            method: Methods.GET
        })
        .then(({data : {value: brands}}) => {
            dispatch(setProjectBrands(brands))
            dispatch(setProjectsActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsActionFinished());
        })
    }
}

export const addProjectBrand = (brand, onSuccess) => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.ADD_PROJECT_BRAND,
            method: Methods.POST,
            data: {
                ...brand
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setAddProjectBrand(data.value));

                onSuccess && onSuccess();
            } 
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}

export const saveProjectBrand = brand => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_PROJECT_BRAND,
            method: Methods.POST,
            data: {
                ...brand
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(updateProjectBrand(data.value));
            } 
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}

export const deleteProjectBrand = id => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.DELETE_PROJECT_BRAND,
            method: Methods.DELETE,
            data: {
                id
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setDeleteProjectBrand(id));
            } 
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}

export const addProjectBrandUrl = (url, onSuccess) => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.ADD_PROJECT_BRAND_URL,
            method: Methods.POST,
            data: {
                ...url
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getProjectBrands());

                onSuccess && onSuccess();
            } 
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}

export const saveProjectBrandUrl = (url, onSuccess) => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_PROJECT_BRAND_URL,
            method: Methods.POST,
            data: {
                ...url
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getProjectBrands());

                onSuccess && onSuccess();
            } 
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}

export const deleteProjectBrandUrl = (brandId, id) => {
    return dispatch => {
        dispatch(setProjectsSaveActionBefore());
        return axios({
            url: ApiUrls.DELETE_PROJECT_BRAND_URL,
            method: Methods.DELETE,
            data: {
                brandId, 
                id
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getProjectBrands());
            } 
            dispatch(setProjectsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setProjectsSaveActionFinished());
        })
    }
}
