import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";

import { Spin, Button } from 'antd';

import {
    addPromoStoryBlock,
    addPromoStory,
    deletePromoStoryBlock,
    getPromoStories,
    savePromoStoryBlock,
    deletePromoStory
} from 'store/actions/portal/promoCustomize/sections.action';

import Table from 'components/common/table';
import Confirmation from 'components/common/confirmation';
import TableEmptyView from '../tableEmptyView';
import StoryAddEditComponent from './story-addEdit-component';
import StoryImgList from './storyImgList';

import { tableColumnsCreator } from 'utils/tableColumnsCreator';

import { PROMO_SECTION_TYPE } from 'constants/promo.constants';
import { EXPAND_TABLE_TYPES } from 'constants/common.constants';
import { tableColumns } from './columns';

const DELETE_CONFIRMATION_DEFAULT_DATA = {
    isVisible: false,
    storyBlockId: null
}

const Stories = ({
    data,
    isLoading,
    isSaving,
    getPromoStories,
    addPromoStoryBlock,
    savePromoStoryBlock,
    deletePromoStoryBlock,
    addPromoStory,
    deletePromoStory,
    customizeLanguage,
}) => {
    const { t } = useTranslation();

    const [addEditPopupData, setAddEditPopupData] = useState(null);

    const [deleteConfirmationData, setDeleteConfirmationData] = useState({
        ...DELETE_CONFIRMATION_DEFAULT_DATA
    })

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const showAddEditPopup = (data) => {
        setAddEditPopupData({
            isVisible: true,
            ...data
        })
    }

    const hideEditPopup = () => {
        setAddEditPopupData(null)
    }

    const showDeleteConfirmation = (rowData) => {
        setDeleteConfirmationData({
            storyBlockId: rowData.id,
            isVisible: true,
        })
    }

    const hideDeleteConfirmation = () => {
        setDeleteConfirmationData({
            ...DELETE_CONFIRMATION_DEFAULT_DATA
        })
    }

    const handleTableSwitcherChange = (isChecked, record) => {
        const requestBody = {
            name: record.name,
            id: record.id,
            enabled: isChecked
        }

        savePromoStoryBlock(requestBody);
    }

    const handleStoryAddEdit = (data) => {

        if (addEditPopupData.type === "addBlock") {
            return addPromoStoryBlock({
                name: data.name,
                file: data.file
            });
        }

        if (addEditPopupData.type === "addStory") {
            return addPromoStory({
                groupId: addEditPopupData.groupId,
                file: data.file,
            })
        }

        savePromoStoryBlock({
            name: data.name,
            file: data.file,
            id: addEditPopupData.id,
            enabled: addEditPopupData.enabled
        });
    }

    const handleStoryBlockDelete = () => {
        const requestBody = {
            id: deleteConfirmationData.storyBlockId,
        }

        deletePromoStoryBlock(requestBody);
        hideDeleteConfirmation();
    }

    const handleStoryDelete = (data) => {
        const requestBody = {
            ...data
        }

        deletePromoStory(requestBody);
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({ 
            mainColumns: tableColumns,
            t 
        });
    }, [t])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [
        {
            title: t("backoffice.promo.editStoryBlock"),
            icon: "edit",
            onClick: record => showAddEditPopup({
                name: record.name,
                file: record.imageUrl,
                id: record.id,
                enabled: record.enabled,
                type: "edit",
            }),
        },
        {
            title: t("backoffice.promo.deleteStoryBlock"),
            icon: "trash",
            onClick: showDeleteConfirmation,
        },
    ]

    //#endregion

    // Get Promo Stories
    useEffect(() => {
        getPromoStories();
    }, [customizeLanguage]);

    return (
        <Spin spinning={isLoading || isSaving}>
            <div className='rt--flex rt--justify-end rt--mb-16'>
                <Button
                    type="primary"
                    className="rt--button rt--button-main"
                    onClick={() => showAddEditPopup({
                        type: "addBlock",
                    })}
                    disabled={data.length === 5}
                >
                    <span className='rt--font-normal'>
                        {t('backoffice.promo.addBlock')}
                    </span>
                </Button>
            </div>

            <div className="rt--promo-customize-table-container-300">
                <Table
                    data={data}
                    renderEmpty={() => <TableEmptyView />}
                    columns={mainTableColumns}
                    noPagination={true}
                    actions={tableRowActions}
                    expandable={{
                        type: EXPAND_TABLE_TYPES.CONTENT,
                        details: record => record.stories && ({
                            content: (
                                <StoryImgList
                                    data={record.stories}
                                    groupId={record.id}
                                    showAddEditPopup={showAddEditPopup}
                                    onStoryDelete={handleStoryDelete}
                                />
                            ),
                        }),
                    }}
                    switcher={{
                        valueIndex: "enabled",
                        dataIndex: "enabled",
                        onChange: handleTableSwitcherChange,
                    }}
                />
            </div>

            {addEditPopupData && (
                <StoryAddEditComponent
                    onClose={hideEditPopup}
                    name={addEditPopupData.name}
                    file={addEditPopupData.file}
                    type={addEditPopupData.type}
                    onOk={handleStoryAddEdit}
                />
            )}

            <Confirmation
                title={t('backoffice.promo.deleteStoryBlock')}
                message={t('backoffice.promo.deleteStoryMessage')}
                onOk={handleStoryBlockDelete}
                onCancel={hideDeleteConfirmation}
                isVisible={deleteConfirmationData.isVisible}
            />
        </Spin>
    );
}

/** Stories propTypes
 * PropTypes
*/
Stories.propTypes = {
    data: PropTypes.array,
    /** Redux state property, is true when loading portal customization data */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when promo saving */
    isSaving: PropTypes.bool,
    /** Redux action to get promo stories */
    getPromoStories: PropTypes.func,
    /** Redux action to add promo story block */
    addPromoStoryBlock: PropTypes.func,
    /** Redux action to save promo story block */
    savePromoStoryBlock: PropTypes.func,
    /** Redux action to delete promo story block */
    deletePromoStoryBlock: PropTypes.func,
    /** Redux action to delete promo story */
    deletePromoStory: PropTypes.func,
    /** Redux action to add promo story image to current group */
    addPromoStory: PropTypes.func,
    /** Redux state property, customize page language */
    customizeLanguage: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
    getPromoStories: () => {
        dispatch(getPromoStories())
    },
    addPromoStoryBlock: (requestBody) => {
        dispatch(addPromoStoryBlock(requestBody))
    },
    savePromoStoryBlock: (requestBody) => {
        dispatch(savePromoStoryBlock(requestBody))
    },
    deletePromoStoryBlock: (requestBody) => {
        dispatch(deletePromoStoryBlock(requestBody))
    },
    addPromoStory: (requestBody) => {
        dispatch(addPromoStory(requestBody))
    },
    deletePromoStory: (requestBody) => {
        dispatch(deletePromoStory(requestBody))
    },
});

const mapStateToProps = (state) => {
    return {
        customizeLanguage: state.promoCustomize.customizeLanguage,
        isLoading: state.promoCustomize.isLoading,
        isSaving: state.promoCustomize.isSaving,
        data: state.promoCustomize.sections[PROMO_SECTION_TYPE.STORIES].data,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Stories);