import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";

import {
    SET_AGGREGATED_DATA_ACTION_BEFORE,
    SET_AGGREGATED_DATA_ACTION_FINISH,
    SET_AGGREGATED_DATA,
    SET_AGGREGATED_DATA_SORTING,
    SET_AGGREGATED_DATA_FILTERS
} from "../../../../actionTypes";

export const setAggregatedDataActionBefore = () => ({
    type: SET_AGGREGATED_DATA_ACTION_BEFORE,
});

export const setAggregatedDataActionFinished = () => ({
    type: SET_AGGREGATED_DATA_ACTION_FINISH,
});

const setAggregatedData = (report, add) => ({
    type: SET_AGGREGATED_DATA,
    payload: { report, add },
});

export const setAggregatedDataSorting = sorting => ({
    type: SET_AGGREGATED_DATA_SORTING,
    payload: { sorting },
});

export const setAggregatedDataFilters = (filters, keepPage) => ({
    type: SET_AGGREGATED_DATA_FILTERS,
    payload: { filters, keepPage },
});


export const getAggregatedData = nextPage => {
    return (dispatch, getState) => {

        const aggregatedData = getState().aggregatedData;
        
        const page = nextPage === "first" ? 1 : nextPage === "next" ? aggregatedData.sorting.page + 1 : aggregatedData.sorting.page;
        
        let params = {
            ...aggregatedData.sorting,
            ...aggregatedData.filters,
            page: page
        };

        if(page > 1 && aggregatedData.total <= (page - 1 ) * aggregatedData.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && aggregatedData.total === 0){
            return Promise.resolve();
        }

        dispatch(setAggregatedDataActionBefore());

        return axios({
            url: ApiUrls.GET_AGGREGATED_DATA,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: report } }) => {
                dispatch(setAggregatedData(report, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setAggregatedDataSorting({ ...aggregatedData.sorting, page: page }));
                dispatch(setAggregatedDataActionFinished());
            })
            .catch(() => {
                dispatch(setAggregatedDataActionFinished());
            });
    }
}
