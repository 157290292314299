import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import {
    saveNewsletterTemplateContent,
    setNewsletterCustomizeSaveActionBefore,
    setNewsletterCustomizeSaveActionFinished
} from "./common.action";

import { 
    SET_NEWSLETTER_CUSTOMIZE_TEXT,
    SET_NEWSLETTER_CUSTOMIZE_SECTION_STATE
} from "../../../actionTypes";

/** Section text */

const setNewsletterText = (name, text) => ({
    type: SET_NEWSLETTER_CUSTOMIZE_TEXT,
    payload: { name, text }
})

export const saveNewsletterText = (name, text, id) => {
    return ( dispatch, getState ) => {
        dispatch(setNewsletterCustomizeSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_NEWSLETTER_TEMPLATE_TEXT,
            method: Methods.POST,
            data: {
                name,
                id,
                text
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                dispatch(setNewsletterText(name, text));
                setTimeout(() => {
                    dispatch(saveNewsletterTemplateContent(id, getState().newsletterCustomize.configuration))
                }, 0);
            } 
            dispatch(setNewsletterCustomizeSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setNewsletterCustomizeSaveActionFinished());
        })
    }
}

/** Section state */

const setNewsletterSectionState = (name, enable) => ({
    type: SET_NEWSLETTER_CUSTOMIZE_SECTION_STATE,
    payload: { name, enable }
})

export const toggleNewsletterSection = (id, { enabled, sectionName }) => {
    return ( dispatch, getState) => {
        dispatch(setNewsletterCustomizeSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_NEWSLETTER_TEMPLATE_SECTION,
            method: Methods.POST,
            data: {
                name: sectionName,
                id,
                enable: enabled
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                dispatch(setNewsletterSectionState(sectionName, enabled));
                setTimeout(() => {
                    dispatch(saveNewsletterTemplateContent(id, getState().newsletterCustomize.configuration))
                }, 0);
            } 
            dispatch(setNewsletterCustomizeSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setNewsletterCustomizeSaveActionFinished());
        })
    }
}