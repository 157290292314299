import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Form, Col, Row } from 'antd';

import Modal from "components/common/modal";
import Select from "components/common/select";
import Input from 'components/common/input';

import { createTranslation } from "store/actions/portal/cms/translations/translations.action";
import { createAllBOTranslations } from "store/actions/portal/cms/translations/defaultTranslations.action"

import { isMobile } from 'utils/common';

import { TRANSLATION_TYPE } from "constants/translation.constants";

import translationGroupType from "types/translation/group.type";

/** Translation Creating Popup Component */
const TranslationCreateComponent = ({
    isSaving,
    createTranslation,
    createAllBOTranslations,
    translationGroups,
    groupId,
    subGroupId,
    languageCodes,
    translationType,
    onClose
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    const groups = translationGroups[translationType];

    /** Fires when form submitted
       * @function
       * @memberOf TranslationCreateComponent
    */
    const handleForm = () => {
        validateFields()
            .then(({ key, text }) => {
                const translation = {
                    key: key,
                    groupId: groupId,
                    subGroupId: subGroupId,
                    text: text
                }
                createTranslation(translation, languageCodes, onClose);
                //createAllBOTranslations();
            }).catch(() => { })
    }

    return (
        <Modal
            title={t('backoffice.translations.addNewTranslation')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.create')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    key: "",
                    group: subGroupId || groupId
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.translations.resource')} *`}
                            name="group"
                            className='rt--form-item-disabled'
                        >
                            <Select
                                options={
                                    groups
                                        .map(group => (
                                            {
                                                value: group.id,
                                                text: group.name,
                                                subs: (group.subGroups || []).length > 0 ? (group.subGroups || []).map(sub => ({ value: sub.id, text: sub.name })) : undefined
                                            }
                                        ))
                                }
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.translations.resource')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                disabled={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.translations.translationKey')} *`}
                            name="key"
                            rules={[{ required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') }]}
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.translations.translationKey')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.translations.translationKey')}`}
                                autoFocus={(isMobile() ? false : true)}
                                autoComplete="off"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.translations.translation')} *`}
                            name="text"
                            rules={[{ required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') }]}
                            className="rt--form-item-without-margin rt--general-form-item"
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.translations.translation')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.translations.translation')}`}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

/** TranslationCreateComponent propTypes
    * PropTypes
*/
TranslationCreateComponent.propTypes = {
    /** Redux state property, is true when creating translation request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to create translation */
    createTranslation: PropTypes.func,
    /** Create All BO Translatins */
    createAllBOTranslations: PropTypes.func,
    /** Redux state property, translation groups */
    translationGroups: PropTypes.objectOf(PropTypes.arrayOf(translationGroupType)),
    /** Group id of new translation */
    groupId: PropTypes.string,
    /** Sub group id of new translation */
    subGroupId: PropTypes.string,
    /** The list of arrays of current showing languages  */
    languageCodes: PropTypes.arrayOf(PropTypes.string),
    /** Translation type (promo/bo) */
    translationType: PropTypes.oneOf(Object.values(TRANSLATION_TYPE)),
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        createTranslation: (translation, languageCodes, onSuccess) => {
            dispatch(createTranslation(translation, languageCodes, onSuccess));
        },

        createAllBOTranslations: () => {
            dispatch(createAllBOTranslations())
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.translations.isSaving,
        translationGroups: state.translations.groups
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TranslationCreateComponent)