import { useGlobalSelector, userInfoSelector } from 'core/stores/globalStore';

const useLongId = () => {

    const { companies } = useGlobalSelector(userInfoSelector);

    const getCompanyLongIdById = id => {
        if (id === null || id === undefined) return null;

        const company = companies.find(c => c.id === id);
        if(!company) return null;

        return company.longId;
    }

    return {
        getCompanyLongIdById
    }
}

export default useLongId;