//#region react
import React, { useMemo, useEffect  } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
//#endregion

//#region actions
import {
    getMediaReport,
    setMediaReportSorting,
    setMediaReportFilters,
    getMediaReportTotals
} from 'store/actions/portal/reports/marketing/mediaReport.action';
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import Filters from "./filters.component";
import TotalsComponent from './totals';
//#endregion

//#region utils
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { isMobile } from 'utils/common';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'core/constants/permission';
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import ApiUrls from 'constants/api.constants';
import { USER_ROLE } from 'constants/user.constants';
import { MEDIAS_REPORT } from 'constants/pageName.constants';
import { AFFILIATE_ACCESS_TYPE } from 'constants/affiliate.constants';
import { getTableColumns, getExpandTableColumns } from './columns';
//#endregion

//#region hooks
import useAutosuggestion from 'hooks/useAutosuggestion';
import useFormat from 'hooks/useFormat';
import useIncludedColumns from 'hooks/useIncludedColumns';
import useAccess from 'hooks/useAccess';
import useCurrencyConversion from 'hooks/useCurrencyConvertion';
//#endRegion

//#region types
import mediaReportType from 'types/reports/mediaReport.type';
import sortingType from 'types/common/sorting.type';
import reportTotalsType from 'types/reports/totals.type';
//#endregion

import usePermissions from 'core/hooks/usePermission';
import { globalProjectIdSelector, useAuthSelector, userRoleSelector } from 'core/stores/authStore';
import { currencySelector, useGlobalSelector } from 'core/stores/globalStore';

const EXPAND_TABLE_ROW_UNIQUE_KEY = "currencyCode"

/** Media Report Component */
const MediaReportComponent = ({
    report,
    sorting,
    filters,
    total,
    isLoading,
    getMediaReport,
    setMediaReportSorting,
    setMediaReportFilters,
    getMediaReportTotals,
    totals
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const { formatAmount, formatNumber, formatCurrencyWithSymbol } = useFormat();

    const { hasAccess } = useAccess()

    const { convertCurrency } = useCurrencyConversion();

    const currencyCode = useGlobalSelector(currencySelector);
    const globalProjectId = useAuthSelector(globalProjectIdSelector);
    const userRole = useAuthSelector(userRoleSelector);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: MEDIAS_REPORT });

    const isAffiliate = userRole === USER_ROLE.AFFILIATE;

    const [ trafficSources ] = isAffiliate ? useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.TRAFFIC_SOURCES
    }) : [ [] ];

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = permissionUtils.has( PERMISSION_RESOURCE.REPORT_MEDIA_PERFORMANCE, PERMISSION_ACTION.EXPORT );
    const hasTotalsViewPermission = permissionUtils.has( PERMISSION_RESOURCE.REPORT_MEDIA_PERFORMANCE_TOTALS, PERMISSION_ACTION.VIEW );

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = (value, item) => {
        setMediaReportFilters({
            ...filters,
            mediaNameOrId: value ? item ? item.id : value : ""
        })
    }

    const handleDateSearchChange = value => {
        setMediaReportFilters({
            ...filters,
            from: value[0],
            to: value[1]
        })
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded and columnsForExport are only needed in the desktop version.
    const {
        mainTableColumns,
        expandTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            expandColumns: getExpandTableColumns,
            includedColumns,
            constructForExport: true,
            constructForInclude: true,
            includeExpandColumnsInExport: true,
            additionalProps: {
                isAffiliate,
                trafficSources,
                formatAmount,
                formatNumber,
                hasAccess,
                formatCurrencyWithSymbol,
                currencyCode,
                t
            },
            t
        })
    }, [isAffiliate, trafficSources, includedColumns, hasAccess, currencyCode, t]);

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        search: {
            onSearch: handleSearchChange,
            loadFn: getMediaReport,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.MEDIA,
                dependencies: [globalProjectId]
            },
            placeholder: t("backoffice.reports.mediaNameOrId"),
            showId: true
        },
        date: {
            onSearch: handleDateSearchChange,
            placeholder: t('backoffice.common.date'),
            showTime: true,
            allowClear: false,
            enabledMountsCount: 12,
            loadFn: getMediaReport,
            value: [filters.from, filters.to]
        },
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.reports.medias"),
            columns: columnsForExport,
            url: ApiUrls.EXPORT_MEDIA_REPORT,
            filters: filters
        }
    }

    /** Count all totals and convert currencies */
    const reportData = useMemo(() => {
        const result = report.map(item => {
            const obj = { ...item };
            if (item.details) {
                item.details.forEach(cur => {
                    obj.playerRegistrationCount = (obj.playerRegistrationCount ?? 0) + cur.playerRegistrationCount;
                    obj.depositCount = (obj.depositCount ?? 0) + cur.depositCount;
                    obj.withdrawCount = (obj.withdrawCount ?? 0) + cur.withdrawCount;

                    obj.depositAmount = (obj.depositAmount ?? 0) + convertCurrency(cur.depositAmount, cur.currencyCode);
                    obj.withdrawAmount = (obj.withdrawAmount ?? 0) + convertCurrency(cur.withdrawAmount, cur.currencyCode);
                    obj.netDepositAmount = (obj.netDepositAmount ?? 0) + convertCurrency(cur.netDepositAmount, cur.currencyCode);
                    obj.ggr = (obj.ggr ?? 0) + convertCurrency(cur.ggr, cur.currencyCode);
                    obj.ngr = (obj.ngr ?? 0) + convertCurrency(cur.ngr, cur.currencyCode);
                    obj.cpaAmount = (obj.cpaAmount ?? 0) + convertCurrency(cur.cpaAmount, cur.currencyCode);
                    obj.casinoRevShareAmount = (obj.casinoRevShareAmount ?? 0) + convertCurrency(cur.casinoRevShareAmount, cur.currencyCode);
                    obj.sportRevShareAmount = (obj.sportRevShareAmount ?? 0) + convertCurrency(cur.sportRevShareAmount, cur.currencyCode);
                    obj.totalCommissionAmount = (obj.totalCommissionAmount ?? 0) + convertCurrency(cur.totalCommissionAmount, cur.currencyCode);
                })
            }
            return obj;
        })

        return result;
    }, [report, convertCurrency]);

    const disabledExpandRowsIds = report.filter(r => (r.details?.length ?? 0) === 0).map(r => r.id)
    
    //#endregion

    /** Load Totals */
    useEffect(() => {
        if (hasTotalsViewPermission) {
            getMediaReportTotals();
        }
    }, [globalProjectId, filters])

    /** Table Totals */
    const reportTotalsData = useMemo(() => {
        const curs = Object.keys(totals);
        const result = {};

        if (curs.length > 0) {
            curs.forEach(cur => {
                const item = totals[cur];

                result.viewCount = (result.viewCount ?? 0) + item.viewCount;
                result.validClickCount = (result.validClickCount ?? 0) + item.validClickCount;
                result.invalidClickCount = (result.invalidClickCount ?? 0) + item.invalidClickCount;
                result.playerRegistrationCount = (result.playerRegistrationCount ?? 0) + item.playerRegistrationCount;

                if(hasAccess(AFFILIATE_ACCESS_TYPE.DEPOSIT_COUNT)){
                    result.depositCount = (result.depositCount ?? 0) + item.depositCount;
                }
                if(hasAccess(AFFILIATE_ACCESS_TYPE.WITHDRAW_COUNT)){
                    result.withdrawCount = (result.withdrawCount ?? 0) + item.withdrawCount;
                }
                if(hasAccess(AFFILIATE_ACCESS_TYPE.DEPOSIT_AMOUNT)){
                    result.depositAmount = (result.depositAmount ?? 0) + convertCurrency(item.depositAmount, cur);
                }
                if(hasAccess(AFFILIATE_ACCESS_TYPE.WITHDRAW_AMOUNT)){
                    result.withdrawAmount = (result.withdrawAmount ?? 0) + convertCurrency(item.withdrawAmount, cur);
                }
                if(hasAccess(AFFILIATE_ACCESS_TYPE.NET_DEPOSIT_AMOUNT)){
                    result.netDepositAmount = (result.netDepositAmount ?? 0) + convertCurrency(item.netDepositAmount, cur);
                }
                if(hasAccess(AFFILIATE_ACCESS_TYPE.GGR)){
                    result.ggr = (result.ggr ?? 0) + convertCurrency(item.ggr, cur);
                }
                if(hasAccess(AFFILIATE_ACCESS_TYPE.NGR)){
                    result.ngr = (result.ngr ?? 0) + convertCurrency(item.ngr, cur);
                }
                
                result.cpaAmount = (result.cpaAmount ?? 0) + convertCurrency(item.cpaAmount, cur);
                result.casinoRevShareAmount = (result.casinoRevShareAmount ?? 0) + convertCurrency(item.casinoRevShareAmount, cur);
                result.sportRevShareAmount = (result.sportRevShareAmount ?? 0) + convertCurrency(item.sportRevShareAmount, cur);
                result.totalCommissionAmount = (result.totalCommissionAmount ?? 0) + convertCurrency(item.totalCommissionAmount, cur);
            })

            if(hasAccess(AFFILIATE_ACCESS_TYPE.DEPOSIT_AMOUNT)){
                result.depositAmount = formatAmount(result.depositAmount, currencyCode);
            }
            if(hasAccess(AFFILIATE_ACCESS_TYPE.WITHDRAW_AMOUNT)){
                result.withdrawAmount = formatAmount(result.withdrawAmount, currencyCode);
            }
            if(hasAccess(AFFILIATE_ACCESS_TYPE.NET_DEPOSIT_AMOUNT)){
                result.netDepositAmount = <span className={result.netDepositAmount < 0 ? "rt--error-text" : ""}>{formatAmount(result.netDepositAmount, currencyCode)}</span>;
            }
            if(hasAccess(AFFILIATE_ACCESS_TYPE.GGR)){
                result.ggr = <span className={result.ggr < 0 ? "rt--error-text" : ""}>{formatAmount(result.ggr, currencyCode)}</span>;
            }
            if(hasAccess(AFFILIATE_ACCESS_TYPE.NGR)){
                result.ngr = <span className={result.ngr < 0 ? "rt--error-text" : ""}>{formatAmount(result.ngr, currencyCode)}</span>;
            }

            result.cpaAmount = formatAmount(result.cpaAmount, currencyCode);
            result.casinoRevShareAmount = formatAmount(result.casinoRevShareAmount, currencyCode);
            result.sportRevShareAmount = formatAmount(result.sportRevShareAmount, currencyCode);
            result.totalCommissionAmount = formatAmount(result.totalCommissionAmount, currencyCode);

            result.viewCount = formatNumber(result.viewCount);
            result.validClickCount = formatNumber(result.validClickCount);
            result.invalidClickCount = formatNumber(result.invalidClickCount);
            result.playerRegistrationCount = formatNumber(result.playerRegistrationCount);
            if(hasAccess(AFFILIATE_ACCESS_TYPE.DEPOSIT_COUNT)){
                result.depositCount = formatNumber(result.depositCount);
            }
            if(hasAccess(AFFILIATE_ACCESS_TYPE.WITHDRAW_COUNT)){
                result.withdrawCount = formatNumber(result.withdrawCount);
            }
        }

        return result;
    }, [totals, convertCurrency])

    
    return (
        <TabTableDashboardLayout
            header={headerPartsData}
            isContentScrollable={isMobile() && hasTotalsViewPermission}
            extraContent={isMobile() && hasTotalsViewPermission ? (
                <TotalsComponent
                    totals={reportTotalsData}
                    currencyCode={currencyCode}
                />
            ) : undefined}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={reportData}
                loadFn={getMediaReport}
                sorting={sorting}
                setSortingFn={setMediaReportSorting}
                filters={filters}
                setFiltersFn={setMediaReportFilters}
                total={total}
                updateProps={[globalProjectId]}
                enableReload={true}
                expandable={{
                    title: t("backoffice.reports.additional"),
                    disabled: disabledExpandRowsIds,
                    details: rowData => ({
                        columns: expandTableColumns,
                        data: rowData?.details ?? [],
                        uniqueKey: EXPAND_TABLE_ROW_UNIQUE_KEY,
                    }),
                }}
                totals={hasTotalsViewPermission ? {
                    data: reportTotalsData,
                    title: t("backoffice.common.total")
                } : undefined}
            />

        </TabTableDashboardLayout>
    )
}

/** MediaReportComponent propTypes
    * PropTypes
*/
MediaReportComponent.propTypes = {
    /** Redux state property, represents the array of media report */
    report: PropTypes.arrayOf(mediaReportType),
    /** Redux state property, media report sorting details */
    sorting: sortingType,
    /** Redux state property, media report filters */
    filters: PropTypes.object,
    /** Redux state property, media report total count */
    total: PropTypes.number,
    /** Redux state property, is true when loading media report */
    isLoading: PropTypes.bool,
    /** Redux action to get media report */
    getMediaReport: PropTypes.func,
    /** Redux action to set media report sorting details */
    setMediaReportSorting: PropTypes.func,
    /** Redux action to set media report filters */
    setMediaReportFilters: PropTypes.func,
    /** Redux state property, media report totals */
    totals: reportTotalsType,
    /** Redux action to get media report totals */
    getMediaReportTotals: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getMediaReport: nextPage => {
            dispatch(getMediaReport(nextPage))
        },
        setMediaReportSorting: sorting => {
            dispatch(setMediaReportSorting(sorting));
        },
        setMediaReportFilters: (filters, keepPage) => {
            dispatch(setMediaReportFilters(filters, keepPage));
        },
        getMediaReportTotals: () => {
            dispatch(getMediaReportTotals());
        }
    }
)

const mapStateToProps = state => {
    return {
        report: state.marketingReports.mediaReport.report,
        total: state.marketingReports.mediaReport.total,
        sorting: state.marketingReports.mediaReport.sorting,
        filters: state.marketingReports.mediaReport.filters,
        totals: state.marketingReports.mediaReport.totals,
        isLoading: state.marketingReports.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaReportComponent)