import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Row, Col, Spin, message } from 'antd';

import { deleteProjectLogo } from "store/actions/portal/projects/general.action";

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";
import ImageUploader from "components/common/imageUploader";

import { getAPIUrls } from 'utils/common';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'core/constants/permission';
import { IMAGE_TYPE, LOGO_TYPE } from 'constants/common.constants';
import ApiUrls from 'constants/api.constants';

import { globalStore } from 'core/stores/globalStore';
import usePermissions from 'core/hooks/usePermission';
import useLogo from 'core/hooks/useLogo';


/** Project Edit Page General Info Tab Appearance Subtab Component */
const GeneralInfoAppearanceComponent = ({
    deleteProjectLogo,
    isLoading
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const { paths } = useLogo()

    const [isUploadInProgress, setIsUploadInProgress] = useState(false);
    const [isDeleteInProgress, setDeleteInProgress] = useState(false);

    const hasModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT_GENERALINFO, PERMISSION_ACTION.MODIFY );

    const uploadsDisabled = !hasModifyPermission || isUploadInProgress || isDeleteInProgress;

    const handleSuccessfulUpload = logo => {
		const msg = logo?.message ?? null;
		msg && message.success(msg);

        globalStore.getUserInfo()
	};

    const handleLogoRemove = logoType => {
        setDeleteInProgress(true);
        deleteProjectLogo(logoType).finally(() => {
            setDeleteInProgress(false);
        });
    }

    const handleBeforeUpload = () => {
        setIsUploadInProgress(true);
    }

    const handleAfterUpload = () => {
        setIsUploadInProgress(false);
    }

    return (
        <SubTabFormDashboardLayout>
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Row gutter={[16, 0]}>
                    <Col xs={24} sm={12} xl={6}>
                        <div className={"rt--mb-12" + (uploadsDisabled ? " rt--disabled" : "") }>
                            <div className='rt--project-logo-title rt--font-small rt--font-bold rt--mb-8'>{t('backoffice.projects.logo')}</div>
                            <ImageUploader
                                disabled={uploadsDisabled}
                                size={1024 * 1024 * 1}
                                acceptedFormats={[IMAGE_TYPE.JPEG, IMAGE_TYPE.PNG]}
                                data={{ type: LOGO_TYPE.LOGO }}
                                onSuccess={handleSuccessfulUpload}
                                uploadUrl={`${getAPIUrls().API_URL}${ApiUrls.UPLOAD_PROJECT_LOGO}`}
                                remove={{
                                    handler: () => handleLogoRemove(LOGO_TYPE.LOGO),
                                }}
                                defaultFile={paths[LOGO_TYPE.LOGO] ? {
                                    url: `${import.meta.env.SYSTEM_CDN_URL}/${paths[LOGO_TYPE.LOGO]}`,
                                    status: "done",
                                    percent: 100
                                } : null}
                                onBeforeUpload={handleBeforeUpload}
                                onAfterUpload={handleAfterUpload}
                            />
                        </div>
                    </Col>

                    <Col xs={24} sm={12} xl={6}>
                        <div className={"rt--mb-12" + (uploadsDisabled ? " rt--disabled" : "") }>
                            <div className='rt--project-logo-title rt--font-small rt--font-bold rt--mb-8'>{t('backoffice.projects.collapsedMenuLogo')}</div>
                            <ImageUploader
                                disabled={uploadsDisabled}
                                size={1024 * 1024 * 1}
                                acceptedFormats={[IMAGE_TYPE.JPEG, IMAGE_TYPE.PNG]}
                                data={{ type: LOGO_TYPE.COLLAPSED_LOGO }}
                                onSuccess={handleSuccessfulUpload}
                                uploadUrl={`${getAPIUrls().API_URL}${ApiUrls.UPLOAD_PROJECT_LOGO}`}
                                remove={{
                                    handler: () => handleLogoRemove(LOGO_TYPE.COLLAPSED_LOGO),
                                }}
                                defaultFile={paths[LOGO_TYPE.COLLAPSED_LOGO] ? {
                                    url: `${import.meta.env.SYSTEM_CDN_URL}/${paths[LOGO_TYPE.COLLAPSED_LOGO]}`,
                                    status: "done",
                                    percent: 100
                                } : null}
                                onBeforeUpload={handleBeforeUpload}
                                onAfterUpload={handleAfterUpload}
                            />
                        </div>
                    </Col>
                    <Col xs={24} sm={12} xl={6}>
                        <div className={"rt--mb-12" + (uploadsDisabled ? " rt--disabled" : "") }>
                            <div className='rt--project-logo-title rt--font-small rt--font-bold rt--mb-8'>{t('backoffice.projects.favicon')}</div>
                            <ImageUploader
                                disabled={uploadsDisabled}
                                size={1024 * 1024 * 1}
                                acceptedFormats={[IMAGE_TYPE.JPEG, IMAGE_TYPE.PNG]}
                                data={{ type: LOGO_TYPE.FAVICON }}
                                onSuccess={handleSuccessfulUpload}
                                uploadUrl={`${getAPIUrls().API_URL}${ApiUrls.UPLOAD_PROJECT_LOGO}`}
                                remove={{
                                    handler: () => handleLogoRemove(LOGO_TYPE.FAVICON),
                                }}
                                defaultFile={paths[LOGO_TYPE.FAVICON] ? {
                                    url: `${import.meta.env.SYSTEM_CDN_URL}/${paths[LOGO_TYPE.FAVICON]}`,
                                    status: "done",
                                    percent: 100
                                } :  null}
                                onBeforeUpload={handleBeforeUpload}
                                onAfterUpload={handleAfterUpload}
                            />
                        </div>
                    </Col>

                </Row>
            </Spin>
        </SubTabFormDashboardLayout>
    )
}

/** GeneralInfoAppearanceComponent propTypes
    * PropTypes
*/
GeneralInfoAppearanceComponent.propTypes = {
    /** Redux action to delete project logo */
    deleteProjectLogo: PropTypes.func,
    /** Redux state property, is true when general info is loading */
    isLoading: PropTypes.bool
}

const mapDispatchToProps = dispatch => (
    {
        deleteProjectLogo: type => {
            return dispatch(deleteProjectLogo(type));
        },
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.projects.isLoading,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoAppearanceComponent)