
import isFunction from "core/helpers/typeChecks/isFunction";
import isArray from "core/helpers/typeChecks/isArray";
import ListItems from "core/ui-kit/listItems";

const List = ({
    value,
    mapper,
    title
}) => {

    const items = isFunction(mapper) ? value.map(mapper) : value;

    if(!isArray(items) || items.length === 0){
        return "-";
    }

    return (
        <ListItems 
            items={items}
            title={title}
        />
    )
}

export default List;