import React from "react";
import Tooltip from "core/ui-kit/tooltip";
import Icon from "core/ui-kit/icon";

import isFunction from "core/helpers/typeChecks/isFunction";

const VisibleActions = ({
    actions,
    record,
    onActionClick=Function.prototype
}) => {

    const handleClick = (action, e) => {
        e.preventDefault();
        e.stopPropagation();
        onActionClick(action);
    }

    if(actions.length === 0){
        return null;
    }

    return (
        <div className="rt--table-col-actions rt--flex rt--align-center rt--justify-end">
            {
                actions.map((action, index) => (
                    <div
                        onClick={e => handleClick(action, e)}
                        key={index}
                        className="rt--table-col-actions-item rt--ml-8"
                    >
                        <Tooltip
                            title={ isFunction(action.title) ? action.title(record) : action.title }
                        >
                            <Icon 
                                name={action.icon} 
                            />
                        </Tooltip>
                    </div>
                ))
            }
        </div>
    )
}

export default VisibleActions;