import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import Drawer from "core/ui-kit/drawer";
import Icon from "core/ui-kit/icon";

import ModalMobileFooter from "./footer";

import classNames from "core/helpers/common/classNames";

const ModalMobile = ({
    children,
    visible,
    className,
    title,
    isLoading,
    footer,
    onOk,
    onCancel,
    okText,
    cancelText,
    maskClosable=true,
    okButtonProps,
    cancelButtonProps,
}) => {

    const scrollTop = useRef(0);

    useEffect(() => {
        if(visible){
            scrollTop.current = document.documentElement.scrollTop;
            document.documentElement.classList.add("rt--no-scroll");
        } else {
            if (document.documentElement.classList.contains("rt--no-scroll")){
                document.documentElement.scrollTop = scrollTop.current;
            }
        }

        return () => {
            document.documentElement.classList.remove("rt--no-scroll");
        }
    }, [visible])

    const noFooter = (!cancelText || cancelButtonProps.hidden) && (!okText || okButtonProps.hidden) && !footer;

    const builtClassName = classNames(
        'rt--modal', 
        className,
        noFooter && "rt--modal-without-footer",
        !title && "rt--modal-without-header"
    );    

    return (
        <Drawer
            visible={visible}
            className={builtClassName}
            title={title}
            placement="bottom"
            maskClosable={maskClosable}
            onClose={onCancel}
            closeIcon={(<Icon name='close'/>)}
            destroyOnClose={true}
            getContainer={() => document.getElementsByClassName("rt--portal-layout")[0] || document.getElementsByClassName("App")[0]}
            footer={!noFooter ? (
                <ModalMobileFooter 
                    isLoading={isLoading}
                    footer={footer}
                    onOk={onOk}
                    onCancel={onCancel}
                    okText={okText}
                    cancelText={cancelText}
                    okButtonProps={okButtonProps}
                    cancelButtonProps={cancelButtonProps}
                />
            ) : null}
        >
            { children }
        </Drawer>
    )
}

ModalMobile.propTypes = {
    children: PropTypes.node.isRequired,
    visible: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    isLoading: PropTypes.bool,
    className: PropTypes.string,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    okButtonProps: PropTypes.shape({
        hidden: PropTypes.bool,
        disabled: PropTypes.bool,
        className: PropTypes.string
    }),
    cancelButtonProps: PropTypes.shape({
        hidden: PropTypes.bool,
        disabled: PropTypes.bool,
        className: PropTypes.string
    }),
    closable: PropTypes.bool,
    footer: PropTypes.node,
}

export default ModalMobile;