import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";

import {
    SET_REQUESTS_ACTION_BEFORE,
    SET_REQUESTS_ACTION_FINISH,
    SET_REQUESTS,
    SET_REQUESTS_SORTING,
    SET_REQUESTS_FILTERS
} from "../../../../actionTypes";

const setRequestsActionBefore = () => ({
    type: SET_REQUESTS_ACTION_BEFORE,
});

const setRequestsActionFinished = () => ({
    type: SET_REQUESTS_ACTION_FINISH,
});

const setRequests = (requests, add) => ({
    type: SET_REQUESTS,
    payload: { requests, add },
});

export const setRequestsSorting = sorting => ({
    type: SET_REQUESTS_SORTING,
    payload: { sorting },
});

export const setRequestsFilters = filters => ({
    type: SET_REQUESTS_FILTERS,
    payload: { filters },
});

export const getRequests = nextPage => {
    return (dispatch, getState) => {
        const requestsData = getState().requests;

        const page = nextPage === "first" ? 1 : nextPage === "next" ? requestsData.sorting.page + 1 : requestsData.sorting.page;
        let params = {
            ...requestsData.sorting,
            ...requestsData.filters,
            page: page
        };

        if (page > 1 && requestsData.total <= (page - 1) * requestsData.sorting.limit) {
            return Promise.resolve();
        }

        if (nextPage === "next" && requestsData.total === 0) {
            return Promise.resolve();
        }

        dispatch(setRequestsActionBefore());

        return axios({
            url: ApiUrls.GET_INTEGRATION_LOGS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: requests } }) => {
                dispatch(setRequests(requests, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setRequestsSorting({ ...requestsData.sorting, page: page }));
                dispatch(setRequestsActionFinished());
            })
            .catch((ex) => {
                dispatch(setRequestsActionFinished());
            });
    }
}
