import React, { useEffect, useState } from 'react';

import { useTranslation } from "core/hooks/useTranslation";
import useDevice from 'core/hooks/useDevice';
import usePermissions from 'core/hooks/usePermission';

import { Col, Row } from 'core/ui-kit/layout';
import Form from "core/ui-kit/form";
import Spin from "core/ui-kit/spin";
import Tooltip from "core/ui-kit/tooltip";
import Icon from "core/ui-kit/icon";

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";

import projectService from 'core/services/apiServices/projectService';

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'core/constants/permission';

import { FORM_FIELD_NAMES, PROJECT_DELAY_TYPE } from './constants';
import isFormChanged from 'core/helpers/form/isFormChanged';
import showSuccess from 'core/helpers/common/showSuccess';


const Configuration = ({
    onTabChange
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const [formInstance] = Form.useForm();
    const { setFieldsValue } = formInstance;

    const [isFormTouched, setIsFormTouched] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [delaySettings, setDelaySettings] = useState({});

    const hasModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT_DELAY_SETTINGS, PERMISSION_ACTION.MODIFY );

    const { isMobile } = useDevice()

    useEffect(() => {
        setIsLoading(true);
        projectService.getDelaySettings()
            .then(value => {
                setDelaySettings(value);
                setFieldsValue({
                    [FORM_FIELD_NAMES.DELAY_SETTINGS]: value.delaySettings,
                })
            })
            .finally(() => setIsLoading(false))
    }, [])

    const handleSave = () => {
        formInstance.submit();
    }

    const handleFormSubmit = formData => {
        setIsSaving(true);
        const d = {
            delaySettings: formData[FORM_FIELD_NAMES.DELAY_SETTINGS],
        }

        projectService.saveDelaySettings(d)
            .then(({value, message}) => {
                setDelaySettings(value);
                setIsFormTouched(false);
                showSuccess(message);
            })
            .finally(() => setIsSaving(false))
    }

    const handleFormValuesChange = (_, values) => {
        const initialValues = {
            [FORM_FIELD_NAMES.DELAY_SETTINGS]: delaySettings.delaySettings,
        }
        const formValues = {
            [FORM_FIELD_NAMES.DELAY_SETTINGS]: values[FORM_FIELD_NAMES.DELAY_SETTINGS],
        }
        setIsFormTouched(isFormChanged(initialValues, formValues))
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <SubTabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleSave,
                        text: t("backoffice.common.save"),
                        enabled: hasModifyPermission,
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    form={formInstance}
                    onFinish={handleFormSubmit}
                    onValuesChange={handleFormValuesChange}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Select
                                label={(
                                    <div className='rt--flex rt--align-center'>
                                        <span>{t('backoffice.projects.dataViewDelay')}</span>
                                        <Tooltip
                                            title={t('backoffice.projects.dataViewDelayInfo')}
                                            enableMobile={true}
                                        >
                                            <div className="rt--ml-4 rt--flex rt--align-center rt--cursor-pointer">
                                                <Icon name="info" size={18} />
                                            </div>
                                        </Tooltip>
                                    </div>
                                )}
                                name={FORM_FIELD_NAMES.DELAY_SETTINGS}
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.projects.dataViewDelay')}`}
                                options={
                                    [
                                        { value: PROJECT_DELAY_TYPE.NO_DELAY, text: t('backoffice.projects.noDelay') },
                                        { value: PROJECT_DELAY_TYPE._24_HOUR, text: t('backoffice.projects.24_hourDelay') }
                                    ]
                                }
                                className="rt--form-item-without-margin"
                            />
                            <div className={isMobile ? " rt--mb-8" : ""}>
                                <small className="rt--text-light">
                                    <i>{ t('backoffice.projects.dataViewDelayHint') }</i>
                                </small>
                            </div>
                        </Col>
                    </Row>
                    
                </Form>

            </Spin>
        </SubTabFormDashboardLayout>
    )
}


export default Configuration;