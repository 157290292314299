//#region react
import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";
//#endregion

//#region libraries
import { Input } from "antd";
//#endregion

//#region actions
import {
    getServiceErrors
} from 'store/actions/portal/developer/monitoring/monitoring.action';
//#endregion

//#region components
import Table from "components/common/table";
import Modal from "components/common/modal";
//#endregion

//#region utils
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import { POPUP_SIZE } from "constants/common.constants";
import { tableColumns } from "./columns";
//#endregion

//#region types
import errorType from "types/error/error.type";
//#endregion

/** Errors Component in monitoring page */
const ErrorsComponent = ({
    getServiceErrors,
    errors,
    isLoading,
    total,
    serviceName
}) => {
    const { t } = useTranslation();

    const [stackTrace, setStackTrace] = useState(null);

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const showStackTraceList = (record) => {
        setStackTrace(record.stackTrace)
    }

    const hideStackTraceList = () => {
        setStackTrace(null)
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({ 
            mainColumns: tableColumns,
            t 
        })
    }, [t])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [{
        title: t('backoffice.errorlogs.stackTrace'),
        icon: "code",
        disabled: record => !Boolean(record.stackTrace),
        onClick: showStackTraceList,
    }];

    //#endregion

    useEffect(() => {
        getServiceErrors(serviceName);
    }, [serviceName])

    return (
        <>
            {errors.length > 0 && (
                <Table
                    loading={isLoading}
                    columns={mainTableColumns}
                    data={errors}
                    total={total}
                    actions={tableRowActions}
                    noPagination={true}
                    disableFullView={true}
                />
            )}

            {Boolean(stackTrace) && (
                <Modal
                    title={t('backoffice.menu.errors')}
                    cancelText={t('backoffice.common.cancel')}
                    onCancel={hideStackTraceList}
                    size={POPUP_SIZE.BIG}
                    hideOkButton={true}
                >
                    <Input.TextArea
                        className="rt--stacktrace"
                        autoSize={true}
                        disabled={true}
                        value={stackTrace}
                    />
                </Modal>
            )}
        </>
    );
};

/** ErrorsComponent propTypes
 * PropTypes
*/
ErrorsComponent.propTypes = {
    /** Redux action to get error logs for service */
    getServiceErrors: PropTypes.func,
    /** Redux state property, represents the array of errors  */
    errors: PropTypes.arrayOf(errorType),
    /** Redux state property, is true when loading errors */
    isLoading: PropTypes.bool,
    /** Redux state property, errors total count */
    total: PropTypes.number,
    /** The service name */
    serviceName: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
    getServiceErrors: name => {
        dispatch(getServiceErrors(name))
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.errors.isLoading,
        errors: state.errors.errors,
        total: state.errors.total,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ErrorsComponent);
