//#region React
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";
import { useNavigate } from 'react-router-dom';
//#endregion

//#region Actions
import {
	getSystemPayments,
	setSystemPaymentsSorting,
	deleteSystemPayment
} from "store/actions/portal/settings/systemPayments/systemPayments.action";
//#endregion

//#region Components
import Table from "components/common/table";
import MainDashboardLayout from "components/layouts/main";
import Filters from "./filters.component";
import CreateSystemPayment from "./systemPayment-create.component";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region Constants
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'core/constants/permission';
import ROUTES from "core/constants/routes/routes";
import { SYSTEM_PAYMENT } from "constants/pageName.constants";
import ApiUrls from 'constants/api.constants';
import { tableColumns } from "./columns";
//#endregion

//#region Types
import systemPaymentType from "types/systemPayments/systemPayment.type";
import sortingType from "types/common/sorting.type";
//#endregion

import { globalProjectIdSelector, useAuthSelector } from "core/stores/authStore";

import usePermissions from "core/hooks/usePermission";

/** System Payments Page Component */
const SystemPayments = ({
	getSystemPayments,
	deleteSystemPayment,
	systemPayments,
	isLoading,
	total,
	setSystemPaymentsSorting,
	sorting,
	filters
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

    const permissionUtils = usePermissions();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);

	const [isCreatePopupVisible, setIsCreatePopupVisible] = useState(false);

	const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: SYSTEM_PAYMENT });

	//#region ------------------------------------- PERMISSIONS ---------------------------------------//

	const hasCreatePermission = permissionUtils.has( PERMISSION_RESOURCE.SYSTEM_PAYMENT_METHODS, PERMISSION_ACTION.CREATE );
	const hasDeletePermission = permissionUtils.has( PERMISSION_RESOURCE.SYSTEM_PAYMENT_METHODS, PERMISSION_ACTION.DELETE );
	const hasExportPermission = permissionUtils.has( PERMISSION_RESOURCE.SYSTEM_PAYMENT_METHODS, PERMISSION_ACTION.EXPORT );

	//#endregion

	//#region --------------------------------------- HANDLERS ----------------------------------------//

	const handleRightArrowIconClick = (payment) => {
		navigate(`${ROUTES.SETTINGS_PAYMENTS_EDIT}/${payment.id}?name=${payment.name}`);
	};

	//#endregion

	//#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

	const {
		mainTableColumns,
		columnsForExport,
		columnsThatCanBeIncluded
	} = useMemo(() => {
		return tableColumnsCreator({
			mainColumns: tableColumns,
			constructForExport: true,
			constructForInclude: true,
			includedColumns,
            t
		})
	}, [includedColumns, t]);

	//#endregion

	//#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

	const tableRowActions = [
		{
			title: t('backoffice.common.delete'),
			confirmationMessage: t("backoffice.payments.doYouWantToDeletePayment"),
			confirmationTitle: t("backoffice.common.delete"),
			icon: "trash",
			showConfirmation: true,
			className: "rt--button-danger",
			disabled: (payment) => !hasDeletePermission || payment.projects?.length > 0,
			onClick: (payment) => deleteSystemPayment(payment.id, getSystemPayments)
		},
		{
			title: t('backoffice.common.deepView'),
			icon: "right",
			onClick: handleRightArrowIconClick
		}
	];

	//#endregion

	//#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

	const headerPartsData = {
		filters: <Filters />,
		columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
		buttons: [
			{
				icon: "plus",
				type: "primary",
				onClick: () => setIsCreatePopupVisible(true),
				enabled: hasCreatePermission,
				text: t('backoffice.payments.addPaymentMethod')
			}
		],
		breadcrumbs: {
			items: [
				{
					title: t('backoffice.menu.payments')
				}
			],
		},
		...(hasExportPermission && {
			export: {
				url: ApiUrls.EXPORT_SYSTEM_PAYMENT_METHODS,
				columns: columnsForExport,
				filters: filters,
				tableName: t('backoffice.menu.payments'),
			}
		})
	};

	//#endregion

	return (
		<MainDashboardLayout
			header={headerPartsData}
		>
			<Table
				loading={isLoading}
				columns={mainTableColumns}
				data={systemPayments}
				loadFn={getSystemPayments}
				sorting={sorting}
				setSortingFn={setSystemPaymentsSorting}
				total={total}
				updateProps={[globalProjectId]}
				actions={tableRowActions}
			/>

			{isCreatePopupVisible && (
				<CreateSystemPayment
					onClose={() => setIsCreatePopupVisible(false)}
				/>
			)}
		</MainDashboardLayout>
	);
};

SystemPayments.propTypes = {
	/** Redux action to get System Payments */
	getSystemPayments: PropTypes.func,
	/** Redux action to delete System Payment */
	deleteSystemPayment: PropTypes.func,
	/** Redux state property, represents the array of System Payments  */
	systemPayments: PropTypes.arrayOf(systemPaymentType),
	/** Redux state property, is true when loading System Payments */
	isLoading: PropTypes.bool,
	/** Redux state property, System Payments total count */
	total: PropTypes.number,
	/** Redux action to set System Payments sorting details */
	setSystemPaymentsSorting: PropTypes.func,
	/** Redux state property, System Payments sorting details */
	sorting: sortingType,
	/** Redux state property, System Payments filters */
	filters: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
	getSystemPayments: nextPage => {
		dispatch(getSystemPayments(nextPage));
	},
	deleteSystemPayment: (id, onSuccess) => {
		dispatch(deleteSystemPayment(id, onSuccess));
	},
	setSystemPaymentsSorting: sorting => {
		dispatch(setSystemPaymentsSorting(sorting));
	}
});

const mapStateToProps = (state) => {
	return {
		isLoading: state.systemPayments.isLoading,
		systemPayments: state.systemPayments.payments,
		total: state.systemPayments.total,
		sorting: state.systemPayments.sorting,
		filters: state.systemPayments.filters
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SystemPayments);