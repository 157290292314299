import React from 'react'
import ReactDOM from 'react-dom/client'

import './assets/css/scss/style.scss';

import './assets/css/typography.scss';

import './assets/css/ant.less';

import 'core/lib/baseStore/debugStore/debug.css';

import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import App from './App.jsx'

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);
