import httpService from "core/services/httpService";

import API_URL from "core/constants/api/apiUrl";

const systemLanguageService = {
    getSystemLanguages( signal ) {
		return httpService.get({
			url: API_URL.GET_SYSTEM_AVAILABLE_PUBLISHED_LANGUAGES,
			signal
		});
	},
}

export default systemLanguageService;