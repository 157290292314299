//#region react
import React from "react";
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useParams, useNavigate } from "react-router-dom";

import { Form, Switch } from 'antd';

import Icon from "components/common/icon";

import { changePromoStatus } from "store/actions/portal/projects/promo.action";

import { isMobile } from 'utils/common';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'core/constants/permission';
import ROUTES from "core/constants/routes/routes";

import useDate from "hooks/useDate";

import promoType from "types/project/promo.type";

import usePermissions from "core/hooks/usePermission";

/** Promo Website Component */
const PromoWebsite = ({
    promo,
    changePromoStatus
}) => {

    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const { dateService } = useDate();

    const searchParams = useParams();
    const navigate = useNavigate();

    const hasModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT_PROMO, PERMISSION_ACTION.MODIFY );

    return (
        <div className={'rt--promo-website rt--flex ' + (isMobile() ? 'rt--flex-col' : 'rt--flex-row')}>
            <div className={'rt--promo-website-img ' + (isMobile() ? 'rt--flex rt--justify-between' : '')}>
                <img src={`${import.meta.env.SYSTEM_CDN_URL}/system/static/images/promo-template.png`} alt='img'></img>
                <div className='rt--promo-website-img-content'>
                    <div
                        className='rt--promo-website-img-content-preview'
                        onClick={() => {
                            window.open(`${import.meta.env.SYSTEM_PROMO_DOMAIN}?id=${searchParams.id}`, "_blank")
                        }}
                    >
                        <Icon name='eye' />
                    </div>
                    {hasModifyPermission && (
                        <div
                            className='rt--promo-website-img-content-edit'
                            onClick={() => {
                                navigate(`${ROUTES.PROMO_CUSTOMIZE}/${searchParams.id}`); 
                            }}
                        >
                            <Icon name='edit' />
                        </div>
                    )}
                </div>
                <div className="rt--switcher">
                    {
                        isMobile() ?
                            <Form.Item
                            >
                                <Switch
                                    onChange={checked => changePromoStatus(checked)}
                                    checked={promo.enabled}
                                    disabled={!hasModifyPermission}
                                />
                            </Form.Item>
                            :

                            ''
                    }
                </div>
            </div>
            <div className={'rt--promo-website-cols rt--flex rt--flex-col rt--flex-equal ' + (isMobile() ? 'rt--mt-16' : 'rt--ml-16')}>
                <div className='rt--flex rt--flex-col rt--mb-12'>
                    <span className='rt--promo-website-title rt--font-bigest rt--font-extra-bold'>{promo.name}</span>
                </div>
                    <div className={"rt--flex " + (isMobile() ? 'rt--flex-col' : '')}>
                    <div className='rt--flex'>
                        <div className='rt--promo-website-section rt--flex rt--mr-12 rt--flex-col'>
                            <div className="rt--flex">
                                <span className={'rt--promo-website-section-title rt--text-light ' + (isMobile() ? 'rt--mb-4' : 'rt--mr-4 rt--mb-4')}>{t("backoffice.projects.lastUpdatedAt")}:</span>
                            </div>
                            <span>{dateService.format(promo.modifiedAt, true)}</span>
                        </div>
                        <div className='rt--promo-website-section rt--flex rt--flex-col rt--align-left'>
                            <div className={"rt--flex"}>
                                <span className={'rt--promo-website-section-title rt--text-light ' + (isMobile() ? 'rt--mb-4' : 'rt--mr-4 rt--mb-4')}>{t("backoffice.projects.lastUpdatedBy")}:</span>
                            </div>
                            <span>{promo.modifiedBy}</span>
                        </div>
                    </div>
                    {
                        isMobile() && <div className="rt--promo-website-divider  rt--mt-16 rt--mb-16" />
                    }
                    <div className={'rt--flex rt--promo-website-section-second '  + (!isMobile() ? 'rt--pl-12 rt--ml-12' : '')}>
                        <div className='rt--promo-website-section rt--flex rt--mr-12 rt--flex-col'>
                            <div className="rt--flex">
                                <span className={'rt--promo-website-section-title rt--text-light ' + (isMobile() ? 'rt--mb-4' : 'rt--mr-4 rt--mb-4')}>{t("backoffice.projects.lastPublishedAt")}:</span>
                            </div>
                            <span>{promo.publishedAt === null ? '-' : dateService.format(promo.publishedAt, true)}</span>
                        </div>
                        <div className='rt--promo-website-section rt--flex rt--flex-col rt--align-left'>
                            <div className="rt--flex">
                                <span className={'rt--promo-website-section-title rt--text-light ' + (isMobile() ? 'rt--mb-4' : 'rt--mr-4 rt--mb-4')}>{t("backoffice.projects.lastPublishedby")}:</span>
                            </div>
                            <span>{promo.publishedBy === null ? '-' : promo.publishedBy}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rt--switcher">
                {
                    isMobile() ? '' :
                        <Form.Item
                        >
                            <Switch
                                onChange={checked => changePromoStatus(checked)}
                                checked={promo.enabled}
                                disabled={!hasModifyPermission}
                            />
                        </Form.Item>
                }
            </div>
        </div>
    )
}


PromoWebsite.propTypes = {
    /** Redux action to get Promo */
    changePromoStatus: PropTypes.func,
    /** Redux state property, promo info */
    promo: promoType,
}

const mapDispatchToProps = dispatch => (
    {
        changePromoStatus: checked => {
            dispatch(changePromoStatus(checked))
        }
    }
)

const mapStateToProps = state => {
    return {
        promo: state.projects.edit.promo,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromoWebsite)