import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";

import {
    getProjectLanguages
} from "store/actions/portal/projects/languages.action";

import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";

import LanguageAddComponent from "./language-add.component";

import useIncludedColumns from "hooks/useIncludedColumns";

import { isMobile } from 'utils/common';
import { tableColumnsCreator } from "utils/tableColumnsCreator";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";
import { PROJECT_LANGUAGES } from "constants/pageName.constants";
import { getTableColumns } from "./columns";

import languageType from "types/language/language.type";

import usePermissions from "core/hooks/usePermission";

const ROW_UNIQUE_KEY_PROP = "code";

/** Project Edit Page Languages Tab Component */
const LanguagesComponent = ({
    getProjectLanguages,
    languages,
    isLoading
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const isMobileDevice = isMobile();

    const [showAddPopup, setShowAddPopup] = useState(false);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: PROJECT_LANGUAGES });

    const hasCreatePermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT_LANGUAGE_SETTINGS, PERMISSION_ACTION.MODIFY );

    const { mainTableColumns, columnsThatCanBeIncluded } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            constructForInclude: true,
            includedColumns,
            additionalProps: {
                t
            },
            t
        });
    }, [includedColumns, t])

    useEffect(() => {
        if (isMobileDevice) {
            getProjectLanguages();
        }
    }, [])

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        button: {
            icon: "plus",
            type: "primary",
            text: t("backoffice.languages.addLanguage"),
            enabled: hasCreatePermission,
            onClick: () => setShowAddPopup(true),
        }
    }

    return (
        <TabTableDashboardLayout
            header={headerPartsData}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={languages}
                loadFn={getProjectLanguages}
                uniqueKey={ROW_UNIQUE_KEY_PROP}
                noPagination={true}
                smallInMobile={true}
                disableFullView={true}
            />

            {showAddPopup && (
                <LanguageAddComponent
                    onClose={() => setShowAddPopup(false)}
                />
            )}
        </TabTableDashboardLayout>
    );
};

/** LanguagesComponent propTypes
 * PropTypes
*/
LanguagesComponent.propTypes = {
    /** Redux action to get project languages */
    getProjectLanguages: PropTypes.func,
    /** Redux state property, represents the array of project languages  */
    languages: PropTypes.arrayOf(languageType),
    /** Redux state property, is true when loading project languages */
    isLoading: PropTypes.bool
};

const mapDispatchToProps = dispatch => ({
    getProjectLanguages: () => {
        dispatch(getProjectLanguages());
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.projects.isLoading,
        languages: state.projects.edit.languages.languages,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LanguagesComponent);
