import React, { useRef, useState } from "react";

import { useLocation, useParams } from "react-router-dom";

import { useTranslation } from "core/hooks/useTranslation";

import { authStore, useAuthSelector, userSelector } from "core/stores/authStore";
import { companiesSelector, useGlobalSelector } from "core/stores/globalStore";

import useDevice from "core/hooks/useDevice";

import { USER_ROLE } from "core/constants/auth";

import { companiesDropdownMenu, filterCompanies, filterProjectsBySearch, isDropdownDisabled, projectsDropdownMenu } from "./helpers";

import CompanyProjectDropdownMobile from "./mobile";
import CompanyProjectDropdownDesktop from "./desktop";
import classNames from "core/helpers/common/classNames";

const CompanyProjectDropdown = () => {

    const { t } = useTranslation();

    const { isMobile } = useDevice();

    const urlParams = useParams();
	const location = useLocation();

    const { projectId, companyId, role: userRole } = useAuthSelector(userSelector);
    
    const companies = useGlobalSelector(companiesSelector);

    const [companySearch, setCompanySearch] = useState("");
	const [projectSearch, setProjectSearch] = useState("");

    const [companyPopupOpened, setCompanyPopupOpened] = useState(false);
	const [projectPopupOpened, setProjectPopupOpened] = useState(false);

    const companySearchRef = useRef(null);
	const projectSearchRef = useRef(null);

    const shouldShowDropdown = userRole === USER_ROLE.ADMIN || userRole === USER_ROLE.ACCESS_MANAGER;

    const selectedCompany = companies.find((c) => c.id === companyId);

	const selectedProject = selectedCompany?.projects?.find( project=> project.id === projectId);

    const filteredCompanies = filterCompanies(companies, location, userRole, companySearch);

    const companyFilteredProjects = filterProjectsBySearch(
		selectedCompany?.projects || [],
		projectSearch,
	);

    const companiesMenuItems = companiesDropdownMenu({
		companies: filteredCompanies,
		companyId,
		t,
        isMobile
	});
	const projectsMenuItems = projectsDropdownMenu({
		projects: companyFilteredProjects,
		projectId,
		t,
        isMobile
	});

    const handleCompanyOrProjectChange =
		(type) =>
		({ key }) => {
			if (key === companyId || key === projectId) {
				return;
			}

            if(type === "project"){
                setProjectPopupOpened(false)
            } else {
                setCompanyPopupOpened(false)
            }

			const data = {
				companyId,
				projectId: type === "project" ? key : projectId,
			};

			if (type === "company") {
				const targetCompany = companies.find((company) => company.id === key);
				data.companyId = key;
				data.projectId = targetCompany?.projects?.[0]?.id;
			}

			authStore.changeProject(data)
			setCompanySearch("");
			setProjectSearch("");
		};

	const handleCompanySearchChange = ({ target: { value }}) => {
		setCompanySearch(value);
	};

	const handleProjectSearchChange = ({ target: { value }}) => {
		setProjectSearch(value);
	};

    const handleCompanyDropdownOpenChange = (opened) => {
		if (opened) {
			setTimeout(() => companySearchRef.current?.focus());
		}
        setCompanyPopupOpened(opened)
	};

	const handleProjectDropdownOpenChange = (opened) => {
		if (opened) {
			setTimeout(() => projectSearchRef.current?.focus());
		}
        setProjectPopupOpened(opened)
	};

    const disabled = isDropdownDisabled(location, urlParams);

    if(!shouldShowDropdown) return <div/>;

    const wrapperClassName = classNames(
        "rt--companies-dropdown rt--flex rt--align-center",
        disabled && "rt--companies-dropdown-disabled"
    )


    if (isMobile) {
		return (
			<div className={wrapperClassName}>
				<CompanyProjectDropdownMobile
					companySearch={companySearch}
                    handleCompanyOrProjectChange={handleCompanyOrProjectChange}
                    selectedCompany={selectedCompany}
                    selectedProject={selectedProject}
                    handleCompanySearchChange={handleCompanySearchChange}
                    projectSearch={projectSearch}
                    handleProjectSearchChange={handleProjectSearchChange}
                    filteredCompanies={filteredCompanies}
                    companyFilteredProjects={companyFilteredProjects}
                    disabled={disabled}
				/>
			</div>
		);
	}

	return (
		<div className={wrapperClassName}>
			<CompanyProjectDropdownDesktop
				companiesMenuItems={companiesMenuItems}
                companySearch={companySearch}
                handleCompanyDropdownOpenChange={handleCompanyDropdownOpenChange}
                handleCompanyOrProjectChange={handleCompanyOrProjectChange}
                companyPopupOpened={companyPopupOpened}
                projectPopupOpened={projectPopupOpened}
                projectsMenuItems={projectsMenuItems}
                companySearchRef={companySearchRef}
                selectedCompany={selectedCompany}
                handleCompanySearchChange={handleCompanySearchChange}
                handleProjectDropdownOpenChange={handleProjectDropdownOpenChange}
                selectedProject={selectedProject}
                handleProjectSearchChange={handleProjectSearchChange}
                projectSearch={projectSearch}
                projectSearchRef={projectSearchRef}
                disabled={disabled}
			/>
		</div>
	);
}

export default CompanyProjectDropdown;