import ROUTES from "core/constants/routes/routes";

import ProjectsComponent from "pages/projects/projects.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const ProjectsPageRoute = {
	path: ROUTES.PROJECTS,
	element: ProjectsComponent, 
    permission: { resource: PERMISSION_RESOURCE.PROJECT, action: PERMISSION_ACTION.VIEW }
};

export default ProjectsPageRoute;
