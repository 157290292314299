import ROUTES from "core/constants/routes/routes";

import SystemCurrenciesComponent from "pages/settings/systemCurrencies/systemCurrencies.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const SystemCurrenciesPageRoute = {
	path: ROUTES.SETTINGS_CURRENCIES,
	element: SystemCurrenciesComponent, 
    permission: { resource: PERMISSION_RESOURCE.SYSTEM_CURRENCIES, action: PERMISSION_ACTION.VIEW }
};

export default SystemCurrenciesPageRoute;
