import React, { forwardRef } from "react";

import PropTypes from "prop-types";

import Switch from "core/ui-kit/switch";

import Form from "..";

import classNames from "core/helpers/common/classNames";

const FormSwitch = forwardRef(({
	name,
	label,
	rules=[],
	className,
	showIsRequired = true,
	noStyle,
    dependencies,
    disabled,
    ...props
}, ref) => {

    const builtClassName = classNames(
        disabled && "rt--form-item-disabled",
        className,
        "rt--form-item-without-margin"
    )

	return (
        <div className="rt--switcher rt--flex rt--align-center rt--mb-16">
            <Form.Item
                className={builtClassName}
                name={name}
                rules={rules}
                dependencies={dependencies}
                noStyle={noStyle}
                validateFirst={rules.length > 1}
                valuePropName='checked'
            >
                <Switch
                    ref={ref} 
                    disabled={disabled}
                    {...props}
                />
            </Form.Item>
            <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label rt--flex rt--align-center'>{label}</label>
        </div>
	);
});

FormSwitch.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string,
	name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	rules: PropTypes.array,
	showIsRequired: PropTypes.bool,
	noStyle: PropTypes.bool,
    dependencies: PropTypes.array,
    disabled: PropTypes.bool
};

export default FormSwitch;
