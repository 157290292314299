import React from 'react';

import { useTranslation } from "core/hooks/useTranslation";

import Tooltip from "components/common/tooltip";
import useDevice from 'core/hooks/useDevice';

/** List items Component */
const ListItems = ({
    items,
    title,
    showItemsCount = true
}) => {
    const { t } = useTranslation();

    const { isMobile } = useDevice();

    if (items.length === 1) {
        if(isMobile){
            return items[0]
        } else {
            return (
                <Tooltip
                    title={items[0]}
                    placement="bottom"
                >
                    { items[0] }
                </Tooltip>
            )
        }
    }

    const tagTitleToRender = title ?? t("backoffice.common.items")

    const tooltipTitle = (
        <ul className="rt--tooltip-list">
            {items.map((item) => (
                <li key={item}>
                    {item}
                </li>
            ))}
        </ul>
    )

    return (
        <Tooltip
            title={tooltipTitle}
            placement="bottom"
            enableMobile={true}
        >
            <div 
                className='rt--tag rt--flex rt--justify-between rt--align-center'
                onClick={e => e.stopPropagation()}
            >
                <span className='rt--title rt--font-normal rt--font-regular'>
                    {showItemsCount && (
                        <span className="rt--mr-4">
                            {items.length}
                        </span>
                    )}
                    {tagTitleToRender}
                </span>
            </div>
        </Tooltip>
    )
}

export default ListItems;