export const LEADERBOARD_TYPE = {
    OPERATIONAL: "operational",
    FINANCIAL: "financial"
} 

export const LEADERBOARD_DATA_TYPE = {
    AFFILIATE: "affiliate",
    PLAYER: "player",
    COUNTRY: "country",
    CAMPAIGN: "campaign"
} 

export const EXTRA_CONTENT_POSITIONS = {
    RIGHT: "right",
    LEFT: "left"
}