//#region react
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";
import { useNavigate, useLocation } from 'react-router-dom';
//#endregion

//#region actions
import {
    getPermissionGroups,
    setPermissionGroupsSorting,
    deletePermissionGroup
} from "store/actions/portal/userManagement/permissionGroups/permissionGroups.action";
//#endregion

//#region components
import MainDashboardLayout from "components/layouts/main";
import Table from "components/common/table";
import PermissionGroupCreateComponent from "./permissionGroup-create.component";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from "core/constants/permission";
import { PERMISSION_GROUPS } from "constants/pageName.constants";
import ROUTES from "core/constants/routes/routes";
import { columns } from "./columns";
//#endregion

//#region types
import permissionGroupType from "types/permissionGroup/permissionGroup.type";
import sortingType from "types/common/sorting.type";
//#endregion

import { globalCompanyIdSelector, useAuthSelector } from "core/stores/authStore";

import usePermissions from "core/hooks/usePermission";

/** Permission Groups Page Component */
const PermissionGroupsComponent = ({
    getPermissionGroups,
    deletePermissionGroup,
    permissionGroups,
    isLoading,
    total,
    setPermissionGroupsSorting,
    sorting
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const permissionUtils = usePermissions();

    const globalCompanyId = useAuthSelector(globalCompanyIdSelector);

    const [isCreatePopupVisible, setIsCreatePopupVisible] = useState(false);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: PERMISSION_GROUPS });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasCreatePermission = permissionUtils.has(PERMISSION_RESOURCE.PERMISSION_GROUP, PERMISSION_ACTION.CREATE);
    const hasDeletePermission = permissionUtils.has(PERMISSION_RESOURCE.PERMISSION_GROUP, PERMISSION_ACTION.DELETE);

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const showCreatePopup = () => {
        setIsCreatePopupVisible(true);
    }

    const hideCreatePopup = () => {
        setIsCreatePopupVisible(false);
    }

    const handleRightArrowIconClick = (record) => {
        navigate(
            `${ROUTES.PERMISSION_GROUPS_EDIT}/${record.id}` +
            `?name=${record.name}`
        )
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns, columnsThatCanBeIncluded } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: columns,
            constructForInclude: true,
            includedColumns,
            t
        });
    }, [includedColumns, t])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [{
        title: t('backoffice.common.deepView'),
        icon: "right",
        onClick: handleRightArrowIconClick
    }];

    if (hasDeletePermission) {
        tableRowActions.unshift({
            title: t('backoffice.common.delete'),
            confirmationTitle: t("backoffice.permissiongroups.deletePermissionGroup"),
            confirmationMessage: t("backoffice.permissiongroups.doYouWanttoDeletePermissionGroup"),
            icon: "trash",
            showConfirmation: true,
            onClick: record => deletePermissionGroup(record.id),
            disabled: record => Boolean(record.users) && record.users.length > 0,
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        buttons: [{
            text: t("backoffice.permissiongroups.createPermissionGroup"),
            icon: "plus",
            type: "primary",
            enabled: hasCreatePermission,
            onClick: showCreatePopup,
        }],
        breadcrumbs: {
            items: [{ title: t('backoffice.menu.permissionGroups') }]
        }
    }

    //#endregion

    // Close currency add/edit popup, after save
    useEffect(() => {
        hideCreatePopup();
    }, [permissionGroups])

    return (
        <MainDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={permissionGroups}
                total={total}
                loadFn={nextPage => getPermissionGroups(nextPage)}
                updateProps={[globalCompanyId]}
                sorting={sorting}
                setSortingFn={setPermissionGroupsSorting}
                actions={tableRowActions}
            />

            {isCreatePopupVisible && (
                <PermissionGroupCreateComponent onClose={hideCreatePopup} />
            )}
        </MainDashboardLayout>
    )
};

/** PermissionGroupsComponent propTypes
 * PropTypes
*/
PermissionGroupsComponent.propTypes = {
    /** Redux action to get permission groups */
    getPermissionGroups: PropTypes.func,
    /** Redux state property, represents the array of permission groups  */
    permissionGroups: PropTypes.arrayOf(permissionGroupType),
    /** Redux state property, is true when loading permission groups */
    isLoading: PropTypes.bool,
    /** Redux state property, permission groups total count */
    total: PropTypes.number,
    /** Redux action to set permission groups sorting details */
    setPermissionGroupsSorting: PropTypes.func,
    /** Redux action to delete permission group */
    deletePermissionGroup: PropTypes.func,
    /** Redux state property, permission groups sorting details */
    sorting: sortingType
};

const mapDispatchToProps = dispatch => ({
    getPermissionGroups: (type, nextPage) => {
        dispatch(getPermissionGroups(type, nextPage));
    },
    setPermissionGroupsSorting: sorting => {
        dispatch(setPermissionGroupsSorting(sorting));
    },
    deletePermissionGroup: (id, type) => {
        dispatch(deletePermissionGroup(id, type));
    },
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.permissionGroups.isLoading,
        permissionGroups: state.permissionGroups.permissionGroups,
        total: state.permissionGroups.total,
        sorting: state.permissionGroups.sorting
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PermissionGroupsComponent);
