import React, { useState, useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useParams } from "react-router-dom";

import { Form, Col, Row } from 'antd';

import Modal from "components/common/modal";
import Select from "components/common/select";
import PaymentMethodRequisitsForm from './requisitsForm';

import { 
    getAvailablePaymentMethods, 
    getPaymentMethodFormControls, 
    addAffiliatePaymentMethod 
} from "store/actions/portal/affiliates/affiliates/paymentMethods.action";

import useCurrencies from 'hooks/useCurrencies';

import { POPUP_SIZE } from 'constants/common.constants';
import { USER_ROLE } from 'constants/user.constants';

import paymentMethodType from 'types/project/paymentMethod.type';

import { useGlobalSelector, userInfoSelector } from 'core/stores/globalStore';
import { useAuthSelector, userRoleSelector } from 'core/stores/authStore';

const PAYMENT_DETAILS_FORM_PREFIX = "paymentDetails";

/** Affiliate Add Payment Method Popup Component */
const AddAffiliatePaymentMethod = ({
    getAvailablePaymentMethods,
    availablePaymentMethods,
    getPaymentMethodFormControls,
    paymentMethodFormControls,
    addAffiliatePaymentMethod,
    onClose
}) => {

    const { t } = useTranslation();
    const routeParams = useParams();

    const userInfo = useGlobalSelector(userInfoSelector);
    const role = useAuthSelector(userRoleSelector);

    const [selectedCurrencyCode, setSelectedCurrencyCode] = useState(null);
    const [selectedPaymentId, setSelectedPaymentId] = useState(null);

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const [ currencies ] = useCurrencies();

    const affiliateId = role === USER_ROLE.AFFILIATE ? userInfo.id : routeParams.id;

    /** Get Currency Available Payments */
    useEffect(() => {
        if (selectedCurrencyCode !== null) {
            getAvailablePaymentMethods(affiliateId, selectedCurrencyCode);
        }
    }, [selectedCurrencyCode]);

    const handleForm = () => {
        validateFields()
            .then((data) => {
                const d = {
                    ...data,
                    id: affiliateId,
                }
                if(!d[PAYMENT_DETAILS_FORM_PREFIX]){
                    d[PAYMENT_DETAILS_FORM_PREFIX] = {}
                }

                addAffiliatePaymentMethod(d, onClose)
            }).catch(() => { })
    };

    const handleCurrencyChange = currencyCode => {
        setSelectedCurrencyCode(currencyCode);
        setSelectedPaymentId(null);
        setFieldsValue({paymentId: null})
    };

    /** Selected Payment */
    const selectedPayment = useMemo(() => {
        if(!selectedPaymentId) return null;
        return availablePaymentMethods.find(method => method.id === selectedPaymentId)
    }, [selectedPaymentId, availablePaymentMethods])

    /** Get Payment Requisite Fields list for Add */
    useEffect(() => {
        if(selectedPaymentId) {
            getPaymentMethodFormControls(selectedPaymentId);
        }
    }, [selectedPaymentId]);

    return (
        <Modal
            title={t('backoffice.payments.addPaymentMethod')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.add')}
            onOk={handleForm}
            onCancel={onClose}
            size={POPUP_SIZE.MIDDLE}
            disableOkButton={!selectedPaymentId || !selectedCurrencyCode}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.affiliates.currency')} *`}
                            name="currencyCode"
                        >
                            <Select
                                options={
                                    currencies.map(item => (
                                        { value: item.code, text: item.code }
                                    ))
                                }
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.affiliates.currency')}`}
                                onChange={handleCurrencyChange}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.affiliates.paymentMethod')} *`}
                            className={'rt--general-form-item' + (!selectedCurrencyCode ? " rt--form-item-disabled" : "")}
                            name="paymentId"
                        >
                            <Select
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.affiliates.paymentMethod')}`}
                                disabled={!selectedCurrencyCode}
                                options={
                                    availablePaymentMethods.map(payment => (
                                        { value: payment.id, text: payment.name }
                                    ))
                                }
                                onChange={setSelectedPaymentId}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div className='rt--flex rt--flex-col rt--text-light rt--mb-16'>
                            <div>
                                <span className='rt--text-light rt--font-normal'>{t('backoffice.affiliates.minThreshold')}</span>
                                :&nbsp;
                                <span className='rt--title rt--font-bold'>{selectedPayment?.minThreshhold ?? "-"}</span>
                            </div>
                            <div className='rt--mt-4'>
                                <span className='rt--text-light rt--font-normal'>{t('backoffice.affiliates.commissions')}</span>
                                :&nbsp;
                                <span className='rt--title rt--font-bold'>{`${selectedPayment ? `${selectedPayment?.commission}%` :"-" }`}</span>
                            </div>
                        </div>
                    </Col>
                    {
                        selectedPaymentId && (
                            <PaymentMethodRequisitsForm 
                                formControls={paymentMethodFormControls?.formControls}
                                translations={paymentMethodFormControls?.translations}
                                prefix={PAYMENT_DETAILS_FORM_PREFIX}
                            />
                        )
                    }
                </Row>
            </Form>
        </Modal>
    )
}

AddAffiliatePaymentMethod.propTypes = {
    /** Redux action to get Affiliate selected Currency Available Payment Methods */
    getAvailablePaymentMethods: PropTypes.func,
    /** Redux state, represents Affiliate selected Currency Payment Methods  */
    availablePaymentMethods: PropTypes.arrayOf(paymentMethodType),
    /** Redux action to get Affiliate Payment form controls */
    getPaymentMethodFormControls: PropTypes.func,
    /** Redux state, Payment Method Form Controls */
    paymentMethodFormControls: PropTypes.object,
    /** Redux action to add Affiliate Payment Method */
    addAffiliatePaymentMethod: PropTypes.func,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getAvailablePaymentMethods: (id, currencyCode) => {
            dispatch(getAvailablePaymentMethods(id, currencyCode));
        },
        getPaymentMethodFormControls: id => {
            dispatch(getPaymentMethodFormControls(id));
        },
        addAffiliatePaymentMethod: (params, onSuccess) => {
            dispatch(addAffiliatePaymentMethod(params, onSuccess));
        },
    }
)

const mapStateToProps = state => {
    return {
        availablePaymentMethods: state.affiliates.edit.finance.paymentMethods.availablePaymentMethods,
        paymentMethodFormControls: state.affiliates.edit.finance.paymentMethods.paymentMethodFormControls,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAffiliatePaymentMethod);