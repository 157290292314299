import axiosInstance from "core/lib/axios";

import { authStore } from "core/stores/authStore";

import AUTHORIZATION from "core/constants/api/authorization";

class TokenManagerService {
    constructor(){
        this._isTokenRefreshing = false;
        this._pendingRequestsQueue = [];
        this._tokenRefreshPromise = Promise.resolve();
    }

    async updateToken(){
        if(this._isTokenRefreshing){
            return this._tokenRefreshPromise;
        }

        this._isTokenRefreshing = true;

        this._tokenRefreshPromise = authStore.refreshToken()
            .then(({ token }) => {
                this._pendingRequestsQueue.forEach(request => {
                    request.configs.headers[AUTHORIZATION] = `Bearer ${token}`;

                    axiosInstance
                        .request(request.configs)
                            .then(response => request.resolve(response))
                            .catch(error => request.reject(error));
                });

                this._clearPendingRequestsQueue()
            })
            .catch(error => {
                authStore.logout();
                return error;
            })
            .finally(() => {
                this._isTokenRefreshing = false;
                this._tokenRefreshPromise = Promise.resolve();
            });

        return this._tokenRefreshPromise;
       
    }

    registerPendingRequest(configs){
        const request = {
			configs
		};

        request.promise = new Promise((resolve, reject) => {
			request.resolve = resolve;
			request.reject = reject;
		});

        this._pendingRequestsQueue.push(request);

        return request.promise;
    }

    _clearPendingRequestsQueue(){
        this._pendingRequestsQueue = []
    }
}

const TokenManagerServiceInstance = new TokenManagerService();

export default TokenManagerServiceInstance;