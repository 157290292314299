import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";

import { Form, Row, Col } from "antd";

import HTMLViewer from "components/common/htmlViewer";
import Icon from "components/common/icon";
import Select from "components/common/select";

import { copyToClipboard } from "utils/common";
import { useTranslation } from "core/hooks/useTranslation";

const MediaCode = ({ code }) => {

    const { t } = useTranslation();

    const [selectedDomain, setSelectedDomain] = useState(
        code?.domains?.length > 0 ? code?.domains[0] : code?.defaultDomain
    );

    const handleCopyIconClick = () => {
        copyToClipboard(getCurrentCode(), t);
    };

    const getCurrentCode = () => {
        if (!code) return "";
        return code.content?.replaceAll("{domain}", selectedDomain);
    };

    return (
        <Fragment>
            {code?.domains?.length > 1 && (
                <Row gutter={[16, 0]}>
                    <Col span={24} lg={12}>
                        <Form.Item>
                            <Select
                                options={code?.domains?.map((domain) => ({
                                    value: domain,
                                    text: domain,
                                }))}
                                getPopupContainer={() =>
                                    document.getElementsByClassName("rt--portal-layout")[0]
                                }
                                onChange={(e) => setSelectedDomain(e)}
                                value={selectedDomain}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            )}

            <div className="rt--media-creation-result">
                <div className="rt--media-creation-result-text">
                    <HTMLViewer value={getCurrentCode()} />
                </div>

                <div
                    className="rt--media-creation-result-icon rt--pr-8 rt--pl-8 rt--pb-8 rt--pt-8"
                    onClick={handleCopyIconClick}
                >
                    <Icon name="copy" />
                </div>
            </div>
        </Fragment>
    );
};

/** MediaCode propTypes
 * PropTypes
 */
MediaCode.propTypes = {
    /** Redux state property, represents the media code */
    code: PropTypes.shape({
        content: PropTypes.string,
        defaultDomain: PropTypes.string,
        domains: PropTypes.arrayOf(PropTypes.string),
    }),
};

export default MediaCode;
