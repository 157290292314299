//#region react
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
//#endregion

//#region actions
import {
    getCalculationApprovals,
    setCalculationApprovalsSorting,
    setCalculationApprovalsFilters,
    approveCalculation
} from 'store/actions/portal/payment/calculationApprovals/calculationApprovals.action';
//#endregion

//#region components
import MainDashboardLayout from "components/layouts/main";
import Table from "components/common/table";
import Filters from "./filters.component";
//#endregion

//#region utils
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { CALCULATION_APPROVALS } from 'constants/pageName.constants';
import { getExpandTableColumns, getTableColumns } from './columns';
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'core/constants/permission';
import ApiUrls from 'constants/api.constants';
//#endregion

//#region hooks
import useFormat from 'hooks/useFormat';
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endRegion

//#region types
import commissionCalculationType from 'types/commissionPlan/commissionCalculation.type';
import sortingType from 'types/common/sorting.type';
//#endregion

import { globalProjectIdSelector, useAuthSelector, userSelector } from 'core/stores/authStore';
import usePermissions from 'core/hooks/usePermission';

/** Calculation Approvals Page Component */
const CalculationApprovalsComponent = ({
    calculations,
    sorting,
    filters,
    total,
    isLoading,
    getCalculationApprovals,
    setCalculationApprovalsSorting,
    setCalculationApprovalsFilters,
    approveCalculation
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);
    const { allowImpactOnCPA } = useAuthSelector(userSelector);

    const { formatAmount } = useFormat();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: CALCULATION_APPROVALS });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//
    const hasModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.PAYMENTS_COMMISSION_CALCULATIONS, PERMISSION_ACTION.MODIFY );
    const hasDataExportPermission = permissionUtils.has( PERMISSION_RESOURCE.PAYMENTS_COMMISSION_CALCULATIONS, PERMISSION_ACTION.EXPORT );

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setCalculationApprovalsFilters({
            ...filters,
            affiliateUserNameOrId: value
        })
    }

    const handleDateSearchChange = value => {
        setCalculationApprovalsFilters({
            ...filters,
            from: value[0],
            to: value[1]
        })
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded and columnsForExport are only needed in the desktop version.
    const {
        mainTableColumns,
        expandTableColumns,
        columnsThatCanBeIncluded,
        columnsForExport
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            expandColumns: getExpandTableColumns,
            includedColumns,
            constructForInclude: true,
            constructForExport: true,
            additionalProps: {
                formatAmount,
                allowImpactOnCPA,
                t
            },
            t
        })
    }, [includedColumns, allowImpactOnCPA, t]);

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        search: {
            onSearch: handleSearchChange,
            loadFn: getCalculationApprovals,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.AFFILIATES,
                dependencies: [globalProjectId]
            },
            placeholder: t("backoffice.commissioncalculations.affiliateUserNameOrId"),
        },
        date: {
            onSearch: handleDateSearchChange,
            placeholder: t('backoffice.common.date'),
            showTime: true,
            enabledMountsCount: 12,
            loadFn: getCalculationApprovals,
            value: [filters.from , filters.to]
        },
        breadcrumbs: {
            items: [
                { title: t('backoffice.menu.payment') },
                { title: t('backoffice.menu.approveCommission') }
            ]
        },
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.menu.approveCommission"),
            columns: columnsForExport,
            url: ApiUrls.EXPORT_COMMISSION_CALCULATION_APPROVALS,
            filters: filters,
        }
    }

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasModifyPermission) {
        tableRowActions.push({
            title: t('backoffice.common.approve'),
            confirmationMessage: t("backoffice.commissioncalculations.doYouWanttoApproveCalculation"),
            confirmationTitle: t("backoffice.commissioncalculations.commissionCalculationApproval"),
            icon: "ok",
            showConfirmation: true,
            onClick: record => approveCalculation(record.id),
        })
    }

    //#endregion

    const disabledExpandRowsIds = calculations.filter(calculation => !Boolean(calculation.details)).map(calculation => calculation.id)
    
    return (
        <MainDashboardLayout
            header={headerPartsData}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={calculations}
                loadFn={getCalculationApprovals}
                sorting={sorting}
                setSortingFn={setCalculationApprovalsSorting}
                filters={filters}
                setFiltersFn={setCalculationApprovalsFilters}
                total={total}
                updateProps={[globalProjectId]}
                actions={tableRowActions}
                expandable={{
                    title: t("backoffice.affiliates.calculations"),
                    disabled: disabledExpandRowsIds,
                    details: rowData => ({
                        columns: expandTableColumns,
                        data: rowData.details,
                        uniqueKey: "id"
                    }),
                }}
            />

        </MainDashboardLayout> 
    )
}

/** CalculationApprovalsComponent propTypes
    * PropTypes
*/
CalculationApprovalsComponent.propTypes = {
    /** Redux state property, represents the calculation approvals */
    calculations: PropTypes.arrayOf(commissionCalculationType),
    /** Redux state property, calculation approvals sorting details */
    sorting: sortingType,
    /** Redux state property, calculation approvals filters */
    filters: PropTypes.object,
    /** Redux state property, calculation approvals total count */
    total: PropTypes.number,
    /** Redux state property, is true when loading calculation approvals */
    isLoading: PropTypes.bool,
    /** Redux action to get calculation approvals */
    getCalculationApprovals: PropTypes.func,
    /** Redux action to set calculation approvals sorting details */
    setCalculationApprovalsSorting: PropTypes.func,
    /** Redux action to set calculation approvals filters */
    setCalculationApprovalsFilters: PropTypes.func,
    /** Redux action to approve calculation */
    approveCalculation: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getCalculationApprovals: nextPage => {
            dispatch(getCalculationApprovals(nextPage))
        },
        setCalculationApprovalsSorting: sorting => {
            dispatch(setCalculationApprovalsSorting(sorting));
        },
        setCalculationApprovalsFilters: filters => {
            dispatch(setCalculationApprovalsFilters(filters));
        },
        approveCalculation: id => {
            dispatch(approveCalculation(id));
        }
    }
)

const mapStateToProps = state => {
    return {
        calculations: state.calculationApprovals.calculations,
        total: state.calculationApprovals.total,
        sorting: state.calculationApprovals.sorting,
        filters: state.calculationApprovals.filters,
        isLoading: state.calculationApprovals.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalculationApprovalsComponent)