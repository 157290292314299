import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";
import { Button } from 'antd';

import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';

import Upload from '../upload';
import Lightbox from '../lightbox';
import Icon from '../icon';

const DEFAULT_FRAME_SIZE = "1200x675";

/** HTML Editor Component */
const HTMLEditor = ({
    onChange,
    value,
    size=DEFAULT_FRAME_SIZE,
    name
}) => {

    const { t } = useTranslation();

    // State to show/hide thumbnail
    const [lightbox, setLightbox] = useState(null);

    const handleRun = () => {
        setLightbox({
            items : [
                {
                    type: "iframe",
                    name: name,
                    width: size?.split("x")?.[0],
                    height: size?.split("x")?.[1],
                    html: value
                }
            ]
        })
    }

    const handleEditorChange = value => {
        onChange && onChange(value)
    }

    const onUpload = file => {
        const reader = new FileReader();
        reader.onload = event => {
            onChange && onChange(event.target.result);
        }
        reader.onerror = error => { console.log(error); }
        reader.readAsText(file);
    }

    return (
        <div className='rt--form-item-html rt--pl-16 rt--pr-16 rt--pb-16 rt--pt-16'>
            <div className='rt--form-item-html-header rt--flex rt--align-center rt--justify-between rt--mb-12'>
                <Button
                    type="primary"
                    className="rt--button rt--button-main"
                    onClick={handleRun}
                    disabled={!value}
                >
                    <span className='rt--font-normal rt--font-uppercase'>{t('backoffice.campaigns.run')}</span>
                    <Icon name="right" />
                </Button>
                <Upload
                    onFileReady={onUpload}
                    extensions={["text/html"]}
                    showUploadList={false}
                    size={1024 * 300}
                >
                    <div className="rt--form-item-html-upload rt--flex rt--align-center rt--justify-end rt--pl-16 rt--pr-16 rt--pb-4 rt--pt-4">
                        <Icon name="upload" />
                        <span className="rt--text-secondary rt--font-bold rt--font-normal rt--pl-8">{t('backoffice.campaigns.uploadHTML')}</span>
                    </div>
                </Upload>
            </div>
            <CodeMirror
                extensions={[html()]}
                height="280px"
                placeholder="<!DOCTYPE html>"
                value={value}
                onChange={handleEditorChange}
            />

            {lightbox && (
                <Lightbox
                    onClose={() => setLightbox(null)}
                    items={lightbox.items}
                />
            )}
        </div>
    )
}

/** HTMLEditor propTypes
    * PropTypes
*/
HTMLEditor.propTypes = {
    /** Callback which called on input change */
    onChange: PropTypes.func,
    /** Input value*/
    value: PropTypes.string,
    /** Iframe size */
    size: PropTypes.string,
    /** Name */
    name: PropTypes.string
}

export default HTMLEditor;