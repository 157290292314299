import { useContext } from "react";
import { useTranslation } from "core/hooks/useTranslation";

import UnsavedChangesContext from "components/common/unsavedChangesProvider/unsavedChangesContext";

import useConfirmation from "./useConfirmation";

const useUnsavedChangesConfirmation = ({ cb, subscribe = [] }) => {
    const { t } = useTranslation();

    const { unsavedChangesStore } = useContext(UnsavedChangesContext);

    const { showConfirmationPopup } = useConfirmation({
        title: t("backoffice.common.confirmAction"),
        message: t("backoffice.common.withoutSavingMessage"),
        okText: t("backoffice.common.discard"),
        cancelText: t("backoffice.common.cancel")
    });

    const handleUnsavedChangesConfirmation = (...args) => {
        let needConfirmation = false;

        for (let formPageType of subscribe) {
            if (unsavedChangesStore[formPageType]?.changed) {
                needConfirmation = true;
            }
        }

        if (needConfirmation) {
            showConfirmationPopup({
                onConfirm: () => cb(...args),
            })

            return;
        }

        cb(...args);
    }

    return handleUnsavedChangesConfirmation;
}

export default useUnsavedChangesConfirmation;