import isUndefined from "core/helpers/typeChecks/isUndefined";

import { ALL_VALUE } from "./constants";

export const findOptionByValue = ( options, value ) => {
    return options.find(option => option.value === value)
}

export const findOptionTextByValue = ( options, value ) => {
    const option = options.find(option => option.value === value);
    return option?.text ?? value;
}

export const isOptionDisabled = (option, selected=[], maxMultipleSelectCount) => {
    if(option.disabled) return true;
    if(!isUndefined(maxMultipleSelectCount)){
        if(
            selected.length >= maxMultipleSelectCount && !selected.includes(option.value)
        ){
            return true
        }
    }
    return false;
}

export const isOptionSelected = (option, value, isMultiple) => {
    if (isMultiple) {
        return value.includes(option.value)
    }
    return value === option.value;
}

export const filterAndSortOptions = (options, searchValue = "") => {
    const search = searchValue.toLowerCase().trim();
    if (!search) {
        return options;
    }

    const comparator = (text = "") => {
        return text.toLowerCase().includes(search);
    }

    const filtered = options.filter(option => comparator(option.text) || comparator(String(option.value)) );

    filtered.sort((a, b) => {
        let aIndex = 1000000; // too big number
        let bIndex = 1000000;
        const aText = a?.text?.toLowerCase() ?? "";
        const bText = b?.text.toLowerCase() ?? "";

        if (aText.includes(search)) {
            aIndex = aText.indexOf(search);
        }
        if (bText.includes(search)) {
            bIndex = bText.indexOf(search);
        }

        return aIndex >= bIndex ? 1 : -1;
    });

    return filtered;
}

export const getSelectValue = ({ value , isMultiple, options, isSelectAllOptionVisible }) => {
    if (!isMultiple) {
        return value;
    }

    if (isMultiple && !isSelectAllOptionVisible) {
        if (Array.isArray(value)) {
            return value
        }

        return [];
    }

    if (!value || !options) {
        return [];
    }

    if (value.length === options.length) {
        return [ALL_VALUE, ...value];
    } else {
        return value;
    }

}

export const getNewSelectedValue = ({ isSelectAllOptionVisible, options, newValue, prevValue }) => {
    if (!isSelectAllOptionVisible) {
        return newValue;
    }

    // Select All
    if (newValue.at(-1) === ALL_VALUE) {
        return options.map(option => option.value);
    }

    // All was selected, and now one option has been deselected
    if (newValue.includes(ALL_VALUE) && newValue.length === options.length) {
        return newValue.filter(selectedValue => selectedValue !== ALL_VALUE);
    }

    // Deselect All
    if (prevValue.includes(ALL_VALUE) && newValue.length === options.length) {
        return [];
    }

    return newValue;
}