import React, { lazy } from "react";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import Spin from "core/ui-kit/spin";

import ROUTES from "core/constants/routes/routes";

import withSuspenseHoc from "core/HOCs/withSuspense";

const LoginContainer = withSuspenseHoc(lazy(() => import("./loginContainer")), <Spin />);

const LoginPageRoute = {
	path: ROUTES.LOGIN,
	element: (
		<GoogleReCaptchaProvider reCaptchaKey={import.meta.env.SYSTEM_CAPTCHA_KEY}>
			<LoginContainer />
		</GoogleReCaptchaProvider>
	),
};

export default LoginPageRoute
