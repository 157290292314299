import {
    COMPANY_PASSWORD_PATTERN,
    COMPANY_PASSWORD_PATTERN_RULE,
    COMPANY_PASSWORD_PATTERN_CONTAINS
} from 'constants/company.constants';

/** Validate function for password
     * @function
     * @param {string} value - value of password field
     * @param {object} passwordSettings - password validation settings
 */
export const validatePassword = (value, passwordSettings, t) => {

    if (value.length < passwordSettings.passwordMinLimit || value.length > passwordSettings.passwordMaxLimit) {
        return Promise.reject(t('backoffice.validation.passwordRulesMustBeBetween').replace("%X%", passwordSettings.passwordMinLimit).replace("%Y%", passwordSettings.passwordMaxLimit))
    }

    const pattern = passwordSettings.pattern;
    const patternContain = pattern?.contain ?? [];

    if (pattern?.type === COMPANY_PASSWORD_PATTERN.CONTAINS) {
        for (let i = 0; i < patternContain.length; i++) {
            let result;
            switch (patternContain[i]?.type) {
                case COMPANY_PASSWORD_PATTERN_CONTAINS.UPPERCASE:
                    result = (/[A-Z]/.test(value));
                    break;
                case COMPANY_PASSWORD_PATTERN_CONTAINS.LOWERCASE:
                    result = (/[a-z]/.test(value));
                    break;
                case COMPANY_PASSWORD_PATTERN_CONTAINS.DIGITS:
                    result = (/[0-9]/.test(value));
                    break;
                case COMPANY_PASSWORD_PATTERN_CONTAINS.DASH:
                    result = (/-/.test(value));
                    break;
                case COMPANY_PASSWORD_PATTERN_CONTAINS.UNDERSCORE:
                    result = (/_/.test(value));
                    break;
                case COMPANY_PASSWORD_PATTERN_CONTAINS.SPACE:
                    result = (/\s/.test(value));
                    break;
                case COMPANY_PASSWORD_PATTERN_CONTAINS.SPECIAL:
                    result = (/[!#$%&*+,./:;=?@\\^`|~'"]/.test(value));
                    break;
                case COMPANY_PASSWORD_PATTERN_CONTAINS.BRACKETS:
                    result = (/[{([<>\])}]/.test(value));
                    break;
            }

            if (result && patternContain[i]?.value === COMPANY_PASSWORD_PATTERN_RULE.NONE) {
                return Promise.reject(t('backoffice.validation.passwordRulesMustNotContain').replace("%X%", t(`backoffice.companies.containType${patternContain[i]?.type}`)));
            } else if (!result && patternContain[i].value === COMPANY_PASSWORD_PATTERN_RULE.MUST) {
                return Promise.reject(t('backoffice.validation.passwordRulesMustContain').replace("%X%", t(`backoffice.companies.containType${patternContain[i]?.type}`)));
            }
        }

        if (pattern?.alsoIncludeCharacters) {
            if (!(pattern?.alsoIncludeCharacters ?? "")
                .split('')
                .every(char => value.includes(char))) {
                return Promise.reject(t('backoffice.validation.passwordRulesMustInclude').replace("%X%", pattern?.alsoIncludeCharacters))
            }
        }

    } else if (pattern?.type === COMPANY_PASSWORD_PATTERN.PATTERN) {
        const reg = new RegExp(pattern?.regularExpression ?? "", 'g');
        if (!reg.test(value)) {
            return Promise.reject(t('backoffice.validation.passwordRulesMustMatchRegularExpression').replace("%X%", pattern?.regularExpression));
        }
    }

    return Promise.resolve();
}