//#region react
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";
import { useParams } from "react-router-dom";

import { Button, Spin } from "antd";

import { saveNewsletterColors } from "store/actions/portal/newsletterCustomize/styles.action";

import ColorPicker from "components/common/colorPicker";

import { isFormChanged } from "utils/form";

import { SECTION_NAMES, NEWSLETTER_TEMPLATE_CONFIGS, NEWSLETTER_TEMPLATE } from "constants/newsletter.constants";
import { UNSAVED_FORM_PAGE_TYPE } from "constants/common.constants";

import newsletterColorsType from "types/newsletter/colors.type";

/** Portal Customize Page Sections Colors Editor Component */
const ColorsEditor = ({
    colors = {},
    saveNewsletterColors,
    isSaving,
    isLoading,
    onTabChange,
}) => {
    const { t } = useTranslation();

    const searchParams = useParams();

    const [values, setValues] = useState({});
    const [isFormTouched, setIsFormTouched] = useState(false);

    useEffect(() => {
        setValues(colors);
    }, [colors])

    const onChange = (key, value) => {
        const newValues = { ...values };
        newValues[key] = value;
        setValues(newValues);

        setIsFormTouched(isFormChanged({ ...newValues }, { ...colors }))
    }

    const handleForm = () => {
        saveNewsletterColors(values, searchParams.id);
        setIsFormTouched(false);
    }

    useEffect(() => {
        onTabChange(isFormTouched, SECTION_NAMES.COLORS, UNSAVED_FORM_PAGE_TYPE.CUSTOMIZE_STYLES);
    }, [isFormTouched])

    return (
        <Spin spinning={isLoading}>
            <div>
                {
                    NEWSLETTER_TEMPLATE_CONFIGS[NEWSLETTER_TEMPLATE.DEFAULT].COLORS.map(color => (
                        <div 
                            key={color}
                            className="rt--flex rt--align-center rt--justify-between rt--pt-10 rt--pb-10 rt--mb-4 rt--customize-widget-main-content-form-item"
                        >
                            <span className="rt--title rt--font-normal rt--font-regular">{t(`backoffice.communication.${color}`)}</span>
                            <ColorPicker
                                color={values[color]}
                                onChange={value => onChange(color, value)}
                            />
                        </div>
                    ))
                }
               
                <div className="rt--flex rt--justify-end rt--align-center rt--pt-24 rt--mt-16">
                    <Button
                        type="primary"
                        className="rt--button"
                        onClick={handleForm}
                        loading={isSaving}
                        disabled={!isFormTouched}
                    >
                        <span>{t("backoffice.common.save")}</span>
                    </Button>
                </div>
            </div>
        </Spin>
    );
};

/** ColorsEditor propTypes
 * PropTypes
*/
ColorsEditor.propTypes = {
    /** Redux state promerty, newsletter colors */
    colors: newsletterColorsType,
    /** Redux action to save newsletter colors */
    saveNewsletterColors: PropTypes.func,
    /** Redux state promerty, is true when newsletter saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when loading newsletter customization data */
    isLoading: PropTypes.bool,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    saveNewsletterColors: (colors, id) => {
        dispatch(saveNewsletterColors(colors, id))
    }
});

const mapStateToProps = (state) => {
    return {
        isSaving: state.newsletterCustomize.isSaving,
        colors: state.newsletterCustomize.configuration.colors,
        isLoading: state.newsletterCustomize.isLoading
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ColorsEditor);
