import React from "react";
import PropTypes from 'prop-types';

import SelectDesktop from "./desktop";
import SelectMobile from "./mobile";

import Responsive from "core/ui-kit/responsive";

const Select = ({
    options,
    value,
    onChange,
    placeholder,
    disabled=false,
    className,
    search=false,
    isMultiple=false,
    showSelectAllButton = true,
    icon="down",
    showArrow=true,
    maxMultipleSelectCount,
    ...rest
}) => {
    return (
		<Responsive
			mobile={
				<SelectMobile
                    options={options}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    className={className}
                    showSelectAllButton={showSelectAllButton}
                    icon={icon}
                    isMultiple={isMultiple}
                    disabled={disabled}
                    maxMultipleSelectCount={maxMultipleSelectCount}
                    search={search}
                />
			}
			desktop={
				<SelectDesktop
                    options={options}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    className={className}
                    showSelectAllButton={showSelectAllButton}
                    showArrow={showArrow}
                    icon={icon}
                    isMultiple={isMultiple}
                    disabled={disabled}
                    maxMultipleSelectCount={maxMultipleSelectCount}
                    search={search}
                    { ...rest }
                />
			}
		/>
	);
}

Select.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        subs: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        }))
    })),
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.bool]),
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    search: PropTypes.bool,
    isMultiple: PropTypes.bool,
    showSelectAllButton: PropTypes.bool,
    icon: PropTypes.string,
    showArrow: PropTypes.bool,
    maxMultipleSelectCount: PropTypes.number
}

export default Select;