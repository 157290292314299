export const DATE_FORMAT = {
    FORMAT_1: 1,
    FORMAT_2: 2,
    FORMAT_3: 3,
    FORMAT_4: 4,
    FORMAT_5: 5,
    FORMAT_6: 6
}

export const TIME_FORMAT = {
    _24_HOUR: 1,
    _12_HOUR: 2
}

export const DATE_PICKER_RANGES = {
    TODAY: "today",
    YESTERDAY: "yesterday",
    THIS_MONTH: "thisMonth",
    LAST_MONTH: "lastMonth",
    LAST_3_MONTH: "last3Month",
    YTD: "ytd"
}