import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';

import particles from "core/lib/particles";

import PoweredBy from 'core/ui-kit/poweredBy';

import { LOGO_TYPE } from 'core/constants/common/logo';

/** Auth Layout Sidebar component */
const AuthSidebar = ({
    logos
}) => {

    useEffect(() => {
        particles();
    }, [])

    return (
        <div className='rt--auth-sidebar'>
            <div></div>
            <img src={`${import.meta.env.SYSTEM_CDN_URL}/${logos[LOGO_TYPE.LOGO]}`} alt="logo" />
            <PoweredBy />
        </div>
    )
}

AuthSidebar.propTypes = {
    logos: PropTypes.object
}

export default memo(AuthSidebar);