import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";
import { connect } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";

import SubTabTableDashboardLayout from 'components/layouts/tab/subtab/table';
import Table from "components/common/table";
import Icon from "components/common/icon";

import { getNewsletterAffiliates, setNewsletterAffiliatesSorting } from 'store/actions/portal/communications/newsletter/affiliates.action';

import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { deleteQueryStringParamsToUrl } from 'utils/queryString';

import { getTableColumns } from './columns';
import { NEWSLETTER_AFFILIATES } from 'constants/pageName.constants';
import { UNSAVED_FORM_PAGE_TYPE } from 'constants/common.constants';

import useIncludedColumns from 'hooks/useIncludedColumns';
import useUnsavedChangesConfirmation from 'hooks/useUnsavedChangesConfirmation';

import affiliateType from 'types/affiliate/generalInfo.type';
import sortingType from 'types/common/sorting.type';

const AffiliatesComponent = ({
    getNewsletterAffiliates,
    setNewsletterAffiliatesSorting,
    affiliates,
    isLoading,
    sorting,
    total
}) => {

    const { t } = useTranslation();

    const { search, hash, pathname } = useLocation();
    const navigate = useNavigate();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: NEWSLETTER_AFFILIATES });

    const currentNewsletterId = (new URLSearchParams(search)).get("newsletterId");

    const navigateWithConfirmation = useUnsavedChangesConfirmation({
        cb: navigate,
        subscribe: [UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    const { 
        mainTableColumns,
        columnsThatCanBeIncluded, 
    } = useMemo(() => {
        return tableColumnsCreator({ 
            mainColumns: getTableColumns,
            includedColumns: includedColumns,
            constructForInclude: true, 
            additionalProps: {
                t
            },
            t
        })
    }, [t])

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        }
    }

    return (
        <SubTabTableDashboardLayout
            header={headerPartsData}
            buttons={[
                {
                    type: "secondary",
                    onClick: () => {
                        const url = deleteQueryStringParamsToUrl(pathname, search, hash, ["newsletterId", "newsletterName"] );
                        navigateWithConfirmation(url, { replace: true } )
                    },
                    text: (
                        <span className='rt--back-button'>
                            <Icon name="left" size={16} />
                            <span>
                                {t("backoffice.common.back")}
                            </span>
                        </span>
                    ),
                    enabled: true,
                    className: "rt--button-secondary",
                    placement: "left",
                },
            ]}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={affiliates}
                loadFn={nextPage => getNewsletterAffiliates(currentNewsletterId, nextPage)}
                setSortingFn={setNewsletterAffiliatesSorting}
                sorting={sorting}
                total={total}
                disableFullView={true}
            />

        </SubTabTableDashboardLayout>
    )
}

/** AffiliatesComponent propTypes
    * PropTypes
*/
AffiliatesComponent.propTypes = {
    /** Redux state property, array of affiliates */
    affiliates: PropTypes.arrayOf(affiliateType),
    /** Redux state property, is true when loading affilaites */
    isLoading: PropTypes.bool,
    /** Redux action to get newsletter affiliates */
    getNewsletterAffiliates: PropTypes.func,
    /** Redux action to get newsletter affiliates sorting */
    setNewsletterAffiliatesSorting: PropTypes.func,
    /** Redux state property, affilaites sorting data */
    sorting: sortingType,
    /** Redux state property, affilaites total count */
    total: PropTypes.number
}

const mapDispatchToProps = dispatch => (
    {

        getNewsletterAffiliates: (id, nextPage) => {
            dispatch(getNewsletterAffiliates(id, nextPage));
        },

        setNewsletterAffiliatesSorting: sorting => {
            dispatch(setNewsletterAffiliatesSorting(sorting))
        },
    }
)

const mapStateToProps = state => {
    return {
        affiliates: state.newsletter.affiliates.affiliates,
        isLoading: state.newsletter.affiliates.isLoading,
        total: state.newsletter.affiliates.total,
        sorting: state.newsletter.affiliates.sorting
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AffiliatesComponent);