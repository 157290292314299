import React, { useState, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";

import { Menu, Button } from "antd";

import Dropdown from 'components/common/dropdown';
import Confirmation from "components/common/confirmation";
import Notes from 'components/common/notes';
import Icon from 'components/common/icon';

import { isMobile } from "utils/common";

/** Entity Actions Component */
const EntityActions = ({
    actions
}) => {

    const { t } = useTranslation();

    const _dropdownRef = useRef(null);

    /** Flag to show/hide delete confirmation popup */
    const [confirmationPopup, setConfirmationPopup] = useState(null);

    /** Flag to show/hide status change popup */
    const [statusChangePopup, setStatusChangePopup] = useState(null);

    /**Available Actions */
    const availableActions = actions.filter(action => !action.disabled);

    const handleClick = action => {
        if (action) {
            if (action.isConfirmation) {
                setConfirmationPopup(action)
            } else if (action.isStatusChange) {
                setStatusChangePopup(action)
            } else {
                action.onClick && action.onClick()
            }
        }
    }

    return availableActions.length > 0 ? (
        <Fragment>
            {
                availableActions.length > 1 ? (
                    <Dropdown
                        ref={_dropdownRef}
                        title={t("backoffice.common.actions")}
                        overlay={(
                            <Menu
                                selectedKeys={[]}
                                onClick={e => {
                                    const action = availableActions.find(ac => ac.key === e.key);
                                    handleClick(action);

                                    _dropdownRef.current && _dropdownRef.current.close()
                                }}
                                className={isMobile() ? "rt--table-actions-menu" : "rt--actions-menu"}
                            >
                                {
                                    availableActions.map(action => (
                                        <Menu.Item key={action.key}>
                                            <div className="rt--flex rt--align-center">
                                                {
                                                    isMobile() && <Icon name={action.icon} className="rt--mr-8" />
                                                }

                                                <span className="rt--title rt--font-regular rt--font-normal">{action.title}</span>
                                            </div>
                                        </Menu.Item>
                                    ))
                                }
                            </Menu>
                        )}
                        disabled={availableActions.length === 0}
                        getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                    >

                        <div
                            className='rt--actions-button rt--flex rt--align-center rt--justify-center'
                        >
                            <Icon name={isMobile() ? "ellipsis" : "more"} />
                        </div>

                    </Dropdown>
                ) : (
                    <div className='rt--flex rt--align-center rt--justify-end'>
                        {
                            availableActions.map(action => (
                                <Button
                                    key={action.key}
                                    type={action.buttonType}
                                    htmlType="button"
                                    className={"rt--button rt--ml-16 " + (action.buttonClassName || "")}
                                    onClick={() => {
                                        handleClick(action)
                                    }}
                                >
                                    <span>{action.title}</span>
                                </Button>
                            ))
                        }
                    </div>
                )
            }

            {
                confirmationPopup && (
                    <Confirmation
                        title={confirmationPopup.title}
                        message={confirmationPopup.confirmationMessage}
                        onOk={() => {
                            confirmationPopup.onClick && confirmationPopup.onClick();
                            setConfirmationPopup(null);
                        }}
                        onCancel={() => setConfirmationPopup(null)}
                        isVisible={true}
                    />
                )
            }

            {
                statusChangePopup && (
                    <Notes
                        title={statusChangePopup.title}
                        noteTitle={statusChangePopup.noteTitle}
                        cancelText={statusChangePopup.cancelText}
                        okText={statusChangePopup.okText}
                        maxLength={300}
                        onClose={() => setStatusChangePopup(null)}
                        onSuccess={value => {
                            statusChangePopup.onClick && statusChangePopup.onClick(value);
                            setStatusChangePopup(null);
                        }}
                    />
                )
            }

        </Fragment>
    ) : <Fragment />
}

/** EntityActions propTypes
    * PropTypes
*/
EntityActions.propTypes = {
    /** Available actions  */
    actions: PropTypes.arrayOf(PropTypes.shape({
        /** Unique key */
        key: PropTypes.string,
        /** Button Type */
        buttonType: PropTypes.oneOf(["primary", "secondary", "danger"]),
        /** Additional class name for the button */
        buttonClassName: PropTypes.string,
        /** Title */
        title: PropTypes.string,
        /** Icon */
        icon: PropTypes.string,
        /** Function to fire on click */
        onClick: PropTypes.func,
        /** Is the action disabled */
        disabled: PropTypes.bool,
        /** Should show confirmation popup on click */
        isConfirmation: PropTypes.bool,
        /** Is action status change */
        isStatusChange: PropTypes.bool,
        /** Confirmation popup ,essage */
        confirmationMessage: PropTypes.string
    }))
}

export default EntityActions