import React from "react";

import { useTranslation } from "core/hooks/useTranslation";

import Icon from "core/ui-kit/icon";
import Tooltip from "core/ui-kit/tooltip";

import showSuccess from "core/helpers/common/showSuccess";

const WithCopyButton = ({
    value
}) => {

    const { t } = useTranslation();

    const handleClick = e => {
        e.stopPropagation();

        navigator.clipboard.writeText(value)
            .then(() => {
                showSuccess(t('backoffice.common.copied'));
            })
    }

    return (
        <div
            className="rt--table-col rt--flex rt--align-center rt--table-col-copy"
        >
            <span
                className="rt--title rt--font-normal rt--font-regular rt--flex-equal"
                title={value}
            >
                {value}
            </span>
            <div
                className="rt--table-col-button rt--ml-8"
                onClick={handleClick}
            >
                <Tooltip 
                    title={t("backoffice.common.copy") + " ID"}
                >
                    <Icon name="copy" />
                </Tooltip>
            </div>
        </div>
    );
}

export default WithCopyButton;