import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import {
    saveNewsletterTemplateContent,
    setNewsletterCustomizeSaveActionBefore,
    setNewsletterCustomizeSaveActionFinished
} from "./common.action";

import { 
    SET_NEWSLETTER_CUSTOMIZE_COLORS,
    SET_NEWSLETTER_CUSTOMIZE_FONT_FAMILY
} from "../../../actionTypes";

const setNewsletterColors = colors => ({
    type: SET_NEWSLETTER_CUSTOMIZE_COLORS,
    payload: { colors }
})

export const saveNewsletterColors = (colors, id) => {
    return ( dispatch, getState ) => {
        dispatch(setNewsletterCustomizeSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_NEWSLETTER_TEMPLATE_COLORS,
            method: Methods.POST,
            data: {
                colors,
                id
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                dispatch(setNewsletterColors(data.value?.colors));
                setTimeout(() => {
                    dispatch(saveNewsletterTemplateContent(id, getState().newsletterCustomize.configuration))
                }, 0);
            } 
            dispatch(setNewsletterCustomizeSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setNewsletterCustomizeSaveActionFinished());
        })
    }
}

const setNewsletterFontFamily = fontFamily => ({
    type: SET_NEWSLETTER_CUSTOMIZE_FONT_FAMILY,
    payload: { fontFamily }
})

export const saveNewsletterFontFamily = (fontFamily, id) => {
    return ( dispatch, getState ) => {
        dispatch(setNewsletterCustomizeSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_NEWSLETTER_TEMPLATE_FONT_FAMILY,
            method: Methods.POST,
            data: {
                fontFamily,
                id
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                dispatch(setNewsletterFontFamily(data.value?.fontFamily));
                setTimeout(() => {
                    dispatch(saveNewsletterTemplateContent(id, getState().newsletterCustomize.configuration))
                }, 0);
            } 
            dispatch(setNewsletterCustomizeSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setNewsletterCustomizeSaveActionFinished());
        })
    }
}