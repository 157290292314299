//#region react
import React from "react";
import { useTranslation } from "core/hooks/useTranslation";

import { Row, Col, Input, Collapse } from "antd";
//#endregion

//#region components
import Icon from "components/common/icon";
//#endregion

//#region utils
import { isMobile, prettyJson, copyToClipboard } from "utils/common";
//#endregion

//#region constants
import METHODS from "constants/methods.constants";
//#endregion

//#region types
import requestType from "types/request/request.type";
//#endregion

const RequestDetailsComponent = ({ request }) => {
	const { t } = useTranslation();

	const requestJson =
		request.method?.toLowerCase() === METHODS.GET
			? request?.request?.queryStrings
				? JSON.stringify(request?.request?.queryStrings)
				: ""
			: request?.request?.body ?? "";
	const responseJson = request.response?.content ?? "";
	const responseStatus =
		request.response?.status ??
		`${request.response?.status} ${
			request.response?.errorMessage ? " - " + request.response?.errorMessage : ""
		}`;

	return (
		<div className={!isMobile() ? "rt--pl-34 rt--pr-34" : ""}>
			<Row gutter={[16, 16]}>
				<Col xs={24} xl={12}>
					<div className="rt--widget">
						<div className="rt--widget-header rt--pl-16 rt--pr-16 rt--flex rt--align-center rt--justify-between">
							<b className="rt--title rt--font-big rt--font-extra-bold">
								{t("backoffice.errorlogs.request")}
							</b>
						</div>
						<div
							className={
								"rt--widget-content rt--pb-16 rt--pt-16" +
								(isMobile() ? " rt--pl-16 rt--pr-16" : " rt--pl-24 rt--pr-24")
							}
						>
							<div className="rt--stacktrace-wrapper rt--mb-16">
								<div className="rt--stacktrace-title rt--mb-8">
									<span className="rt--title rt--font-bold rt--font-normal">
										{t("backoffice.errorlogs.url")}
									</span>
								</div>
								<div className="rt--stacktrace-content">
									<div
										className="rt--flex rt--align-center rt--cursor-pointer"
										onClick={() => copyToClipboard(request?.request?.url ?? "", t)}
									>
										<Icon name="copy" className="rt--stacktrace-copy" />
									</div>

									<Input.TextArea
										className="rt--stacktrace"
										autoSize={true}
										disabled={true}
										value={prettyJson(request?.request?.url ?? "")}
									/>
								</div>
							</div>

							<div className="rt--stacktrace-wrapper rt--mb-16">
								<div className="rt--stacktrace-title rt--mb-8">
									<span className="rt--title rt--font-bold rt--font-normal">
										{request.method?.toLowerCase() === METHODS.GET
											? t("backoffice.errorlogs.queryStrings")
											: t("backoffice.errorlogs.body")}
									</span>
								</div>
								<div className="rt--stacktrace-content">
									<div
										className="rt--flex rt--align-center rt--cursor-pointer"
										onClick={() => copyToClipboard(requestJson, t)}
									>
										<Icon name="copy" className="rt--stacktrace-copy" />
									</div>

									<Input.TextArea
										className="rt--stacktrace"
										autoSize={true}
										disabled={true}
										value={prettyJson(requestJson)}
									/>
								</div>
							</div>

							{request?.request?.headers && (
								<div className="rt--stacktrace-wrapper">
									<Collapse className="rt--collapse">
										{
											<Collapse.Panel
												header={
													<div className="rt--flex rt--align-center rt--justify-between rt--collapse-header">
														<Icon name="down" className="rt--mr-2" />
														<span className="rt--title rt--font-normal rt--font-bold rt--pl-4 rt--pr-4">
															{t("backoffice.errorlogs.headers")}
														</span>
													</div>
												}
												showArrow={false}
											>
												<div className="rt--stacktrace-content">
													{Object.keys(request?.request?.headers).map(
														(header) => (
															<div
																className="rt--stacktrace-description rt--flex rt--align-center rt--pt-8 rt--pb-8"
																key={header}
															>
																<span className="rt--title rt--font-normal rt--font-bold">
																	{header}:
																</span>
																<span className="rt--title rt--font-normal rt--font-regular rt--pl-8">
																	{
																		request?.request?.headers[
																			header
																		]
																	}
																</span>
															</div>
														),
													)}
												</div>
											</Collapse.Panel>
										}
									</Collapse>
								</div>
							)}
						</div>
					</div>
				</Col>
				<Col xs={24} xl={12}>
					<div className="rt--widget">
						<div className="rt--widget-header rt--pl-16 rt--pr-16 rt--flex rt--align-center rt--justify-between">
							<b className="rt--title rt--font-big rt--font-extra-bold">
								{t("backoffice.errorlogs.response")}
							</b>
						</div>
						<div
							className={
								"rt--widget-content rt--pb-16 rt--pt-16" +
								(isMobile() ? " rt--pl-16 rt--pr-16" : " rt--pl-24 rt--pr-24")
							}
						>
							{responseStatus && (
								<div className="rt--stacktrace-wrapper rt--mb-16">
									<div className="rt--stacktrace-title rt--mb-8">
										<span className="rt--title rt--font-bold rt--font-normal">
											{t("backoffice.errorlogs.status")}
										</span>
									</div>
									<div className="rt--stacktrace-content">
										<div
											className="rt--flex rt--align-center rt--cursor-pointer"
											onClick={() => copyToClipboard(responseStatus, t)}
										>
											<Icon name="copy" className="rt--stacktrace-copy" />
										</div>

										<Input.TextArea
											className="rt--stacktrace"
											autoSize={true}
											disabled={true}
											value={prettyJson(responseStatus)}
										/>
									</div>
								</div>
							)}

							{responseJson && (
								<div className="rt--stacktrace-wrapper rt--mb-16">
									<div className="rt--stacktrace-title rt--mb-8">
										<span className="rt--title rt--font-bold rt--font-normal">
											{t("backoffice.errorlogs.content")}
										</span>
									</div>
									<div className="rt--stacktrace-content">
										<div
											className="rt--flex rt--align-center rt--cursor-pointer"
											onClick={() => copyToClipboard(responseJson)}
										>
											<Icon name="copy" className="rt--stacktrace-copy" />
										</div>
										<Input.TextArea
											className="rt--stacktrace"
											autoSize={true}
											disabled={true}
											value={prettyJson(responseJson, t)}
										/>
									</div>
								</div>
							)}

							{request?.response?.headers && (
								<div className="rt--stacktrace-wrapper">
									<Collapse className="rt--collapse">
										{
											<Collapse.Panel
												header={
													<div className="rt--flex rt--align-center rt--justify-between rt--collapse-header">
														<Icon name="down" className="rt--mr-2" />
														<span className="rt--title rt--font-normal rt--font-bold rt--pl-4 rt--pr-4">
															{t("backoffice.errorlogs.headers")}
														</span>
													</div>
												}
												showArrow={false}
											>
												<div className="rt--stacktrace-content">
													{Object.keys(request?.response?.headers).map(
														(header) => (
															<div
																className="rt--stacktrace-description rt--flex rt--align-center rt--pt-8 rt--pb-8"
																key={header}
															>
																<span className="rt--title rt--font-normal rt--font-bold">
																	{header}:
																</span>
																<span className="rt--title rt--font-normal rt--font-regular rt--pl-8">
																	{
																		request?.response?.headers[
																			header
																		]
																	}
																</span>
															</div>
														),
													)}
												</div>
											</Collapse.Panel>
										}
									</Collapse>
								</div>
							)}
						</div>
					</div>
				</Col>
			</Row>
		</div>
	);
};

/** RequestDetailsComponent propTypes
 * PropTypes
 */
RequestDetailsComponent.propTypes = {
	/** Request details  */
	request: requestType,
};

export default RequestDetailsComponent;
