import React, { useMemo } from "react";

import { useTranslation } from "core/hooks/useTranslation";

import Responsive from "core/ui-kit/responsive";

import DesktopCurrenciesDropdown from "./desktop";
import MobileCurrenciesDropdown from "./mobile";

import { useAuthSelector, userSelector } from "core/stores/authStore";
import { currenciesSelector, currencySelector, useGlobalSelector } from "core/stores/globalStore";

import { getAvailableWallets, sortCurrenciesByWallet } from "./helpers";

const CurrenciesDropdown = () => {

    const { t } = useTranslation();

    const { role: userRole, allowImpactOnCPA } = useAuthSelector(userSelector);

    const currencies = useGlobalSelector(currenciesSelector);

    const selectedCurrency = useGlobalSelector(currencySelector);

    const availableWallets = getAvailableWallets(userRole, allowImpactOnCPA, t);

    const sortedCurrencies = useMemo(() => {
        const wallets = getAvailableWallets(userRole, allowImpactOnCPA, t)
        return sortCurrenciesByWallet(currencies, wallets, allowImpactOnCPA)
    }, [currencies, userRole, allowImpactOnCPA, t])

    return (
		<Responsive
			mobile={
				<MobileCurrenciesDropdown
                    wallets={availableWallets} 
                    currencies={sortedCurrencies}
                    allowImpactOnCPA={allowImpactOnCPA}
                    selectedCurrency={selectedCurrency}
                />
			}
			desktop={
				<DesktopCurrenciesDropdown 
                    wallets={availableWallets} 
                    currencies={sortedCurrencies}
                    allowImpactOnCPA={allowImpactOnCPA}
                    selectedCurrency={selectedCurrency}
                />
			}
		/>
	);
}

export default CurrenciesDropdown;