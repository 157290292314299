export const LOGO_TYPE = {
    LOGO: 1 << 0,
    COLLAPSED_LOGO: 1 << 1,
    FAVICON: 1 << 2,
}

export const LOGO_DEFAULTS = {
    [LOGO_TYPE.LOGO]: "system/static/images/logo.svg",
    [LOGO_TYPE.COLLAPSED_LOGO]: "system/static/images/collapse-logo.svg",
    [LOGO_TYPE.FAVICON]: "system/static/images/favicon.svg"
}

export const LOGO_WHITE = "system/static/images/logo-sign.svg";