import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";

import { Form, Row, Col, Spin } from 'antd';

import {
    getPromoSection,
    savePromoSection,
    getPromoSectionTranslations,
    savePromoSectionTranslations,
    resetPromoSectionTranslations,
} from 'store/actions/portal/promoCustomize/sections.action';

import Table from 'components/common/table';
import TableEmptyView from '../tableEmptyView';
import CustomizeImgUploader from '../../customizeImgUploader';

import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { TableDataBuilder } from 'utils/promo';

import {
    PROMO_FILE_TYPE,
    PROMO_SECTION_TYPE,
    SECTION_NAMES,
    PROMO_FILE_NAMES
} from 'constants/promo.constants';
import { getTableColumns } from './columns';

const FAQ = ({
    data,
    translations,
    isLoading,
    isSaving,
    getPromoSection,
    savePromoSection,
    getPromoSectionTranslations,
    savePromoSectionTranslations,
    resetPromoSectionTranslations,
    customizeLanguage,
    files,
}) => {
    const { t } = useTranslation();

    const tableData = (
        new TableDataBuilder(data)
            .mergeDataWithTranslations(translations, ["title", "description"])
            .build()
    )

    const handleTableSwitcherChange = (isChecked, record) => {
        const requestBody = {
            key: record.rowKey,
            enabled: isChecked
        }

        savePromoSection(PROMO_SECTION_TYPE.FAQ, requestBody);
    }

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            additionalProps: {
                savePromoSectionTranslations,
                resetPromoSectionTranslations
            },
            t
        });
    }, [savePromoSectionTranslations, resetPromoSectionTranslations, t])

    // Get FAQ Data
    useEffect(() => {
        getPromoSection(PROMO_SECTION_TYPE.FAQ);
    }, [customizeLanguage]);

    // Get FAQ Translations
    useEffect(() => {
        getPromoSectionTranslations(
            SECTION_NAMES.FAQ,
            PROMO_SECTION_TYPE.FAQ,
        );
    }, [customizeLanguage]);

    return (
        <Spin spinning={isLoading || isSaving}>
            <div>
                <Form
                    className="rt--form"
                    colon={false}
                    requiredMark={false}
                    layout="vertical"
                >
                    <Row gutter={[16, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label={t("backoffice.promo.background")}
                            >
                                <CustomizeImgUploader
                                    sectionName={SECTION_NAMES.FAQ}
                                    sectionType={PROMO_SECTION_TYPE.FAQ}
                                    fileType={PROMO_FILE_TYPE.BACKGROUND}
                                    languageCode={customizeLanguage}
                                    imgPath={files[PROMO_FILE_NAMES[PROMO_FILE_TYPE.BACKGROUND]]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className="rt--promo-customize-table-container-250">
                <Table
                    data={tableData}
                    columns={mainTableColumns}
                    renderEmpty={() => <TableEmptyView />}
                    noPagination={true}
                    switcher={{
                        valueIndex: "enabled",
                        dataIndex: "enabled",
                        className: "rt--mr-6",
                        onChange: handleTableSwitcherChange,
                    }}
                />
            </div>
        </Spin>
    );
}


/** FAQ propTypes
 * PropTypes
*/
FAQ.propTypes = {
    data: PropTypes.objectOf(PropTypes.bool),
    translations: PropTypes.objectOf(PropTypes.shape({
        isOverwritten: PropTypes.bool,
        key: PropTypes.string,
        translation: PropTypes.string,
    })),
    /** Redux state property, is true when loading portal customization data */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when promo saving */
    isSaving: PropTypes.bool,
    /** Redux action to get promo FAQ */
    getPromoSection: PropTypes.func,
    /** Redux action to save promo FAQ */
    savePromoSection: PropTypes.func,
    /** Redux action to get promo section translations */
    getPromoSectionTranslations: PropTypes.func,
    /** Redux action to save promo section translations */
    savePromoSectionTranslations: PropTypes.func,
    /** Redux action to reset promo section translations */
    resetPromoSectionTranslations: PropTypes.func,
    /** Redux state property, customize page language */
    customizeLanguage: PropTypes.string,
    /** Section Image URLs */
    files: PropTypes.objectOf(PropTypes.string)
};

const mapDispatchToProps = dispatch => ({
    getPromoSection: (sectionType) => {
        dispatch(getPromoSection(sectionType))
    },
    savePromoSection: (sectionType, requestBody) => {
        dispatch(savePromoSection(sectionType, requestBody))
    },
    getPromoSectionTranslations: (sectionName, sectionType) => {
        dispatch(getPromoSectionTranslations(sectionName, sectionType))
    },
    savePromoSectionTranslations: (sectionName, sectionType, key, text) => {
        dispatch(savePromoSectionTranslations(sectionName, sectionType, key, text))
    },
    resetPromoSectionTranslations: (sectionName, sectionType, key, text) => {
        dispatch(resetPromoSectionTranslations(sectionName, sectionType, key, text))
    },
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.promoCustomize.isLoading,
        isSaving: state.promoCustomize.isSaving,
        customizeLanguage: state.promoCustomize.customizeLanguage,
        data: state.promoCustomize.sections[PROMO_SECTION_TYPE.FAQ].data,
        translations: state.promoCustomize.sections[PROMO_SECTION_TYPE.FAQ].translations,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);