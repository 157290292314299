import React from "react";

import { useTranslation } from "core/hooks/useTranslation";

import CommissionStatus from "core/ui-kit/commissionStatus";
import Status from "core/ui-kit/status";

import classNames from "core/helpers/common/classNames";

import { COMMISSION_TYPE_TRANSLATIONS } from "core/constants/commission/type";
import { COMMISSION_POPUP_MODE } from "../constants";
import Radio from "core/ui-kit/radio";

const Navigation = ({
    items,
    selected,
    onClick,
    mode
}) => {

    const { t } = useTranslation();
    
    return (
        <div>
            <div className="rt--commissions-navigation rt--flex rt--pb-4">
                {
                    items.map(item => (
                        <div 
                            className={
                                classNames(
                                    "rt--commissions-navigation-item rt--pl-16 rt--pr-16 rt--pt-12 rt--pb-12 rt--mr-4",
                                    item.id === selected?.id && "rt--commissions-navigation-item-selected"
                                )
                            } 
                            key={item.id}
                            title={item.name}
                            onClick={() => onClick(item)}
                        >
                            <div className="rt--flex rt--align-center">
                                {
                                    mode === COMMISSION_POPUP_MODE.ADD && (
                                        <Radio 
                                            checked={item.id === selected?.id}
                                        />
                                    )
                                }
                                <div className="rt--commissions-navigation-item-inner">
                                    <div className="rt--commissions-navigation-item-title rt--mb-6">
                                        <b className="rt--font-bold rt--font-normal">{item.name}</b>
                                    </div>
                                    {
                                        mode === COMMISSION_POPUP_MODE.VIEW ? (
                                            <CommissionStatus status={item.status} />
                                        ) : (
                                            <div className="rt--flex rt--align-center">
                                                <span className="rt--commissions-navigation-item-currency rt--font-regular rt--font-normal rt--pr-12">{item.currencyCode}</span>
                                                <Status 
                                                    color="blue"
                                                    text={ t(COMMISSION_TYPE_TRANSLATIONS[item.type]) }
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            
                            
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Navigation;