import React, { Fragment, useState, useMemo, useEffect } from "react";
import PropTypes from 'prop-types';

import Modal from "core/ui-kit/modal";
import Icon from "core/ui-kit/icon";
import Input from "core/ui-kit/input";
import Checkbox from "core/ui-kit/checkbox";
import Selector from "./selector";
import NotFound from "../notFound";

import { useTranslation } from "core/hooks/useTranslation";

import isUndefined from "core/helpers/typeChecks/isUndefined";
import classNames from "core/helpers/common/classNames";
import { filterAndSortOptions, getNewSelectedValue, getSelectValue, isOptionDisabled, isOptionSelected } from "../helpers";

import { INPUT_TYPE } from "core/constants/component/input";

import { ALL_VALUE } from "../constants";

const SelectMobile = ({
    options,
    isMultiple,
    disabled,
    showSelectAllButton,
    value, 
    onChange,
    className,
    icon, 
    placeholder,
    maxMultipleSelectCount,
    search
}) => {

    const { t } = useTranslation(); 

    const [open, setOpen] = useState(false);

    const [searchValue, setSearchValue] = useState("");

    const filteredOptions = useMemo(() => {
        return filterAndSortOptions(options, searchValue)
    }, [options, searchValue])

    const isSelectAllOptionVisible = (
        isMultiple &&
        showSelectAllButton &&
        filteredOptions.length > 1 &&
        searchValue === "" &&
        isUndefined(maxMultipleSelectCount) 
    );

    const internalValue = getSelectValue({
        value, isMultiple, options, isSelectAllOptionVisible
    });

    const handleInternalChange = selectedValue => {
        let newValue = isMultiple ? [...internalValue] : value;

        if (isMultiple) {
            newValue = newValue.includes(selectedValue) ? newValue.filter(value => value !== selectedValue) : [...newValue, selectedValue]
        } else {
            if (newValue === selectedValue) {
                return;
            }

            newValue = selectedValue;
            setOpen(false);
        }

        const updatedValue = getNewSelectedValue({
            options,
            isSelectAllOptionVisible: isSelectAllOptionVisible,
            prevValue: internalValue,
            newValue: newValue
        })

        onChange(updatedValue);
        setSearchValue("");
    }

    const handleSearch = e => {
        setSearchValue(e.target.value)
    }

    const handleSelectorClick = () => {
        if (disabled) {
            return;
        }
        setOpen(true);
    }

    const handleTagClose = tagValue => {
        handleInternalChange(tagValue);
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    const handleOptionClick = option => {
        if(option.subs || isOptionDisabled(option, value, maxMultipleSelectCount)) return;

        handleInternalChange(option.value)
    }

    const getOptionClassName = option => {
        return classNames(
            "rt--select-dropdown-menu-item",
            option.subs ? "rt--select-dropdown-menu-item-subs" : "rt--flex rt--align-center rt--justify-between",
            !isMultiple && isOptionSelected(option, internalValue, isMultiple) && "rt--select-dropdown-menu-item-selected",
        )
    }

    // Prevent page scroll when drawer visible
    useEffect(() => {
        if (open) {
            document.documentElement.classList.add("rt--no-scroll");
        } else {
            document.documentElement.classList.remove("rt--no-scroll");
            setSearchValue("");
        }

        return () => {
            document.documentElement.classList.remove("rt--no-scroll");
        }

    }, [open]);

    return (
        <Fragment>
            <Selector
                className={className}
                onClick={handleSelectorClick}
                options={options}
                disabled={disabled}
                isMultiple={isMultiple}
                icon={icon}
                placeholder={placeholder}
                value={value}
                onTagClose={handleTagClose}
            />
            <Modal
                open={open}
                okButtonProps={{ hidden: true }}
                cancelButtonProps={{ hidden: true }}
                onCancel={handleDrawerClose}
                maskClosable={true}
                title={placeholder}
                className="rt--modal-select"
            >
                <div className="rt--select-dropdown rt--pt-8 rt--pb-8 rt--pl-16 rt--pr-16">
                    <div className="rt--select-dropdown-search">
                        {
                            search && (
                                <Input 
                                    type={INPUT_TYPE.SEARCH}
                                    placeholder={t("backoffice.common.search")}
                                    value={searchValue} 
                                    onChange={handleSearch} 
                                />
                            )
                        }
                    </div>

                    <div className='rt--select-dropdown-menu'>

                        {
                            isSelectAllOptionVisible && (
                                <div
                                    onClick={() => handleInternalChange(ALL_VALUE)}
                                    className="rt--select-dropdown-menu-item rt--select-all rt--flex rt--align-center"
                                >
                                    <Checkbox
                                        className="rt--select-checkbox"
                                        checked={value?.length === options.length}
                                    />

                                    <span className="rt--title rt--font-regular rt--font-normal">
                                        {value?.length === options.length ? t("backoffice.common.deselectAll") : t("backoffice.common.selectAll")}
                                    </span>
                                </div>
                            )
                        }

                        {
                            filteredOptions.length === 0 && (
                                <NotFound />
                            )
                        }

                        {
                            filteredOptions.map(option => (
                                <div
                                    key={option.value || option.text}
                                    onClick={() => handleOptionClick(option)}
                                    className={getOptionClassName(option)}
                                >
                                    {
                                        isMultiple && (
                                            <Checkbox
                                                className="rt--select-checkbox"
                                                checked={isOptionSelected(option, internalValue, isMultiple)}
                                            />
                                        )
                                    }

                                    <div
                                        className={classNames(
                                            "rt--flex rt--flex-equal rt--align-center rt--justify-between",
                                            option.subs && "rt--select-dropdown-menu-item-subs-title"
                                        )}
                                    >
                                        <span className="rt--title rt--font-regular rt--font-normal">
                                            {option.text}
                                        </span>
                                    </div>

                                    {
                                        (
                                            !isMultiple && isOptionSelected(option, internalValue, isMultiple) && !option.subs
                                        ) && (
                                            <Icon name='ok' />
                                        )
                                    }

                                    {
                                        option.subs && (
                                            <div className='rt--select-dropdown-menu-item-subs-list'>
                                                {
                                                    option.subs.map(sub => (
                                                        <div
                                                            key={sub.value || sub.text}
                                                            onClick={() => handleInternalChange(sub.value)}
                                                            className={classNames(
                                                                "rt--select-dropdown-menu-item-subs-list-item rt--flex rt--align-center rt--justify-between",
                                                                isOptionSelected(sub, internalValue, isMultiple) && (
                                                                    "rt--select-dropdown-menu-item-subs-list-item-selected"
                                                                )
                                                            )}
                                                        >
                                                            <span className='rt--title rt--font-regular rt--font-normal'>
                                                                {sub.text}
                                                            </span>

                                                            {
                                                                isOptionSelected(sub, internalValue, isMultiple) && (
                                                                    <Icon name='ok' />
                                                                )
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </Modal>
        </Fragment>
    )
} 

SelectMobile.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        subs: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        }))
    })),
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.bool]),
    disabled: PropTypes.bool,
    search: PropTypes.bool,
    isMultiple: PropTypes.bool,
    showSelectAllButton: PropTypes.bool,
    icon: PropTypes.string,
    maxMultipleSelectCount: PropTypes.number,
    placeholder: PropTypes.string,
    className: PropTypes.string
}

export default SelectMobile;
