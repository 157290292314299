import React from 'react';

import Status from 'components/common/status';
import Icon from 'components/common/icon';

import { makeImagePath, makeUrl } from 'utils/common';

import { CREATIVE_TYPE, MEDIA_TYPE } from 'constants/campaign.constants';
import { STATUS_TYPES } from 'constants/status.constants';

const getImageSource = (mediaType, creativeType, filePath) => {
    if (mediaType === MEDIA_TYPE.LINK) {
        return `${import.meta.env.SYSTEM_CDN_URL}/system/static/images/link.png`;
    }

    if (creativeType === CREATIVE_TYPE.HTML) {
        return `${import.meta.env.SYSTEM_CDN_URL}/system/static/images/html.png`;
    }

    return makeImagePath(filePath)
}

export const getTableColumns = (additionalProps) => {
    const { 
        onThumbnailClick, 
        isAffiliate,
        trafficSources,
        formatNumber,
        t 
    } = additionalProps;

    const mapping = trafficSources.reduce((res, current) => { 
        res[current.longId] = current.name; 
        return res; 
    }, {});

    return [
        {
            dataIndex: "creativeFilePath",
            image: true,
            width: "132px",
            alwaysVisible: true,
            mobileLevel: 1,
            render: (filePath, record) => {
                const imgSource = getImageSource(
                    record.type,
                    record.creativeType,
                    filePath
                )

                return (
                    <div
                        className="rt--table-col-img"
                        onClick={() => onThumbnailClick(record)}
                    >
                        <img src={imgSource} alt="Creative" />
                    </div>
                )
            },
        },
        {
            title: "backoffice.campaigns.mediaToolId",
            dataIndex: "longId",
            alwaysVisible: true,
            copy: true,
        },
        {
            title: "backoffice.campaigns.mediaToolName",
            dataIndex: "name",
            sorter: true,
            tooltip: false,
            alwaysVisible: true,
            mobileLevel: 2,
            render: (mediaToolName, record) => {
                if (record.url) {
                    return (
                        <a
                            className='rt--media-campaign-url-link'
                            href={makeUrl("https:/", null, record.url)} target='_blank' rel="noreferrer"
                        >
                            {mediaToolName}
                        </a>
                    )
                }

                return mediaToolName;
            }
        },
        !isAffiliate && {
            title: "backoffice.campaigns.affiliateUsername",
            dataIndex: "affiliateUserName",
            mobileLevel: 4,
        },
        !isAffiliate && {
            title: "backoffice.campaigns.affiliateId",
            dataIndex: "affiliateLongId",
            copy: true,
        },
        {
            title: "backoffice.campaigns.mediaType",
            dataIndex: "type",
            sorter: true,
            tooltip: false,
            mobileLevel: 3,
            render: (mediaType) => {
                return (
                    <div className='rt--flex rt--align-center'>
                        <div className={'rt--media-type ' + (mediaType === MEDIA_TYPE.LINK ? "rt--media-type-link" : "rt--media-type-creative")}>
                            {
                                mediaType === MEDIA_TYPE.LINK
                                    ? <Icon name='direct-link' size={18} />
                                    : <Icon name='picture' size={18} />
                            }
                        </div>
                        <span>
                            {
                                mediaType === MEDIA_TYPE.LINK ? t("backoffice.campaigns.directLink") : t("backoffice.campaigns.creative")
                            }
                        </span>
                    </div>

                )
            }
        },
        {
            title: "backoffice.campaigns.brandName",
            dataIndex: "brandName",
            mobileLevel: isAffiliate ? 4 : undefined
        },
        {
            title: "backoffice.campaigns.campaignName",
            dataIndex: "campaignName",
        },
        {
            title: "backoffice.common.status",
            dataIndex: "status",
            mobileLevel: 5,
            tooltip: false,
            render: value => (
                <Status
                    type={STATUS_TYPES.MEDIA}
                    status={value}
                />
            )
        },
        {
            title: "backoffice.campaigns.viewCount",
            dataIndex: "viewCount",
            render: (value, record) => record.type === MEDIA_TYPE.LINK ? "-": formatNumber(value)
        },
        {
            title: "backoffice.campaigns.clickCount",
            dataIndex: "clickCount",
            isNumeric: true
        },
        isAffiliate && {
            title: "backoffice.campaigns.trafficSource",
            dataIndex: "trafficSources",
            multi: true,
            multiMapper: sources => sources ? sources.map(longId => mapping[longId] || "") : []
        },
        {
            title: "backoffice.campaigns.createdAt",
            dataIndex: "createdAt",
            mobileLevel: 6,
            isDateTime: true,
            sorter: true,
        },
        {
            title: "backoffice.campaigns.createdBy",
            dataIndex: "createdBy",
            sorter: true,
        },
        {
            title: "backoffice.campaigns.modifiedAt",
            dataIndex: "modifiedAt",
            isDateTime: true,
        },
        {
            title: "backoffice.campaigns.modifiedBy",
            dataIndex: "modifiedBy",
        }
    ]
}