const processDefaultSelectedKeys = (items, path) => {
	return items.reduce((result, item) => {
		if (item.match && item.match.some(p => path.includes(p))) {
			result.push(item.key);
		}

		if (item.items) {
			result = [...result, ...processDefaultSelectedKeys(item.items, path)];
		}

		return result;
	}, []);
};

export default processDefaultSelectedKeys;