import { LINE_CHART_AVAILABLE_COLOR } from "constants/lineChart.constants"
import { WALLET_TYPE } from "constants/wallet.constants"

export const OPERATIONAL_TAB_NAMES = {
    GENERAL: "general",
    PLAYER: "player",
    TOP_AFFILIATES: "topAffiliates"
}

export const OPERATIONAL_ENTITY_TYPE = {
    AFFILIATE: 1,
    PLAYER: 2,
    CAMPAIGN: 4,
    MEDIA: 8,
    DEPOSIT_COUNT: 16,
    SUB_AFFILIATE: 32
}

export const PIE_CHART_STATES = {
    [OPERATIONAL_ENTITY_TYPE.AFFILIATE] : {
        ACTIVE: "active",
        IN_PROGRESS: "inProgress",
        BLOCKED: "blocked",
        DECLINED: "declined",
    },
    [OPERATIONAL_ENTITY_TYPE.CAMPAIGN] : {
        ACTIVE: "active",
        INACTIVE: "inactive",
    },
    [OPERATIONAL_ENTITY_TYPE.MEDIA] : {
        BANNER_CODE: "bannerCode",
        DIRECT_LINK: "directLink",
    },
}

export const TIME_PERIOD = {
    TODAY: 1,
    YESTERDAY: 2,
    LAST_MONTH: 16,
    THIS_MONTH: 32,
    LAST_90_DAYS: 64,
    THIS_YEAR: 256,
    CUSTOM: 512
}

export const TIME_PERIOD_TRANSLATION_SOURCE = {
    [TIME_PERIOD.TODAY]: "backoffice.dashboard.today",
    [TIME_PERIOD.YESTERDAY]: "backoffice.dashboard.yesterday",
    [TIME_PERIOD.THIS_MONTH]: "backoffice.dashboard.thisMonth",
    [TIME_PERIOD.LAST_MONTH]: "backoffice.dashboard.lastMonth",
    [TIME_PERIOD.LAST_90_DAYS]: "backoffice.dashboard.last90Days",
    [TIME_PERIOD.THIS_YEAR]: "backoffice.dashboard.ytd"
}

export const LINE_CHART_ENTITY_TYPE = {
    REGISTRATIONS_COUNT: "registrationsCount",
    CPA_COUNT: "cpaCount",
    FTD_COUNT: "ftdCount",
    GGR: "ggr",
    NGR: "ngr",
    TURNOVER: "turnover",
    DEPOSIT_AMONT: "deposit",
    FTD_AMOUNT: "ftdAmount",
    NET_DEPOSIT_AMOUNT: "netDeposit",
    EARNING: "totalCommissionsAmount",
    CPA: "cpaAmount",
    VIEWS_COUNT: "viewsCount",
    INVALID_CLICKS_COUNT: "invalidClicksCount",
    VALID_CLICKS_COUNT: "validClicksCount"
}

export const LINE_CHART_CONFIGS_BY_ENTITY = {
    [LINE_CHART_ENTITY_TYPE.REGISTRATIONS_COUNT]: {
        mainLineColor: LINE_CHART_AVAILABLE_COLOR.YELLOW
    },
    [LINE_CHART_ENTITY_TYPE.CPA_COUNT]: {
        mainLineColor: LINE_CHART_AVAILABLE_COLOR.GREEN
    },
    [LINE_CHART_ENTITY_TYPE.FTD_COUNT]: {
        mainLineColor: LINE_CHART_AVAILABLE_COLOR.MAGENTA
    },
    [LINE_CHART_ENTITY_TYPE.GGR]: {
        mainLineColor: LINE_CHART_AVAILABLE_COLOR.GREEN
    },
    [LINE_CHART_ENTITY_TYPE.NGR]: {
        mainLineColor: LINE_CHART_AVAILABLE_COLOR.ORANGE
    },
    [LINE_CHART_ENTITY_TYPE.TURNOVER]: {
        mainLineColor: LINE_CHART_AVAILABLE_COLOR.LIGHT_GREEN
    },
    [LINE_CHART_ENTITY_TYPE.DEPOSIT_AMONT]: {
        mainLineColor: LINE_CHART_AVAILABLE_COLOR.BLUE
    },
    [LINE_CHART_ENTITY_TYPE.FTD_AMOUNT]: {
        mainLineColor: LINE_CHART_AVAILABLE_COLOR.MAGENTA
    },
    [LINE_CHART_ENTITY_TYPE.NET_DEPOSIT_AMOUNT]: {
        mainLineColor: LINE_CHART_AVAILABLE_COLOR.ORANGE
    },
    [LINE_CHART_ENTITY_TYPE.EARNING]: {
        mainLineColor: LINE_CHART_AVAILABLE_COLOR.BLUE
    },
    [LINE_CHART_ENTITY_TYPE.CPA]: {
        mainLineColor: LINE_CHART_AVAILABLE_COLOR.LIGHT_GREEN
    },
    [LINE_CHART_ENTITY_TYPE.VIEWS_COUNT]: {
        mainLineColor: LINE_CHART_AVAILABLE_COLOR.BLUE
    },
    [LINE_CHART_ENTITY_TYPE.VALID_CLICKS_COUNT]: {
        mainLineColor: LINE_CHART_AVAILABLE_COLOR.GREEN
    },
    [LINE_CHART_ENTITY_TYPE.INVALID_CLICKS_COUNT]: {
        mainLineColor: LINE_CHART_AVAILABLE_COLOR.MAGENTA
    },
}

export const FINANCIAL_TAB_NAMES = {
    GENERAL: "general",
    EARNING: "earning",
    TOP_AFFILIATES: "topAffiliates",
    TOP_PLAYERS: "topPlayers"
}

export const LIVE_BALANCES_TEXT_MAPPING = {
    [WALLET_TYPE.CPA_BALANCE]: {
        title: "backoffice.wallet.cpaBalance",
        description: "backoffice.wallet.cpaBalanceDescription"
    },
    [WALLET_TYPE.CPA_NOT_APPROVED_BALANCE]: {
        title: "backoffice.wallet.cpaNotApprovedBalance",
        description: "backoffice.wallet.cpaNotApprovedBalanceDescription"
    },
    [WALLET_TYPE.EARNING_BALANCE]: {
        title: "backoffice.wallet.earningBalance",
        description: "backoffice.wallet.earningBalanceDescription"
    },
    [WALLET_TYPE.EARNING_NOT_APPROVED_BALANCE]: {
        title: "backoffice.wallet.earningNotApprovedBalance",
        description: "backoffice.wallet.earningNotApprovedBalanceDescription"
    },
    [WALLET_TYPE.FROZEN_BALANCE]: {
        title: "backoffice.wallet.frozenBalance",
        description: "backoffice.wallet.frozenBalanceDescription"
    },
    [WALLET_TYPE.PAIDOUT_BALANCE]: {
        title: "backoffice.wallet.paidoutBalance",
        description: "backoffice.wallet.paidoutBalanceDescription"
    }
}

export const FINANCIAL_ENTITY_TYPE = {
    GGR: 1,
    NGR: 2,
    TURNOVER: 4,
    DEPOSIT_AMOUNT: 8,
    WITHDRAW_AMOUNT: 16,
    NET_DEPOSIT: 32,
    FTD_AMOUNT: 64,
    EARNING_COMMISSIONS_AMOUNT: 128,
    CPA_COMMISSIONS_AMOUNT: 256
}

export const FINANCIAL_LEADERBOARD_TYPE = {
    AFFILIATE: 1,
    PLAYER: 2
}

export const DASHBOARD_TYPE = {
    OPERATIONAL: 1,
    FINANCIAL: 2
}

export const MARKETING_TAB_NAMES = {
    GENERAL: "general",
    TOP_COUNTRY: "topCountry",
    TOP_CAMPAIGN: "topCampaign"
}

export const MARKETING_ENTITY_TYPE = {
    VIEW: 1,
    VALID_CLICK: 2,
    INVALID_CLICK: 4,
    REGISTRATIONS: 8
}

export const COUNT_CHART_TYPE = {
    VIEW: "viewsCount",
    VALID_CLICK: "validClicksCount",
    INVALID_CLICK: "invalidClicksCount",
    CR_TO_REG: "registrationsCount"
}