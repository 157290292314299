import React from "react";

import { LOGO_TYPE } from "core/constants/common/logo";

import useLogo from "core/hooks/useLogo";

const Header = () => {
    /** Logos */
    const { logos } = useLogo()

    return (
        <div className='rt--customize-layout-header'>
            <div className='rt--customize-layout-header-logo'>
                <img alt="logo" src={`${import.meta.env.SYSTEM_CDN_URL}/${logos[LOGO_TYPE.LOGO]}`} />
            </div>
        </div>
    )
}

export default Header;