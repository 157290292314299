import React, { useEffect , useState, useRef, memo } from "react"
import PropTypes from 'prop-types';

import useDate from "core/hooks/useDate";

const Clock = ({
    isCollapsed
}) => {

    const { dateService } = useDate();

    const timer = useRef(null);

    const [date, setDate] = useState(new Date());

    /** Run the clock */
    useEffect(() => {
        if (isCollapsed) {
            clearTimeout(timer.current);
        } else {
            setDate(new Date());
            runClock();
        }

        return () => {
            clearTimeout(timer.current);
        }
    }, [isCollapsed]);

    const getUntilNext = () => {
        const currentTime = dateService.getNow();
        let nextTime = dateService.getNow();
        nextTime = dateService.setUnit(nextTime, "seconds", 0);
        nextTime = dateService.setUnit(nextTime, "milliseconds", 0);
        nextTime = dateService.addUnit(nextTime, 1, "minutes", 1);
        return dateService.getDifference(nextTime, currentTime, "milliseconds");
    }

    const runClock = () => {
        const untilNext = getUntilNext();
        timer.current = setTimeout(() => {
            setDate(new Date());
            runClock();
        }, untilNext);
    }

    return (
        <div className='rt--flex'>
            <span className='rt--sidebar-content-footer-date rt--font-small rt--font-bold'>
				{
					`${dateService.format(date)} (UTC${dateService.toString(dateService.getNow(), "Z")})`
				}
			</span>
        </div>
    )
}

Clock.propTypes = {
    isCollapsed: PropTypes.bool
}

export default memo(Clock)