import ROUTES from "core/constants/routes/routes";

import AffiliateAccessComponent from "pages/affiliates/access/access.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const AffiliateAccessPageRoute = {
	path: ROUTES.AFFILIATE_ACCESS,
	element: AffiliateAccessComponent, 
    permission: { resource: PERMISSION_RESOURCE.PROJECT_AFFILIATE_ACCESS_SETTINGS, action: PERMISSION_ACTION.VIEW }
};

export default AffiliateAccessPageRoute;
