import { AFFILIATE_ACCESS_TYPE } from 'constants/affiliate.constants';

export const getTableColumns = additionalProps => {
    const {
        formatCurrencyWithSymbol,
        currencyCode,
        hasAccess,
        t 
    } = additionalProps; 

    return [
        hasAccess(AFFILIATE_ACCESS_TYPE.DEPOSIT_COUNT) && {
            title: "backoffice.reports.depositCount",
            dataIndex: "depositCount",
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.DEPOSIT_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.depositAmount")),
            dataIndex: "depositAmount",
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.NET_DEPOSIT_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.netDeposit")),
            dataIndex: "netDepositAmount",
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.FTD_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.ftdAmount")),
            dataIndex: "ftdAmount",
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BET_COUNT) && {
            title: "backoffice.reports.sportBetCount",
            dataIndex: "sportBetCount",
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BET_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.sportBet")),
            dataIndex: "sportBetAmount",
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.WON_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.sportWonAmount")),
            dataIndex: "sportWonAmount",
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.GGR) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.sportGGR")),
            dataIndex: "sportGGR",
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BONUS_WON_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.sportBonusWonAmount")),
            dataIndex: "sportBonusWonAmount",
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.ADMIN_FEE) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.sportAdminFee")),
            dataIndex: "sportFeeAmount",
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.NGR) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.sportNGR")),
            dataIndex: "sportNGR",
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BET_COUNT) && {
            title: "backoffice.reports.casinoBetCount",
            dataIndex: "casinoBetCount",
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BET_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.casinoBet")),
            dataIndex: "casinoBetAmount",
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.WON_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.casinoWonAmount")),
            dataIndex: "casinoWonAmount",
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.GGR) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.casinoGGR")),
            dataIndex: "casinoGGR",
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.BONUS_WON_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.casinoBonusWonAmount")),
            dataIndex: "casinoBonusWonAmount",
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.ADMIN_FEE) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.casinoAdminFee")),
            dataIndex: "casinoFeeAmount",
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.NGR) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.casinoNGR")),
            dataIndex: "casinoNGR",
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.NGR) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.ngr")),
            dataIndex: "ngr",
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.ADMIN_FEE) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.depositFee")),
            dataIndex: "depositFeeAmount",
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.ADJUSTMENTS) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.adjustments")),
            dataIndex: "adjustmentsAmount",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.sportRevShare")),
            dataIndex: "sportRevShareAmount",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.casinoRevShare")),
            dataIndex: "casinoRevShareAmount",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.totalRevShare")),
            dataIndex: "totalRevShareAmount",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.cpaAmount")),
            dataIndex: "cpaAmount",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.totalEarning")),
            dataIndex: "totalCommissionAmount",
        }
    ]
} 
