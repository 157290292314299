import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "core/hooks/useTranslation";

import { Button } from 'antd';
import { createModule, useLightboxState } from "yet-another-react-lightbox";

const ExtraFooter = (props) => {
    const { extraFooterProps } = props;

    const { t } = useTranslation();

    const { currentSlide } = useLightboxState();

    return (
        <div className='rt--lightbox-extraFooter'>
            <Button
                type="secondary"
                htmlType="submit"
                className="rt--button rt--lightbox-extraFooter-button-cancel"
                onClick={extraFooterProps.onCancel}
            >
                <span>{t('backoffice.common.cancel')}</span>
            </Button>
            <Button
                type="primary"
                htmlType="submit"
                className="rt--button rt--button-primary"
                onClick={() => extraFooterProps.onOk(currentSlide)}
            >
                <span>{t('backoffice.common.choose')}</span>
            </Button>
        </div>
    );
}

ExtraFooter.propTypes = {
    extraFooterProps: PropTypes.object,
}

export const ExtraFooterModule = createModule("ExtraFooter", ExtraFooter);