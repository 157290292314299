import { globalCompanyIdSelector, globalProjectIdSelector, useAuthSelector } from 'core/stores/authStore';
import { useGlobalSelector, userInfoSelector } from 'core/stores/globalStore';

const useCurrentProject = () => {

    const globalProjectId = useAuthSelector(globalProjectIdSelector);
    const globalCompanyId = useAuthSelector(globalCompanyIdSelector);
    const { companies } = useGlobalSelector(userInfoSelector);

    const company = companies?.find(c => c.id === globalCompanyId);
    const project = (company?.projects ?? []).find(p => p.id === globalProjectId);
    return project;
}

export default useCurrentProject;