import React from 'react';

export const getTableColumns = additionalProps => {
    const { 
        isAllProjectsSelected,
        t 
    } = additionalProps;

    return [
        {
            title: "backoffice.users.projectName",
            dataIndex: "name",
            showOnlyValue: true,
            mobileLevel: 1,
            render: value => value === "*" ? t('backoffice.common.all') : value,
            mobileRender: (value, record) => (
                <span className="rt--font-normal rt--font-extra-bold">
                    {record.id === "*" ? t("backoffice.common.all") : value}
                </span>
            )
        },
        !isAllProjectsSelected && {
            title: "backoffice.users.projectId",
            dataIndex: "id",
            showOnlyValue: true,
            mobileLevel: 2,
            render: value => value === "*" ? "" : value,
            mobileRender: value => `${t("backoffice.users.id")}: ${value}`,
        }
    ];
}