import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "core/hooks/useTranslation";

import { useAuthSelector, userSelector } from "core/stores/authStore";

import Icon from "core/ui-kit/icon";
import Dropdown from "core/ui-kit/dropdown";
import Input from "core/ui-kit/input";

import { USER_ROLE } from "core/constants/auth";

import { INPUT_TYPE } from "core/constants/component/input";

const CompanyProjectDropdownDesktop = ({
    companiesMenuItems,
	companySearch,
	handleCompanyDropdownOpenChange,
	handleCompanyOrProjectChange,
    companyPopupOpened,
    projectPopupOpened,
	projectsMenuItems,
	companySearchRef,
	selectedCompany,
	handleCompanySearchChange,
	handleProjectDropdownOpenChange,
	selectedProject,
	handleProjectSearchChange,
	projectSearch,
	projectSearchRef,
    disabled=false
}) => {
    const { t } = useTranslation();

    const { role: userRole} = useAuthSelector(userSelector);

    const showProjectsDropdown = userRole === USER_ROLE.ADMIN;

    return (
        <Fragment>
            <span className='rt--companies-dropdown-title rt--title rt--font-normal rt--font-regular rt--pr-8'>{t("backoffice.companies.company")}:</span>
            <div className='rt--companies-dropdown-group rt--flex rt--align-center'>
                <Dropdown
                    forceRender
                    menu={{
						items: companiesMenuItems,
						onClick: handleCompanyOrProjectChange("company"),
                        className: "rt--companies-dropdown-menu",
                        selectedKeys: [selectedCompany?.id]
					}}
                    footer={{
						onOk: handleCompanyOrProjectChange("company"),
					}}
                    onOpenChange={handleCompanyDropdownOpenChange}
                    popupOpened={companyPopupOpened}
                    dropdownRender={ menu => (
						<>
							<div className="rt--companies-dropdown-search rt--pl-8 rt--pr-8 rt--pt-8 rt--pb-8">
                                <Input
                                    type={INPUT_TYPE.SEARCH}
                                    placeholder={t("backoffice.common.search")}
                                    onChange={handleCompanySearchChange}
                                    value={companySearch}
                                    ref={companySearchRef}
                                />
							</div>
							{menu}
						</>
					)}
                    title={t("backoffice.companies.company")} 
                    disabled={disabled}
                >
                    <div className="rt--companies-dropdown-content rt--flex rt--align-center rt--justify-between rt--pl-12 rt--pr-4">
						<span className="rt--title rt--font-normal rt--font-bold rt--pr-4">
							{selectedCompany?.name ?? ""}
						</span>
						<Icon name="down-small" className="rt--icon-rotate" />
					</div>
				</Dropdown>

                {
                    showProjectsDropdown && (
                        <Dropdown
                            footer={{
                                onOk: handleCompanyOrProjectChange("project"),
                            }}
                            menu={{
                                items: projectsMenuItems,
                                onClick: handleCompanyOrProjectChange("project"),
                                className: "rt--companies-dropdown-menu",
                                selectedKeys: [selectedProject?.id]
                            }}
                            popupOpened={projectPopupOpened}
                            onOpenChange={handleProjectDropdownOpenChange}
                            dropdownRender={ menu => (
                                <>
                                    <div className="rt--companies-dropdown-search rt--pl-8 rt--pr-8 rt--pt-8 rt--pb-8">
                                        <Input
                                            type={INPUT_TYPE.SEARCH}
                                            placeholder={t("backoffice.common.search")}
                                            onChange={handleProjectSearchChange}
                                            value={projectSearch}
                                            ref={projectSearchRef}
                                        />
                                    </div>
                                    {menu}
                                </>
                            )}
                            disabled={disabled}
                        >
                            <div className="rt--companies-dropdown-content rt--companies-dropdown-content-last rt--flex rt--align-center rt--justify-between rt--pl-12 rt--pr-4 rt--ml-1">
                                <span className="rt--title rt--font-normal rt--font-bold rt--pr-4">
                                    {selectedProject?.name || (
                                        <span className="rt--companies-dropdown-content-placeholder rt--title rt--font-normal rt--font-regular">{`-- ${t("backoffice.common.choose")} ${t("backoffice.common.project")} --`}</span>
                                    )}
                                </span>
                                <Icon name="down-small" className="rt--icon-rotate" />
                            </div>
                        </Dropdown>
                    )
                }
            </div>
        </Fragment>
    )
}


CompanyProjectDropdownDesktop.propTypes = {
	selectedCompany: PropTypes.object,
	selectedProject: PropTypes.object,
	handleCompanyDropdownOpenChange: PropTypes.func,
	handleCompanySearchChange: PropTypes.func,
	handleProjectDropdownOpenChange: PropTypes.func,
	handleProjectSearchChange: PropTypes.func,
	handleCompanyOrProjectChange: PropTypes.func,
    companyPopupOpened: PropTypes.bool,
    projectPopupOpened: PropTypes.bool,
	companiesMenuItems: PropTypes.array,
	projectsMenuItems: PropTypes.array,
	companySearch: PropTypes.string,
	projectSearch: PropTypes.string,
	companySearchRef: PropTypes.object,
	projectSearchRef: PropTypes.object,
    disabled: PropTypes.bool
};

export default CompanyProjectDropdownDesktop;