import ROUTES from "core/constants/routes/routes";

import ProfileComponent from "pages/profile/profile.component";

const ProfilePageRoute = {
	path: ROUTES.PROFILE,
	element: ProfileComponent
};

export default ProfilePageRoute;
