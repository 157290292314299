import React, { useEffect } from 'react';

import { useTranslation } from "core/hooks/useTranslation";
import { useParams } from "react-router-dom";

import Tabs from "components/common/tabs";

import GeneralInfoComponent from '../edit/sections/general';
import SecuritySettingsComponent from '../edit/sections/securitySettings';

import ROUTES from "core/constants/routes/routes";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "core/constants/permission";

import { authStore, globalCompanyIdSelector, useAuthSelector } from 'core/stores/authStore';
import { companiesSelector, useGlobalSelector } from 'core/stores/globalStore';

/** Company Edit Page Component */
const CompanyEditComponent = () => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const globalCompanyId = useAuthSelector(globalCompanyIdSelector);
    const companies = useGlobalSelector(companiesSelector);

    /** Set current editing company id as global company id */
    useEffect(() => {
        if (globalCompanyId !== searchParams.id) {
            setTimeout(() => {
                let projectId = undefined;
                const c = companies.find(c => c.id === searchParams.id);
                if (c && c.projects && c.projects[0]) {
                    projectId = c.projects[0].id;
                }
                authStore.changeProject({
                    companyId: searchParams.id,
                    projectId
                });
            }, 0)
        }
    }, [])

    const items = [
        {
            title: t("backoffice.companies.general"),
            permissions: [{ resource: PERMISSION_RESOURCE.COMPANY_GENERALINFO, action: PERMISSION_ACTION.VIEW }],
            component: <GeneralInfoComponent />
        },
        {
            title:t("backoffice.companies.securitySettings"),
            permissions: [{ resource: PERMISSION_RESOURCE.COMPANY_SECURITY_SETTINGS, action: PERMISSION_ACTION.VIEW }],
            component: <SecuritySettingsComponent />
        }
    ]

    return (
        <Tabs 
            items={items}
            mainPage={{ title: t('backoffice.menu.companies'), path: ROUTES.COMPANIES }}
        />
    )

}


export default CompanyEditComponent;