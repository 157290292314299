import browserSessionStorage from "core/services/storageService/sessionStorage";

const globalInitialState = {
	languages: browserSessionStorage.get("languages") ?? {},
    isLoading: false,
    isMainInfoLoading: true,
    currencies: [],
    currency: browserSessionStorage.get("currency") ?? null,
    userInfo: null,
    companies: []
};

export default globalInitialState;
