
import React, { Fragment, memo } from "react"
import PropTypes from 'prop-types';

import { LOGO_TYPE } from "core/constants/common/logo";

import useLogo from "core/hooks/useLogo";

const SidebarLogo = ({
    isCollapsed
}) => {

    /** Logos */
    const { logos } = useLogo()

    return (
        <Fragment>
            <div className='rt--sidebar-content-logo rt--flex rt--align-center rt--pl-16 rt--pr-16'>
                {
                    <img alt="logo" src={`${import.meta.env.SYSTEM_CDN_URL}/${logos[LOGO_TYPE.LOGO]}`} />
                }
            </div>
            <div className='rt--sidebar-content-logo-collapse'>
                {
                    isCollapsed && (
                        <img alt='logo' src={`${import.meta.env.SYSTEM_CDN_URL}/${logos[LOGO_TYPE.COLLAPSED_LOGO]}`} />
                    )
                }
            </div>
        </Fragment>
    )
}

SidebarLogo.propTypes = {
    isCollapsed: PropTypes.bool
}

export default memo(SidebarLogo)