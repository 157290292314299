import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'antd';

import TabTableDashboardLayout from 'components/layouts/tab/table';
import LiveBalances from '../../../components/liveBalances';
import FinancialLineChart from '../../../components/financialLineChart';

import Filters from "../../../components/filters";

import { AFFILIATE_ACCESS_TYPE } from 'constants/affiliate.constants';
import { TIME_PERIOD } from '../../../constants';

import useFirstRender from 'hooks/useFirstRender';
import useAccess from 'hooks/useAccess';

import { isMobile } from 'utils/common';

import { globalProjectIdSelector, useAuthSelector, userSelector } from 'core/stores/authStore';
import { currenciesSelector, currencySelector, useGlobalSelector } from 'core/stores/globalStore';

const getDefaultFilters = () => (
    {
        period: {
            period: TIME_PERIOD.TODAY,
            from: null,
            to: null
        }
    }
)

const General = ({
    updateLastUpdatedDate
}) => {

    const [ filters, setFilters ] = useState(getDefaultFilters());

    const { hasAccess } = useAccess();

    const updateFilters = useCallback((newFilters) => {
        setFilters(prevState => {
            return {
                ...prevState,
                ...newFilters
            }
        })
    }, [])

    const globalProjectId = useAuthSelector(globalProjectIdSelector);
    const { allowImpactOnCPA } = useAuthSelector(userSelector);

    const currencyCode = useGlobalSelector(currencySelector);
    const currencies = useGlobalSelector(currenciesSelector);

    useFirstRender({
        afterFirstRenderCB: () => {
            updateFilters(getDefaultFilters())
        },
        dependencies: [globalProjectId, updateFilters]
    })

    const hasFinancialLineChartAccess = [
        AFFILIATE_ACCESS_TYPE.GGR, AFFILIATE_ACCESS_TYPE.NGR, AFFILIATE_ACCESS_TYPE.BET_AMOUNT, AFFILIATE_ACCESS_TYPE.DEPOSIT_AMOUNT, AFFILIATE_ACCESS_TYPE.FTD_AMOUNT, AFFILIATE_ACCESS_TYPE.NET_DEPOSIT_AMOUNT
    ].some(access => hasAccess(access))


    return (
        <TabTableDashboardLayout
            header={{
                filters: isMobile() ? 
                <Filters filters={filters} updateFilters={updateFilters} />
                 : undefined
            }}
        >
            <LiveBalances 
                globalProjectId={globalProjectId}
                currencyCode={currencyCode}
                allowImpactOnCPA={allowImpactOnCPA}
            />

            {
                hasFinancialLineChartAccess && (
                    <div className='rt--mt-24'>
                        <Row gutter={[16, 24]}>
                            <Col span={24}>
                                <FinancialLineChart
                                    updateLastUpdatedDate={updateLastUpdatedDate}
                                    filters={filters}
                                    updateFilters={updateFilters}
                                    currencyCode={currencyCode}
                                    currencies={currencies}
                                />
                            </Col>
                        </Row>
                    </div>
                )
            }
            
            

        </TabTableDashboardLayout>
    )
}

General.propTypes = {
    updateLastUpdatedDate: PropTypes.func,
}

export default General;