import ROUTES from "core/constants/routes/routes";

import AffiliateGroupEditComponent from "pages/affiliates/groups/edit/group-edit.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const AffiliateGroupEditPageRoute = {
	path: `${ROUTES.AFFILIATE_GROUPS_EDIT}/:id`,
	element: AffiliateGroupEditComponent, 
    permission: { resource: PERMISSION_RESOURCE.AFFILIATE_GROUPS, action: PERMISSION_ACTION.VIEW }
};

export default AffiliateGroupEditPageRoute;
