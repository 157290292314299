import React, { useEffect, useMemo } from "react";
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useNavigate, useParams } from "react-router-dom";

import Customize from "components/common/customize";

import { getNewsletterConfiguration } from "store/actions/portal/newsletterCustomize/configuration.action";
import { toggleNewsletterSection } from "store/actions/portal/newsletterCustomize/sections.action";
import { getNewsletterTemplateVariables } from "store/actions/portal/newsletterCustomize/common.action";

import ROUTES from "core/constants/routes/routes";
import { UNSAVED_FORM_PAGE_TYPE } from "constants/common.constants";
import {
    SECTION_PREDEFINED_DATA_BY_SECTION_NAMES,
    STYLES_DEFAULT_SELECTED_KEY,
    STYLES_EDITOR_MENU_ITEMS,
    SECTIONS_DEFAULT_SELECTED_KEY,
    SECTION_NAMES,
    NEWSLETTER_TEMPLATE
} from "constants/newsletter.constants";

import useUnsavedChangesConfirmation from "hooks/useUnsavedChangesConfirmation";
import useCustomizeItems from "hooks/useCustomizeItems";

import { openBase64HtmlInNewTab, makeNewslaterHTML } from "utils/newsletter";

import newsletterConfigurationType from 'types/newsletter/configuration.type';


/** Newslette Customize Page Component */
const NewsletterCustomizeComponent = ({
    getNewsletterConfiguration,
    configuration,
    toggleNewsletterSection,
    getNewsletterTemplateVariables
}) => {

    const { t } = useTranslation();

    const searchParams = useParams();
    const navigate = useNavigate();

    const navigateWithConfirmation = useUnsavedChangesConfirmation({
        cb: navigate,
        subscribe: [
            UNSAVED_FORM_PAGE_TYPE.CUSTOMIZE_SECTIONS,
            UNSAVED_FORM_PAGE_TYPE.CUSTOMIZE_STYLES
        ]
    })

    const sections = useMemo(() => {

        return Object.values(SECTION_NAMES).reduce((result, current) => {
            result[current] = { enabled: Boolean(configuration?.sections?.[current])};
            return result;
        }, {})

    }, [configuration])

    const { sectionList, stylesList, unsavedTabs } = useCustomizeItems({
        sections: sections,
        onSectionToggle: data => toggleNewsletterSection(searchParams.id, data),
        stylesData: STYLES_EDITOR_MENU_ITEMS,
        sectionsData: SECTION_PREDEFINED_DATA_BY_SECTION_NAMES
    })

    /** Get Configuration */
    useEffect(() => {
        getNewsletterConfiguration(searchParams.id);
    }, [])

    /** Get Template Variables */
    useEffect(() => {
        getNewsletterTemplateVariables();
    }, [])

    /** Newsletter html */
    const templateHTML = useMemo(() => {
        return makeNewslaterHTML(NEWSLETTER_TEMPLATE.DEFAULT, configuration)
    }, [configuration])

    /** Reset Configuration */

    return (
        <Customize
            sidebarConfigs={{
                items: [
                    {
                        content: (
                            <Customize.Editor
                                items={stylesList}
                                defaultActiveSectionKey={STYLES_DEFAULT_SELECTED_KEY}
                                unsavedTabs={unsavedTabs.customizeStyles}
                            />
                        ),
                        icon: "pallet",
                        unsaved: unsavedTabs.customizeStyles.length > 0,
                        popupClassName: "rt--customize-sidebar-item-popup-small"
                    },
                    {
                        content: (
                            <Customize.Editor
                                items={sectionList}
                                defaultActiveSectionKey={SECTIONS_DEFAULT_SELECTED_KEY}
                                unsavedTabs={unsavedTabs.customizeSections}
                            />
                        ),
                        icon: "grid",
                        unsaved: unsavedTabs.customizeSections.length > 0,
                        popupClassName: "rt--customize-sidebar-item-popup-middle"
                    },
                ]
            }}
            preview={{
                html: templateHTML
            }}
            toolbarConfigs={{
                title: configuration.name,
                buttons: [
                    {
                        className: "rt--button-main rt--button-main-without-icon",
                        loading: false,
                        text: t("backoffice.promo.preview"),
                        onClick: () => openBase64HtmlInNewTab(templateHTML)
                    }
                ],
                backButton: {
                    onClick: () => navigateWithConfirmation(`${ROUTES.NEWSLETTER}#tab=1`),
                    text: t("backoffice.communication.backToTemplate")
                }
            }}
        />
    );
};

/** NewsletterCustomizeComponent propTypes
    * PropTypes
*/
NewsletterCustomizeComponent.propTypes = {
    /** Redux action to load newsletter configuration */
    getNewsletterConfiguration: PropTypes.func,
    /** Redux state property, newsletter configuration */
    configuration: newsletterConfigurationType,
    /** Redux action to toggle newsletter section */
    toggleNewsletterSection: PropTypes.func,
    /** Redux action to get template  */
    getNewsletterTemplateVariables: PropTypes.func,
}

const mapStateToProps = state => {
    return {
        configuration: state.newsletterCustomize.configuration,
    }
}

const mapDispatchToProps = dispatch => (
    {
        getNewsletterConfiguration: id => {
            dispatch(getNewsletterConfiguration(id))
        },

        toggleNewsletterSection: (id, data) => {
            dispatch(toggleNewsletterSection(id, data))
        },

        getNewsletterTemplateVariables: () => {
            dispatch(getNewsletterTemplateVariables())
        }
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterCustomizeComponent);
