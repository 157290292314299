import React from "react";
import PropTypes from "prop-types";

import Button from "core/ui-kit/button";

const DrawerFooterMobile = ({ 
    cancelText, 
    onCancel, 
    okText, 
    onOk 
}) => {
	return (
		<div className="rt--modal-footer rt--flex rt--justify-end rt--align-center">
			{cancelText && (
				<Button
					type="link"
					className="rt--button rt--button-ghost rt--ml-16"
					onClick={onCancel}
				>
					<span>{cancelText}</span>
				</Button>
			)}

			{okText && (
				<Button
					type="primary"
					className="rt--button rt--button-primary rt--ml-16"
					onClick={onOk}
				>
					<span>{okText}</span>
				</Button>
			)}
		</div>
	);
};

DrawerFooterMobile.propTypes = {
	cancelText: PropTypes.string,
	onCancel: PropTypes.func,
	okText: PropTypes.string,
	onOk: PropTypes.func,
};

export default DrawerFooterMobile;
