import React, { Fragment, useEffect, useState } from 'react';

import { useTranslation } from "core/hooks/useTranslation";
import usePermissions from 'core/hooks/usePermission';

import { Col, Row } from 'core/ui-kit/layout';
import Form from "core/ui-kit/form";
import Spin from "core/ui-kit/spin";
import Tooltip from "core/ui-kit/tooltip";
import Icon from "core/ui-kit/icon";
import Checkbox from "core/ui-kit/checkbox";
import AccessCheckbox from 'core/ui-kit/accessCheckbox';

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";

import projectService from 'core/services/apiServices/projectService';

import binaryToFlags from 'core/helpers/common/binaryToFlags';
import flagsToBinary from 'core/helpers/common/flagsToBinary';

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'core/constants/permission';
import POSTBACK_SETTING_TYPE from 'core/constants/postback/settingsType';

import { FORM_FIELD_NAMES } from './constants';
import isFormChanged from 'core/helpers/form/isFormChanged';
import showSuccess from 'core/helpers/common/showSuccess';

import { globalStore } from 'core/stores/globalStore';

const Postback = ({
    onTabChange
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const [isFormTouched, setIsFormTouched] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [postbackSettings, setPostbackSettings] = useState({});

    const [isViewCheckboxDisabled, setIsViewCheckboxDisabled] = useState(false)

    const hasModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT_POSTBACK_SETTINGS, PERMISSION_ACTION.MODIFY );

    useEffect(() => {
        setIsLoading(true);
        projectService.getPostbackSettings()
            .then(value => {
                setPostbackSettings(value);
                const typeFieldValue = binaryToFlags( Object.values(POSTBACK_SETTING_TYPE), value.affiliatePostbackSettingType)
                setFieldsValue({
                    [FORM_FIELD_NAMES.ENABLED]: value.enabled,
                    [FORM_FIELD_NAMES.TYPE]: typeFieldValue
                })

                setIsViewCheckboxDisabled(typeFieldValue.includes(POSTBACK_SETTING_TYPE.MODIFY) || typeFieldValue.includes(POSTBACK_SETTING_TYPE.CREATE))
            })
            .finally(() => setIsLoading(false))
    }, [])

    const handleSave = () => {
        formInstance.submit();
    }

    const handleFormSubmit = formData => {
        setIsSaving(true);
        const d = {
            enabled: formData[FORM_FIELD_NAMES.ENABLED],
            affiliatePostbackSettingType: flagsToBinary(formData[FORM_FIELD_NAMES.TYPE])
        }

        projectService.savePostbackSettings(d)
            .then(({value, message}) => {
                setPostbackSettings(value);
                setIsFormTouched(false);
                showSuccess(message);
                globalStore.getUserInfo()
            })
            .finally(() => setIsSaving(false))
    }

    const handleFormValuesChange = (_, values) => {
        const initialValues = {
            [FORM_FIELD_NAMES.ENABLED]: postbackSettings.enabled,
            [FORM_FIELD_NAMES.TYPE]: postbackSettings.affiliatePostbackSettingType
        }
        const formValues = {
            [FORM_FIELD_NAMES.ENABLED]: values[FORM_FIELD_NAMES.ENABLED],
            [FORM_FIELD_NAMES.TYPE]: flagsToBinary(values[FORM_FIELD_NAMES.TYPE])
        }
        setIsFormTouched(isFormChanged(initialValues, formValues))
    }

    const handleCheckboxChange = value => {
        if( value.includes(POSTBACK_SETTING_TYPE.MODIFY) || value.includes(POSTBACK_SETTING_TYPE.CREATE) ){
            if(!value.includes(POSTBACK_SETTING_TYPE.VIEW)){
                setFieldsValue({
                    [FORM_FIELD_NAMES.TYPE]: [ POSTBACK_SETTING_TYPE.VIEW, ...value]
                })
            }
            setIsViewCheckboxDisabled(true)
        } else {
            setIsViewCheckboxDisabled(false)
        }
    }


    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <SubTabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleSave,
                        text: t("backoffice.common.save"),
                        enabled: hasModifyPermission,
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    form={formInstance}
                    onFinish={handleFormSubmit}
                    onValuesChange={handleFormValuesChange}
                >
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <Form.Switch 
                                label={(
                                    <Fragment>
                                        <span>{t('backoffice.projects.postback')}</span>
                                        <Tooltip
                                            title={t('backoffice.projects.postbackTooltip')}
                                            enableMobile={true}
                                        >
                                            <div className="rt--ml-4 rt--flex rt--align-center rt--cursor-pointer">
                                                <Icon name="info" size={18} />
                                            </div>
                                        </Tooltip>
                                    </Fragment>
                                )}
                                name={FORM_FIELD_NAMES.ENABLED}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <Form.Item
                                name={FORM_FIELD_NAMES.TYPE}
                            >
                                <Checkbox.Group
                                    className='rt--width-full rt--mt-8'
                                    onChange={handleCheckboxChange}
                                >
                                    <Row gutter={[16, 0]}>
                                        <Col xs={24} sm={12} xl={6}>
                                            <AccessCheckbox 
                                                title={t("backoffice.permissions.View")}
                                                description={t("backoffice.projects.postbackViewDescription")}
                                                value={POSTBACK_SETTING_TYPE.VIEW}
                                                disabled={isViewCheckboxDisabled}
                                            />
                                        </Col>
                                        <Col xs={24} sm={12} xl={6}>
                                            <AccessCheckbox 
                                                title={t("backoffice.permissions.Modify")}
                                                description={t("backoffice.projects.postbackModifyDescription")}
                                                value={POSTBACK_SETTING_TYPE.MODIFY}
                                            />
                                        </Col>
                                        <Col xs={24} sm={12} xl={6}>
                                            <AccessCheckbox 
                                                title={t("backoffice.permissions.Create")}
                                                description={t("backoffice.projects.postbackCreateDescription")}
                                                value={POSTBACK_SETTING_TYPE.CREATE}
                                            />
                                        </Col>
                                        <Col xs={24} sm={12} xl={6}>
                                            <AccessCheckbox 
                                                title={t('backoffice.projects.postbackLogs')}
                                                description={t("backoffice.projects.postbackLogsDescription")}
                                                value={POSTBACK_SETTING_TYPE.LOGS}
                                            />
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    
                </Form>

            </Spin>
        </SubTabFormDashboardLayout>
    )
}


export default Postback;