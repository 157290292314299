import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { isMobile } from "utils/common";

import {
    SET_COMPANIES_ACTION_BEFORE,
    SET_COMPANIES_ACTION_FINISH,
    SET_COMPANIES,
    SET_COMPANIES_SORTING,
    SET_COMPANIES_FILTERS,
    SET_COMPANIES_SAVE_ACTION_BEFORE,
    SET_COMPANIES_SAVE_ACTION_FINISH
} from "../../../actionTypes";

import TokenManagerService from "core/services/tokenManagerService";

import { globalStore } from "core/stores/globalStore";

export const setCompaniesActionBefore = () => ({
    type: SET_COMPANIES_ACTION_BEFORE,
});

export const setCompaniesActionFinished = () => ({
    type: SET_COMPANIES_ACTION_FINISH,
});

export const setCompaniesSaveActionBefore = () => ({
    type: SET_COMPANIES_SAVE_ACTION_BEFORE,
});

export const setCompaniesSaveActionFinished = () => ({
    type: SET_COMPANIES_SAVE_ACTION_FINISH,
});

const setCompanies = (companies, add) => ({
    type: SET_COMPANIES,
    payload: { companies, add },
});

export const setCompaniesSorting = sorting => ({
    type: SET_COMPANIES_SORTING,
    payload: { sorting },
});

export const setCompaniesFilters = filters => ({
    type: SET_COMPANIES_FILTERS,
    payload: { filters },
});


export const getCompanies = nextPage => {
    return (dispatch, getState) => {
        
        const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().companies.sorting.page + 1 : getState().companies.sorting.page;
        
        let params = {
            ...getState().companies.sorting,
            ...getState().companies.filters,
            page: page
        };

     
        
        dispatch(setCompaniesActionBefore());

        return axios({
            url: ApiUrls.GET_COMPANIES,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: companies } }) => {
                dispatch(setCompanies(companies, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setCompaniesSorting({ ...getState().companies.sorting, page: page }));
                dispatch(setCompaniesActionFinished());
            })
            .catch((ex) => {
                dispatch(setCompaniesActionFinished());
            });
    }
}

export const createCompany = (company, onSuccess) => {
    return (dispatch) => {
        dispatch(setCompaniesSaveActionBefore());
        return axios({
            url: ApiUrls.CREATE_COMPANY,
            method: Methods.POST,
            data: {
                ...company
            },
        })
            .then(({ data }) => {
                dispatch(setCompaniesSaveActionFinished());
                if (isResponseSuccess(data)) {
                    
                    TokenManagerService.updateToken()
                        .then(() => {
                            return globalStore.getUserInfo()
                        })
                        .then(() => {
                            onSuccess && onSuccess(data.value);
                        })
                }
            })
            .catch(() => {
                dispatch(setCompaniesSaveActionFinished());
            });
    };
};
