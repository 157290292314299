import React from "react";

import PropTypes from "prop-types";
import { Dropdown as AntDropdown, Menu } from "antd";

const DropdownDesktop = ({ 
    className, 
    children, 
    popupOpened, 
    menu, 
    dropdownRender,
    onOpenChange,
    ...props 
}) => {

    const renderContent = () => {
		let renderedMenu = null;

		if (dropdownRender || menu) {
			renderedMenu = (
                <Menu 
                    onClick={menu.onClick}
                    className={menu.className}
                    style={menu.style}
                    selectedKeys={menu.selectedKeys}
                >
                    {
                        menu.items.map(item => (
                            <Menu.Item
                                key={item.key}
                                className={item.className}
                                disabled={item.disabled}
                                style={item.style}
                            >
                                {item.label}
                            </Menu.Item>
                        ))
                    }
                </Menu>
            );

			if (dropdownRender) {
				return dropdownRender(renderedMenu);
			}

			return renderedMenu;
		}

		return null;
	};

    const dropdownProps = {
        className: "rt--dropdown " + (className || ""),
        trigger: ["click"],
        overlay: renderContent(), // Remove in antd5,
        //menu:menu, // Add in antd5
        onVisibleChange: onOpenChange // Change in antd5
    }

    if(popupOpened !== undefined){
        dropdownProps.visible = popupOpened;// Change in antd5
    }

	return (
		<AntDropdown
			{...dropdownProps}
			{...props}
		>
			{children}
		</AntDropdown>
	);
};

DropdownDesktop.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	popupOpened: PropTypes.bool,
	menu: PropTypes.object,
    dropdownRender: PropTypes.func,
    onOpenChange: PropTypes.func
};

export default DropdownDesktop;