import React from 'react';

import { classNames, generateContactChannelLink } from 'utils/common';

import { CONTACT_CHANNEL_TYPES } from 'constants/affiliate.constants';

export const getTableColumns = (additionalProps) => {
    const {
        havePermissionForEdit,
        onContactsFieldChange,
        t
    } = additionalProps;

    return [
        {
            title: "backoffice.affiliates.channel",
            dataIndex: "type",
            width: "33%",
            mobileLevel: 1,
            render: value => CONTACT_CHANNEL_TYPES[value]
        },
        {
            title: "backoffice.affiliates.contactNumberOrUsername",
            dataIndex: "details",
            width: "33%",
            editable: havePermissionForEdit,
            maxLength: 30,
            mobileLevel: 2,
            render: value => Boolean(value) ? value : "-",
            editableLabel: () => t("backoffice.affiliates.contactNumberOrUsername"),
            editableTitle: () => t("backoffice.affiliates.contactNumberOrUsername"),
            onEdit: (details, record) => {
                onContactsFieldChange({ ...record, details, link: generateContactChannelLink(record.type, details) })
            },
        },
        {
            title: "backoffice.affiliates.channelLink",
            dataIndex: "link",
            width: "33%",
            mobileLevel: 3,
            render: value => (
                <div className={classNames(value && 'rt--table-col-link')}>
                    <b
                        className='rt--title rt--font-bold rt--font-normal'
                        onClick={() => value && window.open(value, "_blank")}
                    >
                        {value}
                    </b>
                </div>
            ),
        }
    ];
};