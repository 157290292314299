import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";

import { Menu } from "antd";

import { setPromoCustomizeLanguage } from "store/actions/portal/promoCustomize/common.action";
import { getPromoLanguages } from 'store/actions/portal/promoCustomize/sections.action';

import Dropdown from "components/common/dropdown";
import Icon from "components/common/icon";

import { classNames } from 'utils/common';
import { sendToPromoIframe } from 'utils/promo';

import { PROMO_UPDATE_TYPE } from 'constants/promo.constants';

/** Language Menu Item Component */
const LanguageMenuItem = ({
    onItemClick,
    isActive,
    customizeLanguage,
    setPromoCustomizeLanguage,
    getPromoLanguages,
    languages,
    defaultLanguageCode,
}) => {
    const { t } = useTranslation();

    // Load promo languages
    useEffect(() => {
        getPromoLanguages();
    }, [])

    const handleLanguageDropdownChange = lang => {
        if (lang === customizeLanguage) {
            return;
        }

        setPromoCustomizeLanguage(lang);

        sendToPromoIframe(PROMO_UPDATE_TYPE.LANGUAGE, {
            language: lang
        })
    }

    useEffect(() => {
        const isSelectedLanguageDisabled = languages.some(languageData => {
            const isSelected = (
                languageData.languageCode.toLowerCase() ===
                customizeLanguage.toLowerCase()
            )

            if (!isSelected) {
                return false
            }

            if (!languageData.enabled) {
                return true;
            }
        })

        if (isSelectedLanguageDisabled) {
            setPromoCustomizeLanguage(defaultLanguageCode)
        }
    }, [languages]);

    return (
        <div className="rt--customize-widget-main-menu-item-language">
            <Dropdown
                placement="bottomRight"
                overlay={(
                    <Menu onClick={e => handleLanguageDropdownChange(e ? e.key : null)}>
                        {
                            languages.map(lang => {
                                if (!lang.enabled) {
                                    return <Fragment key={lang.languageCode} />
                                }

                                return (
                                    <Menu.Item
                                        key={lang.languageCode}
                                        className={lang.languageCode.toUpperCase() === customizeLanguage.toUpperCase() ? "rt--dropdown-item-active" : ""}
                                    >
                                        <div className='rt--flex rt--align-center'>
                                            <span className='rt--title rt--font-normal rt--font-regular'>
                                                {t(`backoffice.languages.${lang.languageCode?.toLowerCase()}`)}
                                            </span>
                                        </div>
                                    </Menu.Item>
                                )
                            })
                        }
                    </Menu>
                )}
            >
                <div className='rt--customize-header-languages-content rt--flex rt--flex-equal rt--justify-between rt--align-center'>
                    <span className='rt--title rt--font-normal rt--font-bold rt--pr-4'>
                        {t(`backoffice.languages.${customizeLanguage?.toLowerCase()}`)}
                    </span>

                    <Icon name="down-small" />
                </div>
            </Dropdown>

            <div className="rt--customize-widget-main-menu-item-language-separator" />

            <div
                onClick={onItemClick}
                className={classNames(
                    "rt--customize-widget-main-menu-item-language-settings",
                    isActive && "rt--customize-widget-main-menu-item-language-settings-active"
                )}
            >
                <Icon name="settings" className="rt--customize-widget-main-menu-item-language-settings-icon" />
            </div>
        </div>
    )
}

/** LanguageMenuItem propTypes
 * PropTypes
*/
LanguageMenuItem.propTypes = {
    /** Redux state property, customize page language */
    customizeLanguage: PropTypes.string,
    /** Redux action to set promo customization language */
    setPromoCustomizeLanguage: PropTypes.func,
    /** Redux action to get promo languages */
    getPromoLanguages: PropTypes.func,
    /** Redux state property, represents the array of prom languages  */
    languages: PropTypes.arrayOf(PropTypes.shape({
        languageCode: PropTypes.string,
        isActive: PropTypes.bool,
    })),
    /** Fire when settings icon is clicked */
    onItemClick: PropTypes.func,
    /** Indicates whether the element is active or not */
    isActive: PropTypes.bool,
    /** Redux state property, represents default language code */
    defaultLanguageCode: PropTypes.string,
};

const mapStateToProps = state => {
    return {
        customizeLanguage: state.promoCustomize.customizeLanguage,
        defaultLanguageCode: state.promoCustomize.configuration.defaultLanguageCode,
        languages: state.promoCustomize.languages,
    }
}

const mapDispatchToProps = dispatch => (
    {
        setPromoCustomizeLanguage: lang => {
            dispatch(setPromoCustomizeLanguage(lang))
        },

        getPromoLanguages: () => {
            dispatch(getPromoLanguages());
        }
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(LanguageMenuItem)