import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Row, Col, Form } from 'antd';

import Select from 'components/common/select';
import DateRangePicker from 'components/common/dateRangePicker';

import FiltersWrapper from "components/common/filters";

import useAutosuggestion from 'hooks/useAutosuggestion';

import { getSystemPayments, setSystemPaymentsFilters } from "store/actions/portal/settings/systemPayments/systemPayments.action";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

import { globalProjectIdSelector, useAuthSelector } from 'core/stores/authStore';


const FILTERS_FIELD_NAMES = {
    PAYMENT_NAME: "nameOrId",
    PROJECT: "projectNameOrId",
    LABELS: "labels",
    DATE: "date"
}

/** System Payments Page Filters Component */
const Filters = ({
    setSystemPaymentsFilters,
    getSystemPayments,
    filters
}) => {
    const { t } = useTranslation();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);
    
    const [ availableMethods, getAvailableMethods ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.SYSTEM_PAYMENT_METHODS,
        autoGet: false
    });
    const [ availableProjects, getAvailableProjects ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.PROJECT,
        autoGet: false
    });
    const [ availableLabels, getAvailableLabels ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.PAYMENT_LABELS,
        autoGet: false
    });

    const methodsOptions = useMemo(() => {
        return availableMethods.map(({ name }) => ({ text: name, value: name }));
    }, [availableMethods]);

    const projectsOptions = useMemo(() => {
        return availableProjects.map(({ id, name }) => ({ text: name, value: id }));
    }, [availableProjects]);

    const labelsOptions = useMemo(() => {
        return availableLabels.map(({ name }) => ({ text: name, value: name }));
    }, [availableLabels]);

    return (
        <FiltersWrapper
            loadFn={getSystemPayments}
            updateProps={[globalProjectId]}
            setFiltersFn={setSystemPaymentsFilters}
            filters={filters}
            datePickerFields={[
                { name: FILTERS_FIELD_NAMES.DATE, time: false }
            ]}
            onInit={() => {
                getAvailableMethods();
                getAvailableProjects();
                getAvailableLabels();
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.payments.method')}
                        name={FILTERS_FIELD_NAMES.PAYMENT_NAME}
                    >
                        <Select
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.payments.method')}`}
                            options={methodsOptions}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.payments.project')}
                        name={FILTERS_FIELD_NAMES.PROJECT}
                    >
                        <Select
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.payments.project')}`}
                            options={projectsOptions}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.payments.labels')}
                        name={FILTERS_FIELD_NAMES.LABELS}
                    >
                        <Select
                            options={labelsOptions}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.payments.labels')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            isMultiple={true}
                            search={true}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.payments.createdAt')}
                        name={FILTERS_FIELD_NAMES.DATE}
                    >
                        <DateRangePicker
                            showTime={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get System Payments */
    getSystemPayments: PropTypes.func,
    /** Redux action to set projects filters */
    setSystemPaymentsFilters: PropTypes.func,
    /** Redux state property, projects filters */
    filters: PropTypes.object
}

const mapDispatchToProps = dispatch => (
    {
        getSystemPayments: () => {
            dispatch(getSystemPayments())
        },
        setSystemPaymentsFilters: filters => {
            dispatch(setSystemPaymentsFilters(filters))
        },
    }
)

const mapStateToProps = state => {
    return {
        filters: state.systemPayments.filters
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);