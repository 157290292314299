import ROUTES from "core/constants/routes/routes";

import PostDeploymentActionsComponent from "pages/developer/postDeploymentActions/postDeploymentActions.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const PostDeploymentActionsPageRoute = {
	path: ROUTES.DEVELOPER_POST_DEPLOYMENT_ACTIONS,
	element: PostDeploymentActionsComponent , 
    permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.MODIFY }
};

export default PostDeploymentActionsPageRoute;
