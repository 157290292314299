import { useDebugSelector } from "../../..";

export const withEnabled = (WrappedComponent) => (props) => {
    const { enabled } = useDebugSelector();

    if (!enabled) {
        return null;
    }

    return <WrappedComponent { ...props } />
}
