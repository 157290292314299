import React, { useMemo} from 'react';

import { debugStore, useDebugSelector } from '../../..';

import DebugChangeItem from '../debugChangeItem';

const DebugChangeList = () => {
    const { changes, selectedChange, stores, selectedStore } = useDebugSelector();

    const filteredChanges = useMemo(() => {
        if (!selectedStore) {
            return changes;
        }

        return changes.filter(change => change.name === selectedStore);
    }, [changes, selectedStore]);

    const handleSelectChange = (change) => {
        if (selectedChange && change.id === selectedChange.id) {
            debugStore.selectChange(null);
            return;
        }

        debugStore.selectChange(change);
    }

    const handleSelectStore = (event) => {
        debugStore.selectStore(event.target.value);
    }

    const getState = (change) => {
        if (change.status === 'INIT') {
            return 'init';
        }

        if (change.changes.previous === change.changes.current) {
            return 'empty'
        }
    }

    return (
        <div className="rt--debug-content-change-list">
            <div className="rt--debug-content-change-list-header">
                <select value={selectedStore} onChange={handleSelectStore}>
                    <option value="">---</option>
                    {stores.map(store => <option key={store} value={store}>{store}</option>)}
                </select>
            </div>
            <div className="rt--debug-content-change-list-content">
                {filteredChanges.map((change) => (
                        <DebugChangeItem
                            key={change.id}
                            isSelected={selectedChange && change.id === selectedChange.id}
                            status={getState(change)}
                            storeName={change.name}
                            propPath={change.path}
                            timestamp={change.timestamp}
                            onClick={() => handleSelectChange(change)}
                        />
                    ))}
            </div>
        </div>
    );
};

export default DebugChangeList;
