import ROUTES from "core/constants/routes/routes";

import UsersEditComponent from "pages/userManagement/users/edit/user-edit.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const UserEditPageRoute = {
	path: `${ROUTES.USERS_EDIT}/:id`,
	element: UsersEditComponent, 
    permission: [
        { resource: PERMISSION_RESOURCE.ADMIN_GENERALINFO, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.ADMIN_COMPANY_ACCESS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.ADMIN_PROJECT_ACCESS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.ADMIN_PERMISSIONS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.ADMIN_SESSIONS, action: PERMISSION_ACTION.VIEW }
    ]
};

export default UserEditPageRoute;