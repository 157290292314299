import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import Select from 'components/common/select';
import DateRangePicker from "components/common/dateRangePicker";

import { getAffiliateManagers, setAffiliateManagersFilters } from "store/actions/portal/userManagement/affiliateManagers/affiliateManagers.action";

import { FILTER_USER_STATE } from "constants/filter.constants";

import { globalProjectIdSelector, useAuthSelector } from 'core/stores/authStore';

/** Affiliate Managers Page Filters Component */

const Filters = ({
    setAffiliateManagersFilters,
    getAffiliateManagers,
    filters
}) => {
    const { t } = useTranslation();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);

    return (
        <FiltersWrapper
            loadFn={getAffiliateManagers}
            setFiltersFn={setAffiliateManagersFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            datePickerFields={[
                { name: "date", time: false },
                { name: "modified", time: false },
            ]}
            searchFieldName="nameOrId"
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.status')}
                        name="state"
                    >
                        <Select
                            options={[
                                { value: FILTER_USER_STATE.ALL, text: t('backoffice.common.all') },
                                { value: FILTER_USER_STATE.IN_PROGRESS, text: t('backoffice.common.inactive') },
                                { value: FILTER_USER_STATE.ACTIVE, text: t('backoffice.common.active') },
                                { value: FILTER_USER_STATE.BLOCKED, text: t('backoffice.common.blocked') },
                                { value: FILTER_USER_STATE.EXPIRED, text: t('backoffice.common.expired') }
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.common.status')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>


                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.creationDate')}
                        name="date"
                    >
                        <DateRangePicker
                            showTime={false}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.lastUpdateDate')}
                        name="modified"
                        className='rt--form-item-without-margin'
                    >
                        <DateRangePicker
                            showTime={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get affiliate managers */
    getAffiliateManagers: PropTypes.func,
    /** Redux action to set affiliate managers filters */
    setAffiliateManagersFilters: PropTypes.func,
    /** Redux state property, affiliate managers filters */
    filters: PropTypes.object
}

const mapDispatchToProps = dispatch => (
    {
        getAffiliateManagers: () => {
            dispatch(getAffiliateManagers())
        },
        setAffiliateManagersFilters: filters => {
            dispatch(setAffiliateManagersFilters(filters))
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.affiliateManagers.filters
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);