import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useParams, useLocation } from "react-router-dom";

//#region actions
import {
    getAffiliatePaymentMethods,
    getAffiliatePaymentMethodDetails,
    deleteAffiliatePaymentMethod,
    setAffiliateDefaultPaymentMethod
} from "store/actions/portal/affiliates/affiliates/paymentMethods.action";
//#endregion

//#region components
import SubTabTableDashboardLayout from 'components/layouts/tab/subtab/table';
import Table from "components/common/table";
import AddPaymentMethod from "./paymentMethod-add.component";
import EditPaymentMethod from "./paymentMethod-edit.component";
import PaymentMethodDetails from "./details";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import { EXPAND_TABLE_TYPES } from 'constants/common.constants';
import { AFFILIATES_PAYMENT_METHODS } from 'constants/pageName.constants';
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'core/constants/permission';
import { USER_ROLE, USER_STATE } from 'constants/user.constants';
import { getTableColumns } from './columns';
//#endregion

//#region types
import { paymentMethodType } from 'types/affiliate/finance.type';
//#endregion

import { globalProjectIdSelector, useAuthSelector, userRoleSelector } from 'core/stores/authStore';
import { useGlobalSelector, userInfoSelector } from 'core/stores/globalStore';
import usePermissions from 'core/hooks/usePermission';

const PaymentMethods = ({
    isLoading,
    isSaving,
    paymentMethods,
    getAffiliatePaymentMethods,
    getAffiliatePaymentMethodDetails,
    deleteAffiliatePaymentMethod,
    setAffiliateDefaultPaymentMethod
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);
    const role = useAuthSelector(userRoleSelector);
    const userInfo = useGlobalSelector(userInfoSelector);

    const routeParams = useParams();
    const { search } = useLocation();

    const [showAddPopup, setShowAddPopup] = useState(null);
    const [showEditPopup, setShowEditPopup] = useState(null);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: AFFILIATES_PAYMENT_METHODS });

    const affiliateId = role === USER_ROLE.AFFILIATE ? userInfo.id : routeParams.id;

    const entityState = Number((new URLSearchParams(search)).get("state"));

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE_PAYMENT_METHODS, PERMISSION_ACTION.MODIFY );
    const hasDeletePermission = permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE_PAYMENT_METHODS, PERMISSION_ACTION.DELETE );
    const hasCreatePermission = permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE_PAYMENT_METHODS, PERMISSION_ACTION.CREATE );

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns, columnsThatCanBeIncluded } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            constructForInclude: true,
            includedColumns,
            additionalProps: {
                t
            },
            t
        });
    }, [includedColumns, t])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [
        {
            title: t('backoffice.affiliates.setAsAutoInvoiceDefault'),
            icon: "default",
            onClick: paymentMethod => setAffiliateDefaultPaymentMethod(paymentMethod.id, paymentMethod.currencyCode, affiliateId ),
            disabled: record => !hasModifyPermission || record.isDefault
        },
        {
            title: t('backoffice.common.edit'),
            icon: "edit",
            onClick: paymentMethod => setShowEditPopup(paymentMethod),
            disabled: () => !hasModifyPermission
        },
        
        {
            title: t('backoffice.common.delete'),
            confirmationMessage: t("backoffice.payments.doYouWantToDeletePayment"),
            confirmationTitle: t("backoffice.common.delete"),
            icon: "trash",
            showConfirmation: true,
            className: "rt--button-danger",
            disabled: record => !hasDeletePermission || record.isDefault,
            onClick: (paymentMethod) => deleteAffiliatePaymentMethod( paymentMethod.id, paymentMethod.currencyCode, affiliateId)
        },
    ];

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        button: {
            icon: "plus",
            type: "primary",
            onClick: () => setShowAddPopup(true),
            text: t("backoffice.payments.addPaymentMethod"),
            enabled: hasCreatePermission && ( entityState !== USER_STATE.IN_PROGRESS && entityState !== USER_STATE.DECLINED)
        }
    }

    //#endregion

    return (
        <SubTabTableDashboardLayout
            header={headerPartsData}
        >
            <Table
                loading={isLoading || isSaving}
                columns={mainTableColumns}
                data={paymentMethods || []}
                loadFn={() => getAffiliatePaymentMethods(affiliateId)}
                noPagination={true}
                disableFullView={true}
                updateProps={[globalProjectId]}
                actions={tableRowActions}
                uniqueKey="unique"
                expandable={{
                    title: t("backoffice.affiliates.paymentMethods"),
                    type: EXPAND_TABLE_TYPES.CONTENT,
                    detailsLoadFn: record => (
                        !record.details && getAffiliatePaymentMethodDetails(record.id, record.currencyCode, affiliateId )
                    ),
                    details: record => {
                        return ({
                            data: record.details,
                            content: (
                                <PaymentMethodDetails details={record.details}/>
                            ),
                        })
                    },
                }}
            />

            {showAddPopup && <AddPaymentMethod onClose={() => setShowAddPopup(null)} />}
            {
                showEditPopup && 
                    <EditPaymentMethod 
                        paymentMethod={showEditPopup} 
                        onClose={() => setShowEditPopup(null)} 
                    />
            } 
        </SubTabTableDashboardLayout>
    )
};

PaymentMethods.propTypes = {
    paymentMethods: PropTypes.arrayOf(paymentMethodType),
    /** Redux action to get Affiliate Payment Methods */
    getAffiliatePaymentMethods: PropTypes.func,
    /** Redux action to get Affiliate selected Payment Details */
    getAffiliatePaymentMethodDetails: PropTypes.func,
    /** Redux action to delete Affiliate Payment Method */
    deleteAffiliatePaymentMethod: PropTypes.func,
    /** Redux action to set Affiliate Default Payment Method */
    setAffiliateDefaultPaymentMethod: PropTypes.func,
    /** Redux state property, is true when general info is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when general info is loading */
    isLoading: PropTypes.bool,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getAffiliatePaymentMethods: id => {
            dispatch(getAffiliatePaymentMethods(id));
        },
        getAffiliatePaymentMethodDetails: (paymentId, currencyCode, id) => {
            dispatch(getAffiliatePaymentMethodDetails(paymentId, currencyCode, id));
        },
        deleteAffiliatePaymentMethod: (paymentId, currencyCode, id) => {
            dispatch(deleteAffiliatePaymentMethod(paymentId, currencyCode, id));
        },
        setAffiliateDefaultPaymentMethod: (paymentId, currencyCode, id) => {
            dispatch(setAffiliateDefaultPaymentMethod(paymentId, currencyCode, id));
        },
    }
)

const mapStateToProps = state => {
    return {
        paymentMethods: state.affiliates.edit.finance.paymentMethods.data,
        isSaving: state.affiliates.isSaving,
        isLoading: state.affiliates.edit.finance.paymentMethods.isLoading,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethods);