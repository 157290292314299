import React from 'react';

import { useTranslation } from "core/hooks/useTranslation";

import Tabs from 'components/common/tabs';

import GeneralInfoComponent from '../edit/sections/general';

import ROUTES from "core/constants/routes/routes";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'core/constants/permission';


/** System Payments Edit Page Component */
const SystemPaymentsEdit = () => {
    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.payments.general"),
            permissions: [{ resource: PERMISSION_RESOURCE.SYSTEM_PAYMENT_METHODS, action: PERMISSION_ACTION.VIEW }],
            component: <GeneralInfoComponent />
        }
    ]

    return (
        <Tabs
            items={items}
            mainPage={
                {
                    title: t('backoffice.menu.payments'),
                    path: ROUTES.SETTINGS_PAYMENTS
                }
            }
        />
    )
}


export default SystemPaymentsEdit;