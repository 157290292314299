import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";

import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';

/** HTML Viewer Component */
const HTMLViewer = ({
    value
}) => {

    const { t } = useTranslation();

    return (
        <div>
            <CodeMirror
                extensions={[html()]}
                value={value}
                readOnly={true}
                editable={false}
                width="100%"
                theme="none"
                basicSetup={{
                    foldGutter: false,
                    lineNumbers: false,
                    tabSize: 1
                }}
            />
        </div>
    )
}

/** HTMLViewer propTypes
    * PropTypes
*/
HTMLViewer.propTypes = {
    /** Value */
    value: PropTypes.string
}

export default HTMLViewer;