import axiosInstance from "core/lib/axios";

import sessionManagerService from "../sessionManagerService";

import { getURL, getURLMergedWithQueryData, handleRequestBody } from "./helpers";

class HttpService {
    #request({url, method, queryData, requestBody, signal, onProgress, configs = {}}){
        sessionManagerService.refresh();
        
        return axiosInstance({
            url,
            method,
            signal,
            params: queryData,
            data: requestBody, 
            onUploadProgress: onProgress,
            ...configs
        })
    }

    get({ url, queryData, signal, onProgress, configs }){
        const mergedURL = getURLMergedWithQueryData(getURL(url), queryData);

        return this.#request({
			method: "get",
			url: mergedURL,
			signal,
			onProgress,
			configs,
		});
    }

    post({ url, requestBody, signal, onProgress, configs }) {
		return this.#request({
			method: "post",
			url: getURL(url),
			requestBody: handleRequestBody(requestBody),
			signal,
			onProgress,
			configs,
		});
	}

    delete({ url, requestBody, signal, onProgress, configs }) {
		return this.#request({
			method: "delete",
			url: getURL(url),
			requestBody: handleRequestBody(requestBody),
			signal,
			onProgress,
			configs,
		});
	}
}

export default new HttpService();