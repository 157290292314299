import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "core/hooks/useTranslation";

import { Form } from 'antd';

import Modal from 'components/common/modal';
import Input from 'components/common/input';

import { isFunction } from 'utils/common';

import { URL_REGEX } from 'constants/regex.constants';

const FORM_FIELD_NAMES = {
    BRAND_NAME: "brandName",
    URL: "url",
}

const BrandsAddEditComponent = ({
    brandName,
    url,
    onClose,
    type,
    onOk,
    isSaving,
}) => {
    const { t } = useTranslation();

    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    const handleForm = () => {
        validateFields()
            .then(fieldsValue => {
                if (isFunction(onOk)) {
                    onOk(fieldsValue);
                    onClose();
                }

            }).catch(ex => {
                console.log(ex)
            })
    }

    return (
        <Modal
            title={type === "edit" ? t('backoffice.promo.editBrand') : t('backoffice.promo.addBrand')}
            cancelText={t('backoffice.common.cancel')}
            okText={type === "edit" ? t('backoffice.common.save') : t('backoffice.common.add')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    [FORM_FIELD_NAMES.BRAND_NAME]: brandName,
                    [FORM_FIELD_NAMES.URL]: url,
                }}
            >
                <Form.Item
                    label={`${t('backoffice.promo.brandName')} *`}
                    name={FORM_FIELD_NAMES.BRAND_NAME}
                    className='rt--general-form-item'
                    validateFirst
                    rules={[
                        { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                        { max: 50, message: t('backoffice.validation.mustBeLess').replace("%X%", 50) },
                        { min: 3, message: t('backoffice.validation.mustBeMore').replace("%X%", 3) }
                    ]}
                >
                    <Input
                        placeholder={`${t('backoffice.common.enter')} ${t('backoffice.promo.brandName')}`}
                        maxLength={50}
                    />
                </Form.Item>
                <Form.Item
                    name={FORM_FIELD_NAMES.URL}
                    label={`${t('backoffice.promo.url')} *`}
                    className="rt--form-item-without-margin rt--general-form-item"
                    rules={[
                        { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                        { pattern: URL_REGEX, message: t('backoffice.validation.fieldInvalid') }
                    ]}
                    validateFirst
                >
                    <Input.Dropdown
                        placeholder={`${t('backoffice.common.enter')} ${t('backoffice.promo.url')}`}
                        items={[
                            {
                                label: "https://",
                                value: "https://",
                            }
                        ]}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}

/** BrandsAddEditComponent propTypes
    * PropTypes
*/
BrandsAddEditComponent.propTypes = {
    type: PropTypes.oneOf([
        "add",
        "edit",
    ]),
    /** Fires on popup close */
    onClose: PropTypes.func,
    /** Fires on popup add/save button clicked */
    onOk: PropTypes.func,
    /** title initial value on add/edit mode */
    brandName: PropTypes.string,
    /** url initial value on add/edit mode */
    url: PropTypes.string,
    /** true when saving data */
    isSaving: PropTypes.bool,

}

export default BrandsAddEditComponent;