import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'antd';

import Icon from "components/common/icon";

import InternalEditor from './internalEditor';
import { isFunction } from 'utils/common';


const EditorWithAdditionalActions = ({
    initialValue,
    onConfirm,
    onChange,
    onReset,
    showReset,
    value,
    ...restProps
}) => {

    const [isEditorInitialized, setIsEditorInitialized] = useState(false);

    const [currentValue, setCurrentValue] = useState("");

    const handleSave = () => {
        if (!isFunction(onConfirm)) {
            return;
        }

        onConfirm(currentValue)
    }

    const handleCancel = () => {
        if (!isFunction(onChange)) {
            return;
        }
        onChange(initialValue);
    }

    useEffect(() => {
        setCurrentValue(initialValue)
    }, [initialValue])

    return (
        <div className='rt--editor'>
            <InternalEditor
                {...restProps}
                onInit={() => setIsEditorInitialized(true)}
                onChange={value => {
                    setCurrentValue(value);
                    if (isFunction(onChange)) {
                        onChange(value);
                    }
                }}
                value={value}
            />
            {isEditorInitialized && (
                <div className='rt--editor-actions rt--flex rt--justify-end'>
                    {showReset && (
                        <Button
                            className="rt--button"
                            onClick={onReset}
                        >
                            <Icon name="reload" />
                        </Button>
                    )}
                    {
                        initialValue !== currentValue && (
                            <>
                                <Button
                                    className="rt--button"
                                    onClick={handleCancel}
                                >
                                    <Icon name="close" className='rt--error-text' />
                                </Button>
                                <Button
                                    className="rt--button"
                                    onClick={handleSave}
                                >
                                    <Icon name="ok" className='rt--success-text' />
                                </Button>
                            </>
                        )
                    }
                </div>
            )}
        </div>
    );
};

EditorWithAdditionalActions.propTypes = {
    /** Initial Value */
    initialValue: PropTypes.string,
    /** On Confirm handler */
    onConfirm: PropTypes.func,
    /** On Reset handler */
    onReset: PropTypes.func,
    /** On Change Handler */
    onChange: PropTypes.func,
    /** Value */
    value: PropTypes.string,
    /** Show reset action button */
    showReset: PropTypes.bool
}

export default EditorWithAdditionalActions;