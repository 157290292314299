export const getTableColumns = (additionalProps) => {
    const {
        formatCurrencyWithSymbol,
        currencyCode,
        t 
    } = additionalProps; 

    return [
        {
            title: "backoffice.reports.playerRegistrations",
            dataIndex: "playerRegistrationCount",
            mobileLevel: 1
        },
        {
            title: "backoffice.reports.depositCount",
            dataIndex: "depositCount",
            mobileLevel: 2
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.depositAmount")),
            dataIndex: "depositAmount",
            mobileLevel: 3
        },

        {
            title: "backoffice.reports.ftdCount",
            dataIndex: "ftdCount",
            isNumeric: true,
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.ftdAmount")),
            dataIndex: "ftdAmount"
        },

        {
            title: "backoffice.reports.qftdCount",
            dataIndex: "cpaCount",
            isNumeric: true
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.netDeposit")),
            dataIndex: "netDepositAmount",
            mobileLevel: 4
        },

        {
            title: "backoffice.reports.sportBetCount",
            dataIndex: "sportBetCount",
            isNumeric: true,
            mobileLevel: 5
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.sportBet")),
            dataIndex: "sportBetAmount",
            mobileLevel: 6
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.sportWonAmount")),
            dataIndex: "sportWonAmount",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.sportGGR")),
            dataIndex: "sportGGR",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.sportBonusWonAmount")),
            dataIndex: "sportBonusWonAmount",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.sportAdminFee")),
            dataIndex: "sportFeeAmount",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.sportNGR")),
            dataIndex: "sportNGR",
        },

        
        {
            title: "backoffice.reports.casinoBetCount",
            dataIndex: "casinoBetCount",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.casinoBet")),
            dataIndex: "casinoBetAmount",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.casinoWonAmount")),
            dataIndex: "casinoWonAmount",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.casinoGGR")),
            dataIndex: "casinoGGR",
        },


        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.casinoBonusWonAmount")),
            dataIndex: "casinoBonusWonAmount",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.casinoAdminFee")),
            dataIndex: "casinoFeeAmount",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.casinoNGR")),
            dataIndex: "casinoNGR",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.ngr")),
            dataIndex: "ngr",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.depositFee")),
            dataIndex: "depositFeeAmount",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.adjustments")),
            dataIndex: "adjustmentsAmount",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.sportRevShare")),
            dataIndex: "sportRevShareAmount",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.casinoRevShare")),
            dataIndex: "casinoRevShareAmount",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.totalRevShare")),
            dataIndex: "totalRevShareAmount",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.cpaAmount")),
            dataIndex: "cpaAmount",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.fixedAmount")),
            dataIndex: "fixedCommissionAmount",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.totalEarning")),
            dataIndex: "totalCommissionAmount",
        }
    ]
} 
