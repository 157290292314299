import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Form, Col, Row } from 'antd';

import Modal from "components/common/modal";
import Select from "components/common/select";
import NumericInput from "components/common/numericInput";
import Tooltip from "components/common/tooltip";
import Icon from "components/common/icon";

import { addProjectCurrency, saveProjectCurrency } from "store/actions/portal/projects/currencies.action";
import { getSystemAvailableCurrencies } from "store/actions/portal/settings/systemCurrencies/systemCurrencies.action";
import { DEFAULT_CURRENCY } from "constants/currency.constants";

import currencyType from "types/currency/currency.type";

import { isFormChanged } from "utils/form";
import { numberTransform } from 'utils/common';

import { POPUP_SIZE } from 'constants/common.constants';

import { globalProjectIdSelector, useAuthSelector } from 'core/stores/authStore';

/** Currency Creating Popup Component */
const CurrencyAddEditComponent = ({
    isSaving,
    isAvailableLoading,
    systemAvailableCurrencies,
    currencies,
    addProjectCurrency,
    saveProjectCurrency,
    getSystemAvailableCurrencies,
    editingCurrency,
    onClose
}) => {
    const { t } = useTranslation();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const [isFormTouched, setIsFormTouched] = useState(editingCurrency ? false : true);

    const [selectedCur, setSelectedCur] = useState(editingCurrency ? editingCurrency.toUpperCase() : "");
    const [systemValues, setSystemValues] = useState({})

    /** Load system currencies */
    useEffect(() => {
        getSystemAvailableCurrencies();
    }, [])


    /** Get initial values for form
       * @function
       * @returns {object} - initial values
       * @memberOf CurrencyAddEditComponent
   */
    const getDefaultValues = () => {

        if (editingCurrency) {
            const cur = currencies.find(c => c.code === editingCurrency);
            if (cur) {
                return {
                    code: cur.code,
                    rate: cur.isOverridden ? cur.rate : "",
                }
            }
        }

        return {
            code: undefined,
            rate: ""
        };
    }

    /** Fires when form submitted
       * @function
       * @memberOf CurrencyAddEditComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                const d = {
                    code: data.code.toUpperCase(),
                    rate: data.rate ? Number(data.rate) : null
                }

                if (editingCurrency) {
                    const cur = currencies.find(c => c.code === editingCurrency);
                    saveProjectCurrency({
                        ...d,
                        id: cur.id,
                        enabled: cur.enabled,
                        isDefault: cur.isDefault,
                    }, onClose);
                } else {
                    addProjectCurrency(d, globalProjectId, onClose);
                }

            }).catch(() => { })
    }


    /** Fill default value for rate */
    useEffect(() => {
        const selectedCurrency = systemAvailableCurrencies.find(c => c.code.toLowerCase() === selectedCur.toLowerCase())
        if (selectedCurrency && selectedCur && !editingCurrency) {
            const values = {
                code: selectedCurrency.code
            }
            setFieldsValue(values);
            setSystemValues({
                ...values,
                rate: selectedCurrency.rate
            })
        } else if (systemAvailableCurrencies.length > 0 && editingCurrency) {
            const cur = currencies.find(c => c.code.toLowerCase() === editingCurrency.toLowerCase());
            const values = {
                code: cur.code
            }
            setFieldsValue(values);
            setSystemValues({
                ...values,
                rate: selectedCurrency?.rate ?? ""
            })
        }
    }, [selectedCur, systemAvailableCurrencies])


    return (
        <Modal
            title={editingCurrency ? t('backoffice.currencies.editCurrency') : t('backoffice.currencies.addCurrency')}
            cancelText={t('backoffice.common.cancel')}
            okText={editingCurrency ? t('backoffice.common.save') : t('backoffice.common.add')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.SMALL}
            disableOkButton={!isFormTouched}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={getDefaultValues()}
                onValuesChange={(changed, formValues) => {
                    if (editingCurrency) {
                        setIsFormTouched(isFormChanged({ ...formValues }, { ...getDefaultValues() }))
                    }
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.currencies.selectCurrency')} *`}
                            name="code"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') }
                            ]}
                            className={editingCurrency ? "rt--form-item-disabled" : ""}
                        >
                            <Select
                                options={
                                    systemAvailableCurrencies
                                        .filter(item => !currencies.some(c => c.code.toUpperCase() === item.code.toUpperCase()))
                                        .map(item => (
                                            { value: item.code.toUpperCase(), text: item.code.toUpperCase() + " - " + item.name }
                                        ))
                                }
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                loading={isAvailableLoading}
                                disabled={Boolean(editingCurrency)}
                                placeholder={t('backoffice.currencies.selectCurrencyPlaceholder')}
                                onChange={v => {
                                    setSelectedCur(v.toUpperCase());
                                    if (v.toUpperCase() === "EUR") {
                                        setFieldsValue({ rate: "1" })
                                    }
                                }}
                                search={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={t('backoffice.currencies.rate')}
                            name="rate"
                            rules={[
                                { type: "number", max: 99999999, message: t('backoffice.validation.mustBeLess').replace("%X%", 99999999), transform: numberTransform },
                                { type: "number", min: 0.000001, message: t('backoffice.validation.mustBeMore').replace("%X%", 0.000001), transform: numberTransform }
                            ]}
                            className={'rt--general-form-item rt--form-item-with-suffix' + (selectedCur === "EUR" ? ' rt--form-item-disabled' : "")}
                            data-placeholder={systemValues.rate ? `${t('backoffice.currencies.default')}: ${systemValues.rate}` : `${t('backoffice.common.enter')} ${t('backoffice.currencies.rate')}`}
                        >
                            <NumericInput
                                placeholder={systemValues.rate ? `${t('backoffice.currencies.default')}: ${systemValues.rate}` : `${t('backoffice.common.enter')} ${t('backoffice.currencies.rate')}`}
                                maxLength={8}
                                disabled={selectedCur === "EUR"}
                                suffix={getDefaultValues().isOverridden ? (
                                    <Tooltip
                                        title={t('backoffice.currencies.resetToDefault')}
                                        trigger={["hover"]}
                                        placement="bottom"
                                        enableMobile={true}
                                    >
                                        <Icon
                                            name='reset'
                                            onClick={() => {
                                                setFieldsValue({ rate: "" });
                                                setIsFormTouched(true)
                                            }}
                                        />
                                    </Tooltip>
                                ) : <span className='rt--text-light rt--font-smallest rt--font-bold rt--pl-4 rt--r-4'>{`=1${DEFAULT_CURRENCY}`}</span>}
                            />

                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Modal>
    )
}

/** CurrencyAddEditComponent propTypes
    * PropTypes
*/
CurrencyAddEditComponent.propTypes = {
    /** Redux state property, is true when adding currency request is in process */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when loading available currencies */
    isAvailableLoading: PropTypes.bool,
    /** Redux action to add currency for project */
    addProjectCurrency: PropTypes.func,
    /** Redux action to edit currency for project */
    saveProjectCurrency: PropTypes.func,
    /** Redux action to get system available currencies */
    getSystemAvailableCurrencies: PropTypes.func,
    /** Redux state property, represents the array of currencies  */
    currencies: PropTypes.arrayOf(currencyType),
    /** Redux state property, represents the array of system available currencies  */
    systemAvailableCurrencies: PropTypes.arrayOf(currencyType),
    /** Fires on popup close */
    onClose: PropTypes.func,
    /** Current editing currecny */
    editingCurrency: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        addProjectCurrency: (currency, id, onSuccess) => {
            dispatch(addProjectCurrency(currency, id, onSuccess));
        },
        saveProjectCurrency: (currency, onSuccess) => {
            dispatch(saveProjectCurrency(currency, onSuccess));
        },
        getSystemAvailableCurrencies: () => {
            dispatch(getSystemAvailableCurrencies())
        },
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.projects.isSaving,
        isAvailableLoading: state.systemCurrencies.isAvailableLoading,
        currencies: state.projects.edit.currencies,
        systemAvailableCurrencies: state.systemCurrencies.systemAvailableCurrencies
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyAddEditComponent)