import ROUTES from "core/constants/routes/routes";

import GuideComponent from "pages/guide/guide.component";

const GuideRoute = {
	path: `${ROUTES.GUIDE}`,
	element: GuideComponent , 
};

export default GuideRoute;
