import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Icon from "components/common/icon";

import { getPermissionRequestsCount } from 'store/actions/portal/userManagement/requests/requests.action';

import { flagsToBinary } from 'utils/common';

import ROUTES from "core/constants/routes/routes";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'core/constants/permission';
import { PERMISSION_REQUEST_TYPE } from 'constants/permissionRequest.constants';

import useFirstRender from 'hooks/useFirstRender';
import usePermissions from 'core/hooks/usePermission';

/** Pending Requests Note Component */
const PendingRequestsNote = ({
    objectId,
    actionTypes = [],
    objectType,
    dependencies = [],
    getPermissionRequestsCount,
    count
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const navigate = useNavigate();

    const flag = flagsToBinary(actionTypes);

    const requestsCount = count[flag] ?? 0;

    const hasRequestViewPermission = permissionUtils.has( PERMISSION_RESOURCE.PERMISSION_REQUESTS, PERMISSION_ACTION.VIEW );

    useFirstRender({
        afterFirstRenderCB: () => {
            if(hasRequestViewPermission){
                getPermissionRequestsCount(objectId, actionTypes, objectType);
            }
        },
        dependencies: dependencies
    })

    return requestsCount > 0 ? (
        <div
            className={
                'rt--alert-info ' + 
                (hasRequestViewPermission ? " rt--cursor-pointer": "") + 
                (flag === PERMISSION_REQUEST_TYPE.GROUP_MODIFY ? " rt--mb-16": "")
            }
            onClick={() => {
                if (hasRequestViewPermission) {
                    navigate(`${ROUTES.PERMISSION_REQUESTS}?objectId=${objectId}&actionTypes=${flag}&objectType=${objectType}`);
                }
            }}
        >
            <div className='rt--flex rt--align-center rt--alert-info-content'>
                <Icon name='info' />
                <span className='rt--pl-4 rt--title rt--font-bold rt--font-regular'>{t("backoffice.users.pendingRequestsInfoMessage").replace("%%count%%", requestsCount)}</span>
            </div>
        </div>
    ) : null
}

/** PendingRequestsNote propTypes
    * PropTypes
*/
PendingRequestsNote.propTypes = {
    /** Object Id */
    objectId: PropTypes.string,
    /** Action Type */
    actionTypes: PropTypes.arrayOf(PropTypes.number),
    /** Object Type */
    objectType: PropTypes.number,
    /** Dependencies */
    dependencies: PropTypes.array,
    /** Redux action, to get permission requests */
    getPermissionRequestsCount: PropTypes.func,
    /** Redux state property, permission requests counts */
    count: PropTypes.object
}

const mapDispatchToProps = dispatch => (
    {
        getPermissionRequestsCount: (objectId, actionType, objectType) => {
            dispatch(getPermissionRequestsCount(objectId, actionType, objectType))
        }
    }
)

const mapStateToProps = state => {
    return {
        count: state.permissionRequests.count
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    PendingRequestsNote
);