import ROUTES from "core/constants/routes/routes";

import AffiliatesEdit from "pages/affiliates/affiliates/edit/affiliate-edit.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

import { USER_ROLE } from "core/constants/auth";

const MyAccountPageRoute = {
	path: ROUTES.MY_ACCOUNT,
	element: AffiliatesEdit, 
    permission: { resource: PERMISSION_RESOURCE.AFFILIATE, action: PERMISSION_ACTION.VIEW },
    role: USER_ROLE.AFFILIATE
};

export default MyAccountPageRoute;
