import { message } from 'antd';

import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setUsersActionBefore, setUsersActionFinished, setUsersSaveActionBefore, setUsersSaveActionFinished } from './users.action';

import { SET_USER_COMPANY_ACCESS } from "../../../../actionTypes";

const setUserCompanyAccess = companyAccess => ({
    type: SET_USER_COMPANY_ACCESS,
    payload: { companyAccess }
})

export const getUserCompanyAccess = id => {
    return dispatch => {
        dispatch(setUsersActionBefore());

        return axios({
            url: ApiUrls.GET_USER_COMPANY_ACCESS,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({data : {value: companyAccess}}) => {
            dispatch(setUserCompanyAccess(companyAccess))
            dispatch(setUsersActionFinished());
        })
        .catch((ex) => {
            dispatch(setUsersActionFinished());
        })
    }
}

export const addUserCompanyAccess = (id, companyIds, note, onSuccess) => {
    return dispatch => {
        dispatch(setUsersSaveActionBefore());
        return axios({
            url: ApiUrls.ADD_USER_COMPANY_ACCESS,
            method: Methods.POST,
            data: {id, companyIds, note}
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setUserCompanyAccess(data.value));
                onSuccess && onSuccess()
            } 
            dispatch(setUsersSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setUsersSaveActionFinished());
        })
    }
}

export const deleteUserCompanyAccess = (id, companyIds, note) => {
    return dispatch => {
        dispatch(setUsersSaveActionBefore());
        return axios({
            url: ApiUrls.DELETE_USER_COMPANY_ACCESS,
            method: Methods.DELETE,
            data: {id, companyIds, note}
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setUserCompanyAccess(data.value));
            } 
            dispatch(setUsersSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setUsersSaveActionFinished());
        })
    }
}
