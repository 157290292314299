import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";

import { deletePromoImage, setPromoImage } from 'store/actions/portal/promoCustomize/image.action';

import ImageUploader from "components/common/imageUploader";

import { sendToPromoIframe } from 'utils/promo';

import ApiUrls from 'constants/api.constants';
import { PROMO_UPDATE_TYPE } from 'constants/promo.constants';

import { getAPIUrls, isFunction } from 'utils/common';

const getDefaultImgPath = (imgPath) => {
    if (Boolean(imgPath)) {
        return {
            url: `${import.meta.env.SYSTEM_CDN_URL}/${imgPath.toLowerCase()}`,
            status: "done",
            percent: 100
        }
    }

    return null;
}

const CustomizeImgUploader = ({
    sectionName,
    sectionType,
    fileType,
    imgPath,
    onSuccess,

    // redux
    customizeLanguage,
    deletePromoImage,
    setPromoImage,

    ...restProps
}) => {
    const { t } = useTranslation();

    const handleSuccessfulUpload = (newImgPath) => {
        setPromoImage(newImgPath, sectionType, sectionName, fileType, customizeLanguage);

        sendToPromoIframe(PROMO_UPDATE_TYPE.IMAGE, {
            fileType,
            sectionType,
            language: customizeLanguage,
            path: newImgPath
        })

        if (isFunction(onSuccess)) {
            onSuccess(newImgPath);
        }
    }

    const handleImgDeletion = () => {
        deletePromoImage(
            sectionType,
            sectionName,
            fileType,
            customizeLanguage,
            () => {
                sendToPromoIframe(PROMO_UPDATE_TYPE.IMAGE, {
                    sectionType,
                    fileType,
                    language: customizeLanguage,
                    path: null
                })
            }
        )
    }

    const requestBodyForUpload = {
        sectionType,
        fileType,
        languageCode: customizeLanguage,
    }

    return (
        <ImageUploader
            {...restProps}
            uploadUrl={`${getAPIUrls().API_URL}${ApiUrls.UPLOAD_PROMO_IMAGE}`}
            data={requestBodyForUpload}
            size={1024*1024}
            disablePreview={true}
            onSuccess={handleSuccessfulUpload}
            defaultFile={getDefaultImgPath(imgPath)}
            updateProps={[imgPath]}
            fileBuilder={file => ({
                url: `${import.meta.env.SYSTEM_CDN_URL}/${file}`.toLowerCase()
            })}
            remove={{
                handler: handleImgDeletion
            }}
        />
    );
}

/** CustomizeImgUploader propTypes
 * PropTypes
*/
CustomizeImgUploader.propTypes = {
    /** Redux state property, customize page language */
    customizeLanguage: PropTypes.string,
    /** Redux action to delete promo image */
    deletePromoImage: PropTypes.func,
    /** Redux action to save promo image */
    setPromoImage: PropTypes.func,
    /** Image URL */
    imgPath: PropTypes.string,
    /** Section Type */
    sectionType: PropTypes.number,
    /** File Type */
    fileType: PropTypes.number,
    /** Section Name */
    sectionName: PropTypes.string,
    /** Fires when img successfully uploaded  */
    onSuccess: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
    deletePromoImage: (sectionType, sectionName, fileType, languageCode, onSuccess) => {
        dispatch(deletePromoImage(sectionType, sectionName, fileType, languageCode, onSuccess))
    },
    setPromoImage: (imgPath, sectionType, sectionName, fileType, customizeLanguage) => {
        dispatch(setPromoImage(imgPath, sectionType, sectionName, fileType, customizeLanguage))
    },
});

const mapStateToProps = (state) => {
    return {
        customizeLanguage: state.promoCustomize.customizeLanguage
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomizeImgUploader);