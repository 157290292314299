import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";

import Tabs from 'components/common/tabs';

import GeneralInfoComponent from './sections/general';

/**Commission Plan edit Component */
const CommissionPlanEditComponent = ({
    isAffiliate,
    onTabChange
}) => {
    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.commissionplans.general"),
            component: <GeneralInfoComponent isAffiliate={isAffiliate}/>
        }
    ]

    return <Tabs.SubTabs
        items={items}
        isDeepView={true}
        onTabChange={onTabChange}
    />
}

/** CommissionPlanEditComponent propTypes
    * PropTypes
*/
CommissionPlanEditComponent.propTypes = {
    /** Is Affiliate */
    isAffiliate: PropTypes.bool,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func,
}


export default CommissionPlanEditComponent;
