import React, { forwardRef } from "react";

import PropTypes from "prop-types";

import Form from "..";

import Input from "core/ui-kit/input";

import classNames from "core/helpers/common/classNames";

import { INPUT_TYPE } from "core/constants/component/input";

const FormTextarea = forwardRef(({
	name,
	label,
	rules=[],
	className,
	showIsRequired = true,
	noStyle,
	placeholder,
    dependencies,
    disabled,
    normalize,
    ...props
}, ref) => {
	const isRequired = rules && rules.find(rule => rule.required) && showIsRequired;

	const builtLabel = label ? isRequired ? `${label} *` : label : "";

    const builtClassName = classNames(
        disabled && "rt--form-item-disabled",
        className
    )

	return (
		<Form.Item
			className={builtClassName}
			name={name}
			rules={rules}
            dependencies={dependencies}
			label={builtLabel}
			noStyle={noStyle}
            validateFirst={rules.length > 1}
            normalize={normalize}
		>
			<Input
                ref={ref} 
                type={INPUT_TYPE.TEXTAREA} 
                placeholder={placeholder}
                disabled={disabled}
                {...props}
            />
		</Form.Item>
	);
});

FormTextarea.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string,
	name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	rules: PropTypes.array,
	showIsRequired: PropTypes.bool,
	noStyle: PropTypes.bool,
	placeholder: PropTypes.string,
    dependencies: PropTypes.array,
    disabled: PropTypes.bool,
    normalize: PropTypes.func
};

export default FormTextarea;
