//#region react
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
//#endregion

//#region actions
import {
    getAggregatedData,
    setAggregatedDataSorting,
    setAggregatedDataFilters
} from 'store/actions/portal/reports/aggregatedData/aggregatedData.action';
//#endregion

//#region components
import MainDashboardLayout from "components/layouts/main";
import Table from "components/common/table";
import Filters from "./filters.component";
//#endregion

//#region utils
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'core/constants/permission';
import ApiUrls from 'constants/api.constants';
import { AGGREGATED_DATA } from 'constants/pageName.constants';
import { getTableColumns } from './columns';
//#endregion

//#region hooks
import useFormat from 'hooks/useFormat';
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endRegion

//#region types
import aggregatedDataType from 'types/reports/aggregatedData.type';
import sortingType from 'types/common/sorting.type';
import { DATE_PICKER_RANGES } from 'constants/common.constants';
//#endregion

import { globalProjectIdSelector, useAuthSelector } from 'core/stores/authStore';
import usePermissions from 'core/hooks/usePermission';

/** Aggregated Data Report Component */
const AggregatedDataComponent = ({
    report,
    sorting,
    filters,
    total,
    isLoading,
    getAggregatedData,
    setAggregatedDataSorting,
    setAggregatedDataFilters
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);

    const { formatAmount } = useFormat();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: AGGREGATED_DATA });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = permissionUtils.has( PERMISSION_RESOURCE.REPORT_AGGREGATION_HISTORY, PERMISSION_ACTION.EXPORT );

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleDateSearchChange = value => {
        setAggregatedDataFilters({
            ...filters,
            from: value[0],
            to: value[1]
        })
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded and columnsForExport are only needed in the desktop version.
    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            includedColumns,
            constructForExport: true,
            constructForInclude: true,
            additionalProps: {
                formatAmount
            },
            t
        })
    }, [includedColumns, t]);

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        date: {
            onSearch: handleDateSearchChange,
            placeholder: t('backoffice.common.date'),
            showTime: true,
            allowClear: false,
            loadFn: getAggregatedData,
            enabledMountsCount: 3,
            disabledRanges: [DATE_PICKER_RANGES.YTD],
            value: [filters.from, filters.to]
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        breadcrumbs: {
            items: [
                { title: t('backoffice.menu.aggregatedData') }
            ]
        },
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.menu.aggregatedData"),
            columns: columnsForExport,
            url: ApiUrls.EXPORT_AGGREGATED_DATA,
            filters: filters
        }
    }

    //#endregion
    
    return (
        <MainDashboardLayout
            header={headerPartsData}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={report}
                loadFn={getAggregatedData}
                sorting={sorting}
                setSortingFn={setAggregatedDataSorting}
                filters={filters}
                setFiltersFn={setAggregatedDataFilters}
                total={total}
                updateProps={[globalProjectId]}
                enableReload={true}
            />

        </MainDashboardLayout>
    )
}

/** AggregatedDataComponent propTypes
    * PropTypes
*/
AggregatedDataComponent.propTypes = {
    /** Redux state property, represents the aggregated data */
    report: PropTypes.arrayOf(aggregatedDataType),
    /** Redux state property, aggregated data sorting details */
    sorting: sortingType,
    /** Redux state property, aggregated data filters */
    filters: PropTypes.object,
    /** Redux state property, aggregated data total count */
    total: PropTypes.number,
    /** Redux state property, is true when loading aggregated data */
    isLoading: PropTypes.bool,
    /** Redux action to get aggregated data */
    getAggregatedData: PropTypes.func,
    /** Redux action to set aggregated data sorting details */
    setAggregatedDataSorting: PropTypes.func,
    /** Redux action to set aggregated data filters */
    setAggregatedDataFilters: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getAggregatedData: nextPage => {
            dispatch(getAggregatedData(nextPage))
        },
        setAggregatedDataSorting: sorting => {
            dispatch(setAggregatedDataSorting(sorting));
        },
        setAggregatedDataFilters: (filters, keepPage) => {
            dispatch(setAggregatedDataFilters(filters, keepPage));
        }
    }
)

const mapStateToProps = state => {
    return {
        report: state.aggregatedData.report,
        total: state.aggregatedData.total,
        sorting: state.aggregatedData.sorting,
        filters: state.aggregatedData.filters,
        isLoading: state.aggregatedData.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AggregatedDataComponent)