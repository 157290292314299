import {
    SET_USERS_ACTION_BEFORE,
    SET_USERS_ACTION_FINISH,
    SET_USERS,
    SET_USERS_SORTING,
    SET_USERS_FILTERS,
    SET_USERS_SAVE_ACTION_BEFORE,
    SET_USERS_SAVE_ACTION_FINISH,
    SET_USER_AVAILABLE_COMPANIES_BEFORE,
    SET_USER_AVAILABLE_COMPANIES_FINISH,
    SET_USER_AVAILABLE_COMPANIES,
    SET_USER_GENERAL_INFO,
    SET_USER_PERMISSION_GROUPS,
    SET_USER_AVAILABLE_PERMISSION_GROUPS_BEFORE,
    SET_USER_AVAILABLE_PERMISSION_GROUPS_FINISH,
    SET_USER_AVAILABLE_PERMISSION_GROUPS,
    SET_USER_PERMISSIONS,
    SET_USER_COMPANY_ACCESS
} from "../../actionTypes";

import { PERMISSION_ACTION } from 'core/constants/permission';


export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_USERS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_USERS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_USERS:
            return {
                ...state,
                users: !payload.add ? payload.users.item2 : state.users.concat(payload.users.item2),
                total: payload.users.item1,
            };

        case SET_USERS_FILTERS:
            return {
                ...state,
                filters: payload.filters,
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };
        case SET_USERS_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        case SET_USERS_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_USERS_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_USER_AVAILABLE_COMPANIES_BEFORE:
            return {
                ...state,
                availableCompanies: {
                    ...state.availableCompanies,
                    isLoading: true
                }
            }
        case SET_USER_AVAILABLE_COMPANIES_FINISH:
            return {
                ...state,
                availableCompanies: {
                    ...state.availableCompanies,
                    isLoading: false
                }
            }
        case SET_USER_AVAILABLE_COMPANIES:
            return {
                ...state,
                availableCompanies: {
                    ...state.availableCompanies,
                    availableCompanies: payload.companies
                }
            }
        case SET_USER_GENERAL_INFO:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    general: {
                        ...payload.info
                    }
                }
            }
        case SET_USER_PERMISSION_GROUPS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    permissionGroups: {
                        ...state.edit.permissionGroups,
                        groups: payload.permissionGroups
                    }
                }
            }
        case SET_USER_COMPANY_ACCESS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    companyAccess: {
                        ...payload.companyAccess
                    }
                }
            }
        case SET_USER_AVAILABLE_PERMISSION_GROUPS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    permissionGroups: {
                        ...state.edit.permissionGroups,
                        availableGroups: payload.data
                    }
                }
            }
        case SET_USER_AVAILABLE_PERMISSION_GROUPS_BEFORE:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    permissionGroups: {
                        ...state.edit.permissionGroups,
                        isAvailableGroupsLoading: true
                    }
                }
            }
        case SET_USER_AVAILABLE_PERMISSION_GROUPS_FINISH:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    permissionGroups: {
                        ...state.edit.permissionGroups,
                        isAvailableGroupsLoading: false
                    }
                }
            }
        case SET_USER_PERMISSIONS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    permissions: payload.permissions.filter(perm => perm.actions && perm.actions.some(ac => ac.action === PERMISSION_ACTION.VIEW))
                }
            }
        default:
            return state;
    }
};
