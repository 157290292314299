import React, { Fragment } from "react";

import Divider from "core/ui-kit/divider";
import Icon from "core/ui-kit/icon";
import Flag from "core/ui-kit/flag";

import languageCodeToISO from "core/constants/language/languageCodeToISO";

import { USER_ROLE } from "core/constants/auth";

import { PROFILE_DROPDOWN_TYPE } from "./constants";


export const profileDropdownMenus = ({ 
    languages,
    currencies,
    currency,
    currentUser,
    locale,
    t,
    isMobile
}) => ({
    [PROFILE_DROPDOWN_TYPE.DEFAULT] : [
        {
			key: "profile",
			label: (
				<span className="rt--title rt--font-normal rt--font-regular">
					{t("backoffice.common.profile")}
				</span>
			),
		},
        currentUser?.role !== USER_ROLE.ACCESS_MANAGER ? ({
            key: "currency",
			label: (
				<div className="rt--flex rt--width-full rt--align-center rt--justify-between">
                    <span className='rt--title rt--font-normal rt--font-regular'>
                        {`${t("backoffice.currencies.currency")}: `}
                        <strong className='rt--title rt--font-normal rt--font-regular rt--pl-4'>{currency}</strong>
                    </span>
                    <Icon name='right' />
                </div>
			),
        }) : null,
        {
			key: "language",
			label: (
                <div className="rt--flex rt--width-full rt--align-center rt--justify-between">
                    <span className='rt--title rt--font-normal rt--font-regular'>
                        {`${t("backoffice.languages.language")}: `}
                        <strong className='rt--title rt--font-normal rt--font-regular rt--pl-4'>{t(`backoffice.languages.${locale?.toLowerCase()}`)}</strong>
                    </span>
                    {
                        Object.keys(languages).length > 1 && (
                            <Icon name='right' />
                        )
                    }
                </div>
			),
		},
        {
			key: "divider",
            className: 'rt--divider-dropdown-menu-item',
            label: (
                <Divider className='rt--divider-dropdown' />
            )
		},
		{
			key: "logout",
			label: (
				<span className="rt--title rt--font-normal rt--font-regular">
					{t("backoffice.common.logout")}
				</span>
			),
		},
    ].filter(Boolean),

    [PROFILE_DROPDOWN_TYPE.CURRENCY] : [
        {
            key: "back",
            label: (
				<div className="rt--flex rt--align-center">
					<Icon name="left" className="rt--font-bigest" />
					<span className="rt--title rt--font-normal rt--font-bold rt--pl-4">
						{t("backoffice.currencies.currency")}
					</span>
				</div>
			),
            className:'rt--menu-item-back'
        },
        ...(
            currencies.map(curr => (
                {
                    key: `currency.${curr.currencyCode}`,
                    className: curr.currencyCode === currency ? "rt--dropdown-item-active" : "",
                    label: (
                        <Fragment>
                             <span className='rt--title rt--font-normal rt--font-regular'>
                                {curr.currencyCode}
                            </span>
                            {
                                isMobile && curr.currencyCode === currency && (
                                    <Icon name='ok' />
                                )
                            }
                        </Fragment>
                    ),
                }
            ))
        )
    ],

    [PROFILE_DROPDOWN_TYPE.LANGUAGE] : [
        {
            key: "back",
            label: (
				<div className="rt--flex rt--align-center">
					<Icon name="left" className="rt--font-bigest" />
					<span className="rt--title rt--font-normal rt--font-bold rt--pl-4">
						{t("backoffice.languages.language")}
					</span>
				</div>
			),
            className:'rt--menu-item-back'
        },
        ...(
            Object.keys(languages).map(language => (
                {
                    key: `language.${language}`,
                    className: language === locale ? "rt--dropdown-item-active" : "",
                    label: (
                        <Fragment>
                             <div className='rt--flex rt--align-center'>
                                <Flag code={languageCodeToISO[language]} />
                                <span className='rt--title rt--font-normal rt--font-regular rt--pl-8'>
                                    {t(`backoffice.languages.${language}`)}
                                </span>
                            </div>
                            {
                                isMobile && language === locale && (
                                    <Icon name='ok' />
                                )
                            }
                        </Fragment>
                    ),
                }
            ))
        )
    ]
})

export const buildAvatarPath = ({
    avatarId,
    companyLongId,
    projectLongId,
    userLongId,
    userRole
}) => {
    if(userRole === USER_ROLE.ADMIN || userRole === USER_ROLE.ACCESS_MANAGER){
        return `${import.meta.env.SYSTEM_CDN_URL}/companies/${companyLongId}/users/${userLongId}/${avatarId}_small.png`
    } else {
        return `${import.meta.env.SYSTEM_CDN_URL}/companies/${companyLongId}/projects/${projectLongId}/users/${userLongId}/${avatarId}_small.png`
    }
}