import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import { Drawer as AntDrawer, Menu } from "antd";

import Icon from "core/ui-kit/icon";
import Footer from "./footer";

import classNames from "core/helpers/common/classNames";

const DropdownMobile = ({
	children,
	onOpenChange,
	disabled,
	popupOpened,
	dropdownRender,
	footer,
	className,
	title,
	onClose,
	menu,
}) => {
	const [opened, setOpened] = useState(false);

    const scrollTop = useRef(0);

	const drawerClassName = classNames(
		"rt--modal rt--dropdown ",
		footer && "rt--dropdown-with-footer",
		className,
	);

	const handleClose = () => {
		if (onClose) {
			onClose();
		} else {
			setOpened(false);
			onOpenChange?.(false);
		}
	};

	const handleAfterOpenChange = (opened) => {
		onOpenChange?.(opened);
		setOpened(opened);
	};

	const renderContent = () => {
		let renderedMenu = null;

		if (dropdownRender || menu) {
			renderedMenu = (
                <Menu 
                    onClick={menu.onClick}
                    className={menu.className}
                    style={menu.style}
                    selectedKeys={menu.selectedKeys}
                >
                    {
                        menu.items.map(item => (
                            <Menu.Item
                                key={item.key}
                                className={item.className}
                                disabled={item.disabled}
                                style={item.style}
                            >
                                {item.label}
                            </Menu.Item>
                        ))
                    }
                </Menu>
            );

			if (dropdownRender) {
				return dropdownRender(renderedMenu);
			}

			return renderedMenu;
		}

		return null;
	};

    const openState = popupOpened !== undefined ? popupOpened : opened

    useEffect(() => {
        if(openState){
            scrollTop.current = document.documentElement.scrollTop;
            document.documentElement.classList.add("rt--no-scroll");
        } else {
            if(document.documentElement.classList.contains("rt--no-scroll")){
                document.documentElement.scrollTop = scrollTop.current;
                document.documentElement.classList.remove("rt--no-scroll");
            }
        }
    }, [openState])

    useEffect(() => () => {
        document.documentElement.classList.remove("rt--no-scroll");
    }, [])

	return (
		<>
			<div
				onClick={(e) => {
					if (!disabled) {
						e.stopPropagation();
						e.preventDefault();
						setOpened(true);
						onOpenChange && onOpenChange(true);
					}
				}}
			>
				{children}
			</div>
			<AntDrawer
				visible={popupOpened !== undefined ? popupOpened : opened} // Change in antd5
				className={"rt--modal " + drawerClassName}
				title={title}
				placement="bottom"
				closable={true}
				maskClosable={true}
				onClose={handleClose}
				closeIcon={(<Icon name='close' />)}
				getContainer={() =>
					document.getElementsByClassName("rt--portal-layout")[0] ||
					document.getElementsByClassName("rt--auth")[0]
				}
				afterVisibleChange={handleAfterOpenChange} // Change in antd5
				footer={footer ? <Footer {...footer} /> : undefined}
			>
				<div>{renderContent()}</div>
			</AntDrawer>
		</>
	);
};

DropdownMobile.propTypes = {
	popupOpened: PropTypes.bool,
	footer: PropTypes.shape({
		okText: PropTypes.string,
		cancelText: PropTypes.string,
		onOk: PropTypes.func,
		onCancel: PropTypes.func,
	}),
	dropdownRender: PropTypes.func,
	className: PropTypes.string,
	title: PropTypes.string,
	onClose: PropTypes.func,
	menu: PropTypes.object,
	onOpenChange: PropTypes.func,
	disabled: PropTypes.bool,
	children: PropTypes.node.isRequired,
};

export default DropdownMobile;
