import React from "react";

import { Navigate } from "react-router-dom";

import ROUTES from "core/constants/routes/routes";

import { isAuthenticatedSelector, useAuthSelector } from "core/stores/authStore";

/**
 * HOC which navigate to the login page if the user is not logged in.
 */
const withAnonymousHoc = (WrappedComponent, path = ROUTES.ROOT) => {
	return (props) => {
		const isAuthenticated = useAuthSelector(isAuthenticatedSelector);

		if (isAuthenticated) {
			return <Navigate to={path} />;
		}

		return <WrappedComponent {...props} />;
	};
};

export default withAnonymousHoc;