import React from "react";

import { Navigate } from "react-router-dom";

import usePermissions from "core/hooks/usePermission";
import { globalProjectIdSelector, useAuthSelector, userSelector } from "core/stores/authStore";
import { useGlobalSelector, userInfoSelector } from "core/stores/globalStore";

import { hasPageAccess } from "./helpers";

/**
 * HOC which navigate to available page if there is no access to page
 */
const withAccessHoc = (WrappedComponent, route) => {

	return (props) => {
        const permissionUtils = usePermissions();
        const projectId = useAuthSelector(globalProjectIdSelector);
        const user = useAuthSelector(userSelector);
        const userInfo = useGlobalSelector(userInfoSelector);

        const hasAccess = hasPageAccess({
            user, 
            permissionUtils, 
            projectId,
            userInfo, 
            route
        })

		if (!hasAccess) {
            return <Navigate to={"*"} />;
		}

		return <WrappedComponent {...props} />;
	};
};

export default withAccessHoc;