import { 
    SET_PROFILE_ACTION_BEFORE, 
    SET_PROFILE_ACTION_FINISH,
    SET_PROFILE_SAVE_ACTION_BEFORE, 
    SET_PROFILE_SAVE_ACTION_FINISH
} from "../../../actionTypes";

export const setProfileActionBefore = () => ({
    type: SET_PROFILE_ACTION_BEFORE
});

export const setProfileActionFinished = () => ({
    type: SET_PROFILE_ACTION_FINISH
});

export const setProfileSaveActionBefore = () => ({
    type: SET_PROFILE_SAVE_ACTION_BEFORE
})

export const setProfileSaveActionFinished = () => ({
    type: SET_PROFILE_SAVE_ACTION_FINISH
})