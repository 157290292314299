import React from "react";

import { Divider as AntDivider } from "antd";

const Divider = props => {
    return (
        <AntDivider 
            { ...props }
        />
    ) 
}

export default Divider;