import React from "react";

import Status from "core/ui-kit/status";

import { useTranslation } from "core/hooks/useTranslation";

import { COMMISSION_STATE_COLORS, COMMISSION_STATE_TRANSLATIONS } from "./constants";

const CommissionStatus = ({
    status
}) => {

    const { t } = useTranslation();

    return (
        <Status 
            color={ COMMISSION_STATE_COLORS[status] }
            text={ t(COMMISSION_STATE_TRANSLATIONS[status]) }
        />
    )
}

export default CommissionStatus;