import { message } from 'antd';

import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { 
    setNewsletterActionBefore, 
    setNewsletterActionFinished,
    setNewsletterSaveActionBefore,
    setNewsletterSaveActionFinished 
} from "./newsletter.action";

import { SET_NEWSLETTER_GENERAL_INFO } from "../../../../actionTypes";

const setNewsletterGeneralInfo = info => ({
    type: SET_NEWSLETTER_GENERAL_INFO,
    payload: { info }
})

export const getNewsletterGeneralInfo = id => {
    return dispatch => {
        dispatch(setNewsletterActionBefore());
        return axios({
            url: ApiUrls.GET_NEWSLETTER_GENERAL_INFO,
            method: Methods.GET,
            params: {id}
        })
        .then(({data : {value: info}}) => {
            dispatch(setNewsletterGeneralInfo(info))
            dispatch(setNewsletterActionFinished());
        })
        .catch((ex) => {
            dispatch(setNewsletterActionFinished());
        })
    }
}

export const saveNewsletterGeneralInfo = newsletter => {
    return dispatch => {
        dispatch(setNewsletterSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_NEWSLETTER_GENERAL_INFO,
            method: Methods.POST,
            data: {
                ...newsletter
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setNewsletterGeneralInfo(data.value));
            } 
            dispatch(setNewsletterSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setNewsletterSaveActionFinished());
        })
    }
}
