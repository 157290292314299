import ROUTES from "core/constants/routes/routes";

import AffiliateManagersEditComponent from "pages/userManagement/affiliateManagers/edit/affiliateManager-edit.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const AffiliateManagerEditPageRoute = {
	path: `${ROUTES.AFFILIATE_MANAGERS_EDIT}/:id`,
	element: AffiliateManagersEditComponent, 
    permission: [
        { resource: PERMISSION_RESOURCE.AFFILIATE_MANAGER_GENERALINFO, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.AFFILIATE_MANAGER_AFFILIATES, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.AFFILIATE_MANAGER_SESSIONS, action: PERMISSION_ACTION.VIEW }
    ]
};

export default AffiliateManagerEditPageRoute;
