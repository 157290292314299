import ROUTES from "core/constants/routes/routes";

import JobsComponent from "pages/developer/jobs/jobs.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const JobsPageRoute = {
	path: ROUTES.DEVELOPER_JOBS,
	element: JobsComponent, 
    permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW }
};

export default JobsPageRoute;
