import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { useTranslation } from "core/hooks/useTranslation";
import usePermissions from "core/hooks/usePermission";
import useDevice from "core/hooks/useDevice";

import Form from "core/ui-kit/form";
import Spin from "core/ui-kit/spin";
import { Row, Col } from "core/ui-kit/layout";

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";

import affiliateService from "core/services/apiServices/affiliateService";

import isFormChanged from 'core/helpers/form/isFormChanged';
import FormRulesBuilder from "core/helpers/common/formRulesBuilder";
import showSuccess from "core/helpers/common/showSuccess";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";
import { FORM_FIELD_NAMES } from "./constants";

const FeeComponent = () => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();
    const { isMobile } = useDevice();

    const [formInstance] = Form.useForm();
    const { setFieldsValue } = formInstance;

    const [isFormTouched, setIsFormTouched] = useState(false);

    const [ fees, setFees] = useState({})
    const [ isLoading, setIsLoading ] = useState(false)
    const [ isSaving, setIsSaving ] = useState(false)

    const urlParams = useParams();

    const hasModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE_FEES, PERMISSION_ACTION.MODIFY );

    useEffect(() => {
        setIsLoading(true);
        affiliateService.getAffiliateFees(urlParams.id)
            .then(value => {
                setFees(value);

                setFieldsValue({
                    [FORM_FIELD_NAMES.ENABLED]: value.enabled,
                    [FORM_FIELD_NAMES.SPORT_FEE]: value.sportFee,
                    [FORM_FIELD_NAMES.CASINO_FEE]: value.casinoFee
                })
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    const handleEnableFieldChange = value => {
        if(!value){
            setFieldsValue({
                [FORM_FIELD_NAMES.CASINO_FEE]: fees.casinoFee,
                [FORM_FIELD_NAMES.SPORT_FEE]: fees.sportFee,
            })
        }
    }

    const handleFormValuesChange = (_, values) => {
        const initialValues = {
            [FORM_FIELD_NAMES.ENABLED]: fees.enabled,
            [FORM_FIELD_NAMES.SPORT_FEE]: fees.sportFee,
            [FORM_FIELD_NAMES.CASINO_FEE]: fees.casinoFee,
        }

        const formValues = {
            [FORM_FIELD_NAMES.ENABLED]: values[FORM_FIELD_NAMES.ENABLED],
            [FORM_FIELD_NAMES.SPORT_FEE]: values[FORM_FIELD_NAMES.SPORT_FEE],
            [FORM_FIELD_NAMES.CASINO_FEE]: values[FORM_FIELD_NAMES.CASINO_FEE],
        }

        setIsFormTouched(isFormChanged(initialValues, formValues))
    }

    const handleSave = () => {
        formInstance.submit();
    }

    const handleFormSubmit = formData => {
        setIsSaving(true);
        const d = {
            affiliateId: urlParams.id,
            enabled: formData[FORM_FIELD_NAMES.ENABLED],
            sportFee: formData[FORM_FIELD_NAMES.SPORT_FEE],
            casinoFee: formData[FORM_FIELD_NAMES.CASINO_FEE],
        }

        affiliateService.saveAffiliateFees(d)
            .then(({value, message}) => {
                setFees(value);
                setIsFormTouched(false);
                showSuccess(message)
            })
            .finally(() => setIsSaving(false))
    }

    return (
        <SubTabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleSave,
                        text: t("backoffice.common.save"),
                        enabled: hasModifyPermission,
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    form={formInstance}
                    onValuesChange={handleFormValuesChange}
                    onFinish={handleFormSubmit}
                >
                    <Row gutter={[40, 0]}>
                        <Col xs={24} sm={12} xl={8}>
                            <Row gutter={[16, 0]}>
                                <Col span={24}>
                                    <h4
                                        className={'rt--form-section-title rt--title rt--mb-16 rt--font-bold rt--font-biger' + (isMobile ? " rt--mt-8" : "")}>
                                        {
                                            t("backoffice.commissionplans.administrativeFee")
                                        }
                                    </h4>
                                    <div className='rt--mb-16'>
                                        <span className='rt--text-light rt--font-small rt--font-regular'>{t("backoffice.commissionplans.administrativeFeeInfo")}</span>
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={[16, 0]}>
                                <Col span={24}>
                                    <div className='rt--widget rt--pt-20 rt--pb-20 rt--pl-16 rt--pr-16 rt--mb-16'>
                                        <Form.Switch 
                                            label={<span>{t('backoffice.commissionplans.customForAffiliate')}</span>}
                                            name={FORM_FIELD_NAMES.ENABLED}
                                            onChange={handleEnableFieldChange}
                                        />
                                        <Form.Item shouldUpdate noStyle>
                                            {({ getFieldValue }) => {
                                                return (
                                                    <Form.Number 
                                                        label={t('backoffice.commissionplans.casinoFee')}
                                                        name={FORM_FIELD_NAMES.CASINO_FEE}
                                                        placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.casinoFee')}`}
                                                        disabled={
                                                            !hasModifyPermission ||
                                                            !getFieldValue(FORM_FIELD_NAMES.ENABLED)
                                                        }
                                                        maxLength={8}
                                                        suffix={<span className='rt--text-light rt--font-smallest'>%</span>}
                                                        rules={
                                                            new FormRulesBuilder(t)
                                                                .validator(
                                                                    value => {
                                                                        if (value === "" || value === null || value === undefined) {
                                                                            return Promise.reject(t('backoffice.validation.fieldRequired'))
                                                                        }
                                                                        if (Number(value) < 0 || Number(value) > 100) {
                                                                            return Promise.reject(t('backoffice.validation.mustBeBetween').replace("%X%", 0).replace("%Y%", 100))
                                                                        }
                                                                        return Promise.resolve();
                                                                    }
                                                                )
                                                                .build()
                                                        }
                                                    />
                                                )
                                            }}
                                        </Form.Item>
                                        <Form.Item shouldUpdate noStyle>
                                            {({ getFieldValue }) => {
                                                return (
                                                    <Form.Number 
                                                        label={t('backoffice.commissionplans.sportFee')}
                                                        name={FORM_FIELD_NAMES.SPORT_FEE}
                                                        placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.sportFee')}`}
                                                        disabled={
                                                            !hasModifyPermission ||
                                                            !getFieldValue(FORM_FIELD_NAMES.ENABLED)
                                                        }
                                                        maxLength={8}
                                                        suffix={<span className='rt--text-light rt--font-smallest'>%</span>}
                                                        rules={
                                                            new FormRulesBuilder(t)
                                                                .validator(
                                                                    value => {
                                                                        if (value === "" || value === null || value === undefined) {
                                                                            return Promise.reject(t('backoffice.validation.fieldRequired'))
                                                                        }
                                                                        if (Number(value) < 0 || Number(value) > 100) {
                                                                            return Promise.reject(t('backoffice.validation.mustBeBetween').replace("%X%", 0).replace("%Y%", 100))
                                                                        }
                                                                        return Promise.resolve();
                                                                    }
                                                                )
                                                                .build()
                                                        }
                                                        className="rt--form-item-without-margin"
                                                    />
                                                )
                                            }}
                                        </Form.Item>
                                        
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Form>
            </Spin>
        </SubTabFormDashboardLayout>
    )
}

export default FeeComponent;