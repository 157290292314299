import {
    SET_MEDIA_TOOLS_ACTION_BEFORE,
    SET_MEDIA_TOOLS_ACTION_FINISH,
    SET_MEDIA_TOOLS,
    SET_MEDIA_TOOLS_SORTING,
    SET_MEDIA_TOOLS_FILTERS,
    SET_MEDIA_TOOLS_SAVE_ACTION_BEFORE,
    SET_MEDIA_TOOLS_SAVE_ACTION_FINISH,
    SET_MEDIA_TOOLS_CREATION_RESULT
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_MEDIA_TOOLS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_MEDIA_TOOLS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_MEDIA_TOOLS_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_MEDIA_TOOLS_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_MEDIA_TOOLS:
            return {
                ...state,
                mediaTools: !payload.add ? payload.mediaTools.item2 : state.mediaTools.concat(payload.mediaTools.item2),
                total: payload.mediaTools.item1,
            };
        case SET_MEDIA_TOOLS_FILTERS:
            return {
                ...state,
                filters: payload.filters,
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };
        case SET_MEDIA_TOOLS_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        case SET_MEDIA_TOOLS_CREATION_RESULT:
            return {
                ...state,
                creationResult: payload.result,
            };
        default:
            return state;
    }
};
