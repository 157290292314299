import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Row, Col, Form } from 'antd';

import {
    getMediaTools,
    setMediaToolsFilters
} from 'store/actions/portal/marketingTools/mediaTools/mediaTools.actions';

import FiltersWrapper from "components/common/filters";
import Select from 'components/common/select';
import DateRangePicker from "components/common/dateRangePicker";

import useAutosuggestion from 'hooks/useAutosuggestion';

import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { MEDIA_TYPE } from 'constants/campaign.constants';
import { USER_ROLE } from 'constants/user.constants';

import { globalProjectIdSelector, useAuthSelector, userRoleSelector } from 'core/stores/authStore';

/** Media Tool Page Filters Component */
const Filters = ({
    setMediaToolsFilters,
    getMediaTools,
    filters
}) => {
    const { t } = useTranslation();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);

    const role = useAuthSelector(userRoleSelector);

    const [ campaigns, getCampaigns ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.CAMPAIGN, 
        autoGet: false
    });

    const [ brands, getBrands ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.BRAND, 
        autoGet: false
    });

    const [ affiliates, getAffiliates ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AFFILIATES,
        disable: role === USER_ROLE.AFFILIATE,
        autoGet: false
    });

    return (
        <FiltersWrapper
            loadFn={getMediaTools}
            setFiltersFn={setMediaToolsFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            datePickerFields={[
                { name: "date", time: false },
                { name: "modified", time: false },
            ]}
            searchFieldName={["nameOrId", "date"]}
            onInit={() => {
                getCampaigns();
                getBrands();
                getAffiliates?.();
            }}
        >
            <Row gutter={[16, 0]}>
                {role !== USER_ROLE.AFFILIATE && (
                    <Col span={24}>
                        <Form.Item
                            label={t('backoffice.campaigns.affiliateUsername')}
                            name="affiliateId"
                        >
                            <Select
                                options={affiliates.map(affiliate => ({
                                    value: affiliate.id,
                                    text: affiliate.name
                                }))}
                                placeholder={`${t("backoffice.common.select")} ${t('backoffice.campaigns.affiliateUsername')}`}
                                search={true}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            />
                        </Form.Item>
                    </Col>
                )}

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.campaigns.campaignName')}
                        name="campaignIds"
                    >
                        <Select
                            options={campaigns.map(campaign => ({
                                value: campaign.id,
                                text: campaign.name
                            }))}
                            isMultiple={true}
                            placeholder={`${t("backoffice.common.select")} ${t('backoffice.campaigns.campaignName')}`}
                            search={true}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.campaigns.brandName')}
                        name="brandIds"
                    >
                        <Select
                            options={brands.map(brand => ({
                                value: brand.id,
                                text: brand.name
                            }))}
                            isMultiple={true}
                            placeholder={`${t("backoffice.common.select")} ${t('backoffice.campaigns.brandName')}`}
                            search={true}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.campaigns.mediaType')}
                        name="type"
                    >
                        <Select
                            options={[
                                { value: null, text: t('backoffice.common.all') },
                                { value: MEDIA_TYPE.LINK, text: t("backoffice.campaigns.directLink") },
                                { value: MEDIA_TYPE.CREATIVE, text: t("backoffice.campaigns.creative") },
                            ]}
                            placeholder={`${t("backoffice.common.select")} ${t('backoffice.campaigns.mediaType')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.lastUpdateDate')}
                        name="modified"
                        className='rt--form-item-without-margin'
                    >
                        <DateRangePicker
                            showTime={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to set media tools filters */
    setMediaToolsFilters: PropTypes.func,
    /** Redux state property, media tools filters */
    filters: PropTypes.object,
    /** Redux action to get media tools */
    getMediaTools: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        getMediaTools: () => {
            dispatch(getMediaTools());
        },
        setMediaToolsFilters: filters => {
            dispatch(setMediaToolsFilters(filters))
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.mediaTools.filters,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);