import ROUTES from "core/constants/routes/routes";

import InvoicesComponent from "pages/payment/invoices/invoices.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const InvoicesPageRoute = {
	path: ROUTES.INVOICES,
	element: InvoicesComponent, 
    permission: { resource: PERMISSION_RESOURCE.PAYMENTS_INVOICES, action: PERMISSION_ACTION.VIEW }
};

export default InvoicesPageRoute;
