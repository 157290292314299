import ROUTES from "core/constants/routes/routes";

import SystemNotificationsComponent from "pages/communications/systemNotifications/systemNotifications.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const SystemNotificationsPageRoute = {
	path: ROUTES.SYSTEM_NOTIFICATIONS,
	element: SystemNotificationsComponent, 
    permission: { resource: PERMISSION_RESOURCE.COMMUNICATION, action: PERMISSION_ACTION.VIEW }
};

export default SystemNotificationsPageRoute;
