import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import AutoComplete from "components/common/autoComplete";
import Select from 'components/common/select';
import Input from 'components/common/input';

import { setUserLogsFilters, getUserLogs, getUserLogResources } from "store/actions/portal/userLogs/userLogs.action";

import useAutosuggestion from 'hooks/useAutosuggestion';
import useDate from 'hooks/useDate';

import { USER_LOGS_ACTION_TYPE } from "constants/userLog.constants"
import { MONTHS } from "constants/common.constants"
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

import { globalCompanyIdSelector, useAuthSelector } from 'core/stores/authStore';

/** User logs Page Filters Component */

const Filters = ({
    setUserLogsFilters,
    getUserLogs,
    getUserLogResources,
    resources,
    filters
}) => {
    const { t } = useTranslation();

    const globalCompanyId = useAuthSelector(globalCompanyIdSelector);

    const { dateService } = useDate();

    const YEARS = Array(dateService.getCurrentYMD().year - 2020 + 1).fill().map((_, idx) => 2020 + idx);

    const [ userNames, getUserNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.USER, 
        autoGet: false
    });

    return (
        <FiltersWrapper
            loadFn={getUserLogs}
            setFiltersFn={setUserLogsFilters}
            filters={filters}
            updateProps={[globalCompanyId]}
            onInit={() => {
                getUserNames();
                getUserLogResources();
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.userlogs.userNameOrId')}
                        name="userNameOrId"
                    >
                        <AutoComplete
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.userlogs.userNameOrId')}`}
                            items={userNames}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.userlogs.action')}
                        name="action"
                        className='rt--general-form-item'
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.userlogs.action')}`}
                    >
                        <Input
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.userlogs.action')}`}
                        />
                    </Form.Item>
                </Col>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.userlogs.resourceId')}
                        name="resourceId"
                        className='rt--general-form-item'
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.userlogs.resourceId')}`}
                    >
                        <Input
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.userlogs.resourceId')}`}
                        />
                    </Form.Item>
                </Col>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.userlogs.resource')}
                        name="resource"
                    >
                        <Select
                            options={[
                                { value: "", text: t('backoffice.common.all') },
                                ...Object.keys(resources).map(res => (
                                    { value: res, text: resources[res] }
                                ))
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.userlogs.resource')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.userlogs.actionType')}
                        name="actionType"
                    >
                        <Select
                            options={[
                                { value: "", text: t('backoffice.common.all') },
                                { value: USER_LOGS_ACTION_TYPE.CREATE, text: t('backoffice.userlogs.actionType1') },
                                { value: USER_LOGS_ACTION_TYPE.DELETE, text: t('backoffice.userlogs.actionType2') },
                                { value: USER_LOGS_ACTION_TYPE.ADD, text: t('backoffice.userlogs.actionType3') },
                                { value: USER_LOGS_ACTION_TYPE.REMOVE, text: t('backoffice.userlogs.actionType4') },
                                { value: USER_LOGS_ACTION_TYPE.EDIT, text: t('backoffice.userlogs.actionType5') },
                                { value: USER_LOGS_ACTION_TYPE.EXPORT, text: t('backoffice.userlogs.actionType6') },
                                { value: USER_LOGS_ACTION_TYPE.LOGIN, text: t('backoffice.userlogs.actionType7') },
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.userlogs.actionType')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.month')}
                        name="month"
                    >
                        <Select
                            options={
                                Object.values(MONTHS).map(m => (
                                    { value: m, text: t('backoffice.months.month' + m) }
                                ))
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.common.month')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.year')}
                        name="year"
                    >
                        <Select
                            options={
                                YEARS.map(y => (
                                    { value: y, text: y }
                                ))
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.common.year')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get user logs */
    getUserLogs: PropTypes.func,
    /** Redux action to set user logs filters */
    setUserLogsFilters: PropTypes.func,
    /** Redux state property, user logs filters */
    filters: PropTypes.object,
    /** Redux state property, user logs resources */
    resources: PropTypes.object,
    /** Redux action to get user logs resources */
    getUserLogResources: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getUserLogs: () => {
            dispatch(getUserLogs())
        },
        setUserLogsFilters: filters => {
            dispatch(setUserLogsFilters(filters))
        },
        getUserLogResources: () => {
            dispatch(getUserLogResources())
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.userLogs.filters,
        resources: state.userLogs.resources
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);