import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useParams } from "react-router-dom";

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import DateRangePicker from "components/common/dateRangePicker";
import NumericInput from 'components/common/numericInput';
import Select from 'components/common/select';

import { getCampaignCreatives, setCampaignCreativesFilters } from "store/actions/portal/marketingTools/campaigns/creatives.action";
import { CREATIVE_TYPE } from 'constants/campaign.constants';

/** Campaigns Page Creative Filters Component */
const Filters = ({
    setCampaignCreativesFilters,
    getCampaignCreatives,
    filters
}) => {
    const { t } = useTranslation();

    const searchParams = useParams();

    return (
        <FiltersWrapper
            loadFn={() => getCampaignCreatives(searchParams.id)}
            setFiltersFn={setCampaignCreativesFilters}
            filters={filters}
            datePickerFields={[
                { name: "date", time: false },
            ]}
            searchFieldName="nameOrId"
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.campaigns.creativeType')}
                        name="type"
                    >
                        <Select
                            options={[
                                { value: "", text: t('backoffice.common.all') },
                                { value: CREATIVE_TYPE.IMAGE, text: t('backoffice.campaigns.banner') },
                                { value: CREATIVE_TYPE.HTML, text: t('backoffice.campaigns.bannerHTML') }
                            ]}
                            placeholder={`${t("backoffice.common.select")} ${t('backoffice.campaigns.creativeType')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <div className='rt--flex rt--align-end rt--justify-between rt--form-item-inputs'>
                        <Form.Item
                            label={t('backoffice.campaigns.width')}
                            name="widthFrom"
                            className='rt--general-form-item rt--flex-equal rt--form-item-inputs-left'
                            data-placeholder={t('backoffice.common.from')}
                        >
                            <NumericInput
                                placeholder={t("backoffice.common.from")}
                            />
                        </Form.Item>
                        <Form.Item
                            label={" "}
                        >
                            <div className='rt--form-item-inputs-separator'>
                                <div className='rt--datepicker-separator' />
                            </div>
                        </Form.Item>
                        <Form.Item
                            label={" "}
                            name="widthTo"
                            className='rt--general-form-item rt--flex-equal rt--form-item-inputs-right'
                            data-placeholder={t("backoffice.common.to")}
                        >
                            <NumericInput
                                placeholder={t("backoffice.common.to")}
                            />
                        </Form.Item>
                    </div>
                </Col>
                <Col span={24}>
                    <div className='rt--flex rt--align-end rt--justify-between rt--form-item-inputs'>
                        <Form.Item
                            label={t('backoffice.campaigns.height')}
                            name="heightFrom"
                            className='rt--general-form-item rt--flex-equal rt--form-item-inputs-left'
                            data-placeholder={t('backoffice.common.from')}
                        >
                            <NumericInput
                                placeholder={t("backoffice.common.from")}
                            />
                        </Form.Item>
                        <Form.Item
                            label={" "}
                        >
                            <div className='rt--form-item-inputs-separator'>
                                <div className='rt--datepicker-separator' />
                            </div>
                        </Form.Item>
                        <Form.Item
                            label={" "}
                            name="heightTo"
                            className='rt--general-form-item rt--flex-equal rt--form-item-inputs-right'
                            data-placeholder={t("backoffice.common.to")}
                        >
                            <NumericInput
                                placeholder={t("backoffice.common.to")}
                            />
                        </Form.Item>
                    </div>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.common.creationDate')}
                        name="date"
                    >
                        <DateRangePicker
                            showTime={false}
                        />
                    </Form.Item>
                </Col>

            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get campaigns creatives */
    getCampaignCreatives: PropTypes.func,
    /** Redux action to set campaigns creatives filters */
    setCampaignCreativesFilters: PropTypes.func,
    /** Redux state property, campaigns creatives filters */
    filters: PropTypes.object
}

const mapDispatchToProps = dispatch => (
    {
        getCampaignCreatives: id => {
            dispatch(getCampaignCreatives(id))
        },
        setCampaignCreativesFilters: filters => {
            dispatch(setCampaignCreativesFilters(filters))
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.campaigns.edit.creatives.filters
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);