import * as signalR from '@microsoft/signalr';

class SignalRService{
    constructor(connectionURL, callback){
        this.connection = null;
        this.promise = null;
        this.connectionURL = connectionURL;
        this.callback = callback;
        this.additionalHandlers = [];
        this.connected = false;
        this.buildConnection();
    }

    buildConnection(){
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(this.connectionURL, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets
            })
            .build();

        SignalRService.connections.push(this);

        this.startConnection();

        this.connection.onclose(err => {
            if (err) {
                this.handleConnectionError()
            }
        })
    }

    startConnection() {
        if (!this.promise) {
            this.promise = this.connection.start()
                .then(() => {
                    this.connected = true;
                    this.callback && this.callback(this);
                    if(this.additionalHandlers.length > 0){
                        this.additionalHandlers.forEach(handler => handler())
                    }
                }).catch(() => {
                    this.handleConnectionError()
                });
        }
        return this.promise;
    }

    handleConnectionError(){
        setTimeout(() => {
            this.promise = null;
            this.startConnection();
        }, 5000);
    }

    getConnection(){
        return this.connection;
    }

    stopConnection(){
        this.connection.stop();
        this.additionalHandlers = [];
    }

    addHandler(handler){
        if(this.connected){
            handler();
        } else {
            this.additionalHandlers.push(handler);
        }
    }

    static connections = [];
}

export default SignalRService;