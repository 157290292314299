export const parseValue = (value) => {
	if (value === "undefined") {
		return undefined;
	}

	if (value === "") {
		return "";
	}

	return JSON.parse(value);
}
