import React from "react";

import useDevice from 'core/hooks/useDevice';

import classNames from "core/helpers/common/classNames";

const PoweredBy = () => {

    const { isMobile } = useDevice();

    const linkClassName = classNames(
        "rt--auth-link rt--font-smallest rt--font-extra-bold",
        isMobile ? "rt--ml-4": "rt--ml-8"
    )

    return (
        <div className='rt--auth-sidebar-powered'>
            <span className='rt--font-smallest rt--font-bold'>Powered by</span>
            <a className={linkClassName} href='https://centrivo.io/' target='blank'>
                Digitain.com
            </a>
        </div>
    )
}

export default PoweredBy;