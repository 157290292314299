import ROUTES from "core/constants/routes/routes";

import ProjectEditComponent from "pages/projects/edit/project-edit.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const ProjectEditPageRoute = {
	path: `${ROUTES.PROJECTS_EDIT}/:id`,
	element: ProjectEditComponent , 
    permission: [
        { resource: PERMISSION_RESOURCE.PROJECT_GENERALINFO, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.PROJECT_BRANDS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.PROJECT_PROMO, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.PROJECT_CURRENCIES, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.PROJECT_LANGUAGE_SETTINGS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.PROJECT_REGISTRATION_CONFIGURATION, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.PROJECT_PAYMENT_METHODS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.PROJECT_PAYOUT_SETTINGS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.PROJECT_REFERRAL_SETTINGS, action: PERMISSION_ACTION.VIEW }
    ]
};

export default ProjectEditPageRoute;
