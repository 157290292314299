import React from "react";
import PropTypes from 'prop-types';

import Icon from "core/ui-kit/icon";

import Tag from "../../tag";

import classNames from "core/helpers/common/classNames";
import isNull from "core/helpers/typeChecks/isNull";
import isUndefined from "core/helpers/typeChecks/isUndefined";
import { findOptionTextByValue } from "../../helpers";

const Selector = ({
    className,
    onClick,
    options,
    disabled,
    isMultiple,
    icon,
    placeholder,
    value,
    onTagClose
}) => {

    const optionWithNullValueExist = options.some(option => isNull(option.value));

    const showPlaceholderIfNull = !optionWithNullValueExist && isNull(value);

    const isMultipleSelectEmpty = isMultiple && value?.length === 0;

    const containerClassName = classNames(
        "rt--select-button rt--pl-12 rt--pr-8 rt--flex rt--align-center rt--justify-between",
        disabled && "rt--select-button-disabled",
        className
    )

    const handleClick = e => {
        e.stopPropagation();
        e.preventDefault();
        onClick()
    }

    const handleTagClose = ( e, selectedValue) => {
        e.stopPropagation();
        e.preventDefault();
        onTagClose(selectedValue)
    }

    const renderContent = () => {
        if( isUndefined(value) || showPlaceholderIfNull || isMultipleSelectEmpty){
            return (
                <span className="rt--title rt--font-normal rt--font-regular rt--select-button-placeholder">
                    {placeholder}
                </span>
            )
        }

        if(isMultiple){
            return (
                <div className="rt--flex rt--align-center rt--flex-equal rt--select-button-tags rt--mr-8">
                    {
                        value.map(selectedValue => (
                            <Tag
                                key={selectedValue}
                                label={findOptionTextByValue(options, selectedValue)}
                                disabled={disabled}
                                onClose={e => handleTagClose(e, selectedValue)}
                            />
                        ))
                    }
                </div>
            )
        }

        return (
            <div className="rt--flex rt--justify-between rt--align-center rt--flex-equal">
                <span className="rt--title rt--font-normal rt--font-regular rt--select-button-value">
                    {
                        findOptionTextByValue(options, value)
                    }
                </span>
            </div>
        )
    }

    return (
        <div
            className={containerClassName}
            onClick={handleClick}
        >
            {renderContent()}
            <Icon name={icon} />
        </div>
    )
}

Selector.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        subs: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        }))
    })),
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.bool]),
    placeholder: PropTypes.string,
    isMultiple: PropTypes.bool,
    onTagClose: PropTypes.func
}

export default Selector;