import React from 'react';

import { useTranslation } from "core/hooks/useTranslation";

import Icon from "core/ui-kit/icon";

const DefaultEmptyView = () => {
    const { t } = useTranslation();
    
    return (
        <div className="rt--table-empty rt--flex rt--flex-col rt--justify-center rt--align-center">
            <Icon name="empty" size={80}/>
            <h5 className="rt--font-bold rt--font-bigest rt--mb-6 rt--mt-16">
                {t('backoffice.common.noData')}
            </h5>
            <span className="rt--title rt--font-bold rt--font-normal">
                {t('backoffice.common.noDataFound')}
            </span>
        </div>
    );
}
 
export default DefaultEmptyView;