import React from "react";

import { makeTransactionText } from "utils/wallet";

import { WALLET_OPERATION_TYPE } from "constants/wallet.constants";

export const getTableColumns = additionalProps => {

    const { 
        formatAmount,
        t 
    } = additionalProps;

    return [
        {
            title: "backoffice.wallet.transactionId",
            dataIndex: "transactionId",
            copy: true,
            sorter: true,
            mobileLevel: 1,
            alwaysVisible: true,
        },
        {
            title: "backoffice.wallet.transactionType",
            dataIndex: "transactionType",
            sorter: true,
            mobileLevel: 2,
            render: value => makeTransactionText(value, t),
        },
        {
            title: "backoffice.wallet.transactionTime",
            dataIndex: "transactionTime",
            isDateTime: true,
            sorter: true,
            mobileLevel: 3,
        },
        {
            title: "backoffice.wallet.affiliateId",
            dataIndex: "affiliateId",
            copy: true
        },
        {
            title: "backoffice.wallet.affiliateUserName",
            dataIndex: "affiliateUserName",
            mobileLevel: 4,
            alwaysVisible: true,
        },
        {
            title: "backoffice.wallet.amount",
            dataIndex: "transactionAmount",
            sorter: true,
            mobileLevel: 5,
            render: (value, record) => {
                let decreased = false;
                const change = record.changes?.[0] || { before: 0, after: 0};
                if(
                    record.transactionType === WALLET_OPERATION_TYPE.REFERRAL_CALCULATION || 
                    record.transactionType === WALLET_OPERATION_TYPE.COMMISSION_CALCULATION || 
                    record.transactionType === WALLET_OPERATION_TYPE.COMMISSION_RECALCULATION
                ){
                    if(change.before > change.after){
                        decreased = true
                    }
                } 

                return (
                    <span className={decreased ? "rt--error-text" : "rt--success-text"}>
                        {formatAmount(Math.abs(value), record.currencyCode)}
                    </span>
                )
            },
        },
        
        {
            title: "backoffice.wallet.currency",
            dataIndex: "currencyCode",
            sorter: true,
            mobileLevel: 6,
        },
        {
            title: "backoffice.wallet.createdBy",
            dataIndex: "createdBy"
        },
        {
            title: 'backoffice.wallet.notes',
            dataIndex: 'notes',
            short: true,
        }
    ]
};