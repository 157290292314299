import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";

import { setPerformanceReportsActionBefore, setPerformanceReportsActionFinished } from "./performance.action";

import {
    SET_AFFILIATE_REPORT,
    SET_AFFILIATE_REPORT_SORTING,
    SET_AFFILIATE_REPORT_FILTERS,
    SET_AFFILIATE_REPORT_TOTALS
} from "../../../../actionTypes";

const setAffiliateReport = (report, add) => ({
    type: SET_AFFILIATE_REPORT,
    payload: { report, add },
});

export const setAffiliateReportSorting = sorting => ({
    type: SET_AFFILIATE_REPORT_SORTING,
    payload: { sorting },
});

export const setAffiliateReportFilters = (filters, keepPage) => ({
    type: SET_AFFILIATE_REPORT_FILTERS,
    payload: { filters, keepPage },
});


export const getAffiliateReport = nextPage => {
    return (dispatch, getState) => {

        const affiliateReport = getState().performanceReports.affiliateReport;
        
        const page = nextPage === "first" ? 1 : nextPage === "next" ? affiliateReport.sorting.page + 1 : affiliateReport.sorting.page;
        
        let params = {
            ...affiliateReport.sorting,
            ...affiliateReport.filters,
            page: page
        };

        if(page > 1 && affiliateReport.total <= (page - 1 ) * affiliateReport.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && affiliateReport.total === 0){
            return Promise.resolve();
        }

        dispatch(setPerformanceReportsActionBefore());

        return axios({
            url: ApiUrls.GET_AFFILIATE_PERFORMANCE_REPORT,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: report } }) => {
                dispatch(setAffiliateReport(report, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setAffiliateReportSorting({ ...affiliateReport.sorting, page: page }));
                dispatch(setPerformanceReportsActionFinished());
            })
            .catch(() => {
                dispatch(setPerformanceReportsActionFinished());
            });
    }
}

const setAffiliateReportTotals = totals => ({
    type: SET_AFFILIATE_REPORT_TOTALS,
    payload: { totals },
});

export const getAffiliateReportTotals = () => {
    return (dispatch, getState) => {

        const affiliateReport = getState().performanceReports.affiliateReport;

        const filters = { ... affiliateReport.filters };
        
        let params = {
            ...filters
        };
        
        return axios({
            url: ApiUrls.GET_AFFILIATE_PERFORMANCE_REPORT_TOTALS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: totals } }) => {
                dispatch(setAffiliateReportTotals(totals));
            })
            .catch(ex => {
                console.log(ex)
            });
    }
}
