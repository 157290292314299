import httpService from "core/services/httpService";

import API_URL from "core/constants/api/apiUrl";

const commissionService = {
    getAffiliateCommissions(affiliateId, signal){
        return httpService.get({
            url: API_URL.GET_COMMISSION_PLANS,
            signal,
            queryData: { 
                affiliateId,
                page: 1,
                limit: 1000
            }
        }).then(({ data: { value } }) => {
            return {
                data: value.item2,
                total: value.item1
            };
        })
    },

    getNegativeCarryOver(id, signal){
        return httpService.get({
            url: API_URL.GET_AFFILIATE_NEGATIVE_CARRY_OVER,
            signal,
            queryData: { 
                id
            }
        }).then(({ data: { value } }) => {
            return value
        })
    },

    saveNegativeCarryOver(affiliateId, isNegativeCarryOverEnabled, signal){
        return httpService.post({
            url: API_URL.SAVE_AFFILIATE_NEGATIVE_CARRY_OVER,
            signal,
            requestBody: { 
                affiliateId, 
                isNegativeCarryOverEnabled
            }
        }).then(({ data: { value, message } }) => {
            return { value, message}
        })
    },

    removeCommission(id, affiliateId, signal){
        return httpService.delete({
            url: API_URL.REMOVE_COMMISSION_PLAN,
            signal,
            requestBody: { 
                id, 
                affiliateId
            }
        }).then(({ data: {  message } }) => {
            return { message}
        })
    },

    addCommission( data, signal){
        return httpService.post({
            url: API_URL.ADD_COMMISSION_PLAN,
            signal,
            requestBody: data
        }).then(({ data: {  message } }) => {
            return { message}
        })
    },

    getAffiliateAvailableCommissions(id, signal){
        return httpService.get({
            url: API_URL.GET_AVAILABLE_COMMISSION_PLANS,
            signal,
            queryData: { 
                id
            }
        }).then(({ data: { value } }) => {
            return value;
        })
    }
}

export default commissionService;