import React, { useEffect, useCallback, useState, useRef } from "react";

import { useTranslation } from "core/hooks/useTranslation";

import Modal from "core/ui-kit/modal";

import sessionManagerService from "core/services/sessionManagerService";
import tokenManagerService from "core/services/tokenManagerService";

import { authStore } from "core/stores/authStore";

const SessionExpireModal = () => {

    const { t } = useTranslation();

    const [showModal, setShowModal] = useState(false);

    const [leftSeconds, setLeftSeconds] = useState(null);

    const intervalRef = useRef(null)

    const handleSessionWarning = useCallback(() => {
        setShowModal(true);
        setLeftSeconds(10);
        intervalRef.current = setInterval(() => {
            setLeftSeconds(prevState => prevState - 1)
        }, 1000)
    }, [])

    const handleSessionEnd = useCallback(() => {
        authStore.logout()
    }, [])

    useEffect(() => {
        sessionManagerService.startSession();
        sessionManagerService.sessionEnd.subscribe(handleSessionEnd);
        sessionManagerService.sessionWarning.subscribe(handleSessionWarning);

        return () => {
            sessionManagerService.sessionEnd.unsubscribe(handleSessionEnd);
            sessionManagerService.sessionWarning.unsubscribe(handleSessionWarning);
            sessionManagerService.stopSession();
            clearInterval(intervalRef.current);
        }
    }, [])

    const handleOk = () => {
        tokenManagerService.updateToken();
		sessionManagerService.refresh();
        clearInterval(intervalRef.current);
        setLeftSeconds(null);
        setShowModal(false);
    }

    const handleCancel = () => {
        authStore.logout()
    }

    const textParts = t('backoffice.common.tokenExpireText').split("%X%")

	return (
        <Modal
            open={showModal}
            title={t('backoffice.common.tokenExpiration')}
            onOk={handleOk}
            onCancel={handleCancel}
            okText={t('backoffice.common.refresh')}
            cancelText={t('backoffice.common.logout')}
        >
            <div>
                <span>
                    {textParts[0]}
                    <b>{leftSeconds}</b>
                    {textParts[1]}
                </span>
            </div>
        </Modal>
    );
};

export default SessionExpireModal;