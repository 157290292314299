import { message } from 'antd';

import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { isMobile } from "utils/common";

import { 
    setAffiliateManagersActionBefore, 
    setAffiliateManagersActionFinished, 
    setAffiliateManagersSaveActionBefore, 
    setAffiliateManagersSaveActionFinished 
} from './affiliateManagers.action';

import { 
    SET_AFFILIATE_MANAGER_AFFILIATES,
    SET_AFFILIATE_MANAGER_AFFILIATES_SORTING,
    SET_AFFILIATE_MANAGER_AFFILIATES_FILTERS,
    SET_AFFILIATE_MANAGER_AVAILABLE_AFFILIATES 
} from "../../../../actionTypes";

const setAffiliateManagerAffiliates = (affiliates, add) => ({
    type: SET_AFFILIATE_MANAGER_AFFILIATES,
    payload: { affiliates, add }
})

const setAffiliateManagerAvailableAffiliates = affiliates => ({
    type: SET_AFFILIATE_MANAGER_AVAILABLE_AFFILIATES,
    payload: { affiliates }
})

export const setAffiliateManagerAffiliatesSorting = sorting => ({
    type: SET_AFFILIATE_MANAGER_AFFILIATES_SORTING,
    payload: { sorting },
});

export const setAffiliateManagerAffiliatesFilters = filters => ({
    type: SET_AFFILIATE_MANAGER_AFFILIATES_FILTERS,
    payload: { filters },
});

export const getAffiliateManagerAffiliates = (id, nextPage) => {
    return (dispatch, getState) => {
        const affiliatesData = getState().affiliateManagers.edit.affiliates;

        const page = nextPage === "first" ? 1 : nextPage === "next" ? affiliatesData.sorting.page + 1 : affiliatesData.sorting.page;
        
        let params = {
            ...affiliatesData.sorting,
            ...affiliatesData.filters,
            managerId: id
        };

        if(page > 1 && affiliatesData.total <= (page - 1 ) * affiliatesData.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && affiliatesData.total === 0){
            return Promise.resolve();
        }

        dispatch(setAffiliateManagersActionBefore());
        return axios({
            url: ApiUrls.GET_AFFILIATE_MANAGER_AFFILIATES,
            method: Methods.GET,
            params: params
        })
        .then(({data : {value: affiliates}}) => {
            dispatch(setAffiliateManagerAffiliates(affiliates, isMobile() && page !== 1));
            if (nextPage)
                dispatch(setAffiliateManagerAffiliatesSorting({ ...affiliatesData.sorting, page: page }));
            dispatch(setAffiliateManagersActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliateManagersActionFinished());
        })
    }
}

export const addAffiliateManagerAffiliate = (managerId, ids, onSuccess) => {
    return dispatch => {
        dispatch(setAffiliateManagersSaveActionBefore());
        return axios({
            url: ApiUrls.ADD_AFFILIATE_MANAGER_AFFILIATE,
            method: Methods.POST,
            data: { managerId, ids }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getAffiliateManagerAffiliates(managerId));
                onSuccess && onSuccess()
            } 
            dispatch(setAffiliateManagersSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliateManagersSaveActionFinished());
        })
    }
}

export const deleteAffiliateManagerAffiliate = (managerId, id) => {
    return dispatch => {
        dispatch(setAffiliateManagersSaveActionBefore());
        return axios({
            url: ApiUrls.REMOVE_AFFILIATE_MANAGER_AFFILIATE,
            method: Methods.DELETE,
            data: { managerId, id }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(getAffiliateManagerAffiliates(managerId));
            } 
            dispatch(setAffiliateManagersSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliateManagersSaveActionFinished());
        })
    }
}

export const getAffiliateManagerAvailableAffiliates = id => {
    return dispatch => {

        return axios({
            url: ApiUrls.GET_AFFILIATE_MANAGER_AVAILABLE_AFFILIATES,
            method: Methods.GET,
            params: { id }
        })
        .then(({data : {value: affiliates}}) => {
            dispatch(setAffiliateManagerAvailableAffiliates(affiliates));
        })
        .catch((ex) => {
           console.log(ex)
        })
    }
}