import { lazy } from "react";

import Spin from "core/ui-kit/spin";

import withSuspenseHoc from "core/HOCs/withSuspense";

import ROUTES from "core/constants/routes/routes";

const PostbacksContainer = withSuspenseHoc(lazy(() => import("./postbacksContainer")), <Spin />);

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

import isPostbackEnabled from 'core/helpers/postback/isPostbackEnabled';


const PostbacksPageRoute = {
	path: ROUTES.POSTBACKS,
	element: PostbacksContainer, 
    permission: { resource: PERMISSION_RESOURCE.POSTBACK, action: PERMISSION_ACTION.VIEW },
    isProjectRequired: true,
    isDisabled: ( projectId, user, userInfo ) => !isPostbackEnabled(projectId, user, userInfo)
};

export default PostbacksPageRoute;
