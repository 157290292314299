import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Row, Col, Form } from 'antd';

import {
    getCalculationApprovals,
    setCalculationApprovalsFilters
} from 'store/actions/portal/payment/calculationApprovals/calculationApprovals.action';

import useCurrencies from 'hooks/useCurrencies';

import FiltersWrapper from "components/common/filters";
import Select from "components/common/select";

import { COMMISSION_PLAN_TYPE } from 'constants/commission.constants';

import { globalProjectIdSelector, useAuthSelector } from 'core/stores/authStore';

/** Calculations Approvals Page Filters Component */
const Filters = ({
    setCalculationApprovalsFilters,
    getCalculationApprovals,
    filters
}) => {
    const { t } = useTranslation();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);

    const [ currencies, getCurrencies ] = useCurrencies(false);

    return (
        <FiltersWrapper
            loadFn={getCalculationApprovals}
            setFiltersFn={setCalculationApprovalsFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            datePickerFields={[
                { name: "date", time: true },
            ]}
            searchFieldName={["affiliateUserNameOrId", "date"]}
            onInit={() => {
                getCurrencies();
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.commissioncalculations.currency')}
                        name="currencyCode"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') }
                                ].concat(
                                    currencies.map(item => (
                                        { value: item.code, text: item.code }
                                    ))
                                )
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissioncalculations.currency')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.commissioncalculations.commissionType')}
                        name="type"
                        className='rt--form-item-without-margin'
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') },
                                    { value: COMMISSION_PLAN_TYPE.REVSHARE, text: t('backoffice.commissionplans.revshare') },
                                    { value: COMMISSION_PLAN_TYPE.CPA, text: t('backoffice.commissionplans.cpa') },
                                    { value: COMMISSION_PLAN_TYPE.FIX, text: t('backoffice.commissionplans.fix') }
                                ]
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissioncalculations.commissionType')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get calculation approvals */
    getCalculationApprovals: PropTypes.func,
    /** Redux action to set calculation approvals filters */
    setCalculationApprovalsFilters: PropTypes.func,
    /** Redux state property, calculation approvals filters */
    filters: PropTypes.object
}

const mapDispatchToProps = dispatch => (
    {
        getCalculationApprovals: () => {
            dispatch(getCalculationApprovals())
        },
        setCalculationApprovalsFilters: filters => {
            dispatch(setCalculationApprovalsFilters(filters));
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.calculationApprovals.filters
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);