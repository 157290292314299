import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { isMobile } from "utils/common";

import { 
    setCampaignsActionBefore, 
    setCampaignsActionFinished, 
    setCampaignsSaveActionBefore, 
    setCampaignsSaveActionFinished 
} from './campaigns.action';

import { 
    SET_CAMPAIGN_CREATIVES,
    SET_CAMPAIGN_CREATIVES_SORTING,
    SET_CAMPAIGN_CREATIVES_FILTERS,
    SET_CREATIVE_HTML_ACTION_BEFORE,
    SET_CREATIVE_HTML_ACTION_FINISH,
    SET_CREATIVE_HTML
} from "../../../../actionTypes";

const setCampaignCreatives = (creatives, add) => ({
    type: SET_CAMPAIGN_CREATIVES,
    payload: { creatives, add }
})


export const setCampaignCreativesSorting = sorting => ({
    type: SET_CAMPAIGN_CREATIVES_SORTING,
    payload: { sorting },
});

export const setCampaignCreativesFilters = filters => ({
    type: SET_CAMPAIGN_CREATIVES_FILTERS,
    payload: { filters },
});

export const getCampaignCreatives = (id, nextPage) => {
    return (dispatch, getState) => {
        const creativesData = getState().campaigns.edit.creatives;

        const page = nextPage === "first" ? 1 : nextPage === "next" ? creativesData.sorting.page + 1 : creativesData.sorting.page;
        
        let params = {
            ...creativesData.sorting,
            ...creativesData.filters,
            campaignId: id
        };

        if(page > 1 && creativesData.total <= (page - 1 ) * creativesData.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && creativesData.total === 0){
            return Promise.resolve();
        }

        dispatch(setCampaignsActionBefore());
        return axios({
            url: ApiUrls.GET_CAMPAIGN_CREATIVES,
            method: Methods.GET,
            params: params
        })
        .then(({data : {value: creatives}}) => {
            dispatch(setCampaignCreatives(creatives, isMobile() && page !== 1));
            if (nextPage)
                dispatch(setCampaignCreativesSorting({ ...creativesData.sorting, page: page }));
            dispatch(setCampaignsActionFinished());
        })
        .catch((ex) => {
            dispatch(setCampaignsActionFinished());
        })
    }
}

/** Load creative html */

const setCreativeHtmlActionBefore = () => ({
    type: SET_CREATIVE_HTML_ACTION_BEFORE,
});

const setCreativeHtmlActionFinished = () => ({
    type: SET_CREATIVE_HTML_ACTION_FINISH,
});

const setCreativeHtml = (id, data) => ({
    type: SET_CREATIVE_HTML,
    payload: { id, data}
});

export const getCreativeHTML = (id, onSuccess) => {
    return dispatch => {
        
        dispatch(setCreativeHtmlActionBefore());
        return axios({
            url: ApiUrls.GET_CAMPAIGN_CREATIVE_HTML,
            method: Methods.GET,
            params: { id }
        })
        .then(({data}) => {
            dispatch(setCreativeHtml(id, data.value));
            onSuccess && onSuccess(data.value)
            dispatch(setCreativeHtmlActionFinished());
        })
        .catch((ex) => {
            dispatch(setCreativeHtmlActionFinished());
        })
    }
}

export const createBannerCreative = (creative, onSuccess) => {
    return (dispatch) => {
        dispatch(setCampaignsSaveActionBefore());

        const formData = new FormData();

        Object.keys(creative).forEach((key) => {
            formData.append(key, creative[key]);
        });

        return axios({
            url: ApiUrls.CREATE_BANNER_CREATIVE,
            method: Methods.POST,
            headers: {
                "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
            },
            data: formData
        })
            .then(({ data }) => {
                dispatch(setCampaignsSaveActionFinished());
                if (isResponseSuccess(data)) {
                    dispatch(getCampaignCreatives(creative.campaignId));
                    onSuccess && onSuccess(data.value)
                }
            })
            .catch(() => {
                dispatch(setCampaignsSaveActionFinished());
            });
    };
};

export const createHTMLCreative = (creative, onSuccess) => {
    return (dispatch) => {
        dispatch(setCampaignsSaveActionBefore());
        return axios({
            url: ApiUrls.CREATE_HTML_CREATIVE,
            method: Methods.POST,
            data: {
                ...creative
            },
        })
            .then(({ data }) => {
                dispatch(setCampaignsSaveActionFinished());
                if (isResponseSuccess(data)) {
                    dispatch(getCampaignCreatives(creative.campaignId))
                    onSuccess && onSuccess(data.value)
                }
            })
            .catch(() => {
                dispatch(setCampaignsSaveActionFinished());
            });
    };
};

export const updateBannerCreative = (creative, onSuccess) => {
    return (dispatch) => {
        dispatch(setCampaignsSaveActionBefore());

        const formData = new FormData();

        Object.keys(creative).forEach((key) => {
            formData.append(key, creative[key]);
        });

        return axios({
            url: ApiUrls.SAVE_BANNER_CREATIVE,
            method: Methods.POST,
            headers: {
                "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
            },
            data: formData
        })
            .then(({ data }) => {
                dispatch(setCampaignsSaveActionFinished());
                if (isResponseSuccess(data)) {
                    dispatch(getCampaignCreatives(creative.campaignId));
                    onSuccess && onSuccess(data.value)
                }
            })
            .catch(() => {
                dispatch(setCampaignsSaveActionFinished());
            });
    };
};

export const updateHTMLCreative = (creative, onSuccess) => {
    return (dispatch) => {
        dispatch(setCampaignsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_HTML_CREATIVE,
            method: Methods.POST,
            data: {
                ...creative
            },
        })
            .then(({ data }) => {
                dispatch(setCampaignsSaveActionFinished());
                if (isResponseSuccess(data)) {
                    dispatch(setCreativeHtml(creative.id, data.HTML))
                    dispatch(getCampaignCreatives(creative.campaignId))
                    onSuccess && onSuccess(data.value)
                }
            })
            .catch(() => {
                dispatch(setCampaignsSaveActionFinished());
            });
    };
};

export const deleteCampaignCreative = (id, campaignId) => {
    return (dispatch) => {
        dispatch(setCampaignsSaveActionBefore());
        return axios({
            url: ApiUrls.DELETE_CAMPAIGN_CREATIVE,
            method: Methods.DELETE,
            data: {
                id
            },
        })
            .then(({ data }) => {
                dispatch(setCampaignsSaveActionFinished());
                if (isResponseSuccess(data)) {
                    dispatch(getCampaignCreatives(campaignId))
                }
            })
            .catch(() => {
                dispatch(setCampaignsSaveActionFinished());
            });
    };
};