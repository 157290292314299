import React  from 'react';

import { useTranslation } from "core/hooks/useTranslation";
import { connect } from 'react-redux';

import CheckboxesDropdown from "../checkboxesDropdown";

import { exportData } from 'store/actions/portal/export/export.action';

import { toUpperCaseFirstLetter } from "utils/common";
import { EXPORT_TYPE } from 'constants/common.constants';

import exportButtonType from 'types/components/exportButton.type';

/** Table Export Button Component */
const ExportButton = ({
    columns,
    tableName,
    url,
    exportData,
    filters,
    isPostRequest
}) => {
    const { t } = useTranslation();

    /** Function , do export
       * @function
       * @param {array} value - selected columns
       * @param {string} exportType - pdf/csv 
       * @memberOf ExportButton
   */
    const doExport = (value, exportType) => {
        const exportColumns = value.map(c => toUpperCaseFirstLetter(c));
        exportData(url, exportType, exportColumns, tableName, filters, isPostRequest)
    }

    return (
        <CheckboxesDropdown
            columns={columns.filter(c => !c.disable)}
            buttons={[
                { text: t('backoffice.common.pdf'), onClick: value => doExport(value, EXPORT_TYPE.PDF) },
                { text: t('backoffice.common.csv'), onClick: value => doExport(value, EXPORT_TYPE.CSV) }
            ]}
            buttonText={t('backoffice.common.export')}
            buttonClassName="rt--ml-16 rt--table-export-button"
            allowEmpty={false}
            selectAllByDefault={true}
        />
    )
}

/** ExportButton propTypes
    * PropTypes
*/
ExportButton.propTypes = exportButtonType;

const mapDispatchToProps = dispatch => (
    {
        exportData: (url, exportType, exportColumns, tableName, filters, isPostRequest) => {
            dispatch(exportData(url, exportType, exportColumns, tableName, filters, isPostRequest))
        }
    }
)

export default connect(
    null,
    mapDispatchToProps
)(
    ExportButton
);