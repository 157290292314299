import ROUTES from "core/constants/routes/routes";

import SystemPaymentsComponent from "pages/settings/systemPayments/systemPayments.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const SystemPaymentsPageRoute = {
	path: ROUTES.SETTINGS_PAYMENTS,
	element: SystemPaymentsComponent, 
    permission: { resource: PERMISSION_RESOURCE.SYSTEM_PAYMENT_METHODS, action: PERMISSION_ACTION.VIEW }
};

export default SystemPaymentsPageRoute;
