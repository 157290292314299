import { matchRoutes } from "react-router-dom";

import Icon from "core/ui-kit/icon";

import { USER_ROLE } from "core/constants/auth";
import { PATHS_TO_SHOW_COMPANIES_WITHOUT_PROJECT, PATHS_TO_SHOW_DROPDOWN_DISABLED, QUERY_PARAMS_TO_SHOW_DROPDOWN_DISABLED } from "./constants";

export const filterCompanies = (companies, location, userRole, companySearch) =>
	companies.filter(company => {

		const isAccessManager = userRole === USER_ROLE.ACCESS_MANAGER;
		const hasMatchedRoute = !!matchRoutes(
            PATHS_TO_SHOW_COMPANIES_WITHOUT_PROJECT.map((path) => ({ path })),
            location,
        ) 

		let showWithoutProject = isAccessManager || hasMatchedRoute;

		let shouldReturn =
			!companySearch ||
			company.id.toLowerCase() === companySearch.toLowerCase() ||
			company.name.toLowerCase().includes(companySearch.toLowerCase());

        const projects = company.projects ?? []

		if (!showWithoutProject) {
			shouldReturn &&= projects.length > 0;
		}

		return shouldReturn;
	});

export const filterProjectsBySearch = (projects, projectSearch) =>
    projects.filter(
        project =>
            !projectSearch ||
            project.id.toLowerCase() === projectSearch.toLowerCase() ||
            project.name.toLowerCase().includes(projectSearch.toLowerCase()),
    );

export const isDropdownDisabled = (location, params ) => {
    const { pathname, search } = location;
    let p = pathname;
    if(params.id){
        p = pathname.replace(params.id, ":id")
    }
    /** Check if there is a param in url search to disabled dropdown */
    if(search){
        const urlParams = new URLSearchParams(search);
        const hasDisabledQueryParam = QUERY_PARAMS_TO_SHOW_DROPDOWN_DISABLED.some(p => urlParams.has(p));
        if(hasDisabledQueryParam) return true;
    }

    return PATHS_TO_SHOW_DROPDOWN_DISABLED.includes(p)
}

export const companiesDropdownMenu = ({ companies = [], companyId, t, isMobile }) => {
    if (!companies.length) {
        return [
            {
                key: "notFound",
                className: "rt--companies-dropdown-empty",
                disabled: true,
                label: t("backoffice.common.notFound"),
            },
        ];
    }

    return companies.map(company => ({
        key: company.id,
        className: company.id === companyId ? "rt--companies-dropdown-menu-active" : "",
        label: (
            <>
                <span className="rt--title rt--font-regular rt--font-normal">{company.name}</span>
                {isMobile && company.id === companyId && (
                    <Icon name="ok" className="rt--font-bigest" />
                )}
            </>
        ),
    }));
};
    
export const projectsDropdownMenu = ({ projects = [], projectId, t, isMobile }) => {
    if (!projects.length) {
        return [
            {
                key: "notFound",
                className: "rt--companies-dropdown-empty",
                disabled: true,
                label: t("backoffice.common.notFound"),
            },
        ];
    }

    return projects.map(project => ({
        key: project.id,
        className: (
            project.id === projectId ? "rt--companies-dropdown-menu-active" : ""
        ) + (
            isMobile ? " rt--companies-dropdown-menu-nested" : ""
        ),
        label: (
            <>
                <span className="rt--title rt--font-regular rt--font-normal">{project.name}</span>
                {isMobile && project.id === projectId && (
                    <Icon name="ok" className="rt--font-bigest" />
                )}
            </>
        ),
    }));
};