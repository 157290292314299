import getQueryParams from "core/helpers/common/getQueryParams";

import browserLocalStorage from "core/services/storageService/localStorage";

import DEFAULT_LOCALE from "core/constants/language/defaultLocale";

const getLocaleFromStorage = () => browserLocalStorage.get("locale")

const getLocale = () => {
    const urlLocale = getQueryParams()?.language;

    return urlLocale || getLocaleFromStorage() || DEFAULT_LOCALE;
}

export default getLocale;