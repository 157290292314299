import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";

import { deleteNewsletterImage, updateNewsletterImage } from 'store/actions/portal/newsletterCustomize/image.action';

import ImageUploader from "components/common/imageUploader";

import ApiUrls from 'constants/api.constants';

import { getAPIUrls, isFunction } from 'utils/common';
import { IMAGE_TYPE } from 'constants/common.constants';

const getDefaultImgPath = (imgPath) => {
    if (Boolean(imgPath)) {
        return {
            url: `${import.meta.env.SYSTEM_CDN_URL}/${imgPath.toLowerCase()}`,
            status: "done",
            percent: 100
        }
    }

    return null;
}

const CustomizeImgUploader = ({
    name,
    id,
    imgPath,
    onSuccess,
    deleteNewsletterImage,
    updateNewsletterImage,
    ...restProps
}) => {
    const { t } = useTranslation();

    const handleSuccessfulUpload = (newImgPath) => {
        updateNewsletterImage(newImgPath, name, id);

        if (isFunction(onSuccess)) {
            onSuccess(newImgPath);
        }
    }

    const handleImgDeletion = () => {
        deleteNewsletterImage(name, id)
    }

    const requestBodyForUpload = {
        name,
        id
    }

    return (
        <ImageUploader
            {...restProps}
            uploadUrl={`${getAPIUrls().API_URL}${ApiUrls.UPLOAD_NEWSLETTER_TEMPLATE_FILE}`}
            data={requestBodyForUpload}
            size={1024*1024}
            disablePreview={true}
            onSuccess={handleSuccessfulUpload}
            defaultFile={getDefaultImgPath(imgPath)}
            updateProps={[imgPath]}
            fileBuilder={file => ({
                url: `${import.meta.env.SYSTEM_CDN_URL}/${file}`.toLowerCase()
            })}
            remove={{
                handler: handleImgDeletion
            }}
            acceptedFormats={[IMAGE_TYPE.JPEG, IMAGE_TYPE.PNG, IMAGE_TYPE.GIF, IMAGE_TYPE.WEBP]}
        />
    );
}

/** CustomizeImgUploader propTypes
 * PropTypes
*/
CustomizeImgUploader.propTypes = {
    /** Redux action to delete newsletter image */
    deleteNewsletterImage: PropTypes.func,
    /** Redux action to update newsletter image */
    updateNewsletterImage: PropTypes.func,
    /** Image URL */
    imgPath: PropTypes.string,
    /** File Name */
    name: PropTypes.string,
    /** Id */
    id: PropTypes.string,
    /** Fires when img successfully uploaded  */
    onSuccess: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
    deleteNewsletterImage: (name, id, onSuccess) => {
        dispatch(deleteNewsletterImage(name, id, onSuccess))
    },
    updateNewsletterImage: (imgPath, name, id) => {
        dispatch(updateNewsletterImage(imgPath, name, id))
    },
});


export default connect(null, mapDispatchToProps)(CustomizeImgUploader);