import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Sider } from 'core/ui-kit/layout';
import Icon from "core/ui-kit/icon";
import Button from "core/ui-kit/button";

import SidebarLogo from "./sidebarLogo";
import Menu from "./menu";
import Clock from "./clock";

import { useTranslation } from "core/hooks/useTranslation";


import useDevice from "core/hooks/useDevice";

import ROUTES from "core/constants/routes/routes";

import { authStore } from "core/stores/authStore";

import { useAuthSelector, userRoleSelector } from 'core/stores/authStore';

import { USER_ROLE } from "constants/user.constants";

import { COLLAPSED_SIDEBAR_WIDTH, SIDEBAR_WIDTH } from "../constants";

const Sidebar = () => {

    const { t } = useTranslation();

    const { isMobile } = useDevice();

    const [collapsed, setCollapsed] = useState(false);

    const isSidebarCollapsed = !isMobile && collapsed;

    const userRole = useAuthSelector(userRoleSelector);

    const navigate = useNavigate();

    const handleLogout = () => {
        authStore.logout();
    }

    const onWindowResize = useCallback(() => {
		setCollapsed(window.innerWidth < 1440);
	}, [setCollapsed]);

    const closeSidebar = () => {
        document.body.classList.remove("rt--body-open-sidebar")
    }

	useEffect(() => {
		if (!isMobile) {
			onWindowResize();
			window.addEventListener("resize", onWindowResize);

			return () => {
				window.removeEventListener("resize", onWindowResize);
			};
		}
	}, []);

    useEffect(() => {
		return () => {
            closeSidebar();
        };
	}, []);

    return (
        <Sider
			className="rt--sidebar"
			width={isMobile ? "100vw" : SIDEBAR_WIDTH}
			collapsedWidth={COLLAPSED_SIDEBAR_WIDTH}
			collapsed={isSidebarCollapsed}
		>
            <div className='rt--sidebar-content rt--pt-24 rt--flex rt--flex-col'>
                {
                    isMobile && (
                        <div
                            className='rt--sidebar-content-close'
                            onClick={closeSidebar}
                        >
                            <Icon name='close' />
                        </div>
                    )
                }
                <SidebarLogo 
                    isCollapsed={isSidebarCollapsed}
                />

                <Menu 
                    isCollapsed={isSidebarCollapsed}
                />
            
                {
                    isMobile && (
                        <div className='rt--sidebar-content-logout rt--pl-16 rt--pr-16 rt--mt-24'>
                            <div className='rt--sidebar-content-logout-separator rt--mb-32' />

                                {
                                    userRole === USER_ROLE.AFFILIATE ? 
                                    (
                                        <div 
                                            className="rt--sidebar-content-footer-guide rt--flex rt--align-center rt--mb-16" 
                                            onClick={() => {
                                                navigate(`${ROUTES.GUIDE}`); 
                                            }}
                                        >
                                            <Icon name='guide'/>
                                            <span className="rt--font-normal rt--font-regular rt--ml-8">{t('backoffice.common.userGuide')}</span>
                                        </div>
                                    ) :  null
                                }

                            <Button
                                className='rt--sidebar-content-logout-button'
                                onClick={handleLogout}
                            >
                                <Icon name='logout' />
                                <span className='rt--title rt--font-normal rt--font-regular'>{t('backoffice.common.logout')}</span>
                            </Button>
                        </div>
                    )
                }

                <div className='rt--sidebar-content-footer rt--flex rt--flex-col rt--pl-24 rt--pr-16 rt--pb-26'>

                {
                    !isMobile && userRole === USER_ROLE.AFFILIATE ? 
                    (
                        <div 
                            className="rt--sidebar-content-footer-guide rt--flex rt--align-center" 
                            onClick={() => {
                                navigate(`${ROUTES.GUIDE}`); 
                            }}
                        >
                            <Icon name='guide'/>
                            <span className="rt--font-normal rt--font-regular rt--ml-8">{t('backoffice.common.userGuide')}</span>
                        </div>
                    ) :  null
                }

                    <div className="rt--flex rt--justify-between rt--align-center ">
                        <Clock isCollapsed={isSidebarCollapsed} />
                        {
                            !isMobile && (
                                <div className='rt--sidebar-content-footer-collapse' onClick={() => setCollapsed(!isSidebarCollapsed)}>
                                    <Icon name={isSidebarCollapsed ? 'expand' : 'collapse'} size={20}/>
                                </div>
                            )
                        }
                    </div>

                </div>
            </div>
        </Sider>
    )
}

export default Sidebar;