import * as rdd from "react-device-detect";

import { MEDIA_FOR_MOBILE } from "core/constants/device";

const ReactDeviceDetectWrapper = {};


const detect = ({ userAgent }) => {
	const detectedValuesFromRdd = rdd.getSelectorsByUserAgent(userAgent);
	const matchesMediaMobile = window.matchMedia(MEDIA_FOR_MOBILE);
	const detectedValues = { ...detectedValuesFromRdd };

	// if (!detectedValues.isMobile && matchesMediaMobile.matches) {
	// 	detectedValues.isMobile = matchesMediaMobile.matches;
	// }
	return detectedValues;
}

Object.defineProperty(ReactDeviceDetectWrapper, "detect", {
	writable: false,
	configurable: true,
	enumerable: true,
	value: detect.bind(ReactDeviceDetectWrapper)
});

export default ReactDeviceDetectWrapper;
