import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getProjectAvailableLanguages } from 'store/actions/portal/common/common.action';

const selectProjectLanguages = state => state.common.projectAvailableLanguages;

import { globalProjectIdSelector, useAuthSelector } from 'core/stores/authStore';

const useLanguages = (autoCall = true) => {

    const globalProjectId = useAuthSelector(globalProjectIdSelector);


    //#region ------------------------------------------- REDUX --------------------------------------------//

    const dispatch = useDispatch();
    const languages = useSelector(selectProjectLanguages);

    //#endregion

    const fn = useCallback(() => {
        dispatch(getProjectAvailableLanguages());
    }, [globalProjectId])

    // Load Languages
    useEffect(() => {
        if (autoCall) {
            fn()
        }
    }, [globalProjectId, dispatch]);


    return [ languages, fn ];
}

export default useLanguages;