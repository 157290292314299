import ReactDeviceDetectWrapper from "core/lib/react-device-detect";

/**
 * @typedef {Object} DetectionInfo
 * @property {boolean} isSmartTV: isSmartTVType(device),
 * @property {boolean} isConsole: isConsoleType(device),
 * @property {boolean} isWearable: isWearableType(device),
 * @property {boolean} isEmbedded: isEmbeddedType(device),
 * @property {boolean} isMobileSafari: isMobileSafariType(browser) || getIPad13(),
 * @property {boolean} isChromium: isChromiumType(browser),
 * @property {boolean} isMobile: isMobileAndTabletType(device) || getIPad13(),
 * @property {boolean} isMobileOnly: isMobileType(device),
 * @property {boolean} isTablet: isTabletType(device) || getIPad13(),
 * @property {boolean} isBrowser: isBrowserType(device),
 * @property {boolean} isDesktop: isBrowserType(device),
 * @property {boolean} isAndroid: isAndroidType(os),
 * @property {boolean} isWinPhone: isWinPhoneType(os),
 * @property {boolean} isIOS: isIOSType(os) || getIPad13(),
 * @property {boolean} isChrome: isChromeType(browser),
 * @property {boolean} isFirefox: isFirefoxType(browser),
 * @property {boolean} isSafari: isSafariType(browser),
 * @property {boolean} isOpera: isOperaType(browser),
 * @property {boolean} isIE: isIEType(browser),
 * @property {boolean} osVersion: getOsVersion(os),
 * @property {boolean} osName: getOsName(os),
 * @property {boolean} fullBrowserVersion: getBrowserFullVersion(browser),
 * @property {boolean} browserVersion: getBrowserVersion(browser),
 * @property {boolean} browserName: getBrowserName(browser),
 * @property {boolean} mobileVendor: getMobileVendor(device),
 * @property {boolean} mobileModel: getMobileModel(device),
 * @property {boolean} engineName: getEngineName(engine),
 * @property {boolean} engineVersion: getEngineVersion(engine),
 * @property {boolean} getUA: getUseragent(ua),
 * @property {boolean} isEdge: isEdgeType(browser) || isEdgeChromiumType(ua),
 * @property {boolean} isYandex: isYandexType(browser),
 * @property {boolean} deviceType: getDeviceType(device),
 * @property {boolean} isIOS13: getIOS13(),
 * @property {boolean} isIPad13: getIPad13(),
 * @property {boolean} isIPhone13: getIphone13(),
 * @property {boolean} isIPod13: getIPod13(),
 * @property {boolean} isElectron: isElectronType(),
 * @property {boolean} isEdgeChromium: isEdgeChromiumType(ua),
 * @property {boolean} isLegacyEdge: isEdgeType(browser) && !isEdgeChromiumType(ua),
 * @property {boolean} isWindows: isWindowsType(os),
 * @property {boolean} isMacOs: isMacOsType(os),
 * @property {boolean} isMIUI: isMIUIType(browser),
 * @property {boolean} isSamsungBrowser: isSamsungBrowserType(browser)
 */

/**
 * Invoke to listen events for device detection
 * @callback DeviceDetectionListenInvoker
 * @returns {DeviceDetectionListenDisposer}
 */

/**
 * @callback GetDeviceDetectionInfo
 * @param {MediaQueryList} matchMedia
 * @returns {DetectionInfo}
 */

/**
 * @typedef {Object} DeviceDetectProvider
 * @property {GetDeviceDetectionInfo} detect: Get device detection Info
 */

class DeviceDetect {
	/**
	 * User agent
	 * @type {string}
	 */
	userAgent = null;

	/**
	 * Device detect provider
	 * @type {DeviceDetectProvider}
	 */
	provider = null;

	constructor(userAgent, provider) {
		this.userAgent = userAgent;
		this.provider = provider;
	}

	/**
	 * Get device detection info.
	 * @type {GetDeviceDetectionInfo}
	 */
	detect = () => {
		return this.provider.detect({ userAgent: this.userAgent });
	};
}

const DeviceDetectService = new DeviceDetect(window.navigator.userAgent, ReactDeviceDetectWrapper);

export default DeviceDetectService;
