import {
    SET_PASSWORD_SETTINGS_ACTION_BEFORE,
    SET_PASSWORD_SETTINGS_ACTION_FINISH,
    SET_PASSWORD_SETTINGS
} from '../../actionTypes';

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_PASSWORD_SETTINGS_ACTION_BEFORE:
            return {
                ...state,
                passwordSettings: {
                    ...state.passwordSettings,
                    isLoading: true
                }
            }
        case SET_PASSWORD_SETTINGS_ACTION_FINISH:
            return {
                ...state,
                passwordSettings: {
                    ...state.passwordSettings,
                    isLoading: false
                }
            }
        case SET_PASSWORD_SETTINGS:
            return {
                ...state,
                passwordSettings: {
                    ...state.passwordSettings,
                    passwordSettings: payload.passwordSettings
                }
            }
        default:
            return state;
    }
}