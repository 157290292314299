import ROUTES from "core/constants/routes/routes";

import PerformanceReportsComponent from "pages/reports/performance";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const PerformanceReportsPageRoute = {
	path: ROUTES.PERFORMANCE_REPORTS,
	element: PerformanceReportsComponent, 
    permission: [
        { resource: PERMISSION_RESOURCE.REPORT_AFFILIATE_PERFORMANCE, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.REPORT_PLAYER_PERFORMANCE, action: PERMISSION_ACTION.VIEW }
    ]
};

export default PerformanceReportsPageRoute;
