import React, { useState } from "react";
import PropTypes from "prop-types";

import Icon from "core/ui-kit/icon";
import Modal from "core/ui-kit/modal";

import { WALLET_TYPE } from "core/constants/wallet";

import useFormat from "hooks/useFormat";


import { getAmount } from "../helpers";

const MobileCurrenciesDropdown = ({
    wallets,
    currencies,
    allowImpactOnCPA,
    selectedCurrency
}) => {
    const { formatAmount } = useFormat();

    const [ showModal, setShowModal] = useState(false);

    return (
		<div className="rt--header-actions rt--header-item">
            <div
                className='rt--header-actions rt--header-actions-content-icon rt--header-item rt--pr-16 rt--mr-16 rt--flex rt--align-center'
                onClick={() => setShowModal(true)}
            >
                <Icon name='wallet' />
            </div>
            
            <Modal
                open={showModal}
                okButtonProps={{ hidden: true }}
                cancelButtonProps={{ hidden: true }}
                onCancel={() => setShowModal(false)}
            >
                <div className='rt--header-actions-wallet-table'>
                    {
                        currencies.map(currency => (
                            <div className='rt--header-actions-wallet-table-item rt--mb-8 rt--pl-12 rt--pr-12 rt--pt-12 rt--pb-12' key={currency.currencyCode}>
                                <b className='rt--title rt--font-big rt--font-extra-bold'>{currency.currencyCode}</b>
                                <div className='rt--flex rt--align-center rt--justify-between rt--mt-8'>
                                    {
                                        wallets.map(wallet => (
                                            <div 
                                                className={'rt--header-actions-wallet-table-item-wallets rt--pr-4' + (wallets.length === 1 ? " rt--header-actions-wallet-table-item-wallets-single" : " ")} 
                                                key={wallet.type}
                                            >
                                                <div 
                                                    className='rt--header-actions-wallet-table-item-wallet-title rt--flex rt--flex-equal rt--align-center'
                                                >
                                                    <span className='rt--title rt--font-small rt--font-regular'>{wallet.title}</span>
                                                </div>
                                                <div
                                                    className='rt--header-actions-wallet-table-item-wallet rt--flex rt--flex-equal rt--align-center'
                                                >
                                                    <span className='rt--title rt--font-normal rt--font-bold'>
                                                        {
                                                            formatAmount(
                                                                getAmount(wallet.type, selectedCurrency, currencies, allowImpactOnCPA), 
                                                                currency.currencyCode
                                                            )
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                        ))
                    }
                </div>
            </Modal>
        </div>
	);
}

MobileCurrenciesDropdown.propTypes = {
	selectedCurrency: PropTypes.string,
	currencies: PropTypes.arrayOf(PropTypes.object),
	allowImpactOnCPA: PropTypes.bool,
	wallets: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			type: PropTypes.oneOf(Object.values(WALLET_TYPE)),
		}),
	)
};

export default MobileCurrenciesDropdown