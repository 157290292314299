import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";

import Tooltip from "components/common/tooltip";
import Icon from "components/common/icon";

import { classNames, isFunction, copyToClipboard } from "utils/common";

import { COPY_SIZES } from '../../constants';

const ColumnWithCopyButton = ({
    value,
    onClick,
    size = COPY_SIZES.DEFAULT
}) => {
    const { t } = useTranslation();

    const handleInternalClick = (text, e) => {
        e.stopPropagation();

        copyToClipboard(text, t);

        if (isFunction(onClick)) {
            onClick(text, e);
        }
    }

    return (
        <div
            className={classNames(
                "rt--table-col",
                "rt--flex",
                "rt--align-center",
                "rt--table-col-copy",
                size === COPY_SIZES.MIDDLE && "rt--table-col-copy-large"
            )}
        >
            <span
                className="rt--title rt--font-normal rt--font-regular rt--flex-equal"
                title={value}
            >
                {value}
            </span>
            <div
                className="rt--table-col-button rt--ml-8"
                onClick={(e) => handleInternalClick(value, e)}
            >
                <Tooltip title={t("backoffice.common.copy") + " ID"}>
                    <Icon name="copy" />
                </Tooltip>
            </div>
        </div>
    );
}

/** ColumnWithCopyButton propTypes
 * PropTypes
*/
ColumnWithCopyButton.propTypes = {
    onClick: PropTypes.func,
    size: PropTypes.string,
    value: PropTypes.node
}

export default ColumnWithCopyButton;