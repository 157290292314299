import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Form, Row, Col, Spin, Radio } from 'antd';

import useDate from 'hooks/useDate';

import TabFormDashboardLayout from "components/layouts/tab/form";
import Select from "components/common/select";

import { getFormatSettings, saveFormatSettings } from 'store/actions/portal/profile/formatSettings.action';

import { isFormChanged } from "utils/form";

import { DATE_FORMAT, TIME_FORMAT, NUMBER_FORMAT } from 'constants/common.constants';

import formatSettingsType from 'types/profile/formatSettings.type';

/** User Profile Page Format Settings Component */
const FormatSettingsComponent = ({
    getFormatSettings,
    saveFormatSettings,
    isSaving,
    isLoading,
    formatSettings,
    onTabChange,
}) => {
    const { t } = useTranslation();

    const { dateService } = useDate();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);

    /** Load user profile personal information */
    useEffect(() => {
        getFormatSettings()
    }, [])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            numberFormatting: formatSettings.numberFormatting,
            dateFormatting: formatSettings.dateFormatting,
            timeFormatting: formatSettings.timeFormatting
        });

    }, [formatSettings])

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    /** Fires when form submitted
       * @function
       * @memberOf PersonalInformationComponent
   */
    const handleForm = forceSave => {
        validateFields()
            .then(data => {
                saveFormatSettings(data);
                setIsFormTouched(false)
            }).catch(err => {
                console.log(err)
            })
    }

    return (
        <TabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        loading: isSaving,
                        disabled: !isFormTouched,
                        enabled: true
                    }
                ]
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({ ...formValues }, {
                        numberFormatting: formatSettings.numberFormatting,
                        dateFormatting: formatSettings.dateFormatting,
                        timeFormatting: formatSettings.timeFormatting
                    }))}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={t('backoffice.profile.numberFormat')}
                                name="numberFormatting"
                            >
                                <Select
                                    options={[
                                        { value: NUMBER_FORMAT.FORMAT_1, text: "1 234.5" },
                                        { value: NUMBER_FORMAT.FORMAT_2, text: "1,234.5" },
                                        { value: NUMBER_FORMAT.FORMAT_3, text: "1.234,5" },
                                        { value: NUMBER_FORMAT.FORMAT_4, text: "1 234,5" },
                                        { value: NUMBER_FORMAT.FORMAT_5, text: "1234.5" },
                                        { value: NUMBER_FORMAT.FORMAT_6, text: "1234,5" },
                                    ]}
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.profile.numberFormat')}`}
                                    getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={t('backoffice.profile.timeFormat')}
                                name="timeFormatting"
                                className='rt--form-item-inline rt--form-item-radio-buttons'
                            >
                                <Radio.Group
                                    options={[
                                        { label: t('backoffice.profile.24HourTime'), value: TIME_FORMAT._24_HOUR },
                                        { label: t('backoffice.profile.12HourTime'), value: TIME_FORMAT._12_HOUR }
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={t('backoffice.profile.dateFormat')}
                                name="dateFormatting"
                                className='rt--form-item-without-margin'
                            >
                                <Select
                                    options={[
                                        { value: DATE_FORMAT.FORMAT_1, text: dateService.toString(dateService.getNow(),"YYYY-MM-DD") },
                                        { value: DATE_FORMAT.FORMAT_2, text: dateService.toString(dateService.getNow(),"DD-MMM-YYYY") },
                                        { value: DATE_FORMAT.FORMAT_3, text: dateService.toString(dateService.getNow(),"DD.MM.YYYY") },
                                        { value: DATE_FORMAT.FORMAT_4, text: dateService.toString(dateService.getNow(),"DD/MM/YYYY") },
                                        { value: DATE_FORMAT.FORMAT_5, text: dateService.toString(dateService.getNow(),"MMM DD,YYYY") },
                                        { value: DATE_FORMAT.FORMAT_6, text: dateService.toString(dateService.getNow(),"MM/DD/YYYY") },
                                    ]}
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.profile.dateFormat')}`}
                                    getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            </Spin>
        </TabFormDashboardLayout>
    )
}

/** FormatSettingsComponent propTypes
    * PropTypes
*/
FormatSettingsComponent.propTypes = {
    /** Redux action to get profile format settings */
    getFormatSettings: PropTypes.func,
    /** Redux action to save profile format settings */
    saveFormatSettings: PropTypes.func,
    /** Redux state property, is true when profile format settings is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when profile format settings is loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the user profile format settings  */
    formatSettings: formatSettingsType,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        getFormatSettings: () => {
            dispatch(getFormatSettings());
        },

        saveFormatSettings: data => {
            dispatch(saveFormatSettings(data));
        }
    }
)

const mapStateToProps = state => {
    return {
        formatSettings: state.profile.formatSettings,
        isSaving: state.profile.isSaving,
        isLoading: state.profile.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormatSettingsComponent)