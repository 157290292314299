import { AFFILIATE_ACCESS_TYPE } from 'constants/affiliate.constants';

export const getTableColumns = additionalProps => {
    const {
        formatCurrencyWithSymbol,
        currencyCode,
        hasAccess,
        t 
    } = additionalProps; 

    return [
        {
            title: "backoffice.reports.views",
            dataIndex: "viewCount",
        },
        {
            title: "backoffice.reports.validClicks",
            dataIndex: "validClickCount",
        },
        {
            title: "backoffice.reports.invalidClicks",
            dataIndex: "invalidClickCount",
        },
        {
            title: "backoffice.reports.playerRegistrations",
            dataIndex: "playerRegistrationCount",
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.DEPOSIT_COUNT) && {
            title: "backoffice.reports.depositCount",
            dataIndex: "depositCount",
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.DEPOSIT_AMOUNT) &&  {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.depositAmount")),
            dataIndex: "depositAmount",
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.WITHDRAW_COUNT) && {
            title: "backoffice.reports.withdrawCount",
            dataIndex: "withdrawCount",
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.WITHDRAW_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.withdrawAmount")),
            dataIndex: "withdrawAmount",
        },

        hasAccess(AFFILIATE_ACCESS_TYPE.NET_DEPOSIT_AMOUNT) && {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.netDeposit")),
            dataIndex: "netDepositAmount",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.ggr")),
            dataIndex: "ggr",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.ngr")),
            dataIndex: "ngr",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.sportRevShare")),
            dataIndex: "sportRevShareAmount",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.casinoRevShare")),
            dataIndex: "casinoRevShareAmount",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.cpaAmount")),
            dataIndex: "cpaAmount",
        },

        {
            title: () => formatCurrencyWithSymbol(currencyCode, t("backoffice.reports.totalEarning")),
            dataIndex: "totalCommissionAmount",
        }
    ]
} 
