import React from 'react';

import { useDebugSelector } from '../../..';

const DebugTimestamp = ({timestamp}) => {
    const { initTimestamp } = useDebugSelector();

    const formatTimeDifference = (startTimestamp, endTimestamp) => {
        const diff = endTimestamp - startTimestamp;

        const milliseconds = diff % 1000;
        const totalSeconds = Math.floor(diff / 1000);
        const seconds = totalSeconds % 60;
        const totalMinutes = Math.floor(totalSeconds / 60);
        const minutes = totalMinutes % 60;
        const hours = Math.floor(totalMinutes / 60);

        const pad = (num, size) => {
            let s = "000" + num;
            return s.substr(s.length - size);
        };

        return `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}.${pad(milliseconds, 3)}`;
    }

    return (
        <div className="rt--debug-content-change-list-content-item-timestamp">
            {formatTimeDifference(initTimestamp, timestamp)}
        </div>
    );
};

export default DebugTimestamp;
