import React, { useMemo } from "react";
import PropTypes from 'prop-types';

import { Row, Col } from 'antd';
import { useTranslation } from "core/hooks/useTranslation";

import { isMobile, classNames } from "utils/common";

/** Affiliate Payment Method details Component */
const PaymentMethodDetails = ({
    details
}) => {
    const { t, locale } = useTranslation();

    const languageCode = locale.toUpperCase();

    /** Mapped translations for table */
    const translations = useMemo(() => {
        const result = {};
        const paymentTrs = details?.translations;
        if (!paymentTrs) return result;
        paymentTrs.forEach(tr => {
            result[tr.key?.toLowerCase()] = tr.translations?.find(t => t.languageCode === languageCode)?.text ?? tr.text
        })
        return result;
    }, [details])

    const requisitesObj = details?.paymentDetails ?? {};
    const requisites = Object.keys(requisitesObj);

    return (
        <Row className={classNames("rt--affiliate-payment-method-details", !isMobile() && "rt--pl-16")}>
            {
                requisites.length > 0 ? requisites.map(requisite => (
                    <Col className="rt--affiliate-payment-method-details-row" xs={24} sm={12} xl={6} key={requisite}>
                        <div className={classNames("rt--affiliate-payment-method-details-row-item rt--flex rt--align-center", !isMobile() && "rt--mb-8")}>
                            <b className="rt--affiliate-payment-method-details-row-item-title rt--font-normal rt--font-regular rt--text-light">{translations[requisite.toLowerCase()]}:</b>
                            <span 
                                className="rt--affiliate-payment-method-details-row-item-value rt--title rt--font-normal rt--font-bold rt--pl-8 rt--pr-8 rt--ellipsis"
                                title={requisitesObj[requisite]}
                            >
                                { requisitesObj[requisite] }
                            </span>
                        </div>
                    </Col>
                )) : <span className="rt--pt-16 rt--pb-16">{t("backoffice.affiliates.noDetails")}</span>
            }
        </Row>
    );
};

PaymentMethodDetails.propTypes = {
    details: PropTypes.object
};


export default PaymentMethodDetails;
