import ROUTES from "core/constants/routes/routes";

import CalculationApprovalsComponent from "pages/payment/calculationApprovals/calculationApprovals.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const CalculationApprovalsPageRoute = {
	path: ROUTES.CALCULATION_APPROVALS,
	element: CalculationApprovalsComponent, 
    permission: { resource: PERMISSION_RESOURCE.PAYMENTS_COMMISSION_CALCULATIONS, action: PERMISSION_ACTION.VIEW }
};

export default CalculationApprovalsPageRoute;
