export const PAGE_SIZE_OPTIONS = [10, 30, 50];

export const PAGINATION_ARROW = {
    PREV: "prev",
    NEXT: "next",
}

export const DEFAULT_UNIQUE_PROP = "id";

export const MOBILE_DETAILS_TABS = {
    FULL_VIEW: 1,
    DETAILS: 2
}
