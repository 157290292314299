import React, { useState, useEffect, useMemo, Fragment, forwardRef, useImperativeHandle } from "react";

import { useTranslation } from "core/hooks/useTranslation";
import useDate from "core/hooks/useDate";

import Spin from "core/ui-kit/spin";

import Filters from "../filters";
import Navigation from "../navigation";
import Details from "../details";

import { COMMISSION_ADD_FIELDS, COMMISSION_POPUP_MODE } from "../constants";

import commissionService from "core/services/apiServices/commissionService";
import Empty from "../empty";

const CommissionsPopupAdd = forwardRef(({
    affiliateId,
    setMode,
    onCommissionSelect
}, ref) => {

    const { t } = useTranslation();
    const { dateService } = useDate();

    const [isLoading, setIsLoading] = useState(false);
    const [commissions, setCommissions] = useState([]);
    const [selectedCommission, setSelectedCommission] = useState(null);

    const [filters, setFilters] = useState({
        [COMMISSION_ADD_FIELDS.CURRENCY_CODE] : "",
        [COMMISSION_ADD_FIELDS.COMMISSION_TYPE] : "",
        [COMMISSION_ADD_FIELDS.START_DATE] : dateService.getNow()
    })

    useEffect(() => {
        setIsLoading(true)
        commissionService.getAffiliateAvailableCommissions(affiliateId).
            then( value  => {
                setCommissions(value);
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    const onAssignButtonClick = () => {
        setIsLoading(true);
        commissionService.addCommission({
            startDate: filters[COMMISSION_ADD_FIELDS.START_DATE],
            id: selectedCommission.id,
            affiliateId
        }).then( ()  => {
            setMode(COMMISSION_POPUP_MODE.VIEW)
        })
        .finally(() => {
            setIsLoading(false)
        })
    }

    useImperativeHandle(ref, () => {
        return {
            onAssignButtonClick,
            canAssign: Boolean(selectedCommission)
        }
    }, [onAssignButtonClick, selectedCommission])

    const onFilterChange = change => {
        setFilters(prev => ({
            ...prev,
            ...change
        }))
    }

    const navigationItems = useMemo(() => {
        let results = [...commissions]
        if(filters[COMMISSION_ADD_FIELDS.COMMISSION_TYPE]){
            results = results.filter(res => res.type === filters[COMMISSION_ADD_FIELDS.COMMISSION_TYPE])
        }

        if(filters[COMMISSION_ADD_FIELDS.CURRENCY_CODE]){
            results = results.filter(res => res.currencyCode === filters[COMMISSION_ADD_FIELDS.CURRENCY_CODE])
        }

        return results;
    }, [filters, commissions])

    useEffect(() => {
        if(navigationItems.length === 0){
            setSelectedCommission(null)
        } else {
            if(selectedCommission){
                if(navigationItems.every(item => item.id !== selectedCommission.id)){
                    setSelectedCommission(navigationItems[0])
                }
            } else {
                setSelectedCommission(navigationItems[0])
            }
        }
    }, [navigationItems, selectedCommission])

    useEffect(() => {
        onCommissionSelect(selectedCommission)
    }, [selectedCommission])

    return (
        <div className="rt--commissions-add rt--flex rt--flex-col">
            <Filters 
                initialValues={filters}
                onChange={onFilterChange}
            />
            <Spin spinning={isLoading}>
                {
                    navigationItems.length > 0 ? (
                        <Fragment>
                            <Navigation 
                                items={ navigationItems }
                                selected={ selectedCommission }
                                onClick={ setSelectedCommission }
                                mode={ COMMISSION_POPUP_MODE.ADD }
                            />
                            <Details 
                                commission={selectedCommission}
                                mode={COMMISSION_POPUP_MODE.ADD}
                            />
                            
                        </Fragment>
                    ) : <Empty mode={COMMISSION_POPUP_MODE.ADD} />
                }
            </Spin>
            
        </div>
    )
})

export default CommissionsPopupAdd;