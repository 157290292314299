import React, { Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Form, Row, Col } from 'antd';

import Modal from "components/common/modal";
import Select from "components/common/select";
import NumericInput from 'components/common/numericInput';
import TextAreaInput from 'components/common/textAreaInput';
import ImageUploader from 'components/common/imageUploader';

import { createInvoice, getAvailablePayments, getPaymentTranslations } from "store/actions/portal/payment/invoices/invoices.action";

import { POPUP_SIZE } from 'constants/common.constants';
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { AMOUNT_REGEX } from 'constants/regex.constants';
import { USER_ROLE, USER_STATE } from 'constants/user.constants';

import useAutosuggestion from 'hooks/useAutosuggestion';
import useCurrencies from 'hooks/useCurrencies';

import { flagsToBinary } from 'utils/common';

import availablePaymentType from 'types/payment/availablePayment.type';
import translationType from 'types/translation/translation.type';

import { useGlobalSelector, userInfoSelector } from 'core/stores/globalStore';
import { useAuthSelector, userRoleSelector } from 'core/stores/authStore';

/** Invoice Creating Popup Component */
const InvoiceCreateComponent = ({
    isSaving,
    createInvoice,
    getAvailablePayments,
    availablePayments,
    getPaymentTranslations,
    paymentTranslations,
    onClose
}) => {
    const { t, locale } = useTranslation();

    const userInfo = useGlobalSelector(userInfoSelector);

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue, getFieldValue, getFieldError } = formInstance;

    const role = useAuthSelector(userRoleSelector)

    const [paymentId, setPaymentId] = useState(null);

    const [ currencies ] = useCurrencies();

    const selectedPayment = useMemo(() => availablePayments.find(payment => payment.id === paymentId), [paymentId, availablePayments])

    const [ affiliateNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AFFILIATES,
        actionParams: { state: flagsToBinary([
            USER_STATE.ACTIVE, USER_STATE.LOCKED_LOGIN, USER_STATE.LOCKED_2_FA, USER_STATE.EXPIRED, USER_STATE.BLOCKED, USER_STATE.DELETED
        ])}
    });

    const languageCode = locale.toUpperCase();

    // /** Mapped translations for table */
    const translations = useMemo(() => {
        const result = {};
        const paymentTrs = paymentTranslations[paymentId];
        if (!paymentTrs) return result;
        paymentTrs.forEach(tr => {
            result[tr.key] = tr.translations?.find(t => t.languageCode === languageCode)?.text ?? tr.text
        })

        return result;
    }, [paymentTranslations, paymentId])


    /** Fires when form submitted
       * @function
       * @memberOf InvoiceCreateComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                const requestParams = { ...data };
                if(role === USER_ROLE.AFFILIATE){
                    requestParams.affiliateId = userInfo.id;
                }
                createInvoice(
                    requestParams,
                    () => {
                        onClose();
                    });
            }).catch(ex => {
                console.log(ex)
            })
    }

    const changePayments = () => {
        const currentAffiliateId = role !== USER_ROLE.AFFILIATE ? getFieldValue("affiliateId") : userInfo.id;
        const currentCurrencyCode = getFieldValue("currencyCode");
        if (currentAffiliateId && currentCurrencyCode) {
            getAvailablePayments(currentAffiliateId, currentCurrencyCode)
        }
    }

    const calculateTransactionCommission = () => {
        if (!selectedPayment) {
            return null;
        }

        if (getFieldError("amount").length > 0) {
            return null;
        }
        const currencyCode = getFieldValue("currencyCode");
        const currency = currencies.find(c => c.code === currencyCode.toUpperCase());

        const percent = selectedPayment.paymentCommissionPercent;
        const amount = Number(getFieldValue("amount"));
        let paymentCommissionAmount = (amount * percent) / 100;
        paymentCommissionAmount = paymentCommissionAmount.toFixed(currency?.decimalCount ?? 2)

        return <em className='rt--text-light rt--font-small rt--font-regular'>{`${t("backoffice.invoices.transactionCommission")}: ${paymentCommissionAmount} ${(currency?.symbol ?? "")} (${percent}%)`}</em>
    }

    useEffect(() => {
        setFieldsValue(
            {
                "paymentId": null,
                "amount": ""
            }
        )
        setPaymentId(null)
    }, [availablePayments])

    useEffect(() => {
        const payment = availablePayments.find(payment => payment.id === paymentId);
        if (payment) {
            setFieldsValue({
                amount: payment.availableAmount
            })

            /** Load Translations */
            getPaymentTranslations(paymentId);
        }

    }, [paymentId])

    return (
        <Modal
            title={t('backoffice.invoices.createInvoice')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.create')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.BIG}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                }}
            >
                <Row gutter={[16, 0]}>
                    {
                        role !== USER_ROLE.AFFILIATE && (
                            <Col span={12}>
                                <Form.Item
                                    label={`${t('backoffice.invoices.affiliate')} *`}
                                    name="affiliateId"
                                    rules={
                                        [{ required: true, message: t('backoffice.validation.fieldRequired') }]
                                    }
                                >
                                    <Select
                                        options={
                                            affiliateNames.map(item => (
                                                { value: item.id, text: item.name }
                                            ))
                                        }
                                        placeholder={`${t('backoffice.common.select')} ${t('backoffice.invoices.affiliate')}`}
                                        getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                        search={true}
                                        onChange={changePayments}
                                    />
                                </Form.Item>
                            </Col>
                        )
                    }

                    <Col span={12}>
                        <Form.Item
                            label={`${t('backoffice.invoices.currency')} *`}
                            name="currencyCode"
                            rules={
                                [{ required: true, message: t('backoffice.validation.fieldRequired') }]
                            }
                        >
                            <Select
                                options={
                                    currencies.map(cur => (
                                        { value: cur.code.toUpperCase(), text: cur.code.toUpperCase() }
                                    ))
                                }
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.invoices.currency')}`}
                                onChange={changePayments}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item shouldUpdate>
                            {({ getFieldValue }) => {
                                return (
                                    <Form.Item
                                        label={`${t('backoffice.invoices.paymentMethod')} *`}
                                        name="paymentId"
                                        rules={
                                            [{ required: true, message: t('backoffice.validation.fieldRequired') }]
                                        }
                                        className={
                                            (role !== USER_ROLE.AFFILIATE && !getFieldValue("affiliateId") ) || !getFieldValue("currencyCode") ? 
                                            "rt--form-item-disabled" : ""
                                        }
                                    >
                                        <Select
                                            options={
                                                availablePayments.map(payment => (
                                                    { value: payment.id, text: payment.name }
                                                ))
                                            }
                                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.invoices.paymentMethod')}`}
                                            onChange={paymentId => setPaymentId(paymentId)}
                                            disabled={(role !== USER_ROLE.AFFILIATE && !getFieldValue("affiliateId") ) || !getFieldValue("currencyCode")}
                                        />
                                    </Form.Item>
                                )
                            }}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item shouldUpdate noStyle>
                            {() => {
                                return (
                                    <Form.Item
                                        label={`${t('backoffice.invoices.amount')} *`}
                                        name="amount"
                                        rules={[
                                            { required: true, message: t('backoffice.validation.fieldRequired') },
                                            { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (value === "" || value === null) return Promise.resolve();
                                                    const payment = availablePayments.find(payment => payment.id === paymentId);
                                                    if (!payment) return Promise.resolve();
                                                    const min = payment.minThreshold;
                                                    const max = payment.availableAmount;

                                                    if (Number(value) < min || Number(value) > max) {
                                                        return Promise.reject(t('backoffice.validation.mustBeBetween').replace("%X%", min).replace("%Y%", max))
                                                    }
                                                    return Promise.resolve();
                                                }
                                            })
                                        ]}
                                        className={"rt--general-form-item" + (!paymentId ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.invoices.amount')}`}
                                        help={calculateTransactionCommission()}
                                        validateFirst
                                    >
                                        <NumericInput
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.invoices.amount')}`}
                                            disabled={!paymentId}
                                        />
                                    </Form.Item>
                                )
                            }}
                        </Form.Item>
                    </Col>
                    {
                        selectedPayment?.paymentRequisiteDetails && Object.keys(selectedPayment?.paymentRequisiteDetails).length > 0 ? (
                            <Col span={24}>
                                <Form.Item>
                                    <div className='rt--info rt--pl-16 rt--pr-16 rt--pt-12 rt--pb-12'>
                                        {
                                            Object.keys(selectedPayment?.paymentRequisiteDetails).map(detail => (
                                                <div className='rt--flex rt--align-center rt--mb-4' key={detail}>
                                                    <span className='rt--text-light rt--font-normal rt--font-bold'>
                                                        {translations[detail.toLowerCase()]}:
                                                    </span>
                                                    <span className='rt--text-secondary rt--font-normal rt--font-bold rt--pl-4 rt--pr-4'>
                                                        {selectedPayment?.paymentRequisiteDetails[detail]}
                                                    </span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </Form.Item>
                            </Col>
                        ) : null
                    }

                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.invoices.note')}`}
                            name="note"
                            rules={[
                                { max: 150, message: t('backoffice.validation.fieldInvalid') },
                                { min: 2, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            validateFirst
                            className="rt--general-form-item"
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.invoices.note')}`}
                        >
                            <TextAreaInput
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.invoices.note')}`}
                                maxLength={150}
                                rows={4}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.invoices.screenshot')}`}
                            name="file"
                            className="rt--form-item-without-margin"
                        >
                            <ImageUploader
                                size={1024 * 1024 * 3}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

/** InvoiceCreateComponent propTypes
    * PropTypes
*/
InvoiceCreateComponent.propTypes = {
    /** Redux state property, is true when creating invoice request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to create invoice */
    createInvoice: PropTypes.func,
    /** Redux action to get available payments */
    getAvailablePayments: PropTypes.func,
    /** Redux action to get payment translations */
    getPaymentTranslations: PropTypes.func,
    /** Redux state property, available payments */
    availablePayments: PropTypes.arrayOf(availablePaymentType),
    /** Redux state property, payment Translations */
    paymentTranslations: PropTypes.objectOf(PropTypes.arrayOf(translationType)),
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        createInvoice: (invoice, onSuccess) => {
            dispatch(createInvoice(invoice, onSuccess));
        },

        getAvailablePayments: (affiliateId, currencyCode) => {
            dispatch(getAvailablePayments(affiliateId, currencyCode));
        },

        getPaymentTranslations: paymentId => {
            dispatch(getPaymentTranslations(paymentId));
        },
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.invoices.isSaving,
        availablePayments: state.invoices.availablePayments,
        paymentTranslations: state.invoices.paymentTranslations,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceCreateComponent)