import { USER_ROLE } from "core/constants/auth";
import POSTBACK_SETTING_TYPE from "core/constants/postback/settingsType";

import getCurrentProject from "../common/getCurrentProject";
import binaryToFlags from "../common/binaryToFlags";


const isPostbackLogsEnabled = ( projectId, user, userInfo ) => {
    if(!projectId || !Boolean(user) || !Boolean(userInfo)){
        return false
    }

    const currentProject = getCurrentProject(userInfo.companies, user.companyId, user.projectId);

    if(!currentProject) return false;

    if(!currentProject.postbackEnabled) return false;

    if(user.role !== USER_ROLE.AFFILIATE){
        return true;
    }
    const postbackSettings = binaryToFlags( Object.values(POSTBACK_SETTING_TYPE), userInfo.affiliatePostbackSettingType)

    return postbackSettings.includes(POSTBACK_SETTING_TYPE.LOGS)
}

export default isPostbackLogsEnabled;