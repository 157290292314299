export const DEFAULT_CAPTCHA_TOKEN = "34f530b1728d4b28a5d1e39bb92be946";

export const POPUP_SIZE = {
    SMALLEST: 340,
    SMALL: 446,
    MIDDLE: 500,
    BIG: 620,
    BIGER: 740,
    BIGEST: 910,
    EXTRA_BIG: 1200
}

export const EXPORT_TYPE = {
    CSV: 1,
    PDF: 2
}