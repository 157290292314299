import React from "react";

import Icon from "core/ui-kit/icon";

import TableActions from "./desktop/actions";
import ColumnHeaderTitle from "./columns/header/title";

import { PAGE_SIZE_OPTIONS, PAGINATION_ARROW } from "./constants";

import isFunction from "core/helpers/typeChecks/isFunction";

export const getRowClassName = ({
    rowIndex,
    clickable,
    expanded,
    isAlwaysExpended,
    rowClassName
}) => {
    const classList = []

    const isEvenRow = (rowIndex + 1) % 2 == 0

    if (isEvenRow) {
        classList.push("rt--table-row-even");
    } else {
        classList.push("rt--table-row-odd")
    }

    if(expanded){
        if(isAlwaysExpended){
            classList.push("rt--table-row-always-expended");
        } else {
            classList.push("rt--table-row-expended");
        }
    }

    if(clickable){
        classList.push("rt--table-row-clickable")
    }

    if(rowClassName){
        classList.push(rowClassName)
    }

    return classList;
}

/** Pagination */

const renderPaginationTotal = (total, range) => {
    return `${range[0]} - ${range[1]} of ${total}`
}

const renderPaginationItem = (_, item, originalElement) => {
    if (item === PAGINATION_ARROW.PREV) {
        return <Icon name="left" />
    }

    if (item === PAGINATION_ARROW.NEXT) {
        return <Icon name="right" />
    }

    return originalElement;
}

export const getPaginationData = ({ total, page, limit }) => {
    if(total === 0){
        return false;
    }
    return {
        total: total,
        current: page,
        pageSize: limit,
        showSizeChanger: true,
        defaultCurrent: 1,
        pageSizeOptions: PAGE_SIZE_OPTIONS,
        showTotal: renderPaginationTotal,
        hideOnSinglePage: limit === PAGE_SIZE_OPTIONS[0],
        itemRender: renderPaginationItem,
        suffixIcon: <Icon name="down" />
    }
}

/** ********** */

/** Expandable */

export const renderExpandedIcon = ({ expanded, onExpand, record }, rowExpandable) => {
    if(isFunction(rowExpandable)){
        if(!rowExpandable(record)){
            return null;
        }
    }

    return (
        <a
            onClick={e => {
                onExpand(record, e);
            }}
        >
            <Icon name={expanded ? "up" : "down"} />
        </a>
    )
}

/** ********* */

/** Columns */

export const mapTableColumnsForDesktop = ({
    columns,
    orderDirection,
    actions
}) => {
    const mappedColumns = columns.map(column => ({
        ...column,
        title: <ColumnHeaderTitle title={column.title} isSortable={column.sorter} orderDirection={orderDirection}/>
    }))

    if(Boolean(actions)){
        mappedColumns.push(
            {
                title: actions.title ? isFunction(actions.title) ? actions.title() : actions.title : null,
                dataIndex: "actions",
                className: "rt--table-col-actions-col",
                fixed: "right",
                render: (_, record) => <TableActions record={record} actions={actions.buttons} />,
            }
        )
    }

    return mappedColumns;
}

export const getMobileColumns = columns => {
    const filteredColumns = columns.filter(c => c.mobileConfigs?.order );
    filteredColumns.sort((a, b) => a.mobileConfigs.order > b.mobileConfigs.order ? 1 : -1);

    return filteredColumns;
}

/** ********* */

/** Actions */

export const splitActions = actions => {
    let visibleActions = [], dropdownActions = [];

    if(actions.length > 0){
        if(actions.length === 1){
            visibleActions = [...actions]
        } else {
            visibleActions = actions.filter(a => a.alwaysVisible);
            dropdownActions = actions.filter(a => !a.alwaysVisible);
        }
    }

    return {
        visibleActions,
        dropdownActions
    }
}