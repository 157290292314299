export const INPUT_TYPE = {
    TEXT: 1,
    PASSWORD: 2,
    SEARCH: 3,
    URL: 4,
    NUMBER: 5,
    TEXTAREA: 6
}

export const NUMBER_INPUT_FORMAT = {
    WHOLE: 1,
    MOBILE: 2,
    IP_ADDRESS: 3
}