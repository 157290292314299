//#region react
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useLocation, useNavigate } from "react-router-dom";
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";

import NewsletterCreateComponent from "./createNewsletter";
import Filters from "./filters.component";

//#endregion

//#region actions
import {
    getNewsletters,
    setNewslettersSorting,
    deleteNewsletter
} from 'store/actions/portal/communications/newsletter/newsletters.action';
//#endregion

//#region utils
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { addQueryStringParamsToUrl } from 'utils/queryString';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'core/constants/permission';
import { NEWSLETTERS } from 'constants/pageName.constants';
import { getTableColumns } from './columns';
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region types
import newsletterType from 'types/newsletter/newsletter.type';
import sortingType from 'types/common/sorting.type';
//#endregion

import { globalProjectIdSelector, useAuthSelector } from 'core/stores/authStore';
import usePermissions from 'core/hooks/usePermission';

/** Notifications Page Newsletters Tab List Component */
const NewsletterListComponent = ({
    newsletters,
    sorting,
    total,
    isLoading,
    getNewsletters,
    setNewslettersSorting,
    deleteNewsletter
}) => {
    const { t } = useTranslation();

    const { search, pathname, hash } = useLocation();
    const navigate = useNavigate();

    const permissionUtils = usePermissions();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);

    const [createPopup, setCreatePopup] = useState(false);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: NEWSLETTERS });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasCreatePermission = permissionUtils.has( PERMISSION_RESOURCE.COMMUNICATION_NEWSLETTER, PERMISSION_ACTION.CREATE )

    const hasDeletePermission = permissionUtils.has( PERMISSION_RESOURCE.COMMUNICATION_NEWSLETTER, PERMISSION_ACTION.DELETE )

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleRightArrowIconClick = record => {
        navigate(
            addQueryStringParamsToUrl(
                pathname, 
                search, 
                hash, 
                {
                    newsletterId: record.id,
                    newsletterName: record.name
                }
            )
        )
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded and columnsForExport are only needed in the desktop version.
    const {
        mainTableColumns,
        columnsThatCanBeIncluded
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            constructForInclude: true,
            includedColumns,
            additionalProps: {
                t
            },
            t
        })
    }, [includedColumns, t]);

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        button: {
            icon: "plus",
            type: "primary",
            onClick: () => setCreatePopup(true),
            text: t("backoffice.communication.createEmail"),
            enabled: hasCreatePermission
        }
    }

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    tableRowActions.push({
        title: t("backoffice.common.deepView"),
        icon: "right",
        onClick: handleRightArrowIconClick,
    })

    if (hasDeletePermission) {
        tableRowActions.push(
            {
                title: t('backoffice.common.delete'),
                confirmationMessage: t("backoffice.communication.doYouWantToDeleteNewsletter"),
                confirmationTitle: t("backoffice.common.delete"),
                icon: "trash",
                showConfirmation: true,
                className: "rt--button-danger",
                onClick: newsletter => deleteNewsletter(newsletter.id)
            },
        )
    }

    //#endregion

    return (
        <TabTableDashboardLayout
            header={headerPartsData}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={newsletters}
                loadFn={getNewsletters}
                sorting={sorting}
                setSortingFn={setNewslettersSorting}
                total={total}
                updateProps={[globalProjectId]}
                actions={tableRowActions}
            />
            {
                createPopup && (
                    <NewsletterCreateComponent
                        onClose={() => setCreatePopup(false)}
                    />
                )
            }
        </TabTableDashboardLayout>
    )
}

/** NewsletterListComponent propTypes
    * PropTypes
*/
NewsletterListComponent.propTypes = {
    /** Redux state property, represents the array of newsletters */
    newsletters: PropTypes.arrayOf(newsletterType),
    /** Redux state property, newsletters sorting details */
    sorting: sortingType,
    /** Redux state property, newsletters total count */
    total: PropTypes.number,
    /** Redux state property, is true when loading newsletters */
    isLoading: PropTypes.bool,
    /** Redux action to get newsletters */
    getNewsletters: PropTypes.func,
    /** Redux action to set newsletters sorting details */
    setNewslettersSorting: PropTypes.func,
    /** Redux action to delete newsletter */
    deleteNewsletter: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getNewsletters: nextPage => {
            dispatch(getNewsletters(nextPage))
        },
        setNewslettersSorting: sorting => {
            dispatch(setNewslettersSorting(sorting));
        },
        deleteNewsletter: id => {
            dispatch(deleteNewsletter(id))
        },
    }
)

const mapStateToProps = state => {
    return {
        newsletters: state.newsletter.newsletters.newsletters,
        total: state.newsletter.newsletters.total,
        sorting: state.newsletter.newsletters.sorting,
        isLoading: state.newsletter.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterListComponent)