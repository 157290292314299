import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";

import { setInvoicesActionBefore, setInvoicesActionFinished } from "./invoices.action";

import {
    SET_PENDING_INVOICES,
    SET_PENDING_INVOICES_SORTING,
    SET_PENDING_INVOICES_FILTERS
} from "../../../../actionTypes";

const setPendingInvoices = (invoices, add) => ({
    type: SET_PENDING_INVOICES,
    payload: { invoices, add },
});

export const setPendingInvoicesSorting = sorting => ({
    type: SET_PENDING_INVOICES_SORTING,
    payload: { sorting },
});

export const setPendingInvoicesFilters = filters => ({
    type: SET_PENDING_INVOICES_FILTERS,
    payload: { filters },
});


export const getPendingInvoices = nextPage => {
    return (dispatch, getState) => {

        const invoices = getState().invoices.pending;

        const filters = { ... invoices.filters };
        const sorting = { ... invoices.sorting };
        const total = invoices.total;
        const page = nextPage === "first" ? 1 : nextPage === "next" ? sorting.page + 1 : sorting.page;
        
        let params = {
            ...sorting,
            ...filters,
            page: page
        };

        if(page > 1 && total <= (page - 1 ) * sorting.limit){
            return Promise.resolve();
        }
        
        dispatch(setInvoicesActionBefore());
        return axios({
            url: ApiUrls.GET_PENDING_INVOICES,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: invoices } }) => {
                dispatch(setPendingInvoices(invoices, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setPendingInvoicesSorting({ ...sorting, page: page }));
                dispatch(setInvoicesActionFinished());
            })
            .catch((ex) => {
                dispatch(setInvoicesActionFinished());
            });
    }
}