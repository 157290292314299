import React from "react";
import PropTypes from "prop-types";

import { Modal as AntModal } from "antd";

import classNames from "core/helpers/common/classNames";

import { POPUP_SIZE } from "core/constants/common";

const ModalDesktop = ({
    children,
    visible,
    className,
    title,
    isLoading,
    footer,
    onOk,
    onCancel,
    okText,
    cancelText,
    size,
    closable,
    okButtonProps,
    cancelButtonProps,
    ...rest
}) => {

    const builtClassName = classNames('rt--modal', className);

    const okButton = {
        className: okButtonProps.className ? okButtonProps.className: "rt--button-primary",
        loading: isLoading,
        hidden: okButtonProps.hidden,
        disabled: okButtonProps.disabled
    }

    const cancelButton = {
        className: cancelButtonProps.className ? cancelButtonProps.className: "rt--button-ghost",
        type: "text",
        hidden: cancelButtonProps.hidden,
        disabled: cancelButtonProps.disabled
    }

    const noFooter = (!cancelText || cancelButtonProps.hidden) && (!okText || okButtonProps.hidden)

    const footerContent = footer ? footer : noFooter ? null : undefined

    return (
        <AntModal
            visible={visible}
            className={builtClassName}
            title={title}
            cancelText={cancelText}
            okText={okText}
            onOk={onOk}
            onCancel={onCancel}
            width={size}
            maskClosable={closable}
            closable={closable}
            okButtonProps={okButton}
            cancelButtonProps={cancelButton}
            centered
            getContainer={() => document.getElementsByClassName("rt--portal-layout")[0] || document.getElementsByClassName("App")[0]}
            footer={footerContent}
            destroyOnClose={true}
            {...rest}
        >
            {children}
        </AntModal>
    )
}

ModalDesktop.propTypes = {
    children: PropTypes.node.isRequired,
    visible: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    isLoading: PropTypes.bool,
    className: PropTypes.string,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    size: PropTypes.oneOf(Object.values(POPUP_SIZE)),
    okButtonProps: PropTypes.shape({
        hidden: PropTypes.bool,
        disabled: PropTypes.bool,
        className: PropTypes.string
    }),
    cancelButtonProps: PropTypes.shape({
        hidden: PropTypes.bool,
        disabled: PropTypes.bool,
        className: PropTypes.string
    }),
    closable: PropTypes.bool,
    footer: PropTypes.node,
}

export default ModalDesktop;