import React, { useRef, useCallback, useState } from 'react';

import { useTranslation } from "core/hooks/useTranslation";

import { Tabs as AntTabs } from 'antd';

import TabDashboardLayout from "components/layouts/tab";

import General from "./tabs/general";
import Player from './tabs/player';
import TopAffiliates from './tabs/topAffiliates';
import LastUpdated from '../components/lastUpdated';

import { getHashValue, isMobile, updateLocationHash } from 'utils/common';

import { OPERATIONAL_TAB_NAMES } from '../constants';
import { USER_ROLE } from 'constants/user.constants';

import useDate from 'hooks/useDate';

import { useAuthSelector, userRoleSelector } from 'core/stores/authStore';

const getActiveTab = () => {
    const tabKey = getHashValue("tab");

    return tabKey ? tabKey : OPERATIONAL_TAB_NAMES.GENERAL
}

const OperationalDashboard = () => {
    const { t } = useTranslation();

    const userRole = useAuthSelector(userRoleSelector);

    const { dateService } = useDate();

    const [lastUpdatedDate, setLastUpdatedDate] = useState(null);

    const activeTabRef = useRef(getActiveTab());

    const handleTabChange = (tab) => {
        updateLocationHash("tab=" + tab);
    }

    const updateLastUpdatedDate = useCallback((newDate) => {
        setLastUpdatedDate((prevState) => {
            if (lastUpdatedDate === null || dateService.isAfter(newDate, lastUpdatedDate)) {
                return newDate;
            }

            return prevState;
        })
    }, [])


    return (
        <TabDashboardLayout
            header={
                isMobile() ? { extra: <LastUpdated lastUpdatedDate={lastUpdatedDate} hasFilter={getActiveTab() !== OPERATIONAL_TAB_NAMES.GENERAL} /> } : undefined
            }
            breadcrumbs={{
                items: [{ title: t('backoffice.menu.operational') }]
            }}
        >
            <AntTabs
                animated={false}
                defaultActiveKey={activeTabRef.current}
                destroyInactiveTabPane={true}
                onChange={handleTabChange}
                className='rt--tabs rt--chart-tab'
                tabBarExtraContent={
                    !isMobile() ? <LastUpdated lastUpdatedDate={lastUpdatedDate} /> : undefined
                }
            >
                <AntTabs.TabPane
                    tab={<span>{t("backoffice.dashboard.general")}</span>}
                    key={OPERATIONAL_TAB_NAMES.GENERAL}
                >
                    <General updateLastUpdatedDate={updateLastUpdatedDate} />
                </AntTabs.TabPane>
                {
                    isMobile() && (
                        <AntTabs.TabPane
                            tab={<span>{t("backoffice.dashboard.player")}</span>}
                            key={OPERATIONAL_TAB_NAMES.PLAYER}
                        >
                            <Player updateLastUpdatedDate={updateLastUpdatedDate} />
                        </AntTabs.TabPane>
                    )
                }
                {
                    userRole !== USER_ROLE.AFFILIATE && (
                        <AntTabs.TabPane
                            tab={<span>{t("backoffice.dashboard.topAffiliates")}</span>}
                            key={OPERATIONAL_TAB_NAMES.TOP_AFFILIATES}
                        >
                            <TopAffiliates updateLastUpdatedDate={updateLastUpdatedDate} />
                        </AntTabs.TabPane>
                    )
                }
                
            </AntTabs>
        </TabDashboardLayout>
    )
}

export default OperationalDashboard;