import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import {
    getPromoLanguages,
    savePromoLanguages,
    setPromoDefaultLanguage,
} from 'store/actions/portal/promoCustomize/sections.action';

import Table from 'components/common/table';

import { tableColumnsCreator } from 'utils/tableColumnsCreator';

import { getTableColumns } from './columns';
import { useTranslation } from 'core/hooks/useTranslation';

const ROW_UNIQUE_KEY_PROP = "languageCode";

const LanguageConfig = ({
    languages,
    isLoading,
    isSaving,
    getPromoLanguages,
    savePromoLanguages,
    setPromoDefaultLanguage,
    defaultLanguageCode,
}) => {

    const { t } = useTranslation();

    const handleTableSwitcherChange = (isChecked, record) => {
        const requestBody = {
            languageCode: record.languageCode,
            enabled: isChecked
        }

        savePromoLanguages(requestBody);
    }

    const handleDefaultLanguageChange = useCallback((e, record) => {
        const isChecked = e.target.checked;

        if (!isChecked) {
            return;
        }

        const requestBody = {
            languageCode: record.languageCode
        }

        setPromoDefaultLanguage(requestBody)
    }, [])

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            additionalProps: {
                defaultLanguageCode,
                handleDefaultLanguageChange,
                t
            },
            t
        });
    }, [defaultLanguageCode, handleDefaultLanguageChange, t])

    useEffect(() => {
        getPromoLanguages();
    }, []);

    return (
        <Table
            loading={isLoading || isSaving}
            data={languages}
            columns={mainTableColumns}
            noPagination={true}
            uniqueKey={ROW_UNIQUE_KEY_PROP}
            switcher={{
                valueIndex: "enabled",
                dataIndex: "enabled",
                onChange: handleTableSwitcherChange,
                disabled: record => (record.languageCode === defaultLanguageCode.toLowerCase())
            }}
        />
    );
}

/** LanguageConfig propTypes
 * PropTypes
*/
LanguageConfig.propTypes = {
    languages: PropTypes.arrayOf(PropTypes.shape({
        languageCode: PropTypes.string,
        isActive: PropTypes.bool,
    })),
    /** Redux action to get promo languages */
    getPromoLanguages: PropTypes.func,
    /** Redux action to save promo language */
    savePromoLanguages: PropTypes.func,
    /** Redux action to set promo default language */
    setPromoDefaultLanguage: PropTypes.func,
    /** Redux state property, is true when loading portal customization data */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when promo saving */
    isSaving: PropTypes.bool,
    /** Redux state property, represents default language code */
    defaultLanguageCode: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
    getPromoLanguages: () => {
        dispatch(getPromoLanguages())
    },
    savePromoLanguages: (requestBody) => {
        dispatch(savePromoLanguages(requestBody))
    },
    setPromoDefaultLanguage: (requestBody) => {
        dispatch(setPromoDefaultLanguage(requestBody))
    },
});

const mapStateToProps = (state) => {
    return {
        languages: state.promoCustomize.languages,
        isLoading: state.promoCustomize.isLoading,
        defaultLanguageCode: state.promoCustomize.configuration.defaultLanguageCode,
        isSaving: state.promoCustomize.isSaving,
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(LanguageConfig);