import ROUTES from "core/constants/routes/routes";

import ErrorsComponent from "pages/developer/errors/errors.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const ErrorsPageRoute = {
	path: ROUTES.DEVELOPER_ERRORS,
	element: ErrorsComponent, 
    permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW }
};

export default ErrorsPageRoute;
