import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";

import Tabs from "components/common/tabs";

import GeneralInfoComponent from './sections/general';
import UrlsConfigComponent from './sections/urls';

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'core/constants/permission';

const BrandEditComponent = ({
    onTabChange
}) => {
    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.projects.general"),
            component: <GeneralInfoComponent/>
        },
        {
            title: t("backoffice.projects.brandUrls"),
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_BRAND_URLS, action: PERMISSION_ACTION.VIEW }],
            component: <UrlsConfigComponent />
        }
    ]

    return (
        <Tabs.SubTabs 
            items={items}
            onTabChange={onTabChange}
            isDeepView={true}
        />
    )
}

/** BrandEditComponent propTypes
    * PropTypes
*/
BrandEditComponent.propTypes = {
    /** Function fires on tab change */
    onTabChange: PropTypes.func
}

export default BrandEditComponent;