const findScrollableContainer = element => {
    let currentElement = element;

    while (currentElement) {
        const style = window.getComputedStyle(currentElement);
        const isScrollable =
            (style.overflow !== 'visible' && style.overflow !== 'hidden') ||
            (style.overflowY !== 'visible' && style.overflowY !== 'hidden') ||
            (style.overflowX !== 'visible' && style.overflowX !== 'hidden');

        if (isScrollable) {
            return currentElement;
        }

        currentElement = currentElement.parentElement; 
    }

    return null; 
}

export default findScrollableContainer;