import ROUTES from "core/constants/routes/routes";

import PermissionGroupsEditComponent from "pages/userManagement/permissionGroups/edit/permissionGroup-edit.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const PermissionGroupEditPageRoute = {
	path: `${ROUTES.PERMISSION_GROUPS_EDIT}/:id`,
	element: PermissionGroupsEditComponent, 
    permission: { resource: PERMISSION_RESOURCE.PERMISSION_GROUP, action: PERMISSION_ACTION.VIEW }
};

export default PermissionGroupEditPageRoute;
