import { useRef, useEffect } from "react";

import findScrollableContainer from "core/helpers/common/findScrollableContainer";

const useInfiniteScroll = ({
    callback=Function.prototype,
    dependencies=[],
    disabled=false
}) => {
    const elementRef = useRef(null);

    const scrollableElementRef = useRef(null);

    const handleScroll = () => {
        if (!Boolean(elementRef.current)) {
            return;
        }
        
        if(scrollableElementRef.current){
            if (Math.abs(scrollableElementRef.current.scrollHeight - scrollableElementRef.current.scrollTop - scrollableElementRef.current.clientHeight) < 1) {
                callback();
            }
        }
        
    }

    useEffect(() => {
        if(disabled || !elementRef.current) {
            return;
        }

        scrollableElementRef.current = findScrollableContainer(elementRef.current)
        if(scrollableElementRef.current){
            scrollableElementRef.current.addEventListener('scroll', handleScroll);
        }
        
        return () => {
            if(scrollableElementRef.current){
                scrollableElementRef.current.removeEventListener('scroll', handleScroll);
            }
        }
    }, [elementRef.current, disabled, ...dependencies])

    return { elementRef, scrollableElementRef};
}

export default useInfiniteScroll;