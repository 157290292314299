import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import {
    SET_INVOICES_TOTALS_ACTION_BEFORE,
    SET_INVOICES_TOTALS_ACTION_FINISH,
    SET_INVOICES_TOTALS
} from "../../../../actionTypes";

import { INVOICE_HISTORY_TYPE } from "constants/invoice.constants";

const setInvoicesTotalsActionBefore = () => ({
    type: SET_INVOICES_TOTALS_ACTION_BEFORE,
});

const setInvoicesTotalsActionFinished = () => ({
    type: SET_INVOICES_TOTALS_ACTION_FINISH
});

const setInvoicesTotals = (totals, type) => ({
    type: SET_INVOICES_TOTALS,
    payload: { totals, type }
});

export const getInvoicesTotals = type => {
    return (dispatch, getState) => {
        dispatch(setInvoicesTotalsActionBefore());

        const filters = type === INVOICE_HISTORY_TYPE.PENDING ? getState().invoices.pending.filters : getState().invoices.history.filters;

        return axios({
            url: ApiUrls.GET_INVOICES_TOTALS,
            method: Methods.GET,
            params: {
                nameOrId: filters.affiliateNameOrId
            },
        })
            .then(({ data: { value: totals } }) => {
                dispatch(setInvoicesTotals(totals, type));
                dispatch(setInvoicesTotalsActionFinished());
            })
            .catch((ex) => {
                dispatch(setInvoicesTotalsActionFinished());
            });
    }
}