import React from 'react';

import { useTranslation } from "core/hooks/useTranslation";

import Tabs from "components/common/tabs";

import GeneralInfoComponent from './sections/general';
import MembersComponent from './sections/members';

import ROUTES from "core/constants/routes/routes";

/** Affiliate Group Edit Page Component */
const AffiliateGroupEditComponent = () => {
    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.affiliates.general"),
            component: <GeneralInfoComponent/>
        },
        {
            title: t("backoffice.affiliates.groupMembers"),
            component: <MembersComponent />
        },
    ]

    return (
        <Tabs 
            items={items}
            mainPage={{ title: t('backoffice.menu.affiliateGroups'), path: ROUTES.AFFILIATE_GROUPS }}
        />
    )
}


export default AffiliateGroupEditComponent;