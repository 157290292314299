export const getTableColumns = additionalProps => {

    const { t } = additionalProps;

    return [
        {
            title: "backoffice.userlogs.date",
            dataIndex: "logTime",
            isDateTime: true,
            sorter: true,
            mobileLevel: 2,
        },
        {
            title: "backoffice.userlogs.user",
            dataIndex: 'userName',
            sorter: true,
            alwaysVisible: true,
            mobileLevel: 1,
            render: value => value || "-",
        },
        {
            title: 'backoffice.userlogs.previousUser',
            dataIndex: 'previousUserName',
            render: value => value || "-",
        },
        {
            title: "backoffice.userlogs.resource",
            dataIndex: "resource",
            sorter: true,
            mobileLevel: 3,
        },
        {
            title: "backoffice.userlogs.resourceId",
            dataIndex: "resourceId",
            alwaysVisible: true,
            sorter: true,
            copy: true,
        },
        {
            title: "backoffice.userlogs.resourceName",
            dataIndex: "resourceName",
            sorter: true,
        },
        {
            title: "backoffice.userlogs.action",
            dataIndex: 'action',
            sorter: true,
            mobileLevel: 4,
        },
        {
            title: 'backoffice.userlogs.actionType',
            dataIndex: 'actionType',
            sorter: true,
            mobileLevel: 5,
            render: value => value ? t('backoffice.userlogs.actionType' + value) : "-",
        },
        {
            title: 'backoffice.userlogs.changes',
            dataIndex: 'count',
            disableExport: true,
            render: value => value + " " + t('backoffice.userlogs.changes'),
        },
        {
            title: 'backoffice.userlogs.browser',
            dataIndex: 'browserName',
            render: (value, record) => value + " " + record.browserVersion,
        },
        {
            title: 'backoffice.userlogs.deviceType',
            dataIndex: 'deviceType',
        },
        {
            title: 'backoffice.userlogs.ip',
            dataIndex: 'ipAddress',
        },
        {
            title: 'backoffice.userlogs.os',
            dataIndex: 'os',
        }
    ];
}

export const expandColumns = [
    {
        title: "#",
        dataIndex: "index",
        mobileLevel: 1,
    },
    {
        title: 'backoffice.userlogs.name',
        dataIndex: "name",
        mobileLevel: 2,
    },
    {
        title: 'backoffice.userlogs.previousValue',
        dataIndex: "previousValue",
        mobileLevel: 3,
        render: value => value || "-",
    },
    {
        title: 'backoffice.userlogs.currentValue',
        dataIndex: "currentValue",
        mobileLevel: 4,
        render: value => value || "-",
    }
];