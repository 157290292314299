import React, { Fragment, useEffect, useState } from "react";

import Modal from "core/ui-kit/modal";

import FullView from "./fullView";

import { useTranslation } from "core/hooks/useTranslation";

import { MOBILE_DETAILS_TABS } from "../../constants";

import classNames from "core/helpers/common/classNames";

const TableDetails = ({
    record,
    visible,
    expandable,
    showFullViewData,
    columns,
    onCancel
}) => {

    const { t } = useTranslation();

    const [ activeTab, setActiveTab ] = useState(MOBILE_DETAILS_TABS.FULL_VIEW);

    useEffect(() => {
        if(visible){
            setActiveTab(showFullViewData ? MOBILE_DETAILS_TABS.FULL_VIEW : MOBILE_DETAILS_TABS.DETAILS)
        }
    }, [visible])

    const isExpandable = record && Boolean(expandable) && ( expandable.rowExpandable?.(record) ?? true )

    const title = showFullViewData ? t('backoffice.common.fullView') : isExpandable ? expandable.columnTitle ?? t('backoffice.common.details') : "";

    const showTabs = showFullViewData && isExpandable;  

    const renderContent = () => {
        if(!record){
            return <Fragment />
        }

        return (
            <Fragment>
                {
                    showTabs && (
                        <div className="rt--table-details-tabs rt--flex rt--align-center rt--mb-16">
                            {
                                Object.values(MOBILE_DETAILS_TABS).map(tab => (
                                    <div
                                        key={tab}
                                        onClick={() => setActiveTab(tab)}
                                        className={classNames(
                                            "rt--table-details-tabs-item rt--flex rt--align-center rt--justify-center rt--flex-equal",
                                            activeTab === tab && "rt--table-details-tabs-item-active"
                                        )}
                                    >
                                        <span className="rt--title rt--font-regular rt--font-big">
                                            {
                                                tab === MOBILE_DETAILS_TABS.DETAILS && expandable.columnTitle ? expandable.columnTitle : t('backoffice.common.details') 
                                            }
                                        </span>
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
                {
                    activeTab === MOBILE_DETAILS_TABS.FULL_VIEW ? (
                        <FullView 
                            columns={columns}
                            record={record}
                        />
                    ) : (
                        <div className="rt--table-expanded">
                            { expandable.expandedRowRender(record) }
                        </div>
                    )
                }
            </Fragment>
        )
    }

    return (
        <Modal
            title={title}
            open={visible}
            okButtonProps={{ hidden: true }}
            onCancel={onCancel}
            className="rt--table-modal-details"
        >
            {
                renderContent()
            }
        </Modal>
    )
}

export default TableDetails;