import { LOGO_TYPE, LOGO_DEFAULTS, LOGO_WHITE } from "core/constants/common/logo";

export const mapLogosData = (logos = []) => {
    const mappedData = {
        ...LOGO_DEFAULTS
    }

    mappedData[LOGO_TYPE.LOGO] = LOGO_WHITE;
    logos.forEach(logoData => {
        if(logoData.path){
            mappedData[logoData.type] = logoData.path;
        } 
    });

    return mappedData;
}