import React, { lazy } from "react";

import Spin from "core/ui-kit/spin";

import ROUTES from "core/constants/routes/routes";

import withSuspenseHoc from "core/HOCs/withSuspense";

const ForgotPasswordContainer = withSuspenseHoc(lazy(() => import("./forgotPasswordContainer")), <Spin />);

const ForgotPasswordPageRoute = {
	path: ROUTES.FORGOT_PASSWORD,
	element: <ForgotPasswordContainer />,
};

export default ForgotPasswordPageRoute
