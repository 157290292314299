import ROUTES from "core/constants/routes/routes";

import NewsletterCustomizeComponent from "pages/newsletterCustomize/newsletterCustomize.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const NewsletterCustomizePageRoute = {
	path: `${ROUTES.NEWSLETTER_CUSTOMIZE}/:id`,
	element: NewsletterCustomizeComponent, 
    permission: { resource: PERMISSION_RESOURCE.COMMUNICATION_NEWSLETTER, action: PERMISSION_ACTION.VIEW }
};

export default NewsletterCustomizePageRoute;
