import React from "react"

import { useTranslation } from "core/hooks/useTranslation";

import Icon from "components/common/icon";

import useDevice from 'core/hooks/useDevice';

import classNames from "core/helpers/common/classNames";

const SelectNotFound = () => {
    const { t } = useTranslation();

    const { isMobile } = useDevice();

    const containerClassName = classNames(
        "rt--select-empty rt--flex rt--flex-col rt--justify-center rt--align-center",
        isMobile ? "rt--pt-60 rt--mb-12" : "rt--select-empty"
    )

    const textClassName = isMobile ? "rt--font-normal rt--mt-16" : "rt--font-big rt--font-regular rt--mb-6 rt--mt-16"

    return (
        <div className={containerClassName}>
            <Icon name="empty" size={isMobile ? 60 : 24} />
            <span className={textClassName}>
                { t("backoffice.common.noDataFound") }
            </span>
        </div>
    );
}

export default SelectNotFound;