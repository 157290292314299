import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import EntityActions from "components/common/entityActions";

import {
    changeCommissionPlanState,
    deleteCommissionPlan,
    removeCommissionPlan
} from "store/actions/portal/commissionPlans/commissionPlans.action";

import { deleteQueryStringParamsToUrl } from 'utils/queryString';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'core/constants/permission';
import { COMMISSION_PLAN_STATE } from 'constants/commission.constants';

import commissionPlanType from 'types/commissionPlan/commissionPlan.type';
import { useGlobalSelector, userInfoSelector } from 'core/stores/globalStore';
import usePermissions from 'core/hooks/usePermission';

/** Commission Plan Actions Button Component */
const CommissionPlanActionsComponent = ({
    changeCommissionPlanState,
    deleteCommissionPlan,
    removeCommissionPlan,
    generalInfo,
    isAffiliate
}) => {

    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const userInfo = useGlobalSelector(userInfoSelector);

    const { search, pathname, hash } = useLocation();
    const navigate = useNavigate();

    const searchParams = useParams();

    const commissionPlanId = (new URLSearchParams(search)).get("commissionId");

    /** Actions list */
    const actions = [

        /** Deactivate/Activate */
        ... (!isAffiliate ? [
            {
                key: "block",
                buttonType: generalInfo.status === COMMISSION_PLAN_STATE.ACTIVE ? "primary" : "secondary",
                buttonClassName: generalInfo.status === COMMISSION_PLAN_STATE.ACTIVE ? "rt--button-danger" : "rt--button-secondary",
                title: generalInfo.status !== COMMISSION_PLAN_STATE.ACTIVE ? t('backoffice.commissionplans.activate') : t('backoffice.commissionplans.deactivate'),
                onClick: () => {
                    changeCommissionPlanState(commissionPlanId, generalInfo.status === COMMISSION_PLAN_STATE.ACTIVE ? COMMISSION_PLAN_STATE.INACTIVE : COMMISSION_PLAN_STATE.ACTIVE)
                },
                icon: "block",
                isConfirmation: true,
                confirmationMessage: generalInfo.status === COMMISSION_PLAN_STATE.ACTIVE ? t('backoffice.commissionplans.commissionPlanDeactivateInfo') : t('backoffice.commissionplans.commissionPlanActivateInfo'),
                disabled: (
                    !permissionUtils.has( PERMISSION_RESOURCE.PROJECT_COMMISSION_PLAN, PERMISSION_ACTION.MODIFY )
                )
            }
        ] : []),
        /** Delete */
        {
            key: "delete",
            buttonType: "danger",
            buttonClassName: "rt--button-danger",
            title: isAffiliate ? t('backoffice.common.remove') : t('backoffice.common.delete'),
            icon: "delete",
            onClick: () => {
                const redirectTo = deleteQueryStringParamsToUrl(pathname, search, hash, ["commissionId", "commissionName"] );
                if (isAffiliate) {
                    removeCommissionPlan(commissionPlanId, searchParams.id, () => {
                        navigate(redirectTo, { replace: true })
                    })
                } else {
                    deleteCommissionPlan(commissionPlanId, () => {
                        navigate(redirectTo, { replace: true })
                    })
                }
            },
            isConfirmation: true,
            confirmationMessage: isAffiliate ? t("backoffice.commissionplans.doYouWanttoRemoveCommissionPlan") : t("backoffice.commissionplans.doYouWanttoDeleteCommissionPlan"),
            disabled: (
                !permissionUtils.has( isAffiliate ? PERMISSION_RESOURCE.AFFILIATE_COMMISSION_PLAN : PERMISSION_RESOURCE.PROJECT_COMMISSION_PLAN, PERMISSION_ACTION.DELETE ) || (isAffiliate && searchParams.id === userInfo.id)
            )
        },

    ]

    return (
        <Fragment>
            <EntityActions
                actions={actions}
            />
        </Fragment>
    )
}

/** CommissionPlanActionsComponent propTypes
    * PropTypes
*/
CommissionPlanActionsComponent.propTypes = {
    /** Redux state property, current editing commission plan general info */
    generalInfo: commissionPlanType,
    /** Redux action to change commision plan state */
    changeCommissionPlanState: PropTypes.func,
    /** Redux action to get delete commission plan */
    deleteCommissionPlan: PropTypes.func,
    /** Redux action to get remove commission plan from affiliate */
    removeCommissionPlan: PropTypes.func,
    /** Is Affiliate */
    isAffiliate: PropTypes.bool
}

const mapDispatchToProps = dispatch => (
    {
        changeCommissionPlanState: (id, status) => {
            dispatch(changeCommissionPlanState(id, status));
        },
        deleteCommissionPlan: (id, onSuccess) => {
            dispatch(deleteCommissionPlan(id, onSuccess));
        },

        removeCommissionPlan: (id, affiliateId, onSuccess) => {
            dispatch(removeCommissionPlan(id, affiliateId, onSuccess));
        }
    }
)

const mapStateToProps = state => {
    return {
        generalInfo: state.commissionPlans.edit.general,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommissionPlanActionsComponent)