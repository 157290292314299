import React from "react";

import Status from "components/common/status";

import { STATUS_TYPES } from "constants/status.constants";
import { COMMISSION_PLAN_SOURCE } from "constants/commission.constants";

export const getTableColumns = additionalProps => {

    const {
        formatAmount,
        t
    } = additionalProps;

    return [
        {
            title: "backoffice.commissioncalculations.calculationId",
            dataIndex: "calculationId",
            mobileLevel: 1,
            alwaysVisible: true,
            copy: true,
            fixed: "left"
        },
        {
            title: "backoffice.commissioncalculations.affiliateId",
            dataIndex: "affiliateId",
            mobileLevel: 2,
            alwaysVisible: true,
            copy: true,
            fixed: "left"
        },
        {
            title: "backoffice.commissioncalculations.affiliateUsername",
            dataIndex: "affiliateUserName",
            mobileLevel: 3,
            alwaysVisible: true,
            sorter: true,
            fixed: "left"
        },
        {
            title: "backoffice.commissioncalculations.from",
            dataIndex: "from",
            isDateTime: true,
            mobileLevel: 5
        },
        {
            title: "backoffice.commissioncalculations.to",
            dataIndex: "to",
            isDateTime: true,
            mobileLevel: 6
        },
        {
            title: "backoffice.commissioncalculations.currency",
            dataIndex: "currencyCode",
            mobileLevel: 4,
        },
        {
            title: "backoffice.commissioncalculations.sourceType",
            dataIndex: "sourceType",
            multi: true,
            multiMapper: value => value ? value.map(v => 
                v === COMMISSION_PLAN_SOURCE.GGR ? t("backoffice.commissionplans.ggr") :
                    v === COMMISSION_PLAN_SOURCE.NGR ? t("backoffice.commissionplans.ngr") : 
                        v === COMMISSION_PLAN_SOURCE.TURNOVER ? t("backoffice.commissionplans.turnover") :
                            v === COMMISSION_PLAN_SOURCE.NET_DEPOSIT ? t("backoffice.commissionplans.netDeposit") : ""
                ) : [],
        },
        {
            title: "backoffice.commissioncalculations.status",
            dataIndex: "status",
            render: value => <Status type={STATUS_TYPES.COMMISSION_CALCULATION} status={value} />
        },
        {
            title: "backoffice.commissioncalculations.sportSource",
            dataIndex: "sportSource",
            render: (value, record) => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, record.currencyCode)}
                </span>
            ),
        },
        {
            title: "backoffice.commissioncalculations.sportCommission",
            dataIndex: "sportCommission",
            render: (value, record) => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, record.currencyCode)}
                </span>
            ),
        },
        {
            title: "backoffice.commissioncalculations.casinoSource",
            dataIndex: "casinoSource",
            render: (value, record) => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, record.currencyCode)}
                </span>
            ),
        },
        {
            title: "backoffice.commissioncalculations.casinoCommission",
            dataIndex: "casinoCommission",
            render: (value, record) => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, record.currencyCode)}
                </span>
            ),
        },
        {
            title: "backoffice.commissioncalculations.depositFee",
            dataIndex: "depositFeeAmount",
            render: (value, record) => formatAmount(value, record.currencyCode),
        },
        {
            title: "backoffice.commissioncalculations.adjustmentFee",
            dataIndex: "otherCostsAmount",
            render: (value, record) => formatAmount(value, record.currencyCode),
        },
        {
            title: "backoffice.commissioncalculations.sportAdminFee",
            dataIndex: "sportFeeAmount",
            render: (value, record) => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, record.currencyCode)}
                </span>
            ),
        },
        {
            title: "backoffice.commissioncalculations.casinoAdminFee",
            dataIndex: "casinoFeeAmount",
            render: (value, record) => (
                <span className={value < 0 ? "rt--error-text" : ""}>
                    {formatAmount(value, record.currencyCode)}
                </span>
            ),
        },
        {
            title: "backoffice.commissioncalculations.totalSource",
            dataIndex: "totalSourceAmount",
            render: (value, record) => formatAmount(value, record.currencyCode),
        },
        {
            title: "backoffice.commissioncalculations.revshare",
            dataIndex: "revshareTotal",
            render: (value, record) => formatAmount(value, record.currencyCode),
        },
        {
            title: "backoffice.commissioncalculations.cpa",
            dataIndex: "cpa",
            render: (value, record) => formatAmount(value, record.currencyCode),
        },
        {
            title: "backoffice.commissioncalculations.fixed",
            dataIndex: "fixed",
            render: (value, record) => formatAmount(value, record.currencyCode),
            sorter: true
        },
        {
            title: "backoffice.commissioncalculations.earning",
            dataIndex: "earningTotal",
            render: (value, record) => (
                <span className={value < 0 ? "rt--error-text" : value > 0 ? "rt--success-text" : ""}>
                    {formatAmount(value, record.currencyCode)}
                </span>
            ),
            sorter: true
        },
        {
            title: "backoffice.commissioncalculations.paidReferralCommission",
            dataIndex: "paidReferralCommission",
            render: (value, record) => formatAmount(value, record.currencyCode),
        },
        {
            title: "backoffice.commissioncalculations.calculationDate",
            dataIndex: "calculationDate",
            isDateTime: true,
            sorter: true
        },
        {
            title: "backoffice.commissioncalculations.approvedDate",
            dataIndex: "approvedDate",
            isDateTime: true,
        },
    ]
}

export const getExpandTableColumns = additionalProps => {
    const columns = getTableColumns(additionalProps);
    const filteredList = [
        "affiliateId",
        "affiliateUserName",
        "from",
        "to",
        "currencyCode",
        "sourceType",
        "status"
    ];
    return columns.filter(column => !filteredList.includes(column.dataIndex))
}