import React, { useRef, useState } from "react";

import { debugStore, placementSelector, useDebugSelector } from "../../../../debugStore";

import { isValidValue } from "../../../helpers/isValidValue";
import { parseValue } from "../../../helpers/parseValue";
import { toString } from "../../../helpers/toString";
import { isObjectString } from "../../../helpers/isObjectString";
import { isArrayString } from "../../../helpers/isArrayString";

const DebugValue = ({ path, value, canEdit }) => {
	const [editModeEnabled, setEditModeEnabled] = useState(false);
	const [editedValue, setEditedValue] = useState(value);

	const placement = useDebugSelector(placementSelector);

	const editorRef = useRef(null);
	const oldValue = useRef();

	const innerValue = editModeEnabled ? editedValue : toString(value);
	const showPreview = placement === 'bottom' || placement === 'popup';

	const handleDoubleClick = (event) => {
		if (canEdit) {
			oldValue.current = event.target.innerText;

			setEditModeEnabled(true);
			setEditedValue(event.target.innerText);

			setTimeout(() => {
				editorRef.current?.focus();
			}, 0)
		}
	}

	const handleBlur = (event) => {
		setEditModeEnabled(false);

		const value = event.target.innerText;

		if (isValidValue(value)) {
			if (value !== oldValue.current) {
				const storeName = path[0];
				const propPath = path.slice(1);

				if (value === "") {
					debugStore.deleteStoreProperty(storeName, propPath);
				} else {
					debugStore.setStoreProperty(storeName, propPath, parseValue(value));
				}
			}
		} else {
			event.target.innerText = oldValue.current;
		}

		setEditedValue("");
	}

	const getObjectPreview = (value) => {
		const parsedValue = JSON.parse(value);
		const keys = Object.keys(parsedValue);

		if (!keys.length) {
			return '{ empty }'
		}

		return (
			`{ ${keys.slice(0, 3).map((key) => `${key} : ${getValue(toString(parsedValue[key]), true)}`).join(', ')} ${keys.length > 3 ? ', …' : ''} }`
		)
	}

	const getArrayPreview = (value) => {
		const parsedValue = JSON.parse(value);

        if (!parsedValue.length) {
            return '[ empty ]'
        }

        return (
            `[ ${parsedValue.slice(0, 3).map((item) => getValue(toString(item), true)).join(', ')} ${parsedValue.length > 3? ', …' : ''} ]`
        )
	}

	const getValue = (value, isNested = false) => {
		if (isObjectString(value)) {
			return isNested || !showPreview ? '{…}' : getObjectPreview(value);
		}

		if (isArrayString(value)) {
			return isNested || !showPreview ? '[…]' : getArrayPreview(value);
		}

		return value;
	}

	if (editModeEnabled) {
		return (
			<span
				ref={editorRef}
				className="rt--debug-object-viewer-row-value rt--debug-object-viewer-row-value-edit"
				contentEditable
				onBlur={handleBlur}
			>
				{getValue(innerValue)}
			</span>
		);
	}

	if (isObjectString(innerValue)) {
		return (
			<span className="rt--debug-object-viewer-row-value">
				{getValue(innerValue)}
			</span>
		);
	}

	if (isArrayString(innerValue)) {
		return (
			<span className="rt--debug-object-viewer-row-value">
				{getValue(innerValue)}
			</span>
		);
	}

	if (innerValue === "null") {
		return (
			<span
				className="rt--debug-object-viewer-row-value rt--debug-object-viewer-row-value__null"
				onDoubleClick={handleDoubleClick}
			>
				{getValue(innerValue)}
			</span>
		);
	}

	if (innerValue === "undefined") {
		return (
			<span
				className="rt--debug-object-viewer-row-value rt--debug-object-viewer-row-value__undefined"
				onDoubleClick={handleDoubleClick}
			>
				{getValue(innerValue)}
			</span>
		);
	}

	if (innerValue[0] === '"' && innerValue[innerValue.length - 1] === '"') {
		return (
			<span className="rt--debug-object-viewer-row-value rt--debug-object-viewer-row-value__string">
				<span
					className="rt--debug-object-viewer-row-value-editor"
					onDoubleClick={handleDoubleClick}
				>
					{getValue(innerValue)}
				</span>

				{/*<DebugAction className="rt--debug-object-viewer-row-value-action" onClick={() => console.log("action")}>X</DebugAction>*/}
			</span>
		);
	}

	return (
		<span
			className="rt--debug-object-viewer-row-value rt--debug-object-viewer-row-value__primitive"
			onDoubleClick={handleDoubleClick}
		>
			{getValue(innerValue)}
		</span>
	);
};

export default DebugValue;
