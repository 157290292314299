import React, { useEffect, useState, useRef, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";

import { Checkbox, Select as AntSelect } from 'antd';

import NotFound from '../notFound';
import Tag from '../tag';
import Icon from 'components/common/icon';

import useDebounce from 'hooks/useDebounce';

import { classNames } from 'utils/common';
import {
    getMergedSelectedValues,
    filterAndSortBySearchValue,
    getSelectedValues,
    getPropFromMatchObj,
} from '../helpers';

import { ALL_VALUE } from '../constants';

const SelectDesktop = ({
    options: outsideOptions,
    value,
    onChange,
    disabled,
    placeholder,
    className = "",
    withLabel = false,
    search = false,
    add = false,
    isMultiple,
    maxMultipleSelect,
    showSelectAllButton = true,
    isDark,
    ...restProps
}) => {
    const { t } = useTranslation();

    const [options, setOptions] = useState(outsideOptions);
    const [dropdownOpened, setDropdownOpened] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const debouncedSearch = useDebounce(searchValue);

    const antSelectRef = useRef(null);

    const filteredOptions = useMemo(() => {
        return filterAndSortBySearchValue({ options, searchValue: debouncedSearch, add });
    }, [options, debouncedSearch, add]);

    const isAllOptionVisible = (
        isMultiple &&
        showSelectAllButton &&
        filteredOptions.length > 1 &&
        debouncedSearch === ""
    );

    const mergedSelectedValues = getMergedSelectedValues({
        isMultiple: isAllOptionVisible,
        options,
        selectedValues: value,
        isAllOptionVisible
    });

    const handleInternalChange = (newSelectedValues) => {
        const updatedSelectedValues = getSelectedValues({
            isMultiple: isAllOptionVisible,
            options,
            prevSelectedValues: mergedSelectedValues,
            newSelectedValues,
        })

        onChange(updatedSelectedValues);
    }

    /** Multi mode props */
    const multipleModeProps = isMultiple ? {
        showArrow: true,
        maxTagCount: "responsive",
        maxTagTextLength: 18,
        mode: "multiple",
        menuItemSelectedIcon: (
            <Fragment />
        ),
        tagRender: ({ value, onClose }) => {
            if (value === ALL_VALUE) {
                return;
            }

            return (
                <Tag
                    label={getPropFromMatchObj({ value, options, prop: "text" })}
                    onClose={onClose}
                    disabled={disabled}
                />
            )
        }
    } : {}

    /** Search mode props */
    const searchModeProps = search ? {
        filterOption: () => true,
        onSearch: inputValue => {
            if (search?.pattern && inputValue.match(search.pattern) === null) {
                return;
            }

            setSearchValue(inputValue);
        },
        onSelect: (_, option) => {
            if (add && option.value === debouncedSearch && options.findIndex((option) => option.value === debouncedSearch) === -1) {
                setOptions((prevOptions) => [{ text: debouncedSearch, value: debouncedSearch }, ...prevOptions]);
            }

            setSearchValue("");
        },
        onDeselect: () => {
            setSearchValue("");
        },
        onDropdownVisibleChange: opened => {
            !opened && setSearchValue("");
            setDropdownOpened(opened);
        }
    } : {
        onDropdownVisibleChange: opened => {
            setDropdownOpened(opened)
        }
    }

    const isOptionDisabled = option => {
        if(option.disabled) return true;
        const values = mergedSelectedValues ?? [];
        if(maxMultipleSelect){
            if(
                values.length >= maxMultipleSelect &&
                !values.includes(option.value)
            ){
                return true
            }
        }
        return false;
    }

    useEffect(() => {
        setOptions(outsideOptions);
    }, [outsideOptions]);

    return (
        <AntSelect
            ref={antSelectRef}
            suffixIcon={<Icon name="down" />}
            value={mergedSelectedValues}
            searchValue={searchValue}
            onChange={handleInternalChange}
            disabled={disabled}
            placeholder={placeholder}
            showSearch={Boolean(search)}
            notFoundContent={<NotFound />}
            className={classNames(
                withLabel && "rt--select-with-label",
                isDark && "rt--select-dark",
                className,
            )}
            {...multipleModeProps}
            {...searchModeProps}
            {...restProps}
        >
            {
                isAllOptionVisible && (
                    <AntSelect.Option value={ALL_VALUE} className={"rt--select-all rt--mb-2"}>
                        <div
                            className={classNames(
                                withLabel && "rt--select-with-label-item",
                                "rt--flex",
                                "rt--align-center",
                            )}
                        >

                            {
                                isMultiple && (
                                    <Checkbox
                                        className="rt--select-checkbox"
                                        checked={value && value.length === options.length}
                                    />
                                )
                            }
                            <span
                                className={classNames(
                                    "rt--title",
                                    "rt--font-normal",
                                    "rt--font-regular",
                                )}
                            >
                                {value?.length === options.length ? t("backoffice.common.deselectAll") : t("backoffice.common.selectAll")}
                            </span>
                        </div>
                    </AntSelect.Option>
                )
            }
            {
                filteredOptions.map(option => !option.subs ? (
                    <AntSelect.Option 
                        key={option.value || option.text} 
                        value={option.value}
                        disabled={isOptionDisabled(option)}
                    >
                        {
                            withLabel
                                ? (
                                    <div
                                        className={classNames(
                                            "rt--select-with-label-item",
                                            "rt--flex",
                                            "rt--align-center",
                                            'rt--justify-between',
                                        )}
                                    >
                                        <span
                                            className={classNames(
                                                "rt--title",
                                                "rt--font-normal",
                                                "rt--font-regular"
                                            )}
                                        >
                                            {option.text}
                                        </span>
                                        <small
                                            className={classNames(
                                                "rt--title",
                                                "rt--font-small",
                                                "rt--font-regular",
                                                "rt--pl-16",
                                                "rt--pr-16",
                                            )}
                                        >
                                            {option.label}
                                        </small>
                                    </div>
                                ) : (
                                    <span
                                        className={classNames(
                                            "rt--flex",
                                            "rt--align-center",
                                        )}
                                    >
                                        {
                                            isMultiple && (
                                                <Checkbox
                                                    className="rt--select-checkbox"
                                                    checked={value && value.includes(option.value)}
                                                />
                                            )
                                        }
                                        <span
                                            className={classNames(
                                                "rt--title",
                                                "rt--font-normal",
                                                "rt--font-regular"
                                            )}
                                        >
                                            {option.text}
                                        </span>
                                    </span>
                                )

                        }
                    </AntSelect.Option>
                ) : (
                    <AntSelect.OptGroup key={option.value || option.text} label={option.text}>
                        {
                            option.subs.map(sub => (
                                <AntSelect.Option key={sub.value || sub.text} value={sub.value}>
                                    {sub.text}
                                </AntSelect.Option>
                            ))
                        }
                    </AntSelect.OptGroup>
                ))
            }
        </AntSelect>
    );
}

/** SelectDesktop propTypes
    * PropTypes
*/
SelectDesktop.propTypes = {
    /** Select options */
    options: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        subs: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        }))
    })),
    /** Callback which called on input change */
    onChange: PropTypes.func,
    /** Input value */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.bool]),
    /** Is disabled */
    disabled: PropTypes.bool,
    /** Placeholder */
    placeholder: PropTypes.string,
    /** Classname */
    className: PropTypes.string,
    /** Are the options with label */
    withLabel: PropTypes.bool,
    /** Enable search */
    search: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            pattern: PropTypes.instanceOf(RegExp),
        })
    ]),
    /** Enable add new option */
    add: PropTypes.bool,
    /** Is Multiple mode select */
    isMultiple: PropTypes.bool,
    /** Is dark mode dropdown */
    isDark: PropTypes.bool,
    /** Show select all button or not */
    showSelectAllButton: PropTypes.bool,
    /** Max possible multiple selection count */
    maxMultipleSelect: PropTypes.number
}

export default SelectDesktop;