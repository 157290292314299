import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'core/helpers/common/classNames';

const Flag = ({
    code,
    className
}) => {

    const builtClassName = classNames("rt--flag", "rt--flag-" + code, className);

    if(!code){
        return null;
    }

    return (
        <div className={builtClassName} />
    )
}

Flag.propTypes = {
    code: PropTypes.string,
    className: PropTypes.string
}

export default Flag;