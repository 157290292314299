import React from "react";

import Status from "components/common/status";

import { COMMISSION_PLAN_TYPE } from "constants/commission.constants";
import { STATUS_TYPES } from "constants/status.constants";
import { PROJECT_BRAND_PRODUCT_TYPE } from "constants/project.constants";

export const getTableColumns = additionalProps => {
    const { t } = additionalProps;

    return [
        {
            title: "backoffice.common.id",
            dataIndex: "longId",
            copy: true,
            alwaysVisible: true,
            mobileLevel: 1
        },
        {
            title: "backoffice.commissionplans.name",
            dataIndex: "name",
            sorter: true,
            alwaysVisible: true,
            mobileLevel: 2
        },
        {
            title: "backoffice.commissionplans.commissionType",
            dataIndex: "type",
            sorter: true,
            render: value => value === COMMISSION_PLAN_TYPE.REVSHARE ? t("backoffice.commissionplans.revshare") :
                value === COMMISSION_PLAN_TYPE.CPA ? t("backoffice.commissionplans.cpa") :
                    value === COMMISSION_PLAN_TYPE.FIX ? t("backoffice.commissionplans.fix") : "",
            mobileLevel: 3
        },
        {
            title: "backoffice.commissionplans.currency",
            dataIndex: "currencyCode",
            sorter: true,
            mobileLevel: 4
        },
        {
            title: "backoffice.commissionplans.status",
            dataIndex: "status",
            render: value => <Status type={STATUS_TYPES.COMMISSION} status={value} />,
            mobileLevel: 5
        },
        {
            title: "backoffice.projects.product",
            dataIndex: "products",
            render: value => value ? value.map(
                v => v === PROJECT_BRAND_PRODUCT_TYPE.CASINO ? t(`backoffice.projects.casino`) : 
                v === PROJECT_BRAND_PRODUCT_TYPE.SPORT ? t(`backoffice.projects.sport`) : ""
            ).join(", ") : "-",
            mobileLevel: 6
        },
        {
            title: "backoffice.commissionplans.assignedAffiliates",
            dataIndex: "assignedAffiliates",
            isNumeric: true
        },
        {
            title: "backoffice.commissionplans.createdAt",
            dataIndex: "createdAt",
            isDateTime: true,
            sorter: true
        },
        {
            title: "backoffice.commissionplans.createdBy",
            dataIndex: "createdBy",
            sorter: true
        },
        {
            title: "backoffice.commissionplans.lastModifiedAt",
            dataIndex: "modifiedAt",
            isDateTime: true,
            sorter: true
        },
        {
            title: "backoffice.commissionplans.lastModifiedBy",
            dataIndex: "modifiedBy",
            sorter: true
        }
    ];
}