import { message } from 'antd';

import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { 
    setCommissionPlansActionBefore, 
    setCommissionPlansActionFinished, 
    setCommissionPlansSaveActionBefore, 
    setCommissionPlansSaveActionFinished 
} from './commissionPlans.action';

import { 
    SET_COMMISSION_PLAN_GENERAL_INFO,
    UPDATE_COMMISSION_PLAN_STATE 
} from "../../../actionTypes";

const setCommissionPlanGeneralInfo = info => ({
    type: SET_COMMISSION_PLAN_GENERAL_INFO,
    payload: { info }
})

export const updateCommissionPlanState = status => ({
    type: UPDATE_COMMISSION_PLAN_STATE,
    payload: { status }
})

export const getCommissionPlanGeneralInfo = id => {
    return dispatch => {
        dispatch(setCommissionPlansActionBefore());

        return axios({
            url: ApiUrls.GET_COMMISSION_PLAN_GENERAL_INFO,
            method: Methods.GET,
            params: { id }
        })
        .then(({data : {value: info}}) => {
            dispatch(setCommissionPlanGeneralInfo(info))
            dispatch(setCommissionPlansActionFinished());
        })
        .catch((ex) => {
            dispatch(setCommissionPlansActionFinished());
        })
    }
}

export const saveCommissionPlanRevShare = data => {
    return dispatch => {
        dispatch(setCommissionPlansSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_COMMISSION_PLAN_REV_SHARE,
            method: Methods.POST,
            data: {
                ...data
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setCommissionPlanGeneralInfo(data.value));
            } 
            dispatch(setCommissionPlansSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setCommissionPlansSaveActionFinished());
        })
    }
}

export const saveCommissionPlanCPA = data => {
    return dispatch => {
        dispatch(setCommissionPlansSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_COMMISSION_PLAN_CPA,
            method: Methods.POST,
            data: {
                ...data
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setCommissionPlanGeneralInfo(data.value));
            } 
            dispatch(setCommissionPlansSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setCommissionPlansSaveActionFinished());
        })
    }
}

export const saveCommissionPlanFix = data => {
    return dispatch => {
        dispatch(setCommissionPlansSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_COMMISSION_PLAN_FIX,
            method: Methods.POST,
            data: {
                ...data
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setCommissionPlanGeneralInfo(data.value));
            } 
            dispatch(setCommissionPlansSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setCommissionPlansSaveActionFinished());
        })
    }
}

