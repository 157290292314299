import { useCallback } from 'react';

import { binaryToFlags } from 'utils/common';

import { USER_ROLE } from 'constants/user.constants';
import { AFFILIATE_ACCESS_TYPE } from 'constants/affiliate.constants';

import { useGlobalSelector, userInfoSelector } from 'core/stores/globalStore';
import { useAuthSelector, userRoleSelector } from 'core/stores/authStore';

const useAccess = () => {

    const { affiliateAccessType } = useGlobalSelector(userInfoSelector);
    const userRole = useAuthSelector(userRoleSelector);

    const hasAccess = useCallback(type => {
        if(userRole !== USER_ROLE.AFFILIATE || affiliateAccessType === null)
        {
            return true;
        }

        const accesses = binaryToFlags(Object.values(AFFILIATE_ACCESS_TYPE), affiliateAccessType);

        return accesses?.includes(type) 
    }, [affiliateAccessType, userRole])

    return {
        hasAccess
    }
}

export default useAccess;