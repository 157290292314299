import React, { useMemo } from "react";

import { useTranslation } from "core/hooks/useTranslation";
import useDate from "core/hooks/useDate";
import useDevice from "core/hooks/useDevice";

import { Row, Col } from "core/ui-kit/layout";
import Form from "core/ui-kit/form";
import DatePicker from "core/ui-kit/datePicker";

import { COMMISSION_TYPE, COMMISSION_TYPE_TRANSLATIONS } from "core/constants/commission/type";
import { COMMISSION_ADD_FIELDS } from "../constants";

import { currenciesSelector, useGlobalSelector } from "core/stores/globalStore";


const Filters = ({
    initialValues={},
    onChange
}) => {

    const [formInstance] = Form.useForm();

    const { t } = useTranslation();
    const { dateService } = useDate();
    const { isMobile } = useDevice();

    const currencies = useGlobalSelector(currenciesSelector);

    const commissionTypeOptions = useMemo(() => {
        return [
            { value: "", text: t('backoffice.common.all') },
            ...Object.values(COMMISSION_TYPE).map(type => ({ value: type, text: t(COMMISSION_TYPE_TRANSLATIONS[type]) }))
        ]
    }, [t])

    const currencyOptions = useMemo(() => {
        return [
            { value: "", text: t('backoffice.common.all') },
            ...currencies.map(currency => ({ value: currency.currencyCode, text: currency.currencyCode }))
        ]
    }, [t, currencies])

    const colSpan = isMobile ? { span: 24 } : { span: 8 }

    return (
        <div className="rt--commissions-filters rt--pb-8 rt--mb-16">
            <Form
                form={formInstance}
                initialValues={initialValues}
                onValuesChange={onChange}
            >
                <Row gutter={[16, 0]}>
                    <Col {...colSpan}>
                        <Form.Select
                            name={COMMISSION_ADD_FIELDS.COMMISSION_TYPE}
                            label={t('backoffice.commissionplans.commissionType')}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissionplans.commissionType')}`} 
                            options={commissionTypeOptions}
                        />
                    </Col>
                    <Col {...colSpan}>
                        <Form.Select
                            name={COMMISSION_ADD_FIELDS.CURRENCY_CODE}
                            label={t('backoffice.commissionplans.currency')}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissionplans.currency')}`} 
                            options={currencyOptions}
                            search={true}
                        />
                    </Col>
                    <Col {...colSpan}>
                        <Form.Item
                            name={COMMISSION_ADD_FIELDS.START_DATE}
                            label={t('backoffice.commissionplans.startDateTime')}
                        >
                            <DatePicker
                                dropdownClassName='rt--datepicker-popup rt--datepicker-withtime-popup'
                                format={`${dateService.getFormat(true)}`}
                                showTime={{ format: dateService.getFormat(true, true) }}
                                placeholder={t('backoffice.common.selectDate')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default Filters;