import ROUTES from "core/constants/routes/routes";

import NewsLetterComponent from "pages/communications/newsletter/newsletter.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const NewsLetterPageRoute = {
	path: ROUTES.NEWSLETTER,
	element: NewsLetterComponent, 
    permission: { resource: PERMISSION_RESOURCE.COMMUNICATION_NEWSLETTER, action: PERMISSION_ACTION.VIEW }
};

export default NewsLetterPageRoute;
