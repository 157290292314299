import React from 'react';

import { useDebugSelector } from '../../..';

import ObjectViewer from '../ObjectViewer';

const DebugPrevTab = () => {
    const { selectedChange } = useDebugSelector();

    const isEmptyChange = selectedChange && selectedChange.changes.current === selectedChange.changes.previous;

    if (!selectedChange) {
        return <span className="rt--debug-message-big">Select a change first!</span>
    }

    if (isEmptyChange) {
        return <span className="rt--debug-message-big">Nothing changed.</span>
    }

    return (
        <ObjectViewer value={selectedChange.changes.previous} name={selectedChange.path} />
    );
};

export default DebugPrevTab;
