import { lazy } from "react";

import Spin from "core/ui-kit/spin";

import withSuspenseHoc from "core/HOCs/withSuspense";

import ROUTES from "core/constants/routes/routes";

const PostbackEditContainer = withSuspenseHoc(lazy(() => import("./postbackEditContainer")), <Spin />);

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

import isPostbackEnabled from 'core/helpers/postback/isPostbackEnabled';

const PostbackEditPageRoute = {
	path: `${ROUTES.POSTBACKS_EDIT}/:id`,
	element: PostbackEditContainer, 
    permission: { resource: PERMISSION_RESOURCE.POSTBACK_GENERALINFO, action: PERMISSION_ACTION.VIEW },
    isDisabled: ( projectId, user, userInfo ) => !isPostbackEnabled(projectId, user, userInfo)
};

export default PostbackEditPageRoute;
