import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import DateRangePicker from "components/common/dateRangePicker";
import Select from "components/common/select";

import {
    getNewsletterTemplates,
    setNewsletterTemplatesFilters
} from 'store/actions/portal/communications/newsletter/templates.action';

import useLanguages from 'hooks/useLanguages';

import { globalProjectIdSelector, useAuthSelector } from 'core/stores/authStore';

/** Notifications Page Templates Tab Filters Component */

const Filters = ({
    getNewsletterTemplates,
    setNewsletterTemplatesFilters,
    filters
}) => {
    const { t } = useTranslation();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);

    const [ languages, getLanguages ] = useLanguages(false);

    return (
        <FiltersWrapper
            loadFn={getNewsletterTemplates}
            setFiltersFn={setNewsletterTemplatesFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            datePickerFields={[
                { name: "date", time: false },
                { name: "modified", time: false }
            ]}
            searchFieldName="nameOrId"
            onInit={() => {
                getLanguages();
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.communication.language')}
                        name="languageCode"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') }
                                ].concat(
                                    Object.keys(languages)
                                        .map(item => (
                                            { value: item.toUpperCase(), text: t(`backoffice.languages.${item}`) }
                                        ))
                                )
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.communication.language')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.creationDate')}
                        name="date"
                    >
                        <DateRangePicker
                            showTime={false}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.lastUpdateDate')}
                        name="modified"
                        className='rt--form-item-without-margin'
                    >
                        <DateRangePicker
                            showTime={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get newsletter templates */
    getNewsletterTemplates: PropTypes.func,
    /** Redux action to set newsletter templates filters */
    setNewsletterTemplatesFilters: PropTypes.func,
    /** Redux state property, newsletter templates filters */
    filters: PropTypes.object
}

const mapDispatchToProps = dispatch => (
    {
        getNewsletterTemplates: () => {
            dispatch(getNewsletterTemplates())
        },
        setNewsletterTemplatesFilters: filters => {
            dispatch(setNewsletterTemplatesFilters(filters));
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.newsletter.templates.filters
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);