import { USER_ROLE } from "core/constants/auth";
import { WALLET_TYPE } from "core/constants/wallet";

export const getAvailableWallets = (userRole, allowImpactOnCPA, t) => {
    let wallets = [];

    if (userRole === USER_ROLE.AFFILIATE) {
        /** Earning Balance */
        wallets.push({
            type: WALLET_TYPE.EARNING_BALANCE,
            title: t("backoffice.wallet.earningBalance")
        })

        if (!allowImpactOnCPA) {
            /** CPA Balance */
            wallets.push({
                type: WALLET_TYPE.CPA_BALANCE,
                title: t("backoffice.wallet.cpaBalance")
            })
        }
    }

    return wallets;
}

export const getAmount = (type, currencyCode, currencies, allowImpactOnCPA) => {
    let amount = 0;

    const currency = currencies.find(c => c.currencyCode === currencyCode?.toUpperCase());
    if(!currency){
        return amount;
    } 

    const wallet = currency.wallets.find(b => b.type === type);

    if(!wallet){
        return amount;
    }

    if(type === WALLET_TYPE.EARNING_BALANCE && allowImpactOnCPA){
        const earningAmount = currency.wallets.find(b => b.type === WALLET_TYPE.EARNING_BALANCE)?.amount ?? 0;
        const cpaAmount = currency.wallets.find(b => b.type === WALLET_TYPE.CPA_BALANCE)?.amount ?? 0;
        return earningAmount + cpaAmount;
    }

    return wallet.amount;
}

export const sortCurrenciesByWallet = (currencies, wallets, allowImpactOnCPA) => {
    const sortedCurrencies = [...currencies];

    if (wallets.some(w => w.type === WALLET_TYPE.EARNING_BALANCE)) {
        sortedCurrencies.sort((c1, c2) => {
            const c1Balance = getAmount(WALLET_TYPE.EARNING_BALANCE, c1.currencyCode, currencies, allowImpactOnCPA);
            const c2Balance = getAmount(WALLET_TYPE.EARNING_BALANCE, c2.currencyCode, currencies, allowImpactOnCPA);
            return Number(c2Balance) - Number(c1Balance);
        })
    }

    return sortedCurrencies;
}

export const currenciesDropdownMenu = (currencies, wallets, formatAmount, allowImpactOnCPA) => [
    (
        wallets.length > 0 ? {
            key:"*",
            className:'rt--header-actions-wallet-item-header',
            label: (
                <div className='rt--header-actions-wallet rt--header-actions-wallet-title rt--flex rt--pl-12 rt--pr-12'>
                    {
                        wallets.map(wallet => (
                            <div className='rt--flex rt--flex-col rt--flex-equal rt--justify-center rt--align-start' key={wallet.type}>
                                <span className='rt--title rt--font-small rt--font-regular'>{wallet.title}</span>
                            </div>
                        ))
                    }
                    <div></div>
                </div>
            )
        } : null
    ),
    ...(
        currencies.map(currency => ({
            key: currency.currencyCode,
            className: "rt--header-actions-wallet-item",
            label: (
                <div className='rt--header-actions-wallet'>
                    {
                        wallets.map(wallet => (
                            <div className='rt--flex rt--flex-col rt--flex-equal rt--justify-center rt--align-start' key={wallet.type}>
                                <b className='rt--title rt--font-normal rt--font-bold'>
                                    {
                                        formatAmount(
                                            getAmount(wallet.type, currency.currencyCode, currencies, allowImpactOnCPA), 
                                            currency.currencyCode
                                        )
                                    }
                                </b>
                            </div>
                        ))
                    }
                    <div className={
                        'rt--flex rt--flex-col rt--flex-equal rt--justify-center ' + 
                        (wallets.length > 0 ? "rt--align-end" : "rt--align-center")
                    }>
                    <b className='rt--title rt--font-normal'>{currency.currencyCode}</b>
                </div>
            </div>
            )
        }))
    )
].filter(Boolean)
    