import React, { useState } from "react";

import { COMPANIES_DROPDOWN_MENU_MOBILE_TYPES } from "../constants";

import CompaniesDropdownMobile from "./companies";
import ProjectsDropdownMobile from "./projects";

const CompanyProjectDropdownMobile = ({
    companySearch,
	handleCompanyOrProjectChange,
	selectedCompany,
	selectedProject,
	handleCompanySearchChange,
	projectSearch,
	handleProjectSearchChange,
	filteredCompanies,
	companyFilteredProjects,
    disabled
}) => {

    const [opened, setOpened] = useState(false);
    const [drawerType, setDrawerType] = useState(COMPANIES_DROPDOWN_MENU_MOBILE_TYPES.PROJECTS);
    const [preselectedCompany, setPreselectedCompany] = useState(selectedCompany?.id);
	const [preselectedProject, setPreselectedProject] = useState(selectedProject?.id);

    const handleDrawerTypeChange = type => {
		setDrawerType(type);
	};

    const changeTypeToCompanies = () => {
		handleDrawerTypeChange(COMPANIES_DROPDOWN_MENU_MOBILE_TYPES.COMPANIES);
	};

	const changeTypeToProjects = () => {
		handleDrawerTypeChange(COMPANIES_DROPDOWN_MENU_MOBILE_TYPES.PROJECTS);
	};

    const handleMenuChange = ({ key }) => {
		if (drawerType === COMPANIES_DROPDOWN_MENU_MOBILE_TYPES.COMPANIES) {
			setPreselectedCompany(key);
		} else {
			setPreselectedProject(key);
		}
	};

    const handleOpenChange = opened => {
		setOpened(opened);
		if (!opened) {
			setPreselectedProject(selectedProject?.id);
			setPreselectedCompany(selectedCompany?.id);
			changeTypeToProjects();
		}
	};

    if (drawerType === COMPANIES_DROPDOWN_MENU_MOBILE_TYPES.COMPANIES) {
		return (
			<CompaniesDropdownMobile
				selectedProject={selectedProject}
				onChange={handleCompanyOrProjectChange("company")}
				onOpenChange={handleOpenChange}
				onChangeToProject={changeTypeToProjects}
				companySearch={companySearch}
				opened={opened}
				onSearchChange={handleCompanySearchChange}
				setOpened={setOpened}
				preselectedCompany={preselectedCompany}
				onMenuChange={handleMenuChange}
				filteredCompanies={filteredCompanies}
                disabled={disabled}
			/>
		);
	}

	return (
		<ProjectsDropdownMobile
			opened={opened}
			setOpened={setOpened}
			onMenuChange={handleMenuChange}
			onChange={handleCompanyOrProjectChange("project")}
			onOpenChange={handleOpenChange}
			onSearchChange={handleProjectSearchChange}
			selectedProject={selectedProject}
			projectSearch={projectSearch}
			selectedCompany={selectedCompany}
			preselectedProject={preselectedProject}
			filteredProjects={companyFilteredProjects}
			onChangeToCompanies={changeTypeToCompanies}
            disabled={disabled}
		/>
	);
}   

export default CompanyProjectDropdownMobile;