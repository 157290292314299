import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";

import {
    SET_CALCULATION_APPROVALS_ACTION_BEFORE,
    SET_CALCULATION_APPROVALS_ACTION_FINISH,
    SET_CALCULATION_APPROVALS,
    SET_CALCULATION_APPROVALS_SORTING,
    SET_CALCULATION_APPROVALS_FILTERS
} from "../../../../actionTypes";
import { message } from "antd";
import { isResponseSuccess } from "utils/request";

const setCalculationApprovalsActionBefore = () => ({
    type: SET_CALCULATION_APPROVALS_ACTION_BEFORE,
});

const setCalculationApprovalsActionFinished = () => ({
    type: SET_CALCULATION_APPROVALS_ACTION_FINISH,
});

const setCalculationApprovals = (calculations, add) => ({
    type: SET_CALCULATION_APPROVALS,
    payload: { calculations, add },
});

export const setCalculationApprovalsSorting = sorting => ({
    type: SET_CALCULATION_APPROVALS_SORTING,
    payload: { sorting },
});

export const setCalculationApprovalsFilters = filters => ({
    type: SET_CALCULATION_APPROVALS_FILTERS,
    payload: { filters },
});


export const getCalculationApprovals = nextPage => {
    return (dispatch, getState) => {

        const calculationApprovals = getState().calculationApprovals;
        
        const page = nextPage === "first" ? 1 : nextPage === "next" ? calculationApprovals.sorting.page + 1 : calculationApprovals.sorting.page;
        
        let params = {
            ...calculationApprovals.sorting,
            ...calculationApprovals.filters,
            page: page
        };

        if(page > 1 && calculationApprovals.total <= (page - 1 ) * calculationApprovals.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && calculationApprovals.total === 0){
            return Promise.resolve();
        }

        dispatch(setCalculationApprovalsActionBefore());

        return axios({
            url: ApiUrls.GET_COMMISSION_CALCULATION_APPROVALS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: calculations } }) => {
                dispatch(setCalculationApprovals({
                    ...calculations,
                    item2: calculations.item2.map(c => ({...c, uniqueKey: c.calculationId + "_" + c.affiliiateId + "_" + c.currencyCode}))
                }, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setCalculationApprovalsSorting({ ...calculationApprovals.sorting, page: page }));
                dispatch(setCalculationApprovalsActionFinished());
            })
            .catch(() => {
                dispatch(setCalculationApprovalsActionFinished());
            });
    }
}

export const approveCalculation = id => {
    return dispatch => {
        dispatch(setCalculationApprovalsActionBefore());

        return axios({
            url: ApiUrls.APPROVE_COMMISSION_CALCULATION,
            method: Methods.POST,
            data: {
                id
            }
        })
            .then(({ data }) => {
                dispatch(setCalculationApprovalsActionFinished());
                if (isResponseSuccess(data)) {
                    dispatch(getCalculationApprovals());
                    message.success(data.message);
                }
            })
            .catch(() => {
                dispatch(setCalculationApprovalsActionFinished());
            });
    };
}
