import React, { Fragment } from "react";

import Icon from "core/ui-kit/icon";

import { useTranslation } from "core/hooks/useTranslation";
import classNames from "core/helpers/common/classNames";

import { COMMISSION_POPUP_MODE } from "../constants";

const Empty = ({
    mode
}) => {

    const { t } = useTranslation();

    const className = classNames(
        "rt--commissions-empty rt--flex rt--align-center rt--justify-center",
        mode === COMMISSION_POPUP_MODE.ADD && "rt--commissions-empty-add"
    )

    return (
        <div className={className}>
            <div className="rt--commissions-empty-inner rt--flex rt--flex-col rt--align-center rt--justify-center">
                <div className="rt--flex rt--align-center rt--justify-center rt--pt-20 rt--pb-20">
                    <Icon name="not-found" size={120} />
                </div>
                {
                    mode === COMMISSION_POPUP_MODE.VIEW ? (
                        <Fragment>
                            <b className="rt--font-regular rt--font-normal rt--pb-4">{t("backoffice.commissionplans.noCommissionsAssignedYet")}</b>
                            <span className="rt--font-regular rt--font-normal">{t("backoffice.commissionplans.noCommissionsAssignedYetInfo")}</span>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <b className="rt--font-regular rt--font-normal rt--pb-4">{t("backoffice.commissionplans.noCommissionsFoundToAssign")}</b>
                            <span className="rt--font-regular rt--font-normal">{t("backoffice.commissionplans.noCommissionsFoundToAssignInfo")}</span>
                        </Fragment>
                    )
                }
            </div>
            
        </div>
    )
}

export default Empty;