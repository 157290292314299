import ROUTES from "core/constants/routes/routes";

import AggregatedDataComponent from "pages/reports/aggregatedData/aggregatedData.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const AggregatedDataPageRoute = {
	path: ROUTES.AGGREGATED_DATA,
	element: AggregatedDataComponent, 
    permission: { resource: PERMISSION_RESOURCE.REPORT_AGGREGATION_HISTORY, action: PERMISSION_ACTION.VIEW }
};

export default AggregatedDataPageRoute;
