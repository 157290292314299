import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { isMobile } from "utils/common";

import {
    SET_SYSTEM_LANGUAGES_ACTION_BEFORE,
    SET_SYSTEM_LANGUAGES_ACTION_FINISH,
    SET_SYSTEM_LANGUAGES,
    SET_SYSTEM_LANGUAGES_SORTING,
    SET_SYSTEM_LANGUAGES_SAVE_ACTION_BEFORE,
    SET_SYSTEM_LANGUAGES_SAVE_ACTION_FINISH,
    SET_AVAILABLE_LANGUAGES
} from "../../../../actionTypes";

const setSystemLanguagesActionBefore = () => ({
    type: SET_SYSTEM_LANGUAGES_ACTION_BEFORE,
});

const setSystemLanguagesActionFinished = () => ({
    type: SET_SYSTEM_LANGUAGES_ACTION_FINISH,
});

const setSystemLanguagesSaveActionBefore = () => ({
    type: SET_SYSTEM_LANGUAGES_SAVE_ACTION_BEFORE,
});

const setSystemLanguagesSaveActionFinished = () => ({
    type: SET_SYSTEM_LANGUAGES_SAVE_ACTION_FINISH,
});

const setSystemLanguages = (languages, add) => ({
    type: SET_SYSTEM_LANGUAGES,
    payload: { languages, add },
});

export const setSystemLanguagesSorting = sorting => ({
    type: SET_SYSTEM_LANGUAGES_SORTING,
    payload: { sorting },
});


export const getSystemLanguages = nextPage => {
    return (dispatch, getState) => {
        
        const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().systemLanguages.sorting.page + 1 : getState().systemLanguages.sorting.page;
        let params = {
            ...getState().systemLanguages.sorting,
            page: page
        };

        if(page > 1 && getState().systemLanguages.total <= (page - 1 ) * getState().systemLanguages.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && getState().systemLanguages.total === 0){
            return Promise.resolve();
        }
        
        dispatch(setSystemLanguagesActionBefore());

        return axios({
            url: ApiUrls.GET_SYSTEM_LANGUAGES,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: languages } }) => {
                dispatch(setSystemLanguages(languages, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setSystemLanguagesSorting({ ...getState().systemLanguages.sorting, page: page }));
                dispatch(setSystemLanguagesActionFinished());
            })
            .catch((ex) => {
                dispatch(setSystemLanguagesActionFinished());
            });
    }
}

export const createSystemLanguage = (language, onSuccess) => {
    return (dispatch) => {
        dispatch(setSystemLanguagesSaveActionBefore());
        return axios({
            url: ApiUrls.CREATE_SYSTEM_LANGUAGE,
            method: Methods.POST,
            data: {
                ...language
            },
        })
            .then(({ data }) => {
                dispatch(setSystemLanguagesSaveActionFinished());
                if (isResponseSuccess(data)) {
                    dispatch(getSystemLanguages())
                    onSuccess && onSuccess(data.value);
                }
            })
            .catch(() => {
                dispatch(setSystemLanguagesSaveActionFinished());
            });
    };
};

/** Avalable Languages */

const setAvailableLanguages = languages => ({
    type: SET_AVAILABLE_LANGUAGES,
    payload: { languages },
});

export const getAvailableLanguages = () => {
    return (dispatch) => {
        //dispatch(setSystemAvalableLanguagesActionBefore());
        return axios({
            url: ApiUrls.GET_AVAILABLE_LANGUAGES,
            method: Methods.GET
        })
            .then(({ data: { value: languages } }) => {
                dispatch(setAvailableLanguages(languages));
               // dispatch(setSystemAvalableLanguagesActionFinished());
            })
            .catch(() => {
               // dispatch(setSystemAvalableLanguagesActionFinished());
            });
    }
}