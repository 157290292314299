import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import TabTableDashboardLayout from 'components/layouts/tab/table';

import MarketingPieCharts from '../../../components/marketingPieCharts';
import MarketingLineChart from '../../../components/marketingLineChart';

import Filters from '../../../components/filters';

import { TIME_PERIOD } from '../../../constants';

import useFirstRender from 'hooks/useFirstRender';

import { globalProjectIdSelector, useAuthSelector } from 'core/stores/authStore';
import { isMobile } from 'utils/common';

const getDefaultFilters = () => (
    {
        period: {
            period: TIME_PERIOD.TODAY,
            from: null,
            to: null
        }
    }
)

const General = ({
    updateLastUpdatedDate
}) => {

    const [ filters, setFilters ] = useState(getDefaultFilters())

    const globalProjectId = useAuthSelector(globalProjectIdSelector);

    const updateFilters = useCallback((newFilters) => {
        setFilters(prevState => {
            return {
                ...prevState,
                ...newFilters
            }
        })
    }, [])


    useFirstRender({
        afterFirstRenderCB: () => {
            updateFilters(getDefaultFilters())
        },
        dependencies: [globalProjectId, updateFilters]
    })

    return (
        <TabTableDashboardLayout
        header={{
            filters: isMobile() ? (
                <Filters 
                    filters={filters} 
                    updateFilters={updateFilters}
                />
            ) : undefined,
            content: !isMobile() ? (
                <div className="rt--flex rt--align-center rt--justify-end rt--width-full">
                    <Filters 
                        filters={filters} 
                        updateFilters={updateFilters}
                    />
                </div>
            ) : undefined
        }}
        >
            <MarketingPieCharts 
                globalProjectId={globalProjectId}
                filters={filters}
                updateLastUpdatedDate={updateLastUpdatedDate}
            />

            <MarketingLineChart 
                globalProjectId={globalProjectId}
                filters={filters}
                updateLastUpdatedDate={updateLastUpdatedDate}
            />
        </TabTableDashboardLayout>
    )
}

General.propTypes = {
    updateLastUpdatedDate: PropTypes.func,
}

export default General;