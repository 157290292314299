import React from "react"
import PropTypes from "prop-types";

import { useTranslation } from "core/hooks/useTranslation";

import { Row, Col, Button } from "antd";

import FormId from "components/common/formId";
import Select from "components/common/select";

import { isMobile } from "utils/common";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'core/constants/permission';

import buttonType from 'types/components/button.type';

import usePermissions from "core/hooks/usePermission";

/** Tab Form dashboard layout */
const TabFormDashboardLayout = ({
    children,
    buttons,
    actions,
    leftContent=null,
    id,
    longId,
    alwaysShowId=false,
    dropdown
}) => {

    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    /** Function to Render dropdown 
         * @function
         * @returns {JSX}
         * @memberOf TabFormDashboardLayout
    */
    const renderDropdown = () => (
        <Select
            options={
                dropdown.items.map(item => (
                    { value: item.key, text: item.value}
                ))
            }
            placeholder={dropdown.placeholder}
            value={dropdown.value}
            onChange={dropdown.onChange}
            isDark={true}
            className={"rt--tab-form-layout-header-actions-dropdown" + (dropdown.small ? " rt--tab-form-layout-header-actions-dropdown-small" : "")}
            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
        />
    )

    return (
        <div className="rt--tab-form-layout">
            <div className="rt--tab-form-layout-inner rt--flex rt--flex-col">
                {
                    dropdown && (
                        <div className={"rt--tab-form-layout-header rt--flex rt--align-center rt--justify-end " + (!isMobile() ? "rt--pl-24 rt--pr-24 rt--pt-16 rt--pb-16" : "rt--pl-12 rt--pr-12 rt--pt-12 rt--pb-12")}>
                            {dropdown && renderDropdown()}
                        </div>
                    )
                }
                <div className={"rt--tab-form-layout-content rt--flex-equal " + (isMobile() ? "rt--pl-16 rt--pr-16 rt--pt-16 rt--pb-16" : "rt--pl-24 rt--pr-24 rt--pt-24 rt--pb-24")}>
                    {
                        (id || longId || leftContent) && (
                            <Row gutter={[16, 0]}>
                                <Col span={24} >
                                    <div className={'rt--flex rt--justify-between rt--align-center ' + (isMobile() ? "rt--mb-16" : "rt--mb-24")}>
                                        <div className="rt--flex rt--align-center rt--flex-equal">
                                            {
                                                (
                                                    id && (
                                                        alwaysShowId ||
                                                        permissionUtils.has( PERMISSION_RESOURCE.DEVELOPER, PERMISSION_ACTION.VIEW )
                                                    )
                                                ) && (
                                                    <FormId id={id} text={t("backoffice.common.id")} />
                                                )
                                            }
                                            {
                                                longId ? (
                                                    <div className="rt--ml-16">
                                                        <FormId
                                                            id={longId}
                                                            text={t("backoffice.common.longId")}
                                                        />
                                                    </div>
                                                ) : null
                                            }

                                            {
                                                leftContent
                                            }
                                        </div>

                                        <div className='rt--flex rt--align-center rt--justify-end'>
                                            {
                                                !isMobile() && actions ? <div className="rt--tab-form-layout-actions">{actions}</div> : null
                                            }
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        )
                    }

                    {
                        /** Mobile Actions */
                        isMobile() && actions ? (
                            <div className="rt--tab-form-layout-actions">{actions}</div>
                        ) : null
                    }
                    {children}
                </div>
                
                {
                    buttons && buttons.some(b => b.enabled) && (
                        <div
                            className={
                                "rt--tab-form-layout-footer rt--flex rt--align-center " +
                                (isMobile() ? "rt--pl-16 rt--pr-16 rt--pt-8 rt--pb-8" : "rt--pl-24 rt--pr-24 rt--pt-16 rt--pb-16") +
                                (buttons.filter(b => b.enabled).length > 1 && isMobile() ? " rt--justify-between" : " rt--justify-end")
                            }
                        >
                            {
                                buttons.map(b => b.enabled ? (
                                    <Button
                                        type={b.type}
                                        className={
                                            "rt--button rt--button-primary" +
                                            (b.className ? b.className : "")
                                        }
                                        onClick={b.onClick}
                                        key={b.text}
                                        disabled={b.disabled ? true : false}
                                        htmlType={b.htmlType || "submit"}
                                        loading={b.loading}
                                    >
                                        {
                                            b.text
                                        }

                                    </Button>
                                ) : null)
                            }
                        </div>
                    )
                }

            </div>
        </div>
    )
}

/** TabFormDashboardLayout propTypes
 * PropTypes
*/
TabFormDashboardLayout.propTypes = {
    /** Layout Content */
    children: PropTypes.node,
    /** Buttons */
    buttons: PropTypes.arrayOf(PropTypes.shape(buttonType)),
    /** Actions */
    actions: PropTypes.node,
    /** The id */
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** The longId */
    longId: PropTypes.number,
    /** If true, will always show id, even if not have developer permissino */
    alwaysShowId: PropTypes.bool,
    /** Dropdown options */
    dropdown: PropTypes.object
}

export default TabFormDashboardLayout;