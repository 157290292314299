import React from "react";
import PropTypes from "prop-types";

import { Spin as AntSpin } from "antd";

const Spin = ({ 
    children, 
    ...rest 
}) => {
	return (
        <AntSpin 
            { ...rest }
        >
            {
                children
            }
        </AntSpin>
    );
};

Spin.propTypes = {
	children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

export default Spin;
