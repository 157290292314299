import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { 
    setAffiliatesActionBefore, 
    setAffiliatesActionFinished
} from './affiliates.action';

import { 
    SET_AFFILIATES_SUB_AFFILIATES,
    SET_AFFILIATES_SUB_AFFILIATES_FILTERS
} from "../../../../actionTypes";

const setAffiliateSubAffiliates = subAffiliates => ({
    type: SET_AFFILIATES_SUB_AFFILIATES,
    payload: { subAffiliates }
})

export const setAffiliateSubAffiliatesFilters = filters => ({
    type: SET_AFFILIATES_SUB_AFFILIATES_FILTERS,
    payload: { filters },
});

export const getAffiliateSubAffiliates = (affiliateId, languageCode) => {
    return (dispatch, getState) => {
        const userNameOrId = getState().affiliates.edit.subAffiliates.filters.userNameOrId;

        dispatch(setAffiliatesActionBefore());
        return axios({
            url: ApiUrls.GET_AFFILIATE_SUB_AFFILIATES,
            method: Methods.GET,
            params: {
                affiliateId, 
                languageCode,
                userNameOrId
            }
        })
        .then(({data : {value: subAffiliates}}) => {
            dispatch(setAffiliateSubAffiliates(subAffiliates))
            dispatch(setAffiliatesActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliatesActionFinished());
        })
    }
}
