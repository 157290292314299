//#region React
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useParams } from "react-router-dom";
//#endregion

import { Form, Col, Row } from 'antd';

//#region Components
import Modal from "components/common/modal";
import Input from 'components/common/input';
//#endregion

//#region Actions
import {
    addAffiliateTrafficSource,
    saveAffiliateTrafficSources,
    getAffiliateTrafficSources
} from "store/actions/portal/affiliates/affiliates/general.action";
//#endregion

//#region Constants
import { POPUP_SIZE } from "constants/common.constants";
import { USER_ROLE } from 'constants/user.constants';
//#endregion

//#region types
import { trafficSourceType } from 'types/affiliate/generalInfo.type';
//#endregion

import { useGlobalSelector, userInfoSelector } from 'core/stores/globalStore';
import { useAuthSelector, userRoleSelector } from 'core/stores/authStore';

const FORM_FIELDS_NAMES = {
    NAME: "name"
}

/** Add Traffic Source to the Affiliate Popup Component */
const AddTrafficSource = ({
    trafficSource,
    addAffiliateTrafficSource,
    saveAffiliateTrafficSources,
    getAffiliateTrafficSources,
    onClose
}) => {
    const { t } = useTranslation();
    const routeParams = useParams();

    const userInfo = useGlobalSelector(userInfoSelector);
    const role = useAuthSelector(userRoleSelector);

    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;
    
    const affiliateId = role === USER_ROLE.AFFILIATE ? userInfo.id : routeParams.id;
    
    const okAction = trafficSource ? saveAffiliateTrafficSources : addAffiliateTrafficSource;

    const handleForm = () => {
        validateFields()
            .then((data) => {
                const mergedData = { ...data, id: affiliateId, ...(trafficSource && { sourceId: trafficSource.id }) }

                okAction(
                    mergedData,
                    trafficSource => {
                        onClose();
                        getAffiliateTrafficSources(affiliateId);
                    });
            }).catch(() => { })
    };

    return (
        <Modal
            title={`${trafficSource ? `${t('backoffice.common.edit')} ${trafficSource.name}` : t('backoffice.affiliates.addTrafficSource')}`}
            cancelText={t('backoffice.common.cancel')}
            okText={`${trafficSource ? t('backoffice.common.save') : t('backoffice.common.add')}`}
            onOk={handleForm}
            onCancel={onClose}
            size={POPUP_SIZE.MIDDLE}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            name={FORM_FIELDS_NAMES.NAME}
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') }
                            ]}
                            className='rt--general-form-item'
                        >
                            <Input.Dropdown
                                placeholder="example.com"
                                items={[
                                    {
                                        label: "https://",
                                        value: "https://",
                                    }
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

AddTrafficSource.propTypes = {
    /** Redux action to get Affiliate Traffic Sources */
    getAffiliateTrafficSources: PropTypes.func,
    /** React prop, represents the Payments of current editing Project */
    trafficSource: trafficSourceType,
    /** Redux action to add Affiliate Traffic Source */
    addAffiliateTrafficSource: PropTypes.func,
    /** Redux action to edit Affiliate Traffic Source */
    saveAffiliateTrafficSources: PropTypes.func,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        addAffiliateTrafficSource: (params, onSuccess) => {
            dispatch(addAffiliateTrafficSource(params, onSuccess));
        },
        saveAffiliateTrafficSources: (params, onSuccess) => {
            dispatch(saveAffiliateTrafficSources(params, onSuccess));
        },
        getAffiliateTrafficSources: (id) => {
            dispatch(getAffiliateTrafficSources(id));
        }
    }
)

const mapStateToProps = state => {
    return {
        paymentMethods: state.projects.edit.paymentMethods.paymentMethods,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTrafficSource);