import ROUTES from "core/constants/routes/routes";

import Marketing from "pages/dashboard/marketing";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const MarketingRoute = {
	path: ROUTES.DASHBOARD_MARKETING,
	element: Marketing, 
    permission: { resource: PERMISSION_RESOURCE.DASHBOARD_MARKETING, action: PERMISSION_ACTION.VIEW }
};

export default MarketingRoute;
