import React, { lazy } from "react";

import Spin from "core/ui-kit/spin";

import ROUTES from "core/constants/routes/routes";

import withSuspenseHoc from "core/HOCs/withSuspense";

const ResetPasswordContainer = withSuspenseHoc(lazy(() => import("./resetPasswordContainer")), <Spin />);

const ResetPasswordPageRoute = {
	path: ROUTES.RESET_PASSWORD,
	element: <ResetPasswordContainer />,
};

export default ResetPasswordPageRoute