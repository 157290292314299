import ROUTES from "core/constants/routes/routes";

import AccessManagersEditComponent from "pages/userManagement/accessManagers/edit/accessManager-edit.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const AccessManagerEditPageRoute = {
	path: `${ROUTES.ACCESS_MANAGERS_EDIT}/:id`,
	element: AccessManagersEditComponent, 
    permission: [
        { resource: PERMISSION_RESOURCE.ACCESS_MANAGER_GENERALINFO, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.ACCESS_MANAGER_SESSIONS, action: PERMISSION_ACTION.VIEW }
    ]
};

export default AccessManagerEditPageRoute;
