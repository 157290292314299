import { useMemo } from "react";

import { useGlobalSelector, userPermissionsSelector } from "core/stores/globalStore";
import { useAuthSelector, userStateSelector } from "core/stores/authStore";

import Permissions from "core/helpers/permissions";

const usePermissions = () => {
	const userPermissions = useGlobalSelector(userPermissionsSelector);
	const userState = useAuthSelector(userStateSelector);

	const utils = useMemo(() => {
		return Permissions(userPermissions, userState);
	}, [userPermissions, userState]);

	return utils;
}

export default usePermissions;