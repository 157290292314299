import React from "react";

import { DatePicker as AntDatePicker } from "antd";

import Icon from "core/ui-kit/icon";

const DatePicker = props => {
    return (
        <AntDatePicker
            className="rt--datepicker"
            showToday={false}
            showNow={false} 
            allowClear={false}
            suffixIcon={<Icon name="date" />}
            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
            inputReadOnly={true}
            { ...props }
        />
    ) 
}

export default DatePicker;