import React from "react"

import isString from "core/helpers/typeChecks/isString";
import Tooltip from "components/common/tooltip";
import isNumber from "core/helpers/typeChecks/isNumber";

const ListView = ({
    data = []
}) => {

    const showTooltip = item => {
        if(item.disableTooltip){
            return false;
        }

        if(!Boolean(item.value) || item.value === "-"){
            return false;
        }

        return true;
    }

    return (
        <div className="rt--list-view">
            {
                data.map(item => (
                    <div
                        className="rt--list-view-row rt--flex rt--align-center rt--justify-between"
                        key={item.key}
                    >
                        <b className="rt--title rt--font-normal rt--font-bold rt--list-view-row-title">
                            { item.title }
                        </b>
                        {
                            showTooltip(item) ? (
                                <Tooltip
                                    title={item.value}
                                    trigger={["click"]}
                                    enableMobile={true}
                                    className="rt--list-view-tooltip"
                                >
                                    <span className="rt--title rt--font-normal rt--list-view-row-value">
                                        { item.value }
                                    </span>

                                </Tooltip>
                            ) : (
                                <span className="rt--title rt--font-normal rt--list-view-row-value">
                                    { item.value }
                                </span>
                            )
                        }
                    </div>
                ))
            }
        </div>
    )
}

export default ListView