import { memo, useCallback, useEffect, useRef } from "react";

import SignalRService from "core/services/signalRService";
import TokenManagerService from "core/services/tokenManagerService";

import { authStore, useAuthSelector, userSelector } from "core/stores/authStore";
import { useGlobalSelector, userInfoSelector } from "core/stores/globalStore";

import getAPIUrls from "core/helpers/api/getAPIUrls";

import { USER_ROLE, USER_TYPE } from "core/constants/auth";

import { CONNECTION_TYPES, UPDATE_SESSION_INTERVAL } from "./constants";

import { useDispatch } from "react-redux";
import { addNotification } from "store/actions/portal/notifications/notifications.action";

const SignalR = () => {

    const dispatch = useDispatch();

    const { sessionId, role, wsToken, hasPrevious } = useAuthSelector(userSelector);

    const { userId } = useGlobalSelector(userInfoSelector);

    const interval = useRef(null)

    const urls = getAPIUrls()

    const handleSignalREvents = useCallback((connection, connectionType) => {
        if (connectionType === CONNECTION_TYPES.ADMIN) {
            interval.current = setInterval(() => {
                if (connection.getConnection()._connectionState === "Connected") {
                    connection.getConnection().invoke(
                        "UpdateSession",
                        sessionId,
                        userId,
                        role === USER_ROLE.ADMIN ? USER_TYPE.ADMIN :
                        role === USER_ROLE.AFFILIATE ? USER_TYPE.AFFILIATE :
                            role === USER_ROLE.AFFILIATE_MANAGER ? USER_TYPE.AFFILIATE_MANAGER : null
                    );
                }
            }, UPDATE_SESSION_INTERVAL)
        }

        connection.getConnection().on('RefreshToken', () => {
            TokenManagerService.updateToken()
        });

        connection.getConnection().on('Logout', () => {
            if(!hasPrevious){
                authStore.logout();
            }
        });

        connection.getConnection().on('Notification', data => {
            if (role !== USER_ROLE.ACCESS_MANAGER) {
                dispatch( addNotification(JSON.parse(data)) )
            }
        });

    }, [sessionId, role, userId, hasPrevious])

    useEffect(() => {
        const adminSignalRInstance = new SignalRService(
            `${urls.SIGNALR_ADMIN}?accessToken=${wsToken}&apiType=admin`,
            connection => handleSignalREvents(connection, CONNECTION_TYPES.ADMIN)
        )

        const jobsSignalRInstance = new SignalRService(
            `${urls.SIGNALR_JOBS}?accessToken=${wsToken}&apiType=admin`,
            connection => handleSignalREvents(connection, CONNECTION_TYPES.JOB)
        )

        return () => {
            clearInterval(interval.current);

            adminSignalRInstance.getConnection().off("UpdateSession");
            adminSignalRInstance.getConnection().off("RefreshToken");
            adminSignalRInstance.getConnection().off("Logout");
            adminSignalRInstance.getConnection().off("Notification");

            jobsSignalRInstance.getConnection().off("RefreshToken");
            jobsSignalRInstance.getConnection().off("Logout");
            jobsSignalRInstance.getConnection().off("Notification");
        }
    }, [wsToken])

	return null;
};

export default memo(SignalR);