import { message } from "antd";

import RESPONSE_CODES from "core/constants/api/responseCodes";

import translationService from "core/services/translationService";

const showError = (msg, status, resource) => {
    if(!status){
        return message.error(msg);
    }

    const isUnknownStatus = !Object.values(RESPONSE_CODES).includes(status);

    if (isUnknownStatus) {
        return message.error(msg);
    }

    const errorStatusName = Object.keys(RESPONSE_CODES).find(key => RESPONSE_CODES[key] === status);

    let text = (
        translationService
            .translate(`backoffice.errors.${errorStatusName}`)
            .replace("%resource%",
                resource && resource !== "None"
                    ? translationService.translate(`backoffice.resources.${resource}`)
                    : ""
            )
    );

    if(status === RESPONSE_CODES.InvalidParameters && msg){
        text = msg;
    }

    return message.error(text);
}

export default showError;