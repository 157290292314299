import ROUTES from "core/constants/routes/routes";

import CampaignsEditComponent from "pages/marketingTools/campaigns/edit/campaign-edit.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const CampaignEditPageRoute = {
	path: `${ROUTES.CAMPAIGNS_EDIT}/:id`,
	element: CampaignsEditComponent, 
    permission: [
        { resource: PERMISSION_RESOURCE.MARKETING_TOOLS_CAMPAIGN, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.MARKETING_TOOLS_CREATIVE, action: PERMISSION_ACTION.VIEW }
    ]
};

export default CampaignEditPageRoute;
