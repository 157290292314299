const getAPIUrls = () => {
    const currentOrigin = window.location.origin;

    const isLoc = (
        currentOrigin.includes("localhost") || 
        (
            currentOrigin.startsWith("http://aff-admin-client.") &&
            currentOrigin.endsWith(".digi.loc")
        )
    )

    if ( isLoc ) {
        return {
            API_URL: import.meta.env.SYSTEM_API_URL_LOC,
            SIGNALR_ADMIN: import.meta.env.SYSTEM_SIGNALR_URL_ADMIN_LOC,
            SIGNALR_JOBS: import.meta.env.SYSTEM_SIGNALR_URL_JOBS_LOC
        }
    }

    return {
        API_URL: import.meta.env.SYSTEM_API_URL_COM,
        SIGNALR_ADMIN: import.meta.env.SYSTEM_SIGNALR_URL_ADMIN_COM,
        SIGNALR_JOBS: import.meta.env.SYSTEM_SIGNALR_URL_JOBS_COM
    }
}

export default getAPIUrls;