import React, { lazy } from "react";

import Spin from "core/ui-kit/spin";

import ROUTES from "core/constants/routes/routes";

import withSuspenseHoc from "core/HOCs/withSuspense";

const ActivateAccountContainer = withSuspenseHoc(lazy(() => import("./activateAccountContainer")), <Spin />);

const ActivateAccountPageRoute = {
	path: ROUTES.ACTIVATE,
	element: <ActivateAccountContainer />,
};

export default ActivateAccountPageRoute
