import React from 'react';

import { useTranslation } from "core/hooks/useTranslation";
import { useSearchParams } from 'react-router-dom';

import Tabs from 'components/common/tabs';

import GeneralInfoComponent from '../edit/sections/general';
import AffiliatesComponent from '../edit/sections/affiliates';
import SessionsComponent from '../edit/sections/sessions';

import ROUTES from "core/constants/routes/routes";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "core/constants/permission";
import { USER_STATE } from "constants/user.constants";

/** Affiliate Manager Edit Page Component */
const AffiliateManagerEditComponent = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    const items = [
        {
            title: t("backoffice.users.general"),
            permissions: [{ resource: PERMISSION_RESOURCE.AFFILIATE_MANAGER_GENERALINFO, action: PERMISSION_ACTION.VIEW }],
            component: <GeneralInfoComponent/>
        },
        {
            title: t("backoffice.users.affiliates"),
            permissions: [{ resource: PERMISSION_RESOURCE.AFFILIATE_MANAGER_AFFILIATES, action: PERMISSION_ACTION.VIEW }],
            disabled: Number(searchParams.get("state")) === USER_STATE.IN_PROGRESS,
            component: <AffiliatesComponent />
        },
        {
            title: t("backoffice.users.sessions"),
            permissions: [{ resource: PERMISSION_RESOURCE.AFFILIATE_MANAGER_SESSIONS, action: PERMISSION_ACTION.VIEW }],
            component: <SessionsComponent />
        }
    ]

    return (
        <Tabs 
            items={items}
            mainPage={{ title: t('backoffice.menu.affiliateManagers'), path: ROUTES.AFFILIATE_MANAGERS }}
        />
    )

}



export default AffiliateManagerEditComponent;