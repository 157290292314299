import { produce, enablePatches } from "immer";

import isArray from "core/helpers/typeChecks/isArray";
import isObject from "core/helpers/typeChecks/isObject";
import isObjectLiteral from "core/helpers/typeChecks/isObjectLiteral";
import isFrozenObject from "core/helpers/typeChecks/isFrozenObject";

enablePatches();

const IS_PROXY = Symbol("IS_PROXY");

class ImmutableState {
	constructor(initialState, onStateChange) {
		this._onStateChange = onStateChange;
		this.pureState = initialState;
		this.proxyState = this._createProxyState(initialState);
	}

	_getByPath(obj, path) {
		let currentObj = obj;

		for (const segment of path) {
			currentObj = currentObj[segment];
		}

		return currentObj;
	}

	_changeState(path, value, remove = false) {
		let previousState = null;
		let currentState = null;

		this.pureState = produce(this.pureState, (draft) => {
			const objectPath = path.slice(0, -1);
			const propName = path[path.length - 1];
			const currentObj = this._getByPath(draft, objectPath);

			if (remove) {
                delete currentObj[propName];
            } else {
                currentObj[propName] = value;
            }
		}, (curr, prev) => {
			previousState = prev;
			currentState = curr;
		});

		this._onStateChange({ status: "UPDATE", path, value, previousState, currentState });
	}

	_cleanValue(value) {
		if (isObject(value)) {
			if (value[IS_PROXY] || isFrozenObject(value)) {
				let cleanedValue = null;

				if (isArray(value)) {
					cleanedValue = value.map((item) => this._cleanValue(item));
				}

				if (isObjectLiteral(value)) {
					cleanedValue = Object.assign({}, value);

					Object.keys(cleanedValue).forEach((key) => {
						cleanedValue[key] = this._cleanValue(cleanedValue[key]);
					});
				}

				return cleanedValue;
			}

			Object.keys(value).forEach((key) => {
				value[key] = this._cleanValue(value[key]);
			});
		}

		return value;
	}

	_makeProxy(obj, selfPath) {
		return new Proxy(obj, {
			set: (target, name, value, receiver) => {
				let innerValue = value;
				if (isObject(value)) {
					innerValue = this._createProxyState(value, [...selfPath, name]);
				}

				this._changeState([...selfPath, name], this._cleanValue(value));

				return Reflect.set(target, name, innerValue, receiver);
			},

			get: (target, name, receiver) => {
				if (name === IS_PROXY) {
					return true;
				}

				if (isArray(target)) {
					const isMethod = typeof target[name] === "function";

					if (isMethod) {
						return (...args) => Array.prototype[name].apply(receiver, args);
					}
				}

				return Reflect.get(target, name, receiver);
			},

			deleteProperty: (target, name) => {
				if (!isObject(target)) {
					return false;
				}

				this._changeState([...selfPath, name], undefined, true);

				return Reflect.deleteProperty(target, name);
			}
		});
	}

	_createProxyState(state, selfPath = []) {
		if (!isObject(state)) {
			return state;
		}

		let res = null;

		if (isArray(state)) {
			res = [];

			state.forEach((item, index) => {
				res[index] = this._createProxyState(item, [...selfPath, index]);
			});
		} else {
			res = {};

			Object.keys(state).forEach((key) => {
				res[key] = this._createProxyState(state[key], [...selfPath, key]);
			});
		}

		return this._makeProxy(res, selfPath);
	}
}

export default ImmutableState;
