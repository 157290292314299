import ROUTES from "core/constants/routes/routes";

import PlatformTestComponent from "pages/developer/platformTest/platformTest.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const PlatformTestPageRoute = {
	path: ROUTES.DEVELOPER_PLATFORM_TEST,
	element: PlatformTestComponent , 
    permission: { resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW }
};

export default PlatformTestPageRoute;
